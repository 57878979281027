<article>
  <app-labeled-text-inline label="sides.{{ quote.side }}">
    <app-flag [icon]="currency" [label]="quote.amount | balance: currency"></app-flag>
  </app-labeled-text-inline>

  <app-labeled-text-inline label="for">
    <app-flag
      [icon]="counterCurrency"
      label="{{ counterAmount | number: '1.2-2' | nullable }} {{ counterCurrency }}">
    </app-flag>
  </app-labeled-text-inline>

  <app-labeled-text-inline label="Tenor">
    {{ "order.tenors." + quote.tenor | translate }}
  </app-labeled-text-inline>

  <hr />

  <app-labeled-text-inline [label]="quote.rate ? 'ExpectedRate' : 'order.Points'">
    <h4>{{ target | rate }}</h4>
  </app-labeled-text-inline>
</article>
