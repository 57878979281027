import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "@core/session";

@Injectable()
export class FlowsGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (this.userService.hasCustomer && this.userService.userData.showFlowDeclaration) {
      return true;
    }

    setTimeout(() => this.router.navigate(["/"]), 0);
    return false;
  }
}
