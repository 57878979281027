import { Component, HostBinding, HostListener } from "@angular/core";
import { Dashboard } from "@core/preferences/preferences.model";
import { PreferencesService } from "@core/preferences/preferences.service";

@Component({
  selector: "app-pro-banner",
  template: `<div class="pko-banner__container">
      <h3 class="pko-banner__title">{{ "banner.ProTitle" | translate }}</h3>
      <p class="pko-banner__subtitle">{{ "banner.ProText" | translate }}</p>
    </div>
    <img class="pko-banner__image" src="assets/images/pro-banner-computer.svg" />`,
})
export class ProBannerComponent {
  @HostBinding("class") class = "pko-banner";
  @HostListener("click") onClick() {
    this.switchDashboard(false);
  }

  constructor(private _preferences: PreferencesService) {}

  private switchDashboard(simple: boolean): void {
    this._preferences.setDashboard({ simple } as Dashboard);
  }
}
