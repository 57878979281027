import { Failure } from "@core/models/transaction";
import { CountdownMessage } from "@shared/components";

export interface ConfirmationRequest {
  transactionToken: string;
  rateToken: string;
}

export interface DecisionViewModel<T> {
  intervention?: boolean;
  decision?: {
    summary: T;
    time?: number;
    forward(): void;
    onTimeout?: () => void;

    /**
     * Special message to display in the footer
     */
    message?: CountdownMessage;

    forwardValidator?: (time: number) => boolean;
  };
}

type StatusSuccess = { transactionId: string };
type StatusFailure = { status: Failure };

export type StatusResponse = StatusSuccess | StatusFailure;

export const isPending = <T extends Partial<StatusResponse>>(response: T) =>
  (response as StatusFailure).status?.code === "PENDING";

export const isStatusSuccess = (response: StatusResponse): response is StatusSuccess =>
  "transactionId" in response;
