import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session";
import { Filters, PageData } from "@features/history-common/models";
import { Observable } from "rxjs";
import { defaultMonthlyPricingsFilters } from "../models/exchange-list";
import { IListItem } from "../models/view360-list";
import { View360ApiService } from "./view360-api.service";
import { View360Service } from "./view360.service";

@Injectable({
  providedIn: "root",
})
export class MonthlyPricingsService extends View360Service {
  filters = defaultMonthlyPricingsFilters as Filters;

  protected get defaultFilters() {
    return {
      ...defaultMonthlyPricingsFilters,
      sortingOrder: this.preferenceService.dealHistory.order,
    } as Filters;
  }

  constructor(
    public api: View360ApiService,
    protected preferenceService: PreferencesService,
    userService: UserService
  ) {
    super(api, userService);
  }

  getList(filters: Filters): Observable<PageData<IListItem>> {
    return this.api.getPricings(filters);
  }
}
