import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { HolidaysService } from "@features/currency/holidays.service";
import { OriginalExchange, RollRateType } from "@features/exchange/models";
import {
  TenorDate,
  TenorDateConfig,
  TenorDateForm,
  TenorService,
  getProduct,
  getSpotDate,
} from "@features/tenor";
import { assertIsDefined } from "@utils/misc";
import { EMPTY, Observable, combineLatest, map, startWith, tap } from "rxjs";

@Component({
  selector: "app-form-tenor-date-roll",
  templateUrl: "tenor-date-roll.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TenorDateProductRollFormComponent implements OnInit {
  #tenorDates: TenorDate[] = [];

  @Input() original!: Original;

  tenorDates$: Observable<TenorDateConfig> = EMPTY;
  holidays$: Observable<string[]> = EMPTY;
  restricted!: string[];

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private tenorService: TenorService,
    private holidayService: HolidaysService
  ) {}

  ngOnInit() {
    const { rateType } = this.#assertForm();
    const { currencyPair, settlementDate } = this.original;
    this.restricted = [settlementDate];

    const constraints$ = rateType.valueChanges.pipe(
      startWith(rateType.value),
      map(getDateConstraints(this.original))
    );

    const tenorDates$ = this.tenorService
      .getTenorDatesByCurrencyPair(currencyPair)
      .pipe(tap((tenorDates) => (this.#tenorDates = tenorDates)));

    this.tenorDates$ = combineLatest([constraints$, tenorDates$]).pipe(
      map(([constraints = {}, values]) => ({ values, ...constraints }))
    );
    this.holidays$ = this.holidayService.getHolidaysByCurrencyPair(currencyPair);
  }

  onChanged({ tenor, date }: TenorDateForm) {
    if (!date || !this.#tenorDates?.length) return;

    const { settlementDate } = this.original;
    const spotDate = getSpotDate(this.#tenorDates);

    // product is determined by the far leg
    if (date < settlementDate) {
      tenor = this.#tenorDates.find(({ date }) => date === settlementDate)?.tenor;
      date = settlementDate;
    }

    const product = getProduct({ tenor, date, spotDate, isSwap: true });
    // bad type management - can't be undefined, because date is defined.
    this.#controls.product.setValue(product ?? "FxSwap");
  }

  #assertForm() {
    const { rateType, product } = this.#controls;
    assertIsDefined(rateType, "This component needs 'rateType' control present on the form!");
    assertIsDefined(product, "This component needs 'rateType' control present on the form!");

    return { rateType };
  }
}

const getDateConstraints =
  ({ rollData, settlementDate }: Original) =>
  (type: RollRateType | null): Constraints | undefined => {
    if (!type) return;

    const canRollback = rollData[`is${type}RollBackEnabled`];
    if (!canRollback) {
      return { min: settlementDate };
    }

    const canRollover = rollData[`is${type}RollOverEnabled`];
    if (!canRollover) {
      return { max: settlementDate };
    }

    return;
  };

type Constraints = { min?: string; max?: string };
type Original = Pick<OriginalExchange, "currencyPair" | "rollData" | "settlementDate">;
