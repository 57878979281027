import { Injectable } from "@angular/core";
import { debounceTime, delay, map, ReplaySubject } from "rxjs";

export interface BusyPayload {
  isBusy: boolean;
  working: string[];
}
const notBusyPayload: BusyPayload = { isBusy: false, working: [] };

@Injectable({ providedIn: "root" })
export class BusyService {
  private subject = new ReplaySubject<BusyPayload>(0);
  private busyCounter = 0;
  private working: string[] = [];
  currentState$ = this.subject.asObservable();
  isBusy$ = this.subject.pipe(
    debounceTime(0),
    map((busyState) => busyState.isBusy)
  );

  increment(message: string) {
    this.busyCounter++;
    this.working.push(message);
    this.subject.next(this.busyPayload);
  }

  decrement(message: string) {
    this.busyCounter--;
    const index = this.working.indexOf(message);
    if (index > -1) {
      this.working.splice(index, 1);
    }
    if (this.busyCounter <= 0) {
      this.subject.next(notBusyPayload);
    } else {
      this.subject.next(this.busyPayload);
    }
  }

  observeOn(...url: string[]) {
    return this.currentState$.pipe(
      map(({ working }) => url.some((x) => working.some((y) => y.startsWith(x)))),
      delay(0)
    );
  }

  private get busyPayload() {
    return { isBusy: true, working: this.working };
  }
}
