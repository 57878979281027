import { Failure } from "@core/models/transaction";
import { InitializationResponse as InitializationResult } from "@features/transaction/models";
import { MultiFxForm } from "./multifx-form";

export type InitializationRequest = MultiFxForm & { consents?: string[] };

interface InitializationWarning {
  warnings: Failure[];
}

export type InitializationResponse = InitializationResult | InitializationWarning;

export const isInitializationWarning = (
  res: InitializationResponse
): res is InitializationWarning => "warnings" in res && res.warnings.length > 0;

export interface InitializationResponseWrapper {
  response: InitializationResponse;
  resubmit: (consents: string[]) => void;
}
