import { Injectable, Type } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MenuItem } from "@core/components/nav-menu/nav-menu.model";
import { NavigationService } from "@core/navigation";
import { ModalDialogComponent } from "@features/modal/modal-dialog/modal-dialog.component";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Dialog, Modal, ModalType } from "@shared/components/modal/modal-model";
import { BehaviorSubject, combineLatest, filter, pipe, timestamp } from "rxjs";
import { ModalMenuComponent } from "./modal-menu/modal-menu.component";

@Injectable({ providedIn: "root" })
export class ModalService {
  #modalRef = new BehaviorSubject<Record<string, NgbModalRef>>({});
  modalRef$ = this.#modalRef.asObservable();

  #pageData?: Modal;

  get pageData() {
    return this.#pageData;
  }

  get hasOpenModals() {
    return this.ngbModalService.hasOpenModals();
  }

  constructor(
    private ngbModalService: NgbModal,
    private navigation: NavigationService,
    private router: Router
  ) {
    combineLatest([
      this.ngbModalService.activeInstances.pipe(timestamp()),
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        pipe(timestamp())
      ),
    ]).subscribe(([modals, router]) => {
      if (router.timestamp < modals.timestamp) return;

      this.close("menu");
      for (const modal of modals.value) {
        if (modal.componentInstance) {
          if (modal.componentInstance.skipDismissOnRoute) {
            modal.componentInstance.skipDismissOnRoute = false;
            continue;
          }
        }
        modal.close();
      }
    });
  }

  /**
   * Modal Configuration
   * @optional `scrollable: true` - Enables scroll inside modal
   * @optional `size: "xl"` - Creates extra large modal (optional)
   */
  modal({
    backdrop = "static",
    centered = true,
    component,
    data,
    fullscreen,
    key,
    keyboard = false,
    modalDialogClass = "modal-fullscreen-sm-down",
    scrollable,
    size,
    skipDismissOnRoute,
  }: ModalPayload): NgbModalRef {
    const modalRef = this.ngbModalService.open(component, {
      backdrop,
      centered,
      fullscreen,
      keyboard,
      modalDialogClass,
      scrollable,
      size,
    });
    const instance = modalRef.componentInstance;
    instance.data = data;
    instance.skipDismissOnRoute = skipDismissOnRoute;
    const state = this.#modalRef.getValue();
    key && this.#modalRef.next({ ...state, [key]: modalRef });

    return modalRef;
  }

  /**
   * Closing menu modal
   */
  close(key: ModalKey) {
    const state = { ...this.#modalRef.getValue() };
    const modalRef = state[key];
    if (modalRef) {
      modalRef.close();
      delete state[key];
      this.#modalRef.next(state);
    }
  }

  getModalRef(key: ModalKey) {
    return this.#modalRef.getValue()[key];
  }

  menu(item: MenuItem) {
    this.modal({
      component: ModalMenuComponent,
      data: item,
      modalDialogClass: "modal-menu",
      fullscreen: true,
      backdrop: false,
      skipDismissOnRoute: true,
      key: "menu",
    });
  }

  /**
   * Details Modal Configuration
   */
  details({ component, data }: ModalPayload): NgbModalRef {
    this.dismissAll();
    return this.modal({
      component,
      data,
      modalDialogClass: "modal-details",
      fullscreen: true,
      backdrop: false,
      centered: true,
      scrollable: false,
    });
  }

  /**
   * Creates a modal dialog.
   * @param data Properties of the dialog.
   */
  dialog(data: Dialog): NgbModalRef;

  /**
   * Creates a modal dialog or navigates to dialog page.
   * @param data Properties of the dialog.
   * @param type Whether the dialog should be a popup or a full-size page.
   */
  dialog<T extends ModalType>(data: Dialog, type: T, skipDismissOnRoute?: boolean): ModalOrPage<T>;

  dialog(data: Dialog, type: ModalType = "modal", skipDismissOnRoute?: boolean) {
    if (type === "modal") {
      return this.modal({ component: ModalDialogComponent, data, skipDismissOnRoute });
    }

    this.#pageData = data;
    return this.navigation.navigate(["dialog"], { skipLocationChange: true });
  }

  dismissAll() {
    this.ngbModalService.dismissAll();
    this.#modalRef.next({});
  }
}

interface ModalPayload {
  component: Type<unknown>;
  backdrop?: NgbModalOptions["backdrop"];
  centered?: boolean;
  data?: unknown;
  fullscreen?: NgbModalOptions["fullscreen"];
  key?: ModalKey;
  keyboard?: boolean;
  modalDialogClass?: string;
  scrollable?: boolean;
  size?: "sm" | "lg" | "xl";
  skipDismissOnRoute?: boolean;
}

type ModalOrPage<T extends ModalType> = T extends "modal" ? NgbModalRef : Promise<boolean>;

type ModalKey = "menu";
