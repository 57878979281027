import { Component, Input } from "@angular/core";
import { OrderSummary } from "../../models/order-form";

@Component({
  selector: "app-order-summary-detail",
  templateUrl: "summary-detail.component.html",
})
export class OrderSummaryDetailComponent {
  @Input() detail!: OrderSummary;

  get type() {
    return "order.types." + this.detail.type;
  }

  get expiration() {
    return this.detail.expiration.date + "T" + this.detail.expiration.time;
  }
}
