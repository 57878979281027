import { AbstractControl, FormArray } from "@angular/forms";

export const mapToAction = (series: FormArray, isParForward: boolean, index = 0): Action => {
  if (!isParForward) {
    return { shouldUpdate: false };
  }

  const sideControls = series.controls.map((control) => control.get("side"));
  const distinctSides = [...new Set(sideControls.map((control) => control?.value))];

  return distinctSides.length <= 1
    ? { shouldUpdate: false }
    : { shouldUpdate: true, side: sideControls[index]?.value, controls: sideControls };
};

export const updateSideControls = ({ side, controls }: Action) => {
  controls?.forEach((control) => control?.setValue(side));
};

export interface Action {
  shouldUpdate: boolean;
  side?: string;
  controls?: (AbstractControl | null)[];
}
