<ng-container *ngTemplateOutlet="stack ? stackTemplate : rowTemplate"></ng-container>

<ng-template #stackTemplate>
  <div class="flex-grow-1">
    <p class="mb-1">
      <span class="fw-light">{{ "sides." + multiDpwLegSummary.side | translate }}:</span>
      {{ multiDpwLegSummary.amount | balance: currency.code }}
      {{ "for" | translate }}
      {{ multiDpwLegSummary.calculatedAmount | balance: counterCurrency.code }}
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "SettlementDate" | translate }}:</span>
      {{ multiDpwLegSummary.settlement.date | datePko }}
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "Rate" | translate }}:</span
      >{{ multiDpwLegSummary.rate | rate | nullable }}
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "dpw.Number" | translate }}:</span>
      {{ multiDpwLegSummary.dpwNumber }}
    </p>
    <p class="mb-1">
      <span class="fw-light"></span>
    </p></div
></ng-template>

<ng-template #rowTemplate>
  <td pkoTableCell>
    {{ "sides." + multiDpwLegSummary.side | translate }}
  </td>
  <td pkoTableCell>{{ multiDpwLegSummary.settlement.date | datePko }}</td>
  <td align="end" pkoTableCell>
    {{ multiDpwLegSummary.amount | balance: currency.code }}
  </td>
  <td align="end" pkoTableCell>
    {{ multiDpwLegSummary.calculatedAmount | balance: counterCurrency.code | nullable }}
  </td>
  <td align="center" pkoTableCell>{{ multiDpwLegSummary.rate | rate | nullable }}</td>
  <td align="end" pkoTableCell>{{ multiDpwLegSummary.dpwNumber }}</td>
  <td align="end" pkoTableCell></td>
</ng-template>
