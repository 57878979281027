import { AfterViewInit, Component, ElementRef, HostBinding, Input } from "@angular/core";
import { booleanAttribute } from "@utils/misc";

@Component({
  selector: "[pko-preloader]",
  template: `<div class="pko-preloader__indicator"></div>`,
})
export class BusyIndicatorComponent implements AfterViewInit {
  @HostBinding("class.pko-preloader") class = true;
  @HostBinding("class.pko-preloader--sticky") stickyClass = false;

  #transparent = false;
  @Input() @HostBinding("class.pko-preloader--transparent") set transparent(value: unknown) {
    this.#transparent = booleanAttribute(value);
  }
  get transparent() {
    return this.#transparent;
  }

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.stickyClass = this.el.nativeElement.offsetHeight > window.innerHeight;
    }, 0);
  }
}
