import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "account",
})
export class AccountPipe implements PipeTransform {
  transform(number: string, hide = false) {
    return number && formatAccount(number, hide);
  }
}

const formatAccount = (accountNumber: string, hide: boolean): string => {
  const account = accountNumber.replace(/\s/g, "");
  const isValid = /^\d{26}$/.test(account) || /^(?:[A-Z]{2})?\d+$/.test(account);

  if (!isValid) {
    return accountNumber;
  }

  const { regex, prepare } = hide ? handler.hidden : handler.default;
  const chunks = account.match(regex);

  if (!chunks) {
    return accountNumber;
  }

  return prepare(chunks).join(" ");
};

const handler = {
  hidden: {
    regex: /^([\w\s]{2})[\w\s]+([\w\s]{4})([\w\s]{4})$/,
    prepare: (match: RegExpMatchArray) => [match[1], "(...)", match[2], match[3]],
  },
  default: {
    regex: /^([A-Z]{2})?\d{2}|\d{1,4}/g,
    prepare: (match: RegExpMatchArray) => match,
  },
};
