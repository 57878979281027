<app-form-tenor-date
  formGroupName="settlement"
  label="exchange.roll.SettlementDate"
  show="date"
  [tenorDates]="tenorDates$ | async"
  [holidays]="holidays$ | async"
  [restricted]="restricted"
  [errors]="{ restricted: 'restricted.rollDate' }"
  (changed)="onChanged($event)">
</app-form-tenor-date>
