import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import {
  BehaviorSubject,
  animationFrameScheduler,
  map,
  observeOn,
  switchMap,
  take,
  tap,
  timer,
} from "rxjs";

@Component({
  selector: "app-countdown",
  templateUrl: "countdown.component.html",
})
export class CountdownComponent {
  @HostBinding("class") class = "pko-countdown";

  @Output() finished = new EventEmitter<void>();

  @Input() timeoutText?: string;

  #message?: Message;

  @Input() set messageText(value: CountdownMessage | undefined) {
    if (!value) return;
    this.#message = typeof value === "string" ? { value, type: "reset" } : value;
  }

  get message() {
    return this.#message;
  }

  #time = new BehaviorSubject(0);

  @Input() set time(time: number) {
    if (time !== -1) {
      this.max = time;
    }

    this.#time.next(time);
  }

  // NB: using `max` separate from `time` ensures that the bar pauses along with the countdown.
  max = 0;
  countdown$ = this.#time.pipe(switchMap(this.#setTimer.bind(this)));

  #setTimer(time: number) {
    return timer(0, 1000).pipe(
      // smoother animation, although I can't see the difference
      observeOn(animationFrameScheduler),
      take(time + 1),
      map((elapsed) => time - elapsed),
      tap((remaining) => remaining === 0 && this.finished.emit())
    );
  }
}

export type CountdownMessage = string | { value: string; type: "danger" | "warning" };

/**
 * Message displayed under the countdown. Type `"reset"` inherits font color.
 */
type Message = { value: string; type: "danger" | "warning" | "reset" };
