<app-modal-container [config]="modalConfig">
  <ng-container *ngIf="survey$ | async as survey; else loading">
    <ng-container *ngIf="!survey.isError; else error">
      <app-mifid-survey-info
        *ngIf="showInfo"
        (cancelClicked)="close()"
        (nextClicked)="showSurvey()">
      </app-mifid-survey-info>
      <ng-container *ngIf="!showInfo">
        <app-mifid-question-radio
          *ngIf="survey.questions[index].type === 'Radio'"
          [question]="survey.questions[index]"
          [total]="total"
          [index]="index"
          (backClicked)="onBackClicked()"
          (nextClicked)="onNextClicked($event)">
        </app-mifid-question-radio>
        <app-mifid-question-checkbox
          *ngIf="survey.questions[index].type !== 'Radio'"
          [question]="survey.questions[index]"
          [total]="total"
          [index]="index"
          (backClicked)="onBackClicked()"
          (nextClicked)="onNextClicked($event)">
        </app-mifid-question-checkbox>
      </ng-container>
    </ng-container>
    <ng-template #error>
      <h3 class="text-center">{{ "errors.TechnicalError" | translate }}</h3>
      <div class="pko-modal__footer">
        <button class="btn btn-primary mx-sm-auto" (click)="close()">
          {{ "OK" | translate }}
        </button>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div pko-preloader></div>
  </ng-template>
</app-modal-container>
