import { Injectable } from "@angular/core";
import { mapToAction } from "@core/models/observable-action";
import { PvpRule } from "@features/accounts";
import {
  isInitializationSuccess,
  isRateFailure,
  isRateStreamable,
} from "@features/transaction/models";
import { TransactionApiService } from "@features/transaction/services/api.service";
import { PvpService } from "@features/transaction/services/pvp.service";
import { pollStatus } from "@features/transaction/utils/form";
import {
  BehaviorSubject,
  EMPTY,
  Subject,
  catchError,
  distinct,
  endWith,
  filter,
  firstValueFrom,
  map,
  merge,
  of,
  repeat,
  share,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timeout,
} from "rxjs";
import { InitializationRequest } from "../models/initialization";
import { MultiFxRateResponse, rejectedRate } from "../models/multifx-rate";
import { MultiFxStatusResponse } from "../models/multifx-summary";
import { MultiFxResponseService } from "./multifx-response.service";

@Injectable({ providedIn: "root" })
export class MultiFxService {
  #submission = new Subject<InitializationRequest>();
  submission$ = this.#submission.pipe(switchMap(this.#initialize.bind(this)), share());

  #confirmation = new Subject<string>();
  confirmation$ = this.#confirmation.pipe(switchMap((rateToken) => this.#confirm(rateToken)));

  #rejection = new Subject<string>();
  rejection$ = this.#rejection.pipe(
    distinct(),
    filter((token) => !!token),
    switchMap((token) => this.api.reject("multifx", token)),
    share(),
    takeUntil(this.#confirmation)
  );

  #token = new BehaviorSubject("");
  get token() {
    return this.#token.getValue();
  }

  constructor(
    private api: TransactionApiService,
    private responseService: MultiFxResponseService,
    private pvpService: PvpService
  ) {}

  init(request: InitializationRequest, pvpRule?: PvpRule) {
    if (this.pvpService.isPvpMessageRequired(request, pvpRule)) {
      this.pvpService.showPvpMessage(() => this.submit(request));
      return;
    }

    this.submit(request);
  }

  submit(request: InitializationRequest) {
    this.#submission.next(request);
  }

  #initialize(request: InitializationRequest) {
    const resubmit = (consents: string[]) => this.submit({ ...request, consents });

    return this.api.initialize("multifx", request).pipe(
      tap((response) => {
        this.#token.next(isInitializationSuccess(response) ? response.token : "");
        this.responseService.handleInitResponse({ response, resubmit }, request.isDpw);
      }),
      mapToAction()
    );
  }

  pollRate() {
    return this.#token.pipe(
      filter(Boolean),
      switchMap((token) => this.#pollRate(token)),
      share()
    );
  }

  reject() {
    this.#rejection.next(this.token);
  }

  /**
   * Rejects the transaction and awaits observable completion.
   * @description
   * Rejection is used when destroying the component, so normally it would cancel the network call.
   * This method ensures the completion is awaited.
   * If the transaction is already confirmed or rejected, `rejection$` won't emit,
   * which results in an error in `firstValueFrom` - hence `timeout` and `catchError`
   */
  async rejectAsync() {
    const rejection$ = this.rejection$.pipe(
      timeout(5000),
      catchError(() => of(undefined))
    );

    this.#rejection.next(this.token);
    await firstValueFrom(rejection$);
  }

  confirm(rateToken: string) {
    this.#confirmation.next(rateToken);
  }

  #confirm(rateToken: string) {
    const handleConfirmationError = () => {
      this.responseService.handleConfirmationError();
      return EMPTY;
    };

    return this.api.confirm("multifx", { rateToken, transactionToken: this.token }).pipe(
      catchError(handleConfirmationError),
      switchMap((token) => this.#pollStatus(token)),
      tap((status) => this.responseService.handleStatusResponse(status)),
      mapToAction()
    );
  }

  #pollRate(token: string) {
    return this.api.getRate<MultiFxRateResponse>("multifx", token).pipe(
      repeat({ delay: 1000 }),
      tap((response) => isRateFailure(response) && this.#token.next("")),
      tap((res) => this.responseService.handleRateError(res)),
      takeWhile((response) => !isRateFailure(response)),
      filter(isRateStreamable),
      map((res) => ({ ...res, token })),
      takeUntil(merge(this.#rejection, this.#confirmation)),
      endWith(rejectedRate)
    );
  }

  #pollStatus(token: string) {
    return this.api.getStatus<MultiFxStatusResponse>("multifx", token).pipe(pollStatus());
  }
}
