import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { TableColumn } from "@shared/components/table/table-model";
import { Observable } from "rxjs";
import { Dpw } from "../dpw.model";

@Component({
  selector: "app-dpw-list-section",
  templateUrl: "dpw-list-section.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DpwListSectionComponent {
  @Input() items$!: Observable<Dpw[]>;
  @Input() missingData!: string;
  @Input() columns!: TableColumn[];
}
