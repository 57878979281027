import { ChangeDetectionStrategy, Component, HostBinding, Input, Optional } from "@angular/core";
import { NgbActiveModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-detail-container",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div *ngTemplateOutlet="modal ? modalWrapper : content"></div>
    <ng-template #content><ng-content></ng-content></ng-template>
    <ng-template #modalWrapper>
      <div class="pko-modal__titlebar">
        <button
          class="btn pko-modal__titlebar-button pko-modal__titlebar-button--close"
          (click)="close()">
          <div class="pko-modal__titlebar-button-container">
            <svg icon name="close_nav" class="icon icon--lg"></svg>
          </div>
        </button>
        <div class="pko-modal__titlebar-title">{{ modalTitle | translate }}</div>
      </div>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-template>`,
})
export class DetailContainerComponent {
  @HostBinding("class") class = "pko-details__container";
  @Input() modal = false;
  @Input() modalTitle!: string;
  modalRef?: NgbModalRef;

  constructor(@Optional() private activeModal: NgbActiveModal) {}

  close(): void {
    this.activeModal.close();
  }
}
