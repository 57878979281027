import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivate } from "@shared/app-guard";
import { InvestmentDepositDecisionComponent } from "./components/decision.component";
import { InvestmentDepositComponent } from "./components/investment-deposit.component";

const routes: Routes = [
  {
    path: "deposit",
    children: [
      {
        path: "",
        component: InvestmentDepositComponent,
        canActivate: [AppCanActivate],
        data: { expectedRole: "InvDeposit|ProUser,LiEnabled", title: "pages.InvestmentDeposit" },
      },
      {
        path: ":token",
        component: InvestmentDepositDecisionComponent,
        canActivate: [AppCanActivate],
        data: { expectedRole: "InvDeposit|ProUser,LiEnabled", dontClearBack: true },
      },
    ],
  },
  {
    path: "history",
    loadChildren: () =>
      import("@features/investment-deposit-history/investment-deposit-history.module").then(
        (m) => m.InvestmentDepositHistoryModule
      ),
  },
  {
    path: "",
    redirectTo: "deposit",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InvestmentDepostiRoutingModule {}
