import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { mapToApiResponse } from "@core/models/observable-action-v2";
import { getFileNameWithoutExtension } from "@utils/file";
import { Subject, exhaustMap, tap } from "rxjs";
import { FileChangeEvent, FileNetworkErrorEvent, FileUploadEvent } from "./model";

@Component({
  selector: "app-input-file",
  template: `
    <span class="text-info">{{ label ?? ("upload.label" | translate) }}</span>
    <div
      app-drag-n-drop
      (dropped)="onFileSelect($event)"
      class="upload border border-{{
        !errors && !externalErrors ? 'info' : 'danger'
      }} border-3 rounded">
      <input type="file" (change)="onFileSelect($event)" class="d-none" #fileInput />

      <button
        type="button"
        class="btn btn-link btn-add btn-add--vertical my-5"
        (click)="fileInput.click()">
        <svg icon name="plus-0" class="icon icon--3xl border rounded-circle"></svg>
        {{ "upload.prompt" | translate }}
      </button>

      <ul class="list-unstyled m-0">
        <li
          *ngFor="let file of files; let i = index"
          class="d-flex justify-content-between border-top border-info mx-4 p-3">
          <div class="d-flex align-items-center">
            <svg icon name="note" class="icon icon--3xl"></svg>
            {{ file.name }}
          </div>
          <button class="btn btn-link" (click)="remove(i)">
            {{ "buttons.Delete" | translate }}
          </button>
        </li>
      </ul>
      <ng-container *ngIf="busy$ | async as uploadStatus">
        <div pko-preloader *ngIf="uploadStatus.pending" [transparent]="true"></div>
      </ng-container>
    </div>
    <span *ngIf="hint" class="text-info">{{ hint }}</span>
    <app-form-error *ngFor="let error of errors | keyvalue" [error]="error"></app-form-error>
    <app-form-error
      *ngFor="let error of externalErrors | keyvalue"
      [error]="error"></app-form-error>
  `,
  styles: [
    `
      .upload {
        /* to contain the the preloader */
        position: relative;
        border-style: dashed !important;
      }
      .upload.dragover .btn.btn-add svg {
        /* simulate button:hover */
        background: #fff;
        --color6: var(--bs-primary);
        --color7: var(--bs-white);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileComponent {
  /** Remote url to upload the files. */
  @Input() url?: string;

  /** Maximum file size allowed in bytes. */
  @Input() maxFileSize?: number;

  /** Maximum number of files that can be uploaded. */
  @Input() fileLimit?: number;

  /** Custom label to display above the component. */
  @Input() label?: string;

  /** Custom hint to display below the component. */
  @Input() hint?: string;

  @Output() uploaded = new EventEmitter<FileUploadEvent>();
  @Output() failed = new EventEmitter<FileNetworkErrorEvent>();
  @Output() changed = new EventEmitter<FileChangeEvent>();

  readonly #upload = new Subject<File>();
  readonly busy$ = this.#upload.pipe(exhaustMap((file) => this.#send(file)));

  files: File[] = [];
  errors: ValidationErrors | null = null;
  @Input() externalErrors: ValidationErrors | null = null;

  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileSelect(event: any) {
    const file: File = (event.dataTransfer ?? event.target).files[0];

    if (!file || !this.validate(file)) {
      return;
    }

    this.files.push(file);
    this.changed.emit({ file, currentFiles: this.files, action: "select" });

    if (this.url) {
      this.#upload.next(file);
    }
  }

  remove(index: number) {
    this.errors = null;
    const [file] = this.files.splice(index, 1);
    this.changed.emit({ file, currentFiles: this.files, action: "remove" });
  }

  validate(file: File) {
    this.errors = null;

    if (this.fileLimit && this.files.length >= this.fileLimit) {
      this.errors = { "file.count": { max: this.fileLimit } };
      return false;
    }

    if (file.size === 0) {
      this.errors = { "file.empty": {} };
      return false;
    }

    if (/^[a-zA-Z0-9-_]+$/.test(getFileNameWithoutExtension(file.name)) === false) {
      this.errors = { "file.name": {} };
      return false;
    }

    if (this.maxFileSize && file.size > this.maxFileSize) {
      this.errors = { "file.size": { max: formatSize(this.maxFileSize) } };
      return false;
    }

    if (this.files.some(({ name }) => name === file.name)) {
      this.errors = { "file.duplicate": { name: file.name } };
      return false;
    }

    return true;
  }

  #send(file: File) {
    this.errors = null;

    const formData = new FormData();
    formData.append("file", file, file.name);

    return this.http
      .post(this.url as string, formData, {
        headers: new HttpHeaders().set("no-json", "*"),
      })
      .pipe(
        mapToApiResponse(),
        tap(({ data }) => data && this.uploaded.emit({ file, response: data })),
        tap(({ error }) => {
          if (!error) return;
          this.failed.emit({ file, error });
          const index = this.files.findIndex(({ name }) => name === file.name);
          this.remove(index);
          this.errors = { [error.code]: error.data };
        })
      );
  }
}

function formatSize(bytes: number) {
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB"];

  if (bytes === 0) {
    return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
}
