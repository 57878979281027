<app-select
  [label]="showLabel ? label : ''"
  [items]="pairs"
  bindValue="code"
  formControlName="currencyPair"
  validate="currencyPair"
  placeholder="placeholders.CurrencyPair"
  [disableSingleValue]="disableSingleValue"
  ngDefaultControl>
  <ng-template let-item="item">
    <app-currency-pair
      flagSize="xl"
      [code]="item.code"
      [labelDivider]="labelDivider"></app-currency-pair>
  </ng-template>
</app-select>
