import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { origins } from "@core/user-tracking/tracking.model";
import { CurrencyService, filterCurrenciesByPairs } from "@features/currency";
import { LiveRateService } from "@features/transaction/components/form-rate/live-rate.service";
import { handleFormErrors } from "@features/transaction/utils/form";
import { addDays, datepickerFormat } from "@utils/time";
import { combineLatest, map, startWith } from "rxjs";
import { AlertFormService } from "../../services/alert-form.service";

@Component({
  selector: "app-form-alert",
  templateUrl: "form-alert.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LiveRateService],
})
export class AlertFormComponent implements OnInit {
  @HostBinding("class") class = "col-12 col-sm-10 position-relative";

  protected isTile = false;

  max = datepickerFormat(addDays(new Date(), 90));

  #isFormBusy$ = this.busyService.observeOn("/currency").pipe(startWith(true));

  isBusy$ = combineLatest([
    this.formService.submission$.pipe(mapToBusy(), startWith(false)),
    this.#isFormBusy$,
  ]).pipe(map(([submissionIsBusy, formIsBusy]) => submissionIsBusy || formIsBusy));

  pairs$ = this.currencyService.currencySortedPairs$.pipe(
    map((pairs) => pairs.filter(({ isActive360 }) => isActive360))
  );

  currencies$ = combineLatest([this.currencyService.allCurrencies$, this.pairs$]).pipe(
    map(([currencies, pairs]) => filterCurrenciesByPairs(currencies, pairs))
  );

  form: FormGroup;

  constructor(
    fb: FormBuilder,
    private formService: AlertFormService,
    private currencyService: CurrencyService,
    private busyService: BusyService,
    private tracker: Tracker
  ) {
    const { retain, form } = window.history.state;
    formService.reset(retain);
    form && formService.save({ ...this.formService.current, ...form });

    const initialForm = formService.current;

    this.form = fb.group({
      side: initialForm.side,
      amount: initialForm.amount,
      currency: initialForm.currency,
      counterCurrency: initialForm.counterCurrency,
      rate: initialForm.rate,
      expirationDate: initialForm.expirationDate,
      phone: fb.group(initialForm.phone, { validators: Validators.required }),
      currencyPair: null,
    });
  }

  ngOnInit(): void {
    this.isTile ||
      this.tracker.follow({ process: "alert", origin: history.state?.origin ?? "reload" });
  }

  onSubmit(modal?: boolean, componentSelector?: string) {
    this.formService.save(this.form.getRawValue());

    if (handleFormErrors(this.form, componentSelector)) {
      this.isTile && this.tracker.follow({ process: "alert", origin: origins.TILE });
      this.formService.submit(modal);
    }
  }
}
