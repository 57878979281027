<app-labeled-text-inline label="investment-deposit.InterestAmount">
  {{ standardInterestAmount | number : "1.2-2" | nullable }} {{ currency }}
</app-labeled-text-inline>

<app-labeled-text-inline label="investment-deposit.StandardInterest">
  <h4>{{ interestRate | number : "1.2-2" }}%</h4>
</app-labeled-text-inline>

<app-labeled-text-inline label="investment-deposit.InterestAmount">
  {{ maximalInterestAmount | number : "1.2-2" | nullable }} {{ currency }}
</app-labeled-text-inline>

<app-labeled-text-inline label="investment-deposit.MaxInterest">
  <h4>{{ maximalRate | number : "1.2-2" | nullable }}%</h4>
</app-labeled-text-inline>

<hr />

<app-interest-rate-info [detail]="quote" class="d-none d-md-block"></app-interest-rate-info>
