import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { DepositFormService } from "../services/deposit-form.service";

@Component({
  selector: "app-page-deposit",
  templateUrl: "deposit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositPageComponent {
  @HostBinding("class") class = "position-relative";

  constructor(private formService: DepositFormService) {
    const { retain } = window.history.state;
    this.formService.reset(retain);
  }
}
