import { NotificationsService } from "@features/notifications/notifications.service";
import {
  MessageNotificationItem,
  NotificationActionEvent,
  UserNotification,
} from "@features/notifications/notifications.model";

export class NotificationsCommonComponent {
  constructor(private _notificationsService: NotificationsService) {}

  onActionExecuted(event: NotificationActionEvent) {
    if (event.type === "message.delete") {
      this.onMessageRemoved(event.data as MessageNotificationItem);
    }
  }

  onMessageRemoved(notification: MessageNotificationItem): void {
    notification.deleted = true;
    setTimeout(() => {
      notification.hidden = true;
      this._notificationsService.removeFromCache(notification);
    }, 300);
  }

  trackByKey(_: number, { key }: UserNotification): string {
    return key;
  }
}
