import { Component, HostBinding, Input } from "@angular/core";
import { isBuying } from "@core/models/transaction";
import { UnsettledDeal } from "../../recent-history.model";

@Component({
  selector: "app-unsettled-history-item",
  templateUrl: "./unsettled-history-item.component.html",
})
export class UnsettledHistoryItemComponent {
  @HostBinding("class") class = "pko-recent-history__item";
  #deal!: UnsettledDeal;

  @Input()
  public get deal(): UnsettledDeal {
    return this.#deal;
  }
  public set deal(value: UnsettledDeal) {
    this.#deal = value;

    this.isSelling = !isBuying(value.side);
    if (this.isSelling) {
      value.amount *= -1;
    } else {
      value.counterAmount *= -1;
    }
  }

  isSelling!: boolean;
}
