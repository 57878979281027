import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { AccountsView } from "@features/accounts";

@Component({
  selector: "app-select-accounts",
  templateUrl: "select-accounts.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SelectAccountsComponent {
  @Input() vm?: AccountsView | null;
}
