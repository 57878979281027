import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { CurrencyPair } from "@features/currency";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { GraphButtonConfig, GraphPoint, IndicativeGraph } from "./graph.model";

@Injectable({ providedIn: "root" })
export class GraphService {
  data: Record<string, GraphPoint[]> = {};

  constructor(private http: HttpClient, private events: AppEventsService) {
    events.observe(() => EMPTY).subscribe(() => (this.data = {}));
  }

  getData(pair: string, tenor: string) {
    const key = `${pair}_${tenor}`;
    const data = this.data[key] || [];
    const last = data.length ? data[data.length - 1].closeTime : 0;
    if (this.#isNew(last)) {
      return of(data);
    } else {
      return this.http.get<GraphPoint[]>(`/graph/data/${pair}/${tenor}/${last}`).pipe(
        catchError(() => of([])),
        tap((result) => {
          this.data[key] = data.concat(result);
        }),
        map(() => {
          return this.data[key];
        })
      );
    }
  }

  getConfig() {
    return this.http.get<GraphButtonConfig[][]>(`/graph/config`);
  }

  getPairs() {
    return this.events.observe(
      () => this.http.get<CurrencyPair[]>(`/graph/pairs`),
      AppEvent.IndicativesChanged,
      AppEvent.CustomerChanged
    );
  }

  getGraphs(pairs: string[]): Observable<IndicativeGraph[]> {
    if (!pairs.length) {
      return of([]);
    }

    return this.http
      .get<IndicativeGraph[]>("/graph/indicatives", {
        params: new HttpParams({
          fromObject: { pairs },
        }),
      })
      .pipe(catchError(() => of([])));
  }

  #isNew(last: number) {
    return last + new Date(last).getTimezoneOffset() * 6e4 >= Date.now() - 6e4 * 5;
  }
}
