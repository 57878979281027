<ng-container *ngIf="currencies$ | async as currencies">
  <ng-container *ngIf="currencies?.length; else empty">
    <div class="position-relative w-100" *ngIf="graph$ | async as vm; else noData">
      <span *ngFor="let currency of currencies" class="pko-blotter__legend-item">{{
        currency
      }}</span>
      <div style="overflow-x: auto">
        <highcharts-chart
          [Highcharts]="vm.Highcharts"
          [constructorType]="'chart'"
          [options]="vm.chartOptions"
          [(update)]="vm.update"
          [oneToOne]="vm.oneToOneFlag"
          [runOutsideAngular]="vm.runOutsideAngularFlag"
          style="height: 400px; display: block; min-width: 100%">
        </highcharts-chart>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty>
  <div class="text-center">
    <app-alert content="blotter.Empty"></app-alert>
    <ng-container *ngTemplateOutlet="settingsButton"> </ng-container>
  </div>
</ng-template>

<ng-template #noData>
  <div class="text-center">
    <app-alert content="blotter.NoData"></app-alert>
    <ng-container *ngTemplateOutlet="settingsButton"> </ng-container>
  </div>
</ng-template>

<ng-template #settingsButton>
  <button
    class="btn btn-secondary btn-fixed btn-sm pko-recent-history__more"
    (click)="selectCurrencies()">
    {{ "blotter.Choose" | translate }}
  </button>
</ng-template>
