import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivate } from "@shared/app-guard";
import { DpwDecisionComponent } from "./components/decision/dpw-decision.component";
import { IobDecisionComponent } from "./components/decision/iob-decision.component";
import { DpwWizardComponent } from "./components/dpw-wizard.component";
import { MultiDpwDecisionComponent } from "./components/multifx/decision/decision.component";
import { DpwListComponent } from "./dpw-list.component";

const routes: Routes = [
  {
    path: "",
    component: DpwWizardComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "IobEnabled,FxSpot|FxForward", redirectionUrl: "transactions/iob/dpw" },
  },
  {
    path: "dpw",
    component: DpwListComponent,
    canActivate: [AppCanActivate],
    data: {
      expectedRole: "DPWEnabled|ProUser,DpwOn",
      redirectionUrl: "transactions/iob",
    },
  },
  {
    path: ":token",
    component: IobDecisionComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "IobEnabled,FxSpot|FxForward", dontClearBack: true },
  },
  {
    path: "dpw/:token",
    component: DpwDecisionComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "DPWEnabled,FxSpot|FxForward,DpwOn", dontClearBack: true },
  },
  {
    path: "dpw/m/:token",
    component: MultiDpwDecisionComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "DPWEnabled,FxSpot|FxForward,DpwOn", dontClearBack: true },
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DpwRoutingModule {}
