import { Injectable } from "@angular/core";
import { mapToAction } from "@core/models/observable-action";
import { PvpRule } from "@features/accounts";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { ProductType } from "@features/tenor";
import {
  isInitializationSuccess,
  isInitializationWarning,
  isRateFailure,
  isRateStreamable,
} from "@features/transaction/models";
import { TransactionApiService } from "@features/transaction/services/api.service";
import { PvpService } from "@features/transaction/services/pvp.service";
import { pollStatus } from "@features/transaction/utils/form";
import {
  BehaviorSubject,
  EMPTY,
  Subject,
  catchError,
  distinct,
  endWith,
  filter,
  firstValueFrom,
  merge,
  of,
  repeat,
  share,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timeout,
} from "rxjs";
import { ConfirmationData, ExchangeRateResponse, rejectedRate } from "../models";
import { ExchangeVariation, InitializationRequest } from "../models/initialization";
import { ExchangeResponseService } from "./exchange-response.service";

@Injectable({ providedIn: "root" })
export class ExchangeService {
  #submission = new Subject<InitializationRequest>();
  submission$ = this.#submission.pipe(
    switchMap((request) => this.#initialize(request)),
    share()
  );

  #confirmation = new Subject<[string, ConfirmationData]>();
  confirmation$ = this.#confirmation.pipe(switchMap((data) => this.#confirm(...data)));

  #rejection = new Subject<string>();
  rejection$ = this.#rejection.pipe(
    distinct(),
    filter((token) => !!token),
    switchMap((token) => this.api.reject("exchange", token)),
    share(),
    takeUntil(this.#confirmation)
  );

  #token = new BehaviorSubject("");
  get token() {
    return this.#token.getValue();
  }

  constructor(
    private api: TransactionApiService,
    private responseService: ExchangeResponseService,
    private warningCodeService: ExchangeWarningCodeService,
    private pvpService: PvpService
  ) {}

  init(request: InitializationRequest, pvpRule?: PvpRule) {
    if (this.pvpService.isPvpMessageRequired(request, pvpRule)) {
      this.pvpService.showPvpMessage(() => this.submit(request));
      return;
    }

    this.submit(request);
  }

  submit(request: InitializationRequest) {
    this.#submission.next(request);
  }

  reject() {
    this.#rejection.next(this.token);
  }

  /**
   * Rejects the transaction and awaits observable completion.
   * @description
   * Rejection is used when destroying the component, so normally it would cancel the network call.
   * This method ensures the completion is awaited.
   * If the transaction is already confirmed or rejected, `rejection$` won't emit,
   * which results in an error in `firstValueFrom` - hence `timeout` and `catchError`
   */
  async rejectAsync() {
    const rejection$ = this.rejection$.pipe(
      timeout(5000),
      catchError(() => of(undefined))
    );

    this.#rejection.next(this.token);
    await firstValueFrom(rejection$);
  }

  confirm(rateToken: string, data: ConfirmationData) {
    this.#confirmation.next([rateToken, data]);
  }

  pollRate(target: "exchange" | "swap") {
    return this.#token.pipe(
      filter(Boolean),
      switchMap((token) => this.#pollRate(target, token)),
      share(),
      takeUntil(this.#confirmation)
    );
  }

  #product!: ProductType;

  #initialize(request: InitializationRequest) {
    this.#product = request.product;
    const resubmit = (consents: string[]) => this.submit({ ...request, consents });
    const { modal, variation, ...form } = request;

    return this.api.initialize(getInitEndpoint(variation), form).pipe(
      tap((response) => {
        this.#token.next(isInitializationSuccess(response) ? response.token : "");
        this.responseService.handleInitResponse({
          type: request.product,
          response,
          variation,
          modal,
          resubmit,
        });
      }),
      mapToAction()
    );
  }

  #pollRate(target: "exchange" | "swap", token: string) {
    return this.api.getRate<ExchangeRateResponse>(target, token).pipe(
      repeat({ delay: 1000 }),
      tap((response) => isRateFailure(response) && this.#token.next("")),
      tap((response) => this.responseService.handleRateResponse(this.#product, response)),
      // stop polling when failure
      takeWhile((response) => !isRateFailure(response)),
      // display only PRICE and INTERVENTION
      filter(isRateStreamable),
      // stop polling when PRICE rate is confirmed or rejected
      takeUntil(merge(this.#rejection, this.#confirmation)),
      endWith(rejectedRate)
    );
  }

  #confirm(rateToken: string, data: ConfirmationData) {
    const handleConfirmationError = () => {
      this.responseService.handleConfirmationError(this.#product);
      return EMPTY;
    };

    return this.api.confirm("exchange", { rateToken, transactionToken: this.token }).pipe(
      catchError(handleConfirmationError),
      switchMap((token) => this.#pollStatus(token)),
      tap((status) => this.responseService.handleStatusResponse(this.#product, status, data)),
      mapToAction()
    );
  }

  #pollStatus(token: string) {
    return this.api.getStatus("exchange", token).pipe(pollStatus());
  }

  validateMifid(productType: ProductType) {
    return this.api.validateMifid("exchange", productType).pipe(
      tap((response) => {
        if (isInitializationWarning(response)) {
          this.warningCodeService.checkOutOfTargetMarket(response.warnings.map(({ code }) => code));
        } else {
          this.warningCodeService.outOfTargetMarket = false;
        }
      })
    );
  }
}

const getInitEndpoint = (variation?: ExchangeVariation) => {
  switch (variation) {
    case "close":
      return "exchange/close";
    case "roll":
      return "exchange/roll";
    case undefined:
      return "exchange";
    default:
      return variation;
  }
};
