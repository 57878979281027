import { Component, Input, OnInit } from "@angular/core";
import { DepositSummary } from "@features/deposit/models/deposit-summary";
import { difference } from "@utils/time";

@Component({
  selector: "app-summary-detail",
  templateUrl: "summary-detail.component.html",
})
export class DepositSummaryDetailComponent implements OnInit {
  @Input() detail!: DepositSummary;
  duration = 0;

  get currency() {
    return this.detail.currency.code;
  }

  ngOnInit(): void {
    const { start, end } = this.detail;
    this.duration = difference(new Date(end.date), new Date(start.date), "days");
  }
}
