import { groupBy, sortBy } from "@utils/collection";
import { toArray } from "@utils/object";

export function groupAndSort<T>(
  keySelector: (current: T) => string,
  items: T[],
  descending = true
) {
  if (!items) return [];

  return sortBy(
    (current) => current.key,
    toArray(groupBy((current) => keySelector(current), items)),
    descending
  );
}
