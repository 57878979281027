import { Component, HostBinding, OnInit } from "@angular/core";
import { deleteNotificationAnimation } from "@features/notifications/notifications.animations";
import { UserNotification } from "@features/notifications/notifications.model";
import { NotificationsService } from "@features/notifications/notifications.service";
import { GroupedListElement } from "@shared/components/grouped-list/grouped-list.component";
import { GroupedArrayElement, sortBy } from "@utils/collection";
import { toArray } from "@utils/object";
import { datepickerFormat } from "@utils/time";
import {
  Observable,
  Subject,
  from,
  map,
  merge,
  of,
  pipe,
  reduce,
  scan,
  share,
  switchMap,
  tap,
} from "rxjs";
import { NotificationsCommonComponent } from "../notifications.common.component";

@Component({
  selector: "app-notifications-simple",
  templateUrl: "./notifications.simple.component.html",
  animations: [deleteNotificationAnimation],
})
export class NotificationsSimpleComponent extends NotificationsCommonComponent implements OnInit {
  @HostBinding("class") class = "pko-notifications";

  public dates$ = this.notificationService.loadDates;
  #moreAndFinish$ = new Subject<boolean>();
  public moreAndFinish$ = this.#moreAndFinish$.pipe(share());
  public notifications$: Observable<GroupedListElement<UserNotification>[]> = of([]);

  private basicDates: string[] = [];

  constructor(private notificationService: NotificationsService) {
    super(notificationService);
  }

  ngOnInit(): void {
    this.notifications$ = merge(this.loadMore, this.loadCurrent.pipe(filterEmptyGroups())).pipe(
      scan((acc, value) => ({ ...acc, ...value }), {}),
      map(groupNotifications),
      map((groupedNotifications) => setShowEmptyGroups(groupedNotifications, this.basicDates))
    );
  }

  toggleExpansion(): void {
    this.#moreAndFinish$.next(true);
  }

  private loadMore = this.moreAndFinish$.pipe(
    switchMap(() => this.notificationService.loadNextNotifications())
  );

  private loadCurrent = this.dates$.pipe(
    tap((basicDates) => (this.basicDates = basicDates)),
    switchMap((dates) => this.notificationService.loadCurrentNotifications(dates))
  );
}

function groupNotifications(notifications: Record<string, UserNotification[]>) {
  return sortBy((current) => current.key, toArray(notifications), false);
}

function setShowEmptyGroups(
  groupedNotifications: GroupedArrayElement<UserNotification>[],
  basicDates: string[]
) {
  return groupedNotifications.map((group) => ({
    ...group,
    showEmpty: !basicDates.find((x) => x === datepickerFormat(group.key.toString())),
  }));
}

function filterEmptyGroups() {
  return pipe<
    Observable<Record<string, UserNotification[]>>,
    Observable<Record<string, UserNotification[]>>
  >(
    switchMap((result) =>
      from(Object.entries(result)).pipe(
        reduce((all, [currentKey, currentValue]) => {
          if (currentValue.length) {
            return { ...all, [currentKey]: currentValue };
          }
          return all;
        }, {})
      )
    )
  );
}
