import { Component, Input } from "@angular/core";
import { MultiDpwSummary } from "@features/dpw/dpw.model";
import { TableColumn } from "@shared/components/table/table-model";

@Component({
  selector: "app-multidpw-summary-quote",
  templateUrl: "./summary-quote.component.html",
})
export class SummaryQuoteComponent {
  @Input() quote!: MultiDpwSummary;

  readonly columns: TableColumn[] = [
    { name: "multifx.Side" },
    { name: "SettlementDate" },
    { name: "Amount", align: "end" },
    { name: "Amount", align: "end" },
    { name: "Rate", align: "center" },
    { name: "dpw.Number", align: "end" },
  ];
}
