import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { objectToHttpParams } from "@utils/http";
import { catchError, Observable, of } from "rxjs";
import { AlertForm, AlertStatusResponse, CreateAlertResponse } from "../models/alert-form";
import { AlertFilters, AlertListData } from "../models/alert-list";

@Injectable({ providedIn: "root" })
export class AlertApiService {
  constructor(private http: HttpClient) {}

  getList({ pageNumber, status }: AlertFilters): Observable<AlertListData> {
    const params = objectToHttpParams(
      { pageNumber, status: status === "All" ? null : status },
      new HttpParams()
    );

    return this.http
      .get<AlertListData>("alert/history", { params })
      .pipe(catchError(() => of({ items: [], isLastPage: true })));
  }

  cancel(id: string): Observable<boolean> {
    return this.http.post<boolean>("/alert/cancel", id).pipe(catchError(() => of(false)));
  }

  create(form: AlertForm): Observable<CreateAlertResponse> {
    return this.http
      .post<CreateAlertResponse>("alert", form)
      .pipe(catchError(() => of({ error: { code: "GENERIC" } })));
  }

  getStatus(token: string): Observable<AlertStatusResponse> {
    const options = { params: { token } };
    return this.http
      .get<AlertStatusResponse>("alert/status", options)
      .pipe(catchError(() => of({ status: { code: "GENERIC" } })));
  }
}
