import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-mifid-survey-info",
  templateUrl: "./mifid-survey-info.component.html",
})
export class MifidSurveyInfoComponent {
  @Output() cancelClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<void>();

  cancel() {
    this.cancelClicked.emit();
  }

  next() {
    this.nextClicked.emit();
  }
}
