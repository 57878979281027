import { Attribute, Component, Host, Input, OnInit } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { toFilteredPairs } from "@features/order/order-utils";
import { assertIsDefined } from "@utils/misc";
import { EMPTY, map, Observable, startWith } from "rxjs";
import { OrderFormData, OrderPair } from "../../models/order-form";

@Component({
  selector: "app-form-order-controls",
  templateUrl: "form-order-controls.component.html",
})
export class OrderFormControlsComponent implements OnInit {
  @Input() formData!: OrderFormData;

  currencyPairs$: Observable<OrderPair[]> = EMPTY;

  #group!: FormGroup;

  get group() {
    return this.#group;
  }

  // this is a dedicated component, so the context is known - parent is the top form (FormGroup)
  constructor(@Host() private parent: FormGroupDirective, @Attribute("oco") public oco?: "other") {}

  ngOnInit() {
    const { type } = this.#assertForm();

    this.currencyPairs$ = type.valueChanges.pipe(
      startWith(type.value),
      map(toFilteredPairs(this.formData.currencyPairs))
    );
  }

  #assertForm() {
    // `assertForm` is a great place to set the group if there's logic, instead of a getter.
    this.#group = this.oco ? (this.parent.form.controls.other as FormGroup) : this.parent.form;
    const { type } = this.group.controls;
    assertIsDefined(type);
    return { type };
  }
}
