import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TableColumn } from "@shared/components/table/table-model";
import { FlowDeclaration } from "../../flow-declaration.model";

@Component({
  selector: "app-flow-declaration-summary-active",
  templateUrl: "./flow-declaration-summary-active.component.html",
})
export class FlowDeclarationSummaryActiveComponent {
  @Input() declaration!: FlowDeclaration;
  @Input() showActions = true;
  @Output() updated = new EventEmitter<void>();

  readonly columns: TableColumn[] = [
    { name: "history.Currency" },
    { name: "flowDeclaration.Side" },
    { name: "Amount" },
  ];

  update() {
    this.updated.emit();
  }
}
