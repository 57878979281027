import { Component, Host, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { OrderType } from "@features/order/models/order-form";
import { getDefaultExpiration } from "@features/order/order-utils";
import { assertIsDefined } from "@utils/misc";
import { addDays, datepickerFormat } from "@utils/time";
import {
  Subscription,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  startWith,
  switchMap,
} from "rxjs";

@Component({
  selector: "app-form-expiration",
  templateUrl: "expiration.component.html",
})
export class ExpirationFormComponent implements OnInit, OnDestroy {
  #subscription?: Subscription;

  max = datepickerFormat(addDays(new Date(), 90));

  @Input() defaultTime = 0;

  constructor(@Host() private parent: ControlContainer) {}

  get group() {
    return this.parent.control as FormGroup;
  }

  ngOnInit() {
    const { date, time, type, form } = this.#assertForm();
    const setDefaultExpiration = this.#setDefaultExpiration(date, time);

    //Reset value only when date or time is not provided
    if (!date.value || !time.value) {
      setDefaultExpiration(type.value === OrderType.Limit);
    }

    const type$ = type.valueChanges.pipe(startWith(type.value));

    // `other` control gets added/removed during runtime
    const ocoType$ = form.valueChanges.pipe(
      map(({ other }) => Boolean(other)),
      distinctUntilChanged(),
      switchMap(() => toOcoType(form.controls.other))
    );

    this.#subscription = combineLatest([type$, ocoType$])
      .pipe(map(isLimit), distinctUntilChanged())
      .subscribe(setDefaultExpiration);
  }

  ngOnDestroy(): void {
    this.#subscription?.unsubscribe();
  }

  #assertForm() {
    const { controls, parent } = this.group;
    const type = parent?.get("type");

    assertIsDefined(type);

    const date = controls.date ?? new FormControl();
    const time = controls.time ?? new FormControl(null);
    time.setValidators([
      Validators.required,
      Validators.pattern(/^(([0-1][0-9])|([2][0-3])):[0-5][0-9]$/),
    ]);

    controls.date || this.group.addControl("date", date);
    controls.time || this.group.addControl("time", time);

    return { date, time, type, form: parent as FormGroup };
  }

  #setDefaultExpiration = (date: AbstractControl, time: AbstractControl) => (isLimit: boolean) => {
    const { date: dateValue, time: timeValue } = getDefaultExpiration(isLimit, this.defaultTime);

    date.enable();
    date.setValue(dateValue);
    time.setValue(timeValue);

    // with `onlySelf: false` ngbDatepicker removes the value o.O
    !isLimit && date.disable({ onlySelf: true });
  };
}

const toOcoType = (other?: AbstractControl) => {
  if (!other) return of(undefined);

  const { type } = (other as FormGroup).controls;
  return type.valueChanges.pipe(startWith(type.value));
};

/**
 * Determines if the order should be treated as a Limit order or a *Stop* order.
 * If order is OCO, both must be Limit to be considered Limit.
 */
const isLimit = (types: [OrderType, OrderType | undefined]) =>
  types.filter((type) => type !== undefined).every((type) => type === OrderType.Limit);
