import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { ItemRole } from "@features/history-common/models";
import { Dpw } from "../dpw.model";

@Component({
  selector: "[dpw-list-item]",
  templateUrl: "dpw-list-item.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DpwListItemComponent {
  @Input() dpw!: Dpw;
  @Input() role: ItemRole = "row";
}
