<app-modal-container [config]="modalConfig">
  <ng-container *ngIf="data.success">
    <h3 class="pko-title pko-title--3">{{ "mifid.result.Title" | translate }}</h3>
    <app-mifid-customer [client]="data.mifidCustomer"></app-mifid-customer>
    <p class="text-sm" [innerHTML]="'mifid.result.InvestmentGroupInfo' | translate"></p>
    <hr class="mb-3" />
    <h4 class="pko-title pko-title--4 d-flex align-items-center">
      {{ "mifid.result.SurveyResult" | translate }}
      <app-tooltip
        [content]="data.riskProfile.description"
        [translateContent]="false"></app-tooltip>
    </h4>
    <h5 class="pko-subtitle">
      {{ ("mifid.result.RiskProfileInformation" | translate) + data.riskProfile.label }}
    </h5>
    <p>{{ "mifid.result.AdequacyInformation" | translate }}</p>
    <ng-container *ngFor="let group of data.financialInstrumentGroups; let i = index">
      <app-mifid-survey-result-financial-group
        [group]="group"
        [index]="i"></app-mifid-survey-result-financial-group>
    </ng-container>
    <ng-container *ngFor="let service of data.services; let i = index">
      <app-mifid-survey-result-service
        [service]="service"
        [index]="i"></app-mifid-survey-result-service>
    </ng-container>
  </ng-container>
</app-modal-container>
