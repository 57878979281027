import { Pipe, PipeTransform } from "@angular/core";
import {
  getMaxInvestmentDepositRate,
  getMinInvestmentDepositRate,
} from "../utils/inve-depo-rate.utils";

@Pipe({
  name: "minInvDepoRate",
})
export class MinInvDepoRate implements PipeTransform {
  transform(midRate: number, deviation: number) {
    return getMinInvestmentDepositRate(midRate, deviation);
  }
}

@Pipe({
  name: "maxInvDepoRate",
})
export class MaxInvDepoRate implements PipeTransform {
  transform(midRate: number, deviation: number) {
    return getMaxInvestmentDepositRate(midRate, deviation);
  }
}

@Pipe({
  name: "stepInvDepoRate",
})
export class StepInvDepoRate implements PipeTransform {
  transform(midRate: number) {
    if (midRate < 1) {
      return 0.0001;
    }

    if (midRate < 2) {
      return 0.0005;
    }

    return 0.001;
  }
}
