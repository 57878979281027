import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { LeiService } from "@features/lei/lei.service";
import { leiValidators } from "@features/lei/lei.utils";
import { ModalService } from "@features/modal/modal.service";
import { handleFormErrors } from "@features/transaction/utils/form";
import { defaultPrimary, Dialog } from "@shared/components/modal/modal-model";
import { tap } from "rxjs";

@Component({
  selector: "app-lei-edit",
  templateUrl: "./lei-edit.component.html",
})
export class LeiEditComponent {
  @Output() saved = new EventEmitter<void>();

  form = this.fb.group({
    lei: this.fb.control("", leiValidators),
  });

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private leiService: LeiService
  ) {}

  submit() {
    this.form.controls.lei.updateValueAndValidity();
    handleFormErrors(this.form) && this.#showConfirmationDialog();
  }

  #showConfirmationDialog() {
    this.modalService.dialog({
      title: "lei.edit.Warning",
      body: "dialog.lei.ConfirmMessage",
      buttons: {
        primary: {
          text: "yesno.true",
          onClick: () => {
            this.#saveLei();
          },
        },
        secondary: {
          text: "yesno.false",
        },
      },
    });
  }

  #saveLei() {
    const { lei } = this.form.value;
    this.leiService
      .saveLei(lei)
      .pipe(tap((isSuccess) => this.#showSavingResult(isSuccess)))
      .subscribe();
  }

  #showSavingResult(isSuccess: boolean) {
    const saving = () => this.saved.emit();
    this.modalService.dialog(isSuccess ? successDialog(saving) : errorDialog);
  }
}

const successDialog = (clickFn: () => Promise<unknown> | unknown): Dialog => {
  return {
    type: "success",
    title: "dialog.lei.Saved",
    buttons: {
      primary: { text: "OK", onClick: clickFn },
    },
  };
};

const errorDialog: Dialog = {
  type: "failure",
  title: "dialog.lei.Error",
  body: "errors.TechnicalError",
  buttons: { primary: defaultPrimary },
};
