import { Dialog, ModalButtons } from "../../../shared/components/modal/modal-model";

export const getWarningDialog = (
  code: string,
  type: "transaction" | "deposit",
  data?: Record<string, string>
): Dialog => ({
  type: "warning",
  title: `dialog.warning.title.${code}` + (code === "HasSimilarDeal" ? `.${type}` : ""),
  body: {
    key: code === "HasSimilarDeal" ? code : `dialog.warning.body.${code}`,
    params: data,
  },
  buttons: getWarningButtons(code),
  style: code === "OutOfTargetMarket" ? "danger" : undefined,
});

const getWarningButtons = (code: string): ModalButtons => ({
  primary: {
    text:
      code === "OutOfTargetMarket" ? `dialog.warning.buttons.Continue.${code}` : "buttons.Continue",
    resultOnClose: true,
  },
  secondary: {
    text: code === "OutOfTargetMarket" ? `dialog.warning.buttons.Resign.${code}` : "buttons.Resign",
  },
});
