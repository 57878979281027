import { Component, Input } from "@angular/core";
import { FlowDeclaration } from "@features/flow-declaration/flow-declaration.model";
import { Modal } from "@shared/components/modal/modal-model";

@Component({
  selector: "app-temporary-flow-declaration-summary",
  templateUrl: "./temporary-flow-declaration-summary.component.html",
})
export class TemporaryFlowDeclarationSummaryComponent {
  @Input() declaration!: FlowDeclaration;

  modalConfig: Modal = {
    title: "flowDeclaration.Title",
    titleTooltip: "flowDeclaration.Tooltip",
    hideCloseButton: false,
  };
}
