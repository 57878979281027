import { Component, HostBinding, Input, Self } from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective, NgControl } from "@angular/forms";
import { ControlSize } from "@core/models/control";
import { IFormControl } from "@shared/components/controls/model";

@Component({
  selector: "app-input-text",
  templateUrl: "./input-text.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class InputTextComponent implements IFormControl {
  @HostBinding("class") class = "d-block";
  @Input("ngId") id?: string;
  @Input("validate") controlToValidate?: string;
  @Input() className?: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() size?: ControlSize;

  constructor(@Self() private ngControl: NgControl) {}

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }
}
