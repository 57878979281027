<div pko-preloader *ngIf="isBusy$ | async"></div>
<form class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="pko-grid__row">
    <app-form-side></app-form-side>
    <app-form-amount-currency-pair [pairs]="pairs$ | async" [currencies]="currencies$ | async">
    </app-form-amount-currency-pair>
    <app-form-rate tenor="SPOT"></app-form-rate>
  </div>
  <div class="pko-grid__row">
    <app-datepicker
      label="ExpirationDate"
      formControlName="expirationDate"
      class="col-12 col-sm-6"
      [max]="max"
      ngDefaultControl>
    </app-datepicker>
    <app-form-telephone formGroupName="phone" [flag]="false" class="col-12 col-sm-6">
    </app-form-telephone>
  </div>
  <app-form-footer type="alert" [back]="{ hidden: true }" [forward]="{ text: 'buttons.confirm' }">
  </app-form-footer>
</form>
