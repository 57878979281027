import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CustomerService } from "@core/customer/customer.service";
import { Customer } from "@core/models/customer.model";
import { Modal } from "@shared/components/modal/modal-model";
import { Subject, exhaustMap, map, startWith } from "rxjs";

@Component({
  template: `
    <app-modal-container [config]="modalConfig">
      <ul class="pko-customer-list">
        <li *ngFor="let customer of data" class="d-flex px-3 py-4">
          <div class="pko-customer-list__item-initials">{{ customer.name | initials : 2 }}</div>
          <div class="me-auto">
            <div class="mb-1">{{ customer.name }}</div>
            <small class="fw-light">
              <span *ngIf="customer.shortName"> {{ customer.shortName }} | </span> PID
              {{ customer.pid }}
            </small>
          </div>
          <button (click)="authorize(customer)" class="btn btn-primary" translate>
            TreasuryForm.actions.authorize
          </button>
        </li>
      </ul>
    </app-modal-container>
    <div pko-preloader transparent *ngIf="busy$ | async"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreasuryAgreementAuthPendingComponent {
  readonly #busy = new Subject<Customer>();
  readonly busy$ = this.#busy.pipe(
    exhaustMap((customer) =>
      this.customerService.set(customer, ["/profile/treasury-form"]).pipe(
        map(() => true),
        startWith(true)
      )
    )
  );

  readonly modalConfig: Modal = {
    title: "TreasuryForm.authorize.attorneyPrompt",
  };

  @Input() data!: Customer[];

  constructor(private customerService: CustomerService) {}

  authorize(customer: Customer) {
    this.#busy.next(customer);
  }
}
