import { Directive, ElementRef, HostListener, Input, Self } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";

const SPECIAL_KEYS = [
  "Enter",
  "Control",
  "Backspace",
  "Tab",
  "End",
  "Home",
  "ArrowLeft",
  "ArrowRight",
  "Delete",
];

/**
 * Ensures only numbers integers are input.
 * @throws NG0201 (No provider for NgControl found in NodeInjector) if used without a FormControl.
 */
@Directive({
  selector: "[digitsOnly]",
})
export class DigitsOnlyDirective {
  private digits = 10;
  private regex = /^[1-9][0-9]{0,9}$/;

  @Input("digits") set digitsCount(value: number) {
    if (value > 0) {
      this.digits = value;
      this.regex = new RegExp(`^[1-9][0-9]{0,${this.digits - 1}}$`);
    }
  }

  constructor(@Self() private ngControl: NgControl, private el: ElementRef<HTMLElement>) {}

  get control(): AbstractControl | null {
    return this.ngControl.control;
  }

  get element(): HTMLInputElement {
    return (
      this.el.nativeElement.tagName.toLowerCase() === "input"
        ? this.el.nativeElement
        : this.el.nativeElement.querySelector("input[type='text'")
    ) as HTMLInputElement;
  }

  @HostListener("paste", ["$event"]) onPaste(): void {
    this.runAfter();
  }

  @HostListener("drop", ["$event"]) onDrop(): void {
    this.runAfter();
  }

  @HostListener("keydown", ["$event"]) onKeyDown(event: KeyboardEvent): void {
    if (event.key === "Unidentified") {
      // Android keyboard (all non-digit keys are Unidentified, code 229)
      this.runAfter();
    } else {
      this.runBefore(event);
    }
  }

  private runBefore(event: KeyboardEvent) {
    if (this.shouldIgnore(event)) {
      return;
    }

    const element = this.element;
    const { value } = element;

    const start = element?.selectionStart ?? 0;
    const end = element?.selectionEnd ?? 0;
    const next = [value.slice(0, start), event.key, value.slice(end)].join("");

    if (!this.validate(next)) {
      event.preventDefault();
    }
  }

  private runAfter() {
    const previous: string = this.control?.value;

    setTimeout(() => {
      const current: string = this.control?.value;

      if (!this.validate(current)) {
        /*
         * Some Android devices seem to be retaining input history, so that
         * programmatically updated value is pushed to the front of the element value
         * e.g. 12r -> set back to 12 -> press 3 -> `current` is 1212r3
         * e.g. 12r -> set back to 12 -> press Backspace -> `current` is 1212
         * And I can't say how or when it retains these values, because sometimes it gets cleared.
         *
         * So, the easy solution is to just lose focus, which apparently resets the history...
         */
        this.control?.setValue(previous, { emitEvent: false, onlySelf: true });
        this.element.blur();
        this.element.focus();
      }
    });
  }

  private shouldIgnore(event: KeyboardEvent): boolean {
    return (
      // Allow specified special keys
      SPECIAL_KEYS.includes(event.key) ||
      // Allow copy, paste, cut
      ((event.ctrlKey || event.metaKey) &&
        (["a", "c", "x"].includes(event.key) || event.key === "v"))
    );
  }

  private validate(value: string): boolean {
    return value === "" || this.regex.test(String(value));
  }
}
