import { Component, HostBinding, Input } from "@angular/core";
import { IconSize } from "@core/models/control";

@Component({
  selector: "app-tooltip",
  templateUrl: "tooltip.component.html",
})
export class TooltipComponent {
  @HostBinding("class") class = "pko-tooltip";
  @Input() content!: string;
  @Input() contentData?: unknown;
  @Input() icon = "infotip";
  @Input() translateContent = true;
  @Input() placement: "top" | "bottom" = "top";
  @Input() size?: IconSize;
}
