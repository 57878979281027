import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ActionItem } from "@core/menus/action-item";
import { Currency } from "@features/currency";
import { handleFormErrors } from "@features/transaction/utils/form";
import { TableColumn } from "@shared/components/table/table-model";
import { mapSideOptions } from "@shared/utils/side";
import { getMax } from "@utils/time";
import { Subject, takeUntil } from "rxjs";
import { FlowDeclaration, FlowDeclarationItem } from "../../flow-declaration.model";

@Component({
  selector: "app-flow-declaration-edit",
  templateUrl: "./flow-declaration-edit.component.html",
})
export class FlowDeclarationEditComponent implements OnInit, OnDestroy {
  @Input() currencies!: Currency[];
  @Input() declaration!: FlowDeclaration;
  @Input() allowRefuse = false;
  @Output() canceled = new EventEmitter<void>();
  @Output() saved = new EventEmitter<FlowDeclaration>();

  amounts = [
    "< 10 000",
    "10 000 - 100 000",
    "100 000 - 1 000 000",
    "1 000 000 - 10 000 000",
    "10 000 000 - 100 000 000",
    "> 100 000 000",
  ];

  readonly columns: TableColumn[] = [
    { name: "history.Currency", width: 10 },
    { name: "flowDeclaration.Side", width: 20 },
    { name: "Amount" },
    { name: "", actionMenu: true, width: 5 },
  ];

  now!: Date;
  sides = mapSideOptions();
  form = this.fb.group({
    flows: this.fb.array([]),
    refuseToAnswer: false,
  });

  #lastId = 0;
  #destroy = new Subject<void>();

  get flows() {
    return this.form.controls.flows as FormArray;
  }

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.now = getMax(new Date(), this.declaration.dateFrom)!;
    this.declaration.flowsData
      .map((x, index) => this.#mapToFormGroup(x, index))
      .forEach((x) => this.flows.push(x));
    this.form.controls.refuseToAnswer.setValue(this.allowRefuse && this.declaration.refuseToAnswer);
    this.#lastId = this.declaration.flowsData.length;
    if (this.flows.controls.length === 0) {
      this.addNewFlow();
    }

    this.form.controls.refuseToAnswer.valueChanges
      .pipe(takeUntil(this.#destroy))
      .subscribe((value) => {
        value ? this.flows.disable() : this.flows.enable();
      });
  }

  ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
  }

  menu(rowIndex: number): ActionItem[] {
    return [
      {
        text: "buttons.Delete",
        callback: () => this.remove(rowIndex),
      },
    ];
  }

  addNewFlow() {
    const newForm = this.fb.group({
      id: ++this.#lastId,
      currency: [this.currencies[0]?.code, Validators.required],
      side: ["Buy", Validators.required],
      amount: ["< 10 000", Validators.required],
    });

    newForm.setValidators(this.#uniqueFlowValidator);

    this.flows.push(newForm);
    this.cdr.detectChanges();
  }

  remove(rowIndex: number) {
    this.flows.removeAt(rowIndex);
    if (this.flows.controls.length === 0) {
      this.addNewFlow();
    }
  }

  save() {
    this.flows.controls.forEach((x) => x.updateValueAndValidity());
    if (!handleFormErrors(this.form)) return;
    const declaration = this.form.controls.refuseToAnswer.value
      ? {
          dateFrom: new Date(),
          dateTo: this.declaration.dateTo,
          refuseToAnswer: true,
          flowsData: [],
          isActual: true,
        }
      : {
          dateFrom: new Date(),
          dateTo: this.declaration.dateTo,
          refuseToAnswer: false,
          flowsData: this.flows.value,
          isActual: true,
        };
    this.saved.emit(declaration);
  }

  cancel() {
    this.canceled.emit();
  }

  #mapToFormGroup = (flow: FlowDeclarationItem, index: number): FormGroup => {
    const group = this.fb.group({
      id: index,
      currency: [flow.currency, Validators.required],
      side: [flow.side, Validators.required],
      amount: [flow.amount, Validators.required],
    });
    group.setValidators(this.#uniqueFlowValidator);
    return group;
  };

  #uniqueFlowValidator = (form: AbstractControl): ValidationErrors | null => {
    const { currency, side, id } = form.value;
    const duplicated = this.flows.controls
      .map((control) => control.value)
      .filter((flow) => flow.id < id)
      .find((flow) => flow.currency === currency && flow.side === side);
    return !duplicated ? null : { "flowDeclaration.AlreadyExists": true };
  };
}
