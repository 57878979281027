<ul ngbNav #nav="ngbNav" class="pko-tabs nav-tabs" role="tablist" [(activeId)]="listType">
  <li class="pko-tabs__item" role="presentation" ngbNavItem="news">
    <a class="pko-tabs__link" role="tab" ngbNavLink>{{ "marketNews.tab.News" | translate }}</a>
    <ng-template ngbNavContent><app-market-news></app-market-news> </ng-template>
  </li>
  <li class="pko-tabs__item" role="presentation" ngbNavItem="analysis">
    <a class="pko-tabs__link" role="tab" ngbNavLink>{{ "marketNews.tab.Analysis" | translate }}</a>
    <ng-template ngbNavContent><app-analysis></app-analysis> </ng-template>
  </li>
</ul>

<div class="pko-tabs__panel" role="tabpanel" [ngbNavOutlet]="nav"></div>
