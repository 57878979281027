import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "@core/session";

@Component({
  selector: "app-lei-view",
  templateUrl: "./lei-view.component.html",
})
export class LeiViewComponent {
  form: FormGroup;

  constructor(fb: FormBuilder, userService: UserService) {
    const lei = userService.userData.leiMessage.lei?.code;
    this.form = fb.group({
      lei: fb.control({ value: lei, disabled: true }),
    });
  }
}
