import { NgModule } from "@angular/core";
import { DepositCommonModule } from "@features/deposit-common/deposit-common.module";
import { TransactionModule } from "@features/transaction/transaction.module";
import { DepositDecisionComponent } from "./components/decision/decision.component";
import { DepositPageComponent } from "./components/deposit.component";
import { FormDepositTileComponent } from "./components/form-deposit-tile/form-deposit-tile.component";
import { DepositFormComponent } from "./components/form-deposit/form-deposit.component";
import { DepositSummaryDetailComponent } from "./components/summary-detail/summary-detail.component";
import { DepositSummaryQuoteComponent } from "./components/summary-quote/summary-quote.component";
import { DepositRoutingModule } from "./deposit-routing.module";

@NgModule({
  imports: [TransactionModule, DepositCommonModule, DepositRoutingModule],
  exports: [FormDepositTileComponent],
  declarations: [
    DepositPageComponent,
    DepositFormComponent,
    DepositDecisionComponent,
    DepositSummaryDetailComponent,
    DepositSummaryQuoteComponent,
    FormDepositTileComponent,
  ],
  providers: [],
})
export class DepositModule {}
