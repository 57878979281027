/**
 * Calculates the length of the number. Does not include the fraction digits.
 * @param value A positive number.
 * @returns Length of the value.
 */
export const length = (value: number) => Math.ceil(Math.log10(value + 1));

export function floor(x: number, precision: number): number {
  const pow = Math.pow(10, precision);
  return Math.floor(x * pow) / pow;
}

export function ceil(x: number, precision: number): number {
  const pow = Math.pow(10, precision);
  return Math.ceil(x * pow) / pow;
}

export function round(x: number, precision: number): number {
  const pow = Math.pow(10, precision);
  return Math.round(x * pow) / pow;
}

export function hasValue(value?: number | null) {
  return !!value || value === 0;
}

export const parseNumber = (value: string) =>
  typeof value === "string" ? Number.parseFloat(value.replace(",", ".")) : 0;
