<app-form-control [label]="hideLabel ? '' : 'Amount'" validate="amount" [inputGroup]="true">
  <app-input-number
    formControlName="amount"
    placeholder="placeholders.Amount"
    [decimals]="decimals$ | async"
    ngDefaultControl>
  </app-input-number>
  <app-select-flag formControlName="currency" [items]="currencies" ngDefaultControl>
  </app-select-flag>
</app-form-control>
