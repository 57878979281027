import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subject, takeUntil, tap } from "rxjs";
import { FlowDeclaration } from "../../flow-declaration.model";
import { FlowDeclarationService } from "../../flow-declaratoin.service";

@Component({
  selector: "app-flow-declaration-summary-inactive",
  templateUrl: "./flow-declaration-summary-inactive.component.html",
})
export class FlowDeclarationSummaryInactiveComponent implements OnDestroy {
  @Input() declaration!: FlowDeclaration;
  @Input() allowRefuse = true;
  @Input() showActions = true;
  @Output() created = new EventEmitter<void>();
  @Output() refused = new EventEmitter<void>();

  #destroy$ = new Subject<void>();

  constructor(private service: FlowDeclarationService) {}

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  new() {
    this.created.emit();
  }

  refuse() {
    const declaration = {
      dateFrom: new Date(),
      dateTo: this.declaration.dateTo,
      refuseToAnswer: true,
      flowsData: [],
      isActual: true,
    };
    this.service
      .save(declaration)
      .pipe(
        tap(() => this.refused.emit()),
        takeUntil(this.#destroy$)
      )
      .subscribe();
  }
}
