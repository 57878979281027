import {
  Component,
  ContentChild,
  EventEmitter,
  Host,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges,
  SkipSelf,
  TemplateRef,
} from "@angular/core";
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgControl,
} from "@angular/forms";
import { ControlSize } from "@core/models/control";
import { NgSelectComponent } from "@ng-select/ng-select";
import { IFormControl } from "@shared/components/controls/model";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SelectComponent implements IFormControl, OnChanges, OnInit {
  @ContentChild(TemplateRef) template: TemplateRef<never> | undefined;

  @Input("ngId") labelForId?: string;
  @Input("validate") controlToValidate?: string;
  /**
   * Item's property to use for option text. In ng-select's nomenclature, this text is called "label"
   * which is different from this control's label.
   */
  @Input() bindLabel?: string;
  @Input() bindValue?: string;
  @Input() className?: string;
  @Input() clearable = false;
  @Input() items: NgSelectComponent["items"] = [];
  @Input() label?: string | undefined;
  @Input() tooltip = "";
  @Input() translateLabel = true;
  /**
   * You can put a prefix to translate the option text. So it works with `bindLabel` property.
   * But `translateLabel` means "translate control's label".
   * @warning
   * Do not use this with a custom template.
   */
  @Input() translateText: string | false = false;
  @Input() multiple = false;
  @Input() placeholder?: string;
  @Input() searchable = false;
  @Input() size?: ControlSize;
  @Input() disableSingleValue = true;
  disableSingleValueSet = false;

  @Output() selected = new EventEmitter();

  constructor(
    @Host() @SkipSelf() private parent: ControlContainer,
    @Self() private controlDir: NgControl
  ) {}

  get group() {
    return this.parent.control as FormGroup;
  }

  get control() {
    return this.controlDir.control as FormControl;
  }

  ngOnInit(): void {
    this.disableSingleValueSet = true;

    this.ngOnChanges({
      items: {
        currentValue: this.items,
        previousValue: [],
        isFirstChange: () => true,
        firstChange: true,
      },
    });
  }

  ngOnChanges({ items }: SimpleChanges) {
    if (!this.control || !items) return;

    if (!this.disableSingleValueSet) return;

    if (!this.disableSingleValue) {
      return;
    }

    const { currentValue, previousValue } = items;

    if (currentValue?.length === 1 && this.control.value) {
      this.control.disable({ onlySelf: true });
    } else if (previousValue?.length === 1 && currentValue?.length !== 1) {
      this.control.enable({ onlySelf: true });
    }
  }
}
