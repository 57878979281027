import { Side } from "@core/models/transaction";
import { defaultPrimary, Dialog } from "@shared/components/modal/modal-model";
import { formatNumber } from "@shared/utils/format";

interface HistoryItem {
  side: Side;
  rate: number;
  amount: number;
  currency: string;
  currencyPair: string;
}
const buttons = { primary: defaultPrimary };

export const constructCancellationDialog = (
  { amount, currency, currencyPair, rate, side }: HistoryItem,
  type: "Alert" | "Order"
): Dialog => {
  const formattedAmount = formatNumber(amount, { style: "currency", code: currency, decimals: 2 });
  const formattedRate = formatNumber(rate, { style: "pair", code: currencyPair, decimals: 4 });

  return {
    title: `modals.Cancel.${type}.Title`,
    body: {
      key: `modals.Cancel.Common.${side}`,
      params: { amount: formattedAmount, rate: formattedRate },
    },
    buttons: {
      secondary: { text: "buttons.Resign" },
      primary: { text: "buttons.Continue", resultOnClose: true },
    },
  };
};

export const successDialog = (
  type: "Alert" | "Order" | "Deposit",
  onClick: () => void
): Dialog => ({
  type: "success",
  title: `modals.Cancel.${type}.Success`,
  buttons: { primary: { text: "OK", onClick } },
});

/** Only spot desk order for now. If that changes, convert this into function like the rest. */
export const infoDialog: Dialog = {
  type: "info",
  title: "modals.Cancel.Order.SpotDesk.Success",
  buttons,
};

export const errorDialog = (type: "Alert" | "Order"): Dialog => ({
  type: "failure",
  title: `modals.Cancel.${type}.Failure`,
  buttons,
});
