import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfigStore } from "@core/config";
import { mapToBusy } from "@core/models/observable-action";
import { reverseSide } from "@core/models/transaction";
import { NavigationService } from "@core/navigation";
import { Tracker } from "@core/user-tracking/tracker.service";
import { mapToCollateralForm } from "@features/collateral";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { TenorDate, TenorService, getProduct, getSpotDate } from "@features/tenor";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Observable, map, of, shareReplay, switchMap, tap } from "rxjs";
import { CloseExchangeForm, OriginalExchange } from "../../models";
import { ExchangeFormService, ExchangeResponseService, ExchangeService } from "../../services";
import { getActionForm } from "../action-utils";

@Component({
  selector: "app-form-exchange-close",
  templateUrl: "form-exchange-close.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseExchangeFormComponent {
  readonly original: OriginalExchange;
  readonly link;
  readonly form: FormGroup;
  readonly isForward$: Observable<boolean>;
  readonly mifidValidated$: Observable<boolean>;

  isBusy$ = this.formService.submission$.pipe(mapToBusy());

  back = () => this.navigationService.back();

  constructor(
    { config }: ConfigStore,
    fb: FormBuilder,
    tenorService: TenorService,
    private formService: ExchangeFormService,
    private exchangeService: ExchangeService,
    private responseService: ExchangeResponseService,
    public warningService: ExchangeWarningCodeService,
    private navigationService: NavigationService,
    private tracker: Tracker,
    private router: Router
  ) {
    const { form, original } = getActionForm(
      window.history.state,
      formService.current as CloseExchangeForm as CloseForm,
      defaultFormMapper
    );

    this.tracker.follow(
      { process: "exchange", origin: history.state?.origin ?? "reload" },
      { type: "close" }
    );

    this.navigationService.setupBack(() =>
      this.router.navigate([`/history/exchange/fx/${original.id}`])
    );

    const product = new FormControl({ disabled: true });

    this.original = original;
    this.link = config.links.forwardRisk;
    this.form = fb.group({
      amount: form.amount,
      pvp: { value: form.pvp, disabled: true },
      product,
    });

    this.isForward$ = tenorService.getTenorDatesByCurrencyPair(original.currencyPair).pipe(
      map(toProduct(original.settlementDate)),
      tap((type) => product.setValue(type)),
      map((type) => type === "FxForw"),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.mifidValidated$ = this.isForward$.pipe(
      switchMap((isForward) => {
        if (isForward) {
          return this.exchangeService.validateMifid("FxForw").pipe(
            tap((response) =>
              this.responseService.handleMifidResponse(response, "close", this.back)
            ),
            map(() => true)
          );
        } else {
          this.warningService.outOfTargetMarket = false;
          return of(true);
        }
      })
    );
  }

  onSubmit() {
    if (!handleFormErrors(this.form)) return;

    const form = mapToForm(this.original, this.form.getRawValue());
    this.formService.reset();
    this.formService.save(form);
    this.formService.submit({ variation: "close" });
  }
}

const mapToForm = (
  original: OriginalExchange,
  form: Pick<CloseForm, "amount" | "product">
): CloseExchangeForm => ({
  original,
  id: original.id,
  far: original.far,
  side: reverseSide(original.side),
  currency: original.currency,
  counterCurrency: original.counterCurrency,
  currencyPair: original.currencyPair,
  netSettlement: original.netSettlement,
  account: original.account?.number,
  counterAccount: original.counterAccount.number,
  settlement: { date: original.settlementDate },
  collateral: mapToCollateralForm(original.collateral) ?? { type: "AmountBlock" },
  ...form,
});

/**
 * Actual properties used by this component. The full CloseExchangeForm is used on decision screen.
 */
type CloseForm = Pick<CloseExchangeForm, "amount" | "product" | "pvp" | "original">;

const defaultFormMapper = ({ pvp }: OriginalExchange) => ({ amount: null, pvp });

const toProduct = (settlementDate: string) => (tenorDates: TenorDate[]) => {
  const tenorDate = tenorDates.find(({ date }) => date === settlementDate);
  const { tenor, date = settlementDate } = tenorDate ?? {};
  return getProduct({ tenor, date, spotDate: getSpotDate(tenorDates) });
};
