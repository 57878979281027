import { Directive, HostListener } from "@angular/core";
import { Router } from "@angular/router";

/**
 * Sometimes we need to add an internal routerLink from the translation file as innerHtml.
 * This, however, does not create a routerLink directive.
 * This directive catches click events on regular <a href> elements that have "routerLink" class
 * and navigates using the router.
 */
@Directive({ selector: "[pseudoRouter]" })
export class PseudoRouterDirective {
  constructor(private router: Router) {}

  @HostListener("document:click", ["$event"])
  public handleClick(event: Event) {
    if (!(event.target instanceof HTMLAnchorElement)) return;
    if (event.target.className !== "routerLink") return;

    event.preventDefault();
    const route = event.target.getAttribute("href");
    if (route) {
      this.router.navigateByUrl(route);
    }
  }
}
