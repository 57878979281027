import { Sneaky } from "@core/models";
import { Failure, Side } from "@core/models/transaction";
import { Account } from "@features/accounts";
import { Collateral } from "@features/collateral";
import { RollData } from "@features/exchange/models";
import {
  defaultBoundary,
  Filters,
  SettlementStatus,
  View360Details,
} from "@features/history-common/models";
import { ProductType } from "@features/tenor";
import { Observable } from "rxjs";

export interface ExchangeHistoryItem {
  id: string;
  type: string;
  displayDetails: boolean;
  details$: Observable<BaseExchangeDetail>;
  transactionDate: string;
  side: Side;
  subtype: string;
  amount: number;
  counterAmount: number;
  actualPricing: number;
  actualPricingCurrency: string;
  pricingDate?: Date;
  currency: string;
  counterCurrency: string;
  rate: number;
  status: SettlementStatus;
  settlementDate: Date;
  openBalance: number;
  openBalanceCurrency: string;
  linkedDealId?: number;
}

export interface BaseExchangeDetail extends View360Details {
  id: string;
  documentId: string;
  currencyPair: string;
  collateral?: Collateral;
  transactionDate: Date;
  product: ProductType;
  subtype: string;
  linkedDeals: ExchangeHistoryItem[];
  pvp: Sneaky<boolean>;
}

export interface ExchangeDetails extends BaseExchangeDetail {
  amount: number;
  attorney: string;
  account: Account;
  currency: string;
  settlementDate: string;
  counterAccount: Account;
  counterAmount: number;
  counterCurrency: string;
  status: SettlementStatus;
  rate: number;
  side: Side;
  orderId?: number;
  linkedTransactionNr: string;
  netSettlement: boolean;
  referenceRate: string;
  ndfModifiable: boolean;
  netSettlementAmount?: number;
  netSettlementCurrency: string;
  openBalance: number;
  closeVisible: boolean;
  rollVisible: boolean;
  rollData: RollData;
  dpwReference?: string;

  openAmountFTOOne?: number;
  openAmountCurrencyFTOOne: string;
  openAmountFTOTwo?: number;
  openAmountCurrencyFTOTwo: string;
  firstFixingARF?: Date;
  finalFixingARF?: Date;
  buyPricingAmount?: number;
  buyPricingCurrency: string;
  sellPricingAmount?: number;
  sellPricingCurrency: string;
  pricingAmount?: number;
  pricingCurrency: string;
  pricingDate?: Date;
  settlementType: string;
}

export interface SwapDetails extends BaseExchangeDetail {
  status: SettlementStatus;
  attorney: string;
  nearLeg: SwapLeg;
  farLeg: SwapLeg;
  linkedTransactionNr: string;
  pricingAmount?: number;
  pricingCurrency: string;
  pricingDate?: Date;
}

export interface SwapLeg {
  amount: number;
  currency: string;
  counterAmount: number;
  counterCurrency: string;
  status?: string;
  side: Side;
  settlementDate: string;
  rate: number;
  account: Account;
  counterAccount: Account;
  openBalance: number;
  closeVisible: boolean;
  rollVisible: boolean;
  rollData: RollData;

  buyPricingAmount?: number;
  buyPricingCurrency: string;
  sellPricingAmount?: number;
  sellPricingCurrency: string;
}

export const defaultExchangeFilters: Partial<Filters> = {
  pageNumber: 1,
  productTypes: ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
  subproduct: "All",
  type: "All",
  settlementStatus: "All",
  settlementDate: defaultBoundary,
  rate: defaultBoundary,
  amount: defaultBoundary,
  dealDate: defaultBoundary,
  id: null,
};

export const defaultOpenBalanceFilters: Partial<Filters> = {
  pageNumber: 1,
  productTypes: ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
  type: "All",
  settlementStatus: "Unsettled",
  settlementDate: defaultBoundary,
  rate: defaultBoundary,
  amount: defaultBoundary,
  dealDate: defaultBoundary,
};

export const defaultOtherOperationsFilters: Partial<Filters> = {
  pageNumber: 1,
  productTypes: [],
  subproduct: "All",
  type: "All",
  settlementStatus: "All",
  settlementDate: defaultBoundary,
  rate: defaultBoundary,
  amount: defaultBoundary,
  dealDate: defaultBoundary,
  id: null,
};

export const defaultMonthlyPricingsFilters: Partial<Filters> = {
  pageNumber: 1,
  productTypes: [],
  subproduct: "All",
  type: "All",
  settlementStatus: "All",
  settlementDate: defaultBoundary,
  rate: defaultBoundary,
  amount: defaultBoundary,
  dealDate: defaultBoundary,
  pricing: defaultBoundary,
  id: null,
};

export const isSimple = (details?: BaseExchangeDetail | null): details is ExchangeDetails =>
  !!(details as ExchangeDetails).side;

export const isSwap = (details?: BaseExchangeDetail | null): details is SwapDetails =>
  !!(details as SwapDetails)?.nearLeg;

export interface ChangeNdfRequest {
  id: number;
  accountNumber: string;
  ndf: boolean;
}

export interface ChangeNdfResponse {
  success: boolean;
  failure: Failure;
}

export type ExchangeType = "regular" | "openBalance" | "settlement";
