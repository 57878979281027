import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { ProlongSessionComponent } from "./prolong-session.component";

@NgModule({
  imports: [TranslateModule, SharedModule],
  declarations: [ProlongSessionComponent],
  exports: [ProlongSessionComponent],
})
export class ProlongSessionModule {}
