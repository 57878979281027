<app-modal-container
  [config]="modalConfig"
  [formGroup]="form"
  *ngIf="selectablePairs$ | async as selectablePairs">
  <app-groups-selector
    selectedTitle="blotter.SelectedTitle"
    selectedSubtitle="blotter.SelectedSubtitle"
    unSelectedTitle="blotter.UnselectedTitle"
    unSelectedSubtitle="blotter.UnselectedSubtitle"
    [selectedGroup]="selectedPairsGroup"
    [unSelectedGroup]="notSelectedPairsGroup"
    [elements]="selectablePairs"
    [componentType]="itemType"
    placeholder="blotter.SearchCurrency"
    [maxSelected]="5"
    [minSelected]="0">
  </app-groups-selector>
</app-modal-container>
<div pko-preloader *ngIf="isBusy$ | async"></div>
