import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IListItem } from "@features/history/models/view360-list";
import { objectToHttpParams } from "@utils/http";
import { GroupedHistoryItem, HistoryItem, PageData } from "../models";

@Injectable({ providedIn: "root" })
export class HistoryApiService {
  constructor(private http: HttpClient) {}

  getList<T extends HistoryItem | GroupedHistoryItem | IListItem>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filters: any,
    target: "" | "exchange" | "deposit" | "order-" | "investment-deposit-" = "",
    subType: "" | "openbalance" | "settlements" | "settlementsToday" | "pricings" = ""
  ) {
    const params = objectToHttpParams(filters, new HttpParams());

    const url = subType !== "" ? `/${target}history/${subType}` : `/${target}history`;

    return this.http.get<PageData<T>>(url, { params });
  }

  getDetails<T>(id: string, pricingsDate?: Date) {
    return this.http.get<T>(["/history/review", id, pricingsDate].filter(Boolean).join("/"));
  }
}
