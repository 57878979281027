<ng-container *ngTemplateOutlet="role === 'stack' ? stack : row"></ng-container>

<ng-template #row>
  <td pkoTableCell>
    <app-input-check
      [formControlName]="dpw.ssrId"
      ngDefaultControl
      *ngIf="dpw.isActive"></app-input-check>
  </td>
  <td pkoTableCell>
    {{ "dpw.type." + dpw.type | translate }} <br />
    <span class="text-muted text-sm">{{ dpw.ssrId }}</span>
  </td>
  <td align="center" pkoTableCell>
    <app-flag [icon]="dpw.currency" [label]="dpw.amount | balance : dpw.currency"> </app-flag>
  </td>
  <td align="center" pkoTableCell>
    {{ dpw.currencyPair | pair }}
  </td>
  <td align="end" pkoTableCell>
    {{ dpw.expireTime | datePko : "minute" }}
  </td>
</ng-template>

<ng-template #stack>
  <div class="d-flex flex-grow-1">
    <div *ngIf="dpw.isActive">
      <app-input-check [formControlName]="dpw.ssrId" ngDefaultControl></app-input-check>
    </div>
    <div class="p-3">
      <p class="mb-1">
        <span class="fw-light">{{ "dpw.type." + dpw.type | translate }} </span>:
        <span
          >{{ dpw.amount | balance : dpw.currency }} {{ "for" | translate }}
          {{ dpw.counterCurrency }}</span
        >
      </p>
      <p class="mb-1" class="fw-light">
        {{ dpw.ssrId }}
      </p>
      <p class="mb-0">
        <span class="fw-light">{{ "dpw.list.ValidUntil" | translate }}:</span>
        {{ dpw.expireTime | datePko : "minute" }}
      </p>
    </div>
  </div>
</ng-template>
