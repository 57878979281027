import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective, NgControl } from "@angular/forms";
import { map, startWith } from "rxjs";
import { ValidationErrorOverrides } from "../controls";

@Component({
  selector: "app-month-picker",
  template: `
    <ng-container *ngIf="vm$ | async as vm">
      <input
        type="text"
        placeholder="MM/YY"
        class="pko-month-picker__input form-control"
        (focus)="onFocus()"
        [value]="vm.displayValue" />
      <input
        hidden
        type="text"
        [formControl]="control"
        class="pko-month-picker__input form-control"
        (focus)="onFocus()"
        ngDefaultControl />
      <div
        #container
        class="pko-month-picker__container"
        tabindex="-1"
        (blur)="onBlur($event)"
        [hidden]="!focused">
        <div class="pko-month-picker__month">
          <button class="btn btn-link pko-month-picker__up" tabindex="-1" (click)="decreaseMonth()">
            <svg icon name="chevron-right" class="icon--xl"></svg>
          </button>
          <div class="pko-month-picker__scrolling-val">
            <span>{{ vm.prevMonth | padStart : { length: 2, pad: "0" } }}</span>
            <span>{{ month | padStart : { length: 2, pad: "0" } }}</span>
            <span>{{ vm.nextMonth | padStart : { length: 2, pad: "0" } }}</span>
          </div>
          <button
            class="btn btn-link pko-month-picker__down"
            tabindex="-1"
            (click)="increaseMonth()">
            <svg icon name="chevron-right" class="icon--xl"></svg>
          </button>
        </div>
        <div class="pko-month-picker__year">
          <button class="btn btn-link pko-month-picker__up" tabindex="-1" (click)="decreaseYear()">
            <svg icon name="chevron-right" class="icon--xl"></svg>
          </button>
          <div class="pko-month-picker__scrolling-val">
            <span [class.pko-month-picker__invalid]="year === minYear">{{
              year - 1 | padStart : { length: 4, pad: "0" }
            }}</span>
            <span>{{ year | padStart : { length: 4, pad: "0" } }}</span>
            <span [class.pko-month-picker__invalid]="year === maxYear">{{
              year + 1 | padStart : { length: 4, pad: "0" }
            }}</span>
          </div>
          <button
            class="btn btn-link pko-month-picker__down"
            tabindex="-1"
            (click)="increaseYear()">
            <svg icon name="chevron-right" class="icon--xl"></svg>
          </button>
        </div>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MonthPickerComponent implements AfterViewChecked, OnInit {
  constructor(@Self() private controlDir: NgControl) {}
  @Input("errors") errorOverrides?: ValidationErrorOverrides;

  @HostBinding("class.pko-month-picker") get pickerClass() {
    return true;
  }
  @ViewChild("container") container!: ElementRef;

  vm$?: ViewModel;
  ngOnInit(): void {
    const value = this.control.value as string;
    if (value) {
      this.year = Number(value.substring(0, 4));
      this.month = Number(value.substring(5));
    } else {
      this.#updateValue();
    }
    this.vm$ = this.buildVm();
  }

  focused = false;
  @Input() minYear?: number;
  @Input() maxYear?: number;
  @Input() minMonth?: number;
  @Input() maxMonth?: number;

  #year?: number;
  #month?: number;
  get year(): number {
    return this.#year ?? this.#clampYear(new Date().getFullYear());
  }
  @Input() set year(value: number) {
    value = this.#clampYear(value);
    if (value === this.year) return;
    this.#year = value;
    this.#updateValue();
  }
  get month(): number {
    return this.#month ?? this.#clampMonth(new Date().getMonth() + 1);
  }
  @Input() set month(value: number) {
    value = this.#clampMonth(value);
    if (value === this.month) return;
    this.#month = value;
    this.#updateValue();
  }

  get control() {
    return this.controlDir.control as FormControl;
  }

  increaseYear() {
    this.year += 1;
    this.month = this.#clampMonth(this.month);
  }
  decreaseYear() {
    this.year -= 1;
    this.month = this.#clampMonth(this.month);
  }
  increaseMonth() {
    if (this.year === this.maxYear && this.month === this.maxMonth) {
      this.month = 1;
      return;
    }
    this.month = (this.month % 12) + 1;
  }
  decreaseMonth() {
    if (this.year === this.minYear && this.month === this.minMonth) {
      this.month = 12;
      return;
    }
    this.month = ((this.month + 10) % 12) + 1;
  }

  onFocus() {
    this.focused = true;
    if (!this.control.value) {
      this.#updateValue();
    }
  }
  onBlur(e: FocusEvent) {
    if (this.container.nativeElement.contains(e.relatedTarget)) return;
    this.focused = false;
  }
  ngAfterViewChecked(): void {
    if (this.focused) this.container.nativeElement.focus();
  }

  buildVm = () =>
    this.control.valueChanges.pipe(
      startWith(this.control.value),
      map((value) => {
        let prevMonth = ((this.month + 10) % 12) + 1;
        let nextMonth = (this.month % 12) + 1;
        if (this.year === this.minYear) {
          if (this.month === this.minMonth) prevMonth = 12;
          if (this.month === 12) nextMonth = this.minMonth ?? 1;
        }
        if (this.year === this.maxYear) {
          if (this.month === this.maxMonth) nextMonth = 1;
          if (this.month === 1) prevMonth = this.maxMonth ?? 12;
        }
        return {
          prevMonth,
          nextMonth,
          displayValue: `${value.substring(5)}/${value.substring(2, 4)}`,
        };
      })
    );

  #updateValue() {
    const value = `${this.year.toString().padStart(4, "0")}-${this.month
      .toString()
      .padStart(2, "0")}`;

    this.control.setValue(value);
  }
  #clampYear(year: number) {
    return Math.min(Math.max(year, this.minYear ?? 1), this.maxYear ?? 9999);
  }
  #clampMonth(month: number) {
    if (this.year === this.minYear) return Math.max(month, this.minMonth ?? 1);
    if (this.year === this.maxYear) return Math.min(month, this.maxMonth ?? 12);
    return month;
  }
}

type ViewModel = ReturnType<MonthPickerComponent["buildVm"]>;
