<ng-container *ngIf="news$ | async as news">
  <ng-container *ngIf="news.length; else empty">
    <div *ngFor="let news of news" class="d-block p-2" (click)="openDetails(news)">
      <div class="text-xs text-muted mb-1">{{ news.date | datePko: "minute" }}</div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="text-sm fw-bold">{{ news.headline }}</div>
          <div *ngIf="news.content">{{ news.content }}</div>
        </div>
        <svg icon name="chevron-right" class="icon--xl flex-shrink-0"></svg>
      </div>
    </div>

    <button
      class="btn btn-more pko-notifications__more"
      [class.btn-more--collapsed]="showAll$ | async"
      (click)="toggleExpansion()"
      *ngIf="toggle$ | async">
      {{ ((showAll$ | async) ? "ShowLess" : "ShowMore") | translate }}
    </button>
  </ng-container>
</ng-container>
<div pko-preloader *ngIf="isBusy$ | async"></div>

<ng-template #empty>
  <div class="pko-empty pko-empty--group">
    <p class="pko-empty__message">{{ "missingData.MarketNews" | translate }}</p>
  </div>
</ng-template>
