<app-form-control
  label="ExpectedRate"
  validate="rate"
  [inputGroup]="true"
  *ngIf="currencyPair$ | async as pair">
  <app-input-number
    [decimals]="pair.decimals"
    formControlName="rate"
    ngDefaultControl></app-input-number>
  <span class="align-self-center ps-3"> {{ pair.code | pair }}</span>
</app-form-control>

<app-form-live-rate [tenor]="tenor"></app-form-live-rate>
