import { Component, HostBinding, Input } from "@angular/core";
import { Side } from "@core/models/transaction";
import { Account } from "../../accounts-model";

@Component({
  selector: "app-summary-accounts",
  templateUrl: "summary-accounts.component.html",
})
export class SummaryAccountsComponent {
  @HostBinding("class") class = "pko-details__content-section";
  @Input() accounts!: AccountsInput;
  /**
   * Display differs for transaction process and history.
   * But it's a small difference, so it's all in one component.
   */
  @Input() type: "transaction" | "history" = "transaction";

  readonly sellLabel = "accounts.From";
  readonly buyLabel = "accounts.To";

  get isSelling() {
    return this.accounts.side === "Sell";
  }

  get sellAccount() {
    return this.isSelling ? this.accounts.account : this.accounts.counterAccount;
  }

  get buyAccount() {
    return this.isSelling ? this.accounts.counterAccount : this.accounts.account;
  }
}

interface AccountsInput {
  side: Side;
  netSettlement?: boolean;
  account?: Account;
  counterAccount: Account;
}
