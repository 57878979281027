import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, from, Observable, of, partition } from "rxjs";
import { map, reduce, switchMap, tap, toArray } from "rxjs/operators";

import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { UserNotification } from "@features/notifications/notifications.model";
import { datepickerFormat } from "@utils/time";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private _notifications: Record<string, UserNotification[]> = {};

  constructor(private http: HttpClient, private _events: AppEventsService) {}

  loadDates: Observable<string[]> = this._events.observe(
    () => {
      this._notifications = {};
      return this.http
        .get<string[]>("/notification/dates")
        .pipe(map((dates) => dates.map(datepickerFormat)));
    },
    AppEvent.CustomerChanged,
    AppEvent.TransactionSuccess
  );

  reload = this._events.observe(() => of(true), AppEvent.CustomerChanged);

  removeFromCache(notification: UserNotification): void {
    this.removeFromDictionary(notification, this._notifications);
  }

  removeFromDictionary(
    notification: UserNotification,
    dictionary: Record<string, UserNotification[]>
  ) {
    for (const key of Object.keys(dictionary)) {
      const update = this._notifications[key]?.filter((x) => x.key !== notification.key) || [];
      this._notifications[key] = [...update];
    }
  }

  loadNotifications(date: string): Observable<UserNotification[]> {
    if (this._notifications[date]) {
      return of(this._notifications[date] || []);
    }

    return this.http
      .get<UserNotification[]>("/notification/actions", {
        params: { date },
      })
      .pipe(
        tap((notifications) => {
          this._notifications[date] = notifications;
        })
      );
  }

  loadNextNotifications(): Observable<Record<string, UserNotification[]>> {
    const date = Object.keys(this._notifications)
      .sort((x, y) => new Date(x).getTime() - new Date(y).getTime())
      .slice(-1)[0];
    return this.http
      .get<Record<string, UserNotification[]>>("/notification/next", {
        params: { date },
      })
      .pipe(
        tap((notifications) => {
          this._notifications[Object.keys(notifications)[0]] = Object.values(notifications)[0];
        })
      );
  }

  loadCurrentNotifications(dates: string[]): Observable<Record<string, UserNotification[]>> {
    const [cached, toGet] = partition(from(dates), (date) => !!this._notifications[date]);

    return forkJoin([
      cached.pipe(reduce((acc, value) => ({ ...acc, [value]: this._notifications[value] }), {})),
      toGet.pipe(
        toArray(),
        switchMap((dates) =>
          this.http
            .get<Record<string, UserNotification[]>>("/notification/current", {
              params: { dates },
            })
            .pipe(
              tap((notifications) => {
                Object.entries(notifications).forEach(([key, value]) => {
                  this._notifications[key] = value;
                });
              })
            )
        )
      ),
    ]).pipe(map(([a, b]) => ({ ...a, ...b })));
  }
}
