<ng-container *ngIf="applications$ | async as applications">
  <ng-container *ngIf="applications.length">
    <h2 class="pko-title pko-title--2">
      {{ "activation.ActiveApplications" | translate }}
    </h2>
    <app-active-applications [applications]="applications" class="mb-4" [showClient]="false">
    </app-active-applications>
  </ng-container>
</ng-container>
<app-contracts class="mb-4"></app-contracts>
