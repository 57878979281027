import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { IFormService } from "@features/transaction/models/services";
import { BehaviorSubject } from "rxjs";
import { DepositForm } from "../models/deposit-form";
import { DepositService } from "./deposit.service";

@Injectable({ providedIn: "root" })
export class DepositFormService implements IFormService<DepositForm> {
  #form = new BehaviorSubject(this.#defaultForm);

  submission$ = this.depositService.submission$;

  constructor(private preferences: PreferencesService, private depositService: DepositService) {}

  get current() {
    return this.#form.getValue();
  }

  get #defaultForm() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { startTenor, endTenor, showLPREMessage, ...preferences } = this.preferences.deposit;

    return {
      ...preferences,
      start: { tenor: startTenor, date: null },
      end: { tenor: endTenor, date: null },
    } as DepositForm;
  }

  reset(retain?: "all" | "some") {
    if (retain === "all") return;

    const form = this.#defaultForm;
    if (retain === "some") {
      form.currency = this.current.currency;
    }

    this.#form.next(form);
  }

  save(form: DepositForm) {
    this.#form.next(form);
  }

  submit() {
    this.depositService.submit(this.current);
  }
}
