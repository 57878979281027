import { Component, ContentChild, HostBinding, Input, Self, TemplateRef } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { IOption } from "./radio-stack-model";

@Component({ selector: "radio-stack", templateUrl: "radio-stack.component.html" })
export class RadioStackComponent<T extends IOption> {
  @HostBinding("class") class = "pko-radio-stack";
  @ContentChild("optionTemplate", { static: true, read: TemplateRef })
  optionTemplate?: TemplateRef<unknown>;

  @Input() options!: T[];

  constructor(@Self() private ngControl: NgControl) {}

  get control() {
    return this.ngControl.control as FormControl;
  }
}
