<ng-container
  *ngTemplateOutlet="
    accounts.netSettlement ? netSettlement : type === 'transaction' ? transaction : history
  ">
</ng-container>

<ng-template #transaction>
  <app-definition [label]="sellLabel" [account]="sellAccount" *ngIf="sellAccount"></app-definition>
  <app-definition [label]="buyLabel" [account]="buyAccount" *ngIf="buyAccount"></app-definition>
</ng-template>

<ng-template #history>
  <app-definition
    [label]="isSelling ? sellLabel : buyLabel"
    [account]="accounts.account"
    *ngIf="accounts.account">
  </app-definition>
  <app-definition
    [label]="isSelling ? buyLabel : sellLabel"
    [account]="accounts.counterAccount"
    *ngIf="accounts.counterAccount">
  </app-definition>
</ng-template>

<ng-template #netSettlement>
  <app-definition label="accounts.Settlement" [account]="accounts.counterAccount"></app-definition>
</ng-template>
