<fieldset class="pko-grid__row" [formGroup]="group">
  <app-form-side class="col-10 col-md-7 col-lg-5"></app-form-side>
  <div class="w-100"></div>

  <app-form-amount-currencies
    class="col-10 col-md-7 col-lg-5"
    [pairs]="currencyPairs$ | async"
    [currencies]="formData.currencies">
  </app-form-amount-currencies>
  <div class="w-100"></div>

  <app-form-order-type-rate-points class="col-10 col-md-5 col-lg-4" [types]="formData.orderTypes">
  </app-form-order-type-rate-points>
  <div class="w-100"></div>

  <app-select
    class="col-10 col-md-5 col-lg-4"
    label="Tenor"
    formControlName="tenor"
    validate="tenor"
    [items]="formData.tenors"
    translateText="order.tenors"
    required
    ngDefaultControl>
  </app-select>
  <div class="w-100"></div>

  <app-input-check-pending-conversion
    *ngIf="formData.pendingConversionEnabled"
    class="col-10 col-sm-4 mt-sm-4">
  </app-input-check-pending-conversion>
  <div class="w-100"></div>

  <app-form-accounts class="col-10 col-md-5 col-lg-4" type="order"></app-form-accounts>
  <div class="w-100"></div>

  <ng-container *ngIf="!oco">
    <app-form-expiration
      class="col-10 col-md-5 col-lg-4"
      formGroupName="expiration"
      [defaultTime]="formData.defaultExpiration">
    </app-form-expiration>
    <div class="w-100"></div>

    <app-form-telephone class="col-10 col-md-5 col-lg-4" formGroupName="phone"></app-form-telephone>
  </ng-container>
</fieldset>
