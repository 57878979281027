<ngb-accordion
  class="pko-indicatives__accordion"
  *ngIf="pairs$ | async as pairs"
  #acc="ngbAccordion"
  [closeOthers]="true"
  [activeIds]="pairs.length === 1 && isNotMobile ? 'panel-0' : ''">
  <ngb-panel
    *ngFor="let pair of pairs; let i = index"
    cardClass="pko-indicative"
    id="panel-{{ i }}">
    <ng-template ngbPanelHeader>
      <app-indicative-rate [pair]="pair" [rates]="rates$" [graphs$]="graphs$"></app-indicative-rate>
      <button
        *ngIf="{ expanded: acc.isExpanded('panel-' + i) } as em"
        ngbPanelToggle
        class="pko-indicative__button btn btn-link btn-collapse {{
          em.expanded && isNotMobile ? '' : 'btn-collapse--collapsed'
        }} d-none d-sm-flex">
        {{ "toggle.indicatives." + !em.expanded | translate }}
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-graph class="pko-indicatives__graph" [currencyPair]="pair.code" tenor="TOD"></app-graph>
    </ng-template>
  </ngb-panel>
</ngb-accordion>

<p class="pko-infotext pko-infotext--xs" translate>
  indicatives.NotTransactional{{ this.display.isSimple ? "Simple" : "" }}
</p>

<button
  class="btn btn-link btn-add"
  (click)="openSettings()"
  *ngIf="isNotMobile; else mobileButton">
  {{ "indicatives.EditCurrencyPairs" | translate }}
  <svg icon name="plus-0" class="icon icon--lg border rounded-circle ms-2"></svg>
</button>

<ng-template #mobileButton>
  <button
    class="btn btn-fixed btn-primary pko-form-footer__button-forward"
    routerLink="/transactions/exchange">
    {{ "menu.transactions.exchange.Title" | translate }}
  </button>
</ng-template>
