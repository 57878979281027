import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-main-filter-button",
  templateUrl: "./main-filter-button.component.html",
})
export class MainFilterButtonComponent {
  @Input() filtersCount = 0;
  @Output() filtersToggled = new EventEmitter<void>();

  isFiltersOpen = false;

  toggle(): void {
    this.filtersToggled.emit();
    this.isFiltersOpen = !this.isFiltersOpen;
  }
}
