import { Component, HostBinding, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { validateV2 } from "@features/transaction/utils/form";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthComponentData, AuthorizationData, AuthorizationResult } from "../auth.model";

@Component({
  selector: "app-auth-token",
  template: `
    <app-modal-container [config]="authComponentData.modalConfig">
      <fieldset class="d-flex">
        <label>
          {{
            "auth.authorizationCode." + authData.authorizationMethod
              | translate : { challenge: authData.challenge }
          }}
        </label>
        <app-input-password [formControl]="control" ngDefaultControl></app-input-password>
      </fieldset>
      <ng-template #footer>
        <div class="pko-modal__footer">
          <button class="btn btn-secondary mb-3 mb-sm-0 me-sm-auto" (click)="activeModal.close()">
            {{ "buttons.back" | translate }}
          </button>
          <button class="btn btn-primary ms-sm-auto" (click)="next()">
            {{ "buttons.Next" | translate }}
          </button>
        </div>
      </ng-template>
    </app-modal-container>
  `,
})
export class AuthTokenComponent {
  @HostBinding("class") class = "d-block";

  @Input() authData!: AuthorizationData;
  @Input() authComponentData!: AuthComponentData;

  readonly control = new FormControl(null, Validators.required);

  constructor(public activeModal: NgbActiveModal) {}

  next() {
    if (!validateV2(this.control)) return;

    this.authComponentData
      .authorize(this.control.value)
      .subscribe((result) => this.#handleResult(result));
  }

  #handleResult({ nextAttempt, result }: AuthorizationResult<unknown>) {
    if (nextAttempt?.authorizationData) {
      this.authData = nextAttempt.authorizationData;
    }

    if (nextAttempt?.error) {
      return this.control.setErrors({ [nextAttempt.error.code]: true });
    }

    this.activeModal.close();
    this.authComponentData.onResult(result);
  }
}
