import { Component, Input } from "@angular/core";
import { MultiFxSummary } from "../../models/multifx-summary";

@Component({
  selector: "app-summary-sum",
  templateUrl: "./summary-sum.component.html",
})
export class SummarySumComponent {
  @Input() detail!: MultiFxSummary;
}
