import { Component, HostBinding, OnDestroy } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { Account, AccountsService, getAccountByNumber } from "@features/accounts";
import { Currency, CurrencyService, getCurrencyByCode } from "@features/currency";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { DecisionViewModel, RateStreamable, isRateSuccess } from "@features/transaction/models";
import { CountdownMessage } from "@shared/components";
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { ExchangeRateSuccess, ExchangeSummary, NormalExchangeForm } from "../../models";
import { ExchangeFormService, ExchangeService } from "../../services";

@Component({
  selector: "app-decision-exchange",
  templateUrl: "decision.component.html",
})
export class DecisionComponent implements OnDestroy {
  @HostBinding("class") class = "pko-decision";

  /**
   * This component can be put inside a modal, and this is the config used.
   */
  data?: { modal: boolean };

  #subscription = this.exchangeService.rejection$.subscribe();

  #rate$ = this.exchangeService.pollRate("exchange");

  loading$ = merge(
    this.#rate$.pipe(map(() => false)),
    this.exchangeService.confirmation$.pipe(mapToBusy()),
    this.exchangeService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$ = this.#rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  constructor(
    private formService: ExchangeFormService,
    private accountsService: AccountsService,
    private currencyService: CurrencyService,
    private exchangeService: ExchangeService,
    public warningService: ExchangeWarningCodeService,
    private tracker: Tracker
  ) {}

  get isModal() {
    return !!this.data?.modal;
  }

  async ngOnDestroy() {
    await this.exchangeService.rejectAsync();
    this.#subscription.unsubscribe();
  }

  #constructViewModel(rate: RateStreamable): Observable<DecisionViewModel<ExchangeSummary>> {
    if (!isRateSuccess<ExchangeRateSuccess>(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current as NormalExchangeForm;
    const variation = "original" in this.formService.current ? "close" : undefined;

    const summary$ = combineLatest(
      [
        this.accountsService.exchangeAccounts$,
        this.accountsService.collateralAccounts$,
        this.currencyService.allCurrencies$,
      ],
      mapToSummary(rate, form, variation)
    );

    const message = rate.hasInsufficientFunds ? insufficientFundsMessage : undefined;

    return summary$.pipe(
      map((summary) => ({
        decision: {
          summary,
          message,
          time: rate.decisionTime,
          ...this.#getActions(rate.token, summary, variation),
        },
      }))
    );
  }

  #trackRate({ decision }: DecisionViewModel<ExchangeSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.rate) return;

    summary.hasInsufficientFunds &&
      this.tracker.reportProgress({
        action: actions.WARNING,
        data: { code: "InsufficientFundsProgressBar" },
      });

    this.tracker.reportProgress({
      action: "rate",
      data: {
        rate: summary.rate.toString(),
        token: summary.token,
        pair: summary.pair,
      },
    });
  }

  #getActions = (rateToken: string, summary: ExchangeSummary, variation?: "close") => ({
    onTimeout: () => this.exchangeService.reject(),
    forward: rateToken
      ? () => {
          this.tracker.reportProgress({
            action: "confirm",
            data: { rate: `${summary.rate}`, token: rateToken },
          });
          this.exchangeService.confirm(rateToken, { ...summary });
        }
      : () => this.formService.submit({ modal: this.isModal, variation }),
  });
}

const mapToSummary =
  (rate: ExchangeRateSuccess, form: NormalExchangeForm, variation?: "close") =>
  (accounts: Account[], collateralAccounts: Account[], currencies: Currency[]) =>
    ({
      ...form,
      ...rate,
      currency: getCurrencyByCode(currencies, form.currency),
      counterCurrency: getCurrencyByCode(currencies, form.counterCurrency),
      pair: form.currencyPair,
      account: getAccountByNumber(accounts, form.account),
      counterAccount: getAccountByNumber(accounts, form.counterAccount),
      subtype: rate.netSettlementAmount !== undefined ? "Closing" : undefined,
      collateral: form.collateral &&
        !variation && {
          type: form.collateral.type,
          amount: rate.collateralAmount,
          account: getAccountByNumber(collateralAccounts, form.collateral.account),
        },
      profitCurrency: form.counterCurrency,
    } as ExchangeSummary);

const insufficientFundsMessage: CountdownMessage = {
  value: "exchange.insufficientFunds",
  type: "danger",
};
