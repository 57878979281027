import { Component, Input } from "@angular/core";
import { IconSize } from "@core/models/control";
import { baseCurrency, quoteCurrency } from "@shared/utils/currency";

@Component({
  selector: "app-currency-pair",
  templateUrl: "currency-pair.component.html",
})
export class CurrencyPairComponent {
  #code!: string;

  @Input()
  public set code(value: string) {
    this.#code = value;

    if (!value) return;

    this.baseCurrency = baseCurrency(this.#code);
    this.counterCurrency = quoteCurrency(this.#code);
  }

  @Input() flagSize: IconSize = "md";
  @Input() labelDivider = "";

  baseCurrency = "";
  counterCurrency = "";
}
