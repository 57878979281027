import { Component, OnDestroy } from "@angular/core";
import { UserService } from "@core/session";
import { FlowDeclaration } from "@features/flow-declaration/flow-declaration.model";
import { FlowDeclarationService } from "@features/flow-declaration/flow-declaratoin.service";
import { ModalService } from "@features/modal/modal.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";
import { Subject, takeUntil } from "rxjs";
import { FlowDeclarationModalComponent } from "../flow-declaration-modal/flow-declaration-modal.component";

@Component({
  selector: "app-flow-declaration-new-modal",
  templateUrl: "./flow-declaration-new-modal.component.html",
})
export class FlowDeclarationNewModalComponent implements OnDestroy {
  modalConfig: Modal = {
    title: "flowDeclaration.Title",
    titleTooltip: "flowDeclaration.Tooltip",
    hideCloseButton: true,
  };

  flowsDeclaration = this.userService.userData.flowsDeclaration;

  #destroy$ = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private userService: UserService,
    private service: FlowDeclarationService
  ) {}

  onSaved(declaration: FlowDeclaration) {
    this.service.save(declaration).pipe(takeUntil(this.#destroy$)).subscribe();
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  onCanceled() {
    this.activeModal.close();
    this.modalService.modal({
      component: FlowDeclarationModalComponent,
    });
  }
}
