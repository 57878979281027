import { Component, Input } from "@angular/core";
import { TableColumn } from "@shared/components/table/table-model";
import { MultiFxSummary } from "../../models/multifx-summary";

@Component({
  selector: "app-multifx-summary-quote",
  templateUrl: "./summary-quote.component.html",
})
export class SummaryQuoteComponent {
  @Input() quote!: MultiFxSummary;

  readonly columns: TableColumn[] = [
    { name: "multifx.Side" },
    { name: "SettlementDate" },
    { name: "Amount", align: "end" },
    { name: "Amount", align: "end" },
    { name: "Rate", align: "center" },
    { name: "multifx.CollateralAmount", align: "end" },
  ];
}
