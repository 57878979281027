<thead class="pko-table__header">
  <tr class="pko-table__header-row">
    <ng-container *ngFor="let column of columns">
      <th
        [align]="column.align"
        [style.width]="column.width ? column.width + '%' : undefined"
        [actionMenu]="column.actionMenu"
        pkoTableHeaderCell>
        {{ column.name | translate }}
        <app-tooltip
          [content]="column.tooltip"
          [contentData]="column.tooltipData"
          class="align-bottom"
          *ngIf="column.tooltip"></app-tooltip>
      </th>
    </ng-container>
  </tr>
</thead>
<ng-container *ngIf="!items?.length; else content">
  <tbody class="pko-table__body">
    <ng-container *ngTemplateOutlet="empty"></ng-container>
  </tbody>
</ng-container>
<tfoot class="pko-table__footer" *ngIf="loadNextPageButton && !loadNextPageButton.disabled?.()">
  <tr class="pko-table__footer-row">
    <td class="text-center position-relative" [attr.colspan]="+columns.length" pkoTableCell>
      <div pko-preloader *ngIf="isBusy$ | async; else loadButtonTemplate"></div>
    </td>
  </tr>
</tfoot>

<ng-template #empty>
  <tr class="pko-table__row pko-table__row--empty">
    <td align="center" [attr.colspan]="+columns.length" pkoTableCell>
      <p class="pko-table__empty-message">{{ emptyMessage | translate }}</p>
    </td>
  </tr>
</ng-template>

<ng-template #loadButtonTemplate>
  <button
    class="btn btn-secondary"
    (click)="loadNextPageButton?.onClick()"
    *ngIf="loadNextPageButton">
    {{ loadNextPageButton.text | translate }}
  </button>
</ng-template>

<ng-template #content>
  <ng-container *ngTemplateOutlet="!customTBody ? bodyContent : plainContent"></ng-container>
</ng-template>

<ng-template #plainContent>
  <ng-container
    *ngFor="let item of items; let i = index"
    [ngTemplateOutlet]="itemTemplate || defaultTemplate"
    [ngTemplateOutletContext]="{ $implicit: item, index: i }">
  </ng-container>
</ng-template>

<ng-template #bodyContent>
  <tbody class="pko-table__body">
    <ng-container *ngTemplateOutlet="plainContent"></ng-container>
  </tbody>
</ng-template>

<ng-template #defaultTemplate let-item>{{ item }}</ng-template>
