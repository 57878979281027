import { NgModule } from "@angular/core";
import { TransactionModule } from "@features/transaction/transaction.module";
import { DatePkoPipe } from "@shared/pipes";
import { SharedModule } from "@shared/shared.module";
import { HighchartsChartModule } from "highcharts-angular";
import { BlotterGraphComponent } from "./blotter-graph.component";
import { BlotterSettingsComponent } from "./settings/blotter-settings.component";
import { BlotterSettingsItemComponent } from "./settings/item/blotter-settings-item.component";

@NgModule({
  imports: [SharedModule, TransactionModule, HighchartsChartModule],
  declarations: [BlotterGraphComponent, BlotterSettingsComponent, BlotterSettingsItemComponent],
  exports: [BlotterGraphComponent, BlotterSettingsComponent, HighchartsChartModule],
  providers: [DatePkoPipe],
})
export class BlotterGraphModule {}
