import { Component, ContentChild, HostBinding, Input, TemplateRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "../modal-model";

@Component({
  selector: "app-modal-container",
  templateUrl: "./modal-container.component.html",
})
export class ModalContainerComponent {
  @HostBinding("class") class = "pko-modal";
  @Input() config: Modal = {} as Modal;
  @Input() closeResult?: () => unknown;
  @ContentChild("header") customHeaderTemplate?: TemplateRef<unknown>;
  @ContentChild("title") subtitleTemplate?: TemplateRef<unknown>;
  @ContentChild("footer") customFooterTemplate?: TemplateRef<unknown>;

  get hasSubtitle() {
    return Boolean(this.config.subtitle || this.subtitleTemplate);
  }

  constructor(private activeModal: NgbActiveModal) {}

  close(): void {
    const closeResult = this.closeResult?.();
    this.activeModal.close(closeResult);
  }

  onClicked(resultOnClose?: unknown): void {
    // if needed, add `wait` prop
    // and then await onClicked("complete") -> close()
    this.activeModal.close(resultOnClose);
  }
}
