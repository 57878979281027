import { Component, HostBinding, Input } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";
import { UserNotificationComponent } from "@features/notifications/notifications.model";

@Component({
  selector: "app-notifications-item",
  templateUrl: "./notifications-item.component.html",
})
export class NotificationsItemComponent {
  @HostBinding("class") class = "pko-notification";
  @Input()
  get child(): UserNotificationComponent | null {
    return this._child;
  }

  set child(value: UserNotificationComponent | null) {
    this._child = value;
    this.translateHeader = value?.translateHeader ?? true;
    this.header = value?.header || "";
    this.icon = value?.icon || "";
    this.menu = value?.menu;
  }

  @Input() public simple = true;

  public translateHeader = true;
  public header = "";
  public icon = "";
  public menu?: ActionItem[] = [];

  private _child: UserNotificationComponent | null = null;
}
