<app-dashboard-section
  [sectionTitle]="sectionTitle"
  [menu]="menu | async"
  id="recent-history-section">
  <ng-container *ngTemplateOutlet="(showAsSimple$ | async) ? simple : pro"></ng-container>
  <div pko-preloader *ngIf="isBusy$ | async"></div>
  <ng-template #simple><app-recent-history></app-recent-history></ng-template>
  <ng-template #pro>
    <ul
      ngbNav
      #nav="ngbNav"
      class="pko-tabs nav-tabs"
      role="tablist"
      [(activeId)]="listType"
      (activeIdChange)="tabChange($event)">
      <li class="pko-tabs__item" role="presentation" ngbNavItem="recent">
        <a class="pko-tabs__link" role="tab" ngbNavLink>{{
          "history.recent.RecentTransactions" | translate
        }}</a>
        <ng-template ngbNavContent>
          <app-recent-history></app-recent-history>
        </ng-template>
      </li>
      <li class="pko-tabs__item" role="presentation" ngbNavItem="unsettled">
        <a class="pko-tabs__link" role="tab" ngbNavLink>{{
          "history.recent.IncomingSettlements" | translate
        }}</a>
        <ng-template ngbNavContent>
          <app-unsettled-history></app-unsettled-history>
        </ng-template>
      </li>
      <li class="pko-tabs__item" role="presentation" ngbNavItem="chart">
        <a class="pko-tabs__link" role="tab" ngbNavLink>{{
          "history.recent.SettlementsChart" | translate
        }}</a>
        <ng-template ngbNavContent> <app-blotter-graph></app-blotter-graph></ng-template>
      </li>
    </ul>

    <div class="pko-tabs__panel w-100" role="tabpanel" [ngbNavOutlet]="nav"></div>
  </ng-template>
</app-dashboard-section>
