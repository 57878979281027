<app-modal-container
  [config]="modalConfig"
  [formGroup]="form"
  *ngIf="shortcuts$ | async as shortcuts">
  <app-groups-selector
    emptySelectionTitle="missingData.Shortcuts"
    selectedTitle="shortcuts.settings.Selected"
    unSelectedTitle="shortcuts.settings.Unselected"
    [selectedGroup]="selectedGroup"
    [unSelectedGroup]="notSelectedGroup"
    [elements]="shortcuts"
    [componentType]="itemType"
    [maxSelected]="4"
    [hasSearchBar]="false">
  </app-groups-selector>
</app-modal-container>
