<ng-container *ngIf="dates$ | async as dates; else loadingBlock">
  <app-grouped-list
    *ngIf="notifications$ | async as notifications; else loadingBlock"
    [groups]="notifications"
    [byDate]="true"
    emptyMessage="missingData.CurrentNotifications"
    emptyGroupMessage="missingData.Notifications">
    <ng-template #itemTemplate let-item>
      <app-notifications-main
        *ngIf="!item.hidden"
        [notification]="item"
        [@slideOut]="item.deleted ? 'deleted' : ''"
        [simple]="true"
        (actionExecuted)="onActionExecuted($event)">
      </app-notifications-main>
    </ng-template>
  </app-grouped-list>
  <button
    class="btn btn-more pko-notifications__more"
    (click)="toggleExpansion()"
    *ngIf="(moreAndFinish$ | async) !== true">
    {{ "ShowMore" | translate }}
  </button>
</ng-container>
<ng-template #loadingBlock>
  <div pko-preloader></div>
</ng-template>
<ng-template #missing>
  <app-alert content="missingData.Notifications"></app-alert>
</ng-template>
