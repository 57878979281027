const processes = [
  "exchange",
  "order",
  "deposit",
  "alert",
  "multifx",
  "dpw",
  "fast-deal",
  "iob",
  "investment-deposit",
  "premium-deposit",
] as const;

export type transaction = (typeof processes)[number];

export type category =
  | "history"
  | "alert"
  | "login"
  | "Digitalization"
  | "Documents"
  | "FlowDeclaration"
  | "navigation"
  | "preferences"
  | "profile"
  | "set-shortcuts"
  | "set-tiles"
  | transaction;

export interface ProcessTrace {
  id: string;
  process: transaction | "set-shortcuts" | "set-tiles";
  origin: origin;
  start: Date;
}

export interface Trace {
  category: category;
  action: string;
  data?: Record<string, string>;
  processId?: string;
}

export const actions = {
  ENTRY: "entry",
  SUCCESS: "success",
  FAILURE: "failure",
  WARNING: "warning",
  SAVE: "save",
  OFFER_SHOWN: "offer-shown",
} as const;

export const origins = {
  INDICATIVES: "indicatives",
  DASHBOARD: "dashboard",
  SHORTCUT: "shortcut",
  TILE: "tile",
  SIMLE_DEAL_TILE: "simple-deal-tile",
  CHARTS: "charts",
  MORE_MENU: "more",
  DETAILS: "details",
  ALERT: "alert",
  SUCCESS_NEXT: "next",
  FAILURE_NEXT: "retry",
  DPW: "dpw",
  WIDGET: "widget",
  NOTIFICATION: "notification",
  BANNER: "banner",
  OFFER: "offer",
} as const;

export type origin = (typeof origins)[keyof typeof origins];

const completingActions = [actions.SUCCESS, actions.FAILURE, actions.SAVE] as const;

export const completed = (action: any) => completingActions.includes(action);
