import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "svg[icon]",
  template: `<svg:use [attr.xlink:href]="'#icon-' + name"></svg:use>`,
})
export class IconComponent {
  @Input() name!: string;
  @Input() className?: string;
  @HostBinding("attr.viewbox") viewbox = "0 0 24 24";
  @HostBinding("class") get class() {
    return `icon icon-${this.name}`;
  }
}
