<label *ngIf="label" [attr.for]="control" class="form-label d-inline-flex align-items-center">
  {{ translateLabel ? (label | translate) : label }}
  <app-tooltip [content]="tooltip" [contentData]="tooltipData" *ngIf="tooltip"></app-tooltip>
</label>
<ng-container *ngTemplateOutlet="inputGroup ? group : content"></ng-container>
<div class="form-hint" *ngIf="hint">
  {{ hint }}
</div>

<ng-container *ngIf="hasError && control?.errors as errors">
  <app-form-error
    *ngFor="let error of errors | keyvalue"
    [error]="error"
    [overrides]="errorOverrides">
  </app-form-error>
</ng-container>

<ng-template #group>
  <div class="input-group {{ className }}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
