import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ModalService } from "@features/modal/modal.service";
import { TranslateService } from "@ngx-translate/core";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { PreferencesService } from "./preferences/preferences.service";

@Injectable({ providedIn: "root" })
export class LPREMessageService {
  constructor(
    private _http: HttpClient,
    private _modal: ModalService,
    private _preferences: PreferencesService,
    private _translate: TranslateService
  ) {}

  showLPREMessage() {
    this._http
      .get<{ [lang: string]: { title: string; message: string } }>(`/browser/lpre-info`)
      .subscribe({
        next: (messages) => {
          const message = messages[this._translate.currentLang] ?? messages["pl"];
          this._modal.dialog(
            {
              title: message.title,
              body: message.message,
              translateTitle: false,
              translateBody: false,
              type: "info",
              buttons: {
                primary: {
                  ...defaultPrimary,
                  onClick: () =>
                    this._preferences.setDeposit({
                      ...this._preferences.deposit,
                      showLPREMessage: false,
                    }),
                },
              },
            },
            "modal",
            true
          );
        },
      });
  }
}
