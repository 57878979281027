import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { DetailsDirective } from "./components/details.directive";
import { NotificationsEndingDepositComponent } from "./components/details/notifications-ending-deposit/notifications-ending-deposit.component";
import { NotificationsExecutedAlertComponent } from "./components/details/notifications-executed-alert/notifications-executed-alert.component";
import { NotificationsExecutedInvDepositComponent } from "./components/details/notifications-executed-inv-deposit/notifications-executed-inv-deposit.component";
import { NotificationsExecutedOrderComponent } from "./components/details/notifications-executed-order/notifications-executed-order.component";
import { NotificationsExecutedPremiumDepositComponent } from "./components/details/notifications-executed-premium-deposit/notifications-executed-premium-deposit.component";
import { NotificationsExpiredAlertComponent } from "./components/details/notifications-expired-alert/notifications-expired-alert.component";
import { NotificationsExpiredOrderComponent } from "./components/details/notifications-expired-order/notifications-expired-order.component";
import { NotificationsMessageComponent } from "./components/details/notifications-message/notifications-message.component";
import { NotificationsNewDpwComponent } from "./components/details/notifications-new-dpw/notifications-new-dpw.component";
import { NotificationsUnsettledDealComponent } from "./components/details/notifications-unsettled-deal/notifications-unsettled-deal.component";
import { NotificationsItemComponent } from "./components/notifications-item/notifications-item.component";
import { NotificationsMainComponent } from "./components/notifications-main/notifications-main.component";
import { NotificationsComponent } from "./components/notifications.component";
import { NotificationsProComponent } from "./components/views/pro/notifications.pro.component";
import { NotificationsSimpleComponent } from "./components/views/simple/notifications.simple.component";

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, CommonModule, SharedModule],
  declarations: [
    NotificationsUnsettledDealComponent,
    NotificationsEndingDepositComponent,
    NotificationsNewDpwComponent,
    NotificationsExecutedOrderComponent,
    NotificationsExpiredOrderComponent,
    NotificationsExpiredAlertComponent,
    NotificationsExecutedAlertComponent,
    NotificationsExecutedInvDepositComponent,
    NotificationsExecutedPremiumDepositComponent,
    NotificationsMessageComponent,
    NotificationsItemComponent,
    NotificationsMainComponent,
    NotificationsSimpleComponent,
    NotificationsProComponent,
    NotificationsComponent,
    DetailsDirective,
  ],
  exports: [NotificationsComponent, SharedModule],
})
export class NotificationsModule {}
