import { Side } from "@core/models/transaction";
import { NonForwardTenor } from "@features/tenor";
import { Breakpoint } from "./../breakpoints/breakpoints";

export type Preference = Record<string, string | undefined | number | boolean>;
export type PreferenceType = Preferences[keyof Preferences] | TransactionControlFlags;

export interface Preferences {
  exchange: Exchange;
  deposit: Deposit;
  language: Language;
  dashboard: Dashboard;
  swap: Swap;
  transactionControl: TransactionControl;
  depositControl: DepositControl;
  premiumDepositControl: DepositControl;
  investmentDepositControl: DepositControl;
  indicatives: Indicatives;
  dealHistory: DealHistory;
  phone: PhonePreference;
  graph: Graph;
  blotterGraph: BlotterGraph;
  customers: Customers;
  investmentDeposit: InvestmentDeposit;
}

export interface Exchange extends Preference {
  side: Side;
  currency: string;
  counterCurrency: string;
  amount?: number;
  tenor: string;
}

export interface InvestmentDeposit extends Preference {
  currency: string;
  amount: number;
  currencyPair: string;
  tenor: string;
}

export interface Deposit extends Preference {
  amount?: number;
  currency: string;
  startTenor: string;
  endTenor: string;
  showLPREMessage: boolean;
}

export interface Language {
  code: string;
  name: string;
}

export interface Customers {
  pinned: string[];
}

export interface Indicatives {
  pollingEnabled: boolean;
  tenor: NonForwardTenor;
}

export interface Dashboard {
  shortcuts: string[];
  simple: boolean;
  tilesSelection: TilesSelection;
}

export interface TilesSelection {
  left: DashboardTile[];
  right: DashboardTile[];
}

export interface SimpleTilesSelection {
  center?: SimpleDashboardTile[];
  left?: SimpleDashboardTile[];
  right?: SimpleDashboardTile[];
}

export type ResponsiveSimpleTilesSelection = Record<Breakpoint, SimpleTilesSelection>;

export const DASHBOARD_TILES = [
  "deal",
  "order",
  "alert",
  "deposit",
  "investmentDeposit",
  "dpw",
  "notifications",
  "news",
  "transactions",
] as const;
export type DashboardTile = (typeof DASHBOARD_TILES)[number];

export const SIMPLE_DASHBOARD_TILES = [
  "banner",
  "exchange",
  "indicatives",
  "notifications",
  "shortcuts",
  "transactions",
] as const;
export type SimpleDashboardTile = (typeof SIMPLE_DASHBOARD_TILES)[number];

export interface PhonePreference extends Preference {
  prefix: string;
  number?: string;
}

export interface Swap extends Preference {
  side: Side;
  currency: string;
  counterCurrency: string;
  amount?: number;
  nearTenor: string;
  farTenor: string;
}

export interface TransactionControl {
  values: TransactionControlFlags;
  availability: TransactionControlFlags;
}

export interface TransactionControlFlags {
  checkSimilarDeals: boolean;
  allowDealsWithoutBalance: boolean;
}

export interface DepositControl extends Preference {
  checkSimilarDeals: boolean;
}

export interface DealHistory extends Preference {
  order: string;
  openBalance: boolean;
  unsettled: boolean;
}

export interface Graph {
  side: Side;
  period: number;
  type: "linear" | "candle";
}

export interface BlotterGraph {
  currencies: string[];
}
