import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";
import { BusyService } from "./busy.service";

@Injectable()
export class BusyInterceptor implements HttpInterceptor {
  private readonly interceptedUrls = [
    "/exchange",
    "/deposit",
    "/order",
    "/currency",
    "/accounts",
    "/history",
    "/indicatives",
    "/exchangehistory",
    "/deposithistory",
    "/customers",
    "/user",
    "/preferences",
    "/notification",
    "/fastdeal",
    "/graph",
    "/notification",
    "/multifx",
    "/alert",
    "/contracts",
    "/mifid",
    "/messages",
    "/investment-deposit-history",
  ];

  private readonly excludedUrls = ["/rate", "/rates", "/status", "/survey-mobile"];

  constructor(private _busyService: BusyService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.interceptedUrls.some((x) => req.url.startsWith(x))) return next.handle(req);
    if (this.excludedUrls.some((x) => req.url.endsWith(x))) return next.handle(req);

    this._busyService.increment(req.url);
    return next.handle(req).pipe(
      finalize(() => {
        this._busyService.decrement(req.url);
      })
    );
  }
}
