import { Component, Input } from "@angular/core";
import { OriginalExchange } from "@features/exchange/models";

@Component({
  selector: "app-summary-original",
  templateUrl: "summary-original.component.html",
})
export class SummaryOriginalComponent {
  @Input() original!: OriginalExchange;

  collapsed = true;
}
