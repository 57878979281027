<ng-container *ngIf="vm$ | async as vm; else loading">
  <app-flow-declaration-summary
    [declaration]="vm.declaration"
    *ngIf="!isEditing"
    (updated)="onUpdated()"
    (refused)="onRefused()">
  </app-flow-declaration-summary>
  <app-flow-declaration-edit
    *ngIf="isEditing"
    [declaration]="vm.declaration"
    [currencies]="vm.currencies"
    (saved)="onSaved($event)"
    (canceled)="onCanceled()">
  </app-flow-declaration-edit>
</ng-container>

<ng-template #loading>
  <div pko-preloader></div>
</ng-template>
