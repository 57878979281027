import { Component, EventEmitter, Input, Optional, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { handleFormErrors } from "@features/transaction/utils/form";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { mapSideOptions } from "@shared/utils/side";
import { Observable } from "rxjs";

@Component({
  selector: "app-form-multifx-new-transaction-leg",
  templateUrl: "./form-multifx-new-transaction-leg.component.html",
})
export class FormMultifxNewTransactionLegComponent {
  @Input() currencyPair!: Observable<string>;
  @Input() currency!: Observable<string>;
  @Input() modal = false;
  @Input() form!: FormGroup;

  @Output() deleted = new EventEmitter<void>();
  @Output() saved = new EventEmitter<void>();

  sides = mapSideOptions();

  constructor(@Optional() private activeModal: NgbActiveModal) {}

  save() {
    Object.entries(this.form.controls).forEach(([, control]) => control.markAsTouched());
    this.form.updateValueAndValidity({ emitEvent: true });
    if (!this.form.valid) return;
    if (this.modal) this.activeModal.close("save");
    else handleFormErrors(this.form) && this.saved.emit();
  }

  delete() {
    if (this.modal) this.activeModal.close("delete");
    else this.deleted.emit();
  }
}
