<div class="position-relative">
  <div pko-preloader *ngIf="isBusy$ | async"></div>
  <form class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="vm$ | async as data">
    <ng-container *ngIf="data.hasPairs; else noPairs">
      <div class="pko-grid__row">
        <app-form-side></app-form-side>
        <app-form-amount-currencies [pairs]="data.currencyPairs" [currencies]="data.currencies">
        </app-form-amount-currencies>
        <app-form-order-type-rate-points [asGridRow]="true" [types]="data.orderTypes">
        </app-form-order-type-rate-points>
      </div>
      <div class="pko-grid__row">
        <app-select
          label="Tenor"
          formControlName="tenor"
          validate="tenor"
          [items]="data.tenors"
          translateText="order.tenors"
          class="col-12 col-sm-6"
          required
          ngDefaultControl>
        </app-select>
        <app-form-telephone class="col-12 col-sm-6" formGroupName="phone"></app-form-telephone>
      </div>
      <div class="pko-grid__row">
        <app-form-accounts type="order"></app-form-accounts>
        <app-form-expiration formGroupName="expiration" [defaultTime]="data.defaultExpiration">
        </app-form-expiration>
      </div>
      <app-form-footer type="order" [back]="{ hidden: true }"></app-form-footer>
    </ng-container>
  </form>
</div>

<ng-template #noPairs>
  <app-alert icon="info1" content="missingData.OrderPairs" class="col-10 col-md-8"></app-alert>
</ng-template>
