import {
  AfterViewChecked,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective, NgControl } from "@angular/forms";
import { ControlSize } from "@core/models/control";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateStruct,
  NgbDatepicker,
  NgbDatepickerConfig,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import { Subscription, filter, map } from "rxjs";
import { IFormControl, ValidationErrorOverrides } from "../model";
import { DatepickerAdapter } from "./datepicker-adapter";
import { ValidatorName, dateStructToString, stringToDateStruct } from "./datepicker-utils";
import { DatepickerValidator } from "./datepicker-validator.service";

/**
 * A datepicker component with required, min, max, weekend and holiday validation.
 * @summary
 * NgbDatepicker removes value from the control when the control is disabled. To counteract that, call `disable({ onlySelf: true })`.
 * There are also other weird side-effects, like *sometimes* not setting the value on TenorDate component.
 * It *seems* that adding `{ emitEvent: false }` to `control.updateValueAndValidity` here, when updating validation Inputs, fixes it.
 */
@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NgbDateAdapter, useClass: DatepickerAdapter }, DatepickerValidator],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DatepickerComponent implements IFormControl, OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(NgbDatepicker, { static: true }) datepicker!: NgbDatepicker;
  @Input("ngId") labelForId?: string;
  @Input() className?: string;
  @Input() label?: string;
  @Input() placeholder = "DD-MM-YYYY";
  @Input() size?: ControlSize;
  @Input() validate: boolean | ValidatorName[] = true;
  @Input("errors") errorOverrides?: ValidationErrorOverrides;

  @Input() set allowPastDates(value: boolean) {
    value && this.validator.allowPastDates();
  }

  #subscription?: Subscription;

  @Input() set holidays(dates: string[] | null | undefined) {
    this.validator.holidays = dates;
    this.#updateValueAndValidity();
  }

  @Input() set restricted(dates: string[] | null | undefined) {
    this.validator.restricted = dates;
    this.#updateValueAndValidity();
  }

  @Input() set min(date: string | null | undefined) {
    this.validator.setMin(date);
    this.#updateValueAndValidity();
  }

  @Input() set max(date: string | null | undefined) {
    this.validator.setMax(date);
    this.#updateValueAndValidity();
  }

  constructor(
    datepickerConfig: NgbDatepickerConfig,
    @Attribute("debug") public debugMode = false,
    @Self() private controlDir: NgControl,
    private validator: DatepickerValidator,
    private calendar: NgbCalendar,
    public i18n: NgbDatepickerI18n,
    private cdr: ChangeDetectorRef
  ) {
    // navigate using arrows
    datepickerConfig.navigation = "none";
    // days that don't belong to current month are not visible, height of the datepicker might differ
    datepickerConfig.outsideDays = "collapsed";
    // weekdays length - 2 characters depends from language For example: "Su"
    datepickerConfig.weekdays = 1;
  }

  get minDate() {
    return this.validator.min;
  }

  get maxDate() {
    return this.validator.max;
  }

  get isDisabled() {
    return this.validator.isDisabled;
  }

  get control() {
    return this.controlDir.control as FormControl;
  }

  ngOnInit() {
    const { control, validate } = this;

    this.#subscription = control.valueChanges
      .pipe(filter(Boolean), map(stringToDateStruct))
      .subscribe((date) => this.datepicker.navigateTo(date));

    this.#subscription.add(
      control.valueChanges.subscribe((date) => {
        const datepicker = this.datepicker;
        if (!date) {
          datepicker.model.selectedDate = null;
        }
        this.cdr.detectChanges();
      })
    );

    if (validate) {
      const validators = this.validator.getValidators(validate);
      control.addValidators(validators);
      control.markAsTouched({ onlySelf: true });
    }
  }

  ngOnDestroy() {
    this.#subscription?.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  navigate(monthValue: number) {
    const { firstDate } = this.datepicker.state;
    this.datepicker.navigateTo(this.calendar.getNext(firstDate, "m", monthValue));
  }

  //Unfortunately datepicker selection cannot be cleared after set https://github.com/ng-bootstrap/ng-bootstrap/issues/3824
  //and calendar still highligtens previosly selected date even when model is null (https://github.com/ng-bootstrap/ng-bootstrap/issues/4137).
  //On Firefox you cannot set highligtened date (works on Chrome), therefore we are manually setting control value upon first selection
  select(date: NgbDateStruct | null) {
    if (!this.control.value && date) {
      this.control.setValue(dateStructToString(date));
    }
  }

  #updateValueAndValidity = () =>
    this.validate && this.control && this.control.updateValueAndValidity({ emitEvent: false });
}
