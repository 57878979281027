import { Component, HostBinding, Input } from "@angular/core";
import { Service } from "../../mifid.model";

@Component({
  selector: "app-mifid-survey-result-service",
  templateUrl: "./mifid-survey-result-service.component.html",
})
export class MifidSurveyResultServiceComponent {
  @HostBinding("class") class = "d-flex justify-content-between";

  @Input() service!: Service;
  @Input() index!: number;

  get isAdequate(): boolean {
    return this.service?.suitability ?? this.service?.isAppropriate;
  }
}
