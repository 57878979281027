import { Component, HostBinding, OnDestroy } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { Account, AccountsService, getAccountByNumber } from "@features/accounts";
import { Currency, CurrencyService, getCurrencyByCode } from "@features/currency";
import { IobForm, IobSummary } from "@features/dpw/dpw.model";
import { IobFormService } from "@features/dpw/services/iob-form.service";
import { ExchangeRateSuccess } from "@features/exchange/models";
import { ExchangeService } from "@features/exchange/services";
import { DecisionViewModel, RateStreamable, isRateSuccess } from "@features/transaction/models";
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";

@Component({
  selector: "app-iob-decision-exchange",
  templateUrl: "iob-decision.component.html",
})
export class IobDecisionComponent implements OnDestroy {
  @HostBinding("class") class = "pko-decision";

  #subscription = this.exchangeService.rejection$.subscribe();

  #rate$ = this.exchangeService.pollRate("exchange");

  loading$ = merge(
    this.#rate$.pipe(map(() => false)),
    this.exchangeService.confirmation$.pipe(mapToBusy()),
    this.exchangeService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$ = this.#rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  constructor(
    private formService: IobFormService,
    private accountsService: AccountsService,
    private currencyService: CurrencyService,
    private exchangeService: ExchangeService,
    private tracker: Tracker
  ) {}

  async ngOnDestroy() {
    await this.exchangeService.rejectAsync();
    this.#subscription.unsubscribe();
  }

  #constructViewModel(rate: RateStreamable): Observable<DecisionViewModel<IobSummary>> {
    if (!isRateSuccess<ExchangeRateSuccess>(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current as IobForm;

    const summary$ = combineLatest(
      [
        this.accountsService.exchangeAccounts$,
        this.accountsService.collateralAccounts$,
        this.currencyService.allCurrencies$,
      ],
      mapToSummary(rate, form)
    );

    return summary$.pipe(
      map((summary) => ({
        decision: {
          summary,
          time: rate.decisionTime,
          message: rate.hasInsufficientFunds ? "exchange.insufficientFunds" : undefined,
          ...this.#getActions(rate.token, summary),
        },
      }))
    );
  }

  #trackRate({ decision }: DecisionViewModel<IobSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.rate) return;

    this.tracker.reportProgress({
      action: "rate",
      data: {
        rate: summary.rate.toString(),
        token: summary.token,
        pair: summary.pair,
        isIob: "true",
      },
    });
  }

  #getActions = (rateToken: string, summary: IobSummary) => ({
    onTimeout: () => this.exchangeService.reject(),
    forward: rateToken
      ? () => this.exchangeService.confirm(rateToken, { ...summary })
      : () => this.formService.submit(),
  });
}

const mapToSummary =
  (rate: ExchangeRateSuccess, form: IobForm) =>
  (accounts: Account[], collateralAccounts: Account[], currencies: Currency[]): IobSummary => ({
    ...rate,
    ...form,
    pair: form.currencyPair,
    currency: getCurrencyByCode(currencies, form.currency) as Currency,
    counterCurrency: getCurrencyByCode(currencies, form.counterCurrency) as Currency,
    counterAccount: getAccountByNumber(accounts, form.counterAccount),
    collateral: form.collateral && {
      type: form.collateral.type,
      amount: rate.collateralAmount,
      account: getAccountByNumber(collateralAccounts, form.collateral.account),
    },
    profitCurrency: form.counterCurrency,
  });
