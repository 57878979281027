import { Component, Input, OnInit } from "@angular/core";
import { ModalService } from "@features/modal/modal.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal, defaultPrimary } from "@shared/components/modal/modal-model";
import { EMPTY, Observable, catchError, tap } from "rxjs";
import { AuthComponentData, PrepareAuthorizationResult } from "../auth.model";

@Component({
  selector: "app-auth",
  template: `
    <ng-container *ngIf="authData$ | async as authData; else loading">
      <app-auth-token
        *ngIf="authData.authorizationData.authorizationMethod !== 'Mobile'"
        [authData]="authData.authorizationData"
        [authComponentData]="data"></app-auth-token>
      <app-auth-mobile
        *ngIf="authData.authorizationData.authorizationMethod === 'Mobile'"
        [authData]="authData.authorizationData"
        [authComponentData]="data"></app-auth-mobile>
    </ng-container>

    <ng-template #loading>
      <app-modal-container [config]="data.modalConfig">
        <div pko-preloader class="align-self-center"></div>
      </app-modal-container>
    </ng-template>
  `,
})
export class AuthComponent implements OnInit {
  @Input() data!: AuthComponentData;
  authData$!: Observable<PrepareAuthorizationResult>;
  modalConfig!: Modal;

  constructor(private modalService: ModalService, private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.authData$ = this.data.prepare$.pipe(
      catchError(() => {
        this.#handleErrorResult();
        return EMPTY;
      }),
      tap(({ error }) => error && this.#handleErrorResult())
    );
  }

  #handleErrorResult() {
    this.activeModal.close();
    this.modalService.dialog({
      type: "failure",
      title: "errors.GENERIC",
      buttons: { primary: defaultPrimary },
    });
  }
}
