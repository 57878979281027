import { Pipe, PipeTransform } from "@angular/core";
import { Application } from "../activation.model";

@Pipe({
  name: "applicationStatus",
})
export class ApplicationStatusPipe implements PipeTransform {
  transform(item: Application) {
    return item.methodOfSigning === "Branch"
      ? "activation.status.Branch"
      : `activation.status.${item.status}`;
  }
}
