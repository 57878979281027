import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  selector: "ng-template[sectionType]",
})
export class SectionTemplateDirective {
  @Input() sectionType!: string;

  constructor(public template: TemplateRef<unknown>) {}
}
