<radio-stack
  formControlName="side"
  ngDefaultControl
  [options]="directions"
  class="col-10 col-md-8 col-lg-7">
  <ng-template #optionTemplate let-option>
    <svg icon name="{{ option.icon }}" class="pko-radio-stack__icon"></svg>
    <div class="pko-radio-stack__container">
      <div class="pko-radio-stack__item-label">{{ option.title | translate }}</div>
      <div class="pko-radio-stack__item-description">{{ option.subtitle | translate }}</div>
    </div>
  </ng-template>
</radio-stack>
