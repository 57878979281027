import { Injectable } from "@angular/core";
import { PvpRule } from "@features/accounts";
import { ModalService } from "@features/modal/modal.service";

@Injectable({ providedIn: "root" })
export class PvpService {
  rule?: PvpRule;

  constructor(private modalService: ModalService) {}

  isPvpMessageRequired = ({ pvp }: { pvp?: boolean }, pvpRule?: PvpRule) =>
    pvpRule &&
    (pvpRule.displayWarningMessage === "Yes" ||
      (pvpRule.displayWarningMessage === "IfPvpChosen" && pvp));

  showPvpMessage = (proceed: () => void) =>
    this.modalService.dialog({
      title: "pvp.Label",
      body: "pvp.Message",
      type: "info",
      buttons: {
        primary: {
          text: "OK",
          onClick: proceed,
        },
      },
    });
}
