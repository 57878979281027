import { Component, Input } from "@angular/core";
import { BodyLine, Dialog, DialogBody } from "@shared/components/modal/modal-model";

@Component({ template: "" })
export class DialogComponent {
  #data!: Omit<Dialog, "body">;
  #lines?: BodyLine[];

  @Input()
  get data() {
    return this.#data;
  }

  set data(data: Dialog) {
    const { body, ...rest } = data;
    this.#data = rest;
    this.#lines = this.#parseBody(body);
  }

  get lines() {
    return this.#lines;
  }

  #parseBody = (body?: DialogBody) => {
    if (!body) {
      return;
    } else if (Array.isArray(body)) {
      return body;
    } else if (typeof body !== "string") {
      return [body];
    } else {
      return [{ key: body }];
    }
  };
}
