<p [class]="classes.infotext">
  {{ "exchange.info.spot" | translate }}
</p>
<form class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="pko-grid__row">
    <app-form-side [class]="classes.side"></app-form-side>
    <div class="w-100"></div>

    <fx-form-amount-currency-pair [class]="classes.currencyPair"></fx-form-amount-currency-pair>
    <div class="w-100"></div>

    <app-form-tenor-date-product show="date" [class]="classes.tenor" [maxTenor]="maxTenor">
    </app-form-tenor-date-product>
    <div class="w-100"></div>

    <app-form-pvp class="col-10"></app-form-pvp>

    <app-form-accounts [class]="classes.accounts"></app-form-accounts>

    <app-form-collateral formGroupName="collateral" [class]="classes.collateral">
    </app-form-collateral>
  </div>
  <app-form-footer [back]="{ hidden: isTile, onClick: back }"></app-form-footer>
</form>

<ng-container *ngIf="!isTile">
  <pre *ifIsDevMode>{{ form.getRawValue() | json }}</pre>
</ng-container>
