import { Injectable } from "@angular/core";
import { mapToAction } from "@core/models/observable-action";
import { Failure } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { TransactionResponseService } from "@features/transaction/services/response.service";
import { pollStatus } from "@features/transaction/utils/form";
import { BodyLine, ModalType } from "@shared/components/modal/modal-model";
import { formatDate, formatNumber } from "@shared/utils/format";
import { Subject, filter, switchMap, tap } from "rxjs";
import {
  AlertForm,
  AlertInitializationRequest,
  AlertStatusResponse,
  CreateAlertResponse,
  CreateSuccess,
  isCreated,
  isReady,
} from "../models/alert-form";
import { AlertApiService } from "./alert-api.service";

@Injectable({ providedIn: "root" })
export class AlertService {
  #submission = new Subject<AlertInitializationRequest>();
  submission$ = this.#submission.pipe(switchMap(this.create.bind(this)));

  constructor(
    private api: AlertApiService,
    private transaction: TransactionResponseService,
    private tracker: Tracker
  ) {}

  submit(form: AlertInitializationRequest) {
    this.tracker.reportProgress({
      action: "init",
      data: { rate: form.rate.toString(), pair: form.currencyPair },
    });
    this.#submission.next(form);
  }

  create({ modal, ...request }: AlertInitializationRequest) {
    const modalType = modal ? "modal" : "page";

    const handleCreateError = (response: CreateAlertResponse) =>
      isCreated(response) || this.#onError(modalType, response.error);

    const getStatus = ({ token }: CreateSuccess) => this.api.getStatus(token).pipe(pollStatus());

    const handleStatus = (response: AlertStatusResponse) =>
      isReady(response)
        ? this.#onSuccess(request, modalType)
        : this.#onError(modalType, response.status);

    this.tracker.reportProgress({
      action: "confirm",
      data: { rate: request.rate.toString(), pair: request.currencyPair },
    });

    return this.api
      .create(request)
      .pipe(
        tap(handleCreateError),
        filter(isCreated),
        switchMap(getStatus),
        tap(handleStatus),
        mapToAction()
      );
  }

  #onSuccess({ side, currency, currencyPair, ...form }: AlertForm, modalType: ModalType) {
    const rate = formatNumber(+form.rate, { style: "pair", code: currencyPair, decimals: 4 });
    const date = formatDate(form.expirationDate);

    const bodyLines: BodyLine[] = [
      { key: `alert.success.${side}`, params: { currency, rate } },
      { key: "alert.success.Expiration", params: { date } },
    ];

    this.tracker.reportProgress({
      action: actions.SUCCESS,
      data: { pair: currencyPair, rate: form.rate.toString() },
    });
    this.transaction.success({ source: "alert", bodyLines, modalType });
  }

  #onError(modalType: ModalType, error?: Failure) {
    this.tracker.reportProgress({ action: actions.FAILURE });
    this.transaction.error({ source: "alert", error, modalType });
  }
}
