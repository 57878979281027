import { Component, HostBinding } from "@angular/core";
import { ActivationService } from "@features/activation/activation.service";

@Component({
  selector: "app-applications-and-contracts",
  templateUrl: "applications-and-contracts.component.html",
})
export class ApplicationsAndContractsComponent {
  @HostBinding("class") class = "pko-activation__section";

  applications$ = this.activationService.activeApplications.clientOnly$;
  constructor(private activationService: ActivationService) {}
}
