import { Pipe, PipeTransform } from "@angular/core";
import { formatCurrencyPair } from "@shared/utils/format";

@Pipe({ name: "pair" })
export class CurrencyPairPipe implements PipeTransform {
  transform(pair: string | null | undefined) {
    if (!pair) return null;
    return formatCurrencyPair(pair);
  }
}
