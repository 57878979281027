<h4 class="d-inline-flex align-items-center">
  {{ "multifx.CurrencySum" | translate }}
  <app-tooltip content="multifx.CurrencySumTooltip"></app-tooltip>
</h4>

<ng-container *ngIf="currency$ | async as currency">
  <app-labeled-text-inline
    label="{{ 'multifx.Sum' | translate: { currency: currency } }}"
    [translateLabel]="false">
    <app-flag [icon]="currency" [label]="totalSum$ | async | balance: currency"></app-flag>
  </app-labeled-text-inline>
</ng-container>
