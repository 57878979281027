import { Component, HostBinding, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { AccountsService, getAccountByNumber } from "@features/accounts";
import { CurrencyService, getCurrencyByCode } from "@features/currency";
import { ConfirmationRequest, DecisionViewModel } from "@features/transaction/models/confirmation";
import { RateStreamable, isRateSuccess } from "@features/transaction/models/rate";
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  distinct,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  merge,
  of,
  pluck,
  share,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { DepositSummary } from "../../models/deposit-summary";
import { DepositFormService } from "../../services/deposit-form.service";
import { DepositService } from "../../services/deposit.service";

@Component({
  selector: "app-deposit-decision",
  templateUrl: "decision.component.html",
})
export class DepositDecisionComponent implements OnDestroy {
  @HostBinding("class") class = "pko-decision";
  private _confirm = new Subject<[ConfirmationRequest, DepositSummary]>();
  private confirmation$ = this._confirm.pipe(
    switchMap((data) => this.depositService.confirm(...data))
  );

  private _reject = new Subject<string>();
  private rejection$ = this._reject.pipe(
    distinct(),
    filter((token) => !!token),
    switchMap((token) => this.depositService.reject(token)),
    takeUntil(this._confirm)
  );

  private rate$ = this.route.params.pipe(
    pluck("token"),
    switchMap((token) => this.depositService.pollRate(token, this._reject)),
    share(),
    takeUntil(this._confirm)
  );

  loading$ = merge(
    this.rate$.pipe(map(() => false)),
    this.confirmation$.pipe(mapToBusy()),
    this.formService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$: Observable<DecisionViewModel<DepositSummary>> = this.rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private formService: DepositFormService,
    private accountsService: AccountsService,
    private currencyService: CurrencyService,
    private depositService: DepositService,
    private tracker: Tracker
  ) {
    this.subscription = this.rejection$.subscribe();
  }

  async ngOnDestroy() {
    this._reject.next(this.depositService.token);
    await lastValueFrom(this.rejection$);
    this.subscription.unsubscribe();
  }

  #constructViewModel(rate: RateStreamable): Observable<DecisionViewModel<DepositSummary>> {
    if (!isRateSuccess(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current;

    return combineLatest([
      this.accountsService.depositAccounts$,
      this.currencyService.allCurrencies$,
    ]).pipe(
      map(([accounts, currencies]) => {
        const summary = {
          ...rate,
          ...form,
          currency: getCurrencyByCode(currencies, form.currency),
          account: getAccountByNumber(accounts, form.account),
        } as any;
        const decision = {
          summary,
          time: rate.decisionTime,
          ...this.#getActions(rate.token, summary),
        };

        return { decision };
      })
    );
  }

  #trackRate({ decision }: DecisionViewModel<DepositSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.rate) return;

    this.tracker.reportProgress({
      action: "rate",
      data: {
        rate: summary.rate.toString(),
        token: summary.token,
        currency: summary.currency.code,
      },
    });
  }

  #getActions(rateToken: string, data: DepositSummary) {
    const transactionToken = this.depositService.token;
    return {
      onTimeout: () => this._reject.next(transactionToken),
      forward: rateToken
        ? () => {
            this.tracker.reportProgress({
              action: "confirm",
              data: { rate: data.rate.toString(), token: rateToken },
            });
            this._confirm.next([{ rateToken, transactionToken }, data]);
          }
        : () => this.formService.submit(),
    };
  }
}
