import { Pipe, PipeTransform } from "@angular/core";
import { WatchStatus } from "./models";

@Pipe({ name: "statusColor" })
export class StatusColorPipe implements PipeTransform {
  transform = (value: WatchStatus | string) => map[value as keyof typeof map] ?? defaultColor;
}

const defaultColor = "text-info";
const map = {
  Active: "text-success",
  Cancelled: "text-cta-orange",
  Executed: "text-black",
  Expired: defaultColor,
} as const;
