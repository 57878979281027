import { Component } from "@angular/core";
import { BusyService } from "@core/loading/busy.service";
import { ModalService } from "@features/modal/modal.service";
import { BehaviorSubject, combineLatest, tap } from "rxjs";
import { MarketNews } from "../market-news.model";
import { MarketNewsService } from "../market-news.service";
import { MarketNewsDetailsComponent } from "./market-news-details.component";

@Component({
  selector: "app-market-news",
  templateUrl: "./market-news.component.html",
})
export class MarketNewsComponent {
  readonly threshold = 5;
  toggle$ = new BehaviorSubject<boolean>(false);
  showAll$ = new BehaviorSubject<boolean>(false);
  news$ = combineLatest(
    [
      this.service.getNews().pipe(tap((news) => this.toggle$.next(news.length > this.threshold))),
      this.showAll$,
    ],
    (news, getAll) => news.slice(0, getAll ? news.length : this.threshold)
  );

  isBusy$ = this.busyService.observeOn("/marketnews");

  constructor(
    private service: MarketNewsService,
    private busyService: BusyService,
    private modalSerice: ModalService
  ) {}

  openDetails(news: MarketNews) {
    this.service.getNewsDetails(news.id).subscribe((data) =>
      this.modalSerice.modal({
        component: MarketNewsDetailsComponent,
        data,
        scrollable: true,
        size: "sm",
      })
    );
  }

  toggleExpansion() {
    this.showAll$.next(!this.showAll$.value);
  }
}
