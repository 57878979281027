import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "datePko" })
export class DatePkoPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  /**
   * @param value The date expression: a `Date` object, a number
   * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
   * @param precision Default is "day". Time precision can be specified further.
   * @returns A date string in the "dd.MM.yyyy" format.
   */
  transform(value: Date | string | number | null | undefined, precision: Precision = "day") {
    return this.datePipe.transform(value, format[precision]);
  }
}

type Precision = "day" | "hour" | "minute" | "second";

const format = {
  day: "dd.MM.yyyy",
  hour: "dd.MM.yyyy, HH",
  minute: "dd.MM.yyyy, HH:mm",
  second: "dd.MM.yyyy, HH:mm:ss",
} as const;
