<ng-container *ngIf="prefixes$ | async as prefixes" [formGroup]="group">
  <app-form-control
    [label]="label"
    validate="number"
    [inputGroup]="true"
    [class.flex-grow-1]="showButton">
    <ng-container *ngTemplateOutlet="flag ? flagTemplate : noFlagTemplate"></ng-container>
    <ng-template #flagTemplate>
      <app-select-flag
        [items]="prefixes"
        bindIcon="countryCode"
        [bindLabel]="prefixLabel"
        bindValue="countryCode"
        formControlName="country"
        ngDefaultControl>
      </app-select-flag>
    </ng-template>
    <ng-template #noFlagTemplate>
      <app-select
        [items]="prefixes"
        formControlName="country"
        [bindLabel]="prefixLabel"
        bindValue="countryCode"
        ngDefaultControl>
      </app-select>
    </ng-template>
    <input
      class="form-control d-block"
      [placeholder]="'placeholders.Number' | translate"
      formControlName="number"
      ngDefaultControl
      autocomplete="off"
      type="text"
      enterConfirm
      digitsOnly
      clearErrors
      (enterPressed)="emitEnter()"
      [digits]="(digits$ | async) || 9"
      pkoPhoneFormat />
  </app-form-control>
  <button
    type="submit"
    class="btn btn-primary ms-sm-3"
    [class.btn--label-align]="label"
    *ngIf="showButton">
    {{ "AddPhone" | translate }}
  </button>
</ng-container>
