// a newer version from Kantor IKO. Next step would be a lib:
//https://tanstack.com/query/v4/docs/react/community/angular-query
//https://tanstack.com/query/latest/docs/angular/overview

import { HttpErrorResponse } from "@angular/common/http";
import { Observable, catchError, map, of, startWith } from "rxjs";
import { Failure } from "./transaction";

type ApiSuccess<T> = { data: T; error?: null; pending?: false };
type ApiFailure = { data?: null; error: Failure; pending?: false };
type ApiPending = { data?: null; error?: null; pending: true };

export type ApiResponse<T = void> = ApiSuccess<T> | ApiFailure | ApiPending;

export const mapToApiResponse =
  <T = void>(defaultError?: Failure) =>
  ($: Observable<T>) =>
    $.pipe(
      map((data) => ({ data } as ApiSuccess<T>)),
      startWith({ pending: true } as ApiPending),
      catchError((error) => of(toApiFailure(error, defaultError)))
    );

function toApiFailure({ error }: HttpErrorResponse, defaultError?: Failure): ApiFailure {
  if (error?.code) {
    return { error: { code: error.code, data: error.data } };
  }

  return defaultError ? { error: defaultError } : { error: { code: "Unknown" } };
}

export function isApiSuccess<T>(response: ApiResponse<T>): response is ApiSuccess<T> {
  return !!response.data;
}

export function isApiFailure<T>(response: ApiResponse<T>): response is ApiFailure {
  return !!response.error;
}

export function isApiPending<T>(response: ApiResponse<T>): response is ApiPending {
  return !!response.pending;
}

export function mapToBusy<T>() {
  return ($: Observable<ApiResponse<T>>) => $.pipe(map(({ pending }) => pending));
}
