import { Attribute, Component, OnDestroy, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective, Validators } from "@angular/forms";
import { CurrencyPair, CurrencyService } from "@features/currency";
import { NonForwardTenor } from "@features/tenor";
import { EMPTY, filter, Observable, startWith, switchMap } from "rxjs";

@Component({
  selector: "app-form-rate",
  templateUrl: "rate.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class RateFormComponent implements OnInit, OnDestroy {
  currencyPair$: Observable<CurrencyPair | undefined> = EMPTY;

  constructor(
    private parent: FormGroupDirective,
    private currencyService: CurrencyService,
    @Attribute("tenor") public tenor?: NonForwardTenor
  ) {}

  get #controls() {
    return this.parent.form?.controls;
  }

  ngOnInit() {
    const { currencyPair } = this.#assertForm();

    this.currencyPair$ = currencyPair.valueChanges.pipe(
      startWith(currencyPair.value),
      filter(Boolean),
      switchMap((code) => this.currencyService.getCurrencyPair(code))
    );
  }

  ngOnDestroy() {
    const rate = this.#controls?.rate;
    if (!rate) return;

    rate.removeValidators(Validators.required);
    rate.updateValueAndValidity();
  }

  #assertForm() {
    const { currencyPair, rate } = this.#controls;

    if (!currencyPair || !rate) {
      throw new Error(
        "This component needs 'rate' and 'currencyPair' controls present on the form!"
      );
    }

    // when using this component, the rate is always required.
    // if requirements change, then either handle this externally
    // or add (possibly streamlined) setting validation through @Input
    rate.addValidators(Validators.required);

    return { currencyPair };
  }
}
