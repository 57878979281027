import { Component, Input, OnInit, Self } from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective, NgControl } from "@angular/forms";
import type { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "app-select-flag",
  templateUrl: "select-flag.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SelectFlagComponent implements OnInit {
  @Input("ngId") labelForId?: string;
  @Input() items: NgSelectComponent["items"] = [];
  @Input() bindValue = "code";

  // these three are not used by the ng-select api, only in the ngTemplateOutletContext
  // but the names follow ng-select convention for readability
  @Input() bindIcon!: string;
  @Input() bindLabel!: string;
  @Input() translateLabel = false;
  @Input() placeholder?: string;
  @Input() disableSingleValue = true;

  constructor(@Self() private controlDir: NgControl) {}

  get control() {
    return this.controlDir.control as FormControl;
  }

  ngOnInit(): void {
    this.bindLabel ??= this.bindValue;
    this.bindIcon ??= this.bindLabel;
  }
}
