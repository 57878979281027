import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective, Validators } from "@angular/forms";
import { ensureControlHasValue, getCurrencyByCode } from "@features/currency/currency-utils";
import { Currency } from "@features/currency/model";
import { defer, map, startWith } from "rxjs";

@Component({
  selector: "app-form-amount-currency",
  templateUrl: "amount-currency.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AmountCurrencyFormComponent implements OnInit, AfterViewInit {
  #currencies: Currency[] = [];

  decimals$ = defer(() => this.#getDecimalsStream());

  @Input() hideLabel = false;

  @Input() set currencies(value: Currency[] | null) {
    this.#currencies = value ?? [];

    const { currency } = this.#controls;
    if (!currency || !value) return;

    ensureControlHasValue(currency, 0)(value);
  }

  get currencies(): Currency[] {
    return this.#currencies;
  }

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective) {}

  ngOnInit() {
    this.#assertForm();
  }

  ngAfterViewInit(): void {
    this.#setValidators();
  }

  #assertForm() {
    const { currency, amount } = this.#controls;

    if (!currency || !amount) {
      throw new Error("This component needs 'amount' and 'currency' controls present on the form!");
    }

    return { currency };
  }

  #setValidators() {
    const { currency, amount } = this.#controls;

    currency.setValidators(Validators.required);
    amount.setValidators(Validators.required);

    amount.updateValueAndValidity();
  }

  #getDecimalsStream() {
    const { currency } = this.#controls;

    return currency.valueChanges.pipe(
      startWith(currency.value),
      map((code) => getCurrencyByCode(this.#currencies, code)),
      map((currency) => currency?.decimals ?? 2)
    );
  }
}
