<app-transaction-decision type="multifx" [loading$]="loading$" [vm$]="vm$">
  <ng-template #summary let-summary let-time="time">
    <app-multifx-summary-quote [quote]="summary" class="pko-grid__row"></app-multifx-summary-quote>
    <div class="pko-grid__row">
      <app-summary-detail [detail]="summary" class="col-12 col-md-6"></app-summary-detail>
      <div class="col-12 col-md-4 order-md-last pt-3">
        <app-summary-sum [detail]="summary"> </app-summary-sum>
        <app-margin-info
          [data]="summary"
          message="multifx.MarginInfo"
          *ngIf="time > 0"></app-margin-info>
      </div>
    </div>
  </ng-template>
</app-transaction-decision>
