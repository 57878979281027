<svg
  icon
  name="{{ icon }}"
  placement="{{ placement }}"
  [autoClose]="'outside'"
  [ngbTooltip]="tooltipContent"
  [class]="size ? ['align-' + placement, 'icon--' + size] : ['align-' + placement]"
  tooltipClass="pko-tooltip__window"
  triggers="manual"
  container="body"
  #t="ngbTooltip"
  (click)="t.open()"></svg>

<ng-template #tooltipContent>
  <div class="pko-tooltip__backdrop" (click)="t.close()"></div>
  <div class="pko-tooltip__container">
    <button
      type="button"
      class="btn btn-link pko-tooltip__close"
      [attr.aria-label]="'buttons.close' | translate"
      (click)="t.close()">
      <svg icon name="close_nav" class="icon icon--lg"></svg>
    </button>
    <div
      class="pko-tooltip__content"
      [innerHtml]="translateContent ? (content | translate : contentData) : content"
      *ngIf="content; else contentTemplate"></div>
    <button type="button" class="btn btn-secondary mt-4 d-sm-none" (click)="t.close()">
      {{ "OK" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
