export interface Prefix {
  code: string;
  countryCode: string;
  label?: string;
}

export interface PhoneForm {
  prefix: Prefix["code"];
  number?: string;
}

export const defaultPrefix: Prefix = { code: "+48", countryCode: "PL" };
