import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { round } from "@utils/number";

@Component({
  selector: "app-slider",
  templateUrl: "slider.component.html",
  styleUrls: ["slider.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  @HostBinding("class") class = "d-block";
  @Input() type: "single" | "range" = "single";
  @Input() min!: number;
  @Input() max!: number;
  @Input() step!: number;
  @Input() minSpread?: number;
  @Input("ngId") id!: string;
  @Input() numberFormat = "1.2-2";
  @Input() labelSuffix?: string;
  @Input() label?: string;
  @Input() labelData?: unknown;
  @Input() tooltip?: string;
  @Input() tooltipData?: unknown;

  @ViewChild("bar") bar!: ElementRef;
  @ViewChild("progress") progress!: ElementRef<HTMLElement>;
  @ViewChild("handle1") handle1!: ElementRef;
  @ViewChild("handle2") handle2!: ElementRef;
  @ViewChild("handle1Value") handle1Value!: ElementRef;
  @ViewChild("handle2Value") handle2Value!: ElementRef;

  progressElement?: HTMLElement;
  handle1Element!: HTMLElement;
  handle2Element!: HTMLElement;
  handle1ValueElement!: HTMLElement;
  handle2ValueElement!: HTMLElement;

  private el?: HTMLElement;
  private mouseDown = false;
  private last?: MouseEvent | TouchEvent;
  private offset = 0;
  private maxPixels = 0;
  private isSingle = true;

  private _disabled = false;
  @Input()
  public get disabled() {
    return this._disabled;
  }
  public set disabled(value) {
    this._disabled = value;
    this.cdr.detectChanges();
  }
  get opacity() {
    return this.max === this.min || this.disabled ? 0.25 : 1;
  }

  value: number | number[] | null = 0;
  private currentPosition = 0;
  private increment = 0;

  onChange = (value: number | number[] | null) => {};

  onTouched = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges({ min, max, type }: SimpleChanges = {}): void {
    if (!!type) this.isSingle = type.currentValue === "single";

    if (!this.progress) return;

    this.offset = this.bar.nativeElement.getBoundingClientRect().x;
    this.maxPixels = this.bar.nativeElement.clientWidth - 20;
    this.progress.nativeElement.style.width = "0px";

    if (!!min || !!max) {
      if (this.isSingle) {
        if (this.value && min && this.value < min.currentValue)
          return this.writeValue(min.currentValue);
        else if (this.value && max && this.value > max.currentValue)
          return this.writeValue(max.currentValue);
      } else {
        if (
          (this.value && min && (this.value as number[])[0] < min.currentValue) ||
          (this.value && max && (this.value as number[])[1] > max.currentValue)
        ) {
          return this.writeValue([
            !!min
              ? Math.max((this.value as number[])[0], min.currentValue)
              : (this.value as number[])[0],
            !!max
              ? Math.min((this.value as number[])[1], max.currentValue)
              : (this.value as number[])[1],
          ]);
        }
      }
    }

    this.#setValue();
  }

  ngAfterViewInit(): void {
    this.progressElement = this.progress.nativeElement;
    this.handle1Element = this.handle1.nativeElement;
    this.handle2Element = this.handle2?.nativeElement;
    this.handle1ValueElement = this.handle1Value.nativeElement;
    this.handle2ValueElement = this.handle2Value?.nativeElement;

    setTimeout(() => this.ngOnChanges());
  }

  getPixelsFromValue(value: number) {
    const max = this.maxPixels;

    if (this.max === this.min) return max;

    const range = this.max - this.min;
    return round(((value - this.min) * max) / range, 0);
  }

  getValueFromPixels(pixels: number) {
    const max = this.maxPixels;

    if (this.step >= this.max - this.min) {
      return (pixels * (this.max - this.min)) / max > (this.max - this.min) / 2
        ? this.max
        : this.min;
    }

    return pixels >= max
      ? this.max
      : round(
          round((pixels * (this.max - this.min)) / max / this.step, 0) * this.step + this.min,
          5
        );
  }

  writeValue(value: number | number[] | null): void {
    if (value instanceof Array && this.minSpread && value[1] - value[0] < this.minSpread) {
      if (this.el) {
        const firstChanged = this.el == this.handle1Element;
        this.value = firstChanged
          ? [value[1] - this.minSpread, value[1]]
          : [value[0], value[0] + this.minSpread];
        this.#setValue();
      }
      return;
    }

    if (
      (this.isSingle && this.value && this.value === value) ||
      (!this.isSingle &&
        this.value &&
        (this.value as number[])[0] === (value as number[])[0] &&
        (this.value as number[])[1] === (value as number[])[1])
    )
      return;

    this.value = value;
    this.#setValue();
    this.onChange(value);
  }

  registerOnChange(fn: (rating: number | number[] | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostListener("mouseup")
  onMouseup() {
    this.#releaseDraggedElement();
  }

  @HostListener("mouseleave", ["$event"])
  onMouseLeave(event: MouseEvent) {
    this.#handleLeave(event);
  }

  @HostListener("touchend")
  onTouchEnd() {
    this.#releaseDraggedElement();
  }

  @HostListener("mousemove", ["$event"])
  onMousemove(event: MouseEvent) {
    this.#dragElement(event);
  }

  @HostListener("touchmove", ["$event"])
  onTouchMove(event: TouchEvent) {
    this.#dragElement(event);
  }

  @HostListener("mousedown", ["$event"])
  onMousedown(event: MouseEvent) {
    if (this.disabled) return;
    this.#catchDraggedElement(event);
  }

  @HostListener("touchstart", ["$event"])
  onTouchStart(event: TouchEvent) {
    if (this.disabled) return;
    this.#catchDraggedElement(event);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: UIEvent) {
    this.offset = this.bar.nativeElement.getBoundingClientRect().x;
    this.maxPixels = this.bar.nativeElement.clientWidth - 20;
    this.#setValue();
  }

  #setValue() {
    if (!this.progressElement) return;
    if (!this.value) {
      this.progressElement.style.width = "0px";
      this.progressElement.style.left = "0px";
      this.handle1Element.style.left = "0px";
      this.handle2 && (this.handle2Element.style.left = "0px");
    } else {
      if (this.isSingle) {
        this.#moveHandle();
      } else {
        this.#moveHandleRange();
      }
    }
  }

  #catchDraggedElement(event: MouseEvent | TouchEvent) {
    const element = event.target as HTMLElement;

    const isSlider = Array.from(element.classList).some((x) => x.startsWith("pko-slider")),
      isHandle = element.classList.contains("pko-slider__handle");

    if (!isSlider && !isHandle) return;
    this.last = event;

    if (!isHandle) return;

    this.el = element;
    this.mouseDown = true;
    this.currentPosition = this.#getElementPosition(element);

    event.preventDefault();
  }

  #handleLeave(event: MouseEvent | TouchEvent) {
    const position = this.bar.nativeElement.getBoundingClientRect();
    if (this.mouseDown && this.last && this.el) {
      if (this.el === this.handle1Element && this.#getPosition(event) < position.left) {
        if (this.isSingle) {
          this.writeValue(this.min);
        } else {
          this.writeValue([this.min, (this.value as number[])[1]]);
        }
      } else if (this.el === this.handle2Element && this.#getPosition(event) > position.right) {
        if (this.isSingle) {
          this.writeValue(this.max);
        } else {
          this.writeValue([(this.value as number[])[0], this.max]);
        }
      }
      this.#releaseDraggedElement();
    }
  }

  #getElementPosition(element: HTMLElement) {
    return Number(`${element.style.left}`.replace("px", ""));
  }

  #dragElement(event: MouseEvent | TouchEvent) {
    const max = this.maxPixels;
    if (this.mouseDown && this.last && this.el) {
      let newLeft = this.currentPosition + this.#getPosition(event) - this.#getPosition(this.last);
      if (newLeft < 0) newLeft = 0;
      if (newLeft > max) newLeft = max;

      this.last = event;

      const currentPosition = this.currentPosition;
      this.currentPosition = newLeft;

      let value = this.#getRange(newLeft, this.el!);

      if (
        newLeft > 0 &&
        (this.max - this.min) / this.step > this.maxPixels &&
        (currentPosition == newLeft ||
          (this.isSingle && this.value === value) ||
          (!this.isSingle &&
            (this.value as number[])[0] == (value as number[])[0] &&
            (this.value as number[])[1] == (value as number[])[1]))
      ) {
        this.increment++;
      } else {
        this.increment = 0;
      }

      value = this.#checkBoundaries(value);
      this.writeValue(value);

      event.preventDefault();
    }
  }

  #moveHandle() {
    if (!this.isSingle || !this.progressElement) return;
    const max = this.maxPixels;
    const position = this.getPixelsFromValue(this.value as number);
    this.handle1Element.style.left = `${position}px`;
    this.handle1ValueElement.style.left = `${position - 10}px`;
    this.progressElement.style.width = `${position}px`;
  }

  #moveHandleRange() {
    if (this.isSingle || !this.progressElement) return;
    const max = this.maxPixels;
    const start = this.getPixelsFromValue((this.value as number[])[0]);
    const end = this.getPixelsFromValue((this.value as number[])[1]);
    this.progressElement.style.width = `${end - start}px`;
    this.progressElement.style.left = `${start}px`;
    this.handle1Element.style.left = `${start}px`;
    this.handle2Element.style.left = `${end}px`;

    const spread = end - start;

    const shiftStartLabel = spread < 60 ? 30 - spread / 2 : 0;
    this.handle1ValueElement.style.left = `${start - 10 - shiftStartLabel}px`;
    this.handle2ValueElement.style.left = `${end - 10 + shiftStartLabel}px`;
  }

  #getRange(newLeft: number, dragged: HTMLElement) {
    if (this.isSingle) {
      return this.getValueFromPixels(newLeft);
    } else {
      if (dragged == this.handle1Element) {
        const newVal = this.getValueFromPixels(newLeft);
        return [Math.max(newVal + this.increment * this.step, 0), (this.value as number[])[1]];
      } else {
        const newVal = this.getValueFromPixels(newLeft);
        return [
          (this.value as number[])[0],
          Math.min(newVal + this.increment * this.step, this.max),
        ];
      }
    }
  }

  #getPosition(event: MouseEvent | TouchEvent) {
    return (event instanceof MouseEvent ? event.clientX : event.touches[0].clientX) - this.offset;
  }

  #releaseDraggedElement() {
    this.mouseDown = false;
    this.increment = 0;
    if (this.el) {
      this.el = undefined;
    } else {
      const event = this.last;
      if (!event) return;
      if (this.isSingle) {
        const newLeft = this.#getPosition(event);
        let value = this.#getRange(newLeft, this.handle1Element);
        value = this.#checkBoundaries(value);
        this.writeValue(value);
        event.preventDefault();
      } else {
        const newLeft = this.#getPosition(event);
        let value = this.#getRange(
          newLeft,
          (this.getPixelsFromValue((this.value as number[])[0]) +
            this.getPixelsFromValue((this.value as number[])[1])) /
            2 >
            newLeft
            ? this.handle1Element
            : this.handle2Element
        );
        value = this.#checkBoundaries(value);
        this.writeValue(value);
        event.preventDefault();
      }
    }
    this.last = undefined;
  }

  #checkBoundaries(value: number | number[]) {
    if (this.isSingle) {
      if ((value as number) < this.min) value = this.min;
      else if ((value as number) > this.max) value = this.max;
    } else {
      if ((value as number[])[0] < this.min) (value as number[])[0] = this.min;
      else if ((value as number[])[1] > this.max) (value as number[])[1] = this.max;
    }
    return value;
  }
}
