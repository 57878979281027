import { Component, HostBinding, Input } from "@angular/core";
import { isBuying } from "@core/models/transaction";
import { RecentDeal } from "../../recent-history.model";

@Component({
  selector: "app-recent-history-item",
  templateUrl: "./recent-history-item.component.html",
})
export class RecentHistoryItemComponent {
  @HostBinding("class") class = "pko-recent-history__item";
  #deal!: RecentDeal;

  @Input()
  public get deal(): RecentDeal {
    return this.#deal;
  }
  public set deal(value: RecentDeal) {
    this.#deal = value;

    this.headerColor =
      {
        Unsettled: "text-warning",
        Settled: "text-success",
      }[value.settlementStatus] || "text-dark";

    this.isSelling = !isBuying(value.side);
    if (this.isSelling) value.amount *= -1;
  }

  headerColor!: string;
  isSelling!: boolean;
}
