<h4 class="pko-title pko-title--4">
  {{ "activation.ClientData" | translate }}
</h4>
<ng-container
  *ngTemplateOutlet="
    client.clientType === 'Institutional' ? institutional : individual;
    context: { $implicit: client }
  ">
</ng-container>

<ng-template #institutional let-client>
  <ng-container *ngIf="client.institutionalClient as c">
    <div class="pko-grid__row" style="--bs-gutter-x: 0">
      <div class="col-10 col-md-5">
        <app-definition
          label="activation.client.institutional.Name"
          value="{{ c.companyName }}"></app-definition>
        <app-definition
          label="activation.client.institutional.Regon"
          value="{{ c.regon }}"></app-definition>
      </div>
      <div class="col-10 col-md-5">
        <ng-container
          *ngTemplateOutlet="
            address;
            context: { $implicit: c.companyAddress, label: 'activation.CompanyAddress' }
          "></ng-container>
        <ng-container *ngTemplateOutlet="clientType"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #individual let-client>
  <ng-container *ngIf="client.individualClient as c">
    <ng-container
      *ngTemplateOutlet="individualSingle; context: { $implicit: c.clientA }"></ng-container>
    <ng-container *ngIf="c.clientB">
      <ng-container
        *ngTemplateOutlet="individualSingle; context: { $implicit: c.clientB }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #individualSingle let-c>
  <div class="pko-grid__row">
    <div class="col-10 col-md-5">
      <app-definition
        label="activation.client.individual.FullName"
        value="{{ c.firstName }}&nbsp;{{ c.middleName }}&nbsp;{{ c.lastName }}"></app-definition>
      <app-definition
        label="activation.client.individual.Pesel"
        value="{{ c.pesel }}"></app-definition>
      <app-definition
        label="activation.client.individual.BirthDate"
        value="{{ c.birthDate | datePko }}"></app-definition>
    </div>
    <div class="col-10 col-md-5">
      <ng-container
        *ngTemplateOutlet="
          address;
          context: { $implicit: c.houseAddress, label: 'activation.Address' }
        "></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #address let-a let-label="label">
  <app-definition [label]="label">
    {{ a.street }}&nbsp;{{ a.number }}<span *ngIf="a.unitNumber">/{{ a.unitNumber }}</span>
    <br />
    {{ a.zipCode }}&nbsp;{{ a.city }}
    <br />
    {{ a.country }}
  </app-definition>
</ng-template>

<ng-template #clientType>
  <dl class="pko-definition">
    <dt class="pko-definition__term pko-definition__term--tooltip">
      {{ "mifid.result.ClientType" | translate }}
      <app-tooltip>
        {{ "mifid.clientTypeTooltip.ClientCategories" | translate }}
        <ul class="mb-0">
          <li>{{ "mifid.clientTypeTooltip.RetailClient" | translate }}</li>
          <li>{{ "mifid.clientTypeTooltip.ProfessionalClient" | translate }}</li>
          <li>{{ "mifid.clientTypeTooltip.EligibleCounterparty" | translate }}</li>
        </ul>
        {{ "mifid.clientTypeTooltip.MoreClientInformation" | translate }}
      </app-tooltip>
    </dt>
    <dd class="pko-definition__description">
      {{ "mifid.result.RetailClient" | translate }}
    </dd>
  </dl>
</ng-template>
