<div
  class="pko-notification__container"
  [class.flex-column]="!child?.showIcon"
  (click)="child?.mainAction()">
  <svg
    icon
    [name]="icon"
    class="pko-notification__icon icon icon--lg"
    *ngIf="child?.showIcon"></svg>
  <div class="pko-notification__content" *ngIf="child?.showIcon; else content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>
<app-action-menu [menu]="menu" *ngIf="menu" class="pko-notification__menu"></app-action-menu>

<ng-template #content>
  <h6
    class="pko-notification__header"
    [innerHTML]="translateHeader ? (header | translate) : header"></h6>

  <div class="pko-notification__message">
    <ng-content></ng-content>
  </div>
</ng-template>
