import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { StatusResponse } from "@features/transaction/models";
import {
  BehaviorSubject,
  Observable,
  Subject,
  scan,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from "rxjs";

@Component({
  selector: "app-investment-deposit-waiting",
  templateUrl: "investment-deposit-waiting.component.html",
  styles: [
    `
      .circle {
        height: 100px;
      }
    `,
  ],
})
export class InvestmentDepositWaitingComponent implements OnInit {
  @HostBinding("class") class = "pko-countdown";

  @Output() finished = new EventEmitter<StatusResponse | undefined>();
  @Input() message?: string;
  @Input() startSignal!: BehaviorSubject<StatusResponse | undefined>;
  @Input() stopSignal!: Subject<void>;

  counter$!: Observable<number>;

  ngOnInit(): void {
    this.counter$ = this.startSignal.pipe(
      switchMap((status) => timer(status ? 0 : 10000, (status ? 5000 : 80000) / 90)),
      scan((acc: number) => acc + (this.startSignal.value && acc <= 10 ? 100 : 1), 10),
      takeWhile((acc: number) => acc <= 100, true),
      tap(
        (elapsed) =>
          elapsed >= 100 &&
          this.finished.emit(
            this.startSignal.value ?? { status: { code: "InvestmentDepositStatusUnknown" } }
          )
      ),
      takeUntil(this.stopSignal)
    );
  }
}
