import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "@core/navigation";

@Component({
  selector: "app-exchange",
  templateUrl: "exchange.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangeComponent {
  @HostBinding("class") class = "col-12 col-sm-10 position-relative";

  constructor(navigationService: NavigationService, router: Router) {
    navigationService.setupBack(() => router.navigate(["/"]));
  }
}
