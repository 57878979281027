import { Component, HostBinding } from "@angular/core";
import { assertIsDefined } from "@utils/misc";
import { DialogComponent } from "../base-dialog.component";

import { ModalService } from "../modal.service";

@Component({
  templateUrl: "page-dialog.component.html",
})
export class PageDialogComponent extends DialogComponent {
  @HostBinding("class") class = "pko-page";

  constructor(modalService: ModalService) {
    super();
    assertIsDefined(modalService.pageData);
    this.data = modalService.pageData;
  }

  onClicked() {
    // if needed, add `wait` prop
    // and then await onClicked("complete") -> close()
  }
}
