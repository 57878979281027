import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { ModalDialogComponent } from "./modal-dialog/modal-dialog.component";
import { ModalMenuComponent } from "./modal-menu/modal-menu.component";
import { PageDialogComponent } from "./page-dialog/page-dialog.component";
import { TileMessageComponent } from "./tile-message/tile-message.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    PageDialogComponent,
    ModalDialogComponent,
    ModalMenuComponent,
    TileMessageComponent,
  ],
  exports: [PageDialogComponent, ModalDialogComponent, TileMessageComponent],
})
export class ModalModule {}
