import { Pipe, PipeTransform } from "@angular/core";
import { ClientType } from "@features/digitalization-common/customer-data.model";

@Pipe({
  name: "clientType",
})
export class ClientTypePipe implements PipeTransform {
  transform(value: string, clientType: ClientType | boolean) {
    const mapped: ClientType =
      typeof clientType === "boolean" ? (clientType ? "Institutional" : "Individual") : clientType;
    return value + (mapped === "Institutional" ? "I" : "");
  }
}
