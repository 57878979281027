import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { UserService } from "@core/session";

@Injectable()
export class AppCanActivate implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (
      this.userService.hasRole(
        this.router.getCurrentNavigation()?.extras.state?.expectedRole ?? route.data.expectedRole
      )
    ) {
      return true;
    }

    const redirectionUrl =
      route.data.redirectionUrl ?? (this.userService.hasRole("HasCustomer") ? "/" : "/customer");
    return this.router.parseUrl(redirectionUrl);
  }
}
