import { Component, OnInit } from "@angular/core";
import { ControlContainer, FormArray, FormGroupDirective } from "@angular/forms";
import { defer, map, Observable, startWith } from "rxjs";
import { MultiFxLegForm } from "../../models/multifx-form";

@Component({
  selector: "app-form-multifx-sum",
  templateUrl: "./form-multifx-sum.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormMultifxSumComponent implements OnInit {
  totalSum$!: Observable<number>;
  currency$!: Observable<string>;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective) {}

  ngOnInit(): void {
    const { series, currency } = this.#assertForm();

    this.currency$ = defer(() => currency.valueChanges.pipe(startWith(currency.value)));
    this.totalSum$ = series.valueChanges.pipe(startWith(series.value), map(mapToTotalSum));
  }

  #assertForm() {
    const { currency, series } = this.#controls;

    if (!currency || !series || !(series instanceof FormArray)) {
      throw new Error(
        "This component needs 'currencyPair', 'currency', 'parForward' and 'series' as FormArray controls present on the form!"
      );
    }

    return { series, currency };
  }
}

const mapToTotalSum = (series: MultiFxLegForm[]) => {
  const amounts = series.map(({ side, amount }) => (side === "Sell" ? -amount : +amount));
  return amounts.reduce((partialSum, a) => partialSum + a, 0);
};
