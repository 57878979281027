import { Account } from "@features/accounts";

export const COLLATERAL_TYPES = {
  limit: "Limit",
  deposit: "Deposit",
  block: "AmountBlock",
} as const;

export type CollateralType = typeof COLLATERAL_TYPES[keyof typeof COLLATERAL_TYPES];

export interface CollateralForm {
  type: CollateralType;
  account?: string;
}

export interface Collateral {
  type: string;
  account?: Account;
  amount?: number;
  currency?: string;
}
