import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { handleFormErrors } from "@features/transaction/utils/form";
import { startWith, tap } from "rxjs";
import { Question, QuestionAnswer } from "../../mifid.model";
import { toQuestionAnswer } from "../../mifid.utils";

@Component({
  selector: "app-mifid-question-checkbox",
  templateUrl: "./mifid-question-checkbox.component.html",
})
export class MifidQuestionCheckboxComponent {
  #question!: Question;
  @Input() index!: number;
  @Input() total!: number;
  @Output() backClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<QuestionAnswer>();

  @Input() set question(question: Question) {
    this.#question = question;

    this.form = this.fb.group({
      answers: this.fb.array(
        this.question.answers.map((x) => this.fb.control(x.isRecentlyChosen)),
        this.#minLengthValidator
      ),
    });

    for (let i = 0; i < this.question.answers.length; i++) {
      if (this.question.answers[i].isRejected)
        this.answers.controls[i].valueChanges
          .pipe(
            startWith(this.question.answers[i].isRecentlyChosen),
            tap((x) => this.#updateVisibilityOnRejection(x, i))
          )
          .subscribe();
    }
  }

  get question(): Question {
    return this.#question;
  }

  form!: FormGroup;

  get answers() {
    return this.form.controls.answers as FormArray;
  }

  constructor(private fb: FormBuilder) {}

  next() {
    this.answers.updateValueAndValidity();
    if (!handleFormErrors(this.form)) return;

    const { answers } = this.form.getRawValue();
    const selectedIds =
      this.question.answers.map((x) => x.id).filter((_, index) => answers[index]) || [];
    this.question.answers.forEach((ans) => (ans.isRecentlyChosen = selectedIds.includes(ans.id)));
    this.nextClicked.emit(toQuestionAnswer(this.question));
  }

  back() {
    this.backClicked.emit();
  }

  #minLengthValidator: ValidatorFn = (arrayControl: AbstractControl): ValidationErrors | null => {
    const array = arrayControl as FormArray;
    const values = array.value as boolean[];
    return values.filter(Boolean).length > 0 ? null : { required: true };
  };

  #updateVisibilityOnRejection(value: boolean, index: number) {
    if (value) this.answers.controls.forEach((x, ind) => ind !== index && disableAndReset(x));
    else this.answers.controls.forEach((x, ind) => ind !== index && x.enable());
  }

  get hasError() {
    return !!this.form && !this.form.valid && (this.form.dirty || this.form.touched);
  }
}

const disableAndReset = (control: AbstractControl) => {
  control.reset();
  control.disable();
};
