import { Component, OnInit } from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective } from "@angular/forms";
import { DpwService } from "@features/dpw/dpw.service";

@Component({
  selector: "dpw-form-amount-currency-pair",
  template: `<app-form-amount-currencies
    [pairs]="pairs$ | async"
    [currencies]="currencies$ | async">
  </app-form-amount-currencies>`,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DpwAmountPairFormComponent implements OnInit {
  pairs$ = this.service.pairs$;
  currencies$ = this.service.currencies$;

  private get controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective, private service: DpwService) {}

  ngOnInit() {
    this.assertForm();
  }

  private assertForm() {
    if (this.controls.currencyPair) return;

    this.parent.form.addControl("currencyPair", new FormControl());
  }
}
