import { Component, Input } from "@angular/core";
import { baseCurrency } from "@shared/utils/currency";
import { OrderSummary, OrderType } from "../../models/order-form";

@Component({
  selector: "app-order-summary-quote",
  templateUrl: "summary-quote.component.html",
})
export class OrderSummaryQuoteComponent {
  private _counterAmount?: number;
  private _quote!: OrderSummary;

  @Input() set quote(quote: OrderSummary) {
    this._quote = quote;

    if (quote.type === OrderType.Limit) {
      this._counterAmount = this.calculateCounterAmount(quote);
    }
  }

  get quote() {
    return this._quote;
  }

  get currency() {
    return this.quote.currency.code;
  }

  get counterCurrency() {
    return this.quote.counterCurrency.code;
  }

  get target() {
    return this.quote.rate || this.quote.points;
  }

  get counterAmount() {
    return this._counterAmount;
  }

  private calculateCounterAmount(quote: OrderSummary) {
    const { amount, rate, currency, counterCurrency, currencyPair } = quote;
    const multiplier = ["JPYPLN", "HUFPLN"].includes(currencyPair) ? 100 : 1;

    const calc =
      baseCurrency(currencyPair) === currency.code
        ? (+amount * +rate) / multiplier
        : (+amount / +rate) * multiplier;

    return parseFloat(calc.toFixed(counterCurrency.decimals));
  }
}
