import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Lei, UserService } from "@core/session";
import { catchError, map, of, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LeiService {
  constructor(private http: HttpClient, private userService: UserService) {}

  saveLei(lei: string) {
    return this.http.post<void>("/user/save-lei", `"${lei}"`).pipe(
      map(() => true),
      tap(() => {
        this.userService.userData.leiMessage.lei = { code: lei } as Lei;
      }),
      catchError(() => of(false))
    );
  }

  skipLei() {
    return this.http.post<void>("/user/skip-lei", null);
  }
}
