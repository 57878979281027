<div class="pko-customer-list__item-initials">{{ customer.name | initials: 2 }}</div>
<div class="pko-customer-list__item-container">
  <div class="pko-customer-list__item-name">{{ customer.name }}</div>
  <div class="pko-customer-list__item-code">
    <span *ngIf="customer.shortName">{{ customer.shortName }} |</span> PID {{ customer.pid }}
  </div>
</div>
<ng-container *ngFor="let item of actions.buttons">
  <button
    class="btn btn-link"
    (click)="item.callback?.(); $event.stopPropagation()"
    [translate]="item.text"></button>
</ng-container>
<app-action-menu class="pko-dashboard__section-menu" [menu]="actions.menuItems"></app-action-menu>
