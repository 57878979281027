import { Component, Host, HostBinding, Input, Optional, SkipSelf } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";
import { ValidationErrorOverrides } from "../model";

@Component({
  selector: "app-form-control",
  templateUrl: "form-control.component.html",
})
export class FormControlComponent {
  @HostBinding("class") class = "d-block";
  @Input() className?: string;
  /**
   * Control, or name of the control inside the component that should be error tracked.
   * @usage
   * Note that when supplying the name, in order for it to work, the `parent` constructor parameter is required,
   * so the component hosting this FormControlComponent (i.e. its parent) needs to provide `ControlContainer`
   * via the `viewProviders`.
   */
  @Input("validate") controlToValidate?: string | AbstractControl | null;
  @Input("errors") errorOverrides?: ValidationErrorOverrides;
  @Input() inputGroup = false;
  @Input() label?: string;
  @Input() translateLabel = true;
  @Input() hint?: string;
  @Input() tooltip = "";
  @Input() tooltipData: unknown = {};

  /**
   * Creates a new instance of FormControlComponent, a wrapper for form inputs.
   * @param parent Direct parent of the wrapper. Used to track errors of the control specified in `controlToValidate`.
   *               Optional for when validation is not needed.
   */
  constructor(@Host() @SkipSelf() @Optional() private parent: ControlContainer) {}

  get control() {
    if (!this.controlToValidate) return;

    return typeof this.controlToValidate === "string"
      ? this.parent?.control?.get(this.controlToValidate)
      : this.controlToValidate;
  }

  get hasError() {
    return !!this.control && !this.control.valid && (this.control.dirty || this.control.touched);
  }
}
