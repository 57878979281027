import { Failure, Side } from "@core/models/transaction";
import { Account } from "@features/accounts";
import { Currency, CurrencyPair } from "@features/currency";
import { PhoneForm } from "@features/telephone/telephone-model";

export enum OrderType {
  Limit = "Limit",
  StopMarket = "StopMarket",
  TrailingStop = "TrailingStop",
}

export interface OrderForm {
  type: OrderType;
  side: Side;
  amount: number | string;
  currency: string;
  counterCurrency: string;
  currencyPair: string;
  rate: number | string;
  points: number | string;
  account: string;
  counterAccount: string;
  tenor: string;
  phone: PhoneForm;
  expiration: { date: string; time: string };
  pendingConversion: boolean;
  other?: OrderOcoForm;
}
export type OrderOcoForm = Omit<OrderForm, "expiration" | "phone" | "other">;

export interface OrderFormData {
  tenors: string[];
  orderTypes: OrderType[];
  currencyPairs: OrderPair[];
  currencies: Currency[];
  // timeSpan in milliseconds
  defaultExpiration: number;
  pendingConversionEnabled: boolean;
  hasPairs: boolean;
}

export interface OrderPair extends CurrencyPair {
  limit: boolean;
  stopMarket: boolean;
  trailingStop: boolean;
}

export type OrderSummary = SummarySpecials &
  Omit<OrderForm, "account" | "counterAccount" | "currency" | "counterCurrency">;

interface SummarySpecials {
  currency: Currency;
  counterCurrency: Currency;
  account: Account;
  counterAccount: Account;
  oco?: Omit<OrderSummary, "oco">;
}

export type OrderInitialized = { token: string };
export type CreateOrderResponse = OrderInitialized | { error: Failure };
export const isInitialized = (response: CreateOrderResponse): response is OrderInitialized =>
  "token" in response;

export type ConfirmationData = Pick<
  OrderSummary,
  "side" | "amount" | "currency" | "rate" | "points"
>;

export type SuccessData = ConfirmationData & { transactionId: string };

export const formDefaults: Partial<OrderForm> = {
  type: OrderType.Limit,
  currency: "EUR",
  counterCurrency: "PLN",
  side: "Sell",
};
