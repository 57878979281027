import { Component, ContentChild, HostBinding, Input, TemplateRef } from "@angular/core";
import { GroupedArrayElement } from "@utils/collection";

@Component({
  selector: "app-grouped-list",
  templateUrl: "./grouped-list.component.html",
})
export class GroupedListComponent {
  @HostBinding("class") class = "d-block";
  @Input() groups!: GroupedListElement<unknown>[] | undefined;
  @Input() byDate = false;
  @Input() groupLabel: string | undefined;
  /** flatList - displays list items in one collection without header */
  @Input() flatList = false;
  @Input() dateFormat = "EEEE, dd.MM.yyyy";
  @Input() emptyMessage = "missingData.Generic";
  @Input() emptyGroupMessage = "missingData.Generic";
  /** displayAs = "table" - display grouped list as mobile table  */
  @Input() displayAs: "table" | "default" = "default";
  @ContentChild("itemTemplate", { static: true, read: TemplateRef })
  itemTemplate?: TemplateRef<unknown>;
}

export type GroupedListElement<T> = GroupedArrayElement<T> & { showEmpty?: boolean };
