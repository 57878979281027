<ng-container *ngIf="items$ | async as items">
  <div *ngIf="items.length; else empty" class="d-sm-none">
    <div *ngFor="let dpw of items">
      <div class="pko-group__item" dpw-list-item [dpw]="dpw" role="stack"></div>
    </div>
  </div>

  <table
    class="d-none d-sm-table"
    pkoTable
    [columns]="columns"
    [emptyMessage]="missingData"
    [items]="items">
    <ng-template #itemTemplate let-item>
      <tr
        class="pko-table__row pko-table__row-dropdown align-middle"
        dpw-list-item
        [dpw]="item"
        role="row"></tr>
    </ng-template>
  </table>
</ng-container>

<ng-template #empty> <app-alert class="d-sm-none" [content]="missingData"></app-alert></ng-template>
