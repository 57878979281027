import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NavigationService } from "@core/navigation";
import { UserService } from "@core/session";
import { Tracker } from "@core/user-tracking/tracker.service";
import { origins } from "@core/user-tracking/tracking.model";
import { NormalExchangeForm } from "@features/exchange/models";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { PRODUCT_TYPES, isSpotTenor } from "@features/tenor";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Subscription, filter, skip, tap } from "rxjs";
import { pageClasses, prepareForm, tileClasses } from "../../exchange-utils";
import { ExchangeFormService } from "../../services";

@Component({
  selector: "app-form-exchange-spot",
  templateUrl: "form-exchange-spot.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpotExchangeFormComponent implements OnInit, OnDestroy {
  @Input() isTile = false;
  #subscription = new Subscription();
  form: FormGroup;

  readonly maxTenor?: "SPOT";

  back = () => this.navigationService.navigate(["/"]);

  get classes() {
    return this.isTile ? tileClasses : pageClasses;
  }

  constructor(
    fb: FormBuilder,
    userService: UserService,
    private formService: ExchangeFormService,
    private navigationService: NavigationService,
    public warningService: ExchangeWarningCodeService,
    private elem: ElementRef,
    private tracker: Tracker
  ) {
    const form = formService.current as NormalExchangeForm;

    const { tenor, date } = form.settlement;

    this.form = fb.group({
      side: form.side,
      currency: form.currency,
      counterCurrency: form.counterCurrency,
      amount: form.amount,
      account: form.account,
      counterAccount: form.counterAccount,
      pvp: form.pvp,
      product: null,
      settlement: fb.group({
        tenor: ensureSpot(tenor),
        // date is set by tenor, unless the user picked a date without a tenor.
        date: tenor ? null : date,
      }),
      collateral: fb.group(form.collateral ?? {}),
    });

    const product$ = this.form.controls.product.valueChanges.pipe(
      filter((product) => product === PRODUCT_TYPES.forward),
      tap(() => this.formService.change("forward"))
    );

    const tab$ = this.formService.formType$.pipe(
      skip(1),
      filter((type) => type !== "swap"),
      tap(() => this.#save())
    );

    this.#subscription.add(product$.subscribe());
    this.#subscription.add(tab$.subscribe());

    if (!userService.hasRole("FxForward")) {
      this.maxTenor = "SPOT";
    }
  }

  ngOnInit(): void {
    this.warningService.outOfTargetMarket = false;
    this.isTile ||
      this.tracker.follow(
        { process: "exchange", origin: history.state?.origin ?? "reload" },
        { type: "spot" }
      );
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
  }

  onSubmit() {
    this.#save();
    const componentSelector = this.elem.nativeElement.tagName.toLowerCase();

    if (handleFormErrors(this.form, componentSelector)) {
      this.isTile &&
        this.tracker.follow({ process: "exchange", origin: origins.TILE }, { type: "spot" });
      this.formService.submit();
    }
  }

  #save = () => this.formService.save(prepareForm(this.form));
}

const ensureSpot = (tenor?: string | null) => (isSpotTenor(tenor) ? tenor : "TOD");
