import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { Tracker } from "@core/user-tracking/tracker.service";

@Component({
  selector: "app-iob-form",
  templateUrl: "iob-form.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class IobFormComponent implements OnInit, AfterViewInit {
  get side() {
    return this.#controls.side.value;
  }

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private cdr: ChangeDetectorRef,
    private tracker: Tracker
  ) {}

  ngOnInit(): void {
    this.tracker.follow({ process: "iob", origin: history.state?.origin ?? "reload" });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
