import { Injectable } from "@angular/core";
import { BreakpointObserverService } from "@core/breakpoints";
import { PreferencesService } from "@core/preferences/preferences.service";
import { areSome } from "@utils/collection";
import { combineLatest, map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  simple$: Observable<boolean> = this.preferences.dashboard$.pipe(map((x) => x.simple));

  showAsSimple$ = combineLatest([this.simple$, this.breakpointObserver.isBreakpoint("xs")]).pipe(
    map(areSome(true))
  );

  constructor(
    private preferences: PreferencesService,
    private breakpointObserver: BreakpointObserverService
  ) {}
}
