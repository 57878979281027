import { AbstractControl } from "@angular/forms";
import { DataResult } from "@core/models/data-result";
import { baseCurrency, quoteCurrency } from "@shared/utils/currency";
import { groupAndSort } from "@shared/utils/list";
import { GroupedArray } from "@utils/collection";
import { Observable, distinctUntilChanged, filter, tap } from "rxjs";
import { Currency, CurrencyPair } from "../currency";
import { HistoryItem, PageData } from "./models";

export function resetFieldWhenEmpty(formControl: AbstractControl, defaultValue: any = null) {
  return formControl.valueChanges.pipe(
    distinctUntilChanged(),
    filter((value) => !value || value === defaultValue),
    tap(() => formControl.reset(defaultValue))
  );
}

export function markControlsAsDirtyIfNonEmptyValue(
  controls: Record<string, AbstractControl>,
  defaults: Record<string, any> = {}
) {
  Object.entries(controls)
    .filter(([key, control]) => !hasEmptyValue(control, defaults[key]))
    .forEach(([, control]) => control.markAsDirty());
}

export function countNonEmptyValueControls(
  controls: Record<string, AbstractControl>,
  defaults: Record<string, any> = {}
) {
  return Object.entries(controls).filter(([key, control]) => !hasEmptyValue(control, defaults[key]))
    .length;
}

export function hasEmptyValue(control: AbstractControl, defaultValue: any = null) {
  const value = control.value;
  return (
    !value ||
    value == "All" ||
    (typeof value === "object" &&
      (Object.values(value).length === 0 ||
        Object.entries(value).every(([key, value]) => !value || value == defaultValue[key])))
  );
}

export function countDirtyControls(controls: Record<string, AbstractControl>): number {
  return Object.entries(controls).filter(([, control]) => control.dirty && control.value !== "All")
    .length;
}

export function groupAndSortDataResult<T>(keySelector: (current: T) => string, descending = true) {
  return (result: DataResult<T[]>): DataResult<GroupedArray<T>> => {
    if (result.error || !result.data) return { error: true };

    return { data: groupAndSort(keySelector, result.data, descending) };
  };
}

export const getItemsWithDetails =
  <TDetail, TItem extends HistoryItem>(
    fetcher:
      | ((id: string) => Observable<TDetail>)
      | ((id: string, item: TItem) => Observable<TDetail>)
  ) =>
  ({ items, isLastPage }: PageData<TItem>) => ({
    isLastPage,
    items: items.map((item) => ({ ...item, details$: fetcher(item.id, item) })),
  });

export const isMatchingCurrency = (pair: string, currency: Currency) =>
  currency.code === baseCurrency(pair) || currency.code === quoteCurrency(pair);

export const isMatchingPair = (pair: CurrencyPair, currency: string) =>
  currency === baseCurrency(pair.code) || currency === quoteCurrency(pair.code);
