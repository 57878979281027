import { Injectable } from "@angular/core";
import { errorDialog, successDialog, WatchStatusOption } from "@features/history-common/models";
import { ModalService } from "@features/modal/modal.service";
import {
  BehaviorSubject,
  filter,
  map,
  merge,
  scan,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs";
import { AlertFilters, AlertListData } from "../models/alert-list";
import { AlertApiService } from "./alert-api.service";

@Injectable()
export class AlertHistoryService {
  #filters = new BehaviorSubject<AlertFilters>({ status: "Active", pageNumber: 1 });
  #cancel = new Subject<string>();

  #incoming$ = this.#filters.pipe(switchMap((filters) => this.api.getList(filters)));
  #canceled$ = this.#cancel.pipe(switchMap((id) => this.#handleCancel(id)));

  alerts$ = merge(this.#incoming$, this.#canceled$).pipe(
    withLatestFrom(this.#filters),
    scan(alertAccumulator, {} as AlertListData)
  );

  constructor(private api: AlertApiService, private modal: ModalService) {}

  changeStatus(status: WatchStatusOption) {
    this.#filters.next({ status, pageNumber: 1 });
  }

  loadMore() {
    const { status, pageNumber } = this.#filters.getValue();
    this.#filters.next({ status, pageNumber: pageNumber + 1 });
  }

  cancel(id: string) {
    this.#cancel.next(id);
  }

  #handleCancel(id: string) {
    return this.api.cancel(id).pipe(
      tap((ok) =>
        this.modal.dialog(
          ok ? successDialog("Alert", () => this.modal.dismissAll()) : errorDialog("Alert")
        )
      ),
      filter(Boolean),
      map(() => id)
    );
  }
}

const alertAccumulator = (
  state: AlertListData,
  [idOrListData, { pageNumber }]: [string | AlertListData, AlertFilters]
) =>
  typeof idOrListData === "string"
    ? remove(state, idOrListData)
    : add(state, idOrListData, pageNumber === 1);

const remove = ({ isLastPage, items }: AlertListData, id: string) => ({
  isLastPage,
  items: items.filter((alert) => alert.id !== id),
});

const add = ({ items }: AlertListData, next: AlertListData, reload: boolean) =>
  reload ? next : { ...next, items: [...items, ...next.items] };
