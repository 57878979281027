import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivate } from "@shared/app-guard";
import { OrderDecisionComponent } from "./components/decision/order-decision.component";
import { OrderFormComponent } from "./components/form-order/form-order.component";

const routes: Routes = [
  {
    path: "",
    component: OrderFormComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "Orders|ProUser" },
  },
  {
    path: "decision",
    component: OrderDecisionComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "Orders|ProUser" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderRoutingModule {}
