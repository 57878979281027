import { Injectable } from "@angular/core";
import { UserService } from "@core/session";
import { Filters, PageData, View360Details } from "@features/history-common/models";
import { EMPTY, Observable, defer, map, of } from "rxjs";
import { Deal, IListItem, PricingsConfig, isSimple, isStrategy } from "../models/view360-list";
import { HistoryCommonService } from "./history-common.service";
import { View360ApiService } from "./view360-api.service";

@Injectable({ providedIn: "root" })
export abstract class View360Service extends HistoryCommonService<Filters, IListItem> {
  abstract getList(filters: Filters): Observable<PageData<IListItem>>;

  protected getPageData$: Observable<PageData<IListItem>> = defer(() => {
    if (this._allLoaded) return EMPTY;
    return this.getList(this.filters).pipe(
      map(getItemsWithDetails(this.api.getDetails.bind(this.api), this.filters))
    );
  }).pipe(this.handlePageData());

  constructor(protected api: View360ApiService, private userService: UserService) {
    super();
  }

  getPricingsConfig(): Observable<PricingsConfig> {
    return this.userService.hasRole("HasCustomer")
      ? this.api.pricingsConfig$
      : of({
          currentPricingDate: new Date(),
          pricingsDates: [],
          pricingsVisible: false,
        } as PricingsConfig);
  }
}

const getItemsWithDetails =
  (fetcher: (id: string, pricingsDate?: Date) => Observable<View360Details>, filters: Filters) =>
  ({ items, isLastPage }: PageData<IListItem>) => ({
    isLastPage,
    items: items.map((item) =>
      isSimple(item)
        ? { ...item, details$: fetcher(item.id, filters.valuationsDate) }
        : isStrategy(item)
        ? {
            ...item,
            deals: item.deals.map((x) => addDetails(x, fetcher)),
          }
        : item
    ),
  });

const addDetails = (item: Deal, fetcher: (id: string) => Observable<View360Details>) => ({
  ...item,
  details$: fetcher(item.id),
});
