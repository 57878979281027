import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "progress",
})
export class ProgressPipe implements PipeTransform {
  transform(value: number, max: number, threshold = 0.25) {
    return value / max <= threshold ? "warning" : "primary";
  }
}
