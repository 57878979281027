import { Component, Input } from "@angular/core";
import { BreakpointObserverService } from "@core/breakpoints";
import { BusyService } from "@core/loading/busy.service";
import { ActionItem } from "@core/menus/action-item";
import { BlotterSettingsComponent } from "@features/blotter-graph/settings/blotter-settings.component";
import { DashboardService } from "@features/dashboard/dashboard.service";
import { ModalService } from "@features/modal/modal.service";
import { areSome } from "@utils/collection";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-recent-history-view",
  templateUrl: "./recent-history-view.component.html",
})
export class RecentHistoryViewComponent {
  @Input() sectionTitle = "history.recent.RecentTransactions";

  listType: "recent" | "unsettled" | "chart" = "recent";

  showAsSimple$ = combineLatest([
    this.dashboardService.simple$,
    this.breakpointObserver.isBreakpoint(["xs"]),
  ]).pipe(map(areSome(true)));

  isBusy$ = this.busyService.observeOn("/history", "/graph/blotter");

  menu = new BehaviorSubject<ActionItem[] | null>(null);

  constructor(
    private dashboardService: DashboardService,
    private busyService: BusyService,
    private modalService: ModalService,
    private breakpointObserver: BreakpointObserverService
  ) {}

  tabChange(id: string) {
    this.menu.next(
      id === "chart"
        ? [
            {
              text: "blotter.EditCurrencies",
              callback: () => this.selectCurrencies(),
            } as ActionItem,
          ]
        : null
    );
  }

  selectCurrencies() {
    this.modalService.modal({
      component: BlotterSettingsComponent,
      scrollable: true,
    });
  }
}
