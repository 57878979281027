import { Component, HostBinding } from "@angular/core";
import { UserService } from "@core/session";
import { ModalService } from "@features/modal/modal.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs";
import { FlowDeclaration } from "../../flow-declaration.model";
import { FlowDeclarationService } from "../../flow-declaratoin.service";
import { FlowDeclarationNewModalComponent } from "../flow-declaration-new-modal/flow-declaration-new-modal.component";

@Component({
  selector: "app-flow-declaration-modal",
  templateUrl: "./flow-declaration-modal.component.html",
})
export class FlowDeclarationModalComponent {
  @HostBinding("class") class = "pko-modal";
  flowDeclaration: FlowDeclaration;
  canBePostponed: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private service: FlowDeclarationService,
    private userService: UserService
  ) {
    this.flowDeclaration = this.userService.userData.flowsDeclaration.declaration;
    this.canBePostponed = this.userService.userData.flowsDeclaration.canBePostponed;
  }

  postpone(): void {
    this.service.postpone().pipe(take(1)).subscribe();
    this.activeModal.close();
  }

  refuse(): void {
    const declaration = {
      dateFrom: new Date(),
      dateTo: this.flowDeclaration.dateTo,
      refuseToAnswer: true,
      flowsData: [],
      isActual: true,
    };
    this.service.save(declaration).pipe(take(1)).subscribe();
    this.activeModal.close();
  }

  new(): void {
    this.activeModal.close();
    this.modalService.modal({
      component: FlowDeclarationNewModalComponent,
    });
  }
}
