import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AlertsPageComponent } from "./components/alerts.component";
import { AlertFormComponent } from "./components/form-alert/form-alert.component";

const routes: Routes = [
  {
    path: "",
    component: AlertFormComponent,
    data: { title: "pages.Alert" },
  },
  {
    path: "history",
    component: AlertsPageComponent,
    data: { title: "pages.Alerts" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlertRoutingModule {}
