<dl class="pko-definition__list">
  <app-definition label="exchange.Type" [value]="'exchange.type.' + detail.product | translate">
  </app-definition>

  <app-definition
    label="exchange.Subtype"
    [value]="'exchange.history.subtypes.' + detail.subtype | translate"
    *ngIf="detail.subtype">
  </app-definition>

  <app-definition
    label="exchange.Subtype"
    [value]="'exchange.NetSettlement' | translate"
    *ngIf="detail.netSettlement">
  </app-definition>

  <app-summary-accounts [accounts]="detail"></app-summary-accounts>

  <app-summary-collateral [collateral]="detail.collateral" *ngIf="showCollateral">
  </app-summary-collateral>
</dl>
