import { Component, HostBinding } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { MultiFxTransactionRateFailure } from "../models/multifx-rate";
import { MultiFxFormService } from "../services/multifx-form.service";

@Component({
  selector: "app-multifx",
  templateUrl: "./multifx.component.html",
})
export class MultiFxComponent {
  @HostBinding("class") class = "position-relative";

  seriesErrors?: MultiFxTransactionRateFailure[];

  constructor(private formService: MultiFxFormService) {
    const { retain, errors } = window.history.state;
    this.seriesErrors = errors;
    formService.reset(retain);
  }

  isBusy$ = this.formService.submission$.pipe(mapToBusy());
}
