import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { FlowDeclarationApplicationModalComponent } from "./components/flow-declaration-application-modal/flow-declaration-application-modal.component";
import { FlowDeclarationApplicationPanelComponent } from "./components/flow-declaration-application-panel/flow-declaration-application-panel.component";
import { FlowDeclarationEditComponent } from "./components/flow-declaration-edit/flow-declaration-edit.component";
import { FlowDeclarationInfoComponent } from "./components/flow-declaration-info/flow-declaration-info.component";
import { FlowDeclarationModalComponent } from "./components/flow-declaration-modal/flow-declaration-modal.component";
import { FlowDeclarationNewModalComponent } from "./components/flow-declaration-new-modal/flow-declaration-new-modal.component";
import { FlowDeclarationSummaryActiveComponent } from "./components/flow-declaration-summary-active/flow-declaration-summary-active.component";
import { FlowDeclarationSummaryInactiveComponent } from "./components/flow-declaration-summary-inactive/flow-declaration-summary-inactive.component";
import { FlowDeclarationSummaryRefusalComponent } from "./components/flow-declaration-summary-refusal/flow-declaration-summary-refusal.component";
import { FlowDeclarationSummaryComponent } from "./components/flow-declaration-summary/flow-declaration-summary.component";
import { FlowDeclarationViewComponent } from "./components/flow-declaration-view/flow-declaration-view.component";
import { TemporaryFlowDeclarationSummaryComponent } from "./components/temporary-flow-declaration-summary/temporary-flow-declaration-summary.component";
import { FlowsGuard } from "./flows.guard";

@NgModule({
  imports: [TranslateModule, SharedModule],
  declarations: [
    FlowDeclarationModalComponent,
    FlowDeclarationNewModalComponent,
    FlowDeclarationEditComponent,
    FlowDeclarationViewComponent,
    FlowDeclarationSummaryComponent,
    FlowDeclarationSummaryRefusalComponent,
    FlowDeclarationSummaryInactiveComponent,
    FlowDeclarationSummaryActiveComponent,
    FlowDeclarationApplicationPanelComponent,
    FlowDeclarationApplicationModalComponent,
    FlowDeclarationInfoComponent,
    TemporaryFlowDeclarationSummaryComponent,
  ],
  exports: [
    FlowDeclarationModalComponent,
    FlowDeclarationNewModalComponent,
    FlowDeclarationEditComponent,
    FlowDeclarationViewComponent,
    FlowDeclarationApplicationPanelComponent,
    FlowDeclarationApplicationModalComponent,
    FlowDeclarationSummaryComponent,
    TemporaryFlowDeclarationSummaryComponent,
  ],
  providers: [FlowsGuard],
})
export class FlowDeclarationModule {}
