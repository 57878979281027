import { AfterViewChecked, Directive, ElementRef, HostListener, Self } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";

@Directive({ selector: "[pkoPhoneFormat]" })
export class TelephoneFormatDirective implements AfterViewChecked {
  constructor(@Self() private ngControl: NgControl, private el: ElementRef<HTMLInputElement>) {}

  ngAfterViewChecked(): void {
    if (this.element !== document.activeElement) {
      this.onBlur();
    }
  }

  get control(): AbstractControl | null {
    return this.ngControl.control;
  }

  get element(): HTMLInputElement {
    return this.el.nativeElement;
  }

  get rawValue() {
    return this.element.value.replace(/\s/g, "");
  }

  @HostListener("focus") onFocus(): void {
    this.ngControl.valueAccessor?.writeValue(this.rawValue);
  }

  @HostListener("blur") onBlur(): void {
    this.ngControl.valueAccessor?.writeValue(formatPhoneNumber(this.rawValue));
  }
}

const formatPhoneNumber = (phone: string) => {
  return phone && (phone.match(/.{1,3}/g) || [""]).join(" ");
};
