<ng-container *ngTemplateOutlet="label ? containedSelect : select"></ng-container>

<ng-template #select>
  <ng-container [formGroup]="group">
    <ng-select
      class="{{ size && 'form-select-' + size }} {{ className }}"
      [labelForId]="labelForId"
      [formControl]="control"
      [items]="items"
      [placeholder]="!control.disabled && placeholder ? (placeholder | translate) : null"
      [bindLabel]="bindLabel ? bindLabel : ''"
      [bindValue]="bindValue ? bindValue : ''"
      [multiple]="multiple"
      [searchable]="searchable"
      [clearable]="clearable"
      [notFoundText]="'placeholders.SelectNotFound' | translate"
      (change)="selected.emit($event)">
      <ng-template ng-label-tmp ng-option-tmp let-item="item" *ngIf="translateText">
        {{
          ((bindLabel ? item[bindLabel] : item) !== "" ? translateText + "." : "") +
            (bindLabel ? item[bindLabel] : item) | translate
        }}
      </ng-template>
      <ng-template ng-label-tmp ng-option-tmp let-item="item" *ngIf="template">
        <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ item: item }">
        </ng-template>
      </ng-template>
    </ng-select>
  </ng-container>
</ng-template>

<ng-template #containedSelect>
  <app-form-control
    [label]="label"
    [tooltip]="tooltip"
    [translateLabel]="translateLabel"
    [validate]="controlToValidate">
    <ng-container *ngTemplateOutlet="select"></ng-container>
  </app-form-control>
</ng-template>
