import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NavigationService } from "@core/navigation";
import { filter, map, mergeMap } from "rxjs/operators";

@Component({
  selector: "app-titlebar",
  templateUrl: "./titlebar.component.html",
})
export class TitlebarComponent {
  data$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.#getDeepestRoute(this.activatedRoute)),
    filter((route) => route.outlet === "primary"),
    mergeMap((route) => route.data)
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {}

  onBack() {
    this.navigationService.back();
  }

  onClose() {
    this.navigationService.home();
  }

  #getDeepestRoute(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
