import { Component, HostBinding, Input } from "@angular/core";
import { Router } from "@angular/router";
import { origins } from "@core/user-tracking/tracking.model";
import { Shortcut } from "../../models/shortcut-model";

@Component({
  selector: "app-shortcut-item",
  templateUrl: "shortcut-item.component.html",
})
export class ShortcutItemComponent {
  @HostBinding("class") get classes() {
    return "pko-shortcut pko-shortcut-" + this.item.name;
  }

  @Input() item!: Shortcut;

  constructor(private router: Router) {}

  get icon() {
    return this.item.icon ?? this.item.name;
  }

  onClick() {
    const { actionOrRoute } = this.item;

    if (typeof actionOrRoute === "string") {
      this.router.navigate([actionOrRoute], { state: { origin: origins.SHORTCUT } });
    } else {
      actionOrRoute();
    }
  }
}
