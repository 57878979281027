import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective, Validators } from "@angular/forms";
import { OrderType } from "@features/order/models/order-form";
import { distinctUntilChanged, map, of, startWith, Subscription, tap } from "rxjs";

@Component({
  selector: "app-form-order-type-rate-points",
  templateUrl: "type-rate-points.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class OrderTypeRatePointsFormComponent implements OnInit, OnDestroy {
  #subscription!: Subscription;

  isTrailing$ = of(false);

  @Input() types: OrderType[] = [];
  /** asGridRow - display controls in row */
  @Input() asGridRow = false;

  constructor(public parent: FormGroupDirective) {}

  get #controls() {
    return this.parent.form.controls;
  }

  ngOnInit() {
    const { type, points } = this.#assertForm();

    if (!type.value) {
      type.setValue(this.types[0]);
    }

    this.isTrailing$ = type.valueChanges.pipe(
      startWith(type.value),
      map((type: OrderType) => type === OrderType.TrailingStop),
      distinctUntilChanged(),
      tap((isTrailing) => this.#updateValueAndValidity(isTrailing))
    );

    this.#subscription = points.valueChanges
      .pipe(tap((value) => value > 999 && points.setValue(999)))
      .subscribe();
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
  }

  #assertForm() {
    const { type, rate, points } = this.#controls;

    if (!type || !rate || !points) {
      throw new Error(
        "This component needs 'type', 'rate' and 'points' controls present on the form!"
      );
    }

    return { type, rate, points };
  }

  #updateValueAndValidity(isTrailing: boolean) {
    const { rate, points } = this.#controls;
    const required = Validators.required;

    // updating validity only of points, because rate handles it itself internally
    if (isTrailing) {
      rate.reset();
      points.setValidators(required);
    } else {
      points.reset();
      points.removeValidators(required);
      points.updateValueAndValidity();
    }
  }
}
