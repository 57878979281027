<!-- 
// #########################
// ## MODYFIKACJA
// #########################
 -->

<app-transaction-decision
  type="investment-deposit"
  [loading$]="loading$"
  [vm$]="vm$"
  [formGroup]="form">
  <ng-template #summary let-summary let-time="time">
    <app-alert
      *ngIf="warningService.outOfTargetMarket"
      icon="critical"
      content="alert.Content.OutOfTargetMarket"
      context="danger">
    </app-alert>
    <div class="pko-grid__row">
      <div class="col-10 col-lg-4 order-lg-last">
        <app-summary-quote [quote]="summary"></app-summary-quote>
        <!-- 
        ZAKOMENTOWANO PONIŻEJ
        -->
        <!-- <app-margin-info [data]="summary" *ngIf="time > 0"></app-margin-info> -->
      </div>
      <app-summary-detail [detail]="summary" class="col-10 col-md-6"></app-summary-detail>
    </div>
    <div class="pko-grid__row">
      <div class="col-10">
        <app-form-control validate="terminationFee" *ngIf="time > 0">
          <app-input-check
            *ngIf="form.controls.terminationFee as control"
            class="app-input-check--desktop"
            [class.app-input-check--desktop--invalid]="control.touched && control.invalid"
            [label]="
              'investment-deposit.TerminationFee'
                | translate
                  : {
                      zero: 0 | balance: summary.currency.code,
                      terminationFee: summary.terminationFee | balance: summary.currency.code
                    }
            "
            formControlName="terminationFee"
            [translateLabel]="false"
            ngDefaultControl>
          </app-input-check>
        </app-form-control>
      </div>
    </div>
  </ng-template>
  <ng-template #waiting let-summary>
    <app-investment-deposit-waiting
      [startSignal]="waitingStart"
      [stopSignal]="waitingStop"
      (finished)="handleStatus($event, summary)">
    </app-investment-deposit-waiting>
  </ng-template>
</app-transaction-decision>
