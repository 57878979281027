import { I18nPluralPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";

@Pipe({ name: "customi18nPlural" })
export class Customi18nPluralPipe implements PipeTransform {
  constructor(private pluralPipe: I18nPluralPipe, private translatePipe: TranslatePipe) {}

  transform(key: string, value: number, path = "") {
    return this.translatePipe.transform(
      this.pluralPipe.transform(value, {
        "=1": buildKey(path, "one", key),
        few: buildKey(path, "few", key),
        other: buildKey(path, "other", key),
      }),
      {
        value,
      }
    );
  }
}

function buildKey(...fragments: string[]) {
  return fragments.filter((x) => !!x).join(".");
}
