import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { SelectableComponent } from "@shared/components/groups-selector/groups-selector.model";

@Component({
  selector: "app-indicative-settings-item",
  templateUrl: "./indicative-settings-item.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class IndicativeSettingsItemComponent implements SelectableComponent {
  @Input() key!: string;
}
