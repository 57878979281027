import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { filter, switchMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class NavigationService {
  private history: string[] = [];
  #back?: () => void;

  get last() {
    return this.history.at(-1);
  }

  constructor(
    private _router: Router,
    private _location: Location,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscribe();
  }

  navigate(commands: unknown[], extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate(commands, extras);
  }

  setupBack(back: (() => void) | undefined) {
    this.#back = back;
  }

  back(): void {
    if (this.#back) {
      this.#back();
      return;
    }
    this.history.pop();
    if (this.history.length > 0) {
      this._location.back();
    } else {
      this._router.navigateByUrl("/");
    }
  }

  home(): void {
    this.history = [];
    this._router.navigateByUrl("/", { replaceUrl: true });
  }

  private subscribe() {
    this._router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.history.push(event.urlAfterRedirects);
      });

    this._router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        switchMap(() => this.#getDeepestRoute(this.activatedRoute).data)
      )
      .subscribe((data) => {
        if (data?.dontClearBack) return;
        this.#back = undefined;
      });
  }

  #getDeepestRoute(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
