import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone",
})
export class PhonePipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) return "";

    let prefix = "",
      number = "";

    const prefixRegex = /^(((\(\+\d+\))|(\+\d+\s))\s*)?/;
    if (prefixRegex.test(phone)) {
      prefix = prefixRegex.exec(phone)?.[0] ?? "";
      number = phone.replace(prefixRegex, "");
    }

    return `${prefix} ${(number.match(/.{1,5}$|.{1,3}/g) || [""]).join(" ")}`;
  }
}
