import { NgModule } from "@angular/core";
import { AccountsModule } from "@features/accounts/accounts.module";
import { CurrencyModule } from "@features/currency/currency.module";
import { SharedModule } from "@shared/shared.module";
import { DetailContainerComponent } from "./components/detail-container/detail-container.component";
import { MaxDatePipe } from "./components/form-range/max-date.pipe";
import { DateRangeFormComponent } from "./components/form-range/range-date.component";
import { NumberRangeFormComponent } from "./components/form-range/range-number.component";
import { ListContainerComponent } from "./components/list-container/list-container.component";
import { MainFilterButtonComponent } from "./components/main-filter-button/main-filter-button.component";
import { MainFilterComponent } from "./components/main-filter/main-filter.component";
import { StatusColorPipe } from "./status-color.pipe";

@NgModule({
  imports: [SharedModule, AccountsModule, CurrencyModule],
  declarations: [
    DateRangeFormComponent,
    DetailContainerComponent,
    NumberRangeFormComponent,
    MainFilterComponent,
    MainFilterButtonComponent,
    StatusColorPipe,
    ListContainerComponent,
    MaxDatePipe,
  ],
  exports: [
    SharedModule,
    AccountsModule,
    CurrencyModule,
    DateRangeFormComponent,
    NumberRangeFormComponent,
    MainFilterComponent,
    MainFilterButtonComponent,
    DetailContainerComponent,
    StatusColorPipe,
    ListContainerComponent,
  ],
})
export class HistoryCommonModule {}
