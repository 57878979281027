import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, FormGroupDirective, Validators } from "@angular/forms";
import { isBuying } from "@core/models/transaction";
import { AccountsService, AccountsView, updateValidator } from "@features/accounts";
import { combineLatest, EMPTY, map, Observable, of, startWith, switchMap, tap } from "rxjs";

@Component({
  selector: "app-form-accounts",
  templateUrl: "accounts.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AccountsFormComponent implements OnInit {
  @Input() type: "exchange" | "order" = "exchange";

  vm$: Observable<AccountsView> = EMPTY;

  constructor(private parent: FormGroupDirective, private accountsService: AccountsService) {}

  ngOnInit() {
    const { currency, counterCurrency, account, netSettlement, side } = this.#assertForm();

    const accounts$ = this.#observeAccounts(currency);
    const counterAccounts$ = this.#observeAccounts(counterCurrency);
    const netSettlement$ = this.#observeNetSettlement(account, netSettlement);
    const isBuying$ = side.valueChanges.pipe(startWith(side.value), map(isBuying));

    this.vm$ = combineLatest([netSettlement$, isBuying$, accounts$, counterAccounts$]).pipe(
      map(([netSettlement, isBuying, accounts, counterAccounts]) => ({
        netSettlement,
        isBuying,
        accounts,
        counterAccounts,
      }))
    );
  }

  #assertForm() {
    const { currency, counterCurrency, account, counterAccount, netSettlement, side } =
      this.parent.form.controls;

    if (!account || !counterAccount || !currency || !counterCurrency || !side) {
      throw new Error(
        `This component needs 'side', 'account', 'counterAccount', 'currency' and 'counterCurrency' controls present on the form!`
      );
    }

    counterAccount.setValidators(Validators.required);

    return { currency, counterCurrency, account, netSettlement, side };
  }

  #observeAccounts(currency: AbstractControl) {
    const getAccounts = this.accountsService.getAccounts(this.type);
    return currency.valueChanges.pipe(startWith(currency.value), switchMap(getAccounts));
  }

  #observeNetSettlement(account: AbstractControl, net?: AbstractControl) {
    const netSettlement$ = net?.valueChanges.pipe(startWith(net.value)) ?? of(false);
    return netSettlement$.pipe(tap(updateValidator(account)));
  }
}
