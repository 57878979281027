import { Component, Input } from "@angular/core";
import { BootstrapContext } from "@core/models/bootstrap-context";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
})
export class AlertComponent {
  @Input() content!: string;
  @Input() icon?: string;
  @Input() context: BootstrapContext = "info";
  @Input() contentData = {};
}
