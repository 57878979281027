import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { NgbCalendar, NgbDate, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { pick } from "@utils/object";
import { isWeekend } from "@utils/time";
import { ValidatorName } from "./datepicker-utils";

/**
 * Handles validation logic of the datepicker.
 * @description
 * Current implementation of the datepicker component uses `ngb-datepicker` and an `input`
 * and treats them separately. That is because _disable_ logic is used by the `ngb-datepicker`
 * and _validate_ logic is used by the `input`.
 * Thanks to this service they are at least stored together.
 */
@Injectable()
export class DatepickerValidator {
  #holidays?: string[] | null;
  #restricted?: string[] | null;
  #min: NgbDate;
  #max: NgbDate;
  #allowPastDates = false;
  #validators: NamedValidators;
  #isDisabled: (date: NgbDate) => boolean;

  constructor(private calendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) {
    const today = calendar.getToday();
    this.#min = today;
    this.#max = calendar.getNext(today, "y", 10);
    this.#isDisabled = this.#isWeekend;

    this.#validators = {
      required: Validators.required,
      min: this.#minValidator,
      max: this.#maxValidator,
      weekend: this.#weekendValidator,
      holiday: this.#holidayValidator,
      restricted: this.#restrictedValidator,
    };
  }

  get min() {
    return this.#min;
  }

  get max() {
    return this.#max;
  }

  get isDisabled() {
    return this.#isDisabled;
  }

  set holidays(dates: string[] | null | undefined) {
    this.#holidays = dates?.length ? dates : null;
    // when holidays change, we need to update the method, to force ngb rerender.
    this.#isDisabled = (date: NgbDate) =>
      this.#isWeekend(date) || this.#isHoliday(date) || this.#isRestricted(date);
  }

  set restricted(dates: string[] | null | undefined) {
    this.#restricted = dates?.length ? dates : null;
    // when restricted dates change, we need to update the method, to force ngb rerender.
    this.#isDisabled = (date: NgbDate) =>
      this.#isWeekend(date) || this.#isHoliday(date) || this.#isRestricted(date);
  }

  getValidators(allOrSome: true | ValidatorName[]) {
    if (!allOrSome) return [];

    const validators = allOrSome === true ? this.#validators : pick(this.#validators, ...allOrSome);

    return Object.values(validators);
  }

  allowPastDates() {
    this.#allowPastDates = true;
    this.#min = this.calendar.getNext(this.calendar.getToday(), "y", -50);
  }

  setMin(date?: string | null) {
    const parsedDate = this.#parse(date);
    if (!parsedDate) {
      this.#min = this.calendar.getToday();
      this.#allowPastDates && this.allowPastDates();
      return;
    }

    this.#min = parsedDate;
  }

  setMax(date: string | null | undefined) {
    const parsedDate = this.#parse(date);
    if (!parsedDate) {
      this.#max = this.calendar.getNext(this.calendar.getToday(), "y", 10);
      return;
    }

    this.#max = parsedDate;
  }

  #isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;

  #isRestricted = (date: NgbDate) => this.#includes(date, this.#restricted);

  #isHoliday = (date: NgbDate) => this.#includes(date, this.#holidays);

  #minValidator: ValidatorFn = ({ value }: AbstractControl): ValidationErrors | null => {
    if (!value) return null;

    const min = this.#min;
    const current = this.dateAdapter.fromModel(value);

    return min.after(current) ? { min: { min: this.dateAdapter.toModel(min) } } : null;
  };

  #maxValidator: ValidatorFn = ({ value }: AbstractControl): ValidationErrors | null => {
    if (!value) return null;

    const max = this.#max;
    const current = this.dateAdapter.fromModel(value);

    return max.before(current) ? { max: { max: this.dateAdapter.toModel(max) } } : null;
  };

  #holidayValidator: ValidatorFn = ({ value }: AbstractControl): ValidationErrors | null => {
    if (!value) return null;

    return this.#holidays?.includes(value) ? { holiday: true } : null;
  };

  #weekendValidator: ValidatorFn = ({ value }: AbstractControl): ValidationErrors | null => {
    if (!value) return null;

    return isWeekend(new Date(value)) ? { weekend: true } : null;
  };

  #restrictedValidator: ValidatorFn = ({ value }: AbstractControl): ValidationErrors | null => {
    if (!value) return null;

    return this.#restricted?.includes(value) ? { restricted: true } : null;
  };

  #parse(date?: string | null) {
    if (!date) return null;

    return NgbDate.from(this.dateAdapter.fromModel(date));
  }

  #includes(date: NgbDate, dates?: string[] | null) {
    if (!dates?.length) return false;

    const current = this.dateAdapter.toModel(date);
    return !!current && dates.includes(current);
  }
}

type NamedValidators = Record<ValidatorName, ValidatorFn>;
