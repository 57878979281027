<!-- 
################################
### MODYFIKACJA
################################
 -->
<!-- 
################################
### NOWE
VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV
 -->
<!-- <div class="d-flex align-items-center my-2" (click)="getCsv()" style="cursor: pointer"> -->
<div class="d-flex align-items-center my-2" style="cursor: pointer">
  <!-- 
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
### NOWE
################################
 -->
  <div class="text-sm fw-bold mb-1">{{ documentTitle | translate }}</div>
  <svg icon name="file-csv" class="icon--2xl flex-shrink-0"></svg>
</div>
