import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "minorUnit" })
export class MinorUnitPipe implements PipeTransform {
  transform(currency: string | null | undefined) {
    return currency ? minorUnits[currency] ?? null : null;
  }
}

const minorUnits: Record<string, string> = {
  PLN: "gr",
  EUR: "c",
  CHF: "rp",
  DKK: "øre",
  GBP: "p",
  USD: "¢",
  SEK: "c",
} as const;
