<ng-container *ngIf="header">
  <h4>{{ header.title | translate }}</h4>
  <span *ngIf="header.subtitle">{{ header.subtitle | translate }}</span>
</ng-container>

<app-labeled-text-inline label="sides.{{ quote.side }}">
  <app-flag
    [icon]="quote.currency.code"
    [label]="quote.amount | balance: quote.currency.code"></app-flag>
</app-labeled-text-inline>

<app-labeled-text-inline label="for">
  <app-flag
    [icon]="quote.counterCurrency.code"
    label="{{ counterAmount | number: '1.2-2' | nullable }} {{ quote.counterCurrency.code }}">
  </app-flag>
</app-labeled-text-inline>

<app-labeled-text-inline label="SettlementDate">
  {{ quote.settlement.date | datePko }}
</app-labeled-text-inline>

<hr />

<app-labeled-text-inline label="Rate">
  <h4>{{ rate | rate | nullable }}</h4>
</app-labeled-text-inline>

<app-labeled-text-inline
  *ngIf="quote.netSettlementAmount | isNonNullable"
  label="exchange.NetSettlementAmount">
  <span [class]="quote.netSettlementAmount < 0 ? 'text-danger' : 'text-success'">
    {{ quote.netSettlementAmount | balance: quote.counterCurrency.code }}
  </span>
</app-labeled-text-inline>
