import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";

export interface UserNotification {
  key: string;
  name: string;
  date: Date;
  deleted?: boolean;
  hidden?: boolean;
}

export interface MessageNotificationItem extends UserNotification {
  id: number;
  title: string;
  description: string;
  segment: string;
  startDate: Date;
}

export interface NewDpw extends UserNotification {
  dpwType: string;
  expirationDate: Date;
  amount: number;
  currency: string;
  dpwNumber: string;
  currencyPair: string;
  status: string;
}

export interface UnsettledDeal extends UserNotification {
  dealId: string;
  mainDealId: string;
  dealSide: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
}

export interface ExpiredOrder extends UserNotification {
  orderId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  expectedRate: number;
  expired: boolean;
}

export interface ExecutedOrder extends UserNotification {
  orderId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
  executionRate: number;
  executedDealId: number;
}

export interface ExecutedAlert extends UserNotification {
  alertId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
  executionRate: number;
}

export interface ExpiredAlert extends UserNotification {
  alertId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
  expectedRate: number;
  expired: boolean;
}

export interface EndingDeposit extends UserNotification {
  dealId: string;
  settlementAmount: number;
  currency: string;
  interestRate: number;
}

export interface ExecutedInvDeposit extends UserNotification {
  id: string;
  currency: string;
  amount: number;
  settlementAmount?: number;
  isCompleted: boolean;
}

export interface ExecutedPremiumDeposit extends UserNotification {
  dealId: string;
  settlementAmount: number;
  currency: string;
  interestRate: number;
  isCompleted: boolean;
}

@Component({ template: "" })
export abstract class UserNotificationComponent {
  @Input() public notification: UserNotification | null = null;
  @Input() public showIcon = true;
  @Output() public actionExecuted = new EventEmitter<NotificationActionEvent>();
  translateHeader = true;

  abstract get icon(): string;
  abstract get header(): string;

  abstract mainAction(): void;
  abstract get menu(): ActionItem[] | undefined;
}

export interface NotificationActionEvent {
  type: string;
  data: unknown;
}
