import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProductType } from "@features/tenor/tenor-model";
import { catchError, EMPTY, Observable, of } from "rxjs";
import { ComplexInitializationResponse, InitializationResponse } from "../models";
import { ConfirmationRequest, StatusResponse } from "../models/confirmation";
import { RateResponse } from "../models/rate";

@Injectable({ providedIn: "root" })
export class TransactionApiService {
  constructor(private http: HttpClient) {}

  initialize(target: InitializationTarget, form: unknown): Observable<InitializationResponse> {
    return this.http
      .post<InitializationResponse>(target, form)
      .pipe(catchError(() => of({ error })));
  }

  getRate<T extends RateResponse>(target: Target, token: string) {
    const options = { params: { token } };
    return this.http.get<T>(`${target}/rate`, options).pipe(catchError(() => of({ error } as T)));
  }

  reject(target: Target, token: string): Observable<void> {
    return this.http.post<void>(`${target}/reject`, { token }).pipe(catchError(() => EMPTY));
  }

  confirm(target: Target, tokens: ConfirmationRequest): Observable<string> {
    return this.http.post(`${target}/confirm`, tokens, { responseType: "text" });
  }

  getStatus<T extends Partial<StatusResponse> = StatusResponse>(
    target: Target,
    token: string
  ): Observable<T> {
    const options = { params: { token } };

    return this.http
      .get<T>(`${target}/status`, options)
      .pipe(catchError(() => of({ status: error } as T)));
  }

  validateMifid(
    target: Target,
    productType: ProductType
  ): Observable<ComplexInitializationResponse> {
    const options = { params: { productType } };

    return this.http
      .get<ComplexInitializationResponse>(`${target}/validateMifid`, options)
      .pipe(catchError(() => of({ error })));
  }
}

const error = { code: "GENERIC" } as const;

export type Target =
  | "exchange"
  | "iob"
  | "dpw"
  | "swap"
  | "deposit"
  | "multifx"
  | "investmentdeposit";

// endpoints might change. Maybe separate controllers?
type InitializationTarget = Target | "exchange/close" | "exchange/roll";
