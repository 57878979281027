import { NgModule } from "@angular/core";
import { CollateralModule } from "@features/collateral/collateral.module";
import { ExchangeModule } from "@features/exchange/exchange.module";
import { MultiFxModule } from "@features/multifx/multifx.module";
import { TransactionModule } from "@features/transaction/transaction.module";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { BaseDpwListComponent } from "./components/base-dpw-list.component";
import { DpwAccountComponent } from "./components/controls/dpw-account.component";
import { DpwAmountPairFormComponent } from "./components/controls/dpw-amount-pair-form.component";
import { DpwTenorDateProductFormComponent } from "./components/controls/dpw-tenor-date-product.component";
import { DpwDecisionComponent } from "./components/decision/dpw-decision.component";
import { IobDecisionComponent } from "./components/decision/iob-decision.component";
import { DpwListItemComponent } from "./components/dpw-list-item.component";
import { DpwListSectionComponent } from "./components/dpw-list-section.component";
import { DpwWizardComponent } from "./components/dpw-wizard.component";
import { MultiDpwDecisionComponent } from "./components/multifx/decision/decision.component";
import { SummaryQuoteRowComponent } from "./components/multifx/summary-quote-row/summary-quote-row.component";
import { SummaryQuoteComponent } from "./components/multifx/summary-quote/summary-quote.component";
import { DpwTileComponent } from "./components/tile/dpw-tile.component";
import { DpwDirectionComponent } from "./components/wizard/dpw-direction.component";
import { IobFormComponent } from "./components/wizard/iob-form.component";
import { DpwListComponent } from "./dpw-list.component";
import { DpwRoutingModule } from "./dpw-routing.module";
@NgModule({
  imports: [
    DpwRoutingModule,
    SharedModule,
    TranslateModule,
    TransactionModule,
    ExchangeModule,
    CollateralModule,
    MultiFxModule,
  ],
  declarations: [
    DpwListComponent,
    DpwWizardComponent,
    DpwListItemComponent,
    DpwListSectionComponent,
    DpwDirectionComponent,
    IobFormComponent,
    DpwAmountPairFormComponent,
    DpwTenorDateProductFormComponent,
    DpwAccountComponent,
    DpwDecisionComponent,
    IobDecisionComponent,
    DpwTileComponent,
    BaseDpwListComponent,
    MultiDpwDecisionComponent,
    SummaryQuoteRowComponent,
    SummaryQuoteComponent,
  ],
  exports: [DpwListComponent, DpwWizardComponent, DpwTileComponent],
  providers: [],
})
export class DpwModule {}
