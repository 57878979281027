import { Component, OnDestroy, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import type { NonForwardTenor } from "@features/tenor";
import { assertIsDefined } from "@utils/misc";
import { filter, Subscription, tap } from "rxjs";
@Component({
  selector: "app-input-check-pending-conversion",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  template: `<app-input-check
    formControlName="pendingConversion"
    label="order.PendingConversion"
    ngDefaultControl>
  </app-input-check>`,
})
export class PendingConversionComponent implements OnInit, OnDestroy {
  #subscription = new Subscription();

  constructor(private parent: FormGroupDirective) {}

  ngOnInit() {
    const { tenor, pendingConversion } = this.#assertForm();

    // set initial null to false - we need to display it on decision screen
    pendingConversion.value || pendingConversion.setValue(false);

    const toSpot$ = pendingConversion.valueChanges.pipe(
      filter(Boolean),
      // setting to same value emits and other streams run (like live rate)
      tap(() => noSpot(tenor.value) && tenor.setValue("SPOT"))
    );
    const unClick$ = tenor.valueChanges.pipe(
      filter(noSpot),
      tap(() => pendingConversion.setValue(false))
    );

    this.#subscription.add(toSpot$.subscribe());
    this.#subscription.add(unClick$.subscribe());
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
  }

  #assertForm() {
    const { tenor, pendingConversion } = this.parent.form.controls;
    assertIsDefined(tenor);
    assertIsDefined(pendingConversion);
    return { tenor, pendingConversion };
  }
}

const noSpot = (tenor: NonForwardTenor) => tenor !== "SPOT";
