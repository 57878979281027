import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";

@Component({
  selector: "app-dealer-wait",
  templateUrl: "dealer-wait.component.html",
})
export class DealerWaitComponent {
  @HostBinding("class") class = "pko-page";
  @Input() type: "exchange" | "deposit" | "multifx" = "exchange";
  @Input() modal = false;
  @Output() backClicked = new EventEmitter<void>();
}
