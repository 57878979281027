import { Directive, OnDestroy, OnInit, Self } from "@angular/core";
import { NgControl } from "@angular/forms";
import { formatDate } from "@shared/utils/format";
import { map, startWith, Subscription } from "rxjs";

/**
 * This directive does not validate the input in any way.
 * It is created solely for the purpose of reversing the format
 * of the domain model date (which is ISO 8601) on the `app-datepicker` component.
 * That input is handled internally by the NgbDateAdapter.
 */
@Directive({ selector: "input[pkoDatepickerFormat]" })
export class DatepickerFormatDirective implements OnInit, OnDestroy {
  #subscription?: Subscription;

  constructor(@Self() private ngControl: NgControl) {}

  ngOnInit() {
    const { control, valueAccessor } = this.ngControl;

    if (!control || !valueAccessor) return;

    this.#subscription = control.valueChanges
      .pipe(
        startWith(control.value),
        map((date) => formatDate(date))
      )
      .subscribe((formattedValue) => {
        valueAccessor.writeValue(formattedValue);
      });
  }

  ngOnDestroy(): void {
    this.#subscription?.unsubscribe();
  }
}
