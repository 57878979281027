import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { SplitTenorDates } from "../date-bounds.component";

@Component({
  selector: "app-date-bounds-deposit-element",
  template: `
    <div class="pko-grid__row pko-grid__row--12">
      <app-form-tenor-date
        formGroupName="start"
        label="StartDate"
        show="date"
        [tenorDates]="tenorDates.near"
        [holidays]="holidays"
        class="col-12 col-sm-6">
      </app-form-tenor-date>
      <app-form-tenor-date
        formGroupName="end"
        label="EndDate"
        show="date"
        [tenorDates]="tenorDates.far"
        [holidays]="holidays"
        [disableSingleValue]="disableSingleValue"
        class="col-12 col-sm-6">
      </app-form-tenor-date>
    </div>
    <app-form-tenor-date
      formGroupName="end"
      label="deposit.Duration"
      show="tenor"
      [tenorDates]="tenorDates.far"
      [tenorPlaceholder]="endTenorPlaceholder"
      [disableSingleValue]="disableSingleValue"
      translateText="tenor">
    </app-form-tenor-date>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DateBoundsDepositElementComponent {
  @Input() tenorDates!: SplitTenorDates;
  @Input() holidays?: string[] | null;
  @Input() disableSingleValue!: boolean;
  @Input() endTenorPlaceholder?: string;
}
