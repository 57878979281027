<div class="pko-grid__row pko-grid__row--12" *ngIf="vm$ | async as vm">
  <app-select-account
    label="{{ 'multifx.SettlementAccount' | translate: { currency: vm.currency } }}"
    [translateLabel]="false"
    validate="account"
    formControlName="account"
    class="col-12 col-sm-6"
    [items]="vm.accounts"
    ngDefaultControl></app-select-account>
  <app-select-account
    label="{{ 'multifx.SettlementAccount' | translate: { currency: vm.counterCurrency } }}"
    [translateLabel]="false"
    validate="counterAccount"
    formControlName="counterAccount"
    class="col-12 col-sm-6"
    [items]="vm.counterAccounts"
    ngDefaultControl></app-select-account>
</div>
