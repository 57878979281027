import { Component, HostBinding } from "@angular/core";
import { BusyService } from "@core/loading/busy.service";
import { UserService } from "@core/session";
import { BehaviorSubject, switchMap } from "rxjs";
import { ActivationApiService } from "../../activation-api.service";

@Component({
  selector: "app-contracts",
  templateUrl: "./contracts.component.html",
})
export class ContractsComponent {
  @HostBinding("class") class = "d-block position-relative";
  #page = new BehaviorSubject<number>(1);
  contracts$ = this.#page.pipe(switchMap((x) => this.service.getContracts(x)));
  isInstitutional = false;

  isBusy$ = this.busyService.observeOn("/contracts/completed");

  constructor(
    private service: ActivationApiService,
    userService: UserService,
    private busyService: BusyService
  ) {
    this.isInstitutional = userService.userData.isInstitutional;
  }

  onContractPageChanged(page: number) {
    this.#page.next(page);
  }
}
