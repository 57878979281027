import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "@core/components/page-not-found/page-not-found.component";
import { AppCanActivate } from "@shared/app-guard";
import { DashboardComponent } from "./dashboard.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [AppCanActivate],
    data: { expectedRole: "HasCustomer" },
    component: DashboardComponent,
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
