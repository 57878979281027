<p class="fw-bold">
  {{
    "flowDeclaration.RegularHeader"
      | translate
        : {
            start: declaration.dateFrom | datePko,
            end: declaration.dateTo | datePko
          }
  }}
</p>

<app-grouped-list
  [groups]="[{ key: '', value: declaration.flowsData }]"
  class="d-block d-sm-none"
  [flatList]="true">
  <ng-template #itemTemplate let-item let-i="index">
    <div class="pko-group__item pko-group__item--column">
      <p class="mb-1">
        <span class="fw-light">{{ "history.Currency" | translate }}: </span>
        <app-flag [icon]="item.currency" [label]="item.currency" class="d-inline-flex"></app-flag>
      </p>
      <p class="mb-1">
        <span class="fw-light">{{ "flowDeclaration.Side" | translate }}:</span>
        {{ "sides." + item.side | translate }}
      </p>
      <p class="mb-0">
        <span class="fw-light">{{ "Amount" | translate }}:</span>
        {{ item.amount }}
      </p>
    </div>
  </ng-template>
</app-grouped-list>

<table
  [columns]="columns"
  [items]="declaration.flowsData"
  class="d-none d-sm-table pko-flow__table"
  pkoTable>
  <ng-template #itemTemplate let-item let-i="index">
    <tr class="pko-table__row pko-table__row--sm">
      <td pkoTableCell>
        <app-flag [icon]="item.currency" [label]="item.currency"></app-flag>
      </td>
      <td pkoTableCell>
        {{ "sides." + item.side | translate }}
      </td>
      <td pkoTableCell>{{ item.amount }}</td>
    </tr>
  </ng-template>
</table>

<div class="d-flex my-2" *ngIf="showActions">
  <button class="btn btn-primary btn-fixed ms-auto" (click)="update()">
    {{ "buttons.flowDeclaration.Update" | translate }}
  </button>
</div>
