import { Component, HostBinding, OnDestroy } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { Currency, CurrencyService, getCurrencyByCode } from "@features/currency";
import { MultiDpwSummary } from "@features/dpw/dpw.model";
import { MultiFxForm, MultiFxLegForm } from "@features/multifx/models/multifx-form";
import {
  MultiFxRateSuccess,
  MultiFxTransactionRateSuccess,
} from "@features/multifx/models/multifx-rate";
import { MultiFxLegSummary } from "@features/multifx/models/multifx-summary";
import { MultiFxFormService } from "@features/multifx/services/multifx-form.service";
import { MultiFxService } from "@features/multifx/services/multifx.service";
import { DecisionViewModel, RateStreamable, isRateSuccess } from "@features/transaction/models";
import { Observable, distinctUntilChanged, map, merge, of, startWith, switchMap, tap } from "rxjs";

@Component({
  selector: "app-multi-dpw-decision",
  templateUrl: "./decision.component.html",
})
export class MultiDpwDecisionComponent implements OnDestroy {
  @HostBinding("class") class = "pko-decision";

  #subscription = this.multiFxService.rejection$.subscribe();

  #rate$ = this.multiFxService.pollRate();

  loading$ = merge(
    this.#rate$.pipe(map(() => false)),
    this.multiFxService.confirmation$.pipe(mapToBusy()),
    this.multiFxService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$: Observable<DecisionViewModel<MultiDpwSummary>> = this.#rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  constructor(
    private formService: MultiFxFormService,
    private currencyService: CurrencyService,
    private multiFxService: MultiFxService,
    private tracker: Tracker
  ) {}

  async ngOnDestroy() {
    await this.multiFxService.rejectAsync();
    this.#subscription.unsubscribe();
  }

  #constructViewModel(rate: RateStreamable): Observable<DecisionViewModel<MultiDpwSummary>> {
    if (!isRateSuccess<MultiFxRateSuccess>(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current;

    return this.currencyService.allCurrencies$.pipe(
      map(mapToSummary(rate, form)),
      map((summary) => {
        const decision = {
          summary,
          time: rate.decisionTime,
          ...this.#getActions(rate.quoteId),
        };

        return { decision };
      })
    );
  }

  #trackRate({ decision }: DecisionViewModel<MultiDpwSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.token) return;

    this.tracker.reportProgress({
      action: "rate",
      data: {
        token: summary.token,
        pair: summary.currencyPair,
        dpwIds: summary.series.map((x) => x.dpwNumber).join(";"),
      },
    });
  }

  #getActions(quoteId: number) {
    return {
      onTimeout: () => this.multiFxService.reject(),
      forward: quoteId
        ? () => this.multiFxService.confirm(quoteId.toString())
        : () => this.formService.submit(),
    };
  }
}

const mapToSummary = (rate: MultiFxRateSuccess, form: MultiFxForm) => (currencies: Currency[]) =>
  ({
    ...rate,
    dealCurrencyTotal: form.series
      .map((formLeg) => +formLeg.amount)
      .reduce((sum, current) => sum + current, 0),
    currencyPair: form.currencyPair,
    currency: getCurrencyByCode(currencies, form.currency),
    counterCurrency: getCurrencyByCode(currencies, form.counterCurrency),
    series: form.series.map((formLeg, index) => mapToSeries(rate.series[index], formLeg)),
  } as MultiDpwSummary);

const mapToSeries = (rate: MultiFxTransactionRateSuccess, form: MultiFxLegForm) =>
  ({
    ...form,
    ...rate,
  } as MultiFxLegSummary);
