import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { RepeatWhileParams, repeatWhile } from "@utils/stream";
import { isPending } from "../models";

export function scrollTo(el: Element): void {
  if (el) {
    el.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}

export function scrollToError(componentSelector?: string): void {
  const elementToSearch = componentSelector
    ? document.querySelector(componentSelector) || document
    : document;

  const firstElementWithError = elementToSearch.querySelector("app-form-error");

  if (!firstElementWithError) return;
  scrollTo(firstElementWithError);
}

export function handleFormErrors(form: FormGroup, componentSelector?: string) {
  validateControl(form);

  if (!form.valid) {
    scrollToError(componentSelector);
    return false;
  }

  return true;
}

/**
 * Iterates through every control in the form group and validates it separately.
 * For simpler cases use @see validateV2
 */
function validateControl(formControl: AbstractControl) {
  formControl.markAsTouched();
  formControl.markAsDirty();
  formControl.updateValueAndValidity();
  if (formControl instanceof FormGroup || formControl instanceof FormArray) {
    for (const control of Object.values(formControl.controls)) {
      validateControl(control);
    }
  }
}

export function validateV2(control: FormControl): boolean;
export function validateV2(group: FormGroup): boolean;
export function validateV2(control: AbstractControl) {
  if (control.valid) return true;

  control.markAllAsTouched();
  control.updateValueAndValidity();
  scrollToError();
  return false;
}

export const pollStatus = ({
  delay = 1000,
  count = 20,
  failResponse = { status: { code: "GENERIC" } },
}: Partial<RepeatWhileParams> = {}) => repeatWhile(isPending, { delay, count, failResponse });
