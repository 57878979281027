import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Analysis, MarketNews } from "./market-news.model";

@Injectable({ providedIn: "root" })
export class MarketNewsService {
  constructor(private http: HttpClient) {}

  getNews() {
    return this.http.get<MarketNews[]>("/marketnews/news");
  }

  getNewsDetails(id: string) {
    return this.http.get<MarketNews>(`/marketnews/news/${id}`);
  }

  getAnalysis() {
    return this.http.get<Analysis[]>("/marketnews/analysis");
  }
}
