import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { BusyService } from "@core/loading/busy.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { ModalService } from "@features/modal/modal.service";
import { LiveRateService } from "@features/transaction/components/form-rate/live-rate.service";
import { EMPTY, Observable, combineLatest, map, startWith } from "rxjs";
import { OrderFormData } from "../../models/order-form";
import { toFilteredPairs } from "../../order-utils";
import { OrderFormService } from "../../services/order-form.service";
import { OrderFormComponent } from "../form-order/form-order.component";

@Component({
  selector: "app-form-order-tile",
  templateUrl: "./form-order-tile.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LiveRateService],
})
export class OrderFormTileComponent extends OrderFormComponent {
  vm$: Observable<OrderFormData> = EMPTY;

  isTile = true;

  constructor(
    fb: FormBuilder,
    formService: OrderFormService,
    busyService: BusyService,
    modal: ModalService,
    router: Router,
    private elem: ElementRef,
    tracker: Tracker
  ) {
    super(fb, formService, busyService, modal, router, tracker);

    const { type } = this.form.controls;
    this.vm$ = combineLatest([this.data$, type.valueChanges.pipe(startWith(type.value))]).pipe(
      map(([{ currencyPairs, ...data }, type]) => ({
        ...data,
        currencyPairs: toFilteredPairs(currencyPairs)(type),
      }))
    );
  }

  async onSubmit() {
    const componentSelector = this.elem.nativeElement.tagName.toLowerCase();
    super.onSubmit(componentSelector);
  }
}
