import { HttpClient } from "@angular/common/http";
import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from "@core/session";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { FlowDeclaration, FlowDeclarationVM } from "./flow-declaration.model";
import { Tracker } from "@core/user-tracking/tracker.service";

@Injectable({ providedIn: "root" })
export class FlowDeclarationService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
    private _tracker: Tracker
  ) {}

  get(): Observable<FlowDeclarationVM> {
    const headers = { "x-no-retry": "1" };

    const requiredDeclaration = this.userService.userData.flowsDeclaration;
    if (requiredDeclaration) {
      return of(requiredDeclaration);
    }
    return this.http
      .get<FlowDeclarationVM>("flows/declaration", { headers })
      .pipe(tap((data) => (this.userService.userData.flowsDeclaration = data)));
  }

  save(declaration: FlowDeclaration): Observable<void> {
    const headers = { "x-no-retry": "1" };
    this._tracker.report({category: "FlowDeclaration", action: "FlowDeclarationSaved"});
    return this.http
      .post<void>(
        "flows/save",
        {
          ...declaration,
          flowsData: declaration.flowsData.map((x) => ({
            ...x,
            amount: this._sanitizer.sanitize(SecurityContext.HTML, x.amount),
          })),
        },
        { headers }
      )
      .pipe(
        tap(
          () =>
            (this.userService.userData.flowsDeclaration = {
              ...this.userService.userData.flowsDeclaration,
              declaration,
            })
        )
      );
  }

  postpone(): Observable<void> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<void>("flows/postpone", null, { headers });
  }
}
