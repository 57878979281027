<h3 class="pko-modal__title">{{ "mifid.Questionnaire" | translate }}</h3>
<p>
  {{ "mifid.SurveyIntroOne" | translate }}
</p>
<p>
  {{ "mifid.SurveyIntroTwo" | translate }}
</p>
<div class="pko-modal__footer">
  <button class="btn btn-secondary mb-3 mb-sm-0" (click)="cancel()">
    {{ "buttons.Cancel" | translate }}
  </button>

  <button class="btn btn-primary ms-sm-auto" (click)="next()">
    {{ "buttons.Next" | translate }}
  </button>
</div>
