import { Failure, Side } from "@core/models/transaction";
import { PhoneForm } from "@features/telephone/telephone-model";
import { StatusResponse } from "@features/transaction/models";

export interface AlertForm {
  side: Side;
  amount: number | string;
  currency: string;
  counterCurrency: string;
  currencyPair: string;
  rate: number | string;
  expirationDate: string;
  phone: PhoneForm;
}

export type AlertInitializationRequest = AlertForm & { modal?: boolean };

export type CreateSuccess = { token: string };
export type CreateAlertResponse = CreateSuccess | { error: Failure };
export const isCreated = (response: CreateAlertResponse): response is CreateSuccess =>
  "token" in response;

type AlertStatusSuccess = Record<string, never>;
export type AlertStatusResponse =
  | Exclude<StatusResponse, { transactionId: string }>
  | AlertStatusSuccess;
export const isReady = (response: AlertStatusResponse): response is AlertStatusSuccess =>
  !response.status;
