import {
  AfterViewInit,
  Component,
  ComponentRef,
  HostBinding,
  Input,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { propCount } from "@utils/object";
import { Subject, debounceTime, take, takeUntil } from "rxjs";
import { Selectable, SelectableComponent } from "./groups-selector.model";

@Component({
  selector: "app-groups-selector",
  templateUrl: "./groups-selector.component.html",
})
export class GroupsSelectorComponent<T extends SelectableComponent>
  implements AfterViewInit, OnDestroy
{
  @HostBinding("class") class = "d-block";
  @Input() emptySelectionTitle = "";
  @Input() hasSearchBar = true;
  @Input() selectedTitle!: string;
  @Input() selectedSubtitle?: string;
  @Input() unSelectedTitle!: string;
  @Input() unSelectedSubtitle?: string;

  @Input() selectedGroup!: FormGroup;
  @Input() unSelectedGroup!: FormGroup;

  @Input() elements!: Selectable[];
  @Input() componentType!: Type<T>;

  @Input() maxSelected?: number;
  @Input() minSelected?: number;

  @Input() placeholder = "placeholders.Search";

  @ViewChild("selectedContainer", { read: ViewContainerRef })
  selectedContainer!: ViewContainerRef;
  @ViewChild("notSelectedContainer", { read: ViewContainerRef })
  notSelectedContainer!: ViewContainerRef;

  form: FormGroup;
  selectedCount = 0;

  private destroy$ = new Subject<void>();
  private components: ComponentRef<T>[] = [];

  constructor(_fb: FormBuilder) {
    const search = new FormControl("");
    this.form = _fb.group({
      search,
    });
    search.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((pattern) => {
      const selectedRegex = new RegExp(pattern, "i");
      this.components.forEach((x) => {
        const component = x.instance;
        const element: HTMLElement = <HTMLElement>x.location.nativeElement;
        element.classList.remove("d-none");
        if (pattern && !selectedRegex.test(component.key)) {
          element.classList.add("d-none");
        }
      });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elements.forEach((element) => {
        if (element.isSelected) {
          this.selectedCount++;
          this.add(this.selectedGroup, this.selectedContainer, element);
        } else {
          this.add(this.unSelectedGroup, this.notSelectedContainer, element);
        }
      });
      this.toggleDisabled();
    }, 0);
  }

  private add(formGroup: FormGroup, container: ViewContainerRef, input: Selectable) {
    const control = new FormControl(input.isSelected);
    formGroup.addControl(input.key, control);
    control.valueChanges.pipe(debounceTime(50), take(1)).subscribe((x) => {
      const newInput = { ...input, isSelected: x };
      if (x) {
        this.remove(this.unSelectedGroup, newInput);
        this.add(this.selectedGroup, this.selectedContainer, newInput);
        this.toggleDisabled();
      } else {
        this.remove(this.selectedGroup, newInput);
        this.add(this.unSelectedGroup, this.notSelectedContainer, newInput);
        this.toggleDisabled();
      }
    });
    const options = input.isSelected ? {} : { index: this.getIndex(input.key) };
    const component = container.createComponent(this.componentType, options);
    component.instance.key = input.key;
    this.components.push(component);
  }

  private getIndex(key: string) {
    const keys = Object.keys(this.unSelectedGroup.controls).concat(key);
    const sortingOrder = this.elements
      .filter((x) => keys.includes(x.key))
      .sort((a, b) => a.order - b.order);

    return sortingOrder.findIndex((x) => x.key === key);
  }

  private remove(formGroup: FormGroup, input: Selectable) {
    const component = this.components.find((x) => x.instance.key == input.key);
    if (!component) return;

    component.destroy();
    this.components.splice(this.components.indexOf(component), 1);
    formGroup.removeControl(input.key);
  }

  private toggleDisabled() {
    this.#minMaxDisable(this.maxSelected, this.unSelectedGroup);
    this.#minMaxDisable(this.minSelected, this.selectedGroup);
  }

  #minMaxDisable(boundary: number | undefined, group: FormGroup) {
    this.selectedCount = propCount(this.selectedGroup.getRawValue());
    const disable = boundary && boundary === this.selectedCount;

    Object.entries(group.controls).forEach(([, control]) => {
      if (disable) {
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
