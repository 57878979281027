import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-selected-item",
  template: `
    <div class="pko-selected-item__container">
      <div class="pko-selected-item__btn" (click)="callback && callback()">
        <svg *ngIf="callback" icon name="close_nav" class="icon--sm"></svg>
        <span class="pko-selected-item__name">{{ name }}</span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedItemComponent {
  @Input() name!: string;
  @Input() callback?: VoidFunction;
}
