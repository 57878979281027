import { AfterViewInit, Component, HostBinding, HostListener } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  DASHBOARD_TILES,
  DashboardTile,
  TilesSelection,
} from "@core/preferences/preferences.model";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-tiles-settings-component",
  templateUrl: "tiles-settings.component.html",
  styles: [
    `
      #selection-container {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }
    `,
  ],
})
export class TilesSettingsComponent implements AfterViewInit {
  @HostBinding("class") class = "h-100";

  tiles$: BehaviorSubject<TilesSelection>;

  form: FormGroup;

  private el?: HTMLElement;
  private mouseDown = false;
  private dragged = false;
  private last?: MouseEvent | TouchEvent;

  tilesRoles = {
    deal: "FxSpot|FxForward|FxSwap|ProUser",
    order: "FxSpot|ProUser,Orders|ProUser",
    alert: "HasCustomer",
    deposit: "MmDeposit",
    investmentDeposit: "InvDeposit|ProUser,LiEnabled",
    dpw: "DPWEnabled|ProUser,DpwOn",
    notifications: "HasCustomer",
    news: "HasCustomer",
    transactions: "HasCustomer",
  };

  modalConfig: Modal = {
    title: "dashboard.settings.Title",
    buttons: { primary: { text: "buttons.Set", onClick: () => this.save() } },
  };

  constructor(
    fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private preferences: PreferencesService,
    private tracker: Tracker,
    userService: UserService
  ) {
    const tilesSelection = {
      left: this.preferences.dashboard.tilesSelection.left.filter((x) =>
        userService.hasRole(this.tilesRoles[x])
      ),
      right: this.preferences.dashboard.tilesSelection.right.filter((x) =>
        userService.hasRole(this.tilesRoles[x])
      ),
    };
    const allTiles = Object.values(DASHBOARD_TILES).filter((x) =>
      userService.hasRole(this.tilesRoles[x])
    );

    const selected = [...tilesSelection.left, ...tilesSelection.right];
    const notSelected = allTiles.filter((x) => !selected.includes(x));
    const initial = {
      left: [
        ...tilesSelection.left,
        ...notSelected.splice(0, Math.ceil(allTiles.length / 2) - tilesSelection.left.length),
      ] as DashboardTile[],
      right: [...tilesSelection.right, ...notSelected] as DashboardTile[],
    };

    this.form = fb.group(reduceTiles(allTiles, selected));

    this.tiles$ = new BehaviorSubject<TilesSelection>(initial);
  }

  ngAfterViewInit(): void {
    const positions = this.tiles$.value;
    this.#positionElements(positions);
    this.#resizeContainer(positions);
  }

  @HostListener("mouseup")
  onMouseup() {
    this.#releaseDraggedElement();
  }

  @HostListener("touchend")
  onTouchEnd() {
    this.#releaseDraggedElement();
  }

  @HostListener("click", ["$event"])
  onClick(event: MouseEvent) {
    if (this.dragged) {
      event.preventDefault();
      this.dragged = false;
    }
  }

  @HostListener("mousemove", ["$event"])
  onMousemove(event: MouseEvent) {
    this.#dragElement(event);
  }

  @HostListener("touchmove", ["$event"])
  onTouchMove(event: TouchEvent) {
    this.#dragElement(event);
  }

  @HostListener("mousedown", ["$event"])
  onMousedown(event: MouseEvent) {
    this.#catchDraggedElement(event);
  }

  @HostListener("touchstart", ["$event"])
  onTouchStart(event: TouchEvent) {
    this.#catchDraggedElement(event);
  }

  save() {
    const tiles = this.#getElementsPositions();
    const selected = Object.entries(this.form.controls);
    const tilesSelection = {
      left: tiles.left.filter((x) =>
        selected.find(([name, control]) => name === x && control.value)
      ),
      right: tiles.right.filter((x) =>
        selected.find(([name, control]) => name === x && control.value)
      ),
    };
    this.tracker.reportProgress({
      action: actions.SAVE,
      data: {
        oldShortcuts: JSON.stringify(this.preferences.dashboard.tilesSelection),
        newShortcuts: JSON.stringify(tilesSelection),
      },
    });
    this.preferences.setDashboard({
      tilesSelection,
    });
    this.activeModal.close();
  }

  #catchDraggedElement(event: MouseEvent | TouchEvent) {
    const element = event.target as HTMLElement;
    if (element.tagName !== "LABEL") return;

    const parent = element.parentElement?.parentElement;

    if (!parent) return;

    this.el = parent;
    this.mouseDown = true;
    this.last = event;
  }

  #getPosition(event: MouseEvent | TouchEvent) {
    return event instanceof MouseEvent
      ? {
          clientX: event.clientX,
          clientY: event.clientY,
        }
      : {
          clientX: event.touches[0].clientX,
          clientY: event.touches[0].clientY,
        };
  }

  #dragElement(event: MouseEvent | TouchEvent) {
    if (this.mouseDown && this.last && this.el) {
      this.dragged = true;
      this.el.style.top = `${
        this.el.offsetTop + this.#getPosition(event).clientY - this.#getPosition(this.last).clientY
      }px`;
      this.el.style.left = `${
        this.el.offsetLeft + this.#getPosition(event).clientX - this.#getPosition(this.last).clientX
      }px`;
      this.last = event;
      const positions = this.#getElementsPositions();
      this.#positionElements(positions);
    }
  }

  #releaseDraggedElement() {
    this.mouseDown = false;
    if (this.el) {
      this.el = undefined;

      if (!this.dragged) return;
      const positions = this.#getElementsPositions();
      this.#positionElements(positions);
      this.#resizeContainer(positions);
    }
  }

  #positionElements(positions: TilesSelection) {
    const all = this.#getAllTiles();
    const root = document.getElementById("selection-container") as HTMLElement;

    this.#positionColumn(positions.left, all, 0 + "px");
    this.#positionColumn(positions.right, all, root.clientWidth / 2 + "px");
  }

  #resizeContainer(positions: TilesSelection) {
    const root = document.getElementById("selection-container") as HTMLElement;
    root.style.height = (Math.max(positions.left.length, positions.right.length) + 1) * 46.5 + "px";
  }

  #positionColumn(column: DashboardTile[], all: HTMLElement[], left: string) {
    column.forEach((x, i) => {
      const m = all.find((y) => this.#getTileName(y) == x);
      if (!m) return;
      if (x == this.#getTileName(this.el)) return;
      m.style.position = "absolute";
      m.style.top = i * 46.5 + "px";
      m.style.left = left;
    });
  }

  #getElementsPositions() {
    const root = document.getElementById("selection-container") as HTMLElement;
    const threshold = root.clientWidth / 2 - 30;
    const all = this.#getAllTiles();

    const left = selectColumn(all, (x) => x.offsetLeft < threshold);
    const right = selectColumn(all, (x) => x.offsetLeft >= threshold);

    return { left, right };
  }

  #getAllTiles() {
    const all = Array.from(document.getElementsByClassName("tile-select")).map(
      (x) => x as HTMLElement
    );
    return all;
  }

  #getTileName(x: HTMLElement | undefined) {
    return x?.attributes.getNamedItem("data-name")?.nodeValue;
  }
}

function reduceTiles(tiles: DashboardTile[], selected: DashboardTile[]) {
  return tiles.reduce((acc, tile) => ({ ...acc, [tile]: selected.includes(tile) }), {});
}

function selectColumn(elements: Array<HTMLElement>, selector: (x: HTMLElement) => boolean) {
  return elements
    .filter(selector)
    .sort((x, y) => x.offsetTop - y.offsetTop)
    .map((x) => x.attributes.getNamedItem("data-name")?.nodeValue) as DashboardTile[];
}
