// #########################
// ## MODYFIKACJA
// #########################

import { AbstractControl, Validators } from "@angular/forms";

const validateLei = (control: AbstractControl) => {
  if (!control.touched) return null;

  // <-------- NOWE
  // const rx = /^[a-zA-Z0-9]{20}$/;
  // const value = control.value;
  // if (!value || !value.match(rx)) return { invalidLei: true };

  // if (calculateMod97(value.toUpperCase()) != 1) return { invalidLei: true };

  return null;
};

const calculateMod97 = (value: string) => {
  let total = 0;
  for (const x of value) {
    total = isNaN(Number(x)) ? total * 100 + x.charCodeAt(0) - 55 : total * 10 + parseInt(x, 10);

    if (total > 999999999) total %= 97;
  }
  return total % 97;
};

export const leiValidators = [
  Validators.required,
  Validators.maxLength(20),
  (control: AbstractControl) => {
    const left = 20 - (control.value?.length ?? 0);
    return left ? { charactersLeft: left } : null;
  },
  validateLei,
];
