<div pko-preloader *ngIf="isBusy$ | async"></div>
<form class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="pko-grid__row">
    <app-form-amount-currency
      class="col-10 col-md-5 col-lg-4"
      [currencies]="currencies$ | async"></app-form-amount-currency>
    <div class="w-100"></div>
    <app-form-date-bounds
      class="col-10 col-md-5 col-lg-4"
      transactionType="deposit"
      [getTenorDates]="getTenorDates"></app-form-date-bounds>
    <div class="w-100"></div>
    <app-select-account
      label="accounts.From"
      validate="account"
      formControlName="account"
      class="col-10 col-md-5 col-lg-4"
      [items]="accounts$ | async"
      ngDefaultControl></app-select-account>
  </div>
  <app-form-footer type="deposit"></app-form-footer>
</form>

<pre *ifIsDevMode>
    {{ form.value | json }}
</pre>
