<p>
  {{ "flowDeclaration.FillInHeaderExtension" | translate }}
</p>
<p class="fw-bold">
  {{
    "flowDeclaration.FillInHeader"
      | translate
        : {
            start: declaration.dateFrom | datePko,
            end: declaration.dateTo | datePko
          }
  }}
</p>
<p>{{ "flowDeclaration.NoFlowsDeclared" | translate }}</p>

<div class="pko-flow__container-footer" *ngIf="showActions">
  <button
    type="button"
    class="btn btn-secondary mb-3 mb-sm-0 ms-sm-auto"
    (click)="refuse()"
    *ngIf="allowRefuse">
    {{ "buttons.flowDeclaration.RefuseToAnswer" | translate }}
  </button>

  <button type="submit" class="btn btn-primary" (click)="new()">
    {{ "buttons.flowDeclaration.New" | translate }}
  </button>
</div>
