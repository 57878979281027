import { Failure } from "@core/models/transaction";

interface InitializationSuccess {
  token: string;
}

interface InitializationError {
  error: Failure;
}

export type InitializationResponse = InitializationSuccess | InitializationError;

export type ComplexInitializationResponse = InitializationResponse | InitializationWarning;

export const isInitializationSuccess = (
  res: InitializationResponse | unknown
): res is InitializationSuccess => !!(res as InitializationSuccess)?.token;

export const isInitializationError = (
  res: InitializationResponse | unknown
): res is InitializationError => !(res as InitializationSuccess)?.token;

export const isMifidError = (res: InitializationResponse | unknown): res is InitializationError =>
  !!(res as InitializationError)?.error;

interface InitializationWarning {
  warnings: Failure[];
}

export const isInitializationWarning = (
  res: ComplexInitializationResponse
): res is InitializationWarning => "warnings" in res && res.warnings.length > 0;
