import { GroupedArray } from "./collection";

/**
 * Counts properties of an object. Useful for finding empty objects.
 * @param object An object whose props are to be counted.
 * @returns Amount of object's props
 */
export const propCount = <T extends object>(object: T) =>
  (object && Object.keys(object).length) || 0;

export const isEmpty = <T extends object>(object: T) => !propCount(object);

/**
 * From T, pick a set of properties with given keys.
 * @param source Object to pick properties from.
 * @param keys Property names to pick.
 * @returns Object derived from a source object.
 * @see Pick - like TS util, but for instances, not types.
 */
export function pick<T, K extends keyof T>(source: T, ...keys: K[]): { [P in K]: T[P] } {
  const entries = keys.filter((key) => key in source).map((key) => [key, source[key]]);

  return Object.fromEntries(entries);
}

export function toArray<T>(values: Record<string, T[]>): GroupedArray<T> {
  return Object.entries(values).map(([key, value]) => ({ key, value }));
}

export function getByPath(values: object, path: string) {
  const parts = path.split(".");

  for (let current = 0; current < parts.length; current++) {
    const found = Object.entries(values).find(([key]) => key === parts[current]);
    if (!found) return undefined;
    values = found[1];
  }

  return values;
}
