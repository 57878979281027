import { uncapitalize } from "@utils/string";
import {
  add,
  addDays,
  dateOnly,
  datepickerFormat,
  shiftToUTC,
  timepickerFormat,
} from "@utils/time";
import { OrderPair, OrderType } from "./models/order-form";

export const getDefaultExpiration = (isLimit: boolean, time: number) =>
  parseExpiration(getExpiration(isLimit, time));

const getExpiration = (isLimit: boolean, time: number) => {
  const expiration = add(dateOnly(), time);

  if (isLimit) {
    return addDays(expiration, 7);
  }

  return new Date() < expiration
    ? // before today's expiration
      expiration
    : // after today's expiration
      addDays(expiration, 1);
};

const parseExpiration = (dateTime: Date) => ({
  // orders are one of the few instances where we deal with time portion
  // so when the local/utc differences matter.
  date: datepickerFormat(shiftToUTC(dateTime)),
  time: timepickerFormat(dateTime),
});

export const toFilteredPairs = (currencyPairs: OrderPair[]) => (type: OrderType) =>
  currencyPairs.filter((pair) => pair[uncapitalize(type)]);

export const hasPairs = (currencyPairs: OrderPair[]) =>
  currencyPairs?.some((pair) => pair.limit || pair.stopMarket || pair.trailingStop);
