import { Injectable } from "@angular/core";
import { filter, Observable, shareReplay, startWith, Subject, switchMap } from "rxjs";

export enum AppEvent {
  AppStarted,
  CustomerChanged,
  IndicativesChanged,
  TransactionSuccess,
  ApplicationAction,
  ApplicationProcessStarted,
  ApplicationProcessFinished,
}

@Injectable({ providedIn: "root" })
export class AppEventsService {
  private _event = new Subject<AppEvent>();
  event$ = this._event.asObservable();

  emit(event: AppEvent) {
    this._event.next(event);
  }

  observe<T>(callback: (currentEvent?: AppEvent) => Observable<T>, ...observedEvents: AppEvent[]) {
    return this._event.pipe(
      startWith(AppEvent.AppStarted),
      filter(
        (currentEvent) =>
          currentEvent === AppEvent.AppStarted ||
          !observedEvents?.length ||
          observedEvents.some((x) => x === currentEvent)
      ),
      switchMap((currentEvent) => callback(currentEvent)),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }
}
