<h6 class="pko-recent-history__item-status {{ headerColor }}">
  {{ "history.settlementStatus." + deal.settlementStatus | translate }}
</h6>
<app-labeled-text-inline
  class="pko-recent-history__item-deal"
  [label]="'sides.history.' + deal.side">
  <span [class.text-danger]="isSelling">{{ deal.amount | balance: deal.currency }}</span>
</app-labeled-text-inline>
<app-labeled-text-inline class="pko-recent-history__item-deal" label="history.WithRate"
  >{{ deal.rate | rate: deal.currencyPair }}
</app-labeled-text-inline>
