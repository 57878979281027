import { NgModule } from "@angular/core";
import { TenorModule } from "@features/tenor/tenor.module";
import { SharedModule } from "@shared/shared.module";
import { DateBoundsComponent } from "../deposit-common/components/form-date-bounds/date-bounds.component";
import { DateBoundsDepositElementComponent } from "./components/form-date-bounds/form-date-bounds-elements/date-bounds-deposit.component";
import { DateBoundsInvDepositElementComponent } from "./components/form-date-bounds/form-date-bounds-elements/date-bounds-inv-deposit.component";

@NgModule({
  imports: [SharedModule, TenorModule],
  declarations: [
    DateBoundsComponent,
    DateBoundsDepositElementComponent,
    DateBoundsInvDepositElementComponent,
  ],
  exports: [DateBoundsComponent],
})
export class DepositCommonModule {}
