import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Question, QuestionAnswer } from "../../mifid.model";
import { toQuestionAnswer } from "../../mifid.utils";

@Component({
  selector: "app-mifid-question-radio",
  templateUrl: "./mifid-question-radio.component.html",
})
export class MifidQuestionRadioComponent {
  #question!: Question;
  @Input() index!: number;
  @Input() total!: number;
  @Output() backClicked = new EventEmitter<void>();
  @Output() nextClicked = new EventEmitter<QuestionAnswer>();

  @Input() set question(question: Question) {
    this.#question = question;

    const defaultAnswer = this.question.answers.find((x) => x.isRecentlyChosen)?.id;

    this.form = this.fb.group({
      answer: this.fb.control(defaultAnswer, Validators.required),
    });
  }

  get question(): Question {
    return this.#question;
  }

  form!: FormGroup;

  get answer() {
    return this.form.controls.answer as FormControl;
  }

  constructor(private fb: FormBuilder) {}

  next() {
    if (!handleFormErrors(this.form)) return;

    const { answer } = this.form.value;
    this.question.answers.forEach((ans) => (ans.isRecentlyChosen = ans.id === answer));
    this.nextClicked.emit(toQuestionAnswer(this.question));
  }

  back() {
    this.backClicked.emit();
  }

  get hasError() {
    return !!this.form && !this.form.valid && (this.form.dirty || this.form.touched);
  }
}
