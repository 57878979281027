import { Component } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { PreferencesService } from "@core/preferences/preferences.service";
import { Account, AccountsService, getAccountByNumber } from "@features/accounts";
import { Currency, CurrencyService, getCurrencyByCode } from "@features/currency";
import { DecisionViewModel } from "@features/transaction/models";
import { combineLatest, map, Observable, startWith } from "rxjs";
import { OrderForm, OrderSummary } from "../../models/order-form";
import { OrderFormService } from "../../services/order-form.service";
import { OrderService } from "../../services/order.service";

@Component({
  selector: "app-order-decision",
  templateUrl: "order-decision.component.html",
})
export class OrderDecisionComponent {
  readonly showBalanceWarning: boolean;

  vm$: Observable<DecisionViewModel<OrderSummary>>;
  loading$ = this.orderService.confirmation$.pipe(mapToBusy(), startWith(false));

  constructor(
    private orderService: OrderService,
    { current }: OrderFormService,
    { exchangeAccounts$ }: AccountsService,
    { allCurrencies$ }: CurrencyService,
    { transactionControl }: PreferencesService
  ) {
    this.showBalanceWarning = !transactionControl.allowDealsWithoutBalance;
    this.vm$ = this.#constructViewModel(exchangeAccounts$, allCurrencies$, current);
  }

  #constructViewModel(
    accounts$: Observable<Account[]>,
    currencies$: Observable<Currency[]>,
    form: OrderForm
  ) {
    return combineLatest([accounts$, currencies$], mapToSummary(form)).pipe(
      map((summary) => ({
        decision: {
          summary,
          forward: () => this.orderService.confirm(form, summary),
          // 0 allows for decision buttons without a progress bar
          time: 0,
        },
      }))
    );
  }
}

const mapToSummary =
  ({ other, ...form }: OrderForm) =>
  (accounts: Account[], currencies: Currency[]): OrderSummary => {
    const mapOco = other && mapToSummary({ ...other, expiration: form.expiration } as OrderForm);

    return {
      ...form,
      points: +form.points / 10000,
      currency: getCurrencyByCode(currencies, form.currency),
      counterCurrency: getCurrencyByCode(currencies, form.counterCurrency),
      account: getAccountByNumber(accounts, form.account),
      counterAccount: getAccountByNumber(accounts, form.counterAccount),
      oco: mapOco?.(accounts, currencies),
    } as OrderSummary;
  };
