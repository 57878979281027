import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfigStore } from "@core/config";
import { mapToBusy } from "@core/models/observable-action";
import { reverseSide } from "@core/models/transaction";
import { NavigationService } from "@core/navigation";
import { Tracker } from "@core/user-tracking/tracker.service";
import { mapToCollateralForm } from "@features/collateral";
import { prepareForm } from "@features/exchange/exchange-utils";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { ModalService } from "@features/modal/modal.service";
import { TenorDateForm } from "@features/tenor";
import { ComplexInitializationResponse } from "@features/transaction/models";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Dialog } from "@shared/components/modal/modal-model";
import { Observable, tap } from "rxjs";
import {
  OriginalExchange,
  RollExchangeForm,
  RollRateType,
  RollableOriginalExchange,
} from "../../models";
import { ExchangeFormService, ExchangeResponseService, ExchangeService } from "../../services";
import { getActionForm } from "../action-utils";

@Component({
  selector: "app-form-exchange-roll",
  templateUrl: "form-exchange-roll.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RollExchangeFormComponent {
  readonly original: RollableOriginalExchange;
  readonly link: string;
  readonly form: FormGroup;
  readonly rateTypes: RollRateType[];
  readonly mifidValidation$: Observable<ComplexInitializationResponse>;

  isBusy$ = this.formService.submission$.pipe(mapToBusy());

  back = () => this.navigationService.back();

  constructor(
    { config }: ConfigStore,
    fb: FormBuilder,
    private formService: ExchangeFormService,
    private exchangeService: ExchangeService,
    private responseService: ExchangeResponseService,
    public warningService: ExchangeWarningCodeService,
    private navigationService: NavigationService,
    private modal: ModalService,
    private tracker: Tracker,
    private router: Router
  ) {
    const {
      form: { amount, rateType, settlement, collateral, pvp },
      original,
    } = getActionForm(
      window.history.state,
      formService.current as RollExchangeForm as RollForm,
      defaultFormMapper
    );

    this.tracker.follow(
      { process: "exchange", origin: history.state?.origin ?? "reload" },
      { type: "roll" }
    );

    this.navigationService.setupBack(() =>
      this.router.navigate([`/history/exchange/fx/${original.id}`])
    );

    const { rateTypes } = original.rollData;

    if (!rateTypes?.length) {
      throw new Error("Rolling is disabled");
    }

    this.link = config.links.swapRisk;
    this.original = original as RollableOriginalExchange;
    this.rateTypes = rateTypes;
    this.form = fb.group({
      amount,
      pvp: { value: pvp, disabled: true },
      product: { value: null, disabled: true },
      rateType: [{ value: rateType, disabled: rateTypes.length === 1 }, Validators.required],
      settlement: fb.group(settlement),
      collateral: fb.group(collateral ?? {}),
    });

    this.mifidValidation$ = this.exchangeService
      .validateMifid("FxSwap")
      .pipe(
        tap((response) => this.responseService.handleMifidResponse(response, "roll", this.back))
      );
  }

  async onSubmit() {
    if (!handleFormErrors(this.form)) return;

    const [form, isRollover] = mapToForm(this.original, prepareForm(this.form));
    const confirmed = await this.modal.dialog(confirmationDialog(isRollover)).result;
    if (!confirmed) return;

    this.formService.reset();
    this.formService.change("swap");
    this.formService.save(form);
    this.formService.submit({ variation: "roll" });
  }
}

const mapToForm = (
  original: RollableOriginalExchange,
  form: Pick<RollForm, EditableFields>
): [RollExchangeForm, boolean] => {
  // this function runs after form validation, so date won't be null.
  const selectedSettlement: ValidTenorDateForm = form.settlement as ValidTenorDateForm;
  const originalSettlement: ValidTenorDateForm = { date: original.settlementDate };

  // both are ISO strings (yyyy-MM-dd)
  const isRollover = selectedSettlement.date > originalSettlement.date;

  return [
    {
      original,
      id: original.id,
      far: original.far,
      side: isRollover ? reverseSide(original.side) : original.side,
      currency: original.currency,
      counterCurrency: original.counterCurrency,
      currencyPair: original.currencyPair,
      account: original.account.number,
      counterAccount: original.counterAccount.number,
      ...form,
      nearSettlement: isRollover ? originalSettlement : selectedSettlement,
      farSettlement: !isRollover ? originalSettlement : selectedSettlement,
    },
    isRollover,
  ];
};

const defaultFormMapper = ({
  settlementDate,
  collateral,
  pvp,
  rollData: { rateTypes },
}: OriginalExchange) => ({
  amount: null,
  rateType: rateTypes.length === 1 ? rateTypes[0] : null,
  settlement: { tenor: null, date: settlementDate },
  collateral: mapToCollateralForm(collateral) ?? {},
  pvp,
});

const confirmationDialog: (isRollover: boolean) => Dialog = (isRollover: boolean) => ({
  title: "modals.roll.title",
  body: `modals.roll.${isRollover ? "rollover" : "rollback"}`,
  buttons: {
    primary: { text: "modals.roll.primary", resultOnClose: true },
    secondary: { text: "buttons.Resign" },
  },
});

type EditableFields = "amount" | "rateType" | "settlement" | "collateral" | "product";

/**
 * Actual properties used by this component. The full RollExchangeForm is used on decision screen.
 */
type RollForm = Pick<RollExchangeForm, EditableFields | "pvp" | "original">;

type ValidTenorDateForm = TenorDateForm & { date: string };
