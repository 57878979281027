import { ChangeDetectionStrategy, Component, ElementRef, HostBinding } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { CurrencyService } from "@features/currency";
import { LiveRateService } from "@features/transaction/components/form-rate/live-rate.service";
import { AlertFormService } from "../../services/alert-form.service";
import { AlertFormComponent } from "../form-alert/form-alert.component";

@Component({
  selector: "app-form-alert-tile",
  templateUrl: "./form-alert-tile.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LiveRateService],
})
export class AlertFormTileComponent extends AlertFormComponent {
  @HostBinding("class") class = "position-relative";

  isTile = true;

  constructor(
    fb: FormBuilder,
    formService: AlertFormService,
    currencyService: CurrencyService,
    busyService: BusyService,
    private elem: ElementRef,
    tracker: Tracker
  ) {
    super(fb, formService, currencyService, busyService, tracker);
  }

  onSubmit() {
    const componentSelector = this.elem.nativeElement.tagName.toLowerCase();
    super.onSubmit(true, componentSelector);
  }
}
