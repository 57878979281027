import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export function stringToDateStruct(value: string) {
  const [year, month, day] = value.split("-");
  return { year: +year, month: +month, day: +day };
}

export function dateStructToString(date: NgbDateStruct | null): string | null {
  if (!date) return null;

  return `${date.year}-${pad(date.month)}-${pad(date.day)}`;
}

const pad = (value: number, max = 2, fill = "0") => value.toString().padStart(max, fill);

export type ValidatorName = "required" | "min" | "max" | "weekend" | "holiday" | "restricted";
