<app-modal-container [config]="modalConfig">
  <p>
    {{
      (leiMessage.expired ? "lei.message.Expired" : "lei.message.Expiration")
        | translate
          : { code: leiMessage.lei.code, expirationDate: leiMessage.lei.expirationDate | datePko }
    }}
  </p>
  <br />
  <p>{{ "lei.message.ExtendExpiration" | translate }}</p>
  <br />
  <p [innerHTML]="'lei.message.Details' | translate"></p>
  <br />
  <div>
    <a [href]="leiMessage.infoLink" target="_blank" class="text-primary text-decoration-none">
      {{ "lei.message.Link" | translate }}
    </a>
  </div>
</app-modal-container>
