<div class="pko-dialog__container px-5 h-100 pko-news">
  <div class="pko-dialog__header my-4">
    <button
      type="button"
      class="pko-dialog__close"
      [attr.aria-label]="'buttons.Close' | translate"
      (click)="close()">
      <svg icon name="close_nav" class="icon icon--lg"></svg>
    </button>
    <h3 class="pko-dialog__title">
      {{ data.headline }}
    </h3>
  </div>
  <div class="pko-dialog__body modal-body">
    <p [innerHtml]="data.content"></p>
  </div>
</div>
