<div class="pko-details__container" [formGroup]="form">
  <div class="pko-modal__titlebar">
    <button
      class="btn pko-modal__titlebar-button pko-modal__titlebar-button--close"
      (click)="close()">
      <div class="pko-modal__titlebar-button-container">
        <svg icon name="close_nav" class="icon icon--lg"></svg>
      </div>
    </button>
    <div class="pko-modal__titlebar-title">{{ "graphs.Title" | translate }}</div>
  </div>
  <div class="pko-modal__grid pko-graph pko-details__main">
    <app-select
      label="Tenor"
      formControlName="tenor"
      [items]="tenors"
      translateText="tenor"
      class="g-col-8"
      *ngIf="!isSimple"
      ngDefaultControl></app-select>
    <app-form-currency-pair [pairs]="pairs$ | async" class="g-col-8"></app-form-currency-pair>
    <div class="g-col-8 bg-white pko-graph__modal-content">
      <app-form-side [pointed]="true"></app-form-side>
      <ng-container *ngIf="graph$ | async as vm">
        <ng-container *ngIf="vm; else empty">
          <div class="position-relative">
            <highcharts-chart
              [Highcharts]="vm.Highcharts"
              [constructorType]="'stockChart'"
              [options]="vm.chartOptions"
              [(update)]="vm.update"
              [callbackFunction]="chartCallback"
              [oneToOne]="vm.oneToOneFlag"
              [runOutsideAngular]="vm.runOutsideAngularFlag"
              class="d-block w-100">
            </highcharts-chart>

            <div class="pko-graph__footer d-flex" *ngIf="!isSimple">
              <button
                class="pko-graph__button pko-graph__button--link"
                [class.pko-graph__button--selected]="vm.type === 'linear'"
                (click)="switchType('linear')">
                {{ "graphs.Linear" | translate }}
              </button>
              <hr class="pko-graph__button-separator" />
              <button
                class="pko-graph__button pko-graph__button--link"
                [class.pko-graph__button--selected]="vm.type === 'candle'"
                (click)="switchType('candle')">
                {{ "graphs.Candle" | translate }}
              </button>
            </div>
          </div>
          <p class="mt-3">
            {{ "Tenor" | translate }}: <b>{{ "tenor." + tenor | translate }}</b>
          </p>
          <p class="pko-infotext pko-infotext--xs">
            {{ "graphs.MoreInfoMessage" | translate }}
          </p>
        </ng-container>
      </ng-container>
    </div>
    <div pko-preloader *ngIf="isBusy$ | async"></div>
  </div>
</div>

<ng-template #empty>
  <app-alert content="missingData.Graph"></app-alert>
</ng-template>
