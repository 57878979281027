import { GroupedHistoryItem, HistoryItem, PageData } from "@features/history-common/models";
import { propCount } from "@utils/object";
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  map,
  of,
  scan,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { BaseFilters } from "../models/common-list";
import { IListItem } from "../models/view360-list";

export abstract class HistoryCommonService<
  T extends BaseFilters,
  U extends HistoryItem | GroupedHistoryItem | IListItem
> {
  private operation$ = new Subject<Operation<U>>();
  abstract filters: T;
  protected abstract defaultFilters: T;
  protected _allLoaded = false;
  filtersCount = 0;

  historyData$: Observable<U[]> = this.operation$.pipe(
    startWith({ type: "load", page: 1 } as Operation<U>),
    switchMap((op) => this.concatPageData(op)),
    scan((acc, { items, op }) => {
      if (op.type === "load") {
        if (op.page === 1) {
          return items;
        } else {
          return acc.concat(items);
        }
      }

      if (op.type === "export") {
        op.items.next(acc);
      }

      return acc;
    }, [] as U[])
  );

  private concatPageData(op: Operation<U>) {
    return (
      op.type === "load" ? this.getPageData$ : of({ items: [], isLastPage: this._allLoaded })
    ).pipe(
      map((data) => ({
        items: data.items,
        op,
      }))
    );
  }

  export() {
    const exportedItems = new BehaviorSubject<U[]>({} as U[]);
    this.operation$.next({ type: "export", items: exportedItems });
    return exportedItems.asObservable();
  }

  get allLoaded() {
    return this._allLoaded;
  }

  updateFilters(filters: Partial<T>) {
    this._allLoaded = false;
    this.filters = { ...this.filters, ...filters, pageNumber: 1 };
    this.loadNextPage();
  }

  reset(filters: Partial<T> = {}) {
    this._allLoaded = false;
    this.filtersCount = propCount(filters);
    this.filters = { ...this.defaultFilters, ...filters, pageNumber: 1 };
  }

  loadFirstPage(filters: Partial<T> = {}) {
    this.reset(filters);
    this.loadNextPage();
  }

  loadNextPage() {
    this.operation$.next({ type: "load", page: this.filters.pageNumber });
  }

  protected handlePageData<V extends { isLastPage: boolean }>() {
    return (source$: Observable<V>) =>
      source$.pipe(
        catchError(() => of(pageDataError)),
        tap(({ isLastPage }) => {
          this._allLoaded = isLastPage;
          this.filters.pageNumber++;
        })
      );
  }

  protected abstract getPageData$: Observable<PageData<U>>;
}

const pageDataError: PageData<never> = { items: [], isLastPage: true };

type Operation<Item extends HistoryItem | GroupedHistoryItem | IListItem> =
  | LoadOperation
  | ExportOperation<Item>;

interface LoadOperation {
  type: "load";
  page: number;
}

interface ExportOperation<Item extends HistoryItem | GroupedHistoryItem | IListItem> {
  type: "export";
  items: BehaviorSubject<Item[]>;
}
