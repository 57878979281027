import { Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { CurrencyPair } from "@features/currency/model";

@Component({
  selector: "app-form-currency-pair",
  templateUrl: "./form-currency-pair.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormCurrencyPairComponent {
  @Input() pairs!: CurrencyPair[] | null;
  @Input() label = "CurrencyPair";
  @Input() showLabel = true;
  @Input() labelDivider = "";
  @Input() disableSingleValue = true;
}
