<app-form-control
  [label]="label ? (label | translate : labelData) : ''"
  [translateLabel]="false"
  [tooltip]="tooltip ?? ''"
  [tooltipData]="tooltipData">
  <div class="pko-slider" [id]="id" [style.opacity]="opacity">
    <div class="pko-slider__bar" #bar></div>
    <div class="pko-slider__bar pko-slider__bar--selected" #progress></div>
    <div class="pko-slider__verticalLine" *ngIf="type === 'range'"></div>
    <div class="pko-slider__handle" #handle1></div>
    <div class="pko-slider__handle__value user-select-none" #handle1Value>
      {{ disabled ? "" : (this.value | leftSlider : numberFormat : labelSuffix) }}
    </div>
    <div class="pko-slider__handle" #handle2 *ngIf="type === 'range'"></div>
    <div class="pko-slider__handle__value user-select-none" #handle2Value *ngIf="type === 'range'">
      {{ disabled ? "" : (this.value | rightSlider : numberFormat : labelSuffix) }}
    </div>
  </div>
</app-form-control>
