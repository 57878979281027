<app-transaction-decision type="order" [loading$]="loading$" [vm$]="vm$">
  <ng-template #summary let-summary>
    <app-alert *ngIf="showBalanceWarning" content="order.BalanceWarning" icon="critical">
    </app-alert>
    <div class="pko-grid__row">
      <app-order-summary-quote class="col-10 col-md-4 order-md-last" [quote]="summary">
      </app-order-summary-quote>
      <app-order-summary-detail class="col-10 col-md-6" [detail]="summary">
      </app-order-summary-detail>
    </div>
    <div class="pko-grid__row mt-4" *ngIf="summary.oco as oco">
      <h3 translate>order.OCO</h3>
      <app-order-summary-quote class="col-10 col-md-4 order-md-last" [quote]="oco">
      </app-order-summary-quote>
      <app-order-summary-detail class="col-10 col-md-6" [detail]="oco"></app-order-summary-detail>
    </div>
  </ng-template>
</app-transaction-decision>
