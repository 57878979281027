import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  templateUrl: "./csv-document.component.html",
  selector: "app-csv-document",
})
export class CsvDocumentComponent {
  @Input() documentTitle!: string;
  @Output() clicked = new EventEmitter();

  getCsv() {
    this.clicked.emit();
  }
}
