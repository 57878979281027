import { Component, HostBinding, OnDestroy } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { Account, AccountsService, getAccountByNumber } from "@features/accounts";
import { Currency, CurrencyService, getCurrencyByCode } from "@features/currency";
import { DecisionViewModel, RateStreamable, isRateSuccess } from "@features/transaction/models";
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { MultiFxForm, MultiFxLegForm } from "../../models/multifx-form";
import { MultiFxRateSuccess, MultiFxTransactionRateSuccess } from "../../models/multifx-rate";
import { MultiFxLegSummary, MultiFxSummary } from "../../models/multifx-summary";
import { MultiFxFormService } from "../../services/multifx-form.service";
import { MultiFxService } from "../../services/multifx.service";

@Component({
  selector: "app-multifx-decision",
  templateUrl: "./decision.component.html",
})
export class DecisionComponent implements OnDestroy {
  @HostBinding("class") class = "pko-decision";

  #subscription = this.multiFxService.rejection$.subscribe();

  #rate$ = this.multiFxService.pollRate();

  loading$ = merge(
    this.#rate$.pipe(map(() => false)),
    this.multiFxService.confirmation$.pipe(mapToBusy()),
    this.multiFxService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$: Observable<DecisionViewModel<MultiFxSummary>> = this.#rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  constructor(
    private formService: MultiFxFormService,
    private accountsService: AccountsService,
    private currencyService: CurrencyService,
    private multiFxService: MultiFxService,
    private tracker: Tracker
  ) {}

  async ngOnDestroy() {
    await this.multiFxService.rejectAsync();
    this.#subscription.unsubscribe();
  }

  #constructViewModel(rate: RateStreamable): Observable<DecisionViewModel<MultiFxSummary>> {
    if (!isRateSuccess<MultiFxRateSuccess>(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current;

    return combineLatest(
      [
        this.accountsService.exchangeAccounts$,
        this.accountsService.collateralAccounts$,
        this.currencyService.allCurrencies$,
      ],
      mapToSummary(rate, form)
    ).pipe(
      map((summary) => {
        const decision = {
          summary,
          time: rate.decisionTime,
          ...this.#getActions(rate.quoteId),
        };

        return { decision };
      })
    );
  }

  #trackRate({ decision }: DecisionViewModel<MultiFxSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.token) return;

    this.tracker.reportProgress({
      action: "rate",
      data: {
        token: summary.token,
        pair: summary.currencyPair,
      },
    });
  }

  #getActions(quoteId: number) {
    return {
      onTimeout: () => this.multiFxService.reject(),
      forward: quoteId
        ? () => {
            this.tracker.reportProgress({
              action: "confirm",
              data: { token: `${quoteId}` },
            });
            this.multiFxService.confirm(quoteId.toString());
          }
        : () => this.formService.submit(),
    };
  }
}

const mapToSummary =
  (rate: MultiFxRateSuccess, form: MultiFxForm) =>
  (accounts: Account[], collateralAccounts: Account[], currencies: Currency[]) =>
    ({
      ...rate,
      dealCurrencyTotal: form.series
        .map((formLeg) => +formLeg.amount * (formLeg.side === "Sell" ? -1 : 1))
        .reduce((sum, current) => sum + current, 0),
      currencyPair: form.currencyPair,
      currency: getCurrencyByCode(currencies, form.currency),
      counterCurrency: getCurrencyByCode(currencies, form.counterCurrency),
      account: getAccountByNumber(accounts, form.account),
      counterAccount: getAccountByNumber(accounts, form.counterAccount),
      shortCollateral: form.shortCollateral && {
        type: form.shortCollateral.type,
        account: getAccountByNumber(collateralAccounts, form.shortCollateral.account),
      },
      forwardCollateral: form.forwardCollateral && {
        type: form.forwardCollateral.type,
        account: getAccountByNumber(collateralAccounts, form.forwardCollateral.account),
      },
      series: form.series.map((formLeg, index) => mapToSeries(rate.series[index], formLeg)),
      profitCurrency: form.counterCurrency,
    } as MultiFxSummary);

const mapToSeries = (rate: MultiFxTransactionRateSuccess, form: MultiFxLegForm) =>
  ({
    ...form,
    ...rate,
  } as MultiFxLegSummary);
