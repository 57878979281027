<!-- 
// #########################
// ## MODYFIKACJA
// #########################
 -->

<svg icon name="file-pdf" class="icon--2xl flex-shrink-0"></svg>
<div>
  <div class="text-sm fw-bold mb-1">{{ title }}</div>
  <!-- <a [href]="href" target="_blank" class="text-primary text-sm text-decoration-none">{{
    "OpenPdf" | translate
    }}</a> -->
  <!-- <<-- NOWE  -->
  <span class="text-primary text-sm text-decoration-none">
    {{ "OpenPdf" | translate }}
  </span>
</div>
