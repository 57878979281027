import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivate } from "@shared/app-guard";
import {
  CloseExchangeFormComponent,
  DecisionComponent,
  ExchangeComponent,
  RollExchangeFormComponent,
  SwapDecisionComponent,
} from "./components";

const routes: Routes = [
  {
    path: "",
    component: ExchangeComponent,
    canActivate: [AppCanActivate],
    data: {
      expectedRole: "FxSpot|FxForward|FxSwap|ProUser",
      title: "pages.Exchange",
      dontClearBack: true,
    },
  },
  {
    path: "forward",
    component: ExchangeComponent,
    canActivate: [AppCanActivate],
    data: {
      expectedRole: "FxSpot|FxForward|FxSwap|ProUser",
      title: "pages.Exchange",
      type: "forward",
      dontClearBack: true,
    },
  },
  {
    path: "swap",
    component: ExchangeComponent,
    canActivate: [AppCanActivate],
    data: {
      expectedRole: "FxSpot|FxForward|FxSwap|ProUser",
      title: "pages.Exchange",
      type: "swap",
      dontClearBack: true,
    },
  },
  {
    path: "close",
    component: CloseExchangeFormComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "FxSpot|FxForward|FxSwap|ProUser", dontClearBack: true },
  },
  {
    path: "roll",
    component: RollExchangeFormComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "FxSwap", dontClearBack: true },
  },
  {
    path: ":token",
    component: DecisionComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "FxSpot|FxForward|FxSwap|ProUser", dontClearBack: true },
  },
  {
    path: "swap/:token",
    component: SwapDecisionComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "FxSwap|ProUser", dontClearBack: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExchangeRoutingModule {}
