import { Component, Input } from "@angular/core";
import { Currency } from "@features/currency";
import { MultiFxLegSummary } from "@features/multifx/models/multifx-summary";

@Component({
  selector: "[multiDpwLegSummary]",
  templateUrl: "./summary-quote-row.component.html",
})
export class SummaryQuoteRowComponent {
  @Input() multiDpwLegSummary!: MultiFxLegSummary;
  @Input() currency!: Currency;
  @Input() counterCurrency!: Currency;
  @Input() stack = false;
}
