import { Injectable } from "@angular/core";
import { mapToAction } from "@core/models/observable-action";
import {
  isInitializationSuccess,
  isRateFailure,
  isRateStreamable,
} from "@features/transaction/models";
import { ConfirmationRequest } from "@features/transaction/models/confirmation";
import { TransactionApiService } from "@features/transaction/services/api.service";
import { pollStatus } from "@features/transaction/utils/form";
import {
  BehaviorSubject,
  EMPTY,
  ObservableInput,
  Subject,
  catchError,
  endWith,
  filter,
  repeat,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { DepositRateResponse, rejectedRate } from "../models/deposit-rate";
import { ConfirmationData } from "../models/deposit-summary";
import { InitializationRequest } from "../models/initialization";
import { DepositResponseService } from "./deposit-response.service";

@Injectable({ providedIn: "root" })
export class DepositService {
  private _submission = new Subject<InitializationRequest>();
  submission$ = this._submission.pipe(switchMap(this.initialize.bind(this)));

  #token = new BehaviorSubject("");
  get token() {
    return this.#token.getValue();
  }

  constructor(
    private api: TransactionApiService,
    private responseService: DepositResponseService
  ) {}

  submit(form: InitializationRequest) {
    this._submission.next(form);
  }

  initialize(form: InitializationRequest) {
    const resubmit = (consents: string[]) => this.submit({ ...form, consents });
    return this.api.initialize("deposit", form).pipe(
      tap((response) => this.#token.next(isInitializationSuccess(response) ? response.token : "")),
      tap((response) =>
        this.responseService.handleInitResponse({
          response,
          resubmit,
        })
      ),
      mapToAction()
    );
  }

  pollRate(token: string, notifier: ObservableInput<unknown>) {
    return this.api.getRate<DepositRateResponse>("deposit", token).pipe(
      repeat({ delay: 1000 }),
      tap((response) => isRateFailure(response) && this.#token.next("")),
      tap((res) => this.responseService.handleRateError(res)),
      filter(isRateStreamable),
      takeUntil(notifier),
      endWith(rejectedRate)
    );
  }

  reject(token: string) {
    return this.api.reject("deposit", token);
  }

  confirm(tokens: ConfirmationRequest, data: ConfirmationData) {
    const handleConfirmationError = () => {
      this.responseService.handleError();
      return EMPTY;
    };

    return this.api.confirm("deposit", tokens).pipe(
      catchError(handleConfirmationError),
      switchMap((token) => this.#pollStatus(token)),
      tap((status) => this.responseService.handleStatusResponse(status, data)),
      mapToAction()
    );
  }

  #pollStatus(token: string) {
    return this.api.getStatus("deposit", token).pipe(pollStatus());
  }
}
