// #########################
// ## MODYFIKACJA
// #########################

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { Graph } from "@core/preferences/preferences.model";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session";
import { ModalService } from "@features/modal/modal.service";
import { TranslateService } from "@ngx-translate/core";
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  fromEvent,
  merge,
  of,
} from "rxjs";
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { commonData } from "src/app/PREZENTACJA/prezentacja.interceptor"; // <---- NOWE
import { BaseGraphComponent } from "../base-graph.component";
import { GraphButtonConfig, GraphModel } from "../graph.model";
import { GraphService } from "../graph.service";
import { GraphModalComponent } from "./graph-modal.component";

@Component({
  selector: "app-graph",
  templateUrl: "./graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphComponent extends BaseGraphComponent implements OnDestroy {
  @HostBinding("class") class = "pko-graph";
  @Input()
  public get currencyPair(): string {
    return this.#pair;
  }
  public set currencyPair(value: string) {
    this.#pair = value;
    this.pair$.next(value);
  }

  @Input()
  public get tenor(): string {
    return this.#tenor;
  }
  public set tenor(value: string) {
    this.#tenor = value;
    this.tenor$.next(value);
  }

  #destroy$ = new Subject<void>();
  #pair!: string;
  #tenor!: string;
  #type: "linear" | "candle" = "linear";
  form: FormGroup;

  isBusy$ = this.busyService.observeOn("/graph/data", "/indicatives");

  preferences$ = this.preferences.graph$;
  pair$ = new ReplaySubject<string>(1);
  tenor$ = new ReplaySubject<string>(1);
  graph$: Observable<GraphModel | null>;
  type$ = new Subject<"linear" | "candle">();
  simple$: Observable<boolean> = this.preferences.dashboard$.pipe(map((x) => x.simple));

  preferencesUpdate$ = new Subject<UpdateRequest>();

  get type() {
    return this.preferences.dashboard.simple || !this.#type ? "linear" : this.#type;
  }

  get side() {
    return this.form.controls.side.value;
  }

  constructor(
    userService: UserService,
    formBuilder: FormBuilder,
    translate: TranslateService,
    private graphService: GraphService,
    private preferences: PreferencesService,
    private busyService: BusyService,
    private modalService: ModalService,
    private elRef: ElementRef
  ) {
    super(translate, userService, false);

    const side = new FormControl("Sell", Validators.required);
    this.form = formBuilder.group({
      side,
    });

    const loadPreferences$ = this.preferences$.pipe(
      take(1),
      map((x) => ({ source: "initial", preferences: x }))
    );

    const updatePreferences$ = this.preferencesUpdate$.pipe(
      tap((x) => this.preferences.setGraph(x.preferences))
    );

    const setVariables = (preferences: Graph) => {
      this.#type = preferences.type;
      this.form.controls.side.setValue(preferences.side, { emitEvent: false });
      this.graphRange = preferences.period;
    };

    const doNotReloadDataOnOnlyPeriodChange = (
      old: UpdateRequest,
      { source, preferences }: UpdateRequest
    ) => {
      return (
        old === null ||
        (preferences !== null &&
          (old.preferences.period === preferences.period || source !== "dashboard"))
      );
    };

    const loadChart = ([config]: [GraphButtonConfig[][], unknown]) =>
      combineLatest([of(config), this.pair$, this.tenor$]).pipe(
        switchMap(([config]) =>
          this.graphService.getData(this.currencyPair, this.tenor).pipe(
            switchMap((data) =>
              fromEvent(window, "resize").pipe(
                debounceTime(500),
                map(() => data),
                startWith(data)
              )
            ),
            map((data) =>
              this.type === "linear"
                ? this.getLinearChartOptions(config[0], data)
                : this.getCandleChartOptions(config[1], data)
            ),
            switchMap((data) => from([null, data]).pipe(delay(0)))
          )
        )
      );

    this.graph$ = combineLatest([
      this.graphService.getConfig(),
      merge(loadPreferences$, updatePreferences$).pipe(
        startWith({
          source: "initial",
          preferences: {},
        } as UpdateRequest),
        pairwise()
      ),
    ]).pipe(
      tap(([, [, { preferences }]]) => setVariables(preferences)),
      filter(([, [old, curr]]) =>
        doNotReloadDataOnOnlyPeriodChange(old as UpdateRequest, curr as UpdateRequest)
      ),
      switchMap(loadChart)
    );

    merge(
      this.type$.pipe(
        distinctUntilChanged(),
        tap((type) => (this.#type = type))
      ),
      side.valueChanges.pipe(distinctUntilChanged(), debounceTime(0)),
      this.graphRange$.pipe(distinctUntilChanged())
    )
      .pipe(debounceTime(500), takeUntil(this.#destroy$))
      .subscribe(() =>
        this.preferencesUpdate$.next({ source: "dashboard", preferences: this.getSettings() })
      );
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  switchType(type: "linear" | "candle") {
    if (type === "linear") {
      // <<------- NOWE
      commonData.isGraphLinear = true; // <<------- NOWE
    } // <<------- NOWE
    if (type === "candle") {
      // <<------- NOWE
      commonData.isGraphLinear = false; // <<------- NOWE
    } // <<------- NOWE
    this.type$.next(type);
  }

  openModal() {
    const modal = this.modalService.modal({
      component: GraphModalComponent,
      data: {
        currencyPair: this.currencyPair,
        tenor: this.tenor,
        side: this.side,
      },
      size: "xl",
    });
    modal.result.then(
      (result) => this.preferencesUpdate$.next({ source: "modal", preferences: result }),
      () => void 0
    );
  }

  graphButtons(bc: GraphButtonConfig[]) {
    const dg = (units: any) => {
      return {
        approximation: this.type == "linear" ? "close" : "ohlc",
        dateTimeLabelFormats: this.getDateTimeLabelFormats(),
        forced: true,
        enabled: true,
        units: units,
      };
    };

    return [
      {
        type: bc[0].interval,
        count: bc[0].count,
        text: this.translate.instant("graphs.buttons.Day"),
        dataGrouping: dg([["minute", [this.type === "linear" ? 5 : 60]]]),
      },
      {
        type: bc[1].interval,
        count: bc[1].count,
        text: this.translate.instant("graphs.buttons.Week"),
        dataGrouping: dg([["hour", [this.type === "linear" ? 1 : 4]]]),
      },
      {
        type: bc[2].interval,
        count: bc[2].count,
        text: this.translate.instant("graphs.buttons.Month1"),
        dataGrouping: dg([["hour", [this.type === "linear" ? 8 : 24]]]),
      },
      {
        type: bc[3].interval,
        count: bc[3].count,
        text: this.translate.instant("graphs.buttons.Month3"),
        dataGrouping: dg([["hour", [this.type === "linear" ? 24 : 48]]]),
      },
      {
        type: bc[4].interval,
        count: bc[4].count,
        text: this.translate.instant("graphs.buttons.Month12"),
        dataGrouping: dg([this.type === "linear" ? ["day", [7]] : ["week", [2]]]),
      },
    ];
  }

  getDateTimeLabelFormats() {
    return {
      millisecond: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M", " - %H:%M"],
      second: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M:%S", " - %H:%M:%S"],
      minute: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M:%S", " - %H:%M:%S"],
      hour: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M", " - %d.%m.%Y %H:%M"],
      day: ["%d.%m.%Y", "%d.%m.%Y %H:%M", " - %d.%m.%Y %H:%M"],
      week: ["%d.%m.%Y", "%d.%m.%Y %H:%M", " - %d.%m.%Y %H:%M"],
      month: ["%d.%m.%Y", "%d.%m.%Y", " - %d.%m.%Y"],
      year: ["%b.%Y", "%b.%Y", "%b.%Y"],
    };
  }
}

type UpdateRequest = {
  source: UpdateRequestType;
  preferences: Graph;
};

type UpdateRequestType = "initial" | "modal" | "dashboard";
