import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomerSelectionComponent } from "./components/customer-selection/customer-selection.component";

const routes: Routes = [{ path: "customer", component: CustomerSelectionComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
