<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.isDesktop; else mobileView">
    <h2 class="pko-component__title">{{ "activation.Title" | translate }}</h2>
    <ng-template *ngTemplateOutlet="customers"></ng-template>
    <ng-container *ngIf="vm.hasActive">
      <h2 class="pko-component__title mt-5">{{ "activation.ActiveApplications" | translate }}</h2>
      <app-active-applications [applications]="vm.activeApps"></app-active-applications>
    </ng-container>
  </ng-container>

  <ng-template #mobileView>
    <ul ngbNav #nav="ngbNav" class="pko-tabs nav-tabs" role="tablist" activeId="customers">
      <li class="pko-tabs__item" role="presentation" ngbNavItem="customers">
        <a class="pko-tabs__link" role="tab" ngbNavLink>{{ "activation.Title" | translate }}</a>
        <ng-template ngbNavContent>
          <ng-template *ngTemplateOutlet="customers"></ng-template>
        </ng-template>
      </li>
      <li class="pko-tabs__item" role="presentation" ngbNavItem="applications" *ngIf="vm.hasActive">
        <a class="pko-tabs__link" role="tab" ngbNavLink>
          {{ "activation.ActiveApplications" | translate }}
        </a>
        <ng-template ngbNavContent>
          <app-active-applications [applications]="vm.activeApps"></app-active-applications>
        </ng-template>
      </li>
    </ul>
    <div class="pko-tabs__panel" role="tabpanel" [ngbNavOutlet]="nav"></div>
  </ng-template>

  <ng-template #customers>
    <div class="pko-customer" [class.pko-customer--collapsed]="vm.hasActive">
      <app-input-search
        placeholder="customer.SearchCustomer"
        [formControl]="search"
        class="sticky-top"
        ngDefaultControl>
      </app-input-search>

      <app-alert *ngIf="!vm.hasPinned && !vm.hasUnpinned" content="missingData.NoSearchResults">
      </app-alert>

      <app-customer-list
        *ngIf="vm.hasPinned"
        header="customer.Pinned"
        [customers]="vm.pinned"
        [isDesktop]="vm.isDesktop">
      </app-customer-list>

      <app-customer-list
        *ngIf="vm.hasUnpinned"
        header="customer.Unpinned"
        [customers]="vm.unpinned"
        [isDesktop]="vm.isDesktop">
      </app-customer-list>
    </div>
  </ng-template>
</ng-container>
