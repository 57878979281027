import { Component, Input } from "@angular/core";
import {
  FlowDeclaration,
  FlowDeclarationVM,
} from "@features/flow-declaration/flow-declaration.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";

@Component({
  selector: "app-flow-declaration-application-modal",
  templateUrl: "./flow-declaration-application-modal.component.html",
})
export class FlowDeclarationApplicationModalComponent {
  @Input() flowsDeclaration!: FlowDeclarationVM;
  @Input() declaration!: FlowDeclaration;

  modalConfig: Modal = {
    title: "flowDeclaration.Title",
    titleTooltip: "flowDeclaration.Tooltip",
    hideCloseButton: true,
  };

  constructor(private activeModal: NgbActiveModal) {}

  onSaved(declaration: FlowDeclaration) {
    this.activeModal.close(declaration);
  }

  onCanceled() {
    this.activeModal.close();
  }
}
