import { NgModule } from "@angular/core";
import { DepositCommonModule } from "@features/deposit-common/deposit-common.module";
import { ModalModule } from "@features/modal/modal.module";
import { TransactionModule } from "@features/transaction/transaction.module";
import { InvestmentDepositDecisionComponent } from "./components/decision.component";
import { InterestRateInfoComponent } from "./components/interest-rate-info/interest-rate-info.component";
import { InvestmentDepositComponentTile } from "./components/investment-deposit-tile.component";
import { InvestmentDepositComponent } from "./components/investment-deposit.component";
import {
  MaxInvDepoRate,
  MinInvDepoRate,
  StepInvDepoRate,
} from "./components/pipes/inv-depo-rate.pipe";
import { SelectDeviationComponent } from "./components/select-deviation/select-deviation.component";
import { SliderComponent } from "./components/silder/slider.component";
import { LeftSliderPipe, RightSliderPipe } from "./components/silder/slider.pipes";
import { InvestmentDepositSummaryDetailComponent } from "./components/summary-detail/summary-detail.component";
import { InvestmentDepositSummaryQuoteComponent } from "./components/summary-quote/summary-quote.component";
import { InvestmentDepositWaitingComponent } from "./components/waiting/investment-deposit-waiting.component";
import { InvestmentDepostiRoutingModule } from "./investment-deposit-routing.module";

@NgModule({
  imports: [TransactionModule, DepositCommonModule, InvestmentDepostiRoutingModule, ModalModule],
  declarations: [
    InvestmentDepositComponent,
    InvestmentDepositComponentTile,
    InvestmentDepositDecisionComponent,
    SliderComponent,
    SelectDeviationComponent,
    LeftSliderPipe,
    RightSliderPipe,
    MinInvDepoRate,
    MaxInvDepoRate,
    StepInvDepoRate,
    InvestmentDepositSummaryDetailComponent,
    InvestmentDepositSummaryQuoteComponent,
    InterestRateInfoComponent,
    InvestmentDepositWaitingComponent,
  ],
  exports: [InvestmentDepositComponent, InvestmentDepositComponentTile],
})
export class InvestmentDepositModule {}
