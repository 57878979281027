import { NgModule } from "@angular/core";
import { AccountsModule } from "@features/accounts/accounts.module";
import { SharedModule } from "@shared/shared.module";
import { CollateralFormComponent } from "./form-collateral/collateral.component";
import { SummaryCollateralComponent } from "./summary-collateral/summary-collateral.component";

@NgModule({
  imports: [SharedModule, AccountsModule],
  declarations: [CollateralFormComponent, SummaryCollateralComponent],
  exports: [CollateralFormComponent, SummaryCollateralComponent],
})
export class CollateralModule {}
