import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";
import { Pagination } from "./pagination.model";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
})
export class PaginationComponent {
  @Input() pageCount!: number;
  @Input() size = 2;
  @Output() current = new EventEmitter<number>();
  #page = new BehaviorSubject<number>(1);
  page$ = this.#page.pipe(map((x) => this.buildModel(x)));

  next() {
    this.goTo(this.#page.value + 1);
  }

  previous() {
    this.goTo(this.#page.value - 1);
  }

  first() {
    this.goTo(1);
  }

  last() {
    this.goTo(this.pageCount);
  }

  goTo(page: number) {
    const current = this.#page.value;
    if (current === page || current < 1 || current > this.pageCount) return;
    this.#page.next(page);
    this.current.emit(page);
  }

  buildModel(page: number) {
    return {
      current: page,
      hasNext: page !== this.pageCount,
      hasPrevious: page !== 1,
      previous: Array.from({ length: this.size }, (_, i) => page - (i + 1))
        .filter((x) => x > 0)
        .sort(),
      next: Array.from({ length: this.size }, (_, i) => page + (i + 1))
        .filter((x) => x <= this.pageCount)
        .sort(),
    } as Pagination;
  }
}
