import { Component, Input } from "@angular/core";
import { FlowDeclaration } from "@features/flow-declaration/flow-declaration.model";

@Component({
  selector: "app-flow-declaration-info",
  templateUrl: "flow-declaration-info.component.html",
})
export class FlowDeclarationInfoComponent {
  @Input() flowDeclaration!: FlowDeclaration;
}
