// #########################
// ## MODYFIKACJA
// #########################

import { Injectable } from "@angular/core";
import { MenuItem } from "@core/components/nav-menu/nav-menu.model";
import { UserService } from "@core/session/user.service";
import { AccountsService } from "@features/accounts";
import { ActivationService } from "@features/activation/activation.service";
import { TreasuryFormService } from "@features/treasury-form/treasury-form.service";
import { propCount } from "@utils/object";
import { Observable, combineLatest, iif, map, of, shareReplay } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProfileModalMenuService {
  contractsAndApplicationsHidden$ = this.activationService.contractsProfileSectionHidden$;
  mifidHidden$ = of(!this.userService.hasRole("FxForward|FxSwap") || !this.userService.isIdg).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  defaultAccountsHidden$ = iif(
    () => !this.userService.hasRole("DefaultAccounts"),
    of(true),
    this.accountsService.getDefaultAccounts.pipe(map((accounts) => !propCount(accounts)))
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor(
    private userService: UserService,
    private accountsService: AccountsService,
    private activationService: ActivationService,
    private treasuryService: TreasuryFormService
  ) {}

  profileMenu$: Observable<MenuItem> = combineLatest(
    [this.contractsAndApplicationsHidden$, this.mifidHidden$, this.defaultAccountsHidden$],
    (contractsAndApplicationsHidden, mifidHidden, defaultAccountsHidden) =>
      ({
        label: "profile.Menu.Button",
        children: [
          {
            label: "profile.Menu.Data",
            icon: "recipients",
            routerLink: "/profile/data",
          },
          {
            label: "profile.Menu.Settings",
            icon: "settings",
            children: [
              {
                label: "profile.Menu.TransactionControl",
                routerLink: "/profile/transaction-control",
                requiredRoles: "FxSpot",
              },
              {
                label: "profile.Menu.TransactionSettings",
                routerLink: "/profile/transaction",
                requiredRoles: "FxSpot|FxForward|FxSwap",
              },
              {
                label: "profile.Menu.DepositControl",
                routerLink: "/profile/deposit-control",
                requiredRoles:
                  "MmDeposit|PremiumDeposit|InvDeposit,MmDeposit|PremiumDeposit|LiEnabled",
              },
              {
                label: "profile.Menu.DepositSettings",
                routerLink: "/profile/deposit",
                requiredRoles: "MmDeposit",
              },
              {
                label: "profile.Menu.InvestmentDepositSettings",
                routerLink: "/profile/investment-deposit",
                requiredRoles: "InvDeposit,LiEnabled",
              },
              {
                label: "profile.Menu.DefaultAccounts",
                routerLink: "/profile/accounts",
                isHidden: defaultAccountsHidden,
              },
            ],
          },
          {
            label: "profile.Menu.Flows",
            icon: "agency-contract",
            routerLink: "/profile/flows",
            isHidden: !this.userService.userData?.showFlowDeclaration,
          },
          {
            label: "profile.Menu.TreasuryForm",
            icon: "agency-contract",
            routerLink: "/profile/treasury-form",
            isHidden:
              !this.userService.userData?.showTreasuryForm ||
              !this.userService.userData?.hasTreasuryFormProducts,
          },
          {
            label: "profile.Menu.Lei",
            icon: "file-sign",
            routerLink: "/profile/lei",
            requiredRoles: "Lei",
          },
          {
            label: "profile.Menu.Instructions",
            icon: "good-to-know",
            routerLink: "/profile/instructions",
            requiredRoles: "DEMOEnableInstructions", // NOWE --- DEMO-NOWE | ukrywa Instrukcje i regulaminy
          },

          {
            label: "profile.Menu.Contracts",
            icon: "documents",
            isHidden:
              contractsAndApplicationsHidden &&
              mifidHidden &&
              !this.userService.hasRole("HasCustomer,PotentialCustomer|ProductAttorneys"),
            children: [
              {
                label: "profile.Menu.ContractsAndApplications",
                routerLink: "/profile/contracts",
                isHidden: contractsAndApplicationsHidden,
              },
              {
                label: "profile.Menu.Apply",
                routerLink: "/profile/apply",
                requiredRoles: "HasCustomer,PotentialCustomer",
              },
              {
                label: "profile.Menu.Attorneys",
                routerLink: "/profile/attorneys",
                requiredRoles: "HasCustomer,ProductAttorneys",
              },
              {
                label: "profile.Menu.Mifid",
                routerLink: "/profile/mifid",
                isHidden: mifidHidden,
              },
            ],
          },
        ],
      } as MenuItem)
  );
}
