<ng-container *ngIf="asGridRow; else controls">
  <div class="pko-grid__row pko-grid__row--12">
    <ng-container *ngTemplateOutlet="controls"></ng-container>
  </div>
</ng-container>

<ng-template #controls>
  <app-select
    label="order.Type"
    formControlName="type"
    validate="type"
    [items]="types"
    translateText="order.types"
    ngDefaultControl>
  </app-select>
  <ng-container *ngIf="isTrailing$ | async; then trailing; else other"></ng-container>
</ng-template>

<ng-template #trailing>
  <app-input-number
    label="order.Points"
    formControlName="points"
    validate="points"
    ngDefaultControl>
  </app-input-number>
</ng-template>

<ng-template #other>
  <app-form-rate></app-form-rate>
</ng-template>
