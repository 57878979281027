import { Component, HostBinding } from "@angular/core";
import { ShortcutsService } from "@features/dashboard/services/shortcuts.service";

@Component({
  selector: "app-shortcuts",
  templateUrl: "./shortcuts.component.html",
})
export class ShortcutsComponent {
  @HostBinding("class") class = "d-block";

  shortcuts$ = this.service.shortcuts$;

  constructor(private service: ShortcutsService) {}
}
