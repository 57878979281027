import { Component } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";
import { mapToBusy } from "@core/models/observable-action";
import { NavigationService } from "@core/navigation";
import { Tracker } from "@core/user-tracking/tracker.service";
import { DpwFormService } from "@features/dpw/services/dpw-form.service";
import { NewDpw, UserNotificationComponent } from "@features/notifications/notifications.model";
import { datepickerFormat } from "@utils/time";

@Component({
  selector: "app-notifications-new-dpw",
  templateUrl: "./notifications-new-dpw.component.html",
})
export class NotificationsNewDpwComponent extends UserNotificationComponent {
  get dpw(): NewDpw {
    return this.notification as NewDpw;
  }

  constructor(
    private formService: DpwFormService,
    private navigation: NavigationService,
    private tracker: Tracker
  ) {
    super();
  }

  get icon(): string {
    return "dpw";
  }

  get header(): string {
    return "notifications." + this.dpw.name + (this.isPending ? "Pending" : "Active");
  }

  get isPending() {
    return this.dpw.status === "P";
  }

  isBusy$ = this.formService.submission$.pipe(mapToBusy());

  mainAction(): void {
    if (this.isPending) return;

    this.tracker.follow({ process: "dpw", origin: "notification" });

    this.formService.save({
      currency: this.dpw.currency,
      counterCurrency: this.dpw.currencyPair.replace(this.dpw.currency, ""),
      currencyPair: this.dpw.currencyPair,
      side: /in/i.test(this.dpw.dpwType) ? "Sell" : "Buy",
      settlement: { date: datepickerFormat(new Date()), tenor: "TOD" },
      amount: this.dpw.amount,
      product: "FxOn",
      ssrId: this.dpw.dpwNumber,
    });

    this.formService.submit();
  }

  get menu(): ActionItem[] {
    return this.isPending
      ? [
          {
            text: "notifications.GoToDpwList",
            callback: () =>
              this.navigation.navigate(["/transactions/iob/dpw"], {
                state: {
                  origin: "notification",
                },
              }),
          } as ActionItem,
        ]
      : [
          {
            text: "notifications.CheckRate",
            callback: () => this.mainAction(),
          } as ActionItem,
        ];
  }
}
