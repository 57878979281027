<!-- 
// #########################
// ## MODYFIKACJA
// #########################
 -->

<nav class="pko-navbar navbar" *ngIf="(isContracts$ | async) === false">
  <div class="pko-navbar__return">
    <a (click)="backToBank()" tabindex="0">
      <svg icon name="chevron-left" class="icon icon--lg"></svg>
      <span class="text-center">{{ app }}</span>
    </a>
  </div>

  <!-- NOWE -->
  <!-- <a href="/" class="pko-navbar__brand" tabindex="0">
    <img src="assets/images/logo-dealer.svg" alt="iPKO Dealer - Logo" />
  </a> -->
  <!-- NOWE gotowe  -->
  <span (click)="handleBrand()" class="pko-navbar__brand" tabindex="0" style="cursor: pointer">
    <img src="assets/images/logo-dealer.svg" alt="iPKO Dealer - Logo" />
  </span>

  <ng-container *ngIf="hasCustomer && (isCustomer$ | async) === false">
    <ul *ngIf="menu$ | async as menu" class="pko-nav navbar-nav" (clickOutside)="collapseAll(menu)">
      <ng-container *ngFor="let item of menu; trackBy: trackByLabel">
        <ng-container *ifHasRole="item.requiredRoles || 'HasCustomer'">
          <ng-container
            *ngTemplateOutlet="
              item.children ? collapseItem : routerItem;
              context: { $implicit: item, menu: menu }
            "></ng-container>
        </ng-container>
      </ng-container>
      <li
        class="pko-nav__item nav-item"
        [class.active]="router.isActive('/ui-guide', true) && !isExpanded(menu) && !working"
        *ifIsDevMode>
        <a class="pko-nav__link nav-link" [routerLink]="['/ui-guide']" tabindex="0">
          <span class="pko-nav__link-name">UI</span>
        </a>
      </li>
    </ul>
    <app-language class="pko-navbar__language" [translateLabel]="false" tabindex="0"></app-language>
    <app-profile tabindex="0"></app-profile>
  </ng-container>

  <button
    class="btn pko-logout"
    [class.ms-auto]="
      !(hasCustomer && (isCustomer$ | async) === false && (isContracts$ | async) === false)
    "
    tabindex="0"
    (click)="logout()">
    <svg icon name="logout_nav" class="pko-logout__icon icon icon--lg"></svg>
    <span class="pko-logout__title" translate>menu.Logout</span>
  </button>
</nav>

<ng-container *ngIf="hasCustomer && (isCustomer$ | async) === false">
  <ng-container *ngIf="menu$ | async as menu">
    <ng-container *ngFor="let item of menu; trackBy: trackByLabel">
      <ng-container *ifHasRole="item.requiredRoles || 'HasCustomer'">
        <ng-container *ngIf="item.children">
          <ng-container
            *ngTemplateOutlet="
              collapsedContent;
              context: { $implicit: item, menu: menu }
            "></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<nav
  class="pko-navbar pko-navbar--mobile navbar"
  [class.pko-navbar--over]="isMenuModalOpen$ | async"
  *ngIf="hasCustomer && (isContracts$ | async) === false && (isCustomer$ | async) === false">
  <ul class="pko-nav navbar-nav" *ngIf="mobileMenu$ | async as menu">
    <ng-container *ngFor="let item of menu; trackBy: trackByLabel">
      <ng-container *ifHasRole="item.requiredRoles || 'HasCustomer'">
        <ng-container
          *ngTemplateOutlet="
            item.children ? collapseItem : routerItem;
            context: { $implicit: item, menu: menu }
          "></ng-container>
      </ng-container>
    </ng-container>
  </ul>
</nav>

<ng-template #routerItem let-item let-menu="menu">
  <li
    class="pko-nav__item nav-item"
    [class.pko-nav__item--hidden]="item.hideOnMobile || item.hideOverMobile"
    [class.disabled]="item.disabled"
    [attr.aria-disabled]="item.disabled"
    [class.active]="router.isActive(item.routerLink, item.exact) && !isExpanded(menu) && !working"
    (click)="closeMenu()">
    <a
      class="pko-nav__link nav-link"
      [routerLink]="item.routerLink"
      [state]="{ origin: 'menu' }"
      tabindex="0">
      <svg icon [name]="item.icon" class="pko-nav__link-icon icon icon--xl" *ngIf="item.icon"></svg>
      <span class="pko-nav__link-name">{{ item.label | translate }}</span>
    </a>
  </li>
</ng-template>

<ng-template #collapseItem let-item let-menu="menu">
  <li
    class="pko-nav__item pko-nav__item--collapse nav-item"
    [class.pko-nav__item--hidden]="item.hideOnMobile"
    [class.pko-nav__item--more]="item.label === 'menu.More'"
    [class.pko-nav__item--selected]="item.expanded"
    [class.active]="
      item.routerLink && router.isActive(item.routerLink, false) && !isExpanded(menu) && !working
    "
    tabindex="0">
    <a
      class="pko-nav__link nav-link"
      [class.disabled]="item.disabled"
      [attr.aria-disabled]="item.disabled"
      [attr.aria-expanded]="item.expanded"
      (click)="collapseToggle(menu, item)"
      (keydown.enter)="collapseToggle(menu, item)"
      tabindex="0">
      <svg icon [name]="item.icon" class="pko-nav__link-icon icon icon--xl" *ngIf="item.icon"></svg>
      <span class="pko-nav__link-name">{{ item.label | translate }}</span>
    </a>
  </li>
</ng-template>

<ng-template #collapsedContent let-item let-menu="menu">
  <div class="pko-collapse-menu" [ngbCollapse]="!item.expanded || isLaptop">
    <div class="pko-collapse-menu__container container">
      <div class="row">
        <ng-container *ngFor="let child of item.children">
          <div class="col-3" *ifHasRole="child.requiredRoles || 'HasCustomer'">
            <div
              class="pko-collapse-menu__card"
              [class.disabled]="child.disabled"
              [attr.aria-disabled]="child.disabled"
              (click)="collapseAll(menu)"
              (keydown.enter)="
                collapseAll(menu);
                this.router.navigate([child.routerLink], { state: { origin: 'menu' } })
              "
              [routerLink]="child.routerLink"
              [state]="{ origin: 'menu' }"
              [routerLinkActive]="['pko-collapse-menu__card--active']"
              tabindex="0">
              <svg
                icon
                [name]="child.icon"
                class="pko-collapse-menu__card-icon icon"
                *ngIf="child.icon"></svg>
              <div class="pko-collapse-menu__card-container">
                <h3
                  class="pko-collapse-menu__card-title"
                  [innerHTML]="child.label | translate"></h3>
                <p class="pko-collapse-menu__card-description" *ngIf="child.description">
                  {{ child.description | translate }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
