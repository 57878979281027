<app-modal-container
  [formGroup]="form"
  class="pko-modal--chart"
  [closeResult]="getSettings.bind(this)">
  <div class="pko-modal__grid pko-graph">
    <div class="pko-graph__actions g-col-8 g-col-md-10 g-col-lg-8">
      <button class="pko-graph__button pko-graph__button--icon" (click)="export('png')">
        {{ "graphs.DownloadPNG" | translate }}<svg icon name="file-png" class="icon--xl"></svg>
      </button>
      <button class="pko-graph__button pko-graph__button--icon" (click)="export('svg')">
        {{ "graphs.DownloadSVG" | translate }}<svg icon name="file-svg" class="icon--xl"></svg>
      </button>
    </div>
    <app-select
      label="Tenor"
      formControlName="tenor"
      [items]="tenors"
      translateText="tenor"
      class="g-col-8 g-col-sm-4 g-col-md-5 g-col-lg-2 d-none d-sm-block"
      ngDefaultControl></app-select>
    <app-form-currency-pair
      [pairs]="pairs$ | async"
      class="g-col-8 g-col-sm-4 g-col-md-5 g-col-lg-3"></app-form-currency-pair>
    <app-form-side [pointed]="true" class="g-col-8 g-col-md-10 g-col-lg-3"></app-form-side>
    <ng-container *ngIf="graph$ | async as vm">
      <ng-container *ngIf="vm.chartOptions; else empty">
        <highcharts-chart
          [Highcharts]="vm.Highcharts"
          [constructorType]="'stockChart'"
          [options]="vm.chartOptions"
          [(update)]="vm.update"
          [callbackFunction]="chartCallback"
          [oneToOne]="vm.oneToOneFlag"
          [runOutsideAngular]="vm.runOutsideAngularFlag"
          class="d-block g-col-8 g-col-md-10 g-col-lg-8">
        </highcharts-chart>

        <div class="pko-graph__footer d-none d-sm-flex">
          <button
            class="pko-graph__button pko-graph__button--link"
            [class.pko-graph__button--selected]="vm.type === 'linear'"
            (click)="switchType('linear')">
            {{ "graphs.Linear" | translate }}
          </button>
          <hr class="pko-graph__button-separator" />
          <button
            class="pko-graph__button pko-graph__button--link"
            [class.pko-graph__button--selected]="vm.type === 'candle'"
            (click)="switchType('candle')">
            {{ "graphs.Candle" | translate }}
          </button>
        </div>
        <p class="g-col-8 g-col-md-10 g-col-lg-8 d-sm-none">
          {{ "Tenor" | translate }}: <b>{{ "tenor." + tenor | translate }}</b>
        </p>
        <p class="pko-infotext pko-infotext--xs g-col-8 g-col-md-10 g-col-lg-8">
          {{ "graphs.MoreInfoMessage" | translate }}
        </p>
      </ng-container>
    </ng-container>
    <div pko-preloader *ngIf="isBusy$ | async"></div>
  </div>
</app-modal-container>

<ng-template #empty>
  <app-alert content="missingData.Graph"></app-alert>
</ng-template>
