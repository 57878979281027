export const BREAKPOINTS = {
  lg: "(min-width: 1280px)",
  md: "(min-width:1024px) and (max-width:1279.5px)",
  sm: "(min-width:768px) and (max-width:1023.5px)",
  xs: "(max-width:767.5px)",
} as const;

export type Breakpoint = keyof typeof BREAKPOINTS;

export function isBreakpoint(current: Breakpoint | null, selected: Breakpoint | Breakpoint[]) {
  if (!current) return false;
  return Array.isArray(selected) ? selected.includes(current) : selected === current;
}
