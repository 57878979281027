<div class="pko-page__container" [class.pko-page__container--center]="data.type">
  <div class="pko-page__header">
    <svg icon name="{{ data.type }}" class="pko-page__icon" *ngIf="data.type"></svg>
    <h3
      class="pko-page__title"
      [innerHTML]="data.translateTitle ?? true ? (data.title ?? '' | translate) : data.title ?? ''"
      [class.hidden]="!data.title"></h3>
  </div>
  <div class="pko-page__body">
    <p
      *ngFor="let line of lines"
      [innerHtml]="
        data.translateBody ?? true ? (line.key | translate : line.params) : line.key
      "></p>
  </div>
  <div
    class="pko-page__footer"
    *ngIf="data.buttons"
    [buttons]="data.buttons"
    [type]="'page'"
    (clicked)="onClicked()"
    app-modal-footer></div>
</div>
