import { Side } from "@core/models/transaction";
import { Graph } from "@core/preferences/preferences.model";
import { UserService } from "@core/session";
import { TranslateService } from "@ngx-translate/core";
import { ceil, floor } from "@utils/number";
import { propCount } from "@utils/object";
import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";
import HC_offline_exporting from "highcharts/modules/offline-exporting";
import { Subject } from "rxjs";
import { GraphButtonConfig, GraphModel, GraphPoint } from "./graph.model";
import darkTheme from "./themes/graph.dark.theme";
import lightTheme from "./themes/graph.light.theme";
import { graphWatermark } from "./watermark";
export abstract class BaseGraphComponent {
  #isBig: boolean;
  protected chart: any;
  protected Highcharts = Highcharts;
  protected graphRange = 0;
  graphRange$ = new Subject<number>();

  constructor(
    protected translate: TranslateService,
    private userService: UserService,
    isBig: boolean
  ) {
    userService.isDarkMode ? darkTheme(Highcharts) : lightTheme(Highcharts);
    this.#isBig = isBig;
    HC_exporting(Highcharts);
    HC_offline_exporting(Highcharts);
  }

  chartHeight = () => {
    const list = document.getElementsByClassName("pko-indicatives__list")[0] as HTMLElement;
    return list ? list.clientHeight : 400;
  };

  abstract get side(): Side;
  abstract get type(): "linear" | "candle";
  abstract get currencyPair(): string;
  abstract graphButtons(config: GraphButtonConfig[]): any;

  chartCallback = (chart: any) => {
    setTimeout(() => {
      if (!propCount(chart)) return;
      this.chart = chart;
      chart.rangeSelector.buttons
        .map((o: any) => o.element)
        .forEach((x: any, i: number) => {
          const p = x.onclick;
          if (p) {
            x.onclick = (e: any) => {
              this.graphRange = i;
              this.graphRange$.next(i);
              p.call(this, e);
            };
          } else {
            const pt = x.ontouchstart;
            x.ontouchstart = (e: any) => {
              this.graphRange = i;
              this.graphRange$.next(i);
              pt.call(this, e);
            };
          }
        });
      const oldSetExtremes = chart.xAxis[0].setExtremes;
      chart.xAxis[0].setExtremes = function (
        newMin: number,
        newMax: number,
        redraw: boolean,
        animation: boolean,
        eventArguments: any
      ) {
        this.closestPointRange = 300000;
        oldSetExtremes.call(this, newMin, newMax, redraw, animation, eventArguments);
      };
    }, 0);
  };

  getSettings() {
    return {
      period: this.graphRange,
      side: this.side,
      type: this.type,
    } as Graph;
  }

  #getCommonOptions(config: GraphButtonConfig[]): any {
    const xAxis: any = {
      ordinal: false,
      breaks: [
        {
          from: Date.UTC(2020, 0, 11, 0, 0, 30, 0),
          to: Date.UTC(2020, 0, 12, 23, 59, 30, 0),
          repeat: 7 * 24 * 36e5,
          breakSize: 4e5,
        },
      ],
      labels: {
        useHTML: true,
        style: {
          width: "50px",
          whiteSpace: "normal",
        },
        formatter: function () {
          const self = this as any;
          let value = Highcharts.dateFormat(self.dateTimeLabelFormat, self.value);

          if (self.chart.rangeSelector.selected === 0) {
            value = Highcharts.dateFormat("%d/%m/%Y %H:%M", self.value);
          } else {
            value = Highcharts.dateFormat("%d/%m/%Y", self.value);
          }

          const v = value.split(" ");
          if (v.length > 1) {
            return `<div style="text-align: center">${v[0]}<br/>${v[1]}</div>`;
          } else {
            return `<div>${value}</div>`;
          }
        },
        reserveSpace: true,
        allowOverlap: false,
        y: 30,
      },
      tickPositioner: function () {
        const offset = 24 * 60 * 60 * 1000;

        const positions: number[] = [];
        const pd = this.series[0].processedXData.length
          ? this.series[0].processedXData
          : this.series[1].processedXData;
        positions.push(this.min);
        positions.push((pd[0] + pd[pd.length - 1]) / 2);
        positions.push(Math.min(pd[pd.length - 1], this.max));

        if (this.chart.rangeSelector.selected !== 1) {
          return positions;
        }

        const omitsWeekend = function (a: number, b: number) {
          return new Date(positions[a]).getDay() > new Date(positions[b]).getDay();
        };

        if (omitsWeekend(0, 1)) {
          positions[1] = positions[1] + offset;
        }

        if (omitsWeekend(1, 2)) {
          positions[1] = positions[1] - offset;
        }

        return positions;
      },
    };

    const translate = this.translate;
    const exportingEvents: any = {
      load: function () {
        this.renderer.image(graphWatermark, 139, 30, 533, 300).add();
        this.renderer
          .text(translate.instant("graphs.FromiPKO"), 20, 300)
          .css({
            color: "rgb(138,138,138)",
            fontSize: "10px",
          })
          .add();

        this.renderer
          .text(translate.instant("graphs.MoreInfoMessagePrint"), 20, 320)
          .css({
            color: "rgb(138,138,138)",
            fontSize: "10px",
          })
          .add();
      },
    };

    const graphElement = document.getElementsByClassName("pko-graph")[0] as HTMLElement;

    return {
      global: {
        useUTC: true,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: null,
        align: "right",
        x: 0,
        labels: {
          format: "{value:.4f}",
        },
        opposite: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      exporting: {
        enabled: false,
        chartOptions: {
          fallbackToExportServer: false,
          title: {
            text: `${this.currencyPair}/${this.translate.instant("sides." + this.side)}`,
            y: 25,
            marigin: 5,
          },
          chart: {
            marginTop: 50,
            width: 800,
            height: 356,
            spacingBottom: 16,
            events: exportingEvents,
          },
          rangeSelector: {
            inputPosition: {
              x: -5,
              y: 10,
            },
            buttonPosition: {
              x: 50,
              y: 20,
            },
          },
        },
      },
      chart: {
        marginTop: 0,
        marginBottom: 112,
        alignTicks: true,
        zoomType: "",
        pinchType: "",
        panning: false,
        height: this.#isBig ? 400 : this.chartHeight() - 44 > 353 ? this.chartHeight() - 44 : 356,
      },
      xAxis,
      rangeSelector: {
        selected: this.graphRange,
        allButtonsEnabled: true,
        buttonPosition: {
          x: this.#isBig
            ? 0
            : (((graphElement.closest(".accordion-item") as HTMLElement) ?? graphElement)
                .clientWidth -
                290) /
              2,
          y: this.#isBig ? 360 : Math.max(this.chartHeight() - 68, 332),
        },
        buttonTheme: {
          fill: "#F2F2F2",
          stroke: "none",
          "stroke-width": 0,
          padding: 6,
          r: 4,
          style: {
            color: "#003574",
            fontSize: "0.75rem",
            lineHeight: 1,
          },
          states: {
            hover: {
              fill: "#004c9a",
              style: {
                color: "#FFFFFF",
              },
            },
            select: {
              fill: "#003574",
              style: {
                color: "#FFFFFF",
              },
            },
          },
        },
        buttons: this.graphButtons(config),
        buttonSpacing: 14,
        inputStyle: {
          display: "none",
        },
        labelStyle: {
          display: "none",
        },
      },
    };
  }

  protected getLinearChartOptions(config: GraphButtonConfig[], data: GraphPoint[]): any {
    const tooltip: any = {
      borderColor: "#bababa",
      borderRadius: 4,
      borderWidth: 1,
      headerFormat:
        '<span style="display: block; font-size: 11px; color: #8d8d8d; margin-bottom: 4px;">{point.key}</span><table>',
      pointFormatter: function () {
        return (
          '<tr ><td style="padding-right: 20px"><b>' +
          this.series.name +
          '</b></td> <td style="color:' +
          this.color +
          '; text-align: right"><b>' +
          (this.side === "Sell" ? floor(this.y, 4) : ceil(this.y, 4)).toFixed(4) +
          "</b></td></tr>"
        );
      },
      positioner: function (boxWidth: number, boxHeight: number, point: any) {
        const { x, y } = this.getPosition(boxWidth, boxHeight, point);
        return {
          x,
          y: y + boxHeight > this.chart.plotHeight ? y - 50 : y,
        };
      },
      shadow: false,
      useHTML: true,
      footerFormat: "</table>",
      shared: false,
      dateTimeLabelFormats: {
        millisecond: "%d/%m/%Y %H:%M:%S.%L",
        second: "%d/%m/%Y %H:%M:%S",
        minute: "%d/%m/%Y %H:%M",
        hour: "%d/%m/%Y %H:%M",
        day: "%d/%m/%Y",
        week: "%d/%m/%Y",
        month: "%d/%m/%Y",
        year: "%d/%m/%Y",
      },
    };

    const linear = {
      tooltip,
      plotOptions: {
        series: {
          lineWidth: 1,
          marker: {
            symbol: "circle",
          },
        },
      },
    };

    const common = this.#getCommonOptions(config);

    const chartOptions = { ...common, ...linear };

    if (!data || !data.length) return {};

    chartOptions.series = [
      {
        type: "line",
        name: this.translate.instant("sides." + this.side),
        data: data.map((x) => [x.closeTime, this.side === "Sell" ? x.closeBid : x.closeAsk]),
        color: this.#getSeriesColor(),
      },
    ];
    chartOptions.rangeSelector.selected = this.graphRange;
    return {
      Highcharts: Highcharts,
      chartOptions,
      oneToOneFlag: true,
      update: true,
      runOutsideAngularFlag: false,
      type: this.type,
    } as GraphModel;
  }

  protected getCandleChartOptions(config: GraphButtonConfig[], data: GraphPoint[]): any {
    const tooltip: any = {
      useHTML: true,
      headerFormat:
        '<span style="font-size: 13px; color: #8A8A8A; font-family: pkobp, Tahoma, sans-serif">{point.key}</span><br/>',
      pointFormat: `<div class="tooltip-body">
      <span class="candle-tooltip-series tooltip-series">&#9679;</span><!--style="color: {point.color}"-->
      <span class="tooltip-line-value">{series.name}</span><br />

      <div class="tooltip-lines">
        <div class="line">
          <span class="tooltip-line">${this.translate.instant(
            "graphs.Open"
          )}</span>&nbsp;<span class="tooltip-line-value">{point.open}</span>
        </div>
        <div class="line">
          <span class="tooltip-line">${this.translate.instant(
            "graphs.High"
          )}</span>&nbsp;<span class="tooltip-line-value">{point.high}</span>
        </div>
        <div class="line">
          <span class="tooltip-line">${this.translate.instant(
            "graphs.Low"
          )}</span>&nbsp;<span class="tooltip-line-value">{point.low}</span>
        </div>
        <div class="line">
          <span class="tooltip-line">${this.translate.instant(
            "graphs.Close"
          )}</span>&nbsp;<span class="tooltip-line-value">{point.close}</span>
        </div>
      </div>
    </div>`,
      borderColor: "rgb(138,138,138)",
      positioner: function (boxWidth: number, boxHeight: number, point: any) {
        const { x, y } = this.getPosition(boxWidth, boxHeight, point);
        return {
          x,
          y: y + boxHeight > this.chart.plotHeight ? y - 60 : y,
        };
      },
    };
    const candle = {
      tooltip: tooltip,
      plotOptions: {
        candlestick: {
          color: "rgba(0,70,140,0.4)",
          upColor: "rgba(228,32,44,0.4)",
          lineColor: "rgb(0,70,140)",
          upLineColor: "rgb(228,32,44)",
        },
      },
    };

    const common = this.#getCommonOptions(config);

    const chartOptions = { ...common, ...candle };

    if (!data || !data.length) return null;

    chartOptions.series = [
      {
        type: "candlestick",
        //dataGrouping: this.getDataGrouping(),
        name: this.currencyPair,
        data: data.map((x) =>
          this.side === "Sell"
            ? [x.openTime, x.openBid, x.highBid, x.lowBid, x.closeBid]
            : [x.openTime, x.openAsk, x.highAsk, x.lowAsk, x.closeAsk]
        ),
        tooltip: {
          valueDecimals: 4,
        },
        pointWidth: 4,
      },
    ];
    chartOptions.rangeSelector.selected = this.graphRange;
    return {
      Highcharts: Highcharts,
      chartOptions,
      oneToOneFlag: true,
      update: true,
      runOutsideAngularFlag: false,
      type: this.type,
    } as GraphModel;
  }

  #getSeriesColor() {
    const sell = this.side === "Sell";
    return this.userService.isDarkMode
      ? sell
        ? "#006EF5"
        : "#e4172c"
      : sell
      ? "#004c9a"
      : "#e4172c";
  }
}
