import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StatusResponse } from "@features/transaction/models";
import { catchError, EMPTY, Observable, of } from "rxjs";
import { CreateOrderResponse, OrderForm, OrderFormData } from "../models/order-form";

@Injectable({ providedIn: "root" })
export class OrderApiService {
  constructor(private http: HttpClient) {}

  getFormData() {
    return this.http
      .get<Omit<OrderFormData, "currencies">>("order/form")
      .pipe(catchError(() => EMPTY));
  }

  create(form: OrderForm): Observable<CreateOrderResponse> {
    return this.http
      .post<CreateOrderResponse>("order", form)
      .pipe(catchError(() => of({ error: { code: "GENERIC" } })));
  }

  getStatus(token: string): Observable<StatusResponse> {
    const options = { params: { token } };
    return this.http
      .get<StatusResponse>("order/status", options)
      .pipe(catchError(() => of({ status: { code: "GENERIC" } })));
  }
}
