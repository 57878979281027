import { Component, ElementRef, HostBinding } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { AccountsService } from "@features/accounts";
import { CurrencyService } from "@features/currency";
import { TenorService } from "@features/tenor";
import { DepositFormService } from "../../services/deposit-form.service";
import { DepositFormComponent } from "../form-deposit/form-deposit.component";

@Component({
  selector: "app-form-deposit-tile",
  templateUrl: "./form-deposit-tile.component.html",
})
export class FormDepositTileComponent extends DepositFormComponent {
  @HostBinding("class") class = "position-relative";
  isTile = true;

  constructor(
    fb: FormBuilder,
    formService: DepositFormService,
    currencyService: CurrencyService,
    accountsService: AccountsService,
    busyService: BusyService,
    tenorService: TenorService,
    private elem: ElementRef,
    tracker: Tracker
  ) {
    super(fb, formService, currencyService, accountsService, busyService, tenorService, tracker);
  }

  onSubmit() {
    const componentSelector = this.elem.nativeElement.tagName.toLowerCase();
    super.onSubmit(componentSelector);
  }
}
