<ng-container [formGroup]="form">
  <div pko-preloader [transparent]="true" *ngIf="isBusy$ | async"></div>
  <ng-container *ngIf="graph$ | async as vm">
    <app-form-side [pointed]="true"></app-form-side>
    <ng-container *ngIf="vm.chartOptions; else empty">
      <highcharts-chart
        [Highcharts]="vm.Highcharts"
        [constructorType]="'stockChart'"
        [options]="vm.chartOptions"
        [(update)]="vm.update"
        [callbackFunction]="chartCallback"
        [oneToOne]="vm.oneToOneFlag"
        [runOutsideAngular]="vm.runOutsideAngularFlag"
        class="d-block">
      </highcharts-chart>

      <div class="pko-graph__footer" *ngIf="(simple$ | async) === false">
        <button
          class="pko-graph__button pko-graph__button--link"
          [class.pko-graph__button--selected]="vm.type === 'linear'"
          (click)="switchType('linear')">
          {{ "graphs.Linear" | translate }}
        </button>
        <hr class="pko-graph__button-separator" />
        <button
          class="pko-graph__button pko-graph__button--link"
          [class.pko-graph__button--selected]="vm.type === 'candle'"
          (click)="switchType('candle')">
          {{ "graphs.Candle" | translate }}
        </button>

        <button class="pko-graph__button pko-graph__button--icon" (click)="openModal()">
          {{ "graphs.OpenModal" | translate }}
          <svg icon name="zoom" class="icon--lg"></svg>
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #empty>
  <app-alert content="missingData.Graph"></app-alert>
</ng-template>
