<div pko-preloader *ngIf="isBusy$ | async"></div>
<form class="pko-form pko-form--full" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="pko-grid__row">
    <app-form-side></app-form-side>
    <div class="w-100"></div>
    <fx-form-amount-currency-pair></fx-form-amount-currency-pair>
    <div class="w-100"></div>
    <p class="pko-infotext">
      {{ "exchange.info.simple" | translate: { date: today | datePko } }}
    </p>
    <div class="col">
      <button
        type="button"
        class="btn btn-link btn-collapse {{ accountsCollapsed ? 'btn-collapse--collapsed' : '' }}"
        (click)="collapse.toggle()">
        {{ "toggle.accounts." + accountsCollapsed | translate }}
      </button>
    </div>

    <app-form-accounts #collapse="ngbCollapse" [(ngbCollapse)]="accountsCollapsed">
    </app-form-accounts>

    <app-form-collateral formGroupName="collateral" hidden></app-form-collateral>
  </div>
  <app-form-footer [back]="{ hidden: true }"></app-form-footer>
</form>
