<button
  *ngIf="buttons.secondary"
  class="btn btn-secondary{{ type === 'modal' ? '' : ' btn-fixed' }}{{
    buttons.primary
      ? type === 'page'
        ? ' mb-3 mb-sm-0 ms-sm-auto'
        : ' mb-3 mb-sm-0'
      : type !== 'modal'
      ? ' mx-auto'
      : ' ms-sm-auto'
  }}"
  [class.disabled]="secondaryDisabled"
  (click)="click(buttons.secondary)">
  {{ buttons.secondary.text | translate }}
</button>

<div
  *ngIf="buttons.primary as primary"
  class="btn-tooltip-container{{
    type !== 'modal' && !buttons.secondary
      ? ' mx-auto'
      : type === 'page'
      ? ' me-sm-auto'
      : !buttons.secondary
      ? ' mx-sm-auto'
      : ' ms-sm-auto'
  }}"
  [ngbTooltip]="primary.disabledReason ?? '' | translate"
  placement="bottom">
  <button
    *ngIf="primary"
    class="btn btn-primary{{ type === 'modal' ? '' : ' btn-fixed' }}"
    [class.disabled]="primaryDisabled"
    (click)="click(primary)">
    {{ primary.text | translate }}
  </button>
</div>
