import { Component, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { Account, AccountsService } from "@features/accounts";
import { combineLatest, map, Observable, startWith, switchMap } from "rxjs";

@Component({
  selector: "app-form-multifx-accounts",
  templateUrl: "./form-multifx-accounts.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormMultifxAccountsComponent implements OnInit {
  vm$!: Observable<MultiFxAccountView>;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective, private accountsService: AccountsService) {}

  ngOnInit(): void {
    const { currency, counterCurrency } = this.#assertForm();

    const currency$ = currency.valueChanges.pipe(startWith(currency.value));
    const counterCurrency$ = counterCurrency.valueChanges.pipe(startWith(counterCurrency.value));

    const accounts$ = currency$.pipe(switchMap(this.accountsService.getAccounts("exchange")));

    const counterAccounts$ = counterCurrency$.pipe(
      switchMap(this.accountsService.getAccounts("exchange"))
    );

    this.vm$ = combineLatest([currency$, counterCurrency$, accounts$, counterAccounts$]).pipe(
      map(([currency, counterCurrency, accounts, counterAccounts]) => ({
        currency,
        counterCurrency,
        accounts,
        counterAccounts,
      }))
    );
  }

  #assertForm() {
    const { currency, counterCurrency, account, counterAccount } = this.#controls;

    if (!currency || !counterCurrency || !account || !counterAccount) {
      throw new Error(
        "This component needs 'currency', 'counterCurrency', 'account' and 'counterAccount' controls present on the form!"
      );
    }

    return { currency, counterCurrency, account, counterAccount };
  }
}

interface MultiFxAccountView {
  currency: string;
  counterCurrency: string;
  accounts: Account[];
  counterAccounts: Account[];
}
