import { AfterViewInit, Component, HostBinding, Input, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Breakpoint } from "@core/breakpoints";
import { PreferencesService } from "@core/preferences/preferences.service";
import { IndicativeGraph } from "@features/graph/graph.model";
import { filter, Observable, shareReplay, Subject, takeUntil } from "rxjs";
import { IndicativePair, IndicativeRate } from "../../indicatives.model";

@Component({
  selector: "app-indicatives-pro",
  templateUrl: "./indicatives-pro.component.html",
})
export class IndicativesProComponent implements AfterViewInit, OnDestroy {
  @HostBinding("class") class = "pko-indicatives pko-indicatives--pro";

  @Input() pairs$!: Observable<IndicativePair[]>;
  @Input() rates$!: Observable<IndicativeRate[]>;
  @Input() graphs$!: Observable<IndicativeGraph[]>;
  @Input() breakpoint!: Breakpoint;

  form: FormGroup;

  selectedPair$: Observable<string>;

  #destroy$ = new Subject<void>();

  get selectedTenor() {
    return this.preferences.indicatives.tenor;
  }

  constructor(fb: FormBuilder, private preferences: PreferencesService) {
    this.form = fb.group({ selectedPair: "" });
    this.selectedPair$ = this.form.controls.selectedPair.valueChanges.pipe(
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  ngAfterViewInit(): void {
    this.pairs$
      .pipe(
        filter((pairs) => pairs.length > 0),
        filter((pairs) => {
          const selectedPair = this.form.controls.selectedPair.value;
          return !selectedPair || !pairs.map((x) => x.code).includes(selectedPair);
        }),
        takeUntil(this.#destroy$)
      )
      .subscribe((pairs) => this.form.controls.selectedPair.setValue(pairs[0].code));
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
