import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "maxDate",
})
export class MaxDatePipe implements PipeTransform {
  transform(toDate?: string | null, maxDate?: string | null) {
    if (!toDate && !maxDate) return undefined;
    if (!maxDate) return toDate;
    if (!toDate) return maxDate;
    return new Date(toDate) > new Date(maxDate) ? maxDate : toDate;
  }
}
