<app-flow-declaration-summary-inactive
  [declaration]="declaration"
  *ngIf="!declaration.isActual"
  [allowRefuse]="allowRefuse"
  [showActions]="showActions"
  (created)="onUpdated()"
  (refused)="onRefused()"></app-flow-declaration-summary-inactive>
<app-flow-declaration-summary-refusal
  [declaration]="declaration"
  *ngIf="declaration.isActual && declaration.refuseToAnswer"
  [showActions]="showActions"
  (updated)="onUpdated()"></app-flow-declaration-summary-refusal>
<app-flow-declaration-summary-active
  [declaration]="declaration"
  *ngIf="declaration.isActual && !declaration.refuseToAnswer"
  [showActions]="showActions"
  (updated)="onUpdated()"></app-flow-declaration-summary-active>
