<app-definition label="collateral.Type" [value]="'collateral.types.' + collateral.type | translate">
</app-definition>

<ng-container *ngIf="collateral.account as account">
  <app-definition
    label="collateral.Amount"
    value="{{ collateral.amount | balance: account.currency }}"
    *ngIf="collateral.amount">
  </app-definition>

  <app-definition label="accounts.Collateral" [account]="account"></app-definition>
</ng-container>
