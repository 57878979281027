import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountsService } from "@features/accounts";
import { ProductType } from "@features/tenor";
import { Observable, shareReplay } from "rxjs";
import { CollateralType } from "./collateral-model";

@Injectable({
  providedIn: "root",
})
export class CollateralService {
  #typesByProduct = {} as Record<ProductType, Observable<CollateralType[]>>;

  accounts$ = this.accountsService.collateralAccounts$;

  constructor(private http: HttpClient, private accountsService: AccountsService) {}

  getTypes(product: ProductType) {
    const stored = this.#typesByProduct[product];

    if (stored) return stored;

    const types$ = this.http
      .get<CollateralType[]>("/exchange/collateral", { params: { product } })
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.#typesByProduct[product] = types$;

    return types$;
  }
}
