import { NgModule } from "@angular/core";
import { HistoryCommonModule } from "@features/history-common/history-common.module";
import { TelephoneModule } from "@features/telephone/telephone.module";
import { TransactionModule } from "@features/transaction/transaction.module";
import { AlertRoutingModule } from "./alert-routing.module";
import { AlertsPageComponent } from "./components/alerts.component";
import { AlertFormTileComponent } from "./components/form-alert-tile/form-alert-tile.component";
import { AlertFormComponent } from "./components/form-alert/form-alert.component";
import { ListAlertItemComponent } from "./components/list-alert-item/list-alert-item.component";
import { AlertListComponent } from "./components/list-alert/list-alert.component";

@NgModule({
  imports: [TransactionModule, TelephoneModule, HistoryCommonModule, AlertRoutingModule],
  declarations: [
    AlertsPageComponent,
    AlertFormComponent,
    AlertListComponent,
    ListAlertItemComponent,
    AlertFormTileComponent,
  ],
  exports: [AlertFormTileComponent],
  providers: [],
})
export class AlertModule {}
