<app-transaction-decision type="exchange" [loading$]="loading$" [vm$]="vm$" [modal]="isModal">
  <ng-template #summary let-summary let-time="time">
    <app-alert
      *ngIf="warningService.outOfTargetMarket"
      icon="critical"
      content="alert.Content.OutOfTargetMarket"
      context="danger">
    </app-alert>
    <div class="pko-grid__row">
      <div class="col-10 col-lg-4 order-lg-last">
        <app-summary-quote [quote]="summary"></app-summary-quote>
        <app-margin-info [data]="summary" *ngIf="time > 0"></app-margin-info>
      </div>
      <app-summary-detail [detail]="summary" class="col-10 col-lg-6"></app-summary-detail>
    </div>
  </ng-template>
</app-transaction-decision>
