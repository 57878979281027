import { Component, HostBinding, Input } from "@angular/core";
import { FinancialInstrumentGroup } from "../../mifid.model";

@Component({
  selector: "app-mifid-survey-result-financial-group",
  templateUrl: "./mifid-survey-result-financial-group.component.html",
})
export class MifidSurveyResultFinancialGroupComponent {
  @HostBinding("class") class = "d-flex justify-content-between";

  @Input() group!: FinancialInstrumentGroup;
  @Input() index!: number;
}
