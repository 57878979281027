import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { IndicativeGraph } from "@features/graph/graph.model";
import { Observable } from "rxjs";
import { IndicativePair, IndicativeRate, ViewModel } from "../../indicatives.model";

@Component({
  selector: "app-indicatives-simple",
  templateUrl: "./indicatives-simple.component.html",
})
export class IndicativesSimpleComponent {
  @HostBinding("class") class = "pko-indicatives";

  @Input() display!: Pick<ViewModel, "breakpoint" | "isSimple">;

  @Input() pairs$!: Observable<IndicativePair[]>;
  @Input() rates$!: Observable<IndicativeRate[]>;
  @Input() graphs$!: Observable<IndicativeGraph[]>;
  @Output() settingsOpened = new EventEmitter<void>();

  get isNotMobile() {
    return this.display.breakpoint !== "xs";
  }

  openSettings() {
    this.settingsOpened.next();
  }
}
