<ng-container
  *ngIf="groups?.length; else displayAs === 'table' ? tableEmptyTemplate : defaultEmptyTemplate">
  <ng-container *ngTemplateOutlet="flatList ? flatListTemplate : groupListTemplate"></ng-container>
</ng-container>

<ng-template #flatListTemplate>
  <div class="pko-group">
    <ng-container *ngFor="let group of groups">
      <ng-container *ngTemplateOutlet="groupContent; context: { $implicit: group }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #groupListTemplate>
  <div class="pko-group" *ngFor="let group of groups">
    <ng-container *ngTemplateOutlet="groupHeader; context: { $implicit: group }"></ng-container>
    <ng-container *ngTemplateOutlet="groupContent; context: { $implicit: group }"></ng-container>
  </div>
</ng-template>

<ng-template #groupHeader let-group>
  <div class="pko-group__header" *ngIf="group.value.length || group.showEmpty">
    <ng-container *ngIf="groupLabel">
      <span class="fw-light">{{ groupLabel | translate }}</span
      >:
    </ng-container>
    {{
      byDate ? (group.key | date: (groupLabel ? "dd.MM.yyyy" : dateFormat) | titlecase) : group.key
    }}
  </div>
</ng-template>

<ng-template #groupContent let-group>
  <ng-container *ngIf="!group.value.length && group.showEmpty">
    <ng-container *ngTemplateOutlet="emptyGroup"></ng-container>
  </ng-container>

  <ng-template #defaultTemplate let-item>{{ item }}</ng-template>

  <ng-container
    *ngFor="let item of group.value; let i = index"
    [ngTemplateOutlet]="itemTemplate || defaultTemplate"
    [ngTemplateOutletContext]="{ $implicit: item, index: i }">
  </ng-container>

  <ng-content></ng-content>
</ng-template>

<ng-template #emptyGroup> <app-alert [content]="emptyGroupMessage"></app-alert> </ng-template>

<ng-template #tableEmptyTemplate>
  <div class="pko-empty pko-empty--group">
    <p class="pko-empty__message">{{ emptyMessage | translate }}</p>
  </div>
</ng-template>
<ng-template #defaultEmptyTemplate>
  <app-alert [content]="emptyMessage"></app-alert>
</ng-template>
