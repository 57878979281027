<ng-container *ngTemplateOutlet="stack ? stackTemplate : rowTemplate"></ng-container>

<ng-template #stackTemplate>
  <div class="flex-grow-1">
    <p class="mb-1">
      <span class="fw-light">{{ "multifx.types." + multiFxLegSummary.product | translate }}</span>
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "sides." + multiFxLegSummary.side | translate }}:</span>
      {{ multiFxLegSummary.amount | balance: currency.code }}
      {{ "for" | translate }}
      {{ multiFxLegSummary.calculatedAmount | balance: counterCurrency.code }}
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "SettlementDate" | translate }}:</span>
      {{ multiFxLegSummary.settlement.date | datePko }}
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "Rate" | translate }}:</span
      >{{ multiFxLegSummary.rate | rate | nullable }}
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "multifx.CollateralAmount" | translate }}:</span>
      {{
        (multiFxLegSummary.depositCurrency ? multiFxLegSummary.depositAmount : null)
          | balance: multiFxLegSummary.depositCurrency
          | nullable
      }}
    </p>
  </div></ng-template
>

<ng-template #rowTemplate>
  <td pkoTableCell>
    {{ "sides." + multiFxLegSummary.side | translate }}
  </td>
  <td pkoTableCell>{{ multiFxLegSummary.settlement.date | datePko }}</td>
  <td align="end" pkoTableCell>
    {{ multiFxLegSummary.amount | balance: currency.code }}
  </td>
  <td align="end" pkoTableCell>
    {{ multiFxLegSummary.calculatedAmount | balance: counterCurrency.code | nullable }}
  </td>
  <td align="center" pkoTableCell>{{ multiFxLegSummary.rate | rate | nullable }}</td>
  <td align="end" pkoTableCell>
    {{
      (multiFxLegSummary.depositCurrency ? multiFxLegSummary.depositAmount : null)
        | balance: multiFxLegSummary.depositCurrency
        | nullable
    }}
  </td>
  <td align="end" pkoTableCell></td>
</ng-template>
