// #########################
// ## MODYFIKACJA
// #########################

import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BreakpointObserverService } from "@core/breakpoints";
import { CustomerService } from "@core/customer/customer.service";
import { Customer, PinnableCustomer } from "@core/models/customer.model";
import { PreferencesService } from "@core/preferences/preferences.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { ActivationService } from "@features/activation/activation.service";
import { ModalService } from "@features/modal/modal.service";
import { combineLatest, map, shareReplay, startWith, tap } from "rxjs";
import { TreasuryAgreementAuthPendingComponent } from "../auth-pending-modal.component";

@Component({
  selector: "app-customer-selection",
  templateUrl: "./customer-selection.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSelectionComponent implements OnInit {
  readonly search = new FormControl();

  readonly #createCustomersStream$ = combineLatest([
    this.customerService.customers$.pipe(
      tap(trackCustomers(this.tracker)),
      tap(showTreasuryAgreementAuthDialog(this.modal))
    ),
    this.preferencesService.customers$.pipe(map(({ pinned }) => pinned)),
    this.search.valueChanges.pipe(startWith("")),
  ]).pipe(
    map(toFilteredAndGroupedCustomers),
    shareReplay({ refCount: true, bufferSize: 1 }),
    /*
      #####################
      ## ZMIANA
      VVVVVVVVVVVVVVVVVVVVV
      */
    // map(({ pinned, unpinned }) => ({ pinned, unpinned: sortUnpinned(unpinned) }))
    map(({ pinned, unpinned }) => ({ pinned, unpinned }))
    /*
      ^^^^^^^^^^^^^^^^^^^^^
      ## ZMIANA END
      #####################
      */
  );

  readonly vm$ = combineLatest([
    this.#createCustomersStream$,
    this.activationService.activeApplications.all$,
    this.breakpointObserver.isBreakpoint(["md", "lg"]),
  ]).pipe(
    map(([{ pinned, unpinned }, activeApps, isDesktop]) => ({
      pinned,
      unpinned,
      isDesktop,
      activeApps,
      hasActive: Boolean(activeApps?.length),
      hasPinned: Boolean(pinned?.length),
      hasUnpinned: Boolean(unpinned?.length),
    }))
  );

  constructor(
    private customerService: CustomerService,
    private preferencesService: PreferencesService,
    private activationService: ActivationService,
    private breakpointObserver: BreakpointObserverService,
    private tracker: Tracker,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.tracker.report({ action: "customer", category: "login" });
  }
}

const toFilteredAndGroupedCustomers = ([customers, pinned, search]: [
  customers: Customer[],
  pinned: string[],
  search: string
]) =>
  customers
    .filter(customersBySearchTerm(search))
    .map(toPinnableCustomer(pinned))
    .sort((a, b) => pinned.indexOf(a.shortName ?? a.pid) - pinned.indexOf(b.shortName ?? b.pid))
    .reduce(groupCustomers, { pinned: [], unpinned: [] });

const customersBySearchTerm =
  (searchTerm: string) =>
  ({ name, shortName, pid }: Customer) =>
    searchString(searchTerm, name) ||
    searchString(searchTerm, shortName) ||
    searchString(searchTerm, pid);

const searchString = (searchFor: string, searchIn?: string) =>
  searchIn?.toLowerCase().includes(searchFor.toLowerCase()) ?? false;

const toPinnableCustomer =
  (pinned: string[]) =>
  (customer: Customer): PinnableCustomer => ({
    ...customer,
    isPinned: pinned.includes(customer.shortName ?? customer.pid),
  });

const groupCustomers = (acc: GroupedCustomers, curr: PinnableCustomer) => {
  curr.isPinned ? acc.pinned.push(curr) : acc.unpinned.push(curr);
  return acc;
};

const sortUnpinned = (customers: PinnableCustomer[]) =>
  customers.sort((a, b) =>
    a.hasApp === b.hasApp
      ? a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      : a.hasApp > b.hasApp
      ? -1
      : 1
  );

const trackCustomers = (tracker: Tracker) => (customers: Customer[]) => {
  tracker.report({
    category: "Digitalization",
    action: "SelectCustomerPage",
    data: getScreenType(customers),
  });
};

function getScreenType(customers: Customer[]): Record<string, string> {
  const quickApplyCustomer = customers.find((x) => x.canApplyInQuickPath);

  if (quickApplyCustomer) {
    return {
      screen: quickApplyCustomer.hasApp ? "WithOpenAndActivateApp" : "WithActivateApp",
      jdgPid: quickApplyCustomer.pid,
    };
  }

  const noAppCustomers = customers.filter((x) => !x.hasApp);
  if (!noAppCustomers.length) {
    return { screen: "Normal" };
  }

  return {
    screen: customers.length === noAppCustomers.length ? "WithManageOnly" : "WithAppAndManage",
  };
}

const showTreasuryAgreementAuthDialog = (modal: ModalService) => (customers: Customer[]) => {
  const authPending = customers.filter((x) => x.hasPendingTreasuryAgreementAuth);
  if (authPending.length === 0) return;

  modal.modal({
    component: TreasuryAgreementAuthPendingComponent,
    data: authPending,
  });
};

type GroupedCustomers = { pinned: PinnableCustomer[]; unpinned: PinnableCustomer[] };
