// #########################
// ## MODYFIKACJA
// #########################

import { Routes } from "@angular/router";
import { AlertsPageComponent } from "@features/alert/components/alerts.component";
import { AlertFormComponent } from "@features/alert/components/form-alert/form-alert.component";
import { DashboardModule } from "@features/dashboard/dashboard.module";
import { ExchangeModule } from "@features/exchange/exchange.module";
import { PageDialogComponent } from "@features/modal/page-dialog/page-dialog.component";
import { OrderFormComponent } from "@features/order/components/form-order/form-order.component";
import { AppCanActivate } from "@shared/app-guard";
// import { PrezentacjaGuard } from "../PREZENTACJA/prezentacja.guard";

export const routes: Routes = [
  // {                                               // <<---- NOWE
  //   path: "",                                     // <<---- NOWE
  //   // canActivateChild: [],                         // <<---- NOWE
  //   canActivateChild: [PrezentacjaGuard],      // <<---- NOWE
  //   children: [                                   // <<---- NOWE

  {
    path: "transactions",
    data: { title: "menu.transactions.Title" },
    children: [
      {
        path: "exchange",
        loadChildren: () => ExchangeModule,
      },
      {
        path: "iob",
        data: { title: "dpw.Title" },
        loadChildren: () => import("@features/dpw/dpw.module").then((m) => m.DpwModule),
      },
      {
        path: "multifx",
        canActivate: [AppCanActivate],
        data: { expectedRole: "FxForward,MultiFx", title: "pages.MultiFx" },
        loadChildren: () => import("@features/multifx/multifx.module").then((m) => m.MultiFxModule),
      },
      {
        path: "fast-deal",
        data: { title: "fastDeal.Title", expectedRole: "FastDeal" },
        canActivate: [AppCanActivate],
        loadChildren: () =>
          import("@features/fast-deal/fast-deal.module").then((m) => m.FastDealModule),
      },
    ],
  },
  {
    path: "rates",
    data: { title: "menu.rates.Title" },
    children: [
      {
        path: "order",
        canActivate: [AppCanActivate],
        data: { expectedRole: "Orders|ProUser", title: "pages.Order" },
        loadChildren: () => import("@features/order/order.module").then((m) => m.OrderModule),
      },
      {
        path: "alert",
        loadChildren: () => import("@features/alert/alert.module").then((m) => m.AlertModule),
      },
    ],
  },
  {
    path: "order",
    data: { title: "menu.order.Title" },
    children: [
      {
        path: "order",
        component: OrderFormComponent,
        canActivate: [AppCanActivate],
        data: { expectedRole: "Orders|ProUser" },
      },
      {
        path: "alert",
        component: AlertFormComponent,
      },
    ],
  },
  {
    path: "history",
    data: { title: "menu.history.Title" },
    children: [
      {
        path: "exchange",
        data: { title: "pages.History" },
        loadChildren: () =>
          import("@features/history/history.module").then((m) => m.ExchangeHistoryModule),
      },
      {
        path: "order",
        data: { title: "pages.OrderHistory" },
        loadChildren: () =>
          import("@features/order-history/order-history.module").then((m) => m.OrderHistoryModule),
      },
      {
        path: "alert",
        component: AlertsPageComponent,
        data: { title: "pages.Alerts" },
      },
    ],
  },
  {
    path: "deposits",
    data: { title: "menu.deposits.Title" },
    loadChildren: () => import("@features/deposit/deposit.module").then((m) => m.DepositModule),
  },
  {
    path: "investment-deposits",
    data: { title: "menu.investment-deposits.Title" },
    loadChildren: () =>
      import("@features/investment-deposit/investment-deposit.module").then(
        (m) => m.InvestmentDepositModule
      ),
  },
  {
    path: "ui-guide",
    loadChildren: () => import("@features/ui-guide/ui-guide.module").then((m) => m.UiGuideModule),
  },
  { path: "dialog", data: { close: true }, component: PageDialogComponent },
  {
    path: "profile",
    loadChildren: () => import("@features/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "contracts",
    canActivate: [AppCanActivate],
    data: { expectedRole: "PotentialCustomer" },
    loadChildren: () => import("@features/apply/apply.module").then((m) => m.ApplyModule),
  },
  {
    path: "treasury-agreement",
    loadChildren: () =>
      import("@features/treasury-form/treasury-form.module").then((m) => m.TreasuryFormModule),
  },
  {
    path: "",
    loadChildren: () => DashboardModule,
  },

  //   ]
  // }
];
