<ng-container *ngTemplateOutlet="(isMobile$ | async) ? mobile : regular"></ng-container>
<ng-template #mobile>
  <div class="pko-amount-currency-pair__flags input-group">
    <app-select-flag
      formControlName="currency"
      [items]="currencies"
      bindValue="code"
      ngDefaultControl>
    </app-select-flag>
    <div class="input-group__text">
      {{ "for" | translate }}
    </div>
    <app-select-flag
      formControlName="counterCurrency"
      [items]="counterCurrencies$ | async"
      bindValue="code"
      ngDefaultControl>
    </app-select-flag>
  </div>
  <app-form-control
    class="pko-amount-currency-pair__amount"
    label="Amount"
    validate="amount"
    [inputGroup]="true"
    *ngIf="currency$ | async as currency">
    <app-input-number
      formControlName="amount"
      placeholder="placeholders.Amount"
      [decimals]="currency.decimals"
      ngDefaultControl>
    </app-input-number>
    <span class="align-self-center ps-3"> {{ currency.code }}</span>
  </app-form-control>
</ng-template>

<ng-template #regular>
  <app-form-control label="Amount" [inputGroup]="true">
    <app-form-amount-currency
      [hideLabel]="true"
      [currencies]="currencies"></app-form-amount-currency>
    <div class="input-group__text">
      {{ "for" | translate }}
    </div>
    <app-select-flag
      formControlName="counterCurrency"
      [items]="counterCurrencies$ | async"
      bindValue="code"
      ngDefaultControl>
    </app-select-flag>
  </app-form-control>
</ng-template>
