import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "list-container",
  templateUrl: "./list-container.component.html",
})
export class ListContainerComponent {
  @Input() loadingFirstPage$!: Observable<boolean>;
}
