import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Message } from "./messages.model";

@Injectable({ providedIn: "root" })
export class MessagesApiService {
  constructor(private http: HttpClient) {}

  getMessages() {
    return this.http.get<Message[]>("/messages");
  }

  archiveMessage(id: number) {
    return this.http.post<void>("/messages/archive", id);
  }

  acceptMessages(ids: string[]) {
    return this.http.post<void>("/messages/read", ids);
  }
}
