<div class="pko-header d-none d-md-flex">
  <h1 class="pko-title">{{ "pages.Alerts" | translate }}</h1>
  <button class="btn btn-link btn-add" routerLink="/rates/alert">
    {{ "buttons.add.alert" | translate }}
    <svg icon name="plus-0" class="icon icon--2xl border rounded-circle ms-2"></svg>
  </button>
</div>

<app-main-filter
  label="Status"
  optionLabel="alert.statuses"
  toggle="hide"
  [dropdownOptions]="$any(statuses)"
  (dropdownChanged)="onStatusChanged($any($event))">
</app-main-filter>

<app-list-alert [data]="alerts$ | async"></app-list-alert>
