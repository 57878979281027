<div pko-preloader *ngIf="isBusy$ | async"></div>

<ul
  ngbNav
  #nav="ngbNav"
  class="pko-tabs nav-tabs"
  role="tablist"
  [activeId]="formType$ | async"
  (navChange)="onTabChanged($event)">
  <li class="pko-tabs__item" role="presentation" ngbNavItem="spot" *ifHasRole="'FxSpot|ProUser'">
    <a class="pko-tabs__link" role="tab" ngbNavLink>{{ "exchange.tab.spot" | translate }}</a>
    <ng-template ngbNavContent>
      <app-form-exchange-spot [isTile]="isTile"></app-form-exchange-spot>
    </ng-template>
  </li>
  <li
    class="pko-tabs__item"
    role="presentation"
    ngbNavItem="forward"
    *ifHasRole="'FxForward|ProUser'">
    <a class="pko-tabs__link" role="tab" ngbNavLink>{{ "exchange.tab.forward" | translate }}</a>
    <ng-template ngbNavContent>
      <app-form-exchange-forward [isTile]="isTile"></app-form-exchange-forward>
    </ng-template>
  </li>
  <li
    class="pko-tabs__item"
    role="presentation"
    ngbNavItem="swap"
    *ifHasRole="'FxSwap|ProUser,FxForward|ProUser'">
    <a class="pko-tabs__link" role="tab" ngbNavLink>{{ "exchange.tab.swap" | translate }}</a>
    <ng-template ngbNavContent>
      <app-form-exchange-swap [isTile]="isTile"></app-form-exchange-swap>
    </ng-template>
  </li>
</ul>

<div class="pko-tabs__panel" role="tabpanel" [ngbNavOutlet]="nav"></div>
