import { animate, state, style, transition, trigger } from "@angular/animations";

export const deleteNotificationAnimation = trigger("slideOut", [
  transition("* => deleted", [
    style({
      right: 0,
      position: "relative",
      display: "block",
    }),
    animate("300ms ease-in", style({ right: "-100vw" })),
  ]),
  state(
    "deleted",
    style({
      display: "none",
    })
  ),
]);
