<h2 class="pko-component__title">{{ "multifx.Transactions" | translate }}</h2>

<app-grouped-list
  formArrayName="series"
  [groups]="[{ key: '', value: series.controls }]"
  class="d-block d-sm-none"
  emptyMessage="missingData.MultiFx"
  [flatList]="true">
  <ng-template #itemTemplate let-item let-i="index">
    <div
      class="pko-group__item"
      [multiFxLeg]="item.value"
      [errors]="item.errors"
      [currency]="currency$"
      [index]="i"
      [stack]="true"
      *ngIf="!legStates[i].isEditing"
      (deleted)="deleteLeg(i)"
      (edited)="edit(i)"></div>
  </ng-template>
</app-grouped-list>

<table
  formArrayName="series"
  [columns]="columns"
  [items]="series.controls"
  class="d-none d-sm-table"
  emptyMessage="missingData.MultiFx"
  pkoTable>
  <ng-template #itemTemplate let-item let-i="index">
    <tr
      class="pko-table__row align-middle"
      [multiFxLeg]="item.value"
      [currency]="currency$"
      [index]="i"
      *ngIf="!legStates[i].isEditing"
      (deleted)="deleteLeg(i)"
      (edited)="edit(i)"></tr>
    <tr class="pko-table__row-details" *ngIf="legStates[i].isEditing">
      <td class="bg-light position-relative" [attr.colspan]="+columns.length" pkoTableCell>
        <app-form-multifx-new-transaction-leg
          [form]="getSeriesAt(i)"
          [currency]="currency$"
          [currencyPair]="currencyPair$"
          (deleted)="deleteLeg(i)"
          (saved)="save(i)"></app-form-multifx-new-transaction-leg>
        <app-form-multifx-transaction-error
          *ngFor="let error of series.controls[i]?.errors | keyvalue"
          [error]="{ code: error.key, data: error.value }"></app-form-multifx-transaction-error>
      </td>
    </tr>
  </ng-template>
</table>

<ng-container *ngIf="hasError && series?.errors as errors">
  <app-form-error *ngFor="let error of errors | keyvalue" [error]="error"> </app-form-error>
</ng-container>

<button
  class="btn btn-link btn-add btn-add--invert"
  type="button"
  (click)="addNewLeg()"
  [disabled]="disabledAddition$ | async">
  {{ "buttons.add.multifx" | translate }}
  <svg icon name="plus-0" class="icon icon--2xl border rounded-circle ms-2"></svg>
</button>
