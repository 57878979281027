import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { merge } from "rxjs";
import { ExchangeFormService } from "../../services";

@Component({
  selector: "app-form-exchange-tabs",
  templateUrl: "./form-exchange-tabs.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormExchangeTabsComponent {
  @Input() isTile = false;

  constructor(
    private formService: ExchangeFormService,
    private busyService: BusyService,
    activatedRoute: ActivatedRoute
  ) {
    const { retain, form } = window.history.state;
    let { type } = window.history.state;
    type || (type = activatedRoute.snapshot.data.type);
    if (form) {
      formService.change(type || "spot");
      formService.save({ ...this.formService.current, ...form });
    } else if (type) {
      formService.change(type);
    } else {
      formService.reset(retain);
    }
  }

  isBusy$ = merge(
    this.formService.submission$.pipe(mapToBusy()),
    this.busyService.observeOn("/accounts", "/currency", "/exchange")
  );

  formType$ = this.formService.formType$;

  onTabChanged(event: NgbNavChangeEvent) {
    event.preventDefault();
    this.formService.change(event.nextId, "tab");
  }
}
