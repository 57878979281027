import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from "@angular/core";
import { CustomerService } from "@core/customer/customer.service";
import { ActionItem } from "@core/menus/action-item";
import { PinnableCustomer } from "@core/models/customer.model";
import { PreferencesService } from "@core/preferences/preferences.service";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "[pkoCustomerItem]",
  templateUrl: "./customer-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerItemComponent implements OnDestroy {
  #destroy = new Subject<void>();

  @HostBinding("class") class = "pko-customer-list__item";
  @HostListener("click") goTo() {
    const { hasApp, canApply } = this.customer;
    if (hasApp) {
      this.customerService.set(this.customer, ["/"]).pipe(takeUntil(this.#destroy)).subscribe();
    } else if (canApply) {
      this.customerService
        .set(this.customer, ["/contracts/manage"])
        .pipe(takeUntil(this.#destroy))
        .subscribe();
    }
  }
  @Input() set data(value: InputData) {
    this.customer = value.customer;
    this.actions = this.#buildItemActions(value);
  }
  actions!: CustomerActions;
  customer!: PinnableCustomer;
  constructor(private preferences: PreferencesService, private customerService: CustomerService) {}

  #togglePin() {
    const key = this.customer.shortName ?? this.customer.pid;
    const pinned = this.preferences.customers.pinned;
    if (this.customer.isPinned) {
      pinned.splice(pinned.indexOf(key), 1);
    } else {
      pinned.splice(0, 0, key);
    }
    this.preferences.setCustomers({ pinned });
  }

  #buildItemActions({ customer, isDesktop }: InputData): CustomerActions {
    const actions = this.#sharedActions(customer);

    const menuActions: ActionItem[] = [
      {
        text: customer.isPinned ? "customer.Unpin" : "customer.Pin",
        callback: () => this.#togglePin(),
      },
    ];

    if (!isDesktop) {
      menuActions.push(...actions);
    }

    if (customer.canApply) {
      const activate = [
        {
          callback: () =>
            this.customerService
              .set(customer, ["/contracts/manage"])
              .pipe(takeUntil(this.#destroy))
              .subscribe(),
          text: "activation.ActivateButton",
        },
      ];

      menuActions.push(...activate);
    }

    if (!customer.shortName) {
      const getKnumber = [
        {
          callback: () =>
            this.customerService.getShortName(customer).pipe(takeUntil(this.#destroy)).subscribe(),
          text: "activation.CheckKNumber",
        },
      ];

      menuActions.push(...getKnumber);
    }

    return { buttons: isDesktop ? actions : undefined, menuItems: menuActions };
  }

  #sharedActions(customer: PinnableCustomer) {
    const actions: ButtonActionItem[] = [
      {
        show: customer.canApplyInQuickPath,
        callback: () =>
          this.customerService
            .set(customer, ["/contracts/spot"])
            .pipe(takeUntil(this.#destroy))
            .subscribe(),
        text: "activation.ActivateForexButton",
      },
      {
        show: customer.hasApp,
        callback: () =>
          this.customerService.set(customer, ["/"]).pipe(takeUntil(this.#destroy)).subscribe(),
        text: "activation.GoToPKODealerButton",
      },
    ];

    return actions.filter(({ show }) => show);
  }

  ngOnDestroy() {
    this.#destroy.next();
    this.#destroy.complete();
  }
}

type InputData = { customer: PinnableCustomer; isDesktop: boolean };
type ButtonActionItem = ActionItem & { show: boolean };
type CustomerActions = { buttons?: ButtonActionItem[]; menuItems: ActionItem[] };
