import { Component, HostBinding, Input } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";

@Component({
  selector: "app-dashboard-section",
  templateUrl: "./dashboard-section.component.html",
})
export class DashboardSectionComponent {
  @HostBinding("class") class = "pko-dashboard__section";
  @HostBinding("class.pko-dashboard__section--solid") get componentBg() {
    return this.solid;
  }
  @Input() menu?: ActionItem[] | null;
  /** Solid
   * Ads class that adds solid background to component */
  @Input() solid = true;
  @Input() sectionTitle!: string;
}
