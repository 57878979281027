import { NgModule } from "@angular/core";
import { CurrencyModule } from "@features/currency/currency.module";
import { SharedModule } from "@shared/shared.module";
import { TenorDateFormComponent } from "./form-tenor-date/tenor-date.component";

@NgModule({
  imports: [SharedModule, CurrencyModule],
  declarations: [TenorDateFormComponent],
  exports: [TenorDateFormComponent],
})
export class TenorModule {}
