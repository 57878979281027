import { Injectable } from "@angular/core";
import { mapToAction } from "@core/models/observable-action";
import {
  ConfirmationRequest,
  isInitializationSuccess,
  isRateFailure,
  isRateStreamable,
} from "@features/transaction/models";
import { TransactionApiService } from "@features/transaction/services/api.service";
import { pollStatus } from "@features/transaction/utils/form";
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  filter,
  map,
  merge,
  repeat,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs";
import {
  ConfirmationData,
  InitializationRequest,
  InvestmentDepositRateResponse,
  rejectedRate,
} from "../investment-deposit.model";
import { InvestmentDepositResponseService } from "./investment-deposit-response.service";

@Injectable({ providedIn: "root" })
export class InvestmentDepositService {
  #submission = new Subject<InitializationRequest>();
  submission$ = this.#submission.pipe(switchMap(this.initialize.bind(this)));

  #token = new BehaviorSubject("");
  get token() {
    return this.#token.getValue();
  }

  constructor(
    private api: TransactionApiService,
    private responseService: InvestmentDepositResponseService
  ) {}

  submit(form: InitializationRequest) {
    this.#submission.next(form);
  }

  initialize(form: InitializationRequest) {
    const resubmit = (consents: string[]) => this.submit({ ...form, consents });
    return this.api.initialize("investmentdeposit", form).pipe(
      tap((response) => this.#token.next(isInitializationSuccess(response) ? response.token : "")),
      tap((response) =>
        this.responseService.handleInitResponse({
          response,
          resubmit,
        })
      ),
      mapToAction()
    );
  }

  pollRate(token: string, notifier: Observable<unknown>) {
    return merge(
      notifier.pipe(map(() => rejectedRate)),
      this.api.getRate<InvestmentDepositRateResponse>("investmentdeposit", token).pipe(
        repeat({ delay: 1000 }),
        tap((response) => isRateFailure(response) && this.#token.next("")),
        tap((res) => this.responseService.handleRateError(res)),
        filter(isRateStreamable),
        take(1),
        takeUntil(notifier)
      )
    );
  }

  reject(token: string) {
    return this.api.reject("investmentdeposit", token);
  }

  confirm(tokens: ConfirmationRequest, data: ConfirmationData) {
    const handleConfirmationError = () => {
      this.responseService.handleConfirmationError();
      return EMPTY;
    };

    return this.api.confirm("investmentdeposit", tokens).pipe(
      catchError(handleConfirmationError),
      switchMap((token) => this.#pollStatus(token)),
      mapToAction()
    );
  }

  #pollStatus(token: string) {
    return this.api.getStatus("investmentdeposit", token).pipe(
      pollStatus({
        delay: 1000,
        count: 90,
        failResponse: { status: { code: "InvestmentDepositStatusUnknown" } },
      })
    );
  }
}
