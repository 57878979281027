<dl class="pko-definition__list">
  <app-definition
    label="{{ 'multifx.SettlementAccount' | translate: { currency: detail.currency.code } }}"
    [translateLabel]="false"
    [account]="detail.account"
    *ngIf="detail.account">
  </app-definition>
  <app-definition
    label="{{ 'multifx.SettlementAccount' | translate: { currency: detail.counterCurrency.code } }}"
    [translateLabel]="false"
    [account]="detail.counterAccount"
    *ngIf="detail.counterAccount">
  </app-definition>

  <app-summary-collateral
    [collateral]="detail.forwardCollateral"
    *ngIf="showCollateral">
  </app-summary-collateral>
</dl>
