import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers.set("X-Requested-With", "XMLHttpRequest");

    if (!headers.has("Content-Type") && !headers.has("no-json")) {
      headers = headers.set("Content-Type", "application/json");
    }

    return next.handle(req.clone({ headers }));
  }
}
