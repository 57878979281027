import { registerLocaleData } from "@angular/common";
import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { TranslateService } from "@ngx-translate/core";
import { delay, firstValueFrom, skip } from "rxjs";

@Injectable({ providedIn: "root" })
export class LocalizationService {
  constructor(private _translate: TranslateService, private _preferences: PreferencesService) {}

  async setup(): Promise<void> {
    const language = await firstValueFrom(this._preferences.language$);
    const localeModule = await import(
      /* webpackInclude: /\\(pl|en|de|cs|uk|ru|ro|sk)\.mjs$/ */
      /* webpackExports: "default" */
      /* webpackChunkName: "locale" */
      `../../../../node_modules/@angular/common/locales/${language.code}`
    );

    registerLocaleData(localeModule.default);
    this._translate.use(language.code);

    this._preferences.language$.pipe(skip(1), delay(500)).subscribe(() => {
      window.location.reload();
    });
  }
}
