import { Pipe, PipeTransform } from "@angular/core";
import { NumberFormatOptions, formatNumber } from "../utils/format";

@Pipe({ name: "appNumber" })
export class CustomNumberPipe implements PipeTransform {
  transform(value: Value, options: NumberOptions = 2) {
    if (value === null || value === undefined) return null;

    return typeof options === "number"
      ? formatNumber(+value, { style: "decimal", decimals: options })
      : formatNumber(+value, { style: "decimal", ...options });
  }
}

type Value = string | number | null | undefined;
type NumberOptions = number | Pick<NumberFormatOptions, "decimals">;
