import { Component, EventEmitter, HostBinding, Output } from "@angular/core";
import { DialogComponent } from "@features/modal/base-dialog.component";

@Component({
  templateUrl: "tile-message.component.html",
  selector: "app-tile-message",
})
export class TileMessageComponent extends DialogComponent {
  @HostBinding("class") class = "pko-page";
  @Output() clicked = new EventEmitter<any>();

  constructor() {
    super();
  }

  onClicked(value: any) {
    this.clicked.emit(value);
  }
}
