import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthorizationResult, MobileAuthorizationResult } from "@features/auth/auth.model";
import { catchError, map, Observable, of } from "rxjs";
import {
  FinishSurveyRequest,
  GetSurveyResult,
  KidStatus,
  OwnerResult,
  Survey,
  SurveyOperationResult,
  SurveyResult,
  SurveyStatusResult,
} from "./mifid.model";
import { Tracker } from "@core/user-tracking/tracker.service";

@Injectable({ providedIn: "root" })
export class MifidService {
  constructor(private http: HttpClient, private tracker: Tracker) {}

  getStatus() {
    return this.http.get<string>("/mifid/status");
  }

  getApplicationStatus(applicationId: string) {
    return this.http.get<SurveyStatusResult>(`/mifid/application-status/${applicationId}`);
  }

  isOwner(): Observable<OwnerResult> {
    return this.http.get<boolean>("/mifid/isOwner").pipe(
      map(toOwnerResult),
      catchError(() => of({ isOwner: false, isError: true }))
    );
  }

  setKidStatus(status: KidStatus) {
    return this.http.post<void>("/mifid/setKidStatus", status);
  }

  getSurvey() {
    return this.http.get<Survey>("/mifid/survey");
  }

  getApplicationSurvey(applicationId: string) {
    return this.http.get<GetSurveyResult>(`/mifid/application-survey/${applicationId}`);
  }

  createCustomer() {
    return this.http.post<void>("/mifid/customer", null).pipe(
      map(() => toSuccess),
      catchError(() => of({ isError: true }))
    );
  }

  finishSurvey(request: FinishSurveyRequest) {
    this.tracker.report({category: "Digitalization", action: "MifidSurveyFinished", data: {"ApplicationId": request.applicationId?.toString() ?? ""}});
    return this.http
      .post<AuthorizationResult<SurveyResult>>("/mifid/survey", request)
      .pipe(
        catchError(() => of({ result: { success: false } } as AuthorizationResult<SurveyResult>))
      );
  }

  saveSurvey(request: SurveyResult, applicationId: string) {
    return this.http
      .post<SurveyResult>(`/mifid/save/${applicationId}`, request)
      .pipe(catchError(() => of({ success: false } as SurveyResult)));
  }

  rejectSurvey(applicationId: string) {
    return this.http
      .post<SurveyOperationResult>(`/mifid/reject/${applicationId}`, null)
      .pipe(catchError(() => of({ success: false } as SurveyOperationResult)));
  }

  finishMobileSurvey(request: FinishSurveyRequest) {
    this.tracker.report({category: "Digitalization", action: "MifidSurveyFinished", data: {"ApplicationId": request.applicationId.toString()}});
    return this.http
      .post<MobileAuthorizationResult<SurveyResult>>("/mifid/survey-mobile", request)
      .pipe(
        catchError(() =>
          of({ result: { success: false } } as MobileAuthorizationResult<SurveyResult>)
        )
      );
  }
}

const toOwnerResult = (isOwner: boolean) => {
  return {
    isOwner: isOwner,
    isError: false,
  };
};

const toSuccess = { isError: false };
