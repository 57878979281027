import {
  AfterViewInit,
  Component,
  HostBinding,
  QueryList,
  TemplateRef,
  ViewChildren
} from "@angular/core";
import { BreakpointObserverService } from "@core/breakpoints";
import { ResponsiveSimpleTilesSelection } from "@core/preferences/preferences.model";
import { SectionTemplateDirective } from "./section-template.directive";
@Component({
  selector: "app-dashboard-simple",
  templateUrl: "./dashboard-simple.component.html",
})
export class DashboardSimpleComponent implements AfterViewInit {
  @HostBinding("class") class = "pko-dashboard pko-dashboard--simple";
  @ViewChildren(SectionTemplateDirective)
  templates!: QueryList<SectionTemplateDirective>;
  types: Record<string, TemplateRef<unknown>> = {};
  currentBreakpoint$ = this.breakpointObserver.currentBreakpoint$;

  responsiveTiles: ResponsiveSimpleTilesSelection = {
    xs: {
      center: ["shortcuts", "indicatives", "notifications"],
    },
    sm: {
      center: ["shortcuts", "banner", "exchange", "indicatives"],
      left: ["transactions"],
      right: ["notifications"],
    },
    md: {
      center: ["exchange", "indicatives"],
      right: ["shortcuts", "banner", "transactions", "notifications"],
    },
    lg: {
      center: ["exchange", "indicatives"],
      left: ["transactions"],
      right: ["shortcuts", "banner", "notifications"],
    },
  };

  constructor(private breakpointObserver: BreakpointObserverService) {}

  /*
   * This is used to override the default comparator function of keyvalue pipe in angular - https://angular.io/api/common/KeyValuePipe
   * and save map order
   */
  orderAsIs() {
    return 0;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.templates?.forEach((x) => (this.types[x.sectionType] = x.template));
    }, 0);
  }
}
