import {
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnInit,
  Optional,
  TemplateRef,
} from "@angular/core";
import { NavigationService } from "@core/navigation";
import { Tracker } from "@core/user-tracking/tracker.service";
import { DecisionViewModel } from "@features/transaction/models/confirmation";
import { TransactionResponseService } from "@features/transaction/services/response.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EMPTY, Observable, map, startWith } from "rxjs";

@Component({
  selector: "app-transaction-decision",
  templateUrl: "decision.component.html",
})
export class DecisionComponent<TSummary> implements OnInit {
  @HostBinding("class") class = "w-100";
  @Input() type: "exchange" | "deposit" | "order" | "multifx" | "investment-deposit" = "exchange";
  @Input() subtype?: "historicalroll" | "marketroll";
  @Input() loading$!: Observable<boolean>;
  @Input() vm$!: Observable<DecisionViewModel<TSummary>>;
  @Input() modal = false;

  @ContentChild("summary", { static: true, read: TemplateRef })
  summaryTemplate!: TemplateRef<TSummary>;

  @ContentChild("waiting", { static: true, read: TemplateRef })
  waitingTemplate?: TemplateRef<unknown>;

  content$: Observable<boolean> = EMPTY;
  #finished = false;

  constructor(
    navigationService: NavigationService,
    private transaction: TransactionResponseService,
    private tracker: Tracker,
    @Optional() private activeModal: NgbActiveModal
  ) {
    navigationService.setupBack(this.back.bind(this));
  }

  ngOnInit() {
    this.content$ = this.loading$.pipe(
      map((loading) => !loading),
      startWith(false as const)
    );
  }

  back() {
    this.tracker.reportProgress({ action: this.#finished ? "reset" : "reject" });
    if (this.modal) {
      this.activeModal.close();
    } else {
      this.transaction.retry();
    }
  }

  setFinished() {
    this.#finished = true;
  }
}
