<!-- 
  // #########################
// ## MODYFIKACJA
// #########################
 -->

<app-modal-container [config]="modalConfig" class="position-relative pko-application-details">
  <ng-container *ngIf="details$ | async as detailsPanel; else loading">
    <ng-container *ngIf="detailsPanel.applicationDetails as details" [formGroup]="form">
      <div class="pko-modal__grid">
        <app-definition
          label="activation.ApplicationNr"
          value="{{ details.nemoId }}"
          class="g-col-8 g-col-md-4"></app-definition>
        <app-definition
          label="activation.ApplicationStatus"
          value="{{ 'activation.status.' + details.statusGate | translate }}"
          class="g-col-8 g-col-md-4"></app-definition>
        <app-definition
          label="activation.ApplicationType"
          value="{{
            'activation.type.list.' + (details.type | clientType: details.isInstitutional)
              | translate
          }}"
          class="g-col-8"></app-definition>

        <table
          [columns]="columns"
          [items]="details.legalRepresentation"
          class="d-none d-sm-table pko-flow__table g-col-8"
          pkoTable>
          <ng-template #itemTemplate let-item let-i="index">
            <tr class="pko-table__row pko-table__row--sm">
              <td pkoTableCell>
                {{ item.name }}
              </td>
              <td pkoTableCell>
                {{ item.pid }}
              </td>
              <td pkoTableCell>
                {{ "activation.signatureStatus." + item.signatureStatus | translate }}
              </td>
            </tr>
          </ng-template>
        </table>

        <ng-container *ngIf="detailsPanel.isRepresentative">
          <a
            [href]="'/contracts/document/' + detailsPanel.applicationDetails.applicationId"
            target="_blank"
            *ngIf="detailsPanel.applicationDetails.documentId"
            class="d-block text-decoration-none text-sm my-2 g-col-8"
            >{{ "activation.Documents" | translate }}</a
          >
          <a
            (click)="openFlowDeclaration(detailsPanel.flowDeclaration)"
            href=""
            *ngIf="detailsPanel.flowDeclaration && detailsPanel.hasUpdatedDeclaration"
            class="d-block text-decoration-none text-sm my-2 g-col-8"
            >{{ "flowDeclaration.Title" | translate }}</a
          >
          <a
            href="/treasuryagreement/pdf?applicationId={{
              detailsPanel.applicationDetails.applicationId
            }}"
            target="_blank"
            *ngIf="detailsPanel.treasurySurveyId"
            class="d-block text-decoration-none text-sm my-2 g-col-8"
            >{{ "pages.TreasuryForm" | translate }}</a
          >
          <!-- ZMIANA -->
          <!-- <a
            href="{{ r.path }}"
            target="_blank"
            *ngFor="let r of detailsPanel.regulations"
            class="d-block text-decoration-none text-sm my-2 g-col-8"
            >{{ r.name }}</a
          > -->
          <span
            *ngFor="let r of detailsPanel.regulations"
            class="d-block text-decoration-none text-sm my-2 g-col-8"
            style="color: var(--bs-link-color)"
            >{{ r.name }}</span
          >
        </ng-container>

        <app-input-check
          formControlName="readDocuments"
          [label]="
            details.type === 'SPOT1'
              ? 'activation.ReadDeclaration1SPOT'
              : 'activation.ReadDeclaration'
          "
          class="g-col-8"
          *ngIf="application.signVisible"
          ngDefaultControl>
        </app-input-check>
      </div>
    </ng-container>
  </ng-container>
</app-modal-container>

<ng-template #loading>
  <div pko-preloader></div>
</ng-template>
