import { Component, HostBinding, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MarketNews } from "../market-news.model";

@Component({
  selector: "app-market-news-details",
  templateUrl: "market-news-details.component.html",
})
export class MarketNewsDetailsComponent {
  @HostBinding("class") class = "pko-dialog";
  @Input() data!: MarketNews;

  constructor(private activeModal: NgbActiveModal) {}

  close(result?: unknown): void {
    this.activeModal.close(result);
  }
}
