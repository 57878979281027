<h4>{{ "exchange.Original" | translate }}</h4>
<dl class="pko-definition__list">
  <app-definition [label]="'sides.action.' + original.side">
    <app-flag [icon]="original.currency" [label]="original.amount | balance: original.currency">
    </app-flag>
  </app-definition>

  <app-definition label="For">
    <app-flag
      [icon]="original.counterCurrency"
      [label]="original.counterAmount | balance: original.counterCurrency">
    </app-flag>
  </app-definition>

  <app-definition
    label="exchange.OriginalSettlementDate"
    [value]="original.settlementDate | datePko">
  </app-definition>

  <app-definition label="exchange.OriginalRate" [value]="original.rate | rate"></app-definition>

  <button
    type="button"
    class="btn btn-link btn-collapse {{ collapsed ? 'btn-collapse--collapsed' : '' }}"
    (click)="collapse.toggle()">
    {{ "toggle.details." + collapsed | translate }}
  </button>

  <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed">
    <app-summary-accounts [accounts]="original"></app-summary-accounts>

    <app-summary-collateral [collateral]="original.collateral" *ngIf="original.collateral">
    </app-summary-collateral>

    <app-definition label="exchange.Type" [value]="'exchange.type.' + original.product | translate">
    </app-definition>

    <app-definition label="exchange.details.Id" [value]="original.id"></app-definition>
  </div>
</dl>
