<div class="pko-shortcut__icon" (click)="onClick()">
  <svg icon name="{{ icon }}" class="icon-{{ icon }}"></svg>
</div>

<label (click)="onClick()" class="pko-shortcut__label">
  {{ "shortcuts." + item.id | translate }}
</label>

<a *ngIf="item.extraAction as extra" class="pko-shortcut__action" (click)="extra.action()">
  {{ "shortcuts." + extra.name | translate }}
</a>
