import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPairPipe } from "./currency-pair.pipe";

@Pipe({
  name: "rate",
})
export class RatePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private currencyPairPipe: CurrencyPairPipe) {}

  transform(rate: string | number | null | undefined, currencyPair?: string | null) {
    const rateValue = this.decimalPipe.transform(rate, "1.4-5");

    // return null to preserve native behavior
    if (!rateValue) return null;

    const pair = this.currencyPairPipe.transform(currencyPair);

    if (!pair) return rateValue;

    return rateValue + "\xa0" + pair;
  }
}
