<div class="position-relative" *ngTemplateOutlet="modal ? modalWrapper : content"></div>

<ng-template #content>
  <ng-container *ngIf="vm$ | async as vm; else preloader">
    <ng-container *ngIf="content$ | async; else loading">
      <app-dealer-wait
        *ngIf="vm.intervention"
        [type]="$any(type)"
        [modal]="modal"
        (backClicked)="back()"></app-dealer-wait>

      <ng-container *ngIf="vm.decision as decision">
        <h1 *ngIf="!modal" class="pko-title">
          {{ "pages.decision." + (subtype ?? type) | translate }}
        </h1>

        <p *ngIf="!modal && subtype" class="pko-subtitle">
          {{ "pages.decision.subtitle." + subtype | translate }}
        </p>

        <ng-container
          [ngTemplateOutlet]="summaryTemplate"
          [ngTemplateOutletContext]="{ $implicit: decision.summary, time: decision.time }">
        </ng-container>

        <app-form-footer
          [type]="type"
          [countdown]="decision.time"
          (finished)="decision.onTimeout?.(); setFinished()"
          [back]="{ onClick: back.bind(this) }"
          [forward]="{ onClick: decision.forward }"
          [message]="decision.message"
          [forwardValidator]="decision.forwardValidator">
        </app-form-footer>
      </ng-container>
    </ng-container>

    <ng-template #loading
      ><ng-container
        *ngIf="vm.decision as decision"
        [ngTemplateOutlet]="waitingTemplate ?? preloader"
        [ngTemplateOutletContext]="{ $implicit: decision.summary }">
      </ng-container
    ></ng-template>
  </ng-container>
</ng-template>

<ng-template #modalWrapper>
  <app-modal-container [config]="{ title: type + '.Simple' }">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-modal-container>
</ng-template>

<ng-template #preloader><div pko-preloader></div></ng-template>
