import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { AuthorizationResult, MobileAuthorizationResult } from "@features/auth/auth.model";
import { Client } from "@features/digitalization-common/customer-data.model";
import { SurveyOperationResult } from "@features/mifid/mifid.model";
import { catchError, of, shareReplay } from "rxjs";
import {
  Application,
  ApplicationDetailsResult,
  CompetedContractsModel,
  Regulation,
} from "./activation.model";

@Injectable({ providedIn: "root" })
export class ActivationApiService {
  constructor(private _http: HttpClient, private applicationEvents: AppEventsService) {}

  getActiveApplications = () =>
    this._http
      .get<Application[]>("/contracts/active")
      .pipe(catchError(() => of([] as Application[])));

  getClientActiveApplications = this.applicationEvents.observe(
    () =>
      this._http
        .get<Application[]>("/contracts/client-active")
        .pipe(catchError(() => of([])))
        .pipe(shareReplay({ refCount: true, bufferSize: 1 })),
    AppEvent.ApplicationAction
  );

  getContracts(pageNumber: number) {
    return this._http.get<CompetedContractsModel>("/contracts/completed", {
      params: new HttpParams({
        fromObject: { pageSize: 5, pageNumber },
      }),
    });
  }

  getRelatedRegulations = this._http
    .get<Regulation[]>("/contracts/related-regulations")
    .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  getProfileRegulations = this._http
    .get<Regulation[]>("/contracts/profile-regulations")
    .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  getClient = this._http
    .get<Client>("/contracts/client")
    .pipe(shareReplay({ refCount: false, bufferSize: 1 }));

  sign(code: string, applicationId: string) {
    return this._http.post<AuthorizationResult<SurveyOperationResult>>("/contracts/sign", {
      applicationId,
      code,
    });
  }

  startSigning(applicationId: string) {
    return this._http.post<void>(`/contracts/start-signing/${applicationId}`, null);
  }

  mobileSign() {
    return this._http.post<MobileAuthorizationResult<SurveyOperationResult>>(
      "/contracts/sign-mobile",
      null
    );
  }

  cancel(applicationId: string) {
    return this._http.post<boolean>(`/contracts/cancel/${applicationId}`, null);
  }

  getDetails(applicationId: string) {
    return this._http.get<ApplicationDetailsResult>(`/contracts/details/${applicationId}`);
  }
}
