import { Component } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";
import { NavigationService } from "@core/navigation";
import {
  ExpiredAlert,
  UserNotificationComponent,
} from "@features/notifications/notifications.model";

@Component({
  selector: "app-notifications-expired-alert",
  templateUrl: "./notifications-expired-alert.component.html",
})
export class NotificationsExpiredAlertComponent extends UserNotificationComponent {
  get alert(): ExpiredAlert {
    return this.notification as ExpiredAlert;
  }

  constructor(private navigation: NavigationService) {
    super();
  }

  get icon(): string {
    return "alert-out";
  }

  get header(): string {
    return "notifications." + (this.alert.expired ? "ExpiredAlert" : "ExpiringAlert");
  }

  mainAction(): void {
    this.navigation.navigate(["/rates/alert"], {
      replaceUrl: true,
      state: {
        form: {
          amount: this.alert.amount1,
          currency: this.alert.currency1,
          counterCurrency: this.alert.currency2,
          side: this.alert.side,
        },
        origin: "notification",
      },
    });
  }

  get menu(): ActionItem[] {
    return [
      {
        text: "notifications.NewAlert",
        callback: () => this.mainAction(),
      } as ActionItem,
    ];
  }
}
