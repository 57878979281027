<app-form-control
  [label]="label"
  [validate]="controlToValidate"
  [inputGroup]="true"
  class="pko-search">
  <button class="btn pko-search__button" type="button">
    <svg icon name="zoom" class="icon--lg"></svg></button
  ><input
    class="pko-search__input form-control {{ size && 'form-control-' + size }}"
    [placeholder]="placeholder | translate"
    [formControl]="control" />
</app-form-control>
