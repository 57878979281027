import { Directive, ElementRef, HostListener, Self } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";

const SPECIAL_KEYS = [
  "Control",
  "Backspace",
  "Tab",
  "End",
  "Home",
  "ArrowLeft",
  "ArrowRight",
  "Delete",
];

@Directive({
  selector: "[alphanumeric]",
})
export class AlphanumericDirective {
  private regex = /^[a-zA-Z0-9]+$/;

  constructor(@Self() private ngControl: NgControl, private el: ElementRef<HTMLInputElement>) {}

  get control(): AbstractControl | null {
    return this.ngControl.control;
  }

  get element(): HTMLInputElement {
    return this.el.nativeElement;
  }

  @HostListener("drop", ["$event"])
  @HostListener("paste", ["$event"])
  onPaste() {
    this.#runAfter();
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    if (this.#shouldIgnore(event)) return;
    if (!this.#validate(event.key)) event.preventDefault();
  }

  #shouldIgnore(event: KeyboardEvent): boolean {
    return (
      // Allow specified special keys
      SPECIAL_KEYS.includes(event.key) ||
      // Allow copy, paste, cut
      ((event.ctrlKey || event.metaKey) && ["a", "c", "x", "v"].includes(event.key))
    );
  }

  #runAfter() {
    const previous: string = this.control?.value;

    setTimeout(() => {
      const current: string = this.control?.value;

      if (!this.#validate(current)) {
        /*
         * Some Android devices seem to be retaining input history, so that
         * programmatically updated value is pushed to the front of the element value
         * e.g. 12r -> set back to 12 -> press 3 -> `current` is 1212r3
         * e.g. 12r -> set back to 12 -> press Backspace -> `current` is 1212
         * And I can't say how or when it retains these values, because sometimes it gets cleared.
         *
         * So, the easy solution is to just lose focus, which apparently resets the history...
         */
        this.control?.setValue(previous, { emitEvent: false, onlySelf: true });
        this.element.blur();
        this.element.focus();
      }
    });
  }

  #validate(value: string) {
    return this.regex.test(value);
  }
}
