import { Component, HostBinding, Input } from "@angular/core";
import { Router } from "@angular/router";
import { BusyService } from "@core/loading/busy.service";
import { UserService } from "@core/session";
import { ModalService } from "@features/modal/modal.service";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { ContractAvailability } from "./application-select.model";
import { ApplicationSelectService } from "./application-select.service";

@Component({
  selector: "app-application-select",
  templateUrl: "./application-select.component.html",
})
export class ApplicationSelectComponent {
  @HostBinding("class") class = "pko-activation__section position-relative";
  @Input() fromProfile = true;
  status$ = this.service.getApplyStatus();
  infoPhones$ = this.service.getInfoPhones();
  isBusy$ = this.busyService.observeOn("/contracts");

  constructor(
    private service: ApplicationSelectService,
    private busyService: BusyService,
    private modalService: ModalService,
    private router: Router,
    private userService: UserService
  ) {
    if (!this.userService.userData.showMobileAuthNotAvailable) return;

    this.userService.userData.showMobileAuthNotAvailable = false;
    this.modalService.dialog(
      {
        title: "activation.Warning",
        body: [
          { key: "activation.MobileAuthorizationNotAvaialable" },
          { key: "activation.MobileAuthorizationNotAvaialable2" },
        ],
        type: "warning",
        buttons: { primary: defaultPrimary },
      },
      "modal",
      true
    );
  }

  get manageUrl() {
    return this.fromProfile ? "/profile/apply" : "/contracts/manage";
  }

  changeSelected(status: ContractAvailability) {
    if (!status.changeRequiresConsent) {
      this.router.navigate(["/contracts/change"], { state: { manageUrl: this.manageUrl } });
      return;
    }

    const modal: NgbModalRef = this.modalService.dialog(
      {
        body: { key: "ChangeConsentInfo" },
        buttons: {
          primary: {
            text: "applyButtons.Agree",
            onClick: () =>
              this.router.navigate(["/contracts/change"], {
                state: { manageUrl: this.manageUrl },
              }),
          },
          secondary: { text: "applyButtons.DoNotAgree", onClick: () => modal.close() },
        },
      },
      "modal",
      true
    );
  }
}
