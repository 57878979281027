<app-transaction-decision type="multifx" [loading$]="loading$" [vm$]="vm$">
  <ng-template #summary let-summary>
    <app-multidpw-summary-quote
      [quote]="summary"
      class="pko-grid__row"></app-multidpw-summary-quote>
    <div class="pko-grid__row py-5 flex-row-reverse">
      <app-summary-sum [detail]="summary" class="col-10 col-md-4 order-md-last"> </app-summary-sum>
    </div>
  </ng-template>
</app-transaction-decision>
