<div class="pko-modal__container">
  <app-flow-declaration-info [flowDeclaration]="flowDeclaration"></app-flow-declaration-info>

  <div class="pko-modal__footer">
    <button *ngIf="canBePostponed" class="btn btn-secondary mb-3 mb-sm-0" (click)="postpone()">
      {{ "buttons.flowDeclaration.Postpone" | translate }}
    </button>

    <button class="btn btn-secondary mb-3 mb-sm-0" (click)="refuse()">
      {{ "buttons.flowDeclaration.RefuseToAnswer" | translate }}
    </button>

    <button class="btn btn-primary" (click)="new()">
      {{ "buttons.flowDeclaration.New" | translate }}
    </button>
  </div>
</div>
