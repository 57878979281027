import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "pkoPercent" })
export class PkoPercentPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  transform(value: number | string | undefined | null, format = "1.2-2") {
    if (value === undefined || value === null) return "";
    return this.decimalPipe.transform(value, format) + "%";
  }
}
