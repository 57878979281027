import { Injectable } from "@angular/core";
import { MenuItem } from "@core/components/nav-menu/nav-menu.model";
import { MonthlyPricingsService } from "@features/history/services/monthly-pricings.service";
import { ModalService } from "@features/modal/modal.service";
import { ProfileModalMenuService } from "@features/profile/profile-modal-menu.service";
import { Observable, combineLatest, map, shareReplay } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MobileMenuService {
  menu: Observable<MenuItem[]> = this.pricingsService
    .getPricingsConfig()
    .pipe(
      map((config) => !config.pricingsDates.length),
      map((hasPricings) => [
        {
          label: "menu.home.Title",
          icon: "start",
          routerLink: "/",
          exact: true,
        },
        {
          label: "menu.transactions.Title",
          icon: "transactions",
          routerLink: "/transactions",
          requiredRoles: "FxSpot|FxForward|FxSwap|ProUser",
          expanded: false,
          children: [
            {
              label: "menu.transactions.exchange.Title",
              description: "menu.transactions.exchange.Subtitle",
              icon: "new-deal",
              routerLink: "/transactions/exchange",
              requiredRoles: "FxSpot|FxForward|FxSwap|ProUser",
              children: [
                {
                  label: "exchange.tab.spot",
                  routerLink: "/transactions/exchange",
                  requiredRoles: "FxSpot|ProUser",
                },
                {
                  label: "exchange.tab.forward",
                  routerLink: "/transactions/exchange/forward",
                  navState: { type: "forward" },
                  requiredRoles: "FxForward|ProUser",
                },
                {
                  label: "exchange.tab.swap",
                  routerLink: "/transactions/exchange/swap",
                  navState: { type: "swap" },
                  requiredRoles: "FxSwap|ProUser",
                },
              ],
            },
            {
              label: "menu.transactions.dpw.Title",
              description: "menu.transactions.dpw.Subtitle",
              icon: "dpw",
              routerLink: "/transactions/iob",
              requiredRoles: "IobEnabled|DPWEnabled|ProUser,DpwOn|IobEnabled",
              children: [
                {
                  label: "menu.transactions.dpw.Subtitle",
                  routerLink: "/transactions/iob",
                },
              ],
            },
            {
              label: "menu.transactions.multiFx.Title",
              description: "menu.transactions.multiFx.Subtitle",
              icon: "fx-multi",
              routerLink: "/transactions/multifx",
              requiredRoles: "FxForward,MultiFx",
              children: [
                {
                  label: "menu.transactions.multiFx.Subtitle",
                  routerLink: "/transactions/multifx",
                },
              ],
            },
            {
              label: "menu.transactions.fastDeal.Title",
              description: "menu.transactions.fastDeal.Subtitle",
              icon: "fast-deal",
              routerLink: "/transactions/fast-deal",
              hideOnMobile: true,
              requiredRoles: "FastDeal",
              children: [
                {
                  label: "menu.transactions.fastDeal.Subtitle",
                  routerLink: "/transactions/fast-deal",
                },
              ],
            },
          ],
        },
        {
          label: "menu.investment-deposits.Title",
          icon: "my-deposit",
          routerLink: "/investment-deposits",
          expanded: false,
          requiredRoles: "LiEnabled",
          children: [
            {
              label: "menu.investment-deposits.newDeposit.Title",
              icon: "li",
              routerLink: "/investment-deposits/deposit",
              requiredRoles: "InvDeposit|ProUser,LiEnabled",
            },
            {
              label: "menu.investment-deposits.history.Title",
              icon: "li-list",
              requiredRoles: "LiEnabled",
              routerLink: "/investment-deposits/history",
            },
          ],
        },
        {
          label: "menu.rates.Title",
          icon: "new-order",
          routerLink: "/rates",
          expanded: false,
          hideOnMobile: true,
          children: [
            {
              label: "menu.rates.newOrder.Title",
              description: "menu.rates.newOrder.Subtitle",
              icon: "new-order",
              routerLink: "/rates/order",
              requiredRoles: "Orders|ProUser",
            },
            {
              label: "menu.rates.newAlert.Title",
              description: "menu.rates.newAlert.Subtitle",
              icon: "alert-bik",
              routerLink: "/rates/alert",
            },
          ],
        },
        {
          label: "menu.history.Title",
          icon: "transaction-list",
          routerLink: "/history",
          expanded: false,
          hideOnMobile: true,
          children: [
            {
              label: "menu.history.exchange.Title",
              icon: "transaction-list",
              routerLink: "/history/exchange",
              children: [
                {
                  label: "exchange.history.tab.FX",
                  routerLink: "/history/exchange/fx",
                },
                {
                  label: "exchange.history.tab.Unsettled",
                  routerLink: "/history/exchange/unsettled",
                },
                {
                  label: "exchange.history.tab.SettlementsToday",
                  routerLink: "/history/exchange/settlementsToday",
                },
                {
                  label: "exchange.history.tab.OpenBalance",
                  routerLink: "/history/exchange/openBalance",
                },
                {
                  label: "exchange.history.tab.Other",
                  routerLink: "/history/exchange/other",
                },
                {
                  label: "exchange.history.tab.Valuations",
                  routerLink: "/history/exchange/valuations",
                  isHidden: hasPricings,
                },
              ],
            },
            {
              label: "menu.history.orders.Title",
              description: "menu.history.orders.Subtitle",
              icon: "order-list",
              routerLink: "/history/order",
              children: [
                {
                  label: "menu.history.orders.Title",
                  routerLink: "/history/order",
                },
              ],
            },
            {
              label: "menu.history.alerts.Title",
              description: "menu.history.alerts.Subtitle",
              icon: "alert-out",
              routerLink: "/history/alert",
              children: [
                {
                  label: "menu.history.alerts.Title",
                  routerLink: "/history/alert",
                },
              ],
            },
          ],
        },
        {
          label: "menu.deposits.Title",
          icon: "my-deposit",
          routerLink: "/deposits",
          expanded: false,
          hideOnMobile: true,
          children: [
            {
              label: "menu.deposits.premiumDeposit.Title",
              description: "menu.deposits.premiumDeposit.Subtitle",
              icon: "new-deposit-premium",
              routerLink: "/deposits/premium/offers",
              requiredRoles: "PremiumDeposit",
            },
            {
              label: "menu.deposits.newDeposit.Title",
              icon: "new-deposit",
              routerLink: "/deposits/deposit",
              requiredRoles: "MmDeposit",
            },
            {
              label: "menu.deposits.history.Title",
              icon: "my-deposit",
              routerLink: "/deposits/history",
            },
          ],
        },
      ])
    )
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  menuMore = combineLatest([
    this.profileMenu.profileMenu$,
    this.pricingsService.getPricingsConfig().pipe(map((config) => !config.pricingsDates.length)),
  ])
    .pipe(
      map(([profileMenu, hasPricings]) => ({
        label: "menu.More",
        icon: "more",
        exact: true,
        children: [
          {
            label: "menu.history.Title",
            icon: "transaction-list",
            children: [
              {
                label: "exchange.history.tab.FX",
                routerLink: "/history/exchange/fx",
              },
              {
                label: "exchange.history.tab.Unsettled",
                routerLink: "/history/exchange/unsettled",
              },
              {
                label: "exchange.history.tab.SettlementsToday",
                routerLink: "/history/exchange/settlementsToday",
              },
              {
                label: "exchange.history.tab.OpenBalance",
                routerLink: "/history/exchange/openBalance",
              },
              {
                label: "exchange.history.tab.Other",
                routerLink: "/history/exchange/other",
              },
              {
                label: "exchange.history.tab.Valuations",
                routerLink: "/history/exchange/valuations",
                isHidden: hasPricings,
              },
            ],
          },
          {
            label: "menu.rates.Title",
            icon: "new-order",
            children: [
              {
                label: "menu.rates.newOrder.Title",
                routerLink: "/rates/order",
                requiredRoles: "Orders|ProUser",
              },
              {
                label: "menu.rates.newAlert.Title",
                routerLink: "/rates/alert",
              },
            ],
          },
          {
            label: "menu.deposits.Title",
            icon: "my-deposit",
            children: [
              {
                label: "menu.deposits.premiumDeposit.Title",
                routerLink: "/deposits/premium/offers",
                requiredRoles: "PremiumDeposit",
              },
              {
                label: "menu.deposits.newDeposit.Title",
                routerLink: "/deposits/deposit",
                requiredRoles: "MmDeposit",
              },
              {
                label: "menu.deposits.history.Title",
                routerLink: "/deposits/history",
              },
            ],
          },
          {
            label: "menu.profile.Title",
            icon: "recipients",
            children: [
              {
                label: "pages.Profile",
                command: () => {
                  this.modalService.menu(profileMenu);
                },
              },
            ],
          },
        ],
      }))
    )
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor(
    private pricingsService: MonthlyPricingsService,
    private modalService: ModalService,
    private profileMenu: ProfileModalMenuService
  ) {}

  mobileMenu = combineLatest([this.menu, this.menuMore], (mobileMenu, menuMore) =>
    mobileMenu.concat(menuMore)
  );
}
