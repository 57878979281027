import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivate } from "@shared/app-guard";
import { DepositDecisionComponent } from "./components/decision/decision.component";
import { DepositPageComponent } from "./components/deposit.component";

const routes: Routes = [
  {
    path: "deposit",
    children: [
      {
        path: "",
        component: DepositPageComponent,
        canActivate: [AppCanActivate],
        data: { expectedRole: "MmDeposit", title: "pages.Deposit" },
      },
      {
        path: ":token",
        component: DepositDecisionComponent,
        canActivate: [AppCanActivate],
        data: { expectedRole: "MmDeposit", dontClearBack: true },
      },
    ],
  },
  {
    path: "premium",
    loadChildren: () =>
      import("@features/deposit/premium/premium.module").then((m) => m.PremiumDepositModule),
  },
  {
    path: "history",
    loadChildren: () =>
      import("@features/deposit-history/deposit-history-page.module").then(
        (m) => m.DepositHistoryPageModule
      ),
  },
  {
    path: "",
    redirectTo: "deposit",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DepositRoutingModule {}
