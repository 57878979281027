import { Attribute, Component, Input } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { ButtonGroupOption } from "@shared/components/controls";
import { mapSideOptions } from "@shared/utils/side";

@Component({
  selector: "app-form-side",
  template: `
    <app-form-button-group
      [options]="sides"
      [pointed]="pointed"
      formControlName="side"
      ngDefaultControl>
    </app-form-button-group>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SideFormComponent {
  @Input() pointed = false;

  constructor(@Attribute("show") show?: "swap") {
    this.sides = show ? mapSideOptions(show) : mapSideOptions();
  }

  sides: ButtonGroupOption[];
}
