<div class="pko-header">
  <div class="d-block">
    <h1 class="pko-title">
      {{ "dpw.Title" | translate }}
    </h1>
    <p class="pko-subtitle">
      {{ "dpw.ActiveTitle" | translate }}
    </p>
  </div>
</div>
<ng-container *ngIf="form$ | async as form">
  <div [formGroup]="form" class="mb-4">
    <app-dpw-list-section
      [items$]="active$"
      missingData="missingData.DpwActive"
      [columns]="columns"></app-dpw-list-section>
    <div class="text-end">
      <button
        *ngIf="(active$ | async)?.length"
        type="submit"
        class="btn btn-fixed btn-primary pko-form-footer__button-forward"
        (click)="getRate()"
        [disabled]="form.invalid">
        {{ "dpw.GetRate" | translate }}
      </button>
    </div>
  </div>
</ng-container>

<p class="pko-subtitle">
  {{ "dpw.PendingTitle" | translate }}
  <app-tooltip content="dpw.PendingTooltip"></app-tooltip>
</p>

<app-dpw-list-section
  [items$]="pending$"
  missingData="missingData.DpwPending"
  [columns]="columns"></app-dpw-list-section>

<div pko-preloader *ngIf="isBusy$ | async"></div>
