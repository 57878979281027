import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class WelcomeWindowService {
  constructor(private _http: HttpClient) {}

  readWelcome() {
    return this._http.post(`/user/read-welcome`, null);
  }
}
