import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BreakpointObserverService } from "@core/breakpoints";
import { BusyService } from "@core/loading/busy.service";
import { Side } from "@core/models/transaction";
import { map } from "rxjs";

@Component({
  selector: "app-ticking-rate",
  templateUrl: "./ticking-rate.component.html",
})
export class TickingRateComponent {
  @Input() side!: Side;
  @Input() rate?: number | null;
  @Input() currency?: string;
  @Input() lockReason?: string;
  @Input() disabled = false;
  @Input() handleZero = true;
  @Output() clicked = new EventEmitter<Side>();

  busy$ = this.busyService.isBusy$;

  tooltipTrigger$ = this.breakpointObserver.isBreakpoint(["xs", "sm", "md"]).pipe(
    map((small) => {
      if (!this.lockReason) return { trigger: "hover", autoClose: true };
      const userAgent = window.navigator.userAgent;
      const touch = small && !!userAgent.match(/WebKit/i);

      return { trigger: touch ? "click" : "hover", autoClose: touch ? "outside" : true };
    })
  );

  constructor(
    private busyService: BusyService,
    private breakpointObserver: BreakpointObserverService
  ) {}

  onClick() {
    if (!!this.lockReason || this.disabled) return;

    this.clicked.next(this.side);
  }
}
