import { NgModule } from "@angular/core";
import { TelephoneModule } from "@features/telephone/telephone.module";
import { TransactionModule } from "../transaction/transaction.module";
import { OrderDecisionComponent } from "./components/decision/order-decision.component";
import { ExpirationFormComponent } from "./components/form-expiration/expiration.component";
import { OrderFormControlsComponent } from "./components/form-order-controls/form-order-controls.component";
import { PendingConversionComponent } from "./components/form-order-controls/pending-conversion.component";
import { OrderFormTileComponent } from "./components/form-order-tile/form-order-tile.component";
import { OrderFormComponent } from "./components/form-order/form-order.component";
import { OrderTypeRatePointsFormComponent } from "./components/form-type-rate-points/type-rate-points.component";
import { OrderSummaryDetailComponent } from "./components/summary-detail/summary-detail.component";
import { OrderSummaryQuoteComponent } from "./components/summary-quote/summary-quote.component";
import { OrderRoutingModule } from "./order-routing.module";

@NgModule({
  imports: [TransactionModule, TelephoneModule, OrderRoutingModule],
  declarations: [
    OrderDecisionComponent,
    OrderFormComponent,
    OrderSummaryQuoteComponent,
    OrderSummaryDetailComponent,
    OrderTypeRatePointsFormComponent,
    OrderFormControlsComponent,
    ExpirationFormComponent,
    OrderFormTileComponent,
    PendingConversionComponent,
  ],
  exports: [OrderFormTileComponent],
})
export class OrderModule {}
