import { Injectable } from "@angular/core";
import { ModalService } from "@features/modal/modal.service";
import { EMPTY, firstValueFrom, map } from "rxjs";
import { MessageComponent } from "./components/message.component";
import { MessagesApiService } from "./messages-api.service";
import { Message } from "./messages.model";

@Injectable({ providedIn: "root" })
export class MessagesService {
  constructor(private modalService: ModalService, private apiService: MessagesApiService) {}

  open(message: Message) {
    return this.modalService.modal({
      component: MessageComponent,
      data: message,
    });
  }

  async showUnreadMessages() {
    const messages = await firstValueFrom(
      this.apiService.getMessages().pipe(map((messages) => messages.filter((m) => !m.read)))
    );

    const accepted = [];
    for (const message of messages) {
      let repeat = false;
      do {
        repeat = false;
        try {
          const result = await this.modalService.modal({
            component: MessageComponent,
            data: message,
          }).result;

          if (!result) {
            this.acceptMessages(accepted).subscribe({
              complete: () => (window.location.href = "/logout"),
            });
            return;
          }
          accepted.push(message.id);
        } catch {
          //bootstrap rejects promise when changing from mobile to desktop view
          repeat = true;
        }
      } while (repeat);
    }

    this.acceptMessages(accepted).subscribe();
  }

  archiveMessage(id: number) {
    return this.apiService.archiveMessage(id);
  }

  acceptMessages(accepted: string[]) {
    if (!accepted.length) return EMPTY;
    return this.apiService.acceptMessages(accepted);
  }
}
