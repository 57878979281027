<!-- 
// #########################
// ## MODYFIKACJA
// #########################
 -->

<app-flag-defs></app-flag-defs>
<app-icon-defs></app-icon-defs>

<!-- NOWE PRZENIOSIONE MIEJSCE -->
<app-nav-menu [hasCustomer]="userService.hasCustomer"></app-nav-menu>

<!-- NOWE -->
<ng-container *ngIf="this.isDemoReady$ | async; else wait">
  <!-- NOWE PRZENIOSIONE MIEJSCE-->
  <div class="demo-info" style="margin: 0 auto; width: 96%">
    <p style="text-align: center; font-weight: bold; font-size: 1.05rem; margin: 14px 0 4px">
      WERSJA DEMONSTRACYJNA serwisu iPKO dealer.
    </p>
    <p style="text-align: center; font-size: 0.9rem">
      Wprowadzone przez Ciebie dane nie są zapisywane. Prezentowany kurs dotyczy wersji Demo,
      prawdziwe stawki są uzależnione od stawek rynkowych oraz rodzaju transakcji.
    </p>
  </div>

  <app-titlebar></app-titlebar>
  <div class="pko-grid">
    <div class="pko-grid__container">
      <div
        class="pko-grid__content"
        [class.pko-grid__content--full]="isDashboard"
        [class.pko-grid__content--without-nav]="isContracts"
        pseudoRouter>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div pko-preloader *ngIf="isBusy$ | async"></div>
  <footer pkoFooter [class.pko-footer--compact]="!userService.hasCustomer"></footer>

  <!-- NOWE -->
</ng-container>
<ng-template #wait>
  <div
    style="
      z-index: 99999;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    ">
    Proszę czekać, trwa wczytywanie danych.
  </div>
</ng-template>
