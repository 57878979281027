import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Failure } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { ModalService } from "@features/modal/modal.service";
import { SimilarDealsWarningComponent } from "@features/transaction/components/similar-deals-warning/similar-deals-warning.component";
import { isStatusSuccess, StatusResponse } from "@features/transaction/models/confirmation";
import {
  isInitializationSuccess,
  isInitializationWarning,
  isMifidError,
} from "@features/transaction/models/initialization";
import { isRateFailure } from "@features/transaction/models/rate";
import { TransactionResponseService } from "@features/transaction/services/response.service";
import { getWarningDialog } from "@features/transaction/utils/dialog";
import { BodyLine } from "@shared/components/modal/modal-model";
import { formatNumber } from "@shared/utils/format";
import {
  ConfirmationData,
  InitializationResponseWrapper,
  InvestmentDepositConfig,
  InvestmentDepositRateResponse,
} from "../investment-deposit.model";

@Injectable({ providedIn: "root" })
export class InvestmentDepositResponseService {
  constructor(
    private transaction: TransactionResponseService,
    private tracker: Tracker,
    private modal: ModalService,
    private router: Router
  ) {}

  async handeMifidResponse(config: InvestmentDepositConfig) {
    const response = config.mifidValidationResult!;

    if (isMifidError(response)) {
      return config;
    }

    if (isInitializationWarning(response)) {
      const consents = await this.#onWarnings(response.warnings);
      if (!consents) {
        this.router.navigate(["/"]);
        return config;
      }

      return { ...config, mifidValidationResult: undefined };
    }

    return { ...config, mifidValidationResult: undefined };
  }

  async handleInitResponse(wrappedResponse: InitializationResponseWrapper) {
    const { response, resubmit } = wrappedResponse;

    if (isInitializationSuccess(response)) {
      this.transaction.decide("investment-deposit", response.token);
      return;
    }

    if (isInitializationWarning(response)) {
      const consents = await this.#onWarnings(response.warnings);
      if (!consents) return;

      return resubmit(consents);
    }

    this.#onError(response.error);
  }

  handleRateError(rateResponse: InvestmentDepositRateResponse) {
    if (!isRateFailure(rateResponse)) return;

    this.#onError(rateResponse.error);
  }

  handleConfirmationError() {
    this.#onError();
  }

  handleStatusResponse(response: StatusResponse, summary: ConfirmationData) {
    isStatusSuccess(response)
      ? this.#onSuccess(response.transactionId, summary)
      : this.#onError(response.status);
  }

  #onSuccess(
    id: string,
    {
      currency,
      amount: amountValue,
      rates: { interestRate: rateValue },
      maximalRate: maximalRateValue,
    }: ConfirmationData
  ) {
    const amount = formatNumber(+amountValue, { style: "currency", ...currency });
    const rate = formatNumber(+rateValue, { decimals: 2 });
    const maximalRate = formatNumber(+maximalRateValue, { decimals: 2 });

    const bodyLines: BodyLine[] = [
      { key: "deposit.success.Amount", params: { amount } },
      { key: "investment-deposit.success.Rate", params: { rate, maximalRate } },
    ];

    this.tracker.reportProgress({
      action: actions.SUCCESS,
      data: { id },
    });
    this.transaction.success({ source: "investment-deposit", bodyLines, id });
  }

  #onError(error?: Failure) {
    this.tracker.reportProgress({ action: actions.FAILURE });
    this.transaction.error(
      {
        source: "investment-deposit",
        error,
        ...(error?.code === "InvestmentDepositStatusUnknown"
          ? {
              title: "investment-deposit.UnknownStatus",
              type: "warning",
              buttons: {
                primary: {
                  text: "buttons.go.history",
                  onClick: () => this.router.navigate(["/investment-deposits/history"]),
                },
                secondary: { text: "buttons.home", onClick: () => this.router.navigate(["/"]) },
              },
            }
          : {}),
      },
      undefined
    );
  }

  /**
   * Displays warning dialogs in sequence, if user accepts.
   * @param warnings Warnings to display.
   * @returns If all warnings are accepted, returns their codes. Otherwise `undefined`.
   */
  async #onWarnings(warnings: Failure[]) {
    for (const { code, data } of warnings) {
      const dialog = getWarningDialog(code, "deposit", data);

      const accepted = await (this.#specialWarnings[code]
        ? this.modal.modal({
            component: this.#specialWarnings[code],
            data: { ...dialog, transactionType: "investment-deposit" },
          })
        : this.modal.dialog(dialog)
      ).result // a dismissed modal calls Promise.reject()
        .catch(() => false);

      this.tracker.reportProgress({
        action: actions.WARNING,
        data: { code, accepted: accepted ?? false },
      });

      if (!accepted) return;
    }

    return warnings.map(({ code }) => code);
  }

  #specialWarnings: Record<string, any> = {
    HasSimilarDeal: SimilarDealsWarningComponent,
  };
}
