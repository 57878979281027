import { Side } from "@core/models/transaction";
import { IListItem } from "@features/history/models/view360-list";

export const TransactionStatus = ["Settled", "Unsettled"] as const;
export type TransactionStatus = typeof TransactionStatus[number];

/**
 * The name `watch` refers to "rate watch" (obserwacja kursów)
 * What's a better name for alerts + orders?
 */
export const WATCH_STATUSES = ["Active", "Executed", "Cancelled", "Expired"] as const;
export type WatchStatus = typeof WATCH_STATUSES[number];
export const WATCH_STATUS_OPTIONS = [...WATCH_STATUSES, "All"] as const;
export type WatchStatusOption = typeof WATCH_STATUS_OPTIONS[number];

export interface DealListItem {
  currencyPair: string;
  transactionDate: string;
  settlementStatus: TransactionStatus;
  side: Side;
  amount: number;
  currency: string;
  rate: number;
  isDeposit: boolean;
}

export type ItemRole = "row" | "stack";

export interface HistoryItem {
  id: string;
  displayDetails: boolean;
}

export interface GroupedHistoryItem {
  settlementDate: Date;
}

export interface PageData<T extends HistoryItem | GroupedHistoryItem | IListItem> {
  items: T[];
  isLastPage: boolean;
}

export interface View360Details {
  id: string;
  documentId: string;
  hasConfirmation: boolean;
  type: string;
}
