import { Component, Input } from "@angular/core";
import { ExchangeSummary } from "../../../exchange/models";

@Component({
  selector: "app-summary-detail",
  templateUrl: "summary-detail.component.html",
})
export class SummaryDetailComponent {
  @Input() detail!: ExchangeSummary;

  get showCollateral() {
    return this.detail.collateral && this.detail.collateral.type !== "AmountBlock";
  }
}
