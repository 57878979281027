import { Directive, HostBinding, Input, OnInit } from "@angular/core";
import { CellAlign } from "@shared/components/table/table-model";

@Directive({
  selector: "[pkoTableCell]",
})
export class TableCellDirective implements OnInit {
  @Input() align?: CellAlign;
  @Input() group?: boolean;
  @Input() actionMenu?: boolean;
  @Input() separated?: boolean;
  @HostBinding("class") hostClasses = "pko-table__row-cell";
  @HostBinding("scope") scope = "col";

  ngOnInit() {
    this.hostClasses = [
      this.hostClasses,
      this.align && "text-" + this.align,
      this.actionMenu && "pko-table__row-cell--menu",
      this.group && "pko-table__row-cell--group",
      this.separated && "pko-table__row-cell--separated",
    ]
      .filter(Boolean)
      .join(" ");
  }
}
