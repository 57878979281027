import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivate } from "@shared/app-guard";
import { DecisionComponent } from "./components/decision/decision.component";
import { MultiFxComponent } from "./components/multifx.component";

const routes: Routes = [
  {
    path: "",
    component: MultiFxComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "FxForward,MultiFx" },
  },
  {
    path: ":token",
    component: DecisionComponent,
    canActivate: [AppCanActivate],
    data: { expectedRole: "FxForward,MultiFx", dontClearBack: true },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MultiFxRoutingModule {}
