<div class="pko-grid__row pko-grid__row--12">
  <app-select
    formControlName="currencyPair"
    [items]="pairs$ | async"
    bindValue="code"
    bindLabel="code"
    label="CurrencyPair"
    placeholder="placeholders.Select"
    class="pko-currency-pair__select pko-currency-pair__select--pair col-8"
    ngDefaultControl>
    <ng-template ng-label-tmp ng-option-tmp let-item="item">
      <app-currency-pair [code]="item.code"></app-currency-pair>
    </ng-template>
  </app-select>
  <app-select
    formControlName="currency"
    [items]="currencies$ | async"
    bindValue="code"
    bindLabel="code"
    label="multifx.Currency"
    placeholder="placeholders.Select"
    class="pko-currency-pair__select pko-currency-pair__select--currency col-4"
    ngDefaultControl>
  </app-select>
</div>
