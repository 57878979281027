import { Injectable } from "@angular/core";
import { ExchangeService } from "@features/exchange/services/exchange.service";
import { IFormService } from "@features/transaction/models";
import { BehaviorSubject } from "rxjs";
import { DpwForm } from "../dpw.model";

@Injectable({ providedIn: "root" })
export class DpwFormService implements IFormService<DpwForm> {
  private _form = new BehaviorSubject({} as DpwForm);

  submission$ = this.exchangeService.submission$;

  constructor(private exchangeService: ExchangeService) {}

  get current() {
    return this._form.value;
  }

  reset() {
    // unused
  }

  save(form: DpwForm) {
    this._form.next(form);
  }

  submit() {
    this.exchangeService.submit({ ...this.current, variation: "dpw" });
  }
}
