import { Component, EventEmitter, Host, HostBinding, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable, combineLatestWith, map, startWith, tap } from "rxjs";
import { defaultPrefix } from "../telephone-model";
import { TelephoneService } from "../telephone.service";

@Component({
  selector: "app-form-telephone",
  templateUrl: "telephone.component.html",
})
export class TelephoneFormComponent implements OnInit {
  @HostBinding("class") get class() {
    return this.showButton ? "d-sm-flex align-items-sm-start justify-content-sm-between" : "";
  }
  @Input() label = "PhoneNumber";
  @Input() flag = false;
  @Input() showButton = false;
  @Input() prefixLabel = "code";
  @Output() enterPressed = new EventEmitter<void>();

  prefixes$ = this.service.prefixes$;
  digits$!: Observable<number>;

  constructor(@Host() private parent: ControlContainer, private service: TelephoneService) {}

  get group() {
    return this.parent.control as FormGroup;
  }

  ngOnInit() {
    this.#assertForm();
  }

  emitEnter() {
    this.enterPressed.emit();
  }

  #assertForm() {
    // to make the controls required, validator must be set on the group
    const required = !!this.group.validator;

    const number = this.group.controls.number ?? new FormControl(null);
    const updateNumberValidation = updateValidation(number);
    required && number.addValidators(Validators.required);

    const prefix = this.group.controls.prefix ?? new FormControl(defaultPrefix.code);
    required && prefix.setValidators(Validators.required);

    const country = this.group.controls.country ?? new FormControl(defaultPrefix.countryCode);
    required && country.setValidators(Validators.required);

    this.group.controls.number || this.group.addControl("number", number);
    this.group.controls.prefix || this.group.addControl("prefix", prefix);
    this.group.controls.country || this.group.addControl("country", country);

    this.digits$ = country.valueChanges.pipe(
      startWith(country.value),
      combineLatestWith(this.prefixes$),
      map(([countryCode, prefixes]) => prefixes.find((x) => x.countryCode === countryCode)?.code),
      tap((code) => prefix.setValue(code)),
      map((prefix) => (prefix === defaultPrefix.code ? 9 : 15)),
      tap((digits) => updateNumberValidation(digits))
    );

    return { number, prefix };
  }
}

const phonePattern = Validators.pattern(/^\d{3}\s?\d{3}\s?\d{3}$/);
const abroadPhonePattern = (control: AbstractControl) => {
  const phone = control.value?.toString();
  const digitsOnly = phone.replace(/\s/g, "");
  return /\d{5,15}/.test(digitsOnly) ? null : { pattern: true };
};

const updateValidation = (numberControl: AbstractControl) => (digits: number) => {
  if (digits === 9) {
    if (numberControl.value?.length > 9) {
      numberControl.reset();
    }
    numberControl.addValidators(phonePattern);
    numberControl.removeValidators(abroadPhonePattern);
  } else {
    if (numberControl.value?.length > 15) {
      numberControl.reset();
    }
    numberControl.addValidators(abroadPhonePattern);
    numberControl.removeValidators(phonePattern);
  }
};
