import { HttpClient } from "@angular/common/http";
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import cacheBusting from "../../../../i18n-cache-busting.json";

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle({ key }: MissingTranslationHandlerParams): string {
    return `Missing translation for "${key}"`;
  }
}

function createHttpLoaderFactory(prefix: string | undefined) {
  return function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, prefix, `.json?v=${cacheBusting.version}`);
  };
}

export function LocaleIdFactory({ currentLang, defaultLang }: TranslateService): string {
  return currentLang || defaultLang;
}

export const AppTranslateModuleConfig = (prefix?: string) => ({
  //defaultLanguage: "pl",
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: CustomMissingTranslationHandler,
  },
  loader: {
    provide: TranslateLoader,
    useFactory: createHttpLoaderFactory(prefix),
    deps: [HttpClient],
  },
  extend: true,
  isolate: false,
});
