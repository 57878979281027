<div
  class="pko-profile"
  ngbDropdown
  #users="ngbDropdown"
  placement="bottom-start"
  *ngIf="userData"
  (openChange)="openChanged($event)">
  <button
    class="btn pko-profile__button"
    [attr.data-initials]="userData.customerName | initials : 2"
    ngbDropdownToggle></button>
  <div class="pko-profile__menu dropdown-menu dropdown-menu-end" ngbDropdownMenu>
    <div class="pko-profile__list">
      <div class="pko-profile__list-item pko-profile__list-item--current">
        <div class="pko-profile__name">{{ userData.customerName }}</div>
        <div class="pko-profile__code">
          {{ userData.customerShortName }} ({{ "profile.UserData.KNumber" | translate }})
        </div>
        <button class="btn btn-primary btn-sm w-100 mt-3" (click)="openProfile(); users.close()">
          {{ "profile.Menu.Button" | translate }}
        </button>
      </div>
      <ng-container *ngIf="customers$ | async as customers">
        <ng-container *ngFor="let customer of customers">
          <button
            class="btn pko-customer__button pko-profile__list-item"
            [attr.data-initials]="customer.name | initials : 2"
            (click)="set(customer); users.close()">
            <span
              class="pko-customer__name"
              [ngbTooltip]="customer.name"
              triggers="manual"
              #t="ngbTooltip"
              (mouseenter)="showTooltip($event) && t.open()"
              (mouseleave)="t.close()">
              {{ customer.name }}
            </span>
          </button>
        </ng-container>
        <div class="pko-profile__list-item">
          <button class="btn btn-primary btn-sm w-100 mt-2 mb-2" routerLink="/customer">
            {{ "profile.BackToCustomerSelection" | translate }}
          </button>
        </div>
      </ng-container>
    </div>
    <div class="pko-profile__language">
      {{ "menu.ChooseLanguage" | translate }}
      <app-language [translateLabel]="false"></app-language>
    </div>
  </div>
  <span
    class="pko-profile__name"
    [ngbTooltip]="userData.customerName"
    triggers="manual"
    #t="ngbTooltip"
    (mouseenter)="showTooltip($event) && t.open()"
    (mouseleave)="t.close()"
    ngbDropdownToggle>
    {{ userData.customerName }}
  </span>
</div>
