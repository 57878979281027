import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { pick } from "@utils/object";
import { BehaviorSubject } from "rxjs";
import { InvestmentDepositForm } from "../investment-deposit.model";
import { InvestmentDepositService } from "./investment-deposit.service";

@Injectable({ providedIn: "root" })
export class InvestmentDepositFormService {
  #form = new BehaviorSubject(this.#defaultForm);

  submission$ = this.depositService.submission$;

  constructor(
    private preferences: PreferencesService,
    private depositService: InvestmentDepositService
  ) {}

  get current() {
    return this.#form.getValue();
  }

  get #defaultForm() {
    const { tenor, ...preferences } = this.preferences.investmentDeposit;

    return {
      ...preferences,
      end: { tenor },
    } as InvestmentDepositForm;
  }

  reset(retain?: "all" | "some") {
    if (retain === "all") return;

    this.#form.next(this.#defaultForm);
  }

  save(form: InvestmentDepositForm) {
    this.#form.next(form as InvestmentDepositForm);
  }

  submit() {
    this.depositService.submit(this.current);
  }
}

const retainSome = <T extends InvestmentDepositForm>(defaultForm: T, filledForm: T): T => ({
  ...defaultForm,
  ...pick(filledForm, "currency"),
});
