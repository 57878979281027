export type GroupingKey = string | number;
export type GroupingResult<T> = Record<GroupingKey, T[]>;
export type GroupedArrayElement<T> = { key: GroupingKey; value: T[] };
export type GroupedArray<T> = GroupedArrayElement<T>[];

/**
 * Groups an array based on a given property's values.
 * @param keySelector Property to group by.
 */
export function groupBy<T>(keySelector: (value: T) => GroupingKey, items: T[]) {
  const reducer = (accumulator: GroupingResult<T>, item: T) => {
    const groupingKey = keySelector(item);
    return {
      ...accumulator,
      [groupingKey]: [...(accumulator[groupingKey] ?? []), item],
    };
  };

  return items.reduce(reducer, {});
}

export function sortBy<T>(keySelector: (value: T) => GroupingKey, items: T[], descending = true) {
  return items.sort((a, b) => {
    const keyA = keySelector(a),
      keyB = keySelector(b);
    return (descending ? 1 : -1) * (keyA === keyB ? 0 : keyA > keyB ? -1 : 1);
  });
}

/**
 * Compares values of two arrays.
 * @param a First array
 * @param b Second array
 * @returns whether two arrays have the same values or not.
 */
export const areEqual = (a: unknown[], b: unknown[]) =>
  a.length === b.length && a.every((v, i) => v === b[i]);

/**
 * Returns a new array without duplicates.
 * @param array A base array with possible duplicates
 */
export function removeDuplicates<T>(array: T[], equalityFn?: (a: T, b: T) => boolean) {
  if (!array.length) return new Array<T>();
  if (!equalityFn) return [...new Set(array)];

  const ret = [array[0]];

  for (let i = 1; i < array.length; ++i) {
    let unique = true;
    for (let j = i - 1; j >= 0; --j) {
      if (equalityFn(array[i], array[j])) {
        unique = false;
        break;
      }
    }
    if (unique) {
      ret.push(array[i]);
    }
  }

  return ret;
}

/**
 * Merges given arrays removing duplicates.
 * @param arrays Arrays to merge
 * @returns A single array without duplicates.
 */
export function merge<T>(...arrays: T[][]) {
  const arr: T[] = [];
  return removeDuplicates(arr.concat(...arrays));
}

/**
 * Extracts from an array those values that are present in the other array
 * @param source An array to extract from
 * @param template
 * @returns A new array with filtered values from the source.
 * @see Extract - like TS util, but for instances, not types.
 */
export const extract = <T extends U, U>(source: T[], template: U[]) =>
  source.filter((value) => template.includes(value));

export const areSome = (value: unknown) => (values: unknown[]) => values.includes(value);

export function last<T>(values: T[]) {
  return values[values.length - 1];
}

export function distinctBy<T>(key: keyof T, array: T[]) {
  const map = new Map(array.map((obj) => [obj[key], obj]));
  return Array.from(map.values());
}
