import { CommonModule } from "@angular/common";
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { FooterComponent } from "./components/footer/footer.component";
import { LanguageComponent } from "./components/language/language.component";
import { NavMenuComponent } from "./components/nav-menu/nav-menu.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { TitlebarComponent } from "./components/titlebar/titlebar.component";
import { WelcomeWindowComponent } from "./components/welcome-window/welcome-window.component";
import { ConfigStore } from "./config";
import { AppErrorHandler } from "./errors/error-handling.service";
import { AppTranslateModuleConfig, LocaleIdFactory } from "./i18n";
import { LocalizationService } from "./i18n/localization.service";
import { PreferencesService } from "./preferences/preferences.service";
import { UserService } from "./session/user.service";
import { assertLoadedOnce, httpInterceptorProviders, InitialBlockingFactory } from "./setup";

@NgModule({
  declarations: [
    LanguageComponent,
    NavMenuComponent,
    PageNotFoundComponent,
    ProfileComponent,
    TitlebarComponent,
    FooterComponent,
    WelcomeWindowComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TranslateModule.forRoot(AppTranslateModuleConfig()),
  ],
  exports: [NavMenuComponent, TitlebarComponent, FooterComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: InitialBlockingFactory,
      deps: [ConfigStore, PreferencesService, LocalizationService, UserService],
      multi: true,
    },

    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: LocaleIdFactory,
    },

    { provide: ErrorHandler, useClass: AppErrorHandler },

    httpInterceptorProviders,
    Title,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() self: CoreModule) {
    assertLoadedOnce(self, "CoreModule");
  }
}
