<!--
  NOTE: this datepicker from [ng-bootstrap](https://ng-bootstrap.github.io/#/components/datepicker/) is not ideal.
  We need a regular readonly input with a button and a datepicker popup. This is best done with a single input[ngbDatepicker].
  Unfortunately this does not support custom ngbDatepickerContent. This is why the input here is treated separately,
  and has to manually format the display (`pkoDatepickerFormat` directive), instead of using an NgbDateParserFormatter.
 -->
<app-form-control
  [label]="label"
  [validate]="control"
  [errors]="errorOverrides"
  [inputGroup]="true"
  class="pko-datepicker"
  container="body"
  ngbDropdown
  dropdownClass="pko-datepicker"
  #dropdown="ngbDropdown">
  <input
    class="pko-datepicker__input form-control {{ size && 'form-control-' + size }}"
    [formControl]="control"
    [placeholder]="placeholder"
    pkoDatepickerFormat
    readonly
    ngbDropdownAnchor
    ngbDropdownToggle />
  <button
    class="btn pko-datepicker__button"
    type="button"
    ngbDropdownToggle
    [attr.disabled]="control.disabled ? 'true' : null">
    <svg icon name="calendar" class="icon--md"></svg>
  </button>
  <ngb-datepicker
    class="pko-datepicker__calendar"
    [markDisabled]="isDisabled"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [formControl]="control"
    (dateSelect)="dropdown.close(); select($event)"
    ngbDropdownMenu
    #datepicker>
    <ng-template ngbDatepickerContent>
      <div class="pko-datepicker__calendar-header">
        <div class="pko-datepicker__calendar-nav">
          <button class="btn pko-datepicker__calendar-button" type="button" (click)="navigate(-12)">
            <svg icon name="double-chevron-left" class="icon--lg"></svg>
          </button>
          <button class="btn pko-datepicker__calendar-button" type="button" (click)="navigate(-1)">
            <svg icon name="chevron-left" class="icon--lg"></svg>
          </button>
        </div>

        <div class="pko-datepicker__month">
          {{ i18n.getMonthFullName(datepicker.state.months[0].month) }}
          {{ datepicker.state.months[0].year }}
        </div>
        <div class="pko-datepicker__calendar-nav">
          <button class="btn pko-datepicker__calendar-button" type="button" (click)="navigate(1)">
            <svg icon name="chevron-right" class="icon--lg"></svg>
          </button>
          <button class="btn pko-datepicker__calendar-button" type="button" (click)="navigate(12)">
            <svg icon name="double-chevron-right" class="icon--lg"></svg>
          </button>
        </div>
      </div>

      <ngb-datepicker-month
        class="pko-datepicker__calendar-month"
        [month]="datepicker.state.months[0]">
      </ngb-datepicker-month>
    </ng-template>
  </ngb-datepicker>
</app-form-control>
<ng-container *ngIf="this.debugMode">
  <pre class="py-3">Model: {{ control.value | json }}</pre>
  <pre class="py-3">Errors: {{ control.errors | json }}</pre>
  <pre class="py-3">Datepicker: {{ datepicker.state | json }}</pre>
</ng-container>
