export function getCookie(key: string): string {
  const cookie = document.cookie
    .split(";")
    .map((cookie) => cookie.trim().split("="))
    .find(([cookieKey]) => cookieKey === key);

  return (cookie && decodeURIComponent(cookie[1])) || "";
}

export function setCookie(key: string, value: string): void {
  document.cookie = `${key}=${value}; SameSite=Strict; Secure; Path=/`;
}
