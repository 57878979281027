<ng-container *ngIf="contracts$ | async as contractsModel">
  <div class="pko-activation__regulations" *ngIf="contractsModel.pageCount">
    <h2 class="pko-title pko-title--2">{{ "activation.Contracts" | translate }}</h2>
    <div class="pko-activation__contracts-list">
      <ng-container *ngFor="let item of contractsModel.items">
        <app-pdf-document
          [title]="
            'activation.documents.' +
              (item.signDate ? 'signed.' : 'notsigned.') +
              item.type +
              (isInstitutional ? 'I' : '')
              | translate : { id: item.nemoId, date: item.signDate | datePko }
          "
          [href]="'/contracts/document/' + item.applicationId"></app-pdf-document>
      </ng-container>

      <app-pagination
        [pageCount]="contractsModel.pageCount"
        (current)="onContractPageChanged($event)"
        class="mt-2"></app-pagination>
    </div>
  </div>
</ng-container>

<div pko-preloader *ngIf="isBusy$ | async"></div>
