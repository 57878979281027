/**
 * Tenor codes used in exchange
 */
export const NON_FORWARD_TENORS = ["TOD", "TOM", "SPOT"] as const;
export type NonForwardTenor = typeof NON_FORWARD_TENORS[number];
export const isSpotTenor = (tenor: string | null | undefined): tenor is NonForwardTenor =>
  NON_FORWARD_TENORS.includes(tenor as NonForwardTenor);

/**
 * Tenor codes used in deposit
 */
const NEAR_TENORS = ["ON", "TN", "SPOT"] as const;
type NearDepositTenor = typeof NEAR_TENORS[number];
export const isNearTenor = (tenor: string): tenor is NearDepositTenor =>
  NEAR_TENORS.includes(tenor as NearDepositTenor);

export interface TenorDate {
  tenor: string;
  date: string;
}

export interface TenorDateConfig {
  values: TenorDate[];
  /**
   * Earliest selectable date. If `exclusive` flag is `true`, the next day is the first selectable date.
   */
  min?: string | null;
  /**
   * Latest selectable date. If `exclusive` flag is `true`, the previous day is the last selectable date.
   */
  max?: string | null;
  /**
   * By default, min & max dates are inclusive, i.e. selectable. If set to `true`, they are excluded from selection.
   */
  exclusive?: boolean;
}

export interface TenorDateForm {
  tenor?: string | null;
  date: string | null;
}

export interface DepositTenors {
  nearTenors: NearDepositTenor[];
  farTenors: string[];
}

export const PRODUCT_TYPES = {
  overnight: "FxOn",
  tomorrow: "FxTn",
  spot: "FxSpot",
  forward: "FxForw",
  swap: "FxSwap",
  shortSwap: "FxSwapShort",
} as const;

export const productsByTenor = {
  TOD: PRODUCT_TYPES.overnight,
  TOM: PRODUCT_TYPES.tomorrow,
  SPOT: PRODUCT_TYPES.spot,
  _: PRODUCT_TYPES.forward,
} as const;

export const swapProductsByTenor = {
  TOD: PRODUCT_TYPES.shortSwap,
  TOM: PRODUCT_TYPES.shortSwap,
  SPOT: PRODUCT_TYPES.shortSwap,
  _: PRODUCT_TYPES.swap,
} as const;

// NB: There is one additional mapping, a weird mix of both product & tenor, to "exchange type"
//     But it's only for display, so it's done directly in the translation json.

export type ProductType = typeof PRODUCT_TYPES[keyof typeof PRODUCT_TYPES];

export type TenorDateVisibility = "date" | "tenor" | "both";
