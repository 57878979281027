<ng-container *ngIf="tenorDates$ | async as tenorDates">
  <ng-container *ngIf="transactionType === 'investment-deposit'; else deposit">
    <app-date-bounds-inv-deposit-element
      [tenorDates]="tenorDates"
      [holidays]="holidays$ | async"
      [disableSingleValue]="disableSingleValue"
      [endTenorPlaceholder]="endTenorPlaceholder">
    </app-date-bounds-inv-deposit-element>
  </ng-container>
  <ng-template #deposit>
    <app-date-bounds-deposit-element
      [tenorDates]="tenorDates"
      [holidays]="holidays$ | async"
      [disableSingleValue]="disableSingleValue"
      [endTenorPlaceholder]="endTenorPlaceholder">
    </app-date-bounds-deposit-element>
  </ng-template>
  <p *ngIf="duration$ | async as duration">
    {{ "deposit.DurationMessage" | translate : { duration } }}
  </p>
</ng-container>
