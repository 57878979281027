/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectorRef, Directive, ElementRef, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";

@Directive()
export abstract class AbstractCheckbox implements ControlValueAccessor {
  #disabled = false;
  #checked = false;

  constructor(private cdr: ChangeDetectorRef) {}

  onChange: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  @ViewChild("input") input?: ElementRef<HTMLInputElement>;

  @Input() set disabled(value: boolean) {
    if (value === this.#disabled) return;

    this.#disabled = value;
    this.cdr.markForCheck();
  }

  get disabled() {
    return this.#disabled;
  }

  @Input() set checked(value: boolean) {
    if (value === this.#checked) return;

    this.#checked = value;
    this.cdr.markForCheck();
  }

  get checked() {
    return this.#checked;
  }

  writeValue(value: any) {
    this.checked = Boolean(value);
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  /**
   * We need to bind to a click event to support changing values manually.
   * Programmatically it works by the ControlValueAccessor implementation.
   */
  onInputClick() {
    if (this.disabled) return;

    const checked = !this.checked;

    this.checked = checked;
    this.onChange(checked);

    if (this.input) {
      this.input.nativeElement.checked = checked;
    }
  }
}
