import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FlowDeclaration } from "../../flow-declaration.model";

@Component({
  selector: "app-flow-declaration-summary",
  templateUrl: "./flow-declaration-summary.component.html",
})
export class FlowDeclarationSummaryComponent {
  @Input() declaration!: FlowDeclaration;
  @Input() allowRefuse = true;
  @Input() showActions = true;
  @Output() updated = new EventEmitter<void>();
  @Output() refused = new EventEmitter<void>();

  onUpdated() {
    this.updated.emit();
  }

  onRefused() {
    this.refused.emit();
  }
}
