import { AbstractControl } from "@angular/forms";
import { Currency } from "./model";

export const getCurrencyByCode = (currencies: Currency[], code: string) =>
  currencies.find((currency) => currency.code === code);

export const ensureControlHasValue =
  (control: AbstractControl, defaultIndex: number) => (currencies: Currency[]) => {
    const codes = currencies.map(({ code }) => code);

    if (!codes.length || codes.includes(control.value)) return;

    control.setValue(codes[defaultIndex]);
  };

export const filterCurrenciesByPairs = (currencies: Currency[], pairs: { code: string }[]) =>
  currencies.filter((currency) => pairs.some((pair) => pair.code.includes(currency.code)));
