import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { ConfigStore } from "@core/config";
import { Application } from "@features/activation/activation.model";
import { ApplicationActionsService } from "@features/activation/application-actions.service";
import { ModalService } from "@features/modal/modal.service";
import { TableColumn } from "@shared/components/table/table-model";
import { ApplicationDetailsComponent } from "../../../digitalization-common/components/application-details/application-details.component";

@Component({
  selector: "app-active-applications",
  templateUrl: "./active-applications.component.html",
})
export class ActiveApplicationsComponent implements OnInit {
  @HostBinding("class") class = "d-block";
  @Input() applications?: Application[] | null;
  @Input() showClient = true;

  readonly columns: TableColumn[] = [
    { name: "activation.list.active.TypeNumber" },
    { name: "activation.list.active.CustomerPid" },
    { name: "activation.list.active.Status", align: "end" },
    { name: "" },
  ];

  constructor(
    public configStore: ConfigStore,
    private modalService: ModalService,
    private actionsService: ApplicationActionsService
  ) {}

  ngOnInit() {
    if (!this.showClient) {
      this.columns.splice(1, 1);
    }
  }

  openDetails(application: Application, event: Event) {
    const modal = this.modalService.modal({
      component: ApplicationDetailsComponent,
      scrollable: true,
    });
    modal.componentInstance.application = application;
    event.stopPropagation();
  }

  cancel(application: Application, event: Event) {
    this.actionsService.cancel(application);
    event.stopPropagation();
  }
}
