<!-- 
// #########################
// ## MODYFIKACJA
// #########################

 -->

<app-grouped-list
  *ngIf="applications && applications.length"
  [groups]="applications | groupAndSort: 'number'"
  [flatList]="true"
  displayAs="table"
  class="d-sm-none">
  <ng-template #itemTemplate let-item>
    <div class="pko-group__item pko-group__item--column" (click)="openDetails(item, $event)">
      <p class="mb-1">
        <span class="fw-light">{{ "activation.list.active.Type" | translate }}:</span>
        {{ "activation.type.list." + (item.type | clientType: item.isInstitutional) | translate }}
      </p>
      <p class="mb-1">
        <span class="fw-light">{{ "activation.list.active.Number" | translate }}:</span>
        {{ item | applicationId }}
      </p>
      <p class="mb-1" *ngIf="showClient">
        <span class="fw-light">{{ "activation.list.active.Customer" | translate }}:</span>
        {{ item.clientAName + (item.clientBName ? "/" + item.clientBName : "") }}
      </p>
      <p class="mb-1" *ngIf="showClient">
        <span class="fw-light">{{ "activation.list.active.Pid" | translate }}:</span>
        {{ item.clientAPid + (item.clientBPid ? "/" + item.clientBPid : "") }}
      </p>
      <p class="mb-1">
        <span class="fw-light">{{ "activation.list.active.Status" | translate }}:</span>
        {{ item | applicationStatus | translate }}
      </p>
      <div class="mb-0 w-100">
        <span
          class="gtext-600 text-sm"
          [innerHTML]="
            'activation.statusInfo.' + item.status
              | translate: { phone: configStore.config.infoPhone }
          ">
        </span>
        <button
          type="button"
          class="btn btn-primary"
          (click)="openDetails(item, $event)"
          *ngIf="item.signVisible">
          {{ "activation.Sign" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="cancel(item, $event)"
          *ngIf="item.cancelVisible">
          {{ "activation.Cancel" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</app-grouped-list>

<table
  *ngIf="applications && applications.length"
  class="d-none d-sm-table pko-applications"
  pkoTable
  [columns]="columns"
  emptyMessage="missingData.ActiveApplications"
  [items]="applications">
  <ng-template #itemTemplate let-item>
    <!-- <tr
    class="pko-table__row pko-table__row-dropdown align-middle"
    role="row"
    (click)="openDetails(item, $event)"> -->
    <!-- ZMIANA -->
    <tr class="pko-table__row pko-table__row-dropdown align-middle" role="row">
      <td pkoTableCell>
        {{ "activation.type.list." + (item.type | clientType: item.isInstitutional) | translate }}
        <br />
        <span class="gtext-600 text-sm">{{ item | applicationId }}</span>
      </td>
      <td pkoTableCell *ngIf="showClient">
        {{ item.clientAName + (item.clientBName ? "/" + item.clientBName : "") }} <br />
        <span class="gtext-600 text-sm">{{
          item.clientAPid + (item.clientBPid ? "/" + item.clientBPid : "")
        }}</span>
      </td>
      <td align="end" pkoTableCell>
        {{ item | applicationStatus | translate }}
      </td>
      <td [separated]="true" style="min-width: 256px" pkoTableCell>
        <button
          type="button"
          class="btn btn-primary"
          (click)="openDetails(item, $event)"
          *ngIf="item.signVisible">
          {{ "activation.Sign" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="cancel(item, $event)"
          *ngIf="item.cancelVisible">
          {{ "activation.Cancel" | translate }}
        </button>
        <span
          class="gtext-600 text-sm"
          [innerHTML]="
            'activation.statusInfo.' + item.status
              | translate: { phone: configStore.config.infoPhone }
          ">
        </span>
      </td>
    </tr>
  </ng-template>
</table>
