import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Account, AccountsService, getAccountByNumber } from "@features/accounts";
import { Filters, View360Details } from "@features/history-common/models";
import { HistoryApiService } from "@features/history-common/services/history-api.service";
import { objectToHttpParams } from "@utils/http";
import { combineLatest, map, shareReplay } from "rxjs";
import { Deal, IListItem, PricingsConfig } from "../models/view360-list";

@Injectable({ providedIn: "root" })
export class View360ApiService {
  constructor(
    private http: HttpClient,
    private api: HistoryApiService,
    private accountsService: AccountsService
  ) {}

  getList(filters: Filters) {
    return this.api.getList<IListItem>(filters);
  }

  getListCsv(filters: Filters) {
    const params = objectToHttpParams(filters, new HttpParams());
    return this.http.get<Deal[]>("/history/csv", { params });
  }

  getPricings(filters: Filters) {
    return this.api.getList<IListItem>(filters, "", "pricings");
  }

  getPricingsCsv(filters: Filters) {
    const params = objectToHttpParams(filters, new HttpParams());
    return this.http.get<Deal[]>("/history/pricings/csv", { params });
  }

  getDetails(id: string, pricingsDate?: Date) {
    return combineLatest([
      this.accountsService.collateralAccounts$,
      this.api.getDetails<View360Details>(id, pricingsDate),
    ]).pipe(map(toDetails), shareReplay({ refCount: true, bufferSize: 1 }));
  }

  pricingsConfig$ = this.http
    .get<PricingsConfig>("/history/pricings-config")
    .pipe(shareReplay({ refCount: false, bufferSize: 1 }));
}

const toDetails = ([accounts, details]: [Account[], any]): View360Details => {
  const props = Object.keys(details)
    .filter((x) => x.toLowerCase().includes("account"))
    .map((x) => x as keyof typeof details);
  for (const prop of props) {
    details[prop] = !details[prop].number
      ? null
      : getAccountByNumber(accounts, details[prop].number) ?? details[prop];
  }
  return details as View360Details;
};
