import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Failure } from "@core/models/transaction";
import { ExchangeVariation } from "@features/exchange/models";
import { ModalService } from "@features/modal/modal.service";
import { TranslateService } from "@ngx-translate/core";
import {
  BodyLine,
  defaultPrimary,
  Dialog,
  ModalButtons,
} from "@shared/components/modal/modal-model";
import { formatDate } from "@shared/utils/format";
import { TransactionType } from "../models";
import { ErrorData, SuccessData } from "../models/dialog";

@Injectable({ providedIn: "root" })
export class TransactionResponseService {
  #returnRoute?: string;

  constructor(
    private _router: Router,
    private _modal: ModalService,
    private _translate: TranslateService
  ) {}

  /**
   * Navigates to a decision screen. The full URL is obscured for the client.
   * @param source Type of transaction triggering the action.
   * @param target Route pointing to the decision screen. For exchange and deposit it's the transaction token.
   * @param variation Variation of the decision route.
   */
  decide(source: TransactionType, target = "decision", variation?: ExchangeVariation) {
    this.#setReturnRoute(source, variation);
    if (variation === "close") variation = undefined;
    if (variation === "roll") variation = "swap";

    const entry = variation ? entryRoutes[variation] : entryRoutes[source];
    const route = [entry, variation, target].filter(Boolean);
    this._router.navigate(route, { skipLocationChange: true });
  }

  /**
   * Navigates back to dashboard. Restarts navigation history.
   */
  home() {
    this._router.navigateByUrl("/", { replaceUrl });
  }

  /**
   * Navigates to another place in the application. Restarts navigation history.
   * @param commands An array of URL fragments with which to construct the target URL.
   */
  go(...commands: unknown[]) {
    this._router.navigate(commands, { replaceUrl });
  }

  /**
   * Navigates back to the `source` form, with some recent values retained, usually on success.
   * Restarts navigation history.
   */
  repeat() {
    this._router.navigate([this.#returnRoute ?? window.location.pathname], {
      replaceUrl: !this.#returnRoute,
      state: { retain: "some" },
    });
  }

  /**
   * Navigates back to the `source` form, with all recent values retained.
   * Restarts navigation history.
   */
  retry() {
    this._router.navigate([this.#returnRoute ?? window.location.pathname], {
      replaceUrl: !this.#returnRoute,
      state: { retain: "all", origin: "back" },
    });
  }

  success(
    { source, modalType = "page", ...data }: SuccessData | SuccessDataBodyOverride,
    skipDismiss = false
  ) {
    const defaultButtons: ModalButtons = {
      primary: {
        text: `buttons.repeat.${source}`,
        onClick: () => this.repeat(),
        disabledReason: data.repeatDisabledReason,
      },
      secondary: { text: "buttons.home", onClick: () => this.home() },
    };

    const content: Dialog = {
      type: "success",
      title: `${source}.success.Title`,
      body: data.body ?? createSuccessBody({ source, ...data }),
      buttons: data.buttons ?? (modalType === "modal" ? defaultModalButtons : defaultButtons),
    };

    skipDismiss || this._modal.dismissAll();
    this._modal.dialog(content, modalType);
  }

  error(
    {
      source,
      error = { code: "GENERIC" },
      modalType = "page",
      buttons,
      title,
      type = "failure",
    }: ErrorData,
    variation?: ExchangeVariation
  ) {
    this.#setReturnRoute(source, variation);
    const defaultButtons = {
      primary: {
        text: error.code === "NoAgreementForTransaction" ? "OK" : "buttons.Retry",
        onClick: () => this.retry(),
      },
      secondary: { text: "buttons.home", onClick: () => this.home() },
    };

    const content: Dialog = {
      type,
      title: title ?? `${source}.Failure`,
      body: { key: `errors.${error.code}`, params: this.#getParams(error) },
      buttons: buttons ?? (modalType === "modal" ? defaultModalButtons : defaultButtons),
    };

    this._modal.dismissAll();
    this._modal.dialog(content, modalType);
  }

  /**
   * How about using a shared ApiService, with unified error handling, with all formattable error codes in one place?
   * Seems a little too coupled, maybe? But very simple & straightforward. It shouldn't be done on server, as it is now.
   */
  #formattable: { [index: string]: Record<string, FormatterFn> } = {
    ExternalPreSpot: { spot: formatDate },
    OverRolloverTransactionDeadline: { max: this.#translateTenor.bind(this) },
  };

  #translateTenor(tenor: string) {
    return this._translate.instant("order.tenors." + tenor);
  }

  #getParams({ code, data }: Failure) {
    if (!data) return;
    const formatter = this.#formattable[code];
    if (!formatter) return data;

    const formatted: [string, string][] = Object.entries(data).map(([key, value]) => [
      key,
      formatter[key]?.(value) ?? value,
    ]);

    return Object.fromEntries(formatted);
  }

  #setReturnRoute(source: TransactionType, variation?: ExchangeVariation) {
    const entry = returnRoutes[variation ?? source];
    this.#returnRoute = window.location.pathname === "/" ? entry : undefined;
  }
}

const replaceUrl = true;

const defaultModalButtons: ModalButtons = { primary: defaultPrimary };

const entryRoutes = {
  alert: "/rates/alert",
  deposit: "/deposits/deposit",
  premiumDeposit: "/deposits/premium",
  exchange: "/transactions/exchange",
  swap: "/transactions/exchange",
  order: "/rates/order",
  dpw: "/transactions/iob",
  iob: "/transactions",
  multifx: "/transactions/multifx",
  multidpw: "/transactions/iob/dpw/m",
  "investment-deposit": "investment-deposits/deposit",
} as const;

const returnRoutes = {
  alert: "/rates/alert",
  deposit: "/deposits/deposit",
  premiumDeposit: "/deposits/premium",
  exchange: "/transactions/exchange",
  swap: "/transactions/exchange",
  order: "/rates/order",
  dpw: "/transactions/iob/dpw",
  iob: "/transactions/iob",
  close: "/transactions/exchange",
  roll: "/transactions/exchange",
  multifx: "/transactions/multifx",
  multidpw: "/transactions/iob/dpw",
  "investment-deposit": "investment-deposits/deposit",
} as const;

const lookupRoutes = {
  alert: "/history/alert",
  deposit: "/deposits/history",
  premiumDeposit: "/deposits/history",
  exchange: "/history/exchange/fx",
  multifx: "/history/exchange/fx",
  multidpw: "/history/exchange/fx",
  order: "/history/order",
  "investment-deposit": "investment-deposits/history",
} as const;

type SuccessDataBodyOverride = Omit<SuccessData, "id" | "bodyLines" | "body"> & {
  body: BodyLine | BodyLine[];
};

export const createSuccessBody = ({ source, bodyLines, id, lookupTarget }: SuccessData) => {
  const lookupLine = {
    key: `${source}.success.Lookup`,
    params: { lookup: lookupRoutes[source], id, target: lookupTarget ?? id },
  };

  if (id) {
    return Array.isArray(bodyLines) ? [lookupLine, ...bodyLines] : [lookupLine, bodyLines];
  } else {
    return Array.isArray(bodyLines) ? [...bodyLines, lookupLine] : [bodyLines, lookupLine];
  }
};

type FormatterFn = typeof formatDate | ((value: string) => string);
