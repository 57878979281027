import { RateResponse, RateSuccess, RESPONSE_TYPE } from "@features/transaction/models/rate";

export interface DepositRateSuccess extends RateSuccess {
  rate: number;
  interestAmount: number;
  maturityAmount: number;
}

export type DepositRateResponse = RateResponse<DepositRateSuccess>;

export const rejectedRate: DepositRateSuccess = {
  responseType: RESPONSE_TYPE.success,
  decisionTime: -1,
  token: "",
  rate: 0,

  interestAmount: 0,
  maturityAmount: 0,
};
