import { Component, HostBinding, OnDestroy } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { Currency, CurrencyService, getCurrencyByCode } from "@features/currency";
import { DpwForm, DpwSummary } from "@features/dpw/dpw.model";
import { DpwFormService } from "@features/dpw/services/dpw-form.service";
import { ExchangeRateSuccess } from "@features/exchange/models";
import { ExchangeService } from "@features/exchange/services";
import { DecisionViewModel, RateStreamable, isRateSuccess } from "@features/transaction/models";
import { Observable, distinctUntilChanged, map, merge, of, startWith, switchMap, tap } from "rxjs";

@Component({
  selector: "app-dpw-decision-exchange",
  templateUrl: "dpw-decision.component.html",
})
export class DpwDecisionComponent implements OnDestroy {
  @HostBinding("class") class = "pko-decision";

  #subscription = this.exchangeService.rejection$.subscribe();

  #rate$ = this.exchangeService.pollRate("exchange");

  loading$ = merge(
    this.#rate$.pipe(map(() => false)),
    this.exchangeService.confirmation$.pipe(mapToBusy()),
    this.exchangeService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$ = this.#rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  constructor(
    private formService: DpwFormService,
    private currencyService: CurrencyService,
    private exchangeService: ExchangeService,
    private tracker: Tracker
  ) {}

  async ngOnDestroy() {
    await this.exchangeService.rejectAsync();
    this.#subscription.unsubscribe();
  }

  #constructViewModel(rate: RateStreamable): Observable<DecisionViewModel<DpwSummary>> {
    if (!isRateSuccess<ExchangeRateSuccess>(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current as DpwForm;

    const summary$ = this.currencyService.allCurrencies$.pipe(map(mapToSummary(rate, form)));

    return summary$.pipe(
      map((summary) => ({
        decision: {
          summary,
          time: rate.decisionTime,
          message: rate.hasInsufficientFunds ? "exchange.insufficientFunds" : undefined,
          ...this.#getActions(rate.token, summary),
        },
      }))
    );
  }

  #trackRate({ decision }: DecisionViewModel<DpwSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.rate) return;

    this.tracker.reportProgress({
      action: "rate",
      data: {
        rate: summary.rate.toString(),
        token: summary.token,
        pair: summary.pair,
        dpwId: summary.ssrId,
      },
    });
  }

  #getActions = (rateToken: string, summary: DpwSummary) => ({
    onTimeout: () => this.exchangeService.reject(),
    forward: rateToken
      ? () => this.exchangeService.confirm(rateToken, { ...summary })
      : () => this.formService.submit(),
  });
}

const mapToSummary = (rate: ExchangeRateSuccess, form: DpwForm) => (currencies: Currency[]) =>
  ({
    ...rate,
    ...form,
    pair: form.currencyPair,
    currency: getCurrencyByCode(currencies, form.currency),
    counterCurrency: getCurrencyByCode(currencies, form.counterCurrency),
    profitCurrency: form.counterCurrency,
  } as DpwSummary);
