import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "initials",
})
export class InitialsPipe implements PipeTransform {
  transform(fullName: string, numChars = 2): string {
    return fullName
      .replace(/[^0-9a-zA-Z_ĄŚĆŻŹÓŁĘąśćżźół\s]/g, "")
      .replace(/\s+/g, " ")
      .replace(/^\s/g, "")
      .replace(/\s$/g, "")
      .split(" ")
      .slice(0, numChars)
      .map((n) => n[0].toUpperCase())
      .join("");
  }
}
