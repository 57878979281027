import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Tracker } from "@core/user-tracking/tracker.service";
import { propCount } from "@utils/object";
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  EMPTY,
  lastValueFrom,
  Observable,
  of,
  pairwise,
  Subscription,
  switchMap,
} from "rxjs";
import { defaults } from "./defaults";
import {
  BlotterGraph,
  Customers,
  Dashboard,
  DealHistory,
  Deposit,
  DepositControl,
  Exchange,
  Graph,
  Indicatives,
  InvestmentDeposit,
  Language,
  PhonePreference,
  Preferences,
  PreferenceType,
  Swap,
  TransactionControlFlags,
} from "./preferences.model";
import { merge } from "./preferences.utils";

@Injectable({ providedIn: "root" })
export class PreferencesService {
  private _savingSubscription = new Subscription();

  private _language = new BehaviorSubject(defaults.language);
  private _indicatives = new BehaviorSubject(defaults.indicatives);
  private _dashboard = new BehaviorSubject(defaults.dashboard);
  private _exchange = new BehaviorSubject(defaults.exchange);
  private _investmentDeposit = new BehaviorSubject(defaults.investmentDeposit);
  private _deposit = new BehaviorSubject(defaults.deposit);
  private _swap = new BehaviorSubject(defaults.swap);
  private _transactionControl = new BehaviorSubject(defaults.transactionControl?.values);
  private _transactionControlAvailibility = defaults.transactionControl?.availability;
  private _depositControl = new BehaviorSubject(defaults.depositControl);
  private _premiumDepositControl = new BehaviorSubject(defaults.premiumDepositControl);
  private _investmentDepositControl = new BehaviorSubject(defaults.investmentDepositControl);
  private _dealHistory = new BehaviorSubject(defaults.dealHistory);
  private _phone = new BehaviorSubject(defaults.phone);
  private _blotterGraph = new BehaviorSubject(defaults.blotterGraph);
  private _graph = new BehaviorSubject(defaults.graph);
  private _customers = new BehaviorSubject(defaults.customers);

  language$ = this._language.asObservable();
  indicatives$ = this._indicatives.asObservable();
  dashboard$ = this._dashboard.asObservable();
  exchange$ = this._exchange.asObservable();
  deposit$ = this._deposit.asObservable();
  swap$ = this._swap.asObservable();
  transactionControl$ = this._transactionControl.asObservable();
  depositControl$ = this._depositControl.asObservable();
  premiumDepositControl$ = this._premiumDepositControl.asObservable();
  investmentDepositControl$ = this._investmentDepositControl.asObservable();
  dealHistory$ = this._dealHistory.asObservable();
  phone$ = this._phone.asObservable();
  graph$ = this._graph.asObservable();
  blotterGarph$ = this._blotterGraph.asObservable();
  customers$ = this._customers.asObservable();
  investmentDeposit$ = this._investmentDeposit.asObservable();

  constructor(private http: HttpClient, private tracker: Tracker) {}

  get deposit() {
    return this._deposit.getValue();
  }

  get exchange() {
    return this._exchange.getValue();
  }

  get investmentDeposit() {
    return this._investmentDeposit.getValue();
  }

  get swap() {
    return this._swap.getValue();
  }

  get transactionControl() {
    return this._transactionControl.getValue();
  }

  get transactionControlAvailability() {
    return this._transactionControlAvailibility;
  }

  get depositControl() {
    return this._depositControl.getValue();
  }

  get premiumDepositControl() {
    return this._premiumDepositControl.getValue();
  }

  get investmentDepositControl() {
    return this._investmentDepositControl.getValue();
  }

  get dashboard() {
    return this._dashboard.getValue();
  }

  get indicatives() {
    return this._indicatives.getValue();
  }

  get graph() {
    return this._graph.getValue();
  }

  get dealHistory() {
    return this._dealHistory.getValue();
  }

  get phone() {
    return this._phone.getValue();
  }

  get blotterGraph() {
    return this._blotterGraph.getValue();
  }

  get customers() {
    return this._customers.getValue();
  }

  setLanguage(update: Language) {
    this._language.next(update);
  }

  setCustomers(update: Customers) {
    this._customers.next(update);
  }

  setIndicatives(update: Partial<Indicatives>) {
    this._indicatives.next({ ...this._indicatives.value, ...update });
  }

  setDashboard(update: Partial<Dashboard>) {
    this._dashboard.next({ ...this._dashboard.value, ...update });
  }

  setGraph(update: Graph) {
    this._graph.next(update);
  }

  setExchange(update: Exchange) {
    this._exchange.next(update);
  }

  setDeposit(update: Deposit) {
    this._deposit.next(update);
  }

  setTransactionControl(update: TransactionControlFlags) {
    this._transactionControl.next(update);
  }

  setDepositControl(update: DepositControl) {
    this._depositControl.next(update);
  }

  setPremiumDepositControl(update: DepositControl) {
    this._premiumDepositControl.next(update);
  }

  setInvestmentDepositControl(update: DepositControl) {
    this._investmentDepositControl.next(update);
  }

  setSwap(update: Swap) {
    this._swap.next(update);
  }

  setDealHistory(update: Partial<DealHistory>) {
    this._dealHistory.next({ ...this._dealHistory.value, ...update });
  }

  setPhone(update: PhonePreference) {
    this._phone.next(update);
  }

  setBlotterGraph(update: BlotterGraph) {
    this._blotterGraph.next(update);
  }

  setInvestmentDeposit(update: InvestmentDeposit) {
    this._investmentDeposit.next(update);
  }

  async setup() {
    this._savingSubscription.unsubscribe();
    this._savingSubscription = new Subscription();

    const preferences$: Observable<Preferences> = this.http
      .get<Preferences>("/preferences")
      .pipe(catchError(() => of(defaults)));

    const {
      language,
      dashboard,
      exchange,
      deposit,
      swap,
      transactionControl,
      depositControl,
      premiumDepositControl,
      investmentDepositControl,
      indicatives,
      dealHistory,
      phone,
      graph,
      blotterGraph,
      customers,
      investmentDeposit,
    } = await lastValueFrom(preferences$);

    this.setLanguage(propCount(language) ? language : defaults.language);
    this.setIndicatives(propCount(indicatives) ? indicatives : defaults.indicatives);
    this.setDashboard(propCount(dashboard) ? dashboard : defaults.dashboard);
    this.setExchange(merge(exchange, defaults.exchange));
    this.setDeposit(merge(deposit, defaults.deposit));
    this.setSwap(merge(swap, defaults.swap));
    this.setDealHistory(merge(dealHistory, defaults.dealHistory));
    this.setPhone(merge(phone, defaults.phone));
    this.setTransactionControl(transactionControl?.values);
    this.setDepositControl(depositControl);
    this.setPremiumDepositControl(premiumDepositControl);
    this.setInvestmentDepositControl(investmentDepositControl);
    this.setGraph({ ...defaults.graph, ...graph });
    this.setBlotterGraph(propCount(blotterGraph) ? blotterGraph : defaults.blotterGraph);
    this.setCustomers(customers);
    this.setInvestmentDeposit(merge(investmentDeposit, defaults.investmentDeposit));
    this._transactionControlAvailibility = transactionControl?.availability;

    this.subscribeToSave();
  }

  private subscribeToSave() {
    const saveLanguagePreferences$ = this._language.pipe(
      this.savePreferences("/preferences/language", this._language)
    );

    const saveCustomersPreferences$ = this._customers.pipe(
      this.savePreferences("/preferences/customers", this._customers)
    );

    const saveIndicativesPreferences$ = this._indicatives.pipe(
      this.savePreferences("/preferences/indicatives", this._indicatives)
    );

    const saveDashboardPreferences$ = this._dashboard.pipe(
      this.savePreferences("/preferences/dashboard", this._dashboard)
    );

    const saveExchangePreferences$ = this._exchange.pipe(
      this.savePreferences("/preferences/exchange", this._exchange)
    );

    const saveDepositPreferences$ = this._deposit.pipe(
      this.savePreferences("/preferences/deposit", this._deposit)
    );

    const saveSwapPreferences$ = this._swap.pipe(
      this.savePreferences("/preferences/swap", this._swap)
    );

    const saveHistoryPreferences$ = this._dealHistory.pipe(
      this.savePreferences("/preferences/dealhistory", this._dealHistory)
    );

    const savePhonePreferences$ = this._phone.pipe(
      this.savePreferences("/preferences/phone", this._phone)
    );

    const saveTransactionControlPreferences$ = this._transactionControl.pipe(
      this.savePreferences("/preferences/transactionControl", this._transactionControl)
    );

    const saveDepositControlPreferences$ = this._depositControl.pipe(
      this.savePreferences("/preferences/depositControl", this._depositControl)
    );

    const savePremiumDepositControlPreferences$ = this._premiumDepositControl.pipe(
      this.savePreferences("/preferences/premiumDepositControl", this._premiumDepositControl)
    );

    const saveInvestmentDepositControlPreferences$ = this._investmentDepositControl.pipe(
      this.savePreferences("/preferences/investmentDepositControl", this._investmentDepositControl)
    );

    const saveGraphPreferences$ = this._graph.pipe(
      this.savePreferences("/preferences/graph", this._graph)
    );

    const saveBlotterGraphPreferences$ = this._blotterGraph.pipe(
      this.savePreferences("/preferences/blottergraph", this._blotterGraph)
    );

    const saveInvestmentDepositPreferences$ = this._investmentDeposit.pipe(
      this.savePreferences("/preferences/investmentDeposit", this._investmentDeposit)
    );

    this._savingSubscription.add(saveLanguagePreferences$.subscribe());
    this._savingSubscription.add(saveCustomersPreferences$.subscribe());
    this._savingSubscription.add(saveIndicativesPreferences$.subscribe());
    this._savingSubscription.add(saveDashboardPreferences$.subscribe());
    this._savingSubscription.add(saveExchangePreferences$.subscribe());
    this._savingSubscription.add(saveDepositPreferences$.subscribe());
    this._savingSubscription.add(saveSwapPreferences$.subscribe());
    this._savingSubscription.add(saveTransactionControlPreferences$.subscribe());
    this._savingSubscription.add(saveDepositControlPreferences$.subscribe());
    this._savingSubscription.add(savePremiumDepositControlPreferences$.subscribe());
    this._savingSubscription.add(saveInvestmentDepositControlPreferences$.subscribe());
    this._savingSubscription.add(saveHistoryPreferences$.subscribe());
    this._savingSubscription.add(savePhonePreferences$.subscribe());
    this._savingSubscription.add(saveGraphPreferences$.subscribe());
    this._savingSubscription.add(saveBlotterGraphPreferences$.subscribe());
    this._savingSubscription.add(saveInvestmentDepositPreferences$.subscribe());
  }

  private savePreferences<T extends PreferenceType>(url: string, subject: BehaviorSubject<T>) {
    const onError = (previousValue: T) => () => {
      this.tracker.report({
        category: "preferences",
        action: "error",
        data: { type: url.split("/").slice(-1)[0] },
      });
      subject.next(previousValue);
      return EMPTY;
    };

    return (source$: Observable<T>) =>
      source$.pipe(
        // we need previous in case of an error
        pairwise(),
        // comparing objects by reference. OnError, `prev` is emitted
        // so it will be caught by distinct and not passed further.
        distinctUntilChanged((prev, curr) => prev[0] === curr[1]),
        switchMap(([prev, next]) => this.http.post<void>(url, next).pipe(catchError(onError(prev))))
      );
  }
}
