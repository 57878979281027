import { Component, HostBinding, Input, Self } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { uid } from "@utils/string";
import { IFormControl } from "../model";

@Component({
  selector: "app-input-radio",
  templateUrl: "./input-radio.component.html",
})
export class InputRadioComponent implements IFormControl {
  @HostBinding("class") class = "d-block";

  @Input("ngId") id = `check-${uid()}`;
  @Input() label?: string;
  @Input() value?: string;
  @Input() translateLabel? = true;
  @Input() name?: string;

  constructor(@Self() private ngControl: NgControl) {}

  get control() {
    return this.ngControl.control as FormControl;
  }
}
