<div class="form-check">
  <input class="form-check-input" type="checkbox" [formControl]="control" [id]="id" />
  <ng-container
    *ngTemplateOutlet="hasContent ? checkContent : description ? checkMultiline : checkLabel">
  </ng-container>
</div>

<ng-template #checkLabel>
  <label
    class="form-check-label"
    *ngIf="!!label"
    [for]="id"
    [innerHTML]="translateLabel ? (label | translate) : label">
  </label>
  <app-tooltip [content]="tooltip" *ngIf="tooltip"></app-tooltip>
</ng-template>

<ng-template #checkDescription>
  <div class="form-check-description" *ngIf="!!description">{{ description | translate }}</div>
</ng-template>

<ng-template #checkContent>
  <div class="form-check-container">
    <ng-container *ngTemplateOutlet="checkLabel"></ng-container>
    <ng-container *ngTemplateOutlet="checkDescription"></ng-container>
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #checkMultiline>
  <div class="form-check-container">
    <ng-container *ngTemplateOutlet="checkLabel"></ng-container>
    <ng-container *ngTemplateOutlet="checkDescription"></ng-container>
  </div>
</ng-template>
