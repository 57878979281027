/**
 * Generates a unique id. For more serious cases {@link uuid4} should be used.
 * @returns A unique id.
 */
export function uid(): string {
  return performance.now().toString(36) + Math.random().toString(36).substring(2);
}

/**
 * Converts the first character of `value` to lower case.
 * @param value The string to convert.
 * @returns The converted string.
 */
export function uncapitalize<T extends string>(value: T) {
  return (value.charAt(0).toLowerCase() + value.slice(1)) as Uncapitalize<T>;
}

/**
 * Converts the first character of `value` to upper case.
 * @param value The string to convert.
 * @returns The converted string.
 */
export function capitalize<T extends string>(value: T) {
  return (value.charAt(0).toUpperCase() + value.slice(1)) as Capitalize<T>;
}
