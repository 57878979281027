/*
##########################
## MODYFIKACJE
##########################
*/

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { Failure } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions, origins } from "@core/user-tracking/tracking.model";
import { Account, AccountsService } from "@features/accounts";
import { Currency, CurrencyPair, CurrencyService } from "@features/currency";
import { ModalService } from "@features/modal/modal.service";
import { TileMessageComponent } from "@features/modal/tile-message/tile-message.component";
import { TenorDate, TenorService } from "@features/tenor";
import { isInitializationWarning, isMifidError } from "@features/transaction/models";
import { getWarningDialog } from "@features/transaction/utils/dialog";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Dialog } from "@shared/components/modal/modal-model";
import { last } from "@utils/collection";
import { assertIsDefined } from "@utils/misc";
import { datepickerFormat, getMax, getMin } from "@utils/time";
import {
  Observable,
  Subscription,
  combineLatest,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  merge,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs";
import {
  InvestmentDepositConfig,
  InvestmentDepositForm,
  OfferCurrencyPair,
  OfferTenor,
  OfferTenorSettings,
} from "../investment-deposit.model";
import { InvestmentDepositFormService } from "../services/investment-deposit-form.service";
import { InvestmentDepositResponseService } from "../services/investment-deposit-response.service";
import { InvestmentDepositWarningCodeService } from "../services/investment-deposit-warning-code-service.service";
import { InvestmentDepositApiService } from "../services/investment-deposit.api.service";
import {
  getMaxInvestmentDepositRate,
  getMinInvestmentDepositRate,
  roundMax,
  roundMin,
} from "./utils/inve-depo-rate.utils";
import {
  amountMapper,
  getOfferByCurrencyAndAmount,
  getOfferTenorsDatesByCurrencyPair,
  mapOfferTenorsToGlobalTenors,
  sortTenors,
} from "./utils/investment-deposit.utils";

@Component({
  selector: "app-investment-deposit",
  templateUrl: "investment-deposit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestmentDepositComponent implements OnInit, OnDestroy {
  @HostBinding("class") class = "position-relative d-block";
  vm$!: Observable<InvestmentDepositConfig>;
  initialization$: Observable<boolean>;
  currencies$!: Observable<Currency[]>;
  pairs$!: Observable<CurrencyPair[]>;
  accounts$!: Observable<Account[]>;
  offerConfig$!: Observable<SlidersConfig>;
  decimals$!: Observable<number>;
  emptyMessage$: Observable<{ isEmpty: boolean; dialog: Dialog | null }>;
  form: FormGroup;
  @ViewChild("mifidValidation", { read: ViewContainerRef }) mifidValidation!: ViewContainerRef;

  #subscription!: Subscription;
  protected isTile = false;

  isBusy$ = merge(
    this.formService.submission$.pipe(mapToBusy()),
    this.busyService.observeOn("/currency", "/accounts", "/deposit")
  );

  constructor(
    fb: FormBuilder,
    private formService: InvestmentDepositFormService,
    private currencyService: CurrencyService,
    private accountsService: AccountsService,
    private api: InvestmentDepositApiService,
    private tenorService: TenorService,
    private busyService: BusyService,
    private tracker: Tracker,
    private router: Router,
    private modal: ModalService,
    private responseService: InvestmentDepositResponseService,
    public warningCodeService: InvestmentDepositWarningCodeService
  ) {
    const { retain } = window.history.state;
    formService.reset(retain);

    const form = formService.current as InvestmentDepositForm;

    const { tenor, date } = form.end;
    const currency = fb.control(form.currency || null, Validators.required);
    const amount = fb.control(form.amount || null, Validators.required);
    const currencyPair = fb.control(form.currencyPair || null, Validators.required);
    const end = fb.group({
      tenor: tenor || null,
      date: fb.control(date, Validators.required),
    });

    this.form = fb.group({
      currency,
      amount,
      currencyPair,
      start: fb.group({
        tenor: "ON",
        date: fb.control(datepickerFormat(new Date()), Validators.required),
      }),
      end,
      account: fb.control(form.account, Validators.required),
      rates: fb.group({
        interestRate: fb.control(form.rates?.interestRate || null, Validators.required),
        rate: fb.control(form.rates?.rate || null, Validators.required),
      }),
    });

    this.vm$ = this.api.getData().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.emptyMessage$ = this.vm$.pipe(
      tap((data) => this.checkTargetMarket(data)),
      switchMap((data) =>
        this.isTile ? this.handeMifidResponse(data) : this.responseService.handeMifidResponse(data)
      ),
      map((data) => this.#showNoAccessPage(data)),
      tap((dialog) => !!dialog && !this.isTile && this.modal.dialog(dialog, "page")),
      map((dialog) => ({ isEmpty: !!dialog, dialog }))
    );

    this.initialization$ = this.vm$.pipe(
      tap(() => {
        this.#setCurrencies(currency, amount);
        this.#setPairs(currency, amount);
        this.#setOfferConfig(currency, amount, currencyPair);
        this.#setAccounts(currency, this.form.get("account")!, end);
        this.#setListeners();
      }),
      switchMap(() => this.#setupDefaultValidation(currency, amount, currencyPair, end)),
      map(() => true)
    );
  }

  ngOnInit(): void {
    this.isTile ||
      this.tracker.follow({
        process: "investment-deposit",
        origin: history.state?.origin ?? "reload",
      });
  }

  ngOnDestroy(): void {
    this.#subscription && this.#subscription.unsubscribe();
  }

  onSubmit(componentSelector?: string) {
    this.formService.save(this.form.getRawValue());

    if (handleFormErrors(this.form, componentSelector)) {
      this.isTile && this.tracker.follow({ process: "investment-deposit", origin: origins.TILE });
      this.formService.submit();
    }
  }

  checkTargetMarket(data: InvestmentDepositConfig) {
    const mifidResult = data.mifidValidationResult;
    assertIsDefined(mifidResult);

    if (isInitializationWarning(mifidResult)) {
      const warnings = mifidResult.warnings.map((warning) => warning.code);
      this.warningCodeService.checkOutOfTargetMarket(warnings);
    } else {
      this.warningCodeService.outOfTargetMarket = false;
    }
  }

  async handeMifidResponse(config: InvestmentDepositConfig) {
    const response = config.mifidValidationResult!;

    if (isMifidError(response)) {
      return config;
    }

    if (isInitializationWarning(response)) {
      const consents = await this.#onWarnings(response.warnings);
      if (!consents) {
        return config;
      }

      return { ...config, mifidValidationResult: undefined };
    }

    return { ...config, mifidValidationResult: undefined };
  }

  async #onWarnings(warnings: Failure[]) {
    for (const { code, data } of warnings) {
      const message = this.mifidValidation.createComponent(TileMessageComponent);
      message.instance.data = getWarningDialog(code, "deposit", data);
      const accepted = await firstValueFrom(message.instance.clicked);
      this.mifidValidation.clear();

      this.tracker.report({
        category: "investment-deposit",
        action: actions.WARNING,
        data: { code, accepted: accepted ?? false },
      });

      if (!accepted) return;
    }

    return warnings.map(({ code }) => code);
  }

  getTenorDates() {
    const { currency, amount, currencyPair } = this.form.controls;
    return (tenor: string) => {
      const observable = combineLatest([
        currency.valueChanges.pipe(startWith(currency.value), distinctUntilChanged()),
        amount.valueChanges.pipe(
          debounceTime(200),
          map(amountMapper),
          startWith(amount.value),
          distinctUntilChanged()
        ),
        currencyPair.valueChanges.pipe(startWith(currencyPair.value), distinctUntilChanged()),
        this.vm$,
        this.tenorService.getTenorDatesByTenor(tenor),
      ]).pipe(
        map(([currency, amount, currencyPair, config, tenors]) => {
          if (!currency || !amount || !currencyPair) {
            return {
              values: [] as TenorDate[],
              min: undefined,
              max: undefined,
            };
          }

          const offerConfig = getOfferByCurrencyAndAmount(config, currency, amount);

          if (!offerConfig) {
            this.form.controls.end.setValue({ tenor: null, date: null });
            disableControl(this.form.controls.end);
            return {
              values: [] as TenorDate[],
              min: undefined,
              max: undefined,
            };
          }

          const globalConfig = sortTenors(config.tenors);

          const offerTenorConfig = getOfferTenorsDatesByCurrencyPair(
            config,
            offerConfig,
            currencyPair
          );

          if (!globalConfig?.length || !offerTenorConfig?.length) {
            this.form.controls.end.setValue({ tenor: null, date: null });
            disableControl(this.form.controls.end);
            return {
              values: [] as TenorDate[],
              min: undefined,
              max: undefined,
            };
          }

          const min = getMax(offerTenorConfig[0]?.from, globalConfig[0]?.from)!;
          const max = getMin(last(offerTenorConfig)?.to, last(globalConfig)?.to)!;
          min.setHours(0); // <-------- NOWE
          min.setMinutes(0); // <-------- NOWE
          min.setSeconds(0); // <-------- NOWE

          return {
            values: tenors.filter(
              (tenor) =>
                new Date(tenor.date) >= min &&
                new Date(tenor.date) <= max &&
                offerTenorConfig.map((x) => x.name).includes(tenor.tenor)
            ),
            min: min && datepickerFormat(min),
            max: max && datepickerFormat(max),
          };
        })
      );

      return observable;
    };
  }

  #showNoAccessPage(data: InvestmentDepositConfig) {
    return this.#showMifidError(data) ?? this.#showEmptyFormError(data);
  }

  #showMifidError(data: InvestmentDepositConfig) {
    if (!!data.mifidValidationResult) {
      if (isMifidError(data.mifidValidationResult)) {
        const content: Dialog = {
          type: "failure",
          body: `errors.${data.mifidValidationResult.error.code}`,
          title: "investment-deposit.ContactDealer",
          buttons: this.isTile
            ? {}
            : {
                primary: {
                  text: "buttons.home",
                  onClick: () => this.router.navigateByUrl("/", { replaceUrl: true }),
                },
              },
        };

        return content;
      }

      if (isInitializationWarning(data.mifidValidationResult)) {
        const content: Dialog = {
          type: "failure",
          body: "investment-deposit.ContactDealer",
        };

        return content;
      }
    }

    return null;
  }

  #showEmptyFormError(data: InvestmentDepositConfig) {
    if (!data.currencies?.length || !data.tenors?.length || !data.offer?.currencies?.length) {
      const content: Dialog = {
        type: "failure",
        title: "investment-deposit.UnknownStatus",
        body: "investment-deposit.ContactDealer",
        buttons: this.isTile
          ? {}
          : {
              primary: {
                text: "buttons.home",
                onClick: () => this.router.navigateByUrl("/", { replaceUrl: true }),
              },
            },
      };

      return content;
    }

    return null;
  }

  #setupDefaultValidation(
    currency: AbstractControl,
    amount: AbstractControl,
    currencyPair: AbstractControl,
    tenor: FormGroup
  ) {
    return this.vm$.pipe(
      combineLatestWith(this.getTenorDates()("ON"), this.accountsService.depositAccounts$),
      tap(([data, tenors, accounts]) => {
        const hasAccount = accounts.find((x) => x.currency === currency.value);
        if (!hasAccount) {
          currency.setValue(null);
          return;
        }

        const offerCurrency = data.offer.currencies.find((x) => x.code === currency.value);
        if (!offerCurrency) {
          currency.setValue(null);
          return;
        }

        const offerPairs = getOfferByCurrencyAndAmount(
          data,
          offerCurrency.code,
          amount.value
        )?.currencyPairs.map((x) => x.code);
        if (!offerPairs) {
          amount.setValue(null);
          return;
        }

        const offerPair = offerPairs.find((x) => x === currencyPair.value);
        if (!offerPair) {
          currencyPair.setValue(null);
          return;
        }

        if (!tenors.values?.length) {
          currencyPair.setValue(null);
          return;
        }

        const tenorDate = tenors.values.find((x) => x.tenor == tenor.get("tenor")!.value)?.date;
        if (
          !tenorDate ||
          new Date(tenorDate) < new Date(tenors.min!) ||
          new Date(tenorDate) > new Date(tenors.max!)
        ) {
          tenor.setValue({ tenor: null, date: null });
          return;
        }
      }),
      take(1)
    );
  }

  #setListeners() {
    //depo start date is always today
    disableControl(this.form.controls.start);

    const { rates, account, end, amount } = this.form.controls;

    const controls = Object.entries(this.form.controls)
      .filter(([name]) => name !== "start")
      .map(([, control]) => control);

    controls.forEach(disableControl);

    var firstEmpty = controls.find((control) => !control.value);
    for (const control of controls) {
      control.enable();
      if (firstEmpty === control) break;
    }

    //set handler to enable/disable next control on change
    controls.forEach((control) => {
      const isAmount = control === amount;
      this.#subscription.add(
        control.valueChanges
          .pipe(
            debounceTime(isAmount ? 200 : 0),
            startWith(control.value),
            map((x) => (isAmount ? amountMapper(x) : x)),
            distinctUntilChanged(compare),
            skip(1),
            combineLatestWith(this.accounts$)
          )
          .subscribe(this.#setNextControlValueAndEnabled(control, rates, account, end, controls))
      );
    });
  }

  #setNextControlValueAndEnabled(
    control: AbstractControl,
    rates: AbstractControl,
    account: AbstractControl,
    end: AbstractControl,
    controls: AbstractControl[]
  ) {
    return ([value, accounts]: [any, Account[]]) => {
      const controlIndex = controls.indexOf(control);
      const nextControl = controls[controlIndex + 1];

      if (!nextControl) return;

      this.#setValue(control, nextControl, rates, end, accounts);
      this.#setDisabled(control, nextControl, value, account, accounts);
    };
  }

  #setValue(
    control: AbstractControl,
    nextControl: AbstractControl,
    rates: AbstractControl,
    end: AbstractControl,
    accounts: Account[]
  ) {
    if (nextControl === rates) {
      return;
    }

    if (control === end && !!(control as FormGroup).get("date")!.value && accounts.length === 1) {
      nextControl?.setValue(accounts[0].number);
      return;
    }

    if (nextControl instanceof FormGroup) {
      nextControl?.setValue({ tenor: null, date: null });
    } else {
      nextControl?.setValue(null);
    }
  }

  #setDisabled(
    control: AbstractControl,
    nextControl: AbstractControl,
    value: any,
    account: AbstractControl,
    accounts: Account[]
  ) {
    if (
      (control instanceof FormGroup && !value.date) ||
      !value ||
      (nextControl === account && accounts.length === 1)
    ) {
      disableControl(nextControl);
    } else {
      nextControl?.enable();
      nextControl.markAsUntouched();
    }
  }

  #setCurrencies(currency: AbstractControl, amount: AbstractControl) {
    this.currencies$ = combineLatest(
      [this.vm$, this.currencyService.allCurrencies$, this.accountsService.depositAccounts$],
      (config, currencies, accounts) => {
        const offerCurrencies = config.offer.currencies.map((x) => x.code);
        return currencies.filter(({ code }) => {
          return (
            config.currencies.map((currency) => currency.code).includes(code) &&
            offerCurrencies.includes(code) &&
            accounts.map((x) => x.currency).includes(code)
          );
        });
      }
    );

    this.#subscription = combineLatest([
      this.vm$,
      currency.valueChanges.pipe(startWith(currency.value), distinctUntilChanged()),
    ]).subscribe(([vm, code]) => {
      if (!code) return;
      const selected = vm.currencies.find((x) => x.code === code);
      if (!selected) return;
      const maxBand = vm.offer.currencies
        .find((x) => x.code === code)!
        .amounts.map((x) => x.amount)
        .sort((a, b) => b - a)[0];
      amount.clearValidators();
      amount.addValidators([
        Validators.min(selected.minimalAmount),
        Validators.max(Math.min(selected.maximalAmount, maxBand)),
        Validators.required,
      ]);
    });

    this.decimals$ = combineLatest(
      [
        currency.valueChanges.pipe(startWith(currency.value), distinctUntilChanged()),
        this.currencies$,
      ],
      (currency, currencies) => currencies.find(({ code }) => code === currency)?.decimals ?? 2
    ).pipe(startWith(2));
  }

  #setPairs(currency: AbstractControl, amount: AbstractControl) {
    this.pairs$ = combineLatest([
      this.vm$,
      currency.valueChanges.pipe(startWith(currency.value), distinctUntilChanged()),
      amount.valueChanges.pipe(
        debounceTime(200),
        map(amountMapper),
        startWith(amount.value),
        distinctUntilChanged()
      ),
    ]).pipe(
      filter(([, , amount]) => !!amount),
      map(([config, currency, amount]) => {
        const offerPairs = getOfferByCurrencyAndAmount(config, currency, amount)?.currencyPairs.map(
          (x) => x.code
        );
        return (
          config.currencies
            .find(({ code }) => code === currency)
            ?.currencyPairs?.filter((code) => offerPairs?.includes(code))
            ?.map((code) => ({ code } as CurrencyPair)) ?? []
        );
      })
    );
  }

  #setAccounts(currency: AbstractControl, account: AbstractControl, end: FormGroup) {
    this.accounts$ = currency.valueChanges.pipe(
      startWith(currency.value),
      distinctUntilChanged(),
      switchMap(this.accountsService.getAccounts("deposit")),
      tap(() => end.controls.date.value || account.disable())
    );
  }

  #setOfferConfig(
    currency: AbstractControl,
    amount: AbstractControl,
    currencyPair: AbstractControl
  ) {
    const { retain } = window.history.state;
    this.offerConfig$ = combineLatest([
      currency.valueChanges.pipe(startWith(currency.value), distinctUntilChanged()),
      amount.valueChanges.pipe(
        debounceTime(200),
        map(amountMapper),
        startWith(amount.value),
        distinctUntilChanged()
      ),
      currencyPair.valueChanges.pipe(startWith(currencyPair.value), distinctUntilChanged()),
      this.form
        .get("end.date")!
        .valueChanges.pipe(startWith(this.form.get("end.date")!.value), distinctUntilChanged()),
      this.vm$,
    ]).pipe(
      filter(
        ([currency, amount, currencyPair, endDate]) =>
          !!currency && !!amount && !!currencyPair && !!endDate
      ),
      map(([currency, amount, currencyPair, endDate, config], index) => {
        const offerConfig = getOfferByCurrencyAndAmount(config, currency, amount);

        if (!offerConfig) return {} as SlidersConfig;

        const offerPairConfig = offerConfig.currencyPairs.find(
          ({ code }) => code === currencyPair
        )!;

        // const globalConfig = config.tenors.sort(
        //   (a, b) => new Date(a.from).getTime() - new Date(b.from).getTime()
        // );

        const offerTenorConfig = mapOfferTenorsToGlobalTenors(offerPairConfig, config);

        if (
          //!globalConfig?.length ||
          !offerTenorConfig?.length
        )
          return {} as SlidersConfig;

        // const duration  =difference(endDate, new Date(), "days");
        //const selectedTenor = globalConfig.find((x) => x.from <= endDate && x.to >= endDate);

        const selectedOfferTenor = offerTenorConfig.find(
          (x) => new Date(x.dates.to) >= new Date(endDate)
        )!;

        if (!selectedOfferTenor) return {} as SlidersConfig;

        (retain !== "all" || index > 0) &&
          this.#setUpRatesValues(selectedOfferTenor.tenor, offerPairConfig);

        return {
          ...selectedOfferTenor.tenor.settings,
          midSpotRate: offerPairConfig.midSpotRate,
        } as SlidersConfig;
      }),
      startWith({
        maxDeviation: 1,
        maximalInterestRate: 1,
        minimalInterestRate: 1,
        minSpread: 1,
        midSpotRate: 0,
        numberOfRateRanges: 0,
      } as SlidersConfig)
    );
  }

  #setUpRatesValues(tenor: OfferTenor, offerPairConfig: OfferCurrencyPair) {
    const min = getMinInvestmentDepositRate(
      offerPairConfig.midSpotRate,
      tenor.settings.maxDeviation
    );

    const max = getMaxInvestmentDepositRate(
      offerPairConfig.midSpotRate,
      tenor.settings.maxDeviation
    );

    this.form.get("rates.interestRate")!.setValue(tenor.settings.maximalInterestRate);
    this.form
      .get("rates.rate")!
      .setValue([
        roundMin((max - min) * 0.25 + min, offerPairConfig.midSpotRate),
        roundMax((max - min) * 0.75 + min, offerPairConfig.midSpotRate),
      ]);
  }
}

function disableControl(control: AbstractControl) {
  if (!control) return;
  control.disable();
}

type SlidersConfig = OfferTenorSettings & Pick<OfferCurrencyPair, "midSpotRate">;

function compare<T>(previous: T, current: T) {
  if (!!previous !== !!current) return false;

  if (!!previous) {
    if (typeof previous === "object" || Array.isArray(previous)) {
      for (const prop in previous) {
        if (!compare(previous[prop], current[prop])) return false;
      }

      return true;
    } else {
      return previous === current;
    }
  }

  return !!previous === !!current;
}
