import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IndicativeRate } from "@features/indicatives/indicatives.model";
import { NonForwardTenor } from "@features/tenor";
import { cache } from "@utils/stream";
import { Observable } from "rxjs";

/**
 * Inject this per component, to limit the performance impact of the map.
 *
 * Since only backend is shared with Indicatives,
 * it makes more sense to make this separate, rather than dependent on Indicatives module.
 */
@Injectable()
export class LiveRateService {
  #rates: Record<string, Observable<LiveRate>> = {};

  constructor(private http: HttpClient) {}

  fetch({ tenor, pair }: FetcherParams) {
    const key = `${tenor}-${pair}`;

    if (this.#rates[key]) {
      return this.#rates[key];
    }

    const rate$ = this.http
      .get<LiveRate>("/indicatives/rate", { params: { pair, tenor } })
      .pipe(cache(15000));

    this.#rates[key] = rate$;

    return rate$;
  }
}

export type LiveRate = Omit<IndicativeRate, "pair">;
type FetcherParams = { tenor: NonForwardTenor; pair: string };
