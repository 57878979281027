import { Component, OnDestroy } from "@angular/core";
import { mapToBusy } from "@core/models/observable-action";
import { reverseSide } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { Account, AccountsService, getAccountByNumber } from "@features/accounts";
import { Currency, CurrencyService, getCurrencyByCode } from "@features/currency";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { DecisionViewModel, RateStreamable, isRateSuccess } from "@features/transaction/models";
import { assertIsDefined } from "@utils/misc";
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import {
  CommonExchangeForm,
  ExchangeQuote,
  ExchangeRateSuccess,
  RollExchangeForm,
  RollableOriginalExchange,
  SwapExchangeForm,
  SwapSummary,
} from "../../models";
import { ExchangeFormService, ExchangeService } from "../../services";

@Component({
  selector: "app-decision-exchange-swap",
  templateUrl: "decision-swap.component.html",
})
export class SwapDecisionComponent implements OnDestroy {
  #subscription = this.exchangeService.rejection$.subscribe();

  #rate$ = this.exchangeService.pollRate("swap");

  loading$ = merge(
    this.#rate$.pipe(map(() => false)),
    this.exchangeService.confirmation$.pipe(mapToBusy()),
    this.exchangeService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$: Observable<DecisionViewModel<SwapSummary>> = this.#rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  get closingSubtitle() {
    return this.#isRoll ? "exchange.roll.legs.Closing" : undefined;
  }

  get rollingSubtitle() {
    return this.#isRoll ? "exchange.roll.legs.Rolling" : undefined;
  }

  get subtype() {
    const form = this.formService.current;
    if (!isRoll(form)) {
      return undefined;
    } else {
      return form.rateType === "Historical" ? "historicalroll" : "marketroll";
    }
  }

  get #isRoll() {
    return isRoll(this.formService.current);
  }

  constructor(
    private formService: ExchangeFormService,
    private accountsService: AccountsService,
    private currencyService: CurrencyService,
    private exchangeService: ExchangeService,
    public warningService: ExchangeWarningCodeService,
    private tracker: Tracker
  ) {}

  async ngOnDestroy() {
    await this.exchangeService.rejectAsync();
    this.#subscription.unsubscribe();
  }

  #constructViewModel(rate: RateStreamable): Observable<DecisionViewModel<SwapSummary>> {
    if (!isRateSuccess<ExchangeRateSuccess>(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current as SwapExchangeForm;

    const summary$ = combineLatest(
      [
        this.accountsService.exchangeAccounts$,
        this.accountsService.collateralAccounts$,
        this.currencyService.allCurrencies$,
      ],
      mapToSummary(rate, form, this.subtype)
    );

    return summary$.pipe(
      map((summary) => ({
        decision: {
          summary,
          time: rate.decisionTime,
          ...this.#getActions(
            rate.token,
            summary,
            (form as unknown as { original: RollableOriginalExchange })?.original
          ),
        },
      }))
    );
  }

  #trackRate({ decision }: DecisionViewModel<SwapSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.nearLeg.rate) return;

    this.tracker.reportProgress({
      action: "rate",
      data: {
        nearRate: summary.nearLeg.rate.toString(),
        farRate: summary.farLeg.rate.toString(),
        token: summary.nearLeg.token,
        pair: summary.nearLeg.pair,
      },
    });
  }

  #getActions(
    rateToken: string,
    { nearLeg, farLeg }: SwapSummary,
    original?: RollableOriginalExchange
  ) {
    const summary = { ...nearLeg, farRate: farLeg.rate, original };
    const variation = this.#isRoll ? "roll" : "swap";

    return {
      onTimeout: () => this.exchangeService.reject(),
      forward: rateToken
        ? () => {
            this.tracker.reportProgress({
              action: "confirm",
              data: { rate: `${summary.rate}`, token: rateToken },
            });
            this.exchangeService.confirm(rateToken, summary);
          }
        : () => this.formService.submit({ variation }),
    };
  }
}

const mapToSummary =
  (
    rateData: ExchangeRateSuccess,
    form: SwapExchangeForm,
    subtype?: "historicalroll" | "marketroll"
  ) =>
  (accounts: Account[], collateralAccounts: Account[], currencies: Currency[]) => {
    const currency = getCurrencyByCode(currencies, form.currency);
    const counterCurrency = getCurrencyByCode(currencies, form.counterCurrency);

    assertIsDefined(currency);
    assertIsDefined(counterCurrency);

    const { rate, token, counterAmount, farRate = 0, farCounterAmount = 0 } = rateData;

    const nearLeg: ExchangeQuote = {
      side: form.side,
      amount: form.amount,
      currency,
      counterCurrency,
      pair: form.currencyPair,
      settlement: form.nearSettlement,
      rate,
      token,
      counterAmount,
      profitCurrency: form.counterCurrency,
    };

    const farLeg: ExchangeQuote = {
      side: reverseSide(form.side),
      amount: form.amount,
      currency,
      counterCurrency,
      pair: form.currencyPair,
      settlement: form.farSettlement,
      rate: farRate,
      token,
      counterAmount: farCounterAmount,
      profitCurrency: form.counterCurrency,
    };

    if (isRoll(form)) {
      const closingLeg =
        form.original.settlementDate === nearLeg.settlement.date ? nearLeg : farLeg;
      closingLeg.netSettlementAmount = rateData.netSettlementAmount ?? 0;
    }

    return {
      ...form,
      ...rateData,
      counterCurrency, // required for MarginInfo
      nearLeg,
      farLeg,
      isRoll: isRoll(form),
      subtype: (() => {
        if (!subtype) {
          return undefined;
        }

        const ob = farLeg.netSettlementAmount !== undefined ? "Rollback" : "Rollover";
        return `${subtype === "historicalroll" ? "Historical" : "Market"}${ob}`;
      })(),
      account: getAccountByNumber(accounts, form.account),
      counterAccount: getAccountByNumber(accounts, form.counterAccount),
      collateral: form.collateral && {
        type: form.collateral.type,
        amount: rateData.collateralAmount,
        account: getAccountByNumber(collateralAccounts, form.collateral.account),
      },
      profitCurrency: counterCurrency.code,
    } as SwapSummary;
  };

const isRoll = (form: CommonExchangeForm): form is RollExchangeForm =>
  !!(form as RollExchangeForm).original;
