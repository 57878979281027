import { Component, HostBinding, Input } from "@angular/core";
import { Collateral } from "../collateral-model";

@Component({
  selector: "app-summary-collateral",
  templateUrl: "summary-collateral.component.html",
})
export class SummaryCollateralComponent {
  @HostBinding("class") class = "pko-details__content-section";
  @Input() collateral!: Collateral;
}
