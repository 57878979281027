<ng-container
  [formGroup]="form"
  *ngIf="{ showSimple: showSimple$ | async, breakpoint: currentBreakpoint$ | async } as vm">
  <app-currency-pair
    class="pko-indicative__currency-pair"
    [class.pko-indicative__currency-pair--pro]="!vm.showSimple"
    [code]="pair.code"></app-currency-pair>
  <app-indicative-chart
    class="pko-indicative__chart"
    [pair]="pair"
    [data$]="graph$"
    *ngIf="
      (vm.breakpoint | breakpoint: 'xs') || (!vm.showSimple && (vm.breakpoint | breakpoint: 'sm'))
    "></app-indicative-chart>
  <app-ticking-rate
    class="pko-indicative__rate"
    [rate]="sell$ | async"
    side="Sell"
    (clicked)="openDeal($event)"></app-ticking-rate>
  <app-ticking-rate
    class="pko-indicative__rate"
    [rate]="buy$ | async"
    side="Buy"
    (clicked)="openDeal($event)"></app-ticking-rate>
  <app-select
    class="pko-indicative__select"
    formControlName="tenor"
    [items]="tenors"
    translateText="tenor"
    *ngIf="!vm.showSimple && !(vm.breakpoint | breakpoint: 'xs')"
    ngDefaultControl></app-select>
  <div
    class="pko-indicative__break"
    *ngIf="!vm.showSimple && (vm.breakpoint | breakpoint: 'xs')"></div>
  <p class="pko-indicative__tenor" *ngIf="!vm.showSimple && (vm.breakpoint | breakpoint: 'xs')">
    {{ "Tenor" | translate }}:
    <b>
      {{ "tenor." + tenor | translate }}
    </b>
  </p>
</ng-container>
