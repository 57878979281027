import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { AuthComponentData } from "@features/auth/auth.model";
import { AuthService } from "@features/auth/auth.service";
import { AuthComponent } from "@features/auth/components/auth.component";
import { SurveyOperationResult, SurveyResult } from "@features/mifid/mifid.model";
import { MifidService } from "@features/mifid/mifid.service";
import { ModalService } from "@features/modal/modal.service";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { ActivationApiService } from "./activation-api.service";
import { Application } from "./activation.model";

@Injectable({ providedIn: "root" })
export class ApplicationActionsService {
  constructor(
    private modalService: ModalService,
    private service: ActivationApiService,
    private mifidService: MifidService,
    private authService: AuthService,
    private applicationEvents: AppEventsService
  ) {}

  cancel(application: Application) {
    const modal = this.modalService.dialog({
      title: "activation.CancelApplication",
      body: "activation.CancelApplicationMessage",
      buttons: {
        primary: { text: "yesno.true", resultOnClose: true },
        secondary: { text: "yesno.false", resultOnClose: false },
      },
    });

    modal.result.then((cancel) => {
      if (!cancel) return;

      this.service.cancel(application.number).subscribe((success) => {
        application.cancelVisible = false;
        this.modalService.dismissAll();
        this.modalService.dialog({
          body: success ? "activation.ApplicationCancelled" : "errors.GENERIC",
          type: success ? "success" : "failure",
        });
        this.applicationEvents.emit(AppEvent.ApplicationAction);
      });
    });
  }

  startSigningDialog(
    requiresMifid: boolean,
    otherAttorneyResult: boolean,
    applicationId: string,
    surveyResult?: SurveyResult
  ) {
    this.modalService.modal({
      component: AuthComponent,
      data: {
        modalConfig: { title: "activation.Authorize" },
        prepare$: this.authService.prepareApplicationAuthorization({
          applicationId,
          surveyResult,
          otherAttorneyResult,
          requiresMifid,
        }),
        onResult: (result: SurveyOperationResult) => this.showSignResult(result),
        authorize: (code: string) => this.#authorize(code, applicationId),
        mobileAuthorize: () => this.#mobileAuthorize(),
      } as AuthComponentData,
    });
  }

  #authorize(code: string, applicationId: string) {
    return this.service.sign(code, applicationId);
  }

  #mobileAuthorize() {
    return this.service.mobileSign();
  }

  showSignResult(result: SurveyOperationResult) {
    if (result.alreadyRejected) return this.handleRejection(result);
    if (!result.success) return this.#showError();
    this.#showSucces();
    this.applicationEvents.emit(AppEvent.ApplicationAction);
  }

  #showSucces() {
    this.modalService.dialog({
      title: "activation.ApplicationSigned",
      type: "success",
      buttons: { primary: defaultPrimary },
    });
  }

  #showError() {
    this.modalService.dialog({
      title: "activation.ApplicationNotSigned",
      type: "failure",
      buttons: { primary: defaultPrimary },
    });
  }

  rejectColleagueDecision(applicationId: string) {
    this.modalService.dialog(
      {
        body: "mifid.mifidAction.RejectConfirmation",
        buttons: {
          primary: {
            text: "yesno.true",
            onClick: () => {
              this.mifidService
                .rejectSurvey(applicationId)
                .subscribe((result) => this.handleRejection(result));
            },
          },
          secondary: {
            text: "buttons.Cancel",
          },
        },
      },
      "modal"
    );
  }

  handleRejection(result: SurveyOperationResult) {
    this.modalService.dialog(
      {
        body: !result.alreadyRejected
          ? "activation.ApplicationCancelled"
          : "mifid.mifidAction.MifidSurveyRejectedText",
        type: !result.alreadyRejected ? "success" : "info",
        buttons: {
          primary: {
            text: "OK",
            onClick: () => {
              this.applicationEvents.emit(AppEvent.ApplicationAction);
            },
          },
        },
      },
      "modal"
    );

    this.applicationEvents.emit(AppEvent.ApplicationAction);
  }

  acceptColleagueDecision(applicationId: string) {
    this.modalService.dialog(
      {
        title: "mifid.mifidAction.SurveyAccepted",
        body: "mifid.mifidAction.ContinueToContractSigning",
        buttons: {
          primary: {
            text: "buttons.Next",
            onClick: () => this.startSigningDialog(true, true, applicationId),
          },
        },
      },
      "modal"
    );
  }
}
