import { DOCUMENT, DecimalPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { ModalService } from "@features/modal/modal.service";
import { DatePkoPipe } from "@shared/pipes";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import { delay, from, map, pluck, switchMap } from "rxjs";
import { BlotterCurrency, GraphModel } from "./blotter-graph.model";
import { BlotterGraphService } from "./blotter-graph.service";
import { BlotterSettingsComponent } from "./settings/blotter-settings.component";

@Component({
  selector: "app-blotter-graph",
  templateUrl: "./blotter-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlotterGraphComponent {
  protected Highcharts = Highcharts;

  constructor(
    private service: BlotterGraphService,
    private preferences: PreferencesService,
    private modalService: ModalService,
    private balance: DecimalPipe,
    private date: DatePkoPipe,
    @Inject(DOCUMENT) document: Document
  ) {
    this.setup();
  }

  currencies$ = this.preferences.blotterGarph$.pipe(
    pluck("currencies"),
    map((currencies) => currencies?.sort())
  );
  graph$ = this.currencies$.pipe(
    switchMap((currencies) => this.service.get(currencies)),
    map((data) =>
      !data?.length
        ? null
        : ({
            Highcharts: Highcharts,
            chartOptions: this.getData(data),
            oneToOneFlag: true,
            update: true,
            runOutsideAngularFlag: false,
          } as GraphModel)
    ),
    switchMap((data) => from([null, data]).pipe(delay(0)))
  );

  getData(data: BlotterCurrency[]) {
    const balancePipe = this.balance;
    const datePipe = this.date;
    const tooltip: any = {
      formatter: function () {
        return `<div class="highcharts-label highcharts-tooltip">
            <span><span style="font-size: 10px">${this.x}</span><br /><span style="color: ${
          this.color
        }">●</span> ${this.series.name}:
              <b>${balancePipe.transform(this.point.low, "1.2-2")}</b> ; <b>${balancePipe.transform(
          this.point.high,
          "1.2-2"
        )}</b><br
            /></span>
          </div>`;
      },
      useHTML: true,
    };

    const dataLabels: any = {
      color: "#000",
      formatter: function () {
        return balancePipe.transform(this.y, "1.2-2");
      },
    };

    return {
      credits: {
        enabled: false,
      },

      chart: {
        type: "columnrange",
        inverted: false,
        height: 400,
        width: Math.max(
          (document.getElementById("recent-history-section")?.offsetWidth ?? 0) - 40,
          120 + data[0].dates.length * 80
        ),
      },

      title: {
        text: "",
      },

      yAxis: {
        title: "",
        //type: "logarithmic",
        custom: {
          allowNegativeLog: true,
        },
      },

      xAxis: {
        categories: data[0].dates.map((x) => datePipe.transform(x.date)),
      },

      plotOptions: {
        columnrange: {
          dataLabels: {
            enabled: true,
          },
        },
      },

      tooltip: tooltip,

      legend: {
        enabled: false,
      },

      series: data.map((x, i) => ({
        name: x.currency,
        data: x.dates.map((x) => x.values),
        color: blotterColors[i],
        dataLabels: dataLabels,
      })),
    };
  }

  selectCurrencies() {
    this.modalService.modal({
      component: BlotterSettingsComponent,
      scrollable: true,
    });
  }

  setup() {
    more(Highcharts);

    (function (H: any) {
      const setup: any = {
        setLogarithmic: function () {
          if (this.isLog && this.options.custom.allowNegativeLog) {
            // Avoid errors on negative numbers on a log axis
            this.positiveValuesOnly = false;

            // Override the converter functions
            this.log2lin = this.val2lin = (num: any) => {
              const isNegative = num < 0;

              let adjustedNum = Math.abs(num);

              if (adjustedNum < 10) {
                adjustedNum += (10 - adjustedNum) / 10;
              }

              const result = Math.log(adjustedNum) / Math.LN10;
              return isNegative ? -result : result;
            };

            this.lin2log = this.lin2val = (num: any) => {
              const isNegative = num < 0;

              let result = Math.pow(10, Math.abs(num));
              if (result < 10) {
                result = (10 * (result - 1)) / (10 - 1);
              }
              return isNegative ? -result : result;
            };
          }
        },
      };

      const oldInit = H.Axis.prototype.init;
      H.Axis.prototype.init = function (...args: any[]) {
        oldInit.call(this, args[0], args[1]);
        setup.setLogarithmic.call(this);
      };
    })(Highcharts);
  }
}

const blotterColors = ["#00468c", "#e4202c", "#8a8a8a", "#ac8121", "#000000"];
