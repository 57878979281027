<ng-container [formGroup]="group">
  <app-form-control [inputGroup]="true">
    <div class="pko-grid__row pko-grid__row--12">
      <app-datepicker
        class="col-12 col-sm-6"
        label="ExpirationDate"
        formControlName="date"
        [max]="max"
        ngDefaultControl>
      </app-datepicker>
      <app-input-text
        class="col-12 col-sm-6"
        label="ExpirationTime"
        formControlName="time"
        validate="time"
        ngDefaultControl></app-input-text>
    </div>
  </app-form-control>
</ng-container>
