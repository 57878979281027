<ng-container *ngIf="!isEditing; else editing">
  <app-flow-declaration-summary
    [declaration]="declaration"
    (updated)="onUpdated()"
    [allowRefuse]="false">
  </app-flow-declaration-summary>
</ng-container>

<ng-template #editing>
  <app-flow-declaration-edit
    [declaration]="declaration"
    [currencies]="flowsDeclaration.currencies"
    [allowRefuse]="true"
    (saved)="onSaved($event)"
    (canceled)="onCanceled()"></app-flow-declaration-edit>
</ng-template>
