import {
  BlotterGraph,
  Customers,
  Dashboard,
  DealHistory,
  Deposit,
  DepositControl,
  Exchange,
  Graph,
  Indicatives,
  InvestmentDeposit,
  Language,
  PhonePreference,
  Preferences,
  Swap,
  TransactionControl,
} from "./preferences.model";

const languageDefaults = { code: "pl" } as Language;

const dashboardDefaults: Dashboard = {
  shortcuts: ["NewDeal", "NewOrder", "NewInvDeposit", "NewDpw", "NewDeposit", "PremiumOffers"],
  simple: true,
  tilesSelection: {
    left: ["deal", "news"],
    right: ["notifications", "investmentDeposit", "transactions"],
  },
};

const exchangeDefaults: Exchange = {
  side: "Sell",
  amount: undefined,
  currency: "EUR",
  counterCurrency: "PLN",
  tenor: "TOD",
};

const depositDefaults: Deposit = {
  amount: undefined,
  currency: "PLN",
  startTenor: "ON",
  endTenor: "1D",
  showLPREMessage: true,
};

const swapDefaults: Swap = {
  side: "Sell",
  amount: undefined,
  currency: "EUR",
  counterCurrency: "PLN",
  nearTenor: "TOD",
  farTenor: "SN",
};

const historyDefaults: DealHistory = {
  order: "TransactionDate",
  openBalance: true,
  unsettled: true,
};

const phoneDefaults: PhonePreference = {
  prefix: "+48",
  number: undefined,
};

const graphDefaults: Graph = {
  side: "Sell",
  period: 0,
  type: "linear",
};

const investmentDepositDefaults: InvestmentDeposit = {
  amount: 0,
  currency: "",
  currencyPair: "",
  tenor: "",
};

const transactionControlDefaults: TransactionControl = {} as TransactionControl;

const depositControlDefaults: DepositControl = {} as DepositControl;

const premiumDepositControlDefaults: DepositControl = {} as DepositControl;

const investmentDepositControlDefaults: DepositControl = {} as DepositControl;

const indicativesDefaults: Indicatives = { pollingEnabled: true, tenor: "TOD" };

const blotterGraphDefaults: BlotterGraph = { currencies: [] };

const customersDefautls: Customers = { pinned: [] };

export const defaults: Preferences = {
  language: languageDefaults,
  dashboard: dashboardDefaults,
  exchange: exchangeDefaults,
  deposit: depositDefaults,
  swap: swapDefaults,
  transactionControl: transactionControlDefaults,
  depositControl: depositControlDefaults,
  premiumDepositControl: premiumDepositControlDefaults,
  investmentDepositControl: investmentDepositControlDefaults,
  indicatives: indicativesDefaults,
  dealHistory: historyDefaults,
  phone: phoneDefaults,
  graph: graphDefaults,
  blotterGraph: blotterGraphDefaults,
  customers: customersDefautls,
  investmentDeposit: investmentDepositDefaults,
};
