import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { HolidaysService } from "@features/currency/holidays.service";
import { TenorDate, TenorDateConfig, isNearTenor } from "@features/tenor";
import { TransactionType } from "@features/transaction/models";
import { difference } from "@utils/time";
import {
  EMPTY,
  Observable,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  of,
  pluck,
  startWith,
  switchMap,
} from "rxjs";

@Component({
  selector: "app-form-date-bounds",
  templateUrl: "date-bounds.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DateBoundsComponent implements OnInit {
  @Input() getTenorDates!: (tenor: string) => Observable<TenorDateConfig>;
  @Input() endTenorPlaceholder?: string;
  @Input() disableSingleValue = true;
  @Input() plnHolidaysOnly = false;
  @Input() transactionType!: TransactionType;
  tenorDates$: Observable<SplitTenorDates> = EMPTY;
  holidays$: Observable<string[]> = EMPTY;
  duration$: Observable<number> = EMPTY;

  constructor(private parent: FormGroupDirective, private holidayService: HolidaysService) {}

  get #controls() {
    return this.parent.form.controls;
  }

  ngOnInit() {
    const { start, end, currency } = this.#assertForm();

    const start$ = start.valueChanges.pipe(startWith(start.value));
    const end$ = end.valueChanges.pipe(startWith(end.value));

    this.tenorDates$ = start$.pipe(
      pluck("tenor"),
      filter(Boolean),
      // without `distinct` it fires wildly on touching the dropdown
      distinctUntilChanged(),
      switchMap(this.getTenorDates),
      map(splitDepositTenorDates),
      delay(0)
    );

    const currency$ = this.plnHolidaysOnly
      ? of("PLN")
      : currency.valueChanges.pipe(startWith(currency.value));
    this.holidays$ = this.holidayService.getHolidaysByCurrency(currency$);

    this.duration$ = combineLatest([start$.pipe(pluck("date")), end$.pipe(pluck("date"))]).pipe(
      map(getDuration),
      startWith(0)
    );
  }

  #assertForm() {
    const { currency, start, end } = this.#controls;

    if (!currency || !start || !end) {
      throw new Error(
        "This component needs 'currency', 'start' and 'end' controls present on the form!"
      );
    }

    return { currency, start, end };
  }
}

const splitDepositTenorDates = (tenorDates: TenorDateConfig) => ({
  near: {
    values: tenorDates.values.filter(({ tenor }: TenorDate) => isNearTenor(tenor)),
    min: tenorDates.min,
    max: tenorDates.max,
  },
  far: {
    values: tenorDates.values.filter(({ tenor }: TenorDate) => !isNearTenor(tenor)),
    min: tenorDates.min,
    max: tenorDates.max,
  },
});

const getDuration = ([startDate, endDate]: [string, string]) =>
  startDate && endDate ? difference(new Date(endDate), new Date(startDate), "days") : 0;

export interface SplitTenorDates {
  near: TenorDateConfig;
  far: TenorDateConfig;
}
