import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { ActionItem } from "@core/menus/action-item";
import { Observable } from "rxjs";
import { MultiFxLegForm } from "../../models/multifx-form";

@Component({
  selector: "[multiFxLeg]",
  templateUrl: "./form-multifx-transaction-leg-row.component.html",
})
export class FormMultifxTransactionLegRowComponent {
  @Input() multiFxLeg!: MultiFxLegForm;
  @Input() errors?: ValidationErrors | null;
  @Input() index!: number;
  @Input() currency!: Observable<string>;
  @Input() stack = false;

  @Output() deleted = new EventEmitter<void>();
  @Output() edited = new EventEmitter<void>();

  menu: ActionItem[] = [
    {
      text: "buttons.Edit",
      callback: () => this.edited.emit(),
    },
    {
      text: "buttons.Delete",
      callback: () => this.deleted.emit(),
    },
  ];
}
