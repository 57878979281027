<div class="form-radio">
  <input
    class="form-radio-input"
    type="radio"
    [value]="value"
    [formControl]="control"
    [id]="id"
    [name]="name ?? ''" />
  <label class="form-radio-label" *ngIf="!!label" [for]="id">
    {{ translateLabel ? (label | translate) : label }}
  </label>
</div>
