import { Component, OnDestroy, OnInit } from "@angular/core";
import { ControlContainer, FormArray, FormGroupDirective } from "@angular/forms";
import { ModalService } from "@features/modal/modal.service";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { Subscription, filter, map, startWith, tap } from "rxjs";
import { Action, mapToAction, updateSideControls } from "../../utils/par-forward-utils";

@Component({
  selector: "app-form-multifx-parforward",
  templateUrl: "./form-multifx-parforward.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormMultifxParforwardComponent implements OnInit, OnDestroy {
  #subscription = new Subscription();

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective, private modal: ModalService) {}

  ngOnInit() {
    const { series, parForward } = this.#assertForm();

    const parForward$ = parForward.valueChanges.pipe(
      startWith(parForward.value),
      filter((value) => value !== "on"),
      map((isParForward) => mapToAction(series, isParForward)),
      filter((action) => action.shouldUpdate),
      tap(updateSideControls),
      tap((action) => this.#showWarningDialog(action))
    );

    this.#subscription.add(parForward$.subscribe());
  }

  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  #showWarningDialog({ side }: Action) {
    this.modal.dialog({
      title: "modals.parForward.Title",
      body: { key: `modals.parForward.Description${side}` },
      buttons: { primary: defaultPrimary },
    });
  }

  #assertForm() {
    const { series, parForward } = this.#controls;

    if (!series || !parForward || !(series instanceof FormArray)) {
      throw new Error(
        "This component needs 'series' FormArray and 'parForward' controls present on the form!"
      );
    }

    return { series, parForward };
  }
}
