import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { reverseSide, Side } from "@core/models/transaction";
import { NonForwardTenor } from "@features/tenor";
import { baseCurrency } from "@shared/utils/currency";
import { formatNumber } from "@shared/utils/format";
import { assertIsDefined } from "@utils/misc";
import {
  combineLatest,
  debounceTime,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  startWith,
  switchMap,
} from "rxjs";
import { LiveRate, LiveRateService } from "./live-rate.service";

@Component({
  selector: "app-form-live-rate",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  template: `<div class="mb-3" *ngIf="currentRate$ | async as rate">
    {{ "LiveRate" | translate }}{{ tenor ? " " + tenor : "" }}: {{ rate }}
  </div>`,
})
export class LiveRateFormComponent implements OnInit {
  @Input() tenor?: NonForwardTenor;
  currentRate$: Observable<string> = EMPTY;

  constructor(private parent: FormGroupDirective, private rate: LiveRateService) {}

  get #controls() {
    return this.parent.form.controls;
  }

  ngOnInit() {
    const { side, currency, currencyPair, tenor } = this.#assertForm();

    const tenor$ = typeof tenor === "string" ? of(tenor) : tenor?.valueChanges;
    const currency$: Observable<string> = currency.valueChanges.pipe(startWith(currency.value));
    const side$: Observable<Side> = side.valueChanges.pipe(startWith(side.value));
    const pair$: Observable<string> = currencyPair.valueChanges.pipe(
      startWith(currencyPair.value),
      filter(Boolean)
    );

    this.currentRate$ = combineLatest([side$, currency$, pair$, tenor$]).pipe(
      debounceTime(100),
      switchMap(([side, currency, pair, tenor]) =>
        this.rate.fetch({ pair, tenor }).pipe(map(toRate(side, pair, currency)))
      )
    );
  }

  #assertForm() {
    const { side, currency, currencyPair } = this.#controls;
    // static input has priority
    const tenor = this.tenor ?? this.#controls.tenor;

    assertIsDefined(side);
    assertIsDefined(tenor);
    assertIsDefined(currency);
    assertIsDefined(currencyPair);
    return { side, currency, currencyPair, tenor };
  }
}

const toRate = (side: Side, pair: string, currency: string) => (rate: LiveRate) => {
  const value = rate[normalizeSide(side, pair, currency)];
  return value
    ? formatNumber(value, {
        style: "pair",
        code: pair,
        decimals: 4,
      })
    : "";
};

const normalizeSide = (formSide: Side, pair: string, currency: string) => {
  const side = currency === baseCurrency(pair) ? formSide : reverseSide(formSide);
  return side.toLowerCase() as Lowercase<Side>;
};
