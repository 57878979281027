import { NgModule } from "@angular/core";
import { AccountsModule } from "@features/accounts/accounts.module";
import { CurrencyPairComponent } from "@features/currency/components/currency-pair/currency-pair.component";
import { SharedModule } from "@shared/shared.module";
import { AmountCurrenciesFormComponent } from "./components/form-amount-currencies/amount-currencies.component";
import { AmountCurrencyPairFormComponent } from "./components/form-amount-currency-pair/form-amount-currency-pair.component";
import { AmountCurrencyFormComponent } from "./components/form-amount-currency/amount-currency.component";
import { FormCurrencyPairComponent } from "./components/form-currency-pair/form-currency-pair.component";
import { AmountLeftInputComponent } from "./components/input-amount-left/input-amount-left.component";

@NgModule({
  imports: [SharedModule, AccountsModule],
  declarations: [
    AmountCurrencyFormComponent,
    AmountCurrenciesFormComponent,
    AmountLeftInputComponent,
    CurrencyPairComponent,
    FormCurrencyPairComponent,
    AmountCurrencyPairFormComponent,
  ],
  exports: [
    AmountCurrencyFormComponent,
    AmountCurrenciesFormComponent,
    AmountLeftInputComponent,
    CurrencyPairComponent,
    FormCurrencyPairComponent,
    AmountCurrencyPairFormComponent,
  ],
})
export class CurrencyModule {}
