<ng-container #mifidWarnings></ng-container>

<ng-container *ngIf="mifidValidation$ | async; else loading">
  <ng-container *ngIf="errorMessage$ | async as message">
    <app-tile-message [data]="message" *ngIf="message"> </app-tile-message>
  </ng-container>

  <ng-container *ngIf="showForm">
    <p [class]="classes.infotext">
      {{ "exchange.info.forward" | translate }}
    </p>
    <app-alert
      *ngIf="warningService.outOfTargetMarket"
      icon="critical"
      content="alert.Content.OutOfTargetMarket"
      context="danger">
    </app-alert>
    <form class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="pko-grid__row">
        <app-form-side [class]="classes.side"></app-form-side>
        <div class="w-100"></div>

        <fx-form-amount-currency-pair [class]="classes.currencyPair"></fx-form-amount-currency-pair>
        <div class="w-100"></div>

        <ng-container *ngTemplateOutlet="isTile ? modalTenors : formTenors"></ng-container>

        <ng-template #modalTenors>
          <app-form-tenor-date-product class="col-12 col-sm-8" minTenor="SN">
          </app-form-tenor-date-product>

          <app-input-check
            label="exchange.NetSettlement"
            formControlName="netSettlement"
            class="col-12 col-sm-4 mt-sm-4"
            ngDefaultControl>
          </app-input-check>
          <app-form-pvp class="col-10"></app-form-pvp>
        </ng-template>

        <ng-template #formTenors>
          <app-form-tenor-date-product class="col-10 col-sm-7 col-md-5 col-lg-4" minTenor="SN">
          </app-form-tenor-date-product>
          <app-form-pvp class="col-10"></app-form-pvp>

          <app-input-check
            label="exchange.NetSettlement"
            formControlName="netSettlement"
            class="col-10"
            ngDefaultControl>
          </app-input-check>
        </ng-template>

        <app-form-accounts [class]="classes.accounts"></app-form-accounts>
        <div class="w-100"></div>

        <app-form-collateral formGroupName="collateral" [class]="classes.collateral">
        </app-form-collateral>
      </div>

      <p
        class="pko-infotext"
        [innerHtml]="'exchange.info.risk.forward' | translate : { link: link }"></p>
      <app-form-footer [back]="{ hidden: isTile, onClick: back }"></app-form-footer>
    </form>

    <ng-container *ngIf="!isTile">
      <pre *ifIsDevMode>{{ form.getRawValue() | json }}</pre>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div pko-preloader></div>
</ng-template>
