<app-labeled-text-inline label="deposit.InterestAmount">
  {{ interestAmount | number : "1.2-2" | nullable }} {{ currency }}
</app-labeled-text-inline>

<app-labeled-text-inline label="deposit.MaturityAmount">
  {{ maturityAmount | number : "1.2-2" | nullable }} {{ currency }}
</app-labeled-text-inline>

<app-labeled-text-inline label="EndDate">
  {{ quote.end.date | datePko }}
</app-labeled-text-inline>

<hr />

<app-labeled-text-inline label="deposit.Rate">
  <h4>{{ rate | number : "1.2-2" | nullable }} %</h4>
</app-labeled-text-inline>
