import { Component, Host, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { Boundary } from "@core/models";
import { Nullable, assertIsDefined } from "@utils/misc";
import { EMPTY, Observable, Subscription, startWith } from "rxjs";
import { resetFieldWhenEmpty } from "../../history-common.utils";

@Component({ template: "" })
export class RangeFormComponent<T> implements OnInit, OnDestroy {
  /** Group label */
  @Input() label?: string;
  @Input() labelFrom?: string;
  @Input() labelTo?: string;
  @Input() placeholder?: Placeholder;
  @Input() fromDefault?: string;
  @Input() toDefault?: string;

  #subscription?: Subscription;
  current$: Observable<RangeForm<T>> = EMPTY;

  get group() {
    return this.parent.control as FormGroup;
  }

  constructor(@Host() private parent: ControlContainer) {}

  ngOnInit() {
    this.#assertForm();
    const { from, to } = this.group.controls;
    this.current$ = this.group.valueChanges.pipe(startWith(this.group.value));
    this.#subscription = resetFieldWhenEmpty(from, this.fromDefault).subscribe();
    this.#subscription.add(resetFieldWhenEmpty(to, this.toDefault).subscribe());
  }

  ngOnDestroy() {
    this.#subscription?.unsubscribe();
  }

  #assertForm() {
    assertIsDefined(this.group.controls.from);
    assertIsDefined(this.group.controls.to);
  }
}

type Placeholder = { from?: string; to?: string };
type RangeForm<T> = Nullable<Boundary<T>>;
