import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Failure } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { ModalService } from "@features/modal/modal.service";
import { SimilarDealsWarningComponent } from "@features/transaction/components/similar-deals-warning/similar-deals-warning.component";
import { TransactionType } from "@features/transaction/models";
import { isStatusSuccess, StatusResponse } from "@features/transaction/models/confirmation";
import {
  isInitializationSuccess,
  isInitializationWarning,
} from "@features/transaction/models/initialization";
import { isRateFailure } from "@features/transaction/models/rate";
import { TransactionResponseService } from "@features/transaction/services/response.service";
import { getWarningDialog } from "@features/transaction/utils/dialog";
import { BodyLine } from "@shared/components/modal/modal-model";
import { formatNumber } from "@shared/utils/format";
import { DepositRateResponse } from "../models/deposit-rate";
import { ConfirmationData } from "../models/deposit-summary";
import { InitializationResponseWrapper } from "../models/initialization";

@Injectable({ providedIn: "root" })
export class DepositResponseService {
  constructor(
    private transaction: TransactionResponseService,
    private tracker: Tracker,
    private modal: ModalService,
    private router: Router
  ) {}

  async handleInitResponse(
    wrappedResponse: InitializationResponseWrapper,
    type: TransactionType = "deposit"
  ) {
    const { response, resubmit } = wrappedResponse;
    if (isInitializationSuccess(response)) {
      type == "deposit"
        ? this.transaction.decide(type, response.token)
        : this.transaction.decide(type);
      return;
    }

    if (isInitializationWarning(response)) {
      const consents = await this.#onWarnings(response.warnings);
      if (!consents) return;

      return resubmit(consents);
    }

    this.handleError(response.error, type);
  }

  handleRateError(rateResponse: DepositRateResponse) {
    if (!isRateFailure(rateResponse)) return;

    this.#onError(rateResponse.error);
  }

  handleError(error?: Failure, type: TransactionType = "deposit") {
    if (type === "premiumDeposit") {
      const accessEnabled = error?.data?.["accessEnabled"] ?? "true";
      return this.#onError(error, accessEnabled === "true");
    }
    this.#onError(error);
  }

  handleStatusResponse(
    response: StatusResponse,
    summary: ConfirmationData,
    type: TransactionType = "deposit"
  ) {
    isStatusSuccess(response)
      ? this.#onSuccess(response.transactionId, summary)
      : this.handleError(response.status, type);
  }

  #onSuccess(id: string, { currency, amount: amountValue, rate: rateValue }: ConfirmationData) {
    const amount = formatNumber(+amountValue, { style: "currency", ...currency });
    const rate = formatNumber(+rateValue, { decimals: 2 });

    const bodyLines: BodyLine[] = [
      { key: "deposit.success.Amount", params: { amount } },
      { key: "deposit.success.Rate", params: { rate } },
    ];

    this.tracker.reportProgress({
      action: actions.SUCCESS,
      data: { id },
    });
    this.transaction.success({ source: "deposit", bodyLines, id });
  }

  #onError(error?: Failure, retry?: boolean) {
    retry = retry ?? true;
    this.tracker.reportProgress({ action: actions.FAILURE, data: { error: error?.code ?? "" } });
    this.transaction.error({
      source: "deposit",
      error,
      buttons: retry
        ? undefined
        : {
            primary: {
              text: "buttons.home",
              onClick: () => this.router.navigateByUrl("/", { replaceUrl: true }),
            },
          },
    });
  }

  /**
   * Displays warning dialogs in sequence, if user accepts.
   * @param warnings Warnings to display.
   * @returns If all warnings are accepted, returns their codes. Otherwise `undefined`.
   */
  async #onWarnings(warnings: Failure[]) {
    for (const { code, data } of warnings) {
      const dialog = getWarningDialog(code, "deposit", data);

      const accepted = await (this.#specialWarnings[code]
        ? this.modal.modal({
            component: this.#specialWarnings[code],
            data: { ...dialog, transactionType: "deposit" },
          })
        : this.modal.dialog(dialog)
      ).result // a dismissed modal calls Promise.reject()
        .catch(() => false);

      this.tracker.reportProgress({
        action: actions.WARNING,
        data: { code, accepted: accepted ?? false },
      });

      if (!accepted) return;
    }

    return warnings.map(({ code }) => code);
  }

  #specialWarnings: Record<string, any> = {
    HasSimilarDeal: SimilarDealsWarningComponent,
  };
}
