import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getCookie } from "@utils/cookie";
import { interval, skipWhile, Subject, timer } from "rxjs";
import { map, shareReplay, startWith, switchMap, takeWhile, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SessionService {
  private _expiration = new Subject<number>();
  #sessionTimeout = new Subject<number>();
  #lastSessionExpiration = 0;

  sessionExpiration$ = interval(1000).pipe(
    map(() => parseInt(getCookie("sessionExpiration"))),
    skipWhile((expiration) => isNaN(expiration)),
    map((session) => Math.floor((session - new Date().getTime()) / 1000)),
    tap((session) => session > this.#lastSessionExpiration && this.#sessionTimeout.next(session)),
    tap((session) => (this.#lastSessionExpiration = session)),
    takeWhile((session) => session >= 0),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  constructor(private http: HttpClient) {
    //observable które często emitują są zatrzymywane przez przeglądarkę na nieaktywnych kartach
    //stąd rozdzielenie: odliczanie intervalem co 1s, wylogowanie na jeden timeout żeby wykonał się
    //nawet w nieaktywnej karcie
    this.#sessionTimeout.pipe(switchMap((expiration) => timer(expiration * 1e3))).subscribe(() => {
      window.location.href = "/logout";
    });
  }

  initialize(tokenExpiration: number): void {
    this._expiration
      .pipe(
        startWith(tokenExpiration),
        switchMap((expiration) => timer(expiration * 6e4)),
        switchMap(() => this.http.post<number>("/login/extend", null)),
        tap((expiration) => this._expiration.next(expiration)),
        takeWhile((expiration) => expiration > 0)
      )
      .subscribe();

    this.sessionExpiration$.subscribe();
  }
}
