import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Customer } from "@core/models/customer.model";
import { ModalService } from "@features/modal/modal.service";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { catchError, of, tap } from "rxjs";
import { AppEvent, AppEventsService } from "../events/app-events.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  readonly customers$ = this.applicationEvents.observe(
    () => this.http.get<Customer[]>("/customers").pipe(catchError(() => of([] as Customer[]))),
    AppEvent.ApplicationProcessFinished
  );

  constructor(
    private http: HttpClient,
    private applicationEvents: AppEventsService,
    private modalService: ModalService
  ) {}

  select({ id }: Customer) {
    return this.http.post("/customers", JSON.stringify(id));
  }

  set(customer: Customer, navigationCommand: unknown[]) {
    return this.select(customer).pipe(
      tap(() => (window.location.href = navigationCommand.join("/")))
    );
  }

  getShortName({ id, name }: Customer) {
    return this.http.get(`/customers/getShortName/${id}`, { responseType: "text" }).pipe(
      tap((shortName: string) => {
        this.modalService.dialog({
          body: shortName
            ? {
                key: "activation.ShortName",
                params: { name, shortName },
              }
            : "activation.MissingShortName",
          buttons: { primary: defaultPrimary },
          type: "info",
        });
      })
    );
  }
}
