import { Side } from "@core/models/transaction";
import { CollateralForm } from "@features/collateral";
import { TenorDateForm } from "@features/tenor";

export interface MultiFxForm {
  currencyPair: string;
  currency: string;
  counterCurrency: string;
  account?: string;
  counterAccount?: string;
  series: MultiFxLegForm[];
  pvp: boolean;
  parForward: boolean;
  shortCollateral?: CollateralForm;
  forwardCollateral?: CollateralForm;
  isDpw: boolean;
}

export interface MultiFxLegForm {
  clientSideId: string;
  amount: number;
  side: Side;
  settlement: TenorDateForm;
  product: MultiFxProductType;
  dpwNumber?: string;
}

export const MULTIFX_PRODUCT_TYPES = {
  overnight: "ON",
  short: "Short",
  spot: "Spot",
  forward: "Forward",
} as const;

export const multiFxProductsByTenor = {
  NOW: MULTIFX_PRODUCT_TYPES.overnight,
  TOD: MULTIFX_PRODUCT_TYPES.overnight,
  TOM: MULTIFX_PRODUCT_TYPES.short,
  SPOT: MULTIFX_PRODUCT_TYPES.spot,
  _: MULTIFX_PRODUCT_TYPES.forward,
} as const;

export type MultiFxProductType = (typeof MULTIFX_PRODUCT_TYPES)[keyof typeof MULTIFX_PRODUCT_TYPES];
