import { Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "@core/session";
import { ModalService } from "@features/modal/modal.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import {
  BehaviorSubject,
  Subject,
  endWith,
  finalize,
  map,
  repeat,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  timer,
} from "rxjs";
import { AuthComponentData, AuthorizationData, MobileAuthorizationResult } from "../auth.model";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-auth-mobile",
  template: `
    <app-modal-container [config]="authComponentData.modalConfig">
      <ng-template #header>
        <button
          type="button"
          class="pko-modal__close"
          [attr.aria-label]="'buttons.Close' | translate"
          (click)="cancel()">
          <svg icon name="close_nav" class="icon icon--lg"></svg>
        </button>
        <h3 class="pko-modal__title">
          {{ authComponentData.modalConfig.title! | translate }}
        </h3>
      </ng-template>
      <ng-container *ngIf="countdown$ | async as countdown; else timeout">
        <div
          [innerHTML]="
            'auth.mobile.Confirm'
              | translate
                : {
                    appName,
                    deviceName: authorizationData.deviceName,
                    phoneNumber: authorizationData.phoneNumber
                  }
          "></div>
        <div
          [innerHTML]="
            'auth.mobile.MobileAuthorizationTimeLeft' | translate : { countdown, appName }
          "></div>
      </ng-container>
    </app-modal-container>
    <ng-template #timeout>
      <p>{{ "auth.mobile.TimeOut" | translate }}</p>
    </ng-template>
  `,
})
export class AuthMobileComponent implements OnInit, OnDestroy {
  @HostBinding("class") class = "d-block";
  readonly #destroy$ = new Subject<void>();
  readonly #decisionTime = new BehaviorSubject(0);
  readonly countdown$ = this.#decisionTime.pipe(
    switchMap((time) =>
      timer(0, 1000).pipe(
        take(time + 1),
        map((elapsed) => time - elapsed)
      )
    )
  );
  readonly appName;
  authorizationData!: AuthorizationData;

  @Input() authComponentData!: AuthComponentData;
  @Input() set authData(data: AuthorizationData) {
    this.authorizationData = data;
    if (this.userService.userData.channel === "IPKOBIZNES") {
      this.authorizationData.phoneNumber = "";
    }

    this.#decisionTime.next(data.timeLeft || 0);
  }

  constructor(
    private authService: AuthService,
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private userService: UserService
  ) {
    this.appName = userService.userData.channel === "IPKO" ? "IKO" : "iPKO biznes";
  }

  ngOnInit() {
    const delayTime = this.authorizationData.pollingInterval * 1000;
    const count = (this.authorizationData.timeLeft || 0) / this.authorizationData.pollingInterval;

    this.authComponentData
      .mobileAuthorize()
      .pipe(
        repeat({ count: count, delay: delayTime }),
        skipWhile(({ authorizationStatus }) => authorizationStatus?.status === "Pending"),
        endWith(expired),
        take(1),
        takeUntil(this.#destroy$)
      )
      .subscribe((result) => this.#handleResult(result));
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  cancel() {
    this.authService
      .cancelMobileAuthorization()
      .pipe(
        finalize(() => this.activeModal.close()),
        takeUntil(this.#destroy$)
      )
      .subscribe();
  }

  #handleResult({ authorizationStatus, result }: MobileAuthorizationResult<unknown>) {
    this.activeModal.close();

    if (authorizationStatus.status === "Approve") {
      return this.authComponentData.onResult(result);
    }

    this.modalService.dialog({
      type: "failure",
      title: `auth.mobile.${authorizationStatus.status}`,
      buttons: { primary: defaultPrimary },
    });
  }
}

const expired: MobileAuthorizationResult<boolean> = {
  authorizationStatus: {
    status: "Expired",
    success: false,
  },
  result: false,
};
