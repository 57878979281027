<app-select
  [bindValue]="bindValue"
  [formControl]="control"
  [items]="items"
  [label]="label"
  [ngId]="labelForId"
  [translateLabel]="translateLabel"
  [validate]="controlToValidate"
  (selected)="selected.emit($event)"
  [placeholder]="placeholder"
  ngDefaultControl>
  <ng-template ng-label-tmp ng-option-tmp let-account="item">
    <div class="account__title">
      <span class="account__name">
        {{ account.name | nullable : ("accounts.DefaultName" | translate) }}
      </span>
      &nbsp;
      <span class="account__balance">
        ({{
          (account.balance | isNonNullable)
            ? (account.balance | balance : account.currency)
            : account.currency
        }})
      </span>
    </div>
    <div class="account__number">{{ account.number | account : true }}</div>
  </ng-template>
</app-select>
