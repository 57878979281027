import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { dateStructToString, stringToDateStruct } from "./datepicker-utils";

/**
 * Handles conversion between the ng-bootstrap model `NgbDateStruct` and domain model
 * which is [ISO 8601](https://www.iso.org/iso-8601-date-and-time-format.html) string `yyyy-MM-dd`
 */
@Injectable()
export class DatepickerAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    if (!value) return null;

    return stringToDateStruct(value);
  }

  toModel(date: NgbDateStruct | null): string | null {
    return dateStructToString(date);
  }
}
