import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FlowDeclaration } from "../../flow-declaration.model";

@Component({
  selector: "app-flow-declaration-summary-refusal",
  templateUrl: "./flow-declaration-summary-refusal.component.html",
})
export class FlowDeclarationSummaryRefusalComponent {
  @Input() declaration!: FlowDeclaration;
  @Input() showActions = true;
  @Output() updated = new EventEmitter<void>();

  update() {
    this.updated.emit();
  }
}
