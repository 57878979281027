<app-dashboard-section *ngIf="vm$ | async as vm" sectionTitle="indicatives.Title" [menu]="vm.menu">
  <div pko-preloader *ngIf="vm.isBusy"></div>

  <app-indicatives-simple
    *ngIf="vm.showAsSimple; else pro"
    [rates$]="rates$"
    [pairs$]="pairs$"
    [graphs$]="graphs$"
    [display]="vm"
    (settingsOpened)="openSettings()">
  </app-indicatives-simple>

  <ng-template #pro>
    <app-indicatives-pro
      [rates$]="rates$"
      [pairs$]="pairs$"
      [graphs$]="graphs$"
      [breakpoint]="vm.breakpoint">
    </app-indicatives-pro>
  </ng-template>
</app-dashboard-section>
