import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { formatNumber } from "@shared/utils/format";

@Component({
  selector: "app-margin-info",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div
    *ngIf="info"
    class="my-3 text-muted"
    [innerHTML]="message | translate : info"></div>`,
})
export class MarginInfoComponent {
  #info?: { profit: string; margin: string };

  get info() {
    return this.#info;
  }

  @Input() set data({ profitCurrency, salesMargin, salesProfit }: Required<RateInput>) {
    if (!salesMargin || !salesProfit) return;

    this.#info = {
      profit: formatNumber(salesProfit, { style: "currency", code: profitCurrency }),
      margin: formatNumber(salesMargin, { decimals: 4 }),
    };
  }

  @Input() message = "exchange.MarginInfo";
}

type RateInput = { profitCurrency: string; salesMargin: number; salesProfit: number };
