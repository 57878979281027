import { NgModule } from "@angular/core";
import { CollateralModule } from "@features/collateral/collateral.module";
import { TransactionModule } from "@features/transaction/transaction.module";
import { DecisionComponent } from "./components/decision/decision.component";
import { FormMultifxAccountsComponent } from "./components/form-multifx-accounts/form-multifx-accounts.component";
import { FormMultifxCurrencyPairComponent } from "./components/form-multifx-currency-pair/form-multifx-currency-pair.component";
import { FormMultifxForwardCollateralComponent } from "./components/form-multifx-forward-collateral/form-multifx-forward-collateral.component";
import { FormMultifxNewTransactionLegComponent } from "./components/form-multifx-new-transaction-leg/form-multifx-new-transaction-leg.component";
import { FormMultifxParforwardComponent } from "./components/form-multifx-parforward/form-multifx-parforward.component";
import { FormMultifxSumComponent } from "./components/form-multifx-sum/form-multifx-sum.component";
import { FormMultifxTenorDateProductComponent } from "./components/form-multifx-tenor-date-product/form-multifx-tenor-date-product.component";
import { FormMultifxTransactionErrorComponent } from "./components/form-multifx-transaction-error/form-multifx-transaction-error.component";
import { FormMultifxTransactionLegRowComponent } from "./components/form-multifx-transaction-leg-row/form-multifx-transaction-leg-row.component";
import { FormMultifxTransactionsLegComponent } from "./components/form-multifx-transactions-leg/form-multifx-transactions-leg.component";
import { FormMultiFxComponent } from "./components/form-multifx/form-multifx.component";
import { MultiFxComponent } from "./components/multifx.component";
import { SummaryDetailComponent } from "./components/summary-detail/summary-detail.component";
import { SummaryQuoteRowComponent } from "./components/summary-quote-row/summary-quote-row.component";
import { SummaryQuoteComponent } from "./components/summary-quote/summary-quote.component";
import { SummarySumComponent } from "./components/summary-sum/summary-sum.component";
import { MultiFxRoutingModule } from "./multifx-routing.module";

@NgModule({
  imports: [TransactionModule, CollateralModule, MultiFxRoutingModule],
  declarations: [
    FormMultiFxComponent,
    MultiFxComponent,
    FormMultifxCurrencyPairComponent,
    FormMultifxAccountsComponent,
    FormMultifxTransactionsLegComponent,
    FormMultifxNewTransactionLegComponent,
    FormMultifxTenorDateProductComponent,
    FormMultifxTransactionLegRowComponent,
    DecisionComponent,
    SummaryQuoteComponent,
    SummaryDetailComponent,
    SummaryQuoteRowComponent,
    SummarySumComponent,
    FormMultifxParforwardComponent,
    FormMultifxSumComponent,
    FormMultifxForwardCollateralComponent,
    FormMultifxTransactionErrorComponent,
  ],
  exports: [SummarySumComponent],
})
export class MultiFxModule {}
