// #########################
// ## MODYFIKACJA
// #########################            + assets/marketnews + assets/waluty

import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationService } from "@core/navigation";
import { DashboardService } from "@features/dashboard/dashboard.service";
import { GraphPoint } from "@features/graph/graph.model";
import { GraphService } from "@features/graph/graph.service";
import { MonthlyPricingsService } from "@features/history/services/monthly-pricings.service";
import { BehaviorSubject, of, tap } from "rxjs";
import PrezentacjaDane from "./prezentacja.dane";

// -- wyszukiwanie modyfikacji kodu     '<<------- NOWE'

export const commonData: any = {
  idDashboardSimple: true,
  isGraphLinear: true,
  wasGraphLinear: true,
};

// async function fetchJsonData(fileName: string) {
//     const res = await fetch("./assets/waluty/" + fileName);
//     const data = await res.json();
//     return data;
// }

async function fetchCsvData(fileName: string) {
  const res = await fetch("./assets/waluty/" + fileName, {
    method: "get",
    headers: {
      "content-type": "text/csv;charset=UTF-8",
    },
  });
  const data = await res.text();
  return formatCsvToArray(data);
}

async function formatCsvToArray(csvString: string): Promise<any[]> {
  const stringArray = csvString.split("\r\n");
  const stringArrayLength = stringArray.length;
  if (stringArrayLength > 2) {
    const dataToReturn: string[][] = [];
    for (let i = 1; i < stringArrayLength - 1; i++) {
      if (stringArray[i] !== "") {
        // dataToReturn.push(stringArray[i].split(";"));
        stringArray[i] = stringArray[i].replaceAll(",", ".");
        dataToReturn.push(stringArray[i].split(/[;|\t]/));
      }
    }
    return dataToReturn;
  }
  return [];
}

function convertToCurrencyPairs(
  tab: string[][]
): { para: string; wybrane: boolean; kup: number; sprzedaj: number; odchylenie: number }[] {
  const arrayToReturn: {
    para: string;
    wybrane: boolean;
    kup: number;
    sprzedaj: number;
    odchylenie: number;
  }[] = [];
  tab.forEach((el) => {
    arrayToReturn.push({
      para: el[0],
      wybrane: el[1] === "true" ? true : false,
      kup: Number(el[2]),
      sprzedaj: Number(el[3]),
      odchylenie: Number(el[4]),
    });
  });
  return arrayToReturn;
}
function convertToGraphPoint(tab: string[][]): (GraphPoint & { interval: string })[] {
  const arrayToReturn: (GraphPoint & { interval: string })[] = [];
  tab.forEach((el) => {
    arrayToReturn.push({
      interval: el[1],
      openTime: new Date(el[2]).getTime(),
      closeTime: new Date(el[3]).getTime(),
      openBid: Number(el[5]),
      highBid: Number(el[6]),
      lowBid: Number(el[7]),
      closeBid: Number(el[8]),
      openAsk: Number(el[9]),
      highAsk: Number(el[10]),
      closeAsk: Number(el[11]),
      lowAsk: Number(el[12]),
    });
  });
  return arrayToReturn;
}

export const isDemoReady = new BehaviorSubject(false);

export const sortByCloseTime = () => {
  Object.entries(commonData).map((el) => {
    if (el[0].includes("/graph")) {
      el[1] = (el[1] as GraphPoint[]).sort((gp1, gp2) => {
        return gp1.closeTime - gp2.closeTime;
      });
    }
    return el;
  });
};

@Injectable()
export class PrezentacjaInterceptor implements HttpInterceptor {
  constructor(
    private navigationService: NavigationService,
    private graphService: GraphService,
    private dashboardService: DashboardService,
    private monthlyPricingsService: MonthlyPricingsService
  ) {
    fetchCsvData("waluty.csv?ver=" + new Date().getTime()) // zmiana 2023-06-20
      .then((data) => {
        const tab = convertToCurrencyPairs(data);
        commonData["/currency"] = tab;
        const promiseArray: Promise<void>[] = [];
        tab.forEach((el: any) => {
          promiseArray.push(
            // WERSJA__ dane od nas
            fetchCsvData(el.para + ".csv?ver=" + new Date().getTime()) // zmiana 2023-06-20
              .then((data2) => {
                const tab2 = convertToGraphPoint(data2);
                const uniqueTab2 = tab2;
                // .filter((el) => {
                //   // if (["01w", "01d", "08h", "01h", "01m"].includes(el.interval)) return true;
                //   // return false;
                //   // // DZIALA_TEZ_ALE_WOLNIEJ
                //   // return true;
                // });
                commonData["/graph/" + el.para] = uniqueTab2;
              })
            // // WERSJA__ dane z accenture
            // fetchJsonData("candlegraph_v1.json")
            //     .then(data2 => {
            //         // // WERSJA PODSTAWOWA
            //         // console.error("DLUGOSC - ", data2.length)
            //         // commonData["/graph/" + el.para] = data2
            //         // WERSJA_CZYSZCZONA
            //         let uniqueObjArray = [...new Map(data2.map((item: GraphPoint) => [item.openTime, item])).values()];
            //         commonData["/graph/" + el.para] = uniqueObjArray;
            //     })
          );
        });
        try {
          Promise.all(promiseArray).then(() => {
            sortByCloseTime();
            isDemoReady.next(true);
          });
        } catch (exc) {
          console.error("FETCH DATA ERROR | ", exc);
        }
      });
    this.dashboardService.simple$.subscribe((data) => {
      // ----------------------------- dopracować to rozwiązanie
      if (data) {
        commonData.idDashboardSimple = true;
        commonData.wasGraphLinear = commonData.isGraphLinear;
        commonData.isGraphLinear = true;
      } else {
        commonData.idDashboardSimple = false;
        commonData.isGraphLinear = commonData.wasGraphLinear;
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // console.warn("** REQ = ", req);
    if (req?.url && req?.method) {
      try {
        const [zeroUrl, firstUrl, secondUrl, ...rest] = req.url.split("/");
        const urlToCheck =
          (zeroUrl ? "/" + zeroUrl : "") +
          (firstUrl ? "/" + firstUrl : "") +
          (secondUrl ? "/" + secondUrl : "");

        // if (
        //     [
        //         "/order/form",
        //         "/currency/exchange",
        //         "/accounts/exchange",
        //         "/settings/prefixes",
        //     ].includes(urlToCheck)
        // ) {
        //     console.warn("RES = ", new HttpResponse({
        //         status: 200,
        //         body: PrezentacjaDane?.[urlToCheck]?.[req.method]({ "body": req.body, "rest": rest, "params": req.params })
        //     }))
        // }

        if (PrezentacjaDane?.[urlToCheck]?.[req.method]) {
          return of(
            new HttpResponse(
              (() => {
                if (urlToCheck === "/graph/data" || urlToCheck === "/preferences/graph") {
                  return {
                    status: 200,
                    body: PrezentacjaDane[urlToCheck][req.method]({
                      body: req.body,
                      rest: rest,
                      params: req.params,
                      service: this.graphService,
                    }),
                  };
                } else if (
                  urlToCheck === "/history/pricings" ||
                  urlToCheck === "/history/pricings/csv"
                ) {
                  return {
                    status: 200,
                    body: PrezentacjaDane[urlToCheck][req.method]({
                      body: req.body,
                      rest: rest,
                      params: req.params,
                      service: this.monthlyPricingsService,
                    }),
                  };
                } else {
                  return {
                    status: 200,
                    body: PrezentacjaDane[urlToCheck][req.method]({
                      body: req.body,
                      rest: rest,
                      params: req.params,
                    }),
                  };
                }
              })()
            )
          ).pipe(
            tap(() => {
              if (urlToCheck === "/exchange/ndf") {
                this.navigationService.navigate(["/history/exchange/fx"]);
              }
            })
          );
        }
      } catch (exc) {
        console.error(`** Interceptor error ${req?.url} | `, exc);
      }
    }
    return next.handle(req);
  }
}
