import { Component, Input } from "@angular/core";
import { AlertHistoryService } from "@features/alert/services/alert-history.service";
import { ModalButton } from "@shared/components/modal/modal-model";
import { TableColumn } from "@shared/components/table/table-model";
import { AlertListData } from "../../models/alert-list";

@Component({
  selector: "app-list-alert",
  templateUrl: "list-alert.component.html",
})
export class AlertListComponent {
  @Input() data?: AlertListData | null;

  columns: TableColumn[] = [
    { name: "alert.Details.Side" },
    { name: "ExpectedRate", align: "center" },
    { name: "CurrencyPair", align: "center" },
    { name: "Status", align: "center" },
    { name: "alert.Details.Expiration", align: "end" },
    { name: "alert.Details.Notification", align: "end" },
    { name: "", align: "center", actionMenu: true },
  ];

  loadMoreButton: ModalButton = {
    text: "buttons.load.history.Alert",
    onClick: () => this.service.loadMore(),
    disabled: () => Boolean(this.data?.isLastPage),
  };

  constructor(private service: AlertHistoryService) {}

  get items() {
    return this.data?.items ?? [];
  }

  get noItems() {
    return !this.items.length;
  }
}
