import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "balance",
})
export class BalancePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(amount: string | number | null | undefined, currency: string, decimals = 2) {
    return (!amount && amount != 0) || !currency
      ? ""
      : `${this.decimalPipe.transform(amount, `1.${decimals}-${decimals}`)} ${currency}`?.replace(
          /\s/g,
          "\u00a0"
        );
  }
}
