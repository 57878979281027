import { Account } from "@features/accounts";
import { SettlementStatus, View360Details } from "@features/history-common/models";
import { ButtonGroupOption } from "@shared/components/controls";
import { Observable } from "rxjs";

export interface IListItem {
  transactionDate?: Date;
  settlementDate?: Date;
  isComplex: boolean;
  displayDetails: boolean;
}

export interface Strategy extends IListItem {
  id: string;
  name: string;
  deals: Deal[];
}

export interface Deal extends IListItem {
  amount: number;
  currency: string;
  side?: string;
  id: string;
  dealDate: Date;
  settlementDate?: Date;
  counterAmount?: number;
  counterCurrency: string;
  actualPricing?: number;
  pricingCurrency: string;
  pricingDate?: Date;
  rate?: number;
  type: string;
  subtype: string;
  status: SettlementStatus;
  openBalance?: number;
  openBalanceCurrency: string;
  linkedDealId?: number;
  details$: Observable<View360Details>;
}

export const isSimple = (item?: IListItem | null): item is Deal => !item?.isComplex;

export const isStrategy = (item?: IListItem | null): item is Strategy => !!item?.isComplex;

export interface CommoditySwap extends View360Details {
  attorney: string;
  transactionDate: Date;
  settlementDate: Date;
  commodity: string;
  amount: number;
  quantity: number;
  currencyOneCommodity: string;
  unit: string;
  settlementCurrency: string;
  clientPayedConstPrice: number;
  collateralType: string;
  collateralAmount: number;
  collateralCurrency: string;
  transactionAction: string;
  prematureClosingDate?: Date;
  clientReceivedConstPrice: number;
  account: Account;
  pricingAmount: number;
  pricingCurrency: string;
  pricingDate: Date;
  payObservations: Date[];
  receiveObservations: Date[];
}

export interface Bond extends View360Details {
  attorney: string;
  transactionDate: Date;
  amount: number;
  currency: string;
  buySellSequence: string;
  currencyDate: Date;
  price: number;
  rateOfReturn: number;
  transactionValue: number;
  quantity: number;
  name: string;
  isin: string;
  issuer: number;
  faceValue: number;
  faceValueCurrency: string;
  account: Account;

  buyBackDate?: Date;
  buyBackPrice?: number;
  buyBackRateOfReturn?: number;
  buyBackTransactionValue?: number;
  buyBackTransactionCurrency: string;
}

export interface Irs extends View360Details {
  transactionDate: Date;
  valueDate?: Date;
  maturityDate?: Date;
  currencyPair: string;
  initialAmount: number;
  initialCurrency: string;
  clientPayInterestRate: string;
  clientPayInterestRateBase: string;
  clientReceiveInterestRate: string;
  clientReceiveInterestRateBase: string;
  interestRatePaymentType: string;
  attorney: string;
  collateralType: string;
  collateralAmount?: number;
  collateralCurrency: string;
  account: Account;
  transactionAction: string;
  prematureClosingDate?: Date;
  pricingAmount?: number;
  pricingCurrency: string;
  pricingDate?: Date;
  schedule: IrsCirsScheduleItem[];
}

export interface Cirs extends View360Details {
  transactionDate: Date;
  valueDate?: Date;
  maturityDate?: Date;
  currencyPair: string;
  initialAmount1: number;
  initialCurrency1: string;
  initialAmount2: number;
  initialCurrency2: string;
  clientPayInterestRate: string;
  clientPayInterestRateBase: string;
  clientReceiveInterestRate: string;
  clientReceiveInterestRateBase: string;
  interestRatePaymentType: string;
  attorney: string;
  collateralType: string;
  collateralAmount?: number;
  collateralCurrency: string;
  accountOne: Account;
  accountTwo: Account;
  transactionAction: string;
  prematureClosingDate?: Date;
  receiveLegPricingAmount?: number;
  receiveLegPricingCurrency: string;
  payLegPricingAmount?: number;
  payLegPricingCurrency: string;
  pricingAmount?: number;
  pricingCurrency: string;
  pricingDate?: Date;
  schedule: IrsCirsScheduleItem[];
  exchangeSchedule: CirsAmountExchangeScheduleItem[];
}

export interface CirsAmountExchangeScheduleItem {
  v360TransID: number;
  transactionID: string;
  observationNumber: number;
  paymentSide: string;
  currency: string;
  amount?: number;
  transferDate?: Date;
}

export interface IrsCirsScheduleItem {
  observationNumber: number;
  paymentSide: string;
  rate: string;
  fixingDate?: Date;
  interestRatePeriodBeginning?: Date;
  interestRatePeriodEnd?: Date;
  settlementDate?: Date;
  currency: string;
  amount?: number;
}

export interface Fra extends View360Details {
  attorney: string;
  transactionDate: Date;
  valueDate: Date;
  amount: number;
  currency: string;
  side: string;
  rate: number;
  referenceRate: string;
  fixingDate: Date;
  collateralType: string;
  collateralAmount?: number;
  collateralCurrency: string;
  maturityDate: Date;
  account: Account;
  pricingAmount?: number;
  pricingCurrency: string;
  pricingDate?: Date;
  transactionAction: string;
  prematureClosingDate?: Date;
}

export interface FxOption extends View360Details {
  transactionType: string;
  attorney: string;
  transactionDate: Date;
  expirationDate: Date;
  dueDate: Date;
  strategyNumber?: number;
  amountOne: number;
  currencyOne: string;
  amountTwo: number;
  currencyTwo: string;
  side: string;
  executionRate: number;
  currencyPair: string;
  referenceRate: string;
  barrierType: string;
  floorRate?: number;
  capRate?: number;
  collateralType: string;
  collateralAmount?: number;
  collateralCurrency: string;
  premiumAmount?: number;
  premiumCurrency: string;
  premiumPayDate?: Date;
  settlementType: string;
  accountOne: Account;
  accountTwo: Account;
  transactionAction: string;
  prematureClosingDate?: Date;
  pricingAmount?: number;
  pricingCurrency: string;
  pricingDate?: Date;
  optionType: string;
  schedule: FxOptionScheduleItem[];
}

export interface FxOptionScheduleItem {
  observationNumber: number;
  observationDate?: Date;
}

export interface CommodityOption extends View360Details {
  commodity: string;
  attorney: string;
  transactionDate: Date;
  optionType: string;
  expiryDate?: Date;
  settlementDate?: Date;
  currencyOneCommodity: string;
  unit: string;
  amount: number;
  settlementCurrency: string;
  side: string;
  transactionType: string;
  executionRate?: number;
  executionCurrency: string;
  collateralType: string;
  collateralAmount: number;
  collateralCurrency: string;
  clientPayedOptionPremium?: number;
  clientReceivedOptionPremium?: number;
  clientPayedOptionPremiumCurrency: string;
  clientReceivedOptionPremiumCurrency: string;
  pricePaymentDate?: Date;
  account: Account;
  observations: Date[];
}

export interface InterestRateOption extends View360Details {
  transactionType: string;
  attorney: string;
  transactionDate: Date;
  expiryDate: Date;
  initialAmount: number;
  initialAmountCurrency: string;
  side: string;
  optionType: string;
  realizationRate: number;
  referenceRate: string;
  interestRateBaseConvention: string;
  collateralType: string;
  collateralAmount: number;
  collateralCurrency: string;
  premiumAmount?: number;
  premiumCurrency: string;
  premiumPayDate?: Date;
  accountOne: Account;
  accountTwo: Account;
  pricingAmount?: number;
  pricingCurrency: string;
  pricingDate?: Date;
  transactionAction: string;
  prematureClosingDate?: Date;
  interestPeriods: InterestPeriod[];
}

export interface InterestPeriod {
  observationNumber: number;
  amount?: number;
  currency: string;
  beginning?: Date;
  end?: Date;
  realizationRate?: number;
  date?: Date;
}

export interface DualCurrencyDeposit extends View360Details {
  transactionType: string;
  attorney: string;
  transactionDate: Date;
  endDate: Date;
  status: string;
  startDate: Date;
  amountInPrimaryCurrency?: number;
  primaryCurrency: string;
  secondaryCurrency: string;
  dueInterestPrimaryCurrency?: number;
  annualDualCurrencyPercentageRate?: number;
  fixedInterestRate?: number;
  optionPremiumPrimaryCurrency?: number;
  currencyPair: string;
  referenceRate: string;
  realizationRate?: number;
  fixingDay?: Date;
  accountOne: Account;
  accountTwo: Account;
}

export interface InvestmentDeposit extends View360Details {
  attorney: string;
  transactionDate: Date;
  startDate: Date;
  endDate: Date;
  amount?: number;
  currency: string;
  strategy: string;
  status: string;
  account: Account;
}

export interface PricingsConfig {
  pricingsDates: Date[];
  currentPricingDate: Date;
  pricingsVisible: boolean;
}

export const mapSideOptions = (): ButtonGroupOption[] => {
  return ["Buy", "Sell"].map((value) => ({ value, text: `view360.side.${value}` }));
};
