import { Component } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";
import { MessagesService } from "@features/messages/messages.service";
import {
  MessageNotificationItem,
  UserNotificationComponent,
} from "@features/notifications/notifications.model";
import { Subscription } from "rxjs";

@Component({
  selector: "app-notifications-message",
  templateUrl: "./notifications-message.component.html",
})
export class NotificationsMessageComponent extends UserNotificationComponent {
  private subscription = new Subscription();

  get message(): MessageNotificationItem {
    return this.notification as MessageNotificationItem;
  }

  constructor(private messagesService: MessagesService) {
    super();
    this.translateHeader = false;
  }

  get icon(): string {
    return "mail";
  }

  get header(): string {
    return this.message.title;
  }

  mainAction(): void {
    const { description, title, id, date } = this.message;
    this.messagesService.open({
      content: description,
      title,
      id: id.toString(),
      publishDate: date,
      read: true,
      requiresAgreement: false,
    });
  }

  get menu(): ActionItem[] {
    return [
      {
        text: "notifications.Details",
        callback: () => this.mainAction(),
      } as ActionItem,
      {
        text: "notifications.Delete",
        callback: () => {
          this.subscription.add(
            this.messagesService.archiveMessage(this.message.id).subscribe({
              complete: () => {
                this.actionExecuted.emit({ type: "message.delete", data: this.message });
              },
            })
          );
        },
      } as ActionItem,
    ];
  }
}
