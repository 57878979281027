// #########################
// ## MODYFIKACJA
// #########################

import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { BusyService } from "@core/loading/busy.service";
import { mapToAction, mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { origins } from "@core/user-tracking/tracking.model";
import { ModalService } from "@features/modal/modal.service";
import { LiveRateService } from "@features/transaction/components/form-rate/live-rate.service";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Dialog } from "@shared/components/modal/modal-model";
import { Observable, merge, share, startWith, tap } from "rxjs";
import { OrderForm, OrderFormData, OrderType, formDefaults } from "../../models/order-form";
import { OrderFormService } from "../../services/order-form.service";

@Component({
  selector: "app-form-order",
  templateUrl: "form-order.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LiveRateService],
})
export class OrderFormComponent implements OnInit {
  oco$: Observable<boolean>;
  // data$ = this.formService.formData$.pipe(share());    // <<------- NOWE
  data$ = this.formService.formData$; // <<------- NOWE
  formData$ = this.data$.pipe(
    tap((form) => this.#handleEmpty(form)),
    share()
  );
  isBusy$ = merge(
    this.data$.pipe(mapToAction(), mapToBusy()),
    this.busyService.observeOn("/accounts", "/currency", "/exchange")
  );

  form: FormGroup;
  protected isTile = false;

  constructor(
    fb: FormBuilder,
    private formService: OrderFormService,
    private busyService: BusyService,
    private modal: ModalService,
    private router: Router,
    private tracker: Tracker
  ) {
    const { retain } = window.history.state;
    formService.reset(retain);

    const { phone, expiration, other, ...form } = formService.current;
    const oco = new FormControl(Boolean(other));

    const handleOco = (isOco: boolean) => {
      if (isOco) {
        this.form.addControl("other", fb.group(toFlatControls(other ?? formDefaults)));
      } else {
        this.form.removeControl("other");
      }
    };

    this.oco$ = oco.valueChanges.pipe(startWith(oco.value), tap(handleOco));
    this.form = fb.group({
      ...toFlatControls(form),
      phone: fb.group(phone),
      expiration: fb.group(expiration ?? {}),
      oco,
    });
  }

  ngOnInit(): void {
    this.isTile ||
      this.tracker.follow({ process: "order", origin: history.state?.origin ?? "reload" });
  }

  #handleEmpty(form: OrderFormData) {
    form.hasPairs ||
      this.modal.dialog(
        {
          body: "missingData.OrderPairs",
          type: "info",
          buttons: {
            primary: {
              text: "OK",
              onClick: () => this.router.navigateByUrl("/"),
            },
          },
        },
        "page"
      );
  }

  async onSubmit(componentSelector?: string) {
    const { form } = this;

    if (!handleFormErrors(form, componentSelector)) return;

    const confirmed = await this.#canProceed(form.value);
    if (!confirmed) return;

    this.formService.save(form.getRawValue());
    this.isTile && this.tracker.follow({ process: "order", origin: origins.TILE });
    this.formService.submit();
  }

  async #canProceed({ type, other }: { type: OrderType; other?: OrderForm["other"] }) {
    const isLimit = [type, other?.type].filter(Boolean).every((type) => type === OrderType.Limit);
    if (isLimit) return true;

    const confirmed = await this.modal.dialog(confirmationDialog).result;
    return Boolean(confirmed);
  }
}

const toFlatControls = (form: Partial<OrderForm>) => ({
  type: form.type,
  side: form.side,
  amount: form.amount,
  currency: form.currency,
  counterCurrency: form.counterCurrency,
  rate: form.rate,
  points: form.points,
  account: form.account,
  counterAccount: form.counterAccount,
  tenor: form.tenor,
  pendingConversion: form.pendingConversion,
  currencyPair: null,
});

const confirmationDialog: Dialog = {
  title: "modals.order.title",
  body: "modals.order.body",
  buttons: {
    primary: { text: "modals.order.primary", resultOnClose: true },
    secondary: { text: "buttons.Resign" },
  },
};
