<button type="button" class="pko-filter-status__button" (click)="toggle()">
  {{ "toggle.filters." + !isFiltersOpen | translate }}
  <div class="pko-filter-status__button-icon">
    <svg icon name="filters-2" class="icon icon--lg"></svg>
    <span
      class="badge rounded-circle bg-primary text-white position-absolute translate-middle"
      *ngIf="filtersCount">
      {{ filtersCount }}
    </span>
  </div>
</button>
