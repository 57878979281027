import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BreakpointObserverService } from "@core/breakpoints";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions, origins } from "@core/user-tracking/tracking.model";
import { ProfileModalMenuService } from "@features/profile/profile-modal-menu.service";
import { Selectable } from "@shared/components/groups-selector/groups-selector.model";
import { Observable, firstValueFrom, map } from "rxjs";
import { ModalService } from "../../modal/modal.service";
import { ShortcutSettingsComponent } from "../components/shortcut-settings/shortcut-settings.component";
import { TilesSettingsComponent } from "../components/tiles-settings/tiles-settings.component";
import { Shortcut, ShortcutId } from "../models/shortcut-model";

@Injectable({
  providedIn: "root",
})
export class ShortcutsService {
  constructor(
    private preferences: PreferencesService,
    private modalService: ModalService,
    private profileMenuService: ProfileModalMenuService,
    private userService: UserService,
    private breakPointObserver: BreakpointObserverService,
    private router: Router,
    private tracker: Tracker
  ) {}

  shortcutsToSelectable$: Observable<Selectable[]> = this.preferences.dashboard$.pipe(
    map(({ shortcuts }) => {
      const all = this.selectableShortcuts
        .filter(({ requiredRoles }) => this.userService.hasRole(requiredRoles ?? "HasCustomer"))
        .map(({ id }) => ({ isSelected: shortcuts.includes(id), key: id, order: 0 }))
        .sort(compareShortcuts(shortcuts));

      const notSelected = all.splice(4);
      notSelected.forEach((x) => (x.isSelected = false));
      return [...all, ...notSelected].sort(compareShortcuts(shortcuts.slice(0, 4)));
    })
  );

  shortcuts$ = this.preferences.dashboard$.pipe(
    map(({ shortcuts, simple }) => {
      const selected = this.selectableShortcuts
        .filter(({ requiredRoles }) => this.userService.hasRole(requiredRoles ?? "HasCustomer"))
        .filter(({ id }) => shortcuts.includes(id))
        .sort((a, b) => shortcuts.indexOf(a.id) - shortcuts.indexOf(b.id));

      selected.splice(4);

      return [...selected, ...this.#getFixedShortcuts(simple)];
    })
  );

  updateShortcuts(shortcuts: string[]) {
    this.tracker.reportProgress({
      action: actions.SAVE,
      data: {
        oldShortcuts: this.preferences.dashboard.shortcuts.join(","),
        newShortcuts: shortcuts.join(","),
      },
    });
    this.preferences.setDashboard({ shortcuts });
  }

  #getFixedShortcuts(simple: boolean) {
    const extraAction = !simple && {
      name: "PersonalizeDashboard",
      action: () => this.#configureDashboard(),
    };

    const changeDashboard: Shortcut = {
      id: simple ? "SwitchDashboardToPro" : "SwitchDashboardToSimple",
      extraAction: extraAction || undefined,
      name: "dashboard-switch",
      actionOrRoute: () => {
        this.preferences.setDashboard({ simple: !simple });
        this.tracker.report({
          category: "preferences",
          action: "dashboard-switch",
          data: { from: simple ? "simple" : "pro", to: !simple ? "simple" : "pro" },
        });
      },
    };

    const changeShortcuts: Shortcut = {
      id: "NewShortcut",
      name: "plus",
      icon: "plus-0",
      actionOrRoute: () => this.#configureShortcuts(),
    };

    return [changeShortcuts, changeDashboard];
  }

  #configureShortcuts() {
    this.tracker.follow({ process: "set-shortcuts", origin: "dashboard" });
    this.modalService.modal({
      component: ShortcutSettingsComponent,
      scrollable: true,
    });
  }

  #configureDashboard() {
    this.tracker.follow({ process: "set-tiles", origin: "dashboard" });
    this.modalService.modal({ component: TilesSettingsComponent });
  }

  selectableShortcuts: readonly Shortcut[] = [
    {
      id: "NewDeal",
      name: "new-deal",
      actionOrRoute: () => {
        this.router.navigate(["/transactions/exchange"], { state: { origin: origins.SHORTCUT } });
      },
      requiredRoles: "FxSpot|FxForward|FxSwap|ProUser",
    },
    {
      id: "NewOrder",
      name: "new-order",
      actionOrRoute: "/rates/order",
      requiredRoles: "Orders|ProUser",
    },
    {
      id: "NewDeposit",
      name: "new-deposit",
      actionOrRoute: "/deposits/deposit",
      requiredRoles: "MmDeposit",
    },
    {
      id: "NewInvDeposit",
      name: "new-investment-deposit",
      icon: "li",
      actionOrRoute: "/investment-deposits/deposit",
      requiredRoles: "InvDeposit|ProUser,LiEnabled",
    },
    {
      id: "PremiumOffers",
      name: "new-deposit-premium",
      actionOrRoute: "/deposits/premium/offers",
      requiredRoles: "PremiumDeposit",
    },
    {
      id: "NewAlert",
      name: "alert-bik",
      actionOrRoute: "/rates/alert",
    },
    {
      id: "NewDpw",
      name: "dpw",
      actionOrRoute: "/transactions/iob",
      requiredRoles: "IobEnabled|DPWEnabled|ProUser,DpwOn|IobEnabled",
    },
    { id: "DealHistory", name: "transaction-list", actionOrRoute: "/history/exchange" },
    {
      id: "OrderHistory",
      name: "order-list",
      actionOrRoute: "/history/order",
      requiredRoles: "Orders|ProUser",
    },
    {
      id: "DepositHistory",
      name: "my-deposit",
      actionOrRoute: "/deposits/history",
    },
    {
      id: "InvestmentDepositHistory",
      name: "investment-deposit-list",
      icon: "li-list",
      actionOrRoute: "/investment-deposits/history",
      requiredRoles: "LiEnabled",
    },
    {
      id: "AlertHistory",
      name: "alert-out",
      actionOrRoute: "/history/alert",
    },
    {
      id: "Profile",
      name: "recipients",
      actionOrRoute: async () => {
        this.breakPointObserver.isMatched("lg")
          ? this.router.navigateByUrl("/profile/data")
          : this.modalService.menu(await firstValueFrom(this.profileMenuService.profileMenu$));
      },
    },
  ];
}

function compareShortcuts(shortcuts: string[]) {
  return <
    T extends {
      isSelected: boolean;
      key: ShortcutId;
      order: number;
    }
  >(
    a: T,
    b: T
  ) =>
    a.isSelected === b.isSelected
      ? shortcuts.indexOf(a.key) - shortcuts.indexOf(b.key)
      : Number(b.isSelected) - Number(a.isSelected);
}
