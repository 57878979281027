import { Component, HostBinding, Input } from "@angular/core";
import { PinnableCustomer } from "@core/models/customer.model";
@Component({
  selector: "app-customer-list",
  template: `<div class="pko-customer-group__header">
      <span class="fw-light">{{ header | translate }}</span>
    </div>
    <ul class="pko-customer-list">
      <li *ngFor="let customer of customers" [data]="{ customer, isDesktop }" pkoCustomerItem></li>
    </ul>`,
})
export class CustomerListComponent {
  @HostBinding("class") class = "pko-customer-group";
  @Input() header!: string;
  @Input() customers!: PinnableCustomer[];
  @Input() isDesktop!: boolean;
}
