import { Component, OnInit } from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective } from "@angular/forms";
import { CurrencyService } from "@features/currency";
import { startWith, switchMap } from "rxjs";

@Component({
  selector: "fx-form-amount-currency-pair",
  template: `<app-form-amount-currencies
    [pairs]="pairs$ | async"
    [currencies]="currencies$ | async">
  </app-form-amount-currencies>`,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AmountCurrencyPairFormComponent implements OnInit {
  pairs$ = this.currencyService.currencyPairs$;
  currencies$ = this.currencyService.exchangeCurrencies$;

  private get controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective, private currencyService: CurrencyService) {}

  ngOnInit() {
    this.assertForm();
    this.setCurrencyStream();
  }

  private assertForm() {
    if (this.controls.currencyPair) return;

    this.parent.form.addControl("currencyPair", new FormControl());
  }

  private setCurrencyStream() {
    const { netSettlement } = this.controls;

    if (!netSettlement) return;

    this.currencies$ = netSettlement.valueChanges.pipe(
      startWith(netSettlement.value),
      switchMap((isNet) =>
        isNet ? this.currencyService.allCurrencies$ : this.currencyService.exchangeCurrencies$
      )
    );
  }
}
