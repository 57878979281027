import { Component, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BreakpointObserverService } from "@core/breakpoints";
import { BusyService } from "@core/loading/busy.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { MultiFxFormService } from "@features/multifx/services/multifx-form.service";
import { TableColumn } from "@shared/components/table/table-model";
import { Subject, takeUntil } from "rxjs";
import { BaseDpwListComponent } from "./components/base-dpw-list.component";
import { DpwService } from "./dpw.service";
import { DpwFormService } from "./services/dpw-form.service";

@Component({
  selector: "app-dpw-list",
  templateUrl: "dpw-list.component.html",
})
export class DpwListComponent extends BaseDpwListComponent implements OnDestroy {
  readonly columns: TableColumn[] = [
    { name: "" },
    { name: "dpw.list.TypeNumber" },
    { name: "dpw.list.Amount", align: "center" },
    { name: "dpw.list.CurrencyPair", align: "center" },
    { name: "dpw.list.ValidUntil", align: "end" },
  ];
  currentBreakpoint$ = this.breakpointObserver.currentBreakpoint$;
  #destroy$ = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserverService,
    dpwService: DpwService,
    fb: FormBuilder,
    formService: DpwFormService,
    multiFormService: MultiFxFormService,
    busyService: BusyService,
    tracker: Tracker
  ) {
    super(dpwService, fb, formService, multiFormService, busyService, tracker);
    this.#observeViewChange();
  }

  #observeViewChange() {
    //workaround to have two html checkbox bound to same abstract control
    this.breakpointObserver
      .isBreakpoint("xs")
      .pipe(takeUntil(this.#destroy$))
      .subscribe(() => this.form$.value.setValue(this.form$.value.getRawValue()));
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
