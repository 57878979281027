<app-transaction-decision type="deposit" [loading$]="loading$" [vm$]="vm$">
  <ng-template #summary let-summary>
    <div class="pko-grid__row">
      <app-summary-quote
        [quote]="summary"
        class="col-10 col-md-4 order-md-last"></app-summary-quote>
      <app-summary-detail [detail]="summary" class="col-10 col-md-6"></app-summary-detail>
    </div>
  </ng-template>
</app-transaction-decision>
