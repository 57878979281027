import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BreakpointObserverService } from "@core/breakpoints";
import { CustomerService } from "@core/customer/customer.service";
import { BusyService } from "@core/loading/busy.service";
import { Customer } from "@core/models/customer.model";
import { UserService } from "@core/session/user.service";
import { ModalService } from "@features/modal/modal.service";
import { ProfileModalMenuService } from "@features/profile/profile-modal-menu.service";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subject, combineLatest, firstValueFrom, map, takeUntil, tap } from "rxjs";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
})
export class ProfileComponent implements OnDestroy {
  customers$ = this.customerService.customers$.pipe(
    map(toSelectableCustomers(this.userService.userData.customerShortName))
  );

  #open$ = new Subject<boolean>();
  #destroy$ = new Subject<void>();

  modalRef?: NgbModalRef;

  get userData() {
    return this.userService.userData;
  }

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private busyService: BusyService,
    private breakpointObserver: BreakpointObserverService,
    private router: Router,
    private modalService: ModalService,
    private profileMenuService: ProfileModalMenuService,
    @Inject(DOCUMENT) private document: Document
  ) {
    combineLatest([this.#open$, breakpointObserver.isBreakpoint(["xs", "sm"])])
      .pipe(
        tap(
          ([open, isSmall]) =>
            (this.document.body.style.overflow = isSmall && open ? "hidden" : "auto")
        ),
        takeUntil(this.#destroy$)
      )
      .subscribe();
  }

  async openProfile() {
    this.breakpointObserver.isMatched("lg")
      ? this.router.navigateByUrl("/profile/data")
      : this.modalService.menu(await firstValueFrom(this.profileMenuService.profileMenu$));
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  async set(customer: Customer) {
    // it takes a while between finishing select and reloading - keep the spinner on
    this.busyService.increment("reload");
    await firstValueFrom(this.customerService.select(customer));
    window.location.href = "/";
  }

  showTooltip(event: any) {
    return event.target.offsetWidth < event.target.scrollWidth;
  }

  openChanged(open: boolean) {
    this.#open$.next(open);
  }
}

const toSelectableCustomers = (current: string) => (customers: Customer[]) =>
  customers.filter(({ shortName, hasApp }) => shortName !== current && hasApp);
