<ng-container *ngTemplateOutlet="role === 'stack' ? stack : table"></ng-container>

<ng-template #table>
  <td align="center" pkoTableCell>
    {{ similarDeal.DealId }}
  </td>
  <td align="center" pkoTableCell *ngIf="transactionType === 'deposit'; else currencyPair">
    {{ similarDeal.Currency }}
  </td>
  <ng-template #currencyPair>
    <td align="center" pkoTableCell>
      {{ similarDeal.CurrencyPair | pair }}
    </td>
  </ng-template>
  <td align="center" pkoTableCell>
    {{ similarDeal.Amount | number : "1.2" }}
  </td>
  <td align="center" pkoTableCell>
    {{ similarDeal.DealDate | datePko }}
  </td>
  <td align="center" pkoTableCell *ngIf="transactionType === 'exchange'">
    {{ "exchange.history.type." + similarDeal.DealType | translate }}
  </td>
  <td align="center" pkoTableCell *ngIf="transactionType === 'deposit'">
    {{ "deposit.Types." + similarDeal.DealType | translate }}
  </td>
  <td align="center" pkoTableCell *ngIf="transactionType === 'investment-deposit'">
    {{ "view360.type.INVDEPO" | translate }}
  </td>
  <td align="center" pkoTableCell *ngIf="transactionType === 'exchange'">
    {{ "history.settlementStatus." + similarDeal.Status | translate }}
  </td>
  <td align="center" pkoTableCell *ngIf="transactionType !== 'exchange'">
    {{ "deposit.statuses." + similarDeal.Status | translate }}
  </td>
</ng-template>

<ng-template #stack>
  <p class="mb-1">
    <span class="fw-light">{{ "dialog.warning.body.HasSimilarDeal.Number" | translate }}:</span>
    {{ similarDeal.DealId }}
  </p>
  <p class="mb-1">
    <span class="fw-light">{{ "Amount" | translate }}:</span>
    {{ similarDeal.Amount | number : "1.2" }}
  </p>
  <p class="mb-1" *ngIf="transactionType === 'deposit'; else currencyPair">
    <span class="fw-light">{{ "history.Currency" | translate }}:</span>
    {{ similarDeal.Currency }}
  </p>
  <ng-template #currencyPair>
    <p class="mb-1">
      <span class="fw-light">{{ "CurrencyPair" | translate }}:</span>
      {{ similarDeal.CurrencyPair | pair }}
    </p>
  </ng-template>
  <p class="mb-1">
    <span class="fw-light">{{ "TransactionDate" | translate }}:</span>
    {{ similarDeal.DealDate | datePko }}
  </p>
  <p class="mb-1" *ngIf="transactionType === 'exchange'">
    {{ "exchange.history.type." + similarDeal.DealType | translate }}
  </p>
  <p class="mb-1" *ngIf="transactionType === 'deposit'">
    {{ "deposit.Types." + similarDeal.DealType | translate }}
  </p>
  <p class="mb-1" *ngIf="transactionType === 'investment-deposit'">
    {{ "iew360.type.INVDEPO" | translate }}
  </p>
  <p class="mb-1" *ngIf="transactionType === 'exchange'">
    {{ "history.settlementStatus." + similarDeal.Status | translate }}
  </p>
  <p class="mb-1" *ngIf="transactionType !== 'exchange'">
    {{ "deposit.statuses." + similarDeal.Status | translate }}
  </p>
  <hr />
</ng-template>
