import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { IFormService } from "@features/transaction/models/services";
import { addDays, datepickerFormat } from "@utils/time";
import { BehaviorSubject } from "rxjs";
import { AlertForm } from "../models/alert-form";
import { AlertService } from "./alert.service";

@Injectable({ providedIn: "root" })
export class AlertFormService implements IFormService<AlertForm> {
  #form = new BehaviorSubject(this.#defaultForm);

  submission$ = this.alertService.submission$;

  constructor(private alertService: AlertService, private preferences: PreferencesService) {}

  get current() {
    return this.#form.getValue();
  }

  get #defaultForm() {
    const { prefix, number } = this.preferences.phone;

    return {
      currency: "EUR",
      counterCurrency: "PLN",
      side: "Sell",
      expirationDate: datepickerFormat(addDays(new Date(), 7)),
      phone: { prefix, number },
    } as AlertForm;
  }

  reset(retain?: "all" | "some") {
    if (retain === "all") return;

    let form = this.#defaultForm;
    if (retain === "some") {
      const current = { ...this.current };
      propsToReset.forEach((prop) => delete current[prop]);
      form = { ...this.#defaultForm, ...current };
    }

    this.#form.next(form);
  }

  save(form: AlertForm) {
    this.#form.next(form);
  }

  submit(modal?: boolean) {
    const current = { ...this.current, modal };
    this.preferences.setPhone({ ...current.phone });
    this.alertService.submit(current);
  }
}

const propsToReset = ["amount", "rate"] as const;
