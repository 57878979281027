import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Breakpoint, BreakpointObserverService } from "@core/breakpoints";
import { ConfigStore } from "@core/config";
import { BusyService } from "@core/loading/busy.service";
import { ActionItem } from "@core/menus/action-item";
import { PreferencesService } from "@core/preferences/preferences.service";
import { DashboardService } from "@features/dashboard/dashboard.service";
import { GraphService } from "@features/graph/graph.service";
import { ModalService } from "@features/modal/modal.service";
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  map,
  repeat,
  shareReplay,
  switchMap,
  takeUntil,
} from "rxjs";
import { IndicativeSettingsComponent } from "./components/settings/indicative-settings.component";
import { IndicativePair, ViewModel } from "./indicatives.model";
import { IndicativesService } from "./indicatives.service";

@Component({
  selector: "app-indicatives",
  templateUrl: "./indicatives.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndicativesComponent implements OnDestroy {
  allPairs = new BehaviorSubject<IndicativePair[]>([]);

  pairs$ = this.allPairs.pipe(map((pairs) => pairs.filter((x) => x.isSelected)));
  destroy$ = new Subject<void>();

  rates$ = combineLatest([this.preferences.indicatives$, this.pairs$]).pipe(
    switchMap(([preferences, pairs]) =>
      this.service
        .getRates(
          pairs.map((x) => x.code),
          this.preferences.dashboard.simple ? "TOD" : preferences.tenor
        )
        .pipe(
          repeat({
            count: preferences.pollingEnabled ? Infinity : 1,
            delay: 1000 * this.config.config.indicatives.pollingInterval,
          }),
          takeUntil(this.destroy$)
        )
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  graphs$ = this.pairs$.pipe(
    switchMap((pairs) => this.graphService.getGraphs(pairs.map((x) => x.code))),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  #menu$ = this.preferences.indicatives$.pipe(
    map(({ pollingEnabled }) => [
      {
        text: "indicatives.EditCurrencyPairs",
        callback: () => this.openSettings(),
      },
      {
        text: pollingEnabled ? "indicatives.DisablePolling" : "indicatives.EnablePolling",
        callback: () => this.togglePolling(pollingEnabled),
      },
    ])
  );

  vm$ = combineLatest([
    this.#menu$,
    this.busyService.observeOn("/indicatives"),
    this.dashboardService.simple$,
    this.breakpointObserver.currentBreakpoint$,
  ]).pipe(map(toViewModel));

  constructor(
    private service: IndicativesService,
    private modalService: ModalService,
    private preferences: PreferencesService,
    private dashboardService: DashboardService,
    private graphService: GraphService,
    private config: ConfigStore,
    private busyService: BusyService,
    private breakpointObserver: BreakpointObserverService
  ) {
    this.service.getPairs
      .pipe(takeUntil(this.destroy$))
      .subscribe((pairs) => this.allPairs.next(pairs));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openSettings() {
    const modal = this.modalService.modal({
      component: IndicativeSettingsComponent,
      scrollable: true,
    });

    modal.componentInstance.pairs = this.allPairs.value;
    modal.result.then(
      (result) => this.update(result),
      () => void 0
    );
  }

  update(pairs: string[]) {
    const allPairs = this.allPairs.value;

    if (!pairs?.length) return;

    allPairs.forEach((x) => (x.isSelected = !!pairs.find((y) => y === x.code)));
    this.allPairs.next(
      pairs
        .map((x) => ({ ...allPairs.find((y) => y.code === x), isSelected: true } as IndicativePair))
        .concat(allPairs.filter((x) => !x.isSelected))
    );
  }

  togglePolling(disable: boolean) {
    this.preferences.setIndicatives({
      pollingEnabled: !disable,
    });
  }
}

const toViewModel = ([menu, isBusy, isSimple, breakpoint]: [
  ActionItem[],
  boolean,
  boolean,
  Breakpoint
]): ViewModel => ({
  menu,
  isBusy,
  isSimple,
  breakpoint,
  showAsSimple: isSimple || breakpoint === "xs",
});
