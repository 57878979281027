<ng-container *ngIf="vm$ | async as collateral">
  <ng-container *ngIf="collateral.show" [formGroup]="group">
    <app-select
      label="collateral.Type"
      formControlName="type"
      validate="type"
      [items]="collateral.types"
      translateText="collateral.types"
      placeholder="placeholders.Select"
      ngDefaultControl>
    </app-select>

    <app-select-account
      *ngIf="collateral.isDeposit"
      [items]="collateral.accounts"
      formControlName="account"
      label="accounts.Collateral"
      validate="account"
      [useDefault]="false"
      ngDefaultControl>
    </app-select-account>
  </ng-container>
</ng-container>
