import { Component, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, FormGroupDirective } from "@angular/forms";
import { HolidaysService } from "@features/currency/holidays.service";
import {
  getProduct,
  getSpotDate,
  TenorDate,
  TenorDateConfig,
  TenorDateForm,
  TenorService,
} from "@features/tenor";
import { assertIsDefined } from "@utils/misc";
import {
  combineLatest,
  defer,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  startWith,
  tap,
} from "rxjs";

@Component({
  selector: "app-form-tenor-date-product-swap",
  templateUrl: "tenor-date-product-swap.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TenorDateProductSwapFormComponent implements OnInit {
  #spotDate = "";

  tenorDates$: Observable<SplitConfig> = EMPTY;
  holidays$: Observable<string[]> = EMPTY;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private tenorService: TenorService,
    private holidayService: HolidaysService
  ) {}

  ngOnInit() {
    const { currencyPair, nearSettlement } = this.#assertForm();

    // defer ensures this observable emits 🤷‍♂️
    const currencyPair$: Observable<string> = defer(() =>
      currencyPair.valueChanges.pipe(startWith(currencyPair.value), filter(Boolean))
    );

    const tenorDates$ = this.tenorService
      .getTenorDatesByCurrencyPair(currencyPair$)
      .pipe(tap((tenorDates) => (this.#spotDate = getSpotDate(tenorDates))));

    this.tenorDates$ = combineLatest([tenorDates$, observeDate(nearSettlement)]).pipe(
      map(splitSwapTenorDates)
    );

    this.holidays$ = this.holidayService.getHolidaysByCurrencyPair(currencyPair$);
  }

  onChanged({ tenor, date }: TenorDateForm) {
    if (!date) return;

    const productType = getProduct({ tenor, date, spotDate: this.#spotDate, isSwap: true });

    if (!productType || this.#controls.product.value === productType) return;

    this.#controls.product.setValue(productType);
  }

  #assertForm() {
    const { currencyPair, product, nearSettlement } = this.#controls;

    assertIsDefined(product);
    assertIsDefined(currencyPair);
    assertIsDefined(nearSettlement);

    return { currencyPair, nearSettlement };
  }
}

const observeDate = ({ value, valueChanges }: AbstractControl) =>
  valueChanges.pipe(
    startWith(value),
    map(({ date }) => date),
    distinctUntilChanged()
  );

const splitSwapTenorDates = ([values, nearDate]: [TenorDate[], string | null]): SplitConfig => ({
  // you can pick any near date, if it's more than far, far will show validation error.
  // to limit both sides: `near: { values: values.slice(0, -1), max: farDate, exclusive: true }`
  near: { values: values.slice(0, -1) },
  far: { values: values.slice(1), min: nearDate, exclusive: true },
});

type SplitConfig = { near: TenorDateConfig; far: TenorDateConfig };
