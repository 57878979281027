import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { AuthorizationData, PrepareAuthorizationResult } from "./auth.model";

@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(private http: HttpClient) {}

  prepareMifidAuthorization(id: string): Observable<PrepareAuthorizationResult> {
    return this.http
      .get<PrepareAuthorizationResult>(`/authorization/mifid/${id}`)
      .pipe(
        catchError(() =>
          of({ error: { code: "GENERIC" }, authorizationData: {} as AuthorizationData })
        )
      );
  }

  prepareApplicationAuthorization(data: unknown): Observable<PrepareAuthorizationResult> {
    return this.http
      .post<PrepareAuthorizationResult>(`/authorization/application`, data)
      .pipe(
        catchError(() =>
          of({ error: { code: "GENERIC" }, authorizationData: {} as AuthorizationData })
        )
      );
  }

  cancelMobileAuthorization() {
    return this.http.post<void>("/authorization/cancel", null);
  }
}
