import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { Logger } from "@core/logging";

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private logger: Logger) {}

  handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      this.logger.logError("global", `Unhandled HTTP error`, error);
    }

    this.logger.logError("global", `Unhandled application error`, error);
  }
}
