<div class="pko-header d-none d-md-flex">
  <h1 class="pko-title">
    {{ "dpw.Title" | translate }}
  </h1>
</div>

<form class="pko-form" [formGroup]="form" *ngIf="page$ | async as page">
  <app-dpw-direction class="pko-grid__row" *ngIf="page === 1"></app-dpw-direction>
  <app-iob-form class="pko-grid__row" *ngIf="page === 2"></app-iob-form>

  <app-form-footer
    *ngIf="back$ | async as back"
    [forward]="forward"
    [back]="back"
    [forwardValidator]="forwardValidator"></app-form-footer>
</form>
<div pko-preloader *ngIf="isBusy$ | async"></div>
