import { Component } from "@angular/core";
import { LeiMessageData, UserService } from "@core/session";
import { LeiService } from "@features/lei/lei.service";
import { Modal } from "@shared/components/modal/modal-model";
import { tap } from "rxjs";

@Component({
  selector: "app-lei-message",
  templateUrl: "./lei-message.component.html",
})
export class LeiMessageComponent {
  modalConfig: Modal = {
    title: "",
    buttons: {
      primary: { text: "OK", onClick: () => this.closeLei() },
      secondary: { text: "buttons.DoNotShowAgain", onClick: () => this.skipLei() },
    },
  };

  leiMessage: LeiMessageData;

  constructor(private leiService: LeiService, private userService: UserService) {
    this.leiMessage = userService.userData.leiMessage;
  }

  skipLei() {
    this.leiService
      .skipLei()
      .pipe(tap(() => this.closeLei()))
      .subscribe();
  }

  closeLei() {
    this.userService.userData.leiMessage.show = false;
  }
}
