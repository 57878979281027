import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { lastValueFrom } from "rxjs";
import { SessionService } from "./session.service";
import { UserData } from "./user.model";

@Injectable({ providedIn: "root" })
export class UserService {
  userData!: UserData;
  hasCustomer = false;
  isDarkMode = false;

  constructor(
    private _http: HttpClient,
    private _sessionService: SessionService,
    private _preferencesService: PreferencesService
  ) {}

  async load() {
    this.userData = await lastValueFrom(this._http.get<UserData>("/user"));
    this.hasCustomer = this.hasRole("HasCustomer|PotentialCustomer");
    this._sessionService.initialize(this.userData.tokenExpiration);
  }

  get isIdg() {
    return this.userData.isIdg;
  }

  hasRole(roles: string) {
    if (!roles) return false;

    const userRoles = [...this.userData.roles];

    if (!this._preferencesService.dashboard.simple && !this.userData.passive) {
      userRoles.push("ProUser");
    }

    if (!this.userData.liPilot || userRoles.includes("InvDeposit")) {
      userRoles.push("LiEnabled");
    }

    return roles.split(",").every((group) => {
      const required = group.split("|");
      return required.some((role) => userRoles.includes(role));
    });
  }
}
