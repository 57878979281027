import { Component, Input } from "@angular/core";

@Component({
  selector: "app-rate-direction-arrow",
  template: `<svg
    icon
    *ngIf="arrow"
    name="arrow-{{ arrow }}"
    class="icon--md"
    [class]="arrow"></svg>`,
  styleUrls: ["./rate-direction-arrow.component.scss"],
})
export class RateDirectionArrowComponent {
  private _currentRate?: number;
  private _previousRate?: number;
  arrow = "up";

  @Input()
  get currentRate() {
    return this.currentRate;
  }

  set currentRate(nextRate: number | undefined) {
    this._previousRate = this._currentRate;
    this._currentRate = nextRate;
    this.setArrow();
  }

  setArrow() {
    if (!this._previousRate) {
      this.arrow = "up";
      return;
    }

    if (!this._currentRate) {
      this.arrow = "";
      return;
    }

    this._previousRate < this._currentRate && (this.arrow = "up");
    this._previousRate > this._currentRate && (this.arrow = "down");
  }
}
