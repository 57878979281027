import { OriginalExchange } from "../models";

export const getActionForm = <
  SavedForm extends { original: OriginalExchange; amount: number | string },
  DefaultForm extends { amount: number | string | null }
>(
  historyState: History["state"],
  savedForm: SavedForm,
  defaultMapper: (original: OriginalExchange) => DefaultForm
): { original: OriginalExchange; form: Omit<SavedForm, "original"> | DefaultForm } => {
  // history state is only for the first entry
  const fromDetails: OriginalExchange = historyState.original;
  const original = fromDetails ?? savedForm.original;

  const form = fromDetails ? defaultMapper(fromDetails) : savedForm;

  // "some" is set only on repeat, i.e. on success.
  // We need to update the open balance, but no need to call details again.
  if (historyState.retain === "some") {
    const updatedBalance = original.openBalance - Number(savedForm.amount);
    original.openBalance = +updatedBalance.toFixed(2);
    form.amount = null;
  }

  return { original, form };
};
