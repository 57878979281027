import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { Button } from "@features/transaction/models/button";
import { CountdownMessage } from "@shared/components";
import { TransactionType } from "../../models";

@Component({
  selector: "app-form-footer",
  templateUrl: "form-footer.component.html",
})
export class FormFooterComponent implements OnChanges {
  @HostBinding("class") class = "pko-form-footer";

  private _timeout = false;

  @Input() type: TransactionType = "exchange";
  @Input() countdown?: number;
  @Input() forward?: Button;
  @Input() back?: Button;
  @Input() message?: CountdownMessage;
  @Input() forwardValidator?: (time: number) => boolean;

  @Output() finished = new EventEmitter<void>();

  constructor(@Optional() private parent: FormGroupDirective) {}

  get forwardDisabled() {
    if (this.forwardValidator) return !this.forwardValidator(this.countdown ?? -1);

    if (!this.parent) return false;
    return !this.parent.form.valid;
  }

  get forwardText() {
    // teeny-tiny hack: to allow "confirm" button without a countdown, set countdown to 0.
    if (!this.countdown && this.countdown !== 0) {
      return this.forward?.text ? this.forward?.text : "buttons.forward." + this.type;
    }

    return this._timeout ? "buttons.refresh." + this.type : "buttons.confirm";
  }

  ngOnChanges({ countdown }: SimpleChanges) {
    if (countdown) {
      this._timeout = countdown.currentValue < 0;
    }
  }

  onForward() {
    if (this.forwardDisabled) return;

    this.forward?.onClick?.();

    if (this.countdown) {
      this.countdown = -1;
    }
  }

  onTimeout() {
    this._timeout = true;
    this.finished.emit();
  }
}
