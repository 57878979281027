import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BlotterCurrency } from "./blotter-graph.model";

@Injectable({ providedIn: "root" })
export class BlotterGraphService {
  constructor(private http: HttpClient) {}

  get(currencies: string[]) {
    return this.http.get<BlotterCurrency[]>("/graph/blotter", {
      params: new HttpParams({
        fromObject: { currencies },
      }),
    });
  }
}
