import { Component, HostBinding, Input } from "@angular/core";
import { IconSize } from "@core/models/control";

@Component({
  selector: "app-flag",
  templateUrl: "flag.component.html",
})
export class FlagComponent {
  @HostBinding("class") class = "d-flex align-items-center";
  @Input() icon = "missing";
  @Input() position: "left" | "right" = "right";
  @Input() label?: string | null;
  @Input() translateLabel = false;
  @Input() size: IconSize = "md";
}
