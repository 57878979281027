import { AbstractControl, Validators } from "@angular/forms";
import { groupBy } from "@utils/collection";
import { Account } from "./accounts-model";

export const getDefaultAccount = (accounts: Account[]) =>
  accounts?.length === 1 ? accounts[0] : accounts?.find(({ isDefault }) => isDefault);

export const groupByCurrency = (accounts: Account[]) =>
  groupBy(({ currency }) => currency, accounts);

export function getAccountByNumber(accounts: Account[], number?: string) {
  if (!number) return;

  return accounts.find((account) => account.number === number);
}

export const updateValidator = (account: AbstractControl) => (isNetSettlement: boolean) => {
  if (isNetSettlement) {
    account.clearValidators();
    account.updateValueAndValidity();
    account.reset();
  } else {
    account.addValidators(Validators.required);
  }
};
