import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nullable",
})
export class NullablePipe implements PipeTransform {
  transform(value: string | null | undefined, replacement = "---") {
    return value ?? replacement;
  }
}
