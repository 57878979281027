import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { ActivationApiService } from "@features/activation/activation-api.service";
import {
  Application,
  ApplicationDetails,
  ApplicationDetailsResult,
} from "@features/activation/activation.model";
import { ApplicationActionsService } from "@features/activation/application-actions.service";
import { TemporaryFlowDeclarationSummaryComponent } from "@features/flow-declaration/components/temporary-flow-declaration-summary/temporary-flow-declaration-summary.component";
import { FlowDeclaration } from "@features/flow-declaration/flow-declaration.model";
import { MifidApplicationSurveyResultComponent } from "@features/mifid/components/mifid-application-survey-result/mifid-application-survey-result.component";
import { MifidSurveyComponent } from "@features/mifid/components/mifid-survey/mifid-survey.component";
import { GetSurveyResult, SurveyResult, SurveyStatusResult } from "@features/mifid/mifid.model";
import { MifidService } from "@features/mifid/mifid.service";
import { ModalService } from "@features/modal/modal.service";
import { Modal, defaultPrimary } from "@shared/components/modal/modal-model";
import { TableColumn } from "@shared/components/table/table-model";
import { EMPTY, Observable, catchError, filter, of, switchMap, tap } from "rxjs";

@Component({
  selector: "app-application-details",
  templateUrl: "./application-details.component.html",
})
export class ApplicationDetailsComponent implements OnInit {
  @HostBinding("class") class = "h-100";
  @Input() application!: Application;

  details$!: Observable<ApplicationDetailsResult>;

  modalConfig: Modal = {
    title: "activation.ApplicationDetails",
    hideCloseButton: false,
  };

  form!: FormGroup;

  readonly columns: TableColumn[] = [
    { name: "activation.NameAndSurname" },
    { name: "activation.PidNumber" },
    { name: "activation.SignatureStatus" },
  ];

  constructor(
    private service: ActivationApiService,
    private modalService: ModalService,
    private actionsService: ApplicationActionsService,
    private mifidService: MifidService,
    private applicationEvents: AppEventsService,
    formBuilder: FormBuilder
  ) {
    this.form = formBuilder.group({
      readDocuments: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.details$ = this.service
      .getDetails(this.application.number)
      .pipe(tap((details) => this.setupButtons(details)));
  }

  setupButtons(details: ApplicationDetailsResult) {
    this.modalConfig.buttons = {};
    if (details.isRepresentative && !details.isSigned) {
      this.application.signVisible = true;
      this.modalConfig.buttons.primary = {
        text: "activation.Sign",
        onClick: () => this.sign(details.applicationDetails),
        disabled: () => !this.form.controls.readDocuments.value,
      };
    } else {
      this.application.signVisible = false;
    }

    if (details.cancelVisible)
      this.modalConfig.buttons.secondary = {
        text: "activation.Cancel",
        onClick: () => this.actionsService.cancel(this.application),
      };
  }

  openFlowDeclaration(declaration: FlowDeclaration) {
    const modal = this.modalService.modal({
      component: TemporaryFlowDeclarationSummaryComponent,
    });

    modal.componentInstance.declaration = declaration;
    return false;
  }

  sign(details: ApplicationDetails) {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;

    this.service.startSigning(this.application.number).subscribe();

    if (details.mifidRequired) {
      this.mifidService
        .getApplicationStatus(this.application.number)
        .pipe(
          tap((statuses) => this.handleStatuses(statuses)),
          catchError(() => this.#handelError())
        )
        .subscribe();
    } else {
      this.actionsService.startSigningDialog(false, false, this.application.number);
    }
  }

  handleStatuses(statuses: SurveyStatusResult) {
    statuses.clientRequiresSurvey
      ? this.#startMifidDialog(statuses)
          .pipe(
            filter(Boolean),
            switchMap(() => this.#checkMifidState()),
            catchError(() => this.#handelError())
          )
          .subscribe()
      : this.actionsService.startSigningDialog(false, false, this.application.number);
  }

  #checkMifidState() {
    return this.mifidService.getApplicationSurvey(this.application.number).pipe(
      tap((state) => this.#handleState(state)),
      catchError(() => this.#handelError())
    );
  }

  #handleState(state: GetSurveyResult) {
    if (state.surveyResult) {
      this.#handleOtherRepresentativeDecision(state.surveyResult);
    } else {
      const modal = this.modalService.modal({
        component: MifidSurveyComponent,
        scrollable: true,
      });
      modal.componentInstance.data$ = of(state.survey);
      modal.componentInstance.applicationId = this.application.number;
    }
  }

  #handleOtherRepresentativeDecision(result: SurveyResult) {
    if (result.rejected) {
      return this.modalService.dialog(this.#handleRejected(), "modal");
    }

    if (result.locked) {
      return this.modalService.dialog(this.#handleLocked(), "modal");
    }

    const modal = this.modalService.modal({
      component: MifidApplicationSurveyResultComponent,
      scrollable: true,
      data: result,
    });

    modal.componentInstance.otherAttorneyResult = true;
    modal.componentInstance.applicationId = this.application.number;
    return modal;
  }

  #handleRejected() {
    return {
      body: "mifid.MifidSurveyRejected",
      buttons: {
        primary: {
          text: "OK",
          onClick: () => {
            this.applicationEvents.emit(AppEvent.ApplicationAction);
          },
        },
      },
    };
  }

  #handleLocked() {
    return {
      body: "mifid.MifidSurveyLockedForEditing",
      buttons: {
        primary: {
          text: "OK",
          resultOnClose: true,
        },
      },
    };
  }

  #startMifidDialog(statuses: SurveyStatusResult) {
    const body = [{ key: "mifid.Info" }];
    statuses.jointRequiresSurvey && body.push({ key: "mifid.InfoJoint" });

    const modal = this.modalService.dialog(
      {
        title: "mifid.Header",
        body,
        buttons: {
          primary: {
            text: "buttons.StartMifid",
            resultOnClose: true,
          },
          secondary: {
            text: "buttons.Close",
          },
        },
      },
      "modal"
    );

    return modal.closed;
  }

  #handelError() {
    this.modalService.dialog({
      title: "errors.GettingMifidSurveyStatusForApplication",
      buttons: {
        primary: defaultPrimary,
      },
      type: "failure",
    });

    return EMPTY;
  }
}
