import { Component, HostBinding, Input, OnChanges } from "@angular/core";
import { ControlContainer, FormGroupDirective, Validators } from "@angular/forms";
import { BreakpointObserverService } from "@core/breakpoints";
import { Currency, CurrencyPair } from "@features/currency/model";
import { baseCurrency, quoteCurrency } from "@shared/utils/currency";
import { Observable, map, startWith, tap } from "rxjs";

@Component({
  selector: "app-form-amount-currency-pair",
  templateUrl: "form-amount-currency-pair.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AmountCurrencyPairFormComponent implements OnChanges {
  @HostBinding("class") class = "pko-amount-currency-pair";
  @Input() pairs!: CurrencyPair[] | null;
  @Input() currencies!: Currency[] | null;

  isMobile$ = this.breakpointObserver.isBreakpoint("xs");

  currency$!: Observable<Currency | undefined>;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private breakpointObserver: BreakpointObserverService
  ) {}

  ngOnChanges() {
    const { pairs, currencies } = this;

    if (!pairs?.length || !currencies?.length) return;

    const { currency, counterCurrency, currencyPair } = this.#assertForm();
    this.#setValidators();

    currencyPair.setValue(
      this.pairs?.find(
        ({ code }) => code.includes(currency.value) && code.includes(counterCurrency.value)
      )?.code
    );

    this.currency$ = currencyPair.valueChanges.pipe(
      startWith(currencyPair.value),
      tap((pair) => {
        currency.setValue(baseCurrency(pair));
        counterCurrency.setValue(quoteCurrency(pair));
      }),
      map((pairCode) => {
        return this.currencies?.find((currency) => currency.code === baseCurrency(pairCode));
      })
    );
  }

  #assertForm() {
    const { currency, counterCurrency, currencyPair, amount } = this.#controls;

    if (!currency || !counterCurrency || !currencyPair || !amount) {
      throw new Error(
        "This component needs 'currency', 'counterCurrency', 'currencyPair' and 'amount' controls present on the form!"
      );
    }

    return { currency, counterCurrency, currencyPair, amount };
  }

  #setValidators() {
    const { currencyPair, amount } = this.#controls;

    currencyPair.setValidators(Validators.required);
    amount.setValidators(Validators.required);

    amount.updateValueAndValidity();
  }
}
