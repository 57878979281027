import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { HolidaysService } from "@features/currency/holidays.service";
import { getSpotDate, TenorDate, TenorDateForm, TenorService } from "@features/tenor";
import { EMPTY, Observable, tap } from "rxjs";
import { multiFxProductsByTenor, MULTIFX_PRODUCT_TYPES } from "../../models/multifx-form";

@Component({
  selector: "app-form-multifx-tenor-date-product",
  templateUrl: "./form-multifx-tenor-date-product.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormMultifxTenorDateProductComponent implements OnInit {
  @Input() currencyPair!: Observable<string>;
  #spotDate = "";

  tenorDates$: Observable<TenorDate[]> = EMPTY;
  holidays$: Observable<string[]> = EMPTY;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private tenorService: TenorService,
    private holidayService: HolidaysService
  ) {}

  ngOnInit() {
    this.#assertForm();

    this.tenorDates$ = this.tenorService
      .getTenorDatesByCurrencyPair(this.currencyPair)
      .pipe(tap((tenorDates) => (this.#spotDate = getSpotDate(tenorDates))));

    this.holidays$ = this.holidayService.getHolidaysByCurrencyPair(this.currencyPair);
  }

  async onChanged({ tenor, date }: TenorDateForm) {
    if (!date) return;

    const nextProduct = getProduct(tenor, date, this.#spotDate);
    const currentProduct = this.#controls.product.value;

    if (nextProduct && currentProduct !== nextProduct) {
      this.#controls.product.setValue(nextProduct);
    }
  }

  #assertForm() {
    const { product } = this.#controls;

    if (!product) {
      throw new Error("This component needs 'product' control present on the form!");
    }

    return { product };
  }
}

const getProduct = (tenor: string | undefined | null, date: string, spotDate: string) => {
  if (!tenor && !date) return;

  const [pre, post] = [MULTIFX_PRODUCT_TYPES.short, MULTIFX_PRODUCT_TYPES.forward];

  const product = multiFxProductsByTenor[tenor as keyof typeof multiFxProductsByTenor];
  const preSpot = new Date(date) < new Date(spotDate);

  return product ?? (preSpot ? pre : post);
};
