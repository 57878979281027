<div class="pko-grid__row" *ngIf="status$ | async as status">
  <ng-container *ngIf="status.spot">
    <ng-container
      *ngTemplateOutlet="
        details;
        context: {
          $implicit: 'JDGSPOT' | clientType: status.clientType,
          link: 'spot',
          status: status
        }
      "></ng-container>
  </ng-container>
  <ng-container *ngIf="status.new">
    <ng-container
      *ngTemplateOutlet="
        details;
        context: { $implicit: 'URN' | clientType: status.clientType, link: 'new', status: status }
      "></ng-container>
  </ng-container>
  <ng-container *ngIf="status.change">
    <ng-container
      *ngTemplateOutlet="
        details;
        context: {
          $implicit: 'URZ' | clientType: status.clientType,
          link: 'change',
          status: status
        }
      "></ng-container>
  </ng-container>
  <ng-container *ngIf="status.oneSpot">
    <ng-container
      *ngTemplateOutlet="
        details;
        context: {
          $implicit: 'SPOT1' | clientType: status.clientType,
          link: 'one-spot',
          status: status
        }
      "></ng-container>
  </ng-container>

  <ng-container *ngIf="!status.new && !status.change && !status.oneSpot && !status.spot">
    <ng-container *ngIf="infoPhones$ | async as infoPhones">
      {{
        (infoPhones.global
          ? "activation.NoApplicationAvailable"
          : "activation.NoApplicationAvailableShort"
        ) | translate: { local: infoPhones.local | phone, global: infoPhones.global | phone }
      }}
    </ng-container>
  </ng-container>
</div>

<div pko-preloader *ngIf="isBusy$ | async"></div>

<ng-template #details let-type let-link="link" let-status="status">
  <div class="pko-activation__subsection col-10 col-sm-5 mt-0">
    <h2 class="pko-title pko-title--2">{{ "activation.type." + type | translate }}</h2>
    <p class="text-sm mb-4">{{ "activation.type.description." + type | translate }}</p>
    <ng-container
      *ngTemplateOutlet="
        link === 'change' ? changeButton : applyButton;
        context: { $implicit: type, link: link, status: status }
      ">
    </ng-container>
  </div>
</ng-template>

<ng-template #applyButton let-type let-link="link" let-status="status">
  <button
    type="button"
    class="btn btn-primary mb-3"
    [routerLink]="'/contracts/' + link"
    [state]="{ manageUrl: manageUrl }">
    {{ "activation.type.buttons." + type | translate }}
  </button>
</ng-template>

<ng-template #changeButton let-type let-link="link" let-status="status">
  <button type="button" class="btn btn-primary" (click)="changeSelected(status)">
    {{ "activation.type.buttons." + type | translate }}
  </button>
</ng-template>
