import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
} from "@angular/core";
import { Subscription } from "rxjs";
@Directive({
  selector: "[scrolling]",
})
export class ScrollingDirective implements AfterViewInit, OnDestroy {
  @HostBinding("class.scrolled") isScrolled = false;
  @Input() scrollingAnchor!: HTMLDivElement;
  @Input() scrollingBody!: HTMLDivElement;

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.setHeaderHeight(this.el.nativeElement.offsetHeight);
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    this.scrollObserver();
  }

  #subscription = new Subscription();
  elementHeight = 0;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit() {
    this.scrollObserver();
  }

  scrollObserver() {
    const observer = new IntersectionObserver(
      ([entries]) => {
        const isEnoughSpace =
          this.document.documentElement.scrollHeight - window.innerHeight >
          this.el.nativeElement.offsetHeight;
        this.isScrolled = !Boolean(entries.isIntersecting) && isEnoughSpace;
        // Timeout allows header to render fully before taking its size after toggling "scrolled" class
        setTimeout(() => {
          this.setHeaderHeight(this.el.nativeElement.offsetHeight);
        }, 300);
      },
      {
        threshold: 0,
      }
    );
    this.#subscription.add(observer.observe(this.scrollingAnchor));
  }

  setHeaderHeight(height: number) {
    if (this.elementHeight !== height) {
      this.elementHeight = height;
      this.renderer.setProperty(this.scrollingBody, "style", `--header-height:${height}px`);
    }
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
  }
}
