import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfigStore } from "@core/config";
import { Failure } from "@core/models/transaction";
import { NavigationService } from "@core/navigation";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions, origins } from "@core/user-tracking/tracking.model";
import { SwapExchangeForm } from "@features/exchange/models";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { TileMessageComponent } from "@features/modal/tile-message/tile-message.component";
import {
  ComplexInitializationResponse,
  isInitializationWarning,
  isMifidError,
} from "@features/transaction/models";
import { getWarningDialog } from "@features/transaction/utils/dialog";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Dialog } from "@shared/components/modal/modal-model";
import { Observable, firstValueFrom, shareReplay, switchMap } from "rxjs";
import { pageClasses, prepareForm, tileClasses } from "../../exchange-utils";
import { ExchangeFormService, ExchangeResponseService, ExchangeService } from "../../services";

@Component({
  selector: "app-form-exchange-swap",
  templateUrl: "form-exchange-swap.component.html",
})
export class SwapExchangeFormComponent implements OnInit {
  @Input() isTile = false;

  form: FormGroup;
  link: string;
  errorMessage$: Observable<Dialog | null>;
  showForm = false;
  mifidValidation$: Observable<ComplexInitializationResponse>;
  @ViewChild("mifidWarnings", { read: ViewContainerRef }) mifidWarnings!: ViewContainerRef;

  back = () => this.navigationService.navigate(["/"]);

  get classes() {
    return this.isTile ? tileClasses : pageClasses;
  }

  constructor(
    fb: FormBuilder,
    private formService: ExchangeFormService,
    private exchangeService: ExchangeService,
    private responseService: ExchangeResponseService,
    public warningService: ExchangeWarningCodeService,
    store: ConfigStore,
    private navigationService: NavigationService,
    private elem: ElementRef,
    private tracker: Tracker
  ) {
    this.link = store.config.links.swapRisk;

    const form = formService.current as SwapExchangeForm;

    this.form = fb.group({
      side: form.side,
      currency: form.currency,
      counterCurrency: form.counterCurrency,
      amount: form.amount,
      account: form.account,
      counterAccount: form.counterAccount,
      nearSettlement: fb.group(form.nearSettlement),
      farSettlement: fb.group(form.farSettlement),
      pvp: form.pvp,
      product: null,
      collateral: fb.group(form.collateral ?? {}),
    });

    this.mifidValidation$ = this.exchangeService
      .validateMifid("FxSwap")
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.errorMessage$ = this.mifidValidation$.pipe(
      switchMap((response) => this.#handleMifidResponse(response))
    );
  }

  ngOnInit(): void {
    this.isTile ||
      this.tracker.follow(
        { process: "exchange", origin: history.state?.origin ?? "reload" },
        { type: "swap" }
      );
  }

  onSubmit() {
    this.formService.save(prepareForm(this.form));

    const componentSelector = this.elem.nativeElement.tagName.toLowerCase();

    if (handleFormErrors(this.form, componentSelector)) {
      this.isTile &&
        this.tracker.follow({ process: "exchange", origin: origins.TILE }, { type: "swap" });
      this.formService.submit({ variation: "swap" });
    }
  }

  async #handleMifidResponse(response: ComplexInitializationResponse) {
    if (isMifidError(response)) {
      if (isMifidError(response)) {
        return this.responseService.handleMifidError(response.error, this.back, this.isTile);
      }
    }
    if (isInitializationWarning(response)) {
      const resign = () => this.formService.change("spot", "tab");

      if (this.isTile) {
        const consents = await this.#onWarningsTile(response.warnings);
        if (!consents) {
          resign();
        }
      } else {
        await this.responseService.handleMifidWarnings(response.warnings, resign);
      }
    }
    this.showForm = true;
    return null;
  }

  async #onWarningsTile(warnings: Failure[]) {
    for (const { code, data } of warnings) {
      const message = this.mifidWarnings.createComponent(TileMessageComponent);
      message.instance.data = getWarningDialog(code, "transaction", data);
      const accepted = await firstValueFrom(message.instance.clicked);
      this.mifidWarnings.clear();

      this.tracker.report({
        category: "exchange",
        action: actions.WARNING,
        data: { code, accepted: accepted ?? false },
      });

      if (!accepted) return;
    }

    return warnings.map(({ code }) => code);
  }
}
