import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalButton, ModalButtons, ModalType } from "../modal-model";

@Component({
  selector: "[app-modal-footer]",
  templateUrl: "modal-footer.component.html",
})
export class ModalFooterComponent {
  @Input() buttons!: ModalButtons;
  @Input() type: ModalType | string = "modal";

  @Output() clicked = new EventEmitter<unknown>();

  get primaryDisabled() {
    return isDisabled(this.buttons.primary);
  }

  get secondaryDisabled() {
    return isDisabled(this.buttons.secondary);
  }

  async click(button: ModalButton): Promise<void> {
    await button.onClick?.();
    this.clicked.emit(button.resultOnClose);
  }
}

const isDisabled = (button?: ModalButton) => button?.disabledReason ?? button?.disabled?.();
