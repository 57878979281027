import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AbstractCheckbox } from "./abstract-checkbox.directive";

let nextUniqueId = 0;

/**
 * Following the [Angular Material example](https://github.com/angular/components/blob/main/src/material/checkbox/checkbox.ts),
 * checkbox is not a directive, but a self-contained component. It can be used with or without a formControl.
 * Note that it has no validation.
 */
@Component({
  selector: "app-checkbox",
  template: `
    <div class="form-check">
      <input
        #input
        type="checkbox"
        role="checkbox"
        class="form-check-input"
        [id]="id"
        (change)="$event.stopPropagation()"
        (click)="onInputClick()"
        (blur)="onTouched()"
        [disabled]="disabled"
        [checked]="checked" />
      <label #label [for]="id" class="form-check-label">
        <ng-content></ng-content>
      </label>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CheckboxComponent }],
})
export class CheckboxComponent extends AbstractCheckbox {
  @HostBinding("class") class = "d-block";
  readonly #uniqueId = `checkbox-${++nextUniqueId}`;

  @Input() ngId?: string;

  get id() {
    return this.ngId || this.#uniqueId;
  }
}
