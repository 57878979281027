import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { EMPTY, catchError, map, of, shareReplay, tap } from "rxjs";
import { Account, DefaultAccounts, PvpRules } from "./accounts-model";
import { groupByCurrency } from "./accounts-utils";

@Injectable({ providedIn: "root" })
export class AccountsService {
  #observedEvents = [AppEvent.CustomerChanged, AppEvent.TransactionSuccess];

  #technicalCustomerNumber?: string;

  exchangeAccounts$ = this._events.observe(
    () => this._http.get<Account[]>("/accounts/exchange").pipe(catchError(() => EMPTY)),
    ...this.#observedEvents
  );

  depositAccounts$ = this._events.observe(
    () => this._http.get<Account[]>("/accounts/deposit").pipe(catchError(() => EMPTY)),
    ...this.#observedEvents
  );

  premiumDepositAccounts$ = this._events.observe(
    () => this._http.get<Account[]>("/accounts/premium-deposit").pipe(catchError(() => EMPTY)),
    ...this.#observedEvents
  );

  collateralAccounts$ = this._events.observe(
    () => this._http.get<Account[]>("/accounts/collateral").pipe(catchError(() => EMPTY)),
    ...this.#observedEvents
  );

  pvp$ = this._http.get<PvpRules>("/accounts/pvp").pipe(
    catchError(() => EMPTY),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(private _http: HttpClient, private _events: AppEventsService) {}

  getAccounts =
    (type: "exchange" | "deposit" | "premiumDeposit" | "order" | "iob" | "fastDeal") =>
    (currency: string) => {
      const byCurrencyAndExternal = (account: Account) =>
        // only exchange can have external accounts.
        account.currency === currency && (type === "exchange" || !account.isExternal);

      return this.#getSource(type).pipe(map((accounts) => accounts.filter(byCurrencyAndExternal)));
    };

  getDefaultAccounts = this._events.observe(
    () =>
      this._http.get<DefaultAccounts>("/accounts/default").pipe(
        catchError(() => of({ accounts: [], technicalCustomerNumber: "" })),
        tap(
          ({ technicalCustomerNumber }) => (this.#technicalCustomerNumber = technicalCustomerNumber)
        ),
        map(({ accounts }) => groupByCurrency(accounts))
      ),
    ...this.#observedEvents
  );

  saveDefaultAccounts(accounts: Account[]) {
    return this._http
      .put<void>("/accounts/default", {
        technicalCustomerNumber: this.#technicalCustomerNumber,
        accounts,
      })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  #getSource = (type: "exchange" | "deposit" | "premiumDeposit" | "order" | "iob" | "fastDeal") => {
    switch (type) {
      case "deposit":
        return this.depositAccounts$;
      case "premiumDeposit":
        return this.premiumDepositAccounts$;
      default:
        return this.exchangeAccounts$;
    }
  };
}
