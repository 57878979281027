<ng-container *ngIf="isBusy$ | async"
  ><ng-container *ngTemplateOutlet="loading"></ng-container
></ng-container>
<div class="pko-header d-none d-md-flex">
  <h1 class="pko-title">{{ "pages.InvestmentDeposit" | translate }}</h1>
</div>
<app-alert
  *ngIf="warningCodeService.outOfTargetMarket"
  icon="critical"
  content="alert.Content.OutOfTargetMarket"
  context="danger">
</app-alert>
<ng-container *ngIf="emptyMessage$ | async as emptyMessage"></ng-container>
<form
  class="pko-form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  *ngIf="initialization$ | async; else loading">
  <div class="pko-grid__row">
    <app-form-control
      label="investment-deposit.Currency"
      validate="currency"
      class="col-10 col-md-5 col-lg-4">
      <app-select-flag
        formControlName="currency"
        [items]="currencies$ | async"
        placeholder="placeholders.DepositCurrency"
        ngDefaultControl>
      </app-select-flag>
    </app-form-control>
    <div class="w-100"></div>
    <app-form-control label="Amount" validate="amount" class="col-10 col-md-5 col-lg-4">
      <app-input-number
        formControlName="amount"
        placeholder="placeholders.Amount"
        [decimals]="decimals$ | async"
        ngDefaultControl>
      </app-input-number>
    </app-form-control>
    <div class="w-100"></div>
    <app-form-currency-pair
      label="investment-deposit.CurrencyPair"
      [pairs]="pairs$ | async"
      [disableSingleValue]="false"
      class="col-10 col-md-5 col-lg-4"></app-form-currency-pair>
    <div class="w-100"></div>
    <app-form-date-bounds
      class="col-10 col-md-5 col-lg-4"
      transactionType="investment-deposit"
      [getTenorDates]="getTenorDates()"
      [disableSingleValue]="false"
      [plnHolidaysOnly]="true"
      endTenorPlaceholder="placeholders.SelectTenorShort"></app-form-date-bounds>
    <div class="w-100"></div>
    <app-select-account
      label="accounts.From"
      validate="account"
      formControlName="account"
      class="col-10 col-md-5 col-lg-4"
      [items]="accounts$ | async"
      placeholder="placeholders.SelectAccount"
      [useDefault]="false"
      ngDefaultControl></app-select-account>
    <div class="w-100"></div>
    <div *ngIf="offerConfig$ | async as offer" formGroupName="rates">
      <!-- <pre *ifIsDevMode>{{ offer | json }}</pre> -->
      <app-slider
        [min]="offer.minimalInterestRate"
        [max]="offer.maximalInterestRate"
        [step]="0.05"
        ngId="interestRate"
        label="investment-deposit.WarrantedInterestRate"
        tooltip="investment-deposit.WarrantedInterestRateTooltip"
        labelSuffix="%"
        class="col-10 col-md-5 col-lg-4"
        formControlName="interestRate"></app-slider>
      <app-slider
        [min]="offer.midSpotRate | minInvDepoRate: offer.maxDeviation"
        [max]="offer.midSpotRate | maxInvDepoRate: offer.maxDeviation"
        [step]="offer.midSpotRate | stepInvDepoRate"
        [minSpread]="offer.minSpread / 10000"
        ngId="rate"
        numberFormat="1.4-4"
        label="investment-deposit.ExpectedRate"
        [labelData]="{ pair: (this.form.controls.currencyPair.value | pair) ?? '' }"
        tooltip="investment-deposit.ExpectedRateTooltip"
        [tooltipData]="{ pair: (this.form.controls.currencyPair.value | pair) ?? '' }"
        class="col-10 col-md-5 col-lg-4"
        formControlName="rate"
        type="range"></app-slider>
      <app-select-deviation
        class="col-10 col-md-5 col-lg-4"
        [midSpotRate]="offer.midSpotRate"
        [deviationRange]="offer.maxDeviation - offer.minSpread"
        [numberOfRanges]="offer.numberOfRateRanges">
      </app-select-deviation>
      <!-- <pre *ifIsDevMode>{{ form.value | json }}</pre> -->
    </div>
  </div>
  <app-form-footer type="investment-deposit"></app-form-footer>
</form>

<ng-template #loading>
  <div pko-preloader></div>
</ng-template>
