import { Pipe, PipeTransform } from "@angular/core";
import { Application, getId } from "../activation.model";

@Pipe({
  name: "applicationId",
})
export class ApplicationIdPipe implements PipeTransform {
  transform(item: Application) {
    return getId(item);
  }
}
