import { Component, HostBinding } from "@angular/core";
import { UserData, UserService } from "@core/session";

@Component({
  selector: "app-client-data-simple",
  templateUrl: "./client-data-simple.component.html",
})
export class ClientDataSimpleComponent {
  @HostBinding("class") class = "pko-activation__section position-relative";
  userData: UserData;

  constructor(userService: UserService) {
    this.userData = userService.userData;
  }
}
