import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { Failure, reverseSide } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { ModalService } from "@features/modal/modal.service";
import { ProductType } from "@features/tenor";
import {
  ComplexInitializationResponse,
  RateFailure,
  StatusResponse,
  isInitializationSuccess,
  isInitializationWarning,
  isMifidError,
  isRateFailure,
  isStatusSuccess,
} from "@features/transaction/models";
import { TransactionResponseService } from "@features/transaction/services/response.service";
import { getWarningDialog } from "@features/transaction/utils/dialog";
import { BodyLine, Dialog, ModalType } from "@shared/components/modal/modal-model";
import { formatNumber } from "@shared/utils/format";
import { SimilarDealsWarningComponent } from "../../transaction/components/similar-deals-warning/similar-deals-warning.component";
import { DecisionComponent } from "../components";
import { ConfirmationData, ExchangeRateResponse, ExchangeRateSuccess } from "../models";
import { ExchangeVariation, InitializationResponseWrapper } from "../models/initialization";

@Injectable({ providedIn: "root" })
export class ExchangeResponseService {
  #display: ModalType = "page";

  constructor(
    private modal: ModalService,
    private transaction: TransactionResponseService,
    private events: AppEventsService,
    private tracker: Tracker
  ) {}

  async handleMifidResponse(
    response: ComplexInitializationResponse,
    variation: ExchangeVariation,
    backFunction: () => void
  ) {
    if (isMifidError(response)) {
      this.handleMifidError(response.error, backFunction, false, variation);
    }

    if (isInitializationWarning(response)) {
      await this.handleMifidWarnings(response.warnings, backFunction);
    }
  }

  handleMifidError(
    error: Failure,
    backFunction: () => void,
    isTile: boolean,
    variation?: ExchangeVariation
  ) {
    if (isTile) {
      this.tracker.report({
        category: "exchange",
        action: actions.FAILURE,
        data: { error: error.code },
      });
    } else {
      this.tracker.reportProgress({
        action: actions.FAILURE,
        data: { error: error.code },
      });
    }

    const content: Dialog = {
      type: "failure",
      title: "exchange.ContactDealer",
      body: `errors.${error.code}`,
      buttons:
        variation === "close" || variation === "roll"
          ? {
              primary: {
                text: "buttons.back",
                onClick: () => backFunction(),
              },
            }
          : isTile
          ? undefined
          : {
              primary: {
                text: "buttons.home",
                onClick: () => backFunction(),
              },
            },
    };

    if (variation === "close" || variation === "roll") {
      this.modal.dialog(content, "page");
      return null;
    } else {
      return content;
    }
  }

  async handleMifidWarnings(warnings: Failure[], resignFunction: () => void) {
    const consents = await this.#onWarnings(warnings);

    if (!consents) {
      resignFunction();
    }
  }

  async handleInitResponse(input: InitializationResponseWrapper) {
    const { response, type, variation, modal, resubmit } = input;

    this.#display = modal ? "modal" : "page";

    if (isInitializationSuccess(response)) {
      if (!modal) {
        return this.transaction.decide("exchange", response.token, variation);
      }

      if (this.modal.hasOpenModals) return;
      return this.modal.modal({ component: DecisionComponent, data: { modal } });
    }

    if (isInitializationWarning(response)) {
      const consents = await this.#onWarnings(response.warnings);
      if (!consents) return;

      return resubmit(consents);
    }

    return this.#onError(type, response.error, variation);
  }

  async handleRateResponse(type: ProductType, rateResponse: ExchangeRateResponse) {
    if (isRateFailure(rateResponse)) {
      return this.#handleRateFailure(type, rateResponse);
    }

    const { balanceWarning } = rateResponse as ExchangeRateSuccess;
    if (!balanceWarning) return;

    this.tracker.reportProgress({ action: "warning", data: { code: balanceWarning.code } });
    // opposite to init warnings, accepting does nothing (proceeds with getRate)
    const accepted = await this.#onWarnings([balanceWarning]);
    accepted || this.#handleRejectedBalanceWarning();
  }

  #handleRejectedBalanceWarning() {
    if (this.#display === "modal") {
      this.modal.dismissAll();
    } else {
      this.transaction.retry();
    }
  }

  handleConfirmationError(type: ProductType) {
    this.#onError(type);
  }

  handleStatusResponse(type: ProductType, response: StatusResponse, summary: ConfirmationData) {
    isStatusSuccess(response)
      ? this.#onSuccess(type, response.transactionId, summary)
      : this.#onError(type, response.status);
  }

  #handleRateFailure(type: ProductType, { error }: RateFailure) {
    if (error.code !== "InsufficientFundsInfo") {
      return this.#onError(type, error);
    }

    // InsufficientFundsInfo is one special case
    const repeat = this.#display === "page" ? () => this.transaction.repeat() : undefined;
    const profile = () => this.transaction.go("/profile/transaction-control");
    const content: Dialog = {
      type: "info",
      title: `dialog.title.${error.code}`,
      body: `errors.${error.code}`,
      buttons: {
        primary: { text: "buttons.repeat.exchange", onClick: repeat },
        secondary: { text: "buttons.go.profile", onClick: profile },
      },
    };

    this.tracker.reportProgress({ action: actions.FAILURE, data: { type, error: error.code } });
    this.modal.dismissAll();
    this.modal.dialog(content, this.#display);
  }

  #onError(type: ProductType, error?: Failure, variation?: ExchangeVariation) {
    this.tracker.reportProgress({
      action: actions.FAILURE,
      data: { type: variation ?? type, error: error?.code ?? "unknown" },
    });
    this.transaction.error({ source: "exchange", error, modalType: this.#display }, variation);
  }

  /**
   * Displays warning dialogs in sequence, if user accepts.
   * @param warnings Warnings to display.
   * @returns If all warnings are accepted, returns their codes. Otherwise `undefined`.
   */
  async #onWarnings(warnings: Failure[]) {
    for (const { code, data } of warnings) {
      const dialog = getWarningDialog(code, "transaction", data);

      const accepted = await (this.#specialWarnings[code]
        ? this.modal.modal({
            component: this.#specialWarnings[code],
            data: { ...dialog, transactionType: "exchange" },
          })
        : this.modal.dialog(dialog)
      ).result // a dismissed modal calls Promise.reject()
        .catch(() => false);

      this.tracker.reportProgress({
        action: actions.WARNING,
        data: { code, accepted: accepted ?? false },
      });

      if (!accepted) return;
    }

    return warnings.map(({ code }) => code);
  }

  #specialWarnings: Record<string, any> = {
    HasSimilarDeal: SimilarDealsWarningComponent,
  };

  #onSuccess(type: ProductType, id: string, summary: ConfirmationData) {
    const { side, currency, rate, farRate } = summary;
    const counterCurrency = summary.counterCurrency.code;
    const amount = formatNumber(+summary.amount, { style: "currency", ...currency });

    const bodyLines: BodyLine[] = [
      {
        key: `exchange.success.${side}`,
        params: { amount, rate: formatNumber(rate, { decimals: 4 }), counterCurrency },
      },
    ];

    if (farRate) {
      bodyLines.push({
        key: `exchange.success.${reverseSide(side)}`,
        params: { amount, rate: formatNumber(farRate, { decimals: 4 }), counterCurrency },
      });
    }

    const repeatDisabledReason = isOpenBalanceFilled(summary)
      ? "exchange.OpenBalanceFilled"
      : undefined;

    this.events.emit(AppEvent.TransactionSuccess);

    this.tracker.reportProgress({
      action: actions.SUCCESS,
      data: { id, type },
    });

    this.transaction.success({
      source: "exchange",
      bodyLines,
      id,
      repeatDisabledReason,
      modalType: this.#display,
    });
  }
}

const isOpenBalanceFilled = ({ amount, original }: ConfirmationData) =>
  original?.openBalance === +amount;
