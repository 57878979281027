import { Component, HostBinding, Input, OnChanges, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, FormGroupDirective, Validators } from "@angular/forms";
import { BreakpointObserverService } from "@core/breakpoints";
import { ensureControlHasValue, getCurrencyByCode } from "@features/currency/currency-utils";
import { Currency, CurrencyPair } from "@features/currency/model";
import {
  EMPTY,
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  tap,
} from "rxjs";

@Component({
  selector: "app-form-amount-currencies",
  templateUrl: "amount-currencies.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AmountCurrenciesFormComponent implements OnInit, OnChanges {
  @HostBinding("class") class = "pko-amount-currency-pair";
  #subscription = new Subscription();

  @Input() currencies: Currency[] | null = [];
  @Input() pairs: CurrencyPair[] | null = [];

  currency$: Observable<Currency | undefined> = EMPTY;
  counterCurrencies$: Observable<Currency[]> = EMPTY;
  isMobile$ = this.breakpointObserver.isBreakpoint("xs");

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private breakpointObserver: BreakpointObserverService
  ) {}

  /**
   * This component relies on both @Inputs to properly function.
   * The assertion is done here so that the users don't need to worry
   * about synchronizing the sources.
   */
  ngOnChanges() {
    const { pairs, currencies } = this;

    if (!pairs?.length || !currencies?.length) return;

    const { currency, counterCurrency, currencyPair } = this.#controls;

    const ensureCurrencyHasValidValue = ensureControlHasValue(
      currency,
      currencies[0]?.code === counterCurrency.value ? 1 : 0
    );
    ensureCurrencyHasValidValue(currencies);
    const currency$ = currency.valueChanges.pipe(startWith(currency.value));
    const counterCurrency$ = counterCurrency.valueChanges.pipe(startWith(counterCurrency.value));

    const currencyPair$ = combineLatest([currency$, counterCurrency$]).pipe(
      debounceTime(100),
      // filter out the swap
      filter(([c1, c2]) => c1 !== c2),
      map(([c1, c2]) => pairs.find(({ code }) => code.includes(c1) && code.includes(c2))),
      filter(Boolean),
      distinctUntilChanged(),
      tap(({ code }) => currencyPair.setValue(code))
    );

    this.counterCurrencies$ = currency$.pipe(
      map((dealCurrency) => [dealCurrency, toCounterCurrencies(pairs, currencies)(dealCurrency)]),
      tap(([dealCurrency, currencies]) =>
        ensureControlHasValue(
          counterCurrency,
          currencies[0]?.code === dealCurrency ? 1 : 0
        )(currencies)
      ),
      map(([, currencies]) => currencies)
    );
    this.currency$ = currency$.pipe(map((code) => getCurrencyByCode(currencies, code)));
    this.#subscription.add(currencyPair$.subscribe());
    this.#subscription.add(currency$.pipe(swap(counterCurrency)).subscribe());
    this.#subscription.add(counterCurrency$.pipe(swap(currency)).subscribe());
  }

  ngOnInit() {
    this.#assertForm();
    this.#setValidators();
  }

  #assertForm() {
    const { currency, counterCurrency, currencyPair, amount } = this.#controls;

    if (!currency || !counterCurrency || !currencyPair || !amount) {
      throw new Error(
        "This component needs 'currency', 'counterCurrency', 'currencyPair' and 'amount' controls present on the form!"
      );
    }

    return { currency, counterCurrency, currencyPair, amount };
  }

  #setValidators() {
    const { currency, amount } = this.#controls;

    currency.setValidators(Validators.required);
    amount.setValidators(Validators.required);

    amount.updateValueAndValidity();
  }
}

const toCounterCurrencies = (pairs: CurrencyPair[], currencies: Currency[]) => (code: string) => {
  const counterCodes = pairs
    .filter((pair) => pair.code.includes(code))
    .map((currency) => currency.code.replace(code, ""));

  const counterCurrencies = currencies.filter(({ code }) => counterCodes.includes(code));
  const selectedCurrency = getCurrencyByCode(currencies, code);
  // adding currency to allow swap from counterCurrency.
  // pushing it last so it won't be picked as default - this causes errors down the stream
  selectedCurrency && counterCurrencies.push(selectedCurrency);

  return counterCurrencies;
};

const swap = (control: AbstractControl) => (source$: Observable<string>) =>
  source$.pipe(
    pairwise(),
    filter(([, next]) => next === control.value),
    tap(([previousCode]) => control.setValue(previousCode))
  );
