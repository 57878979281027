<div pko-preloader *ngIf="isBusy$ | async"></div>
<form class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="pko-grid__row">
    <app-form-amount-currency [currencies]="currencies$ | async"></app-form-amount-currency>
    <app-form-date-bounds
      transactionType="deposit"
      [getTenorDates]="getTenorDates"></app-form-date-bounds>
    <app-select-account
      label="accounts.From"
      validate="account"
      formControlName="account"
      [items]="accounts$ | async"
      ngDefaultControl></app-select-account>
  </div>
  <app-form-footer type="deposit" [back]="{ hidden: true }"></app-form-footer>
</form>
