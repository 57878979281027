<div class="pko-modal__container">
  <div class="pko-modal__header">
    <ng-container *ngTemplateOutlet="customHeaderTemplate ? customHeaderTemplate : headerTemplate">
    </ng-container>
  </div>

  <div class="pko-modal__body">
    <ng-content></ng-content>
  </div>

  <ng-container *ngTemplateOutlet="customFooterTemplate ? customFooterTemplate : footerTemplate">
  </ng-container>
</div>

<ng-template #subtitleTemplate>
  <ng-content select="[subtitle]"></ng-content>
</ng-template>

<ng-template #headerTemplate>
  <button
    type="button"
    class="pko-modal__close"
    [attr.aria-label]="'buttons.Close' | translate"
    (click)="close()"
    *ngIf="!config.hideCloseButton">
    <svg icon name="close_nav" class="icon icon--lg"></svg>
  </button>
  <h3 class="pko-modal__title" [class.d-inline-flex]="config.titleTooltip" *ngIf="config.title">
    {{ config.title | translate }}
    <app-tooltip *ngIf="config.titleTooltip" [content]="config.titleTooltip"></app-tooltip>
  </h3>
  <ng-container *ngIf="hasSubtitle">
    <p class="pko-modal__subtitle">
      <ng-container *ngIf="config.subtitle; else subtitleTemplate">
        {{ config.subtitle | translate }}
      </ng-container>
    </p></ng-container
  >
</ng-template>

<ng-template #footerTemplate>
  <div
    class="pko-modal__footer"
    *ngIf="config.buttons"
    [buttons]="config.buttons"
    (clicked)="onClicked($event)"
    app-modal-footer></div>
</ng-template>
