import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEventsService } from "@core/events/app-events.service";
import { NonForwardTenor } from "@features/tenor";
import { catchError, of } from "rxjs";
import { IndicativePair, IndicativeRate } from "./indicatives.model";

@Injectable({
  providedIn: "root",
})
export class IndicativesService {
  constructor(private http: HttpClient, private events: AppEventsService) {}

  getRates(pairs: string[], tenor: NonForwardTenor) {
    return this.http
      .get<IndicativeRate[]>("/indicatives/rates", {
        params: new HttpParams({
          fromObject: { pairs, tenor },
        }),
      })
      .pipe(catchError(() => of([])));
  }

  getPairs = this.events.observe(() => this.http.get<IndicativePair[]>("/indicatives/pairs"));

  setPairs(pairs: string[]) {
    return this.http.post<string[]>("/indicatives/pairs", pairs);
  }
}
