import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { HolidaysService } from "@features/currency/holidays.service";
import { ModalService } from "@features/modal/modal.service";
import {
  getProduct,
  getSpotDate,
  ProductType,
  TenorDate,
  TenorDateConfig,
  TenorDateForm,
  TenorDateVisibility,
  TenorService,
} from "@features/tenor";
import { defer, EMPTY, filter, map, Observable, startWith, tap } from "rxjs";

@Component({
  selector: "app-form-tenor-date-product",
  templateUrl: "tenor-date-product.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TenorDateProductFormComponent implements OnInit {
  @Input() show: TenorDateVisibility = "both";
  @Input() minTenor?: "SN";
  @Input() maxTenor?: "SPOT";

  #spotDate = "";
  #previousDate?: string;

  tenorDates$: Observable<TenorDateConfig> = EMPTY;
  holidays$: Observable<string[]> = EMPTY;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private tenorService: TenorService,
    private holidayService: HolidaysService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    const { currencyPair } = this.#assertForm();

    // defer ensures this observable emits 🤷‍♂️
    const currencyPair$: Observable<string> = defer(() =>
      currencyPair.valueChanges.pipe(startWith(currencyPair.value), filter(Boolean))
    );

    this.tenorDates$ = this.tenorService.getTenorDatesByCurrencyPair(currencyPair$).pipe(
      tap((tenorDates) => (this.#spotDate = getSpotDate(tenorDates))),
      map((tenorDates) => ({
        values: tenorDates,
        min: findDate(tenorDates, this.minTenor),
        max: findDate(tenorDates, this.maxTenor),
      }))
    );

    this.holidays$ = this.holidayService.getHolidaysByCurrencyPair(currencyPair$);
  }

  async onChanged({ tenor, date }: TenorDateForm) {
    if (!date) return;

    const nextProduct = getProduct({ tenor, date, spotDate: this.#spotDate });
    const currentProduct = this.#controls.product.value;

    if (nextProduct && currentProduct !== nextProduct) {
      if (await this.#shouldChange(currentProduct, nextProduct)) {
        this.#controls.product.setValue(nextProduct);
      } else {
        return this.#controls.settlement.patchValue({ date: this.#previousDate });
      }
    }

    this.#previousDate = date;
  }

  #assertForm() {
    const { currencyPair, product, settlement } = this.#controls;

    if (!currencyPair || !product || !settlement) {
      throw new Error(
        "This component needs 'settlement', 'currencyPair' and 'product' controls present on the form!"
      );
    }

    return { currencyPair, product };
  }

  async #shouldChange(current: ProductType, next: ProductType) {
    if (!current || next !== "FxForw") return true;

    const allow = await this.modal.dialog({
      title: "modals.forwardSwitch.Title",
      buttons: {
        primary: { text: "yesno.true", resultOnClose: true },
        secondary: { text: "buttons.Resign" },
      },
    }).result;

    return !!allow;
  }
}

const findDate = (td: TenorDate[], t?: string) => td.find(({ tenor }) => tenor === t)?.date;
