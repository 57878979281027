<ng-container *ngIf="tenorDates$ | async as tenorDates">
  <app-form-tenor-date
    formGroupName="nearSettlement"
    label="exchange.NearSettlementDate"
    [tenorDates]="tenorDates.near"
    [holidays]="holidays$ | async">
  </app-form-tenor-date>

  <app-form-tenor-date
    formGroupName="farSettlement"
    label="exchange.FarSettlementDate"
    [tenorDates]="tenorDates.far"
    [holidays]="holidays$ | async"
    (changed)="onChanged($event)">
  </app-form-tenor-date>
</ng-container>
