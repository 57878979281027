import { Component, Input } from "@angular/core";
import { InvestmentDepositSummary } from "@features/investment-deposit/investment-deposit.model";

@Component({
  selector: "app-interest-rate-info",
  templateUrl: "./interest-rate-info.component.html",
})
export class InterestRateInfoComponent {
  @Input() detail!: InvestmentDepositSummary;

  get maximalInterestAmount() {
    return this.detail.maximalInterestAmount || null;
  }

  get standardInterestAmount() {
    return this.detail.standardInterestAmount || null;
  }

  get maximalRate() {
    return this.detail.maximalRate || null;
  }
}
