import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlagDefsComponent } from "@core/components/flag-defs/flag-defs.component";
import { IconDefsComponent } from "@core/components/icon-defs/icon-defs.component";
import { CoreModule } from "@core/core.module";
import { PseudoRouterDirective } from "@core/navigation/pseudo-router.directive";
import { CustomerModule } from "@features/customer/customer.module";
import { ModalModule } from "@features/modal/modal.module";
import { ProlongSessionModule } from "@features/prolong-session/prolong-session.module";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FlowDeclarationModule } from "@features/flow-declaration/flow-declaration.module";
import { LeiModule } from "@features/lei/lei.module";

@NgModule({
  declarations: [AppComponent, FlagDefsComponent, IconDefsComponent, PseudoRouterDirective],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    CustomerModule,
    TranslateModule,
    ModalModule,
    FlowDeclarationModule,
    LeiModule,
    ProlongSessionModule,
    AppRoutingModule, // should be last https://angular.io/guide/router-tutorial-toh#module-import-order
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
