import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { DataResult } from "@core/models/data-result";
import { Observable, catchError, map, of } from "rxjs";
import { RecentDeal, UnsettledDeal } from "./recent-history.model";

@Injectable({
  providedIn: "root",
})
export class RecentHistoryService {
  #observedEvents = [AppEvent.CustomerChanged, AppEvent.TransactionSuccess];

  constructor(private _http: HttpClient, private events: AppEventsService) {}

  getRecentDeals: Observable<DataResult<RecentDeal[]>> = this.events.observe(
    () =>
      this._http.get<RecentDeal[]>("/history/recent").pipe(
        map((data) => ({ data })),
        catchError(() => of({ error: true }))
      ),
    ...this.#observedEvents
  );

  getUnsettledDeals: Observable<DataResult<UnsettledDeal[]>> = this.events.observe(
    () =>
      this._http.get<UnsettledDeal[]>("/history/unsettled").pipe(
        map((data) => ({ data })),
        catchError(() => of({ error: true }))
      ),
    ...this.#observedEvents
  );
}
