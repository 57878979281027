import { Component, OnInit } from "@angular/core";
import { Tracker } from "@core/user-tracking/tracker.service";
import { DashboardService } from "@features/dashboard/dashboard.service";
import { MessagesService } from "@features/messages/messages.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  showSimple$ = this.dashboardService.simple$;

  constructor(
    private dashboardService: DashboardService,
    private messagesService: MessagesService,
    private tracker: Tracker
  ) {}

  ngOnInit(): void {
    this.tracker.report({ category: "login", action: "dashboard" });
    setTimeout(() => this.messagesService.showUnreadMessages(), 0);
  }
}
