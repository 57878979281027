import { Component, EventEmitter, Host, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, ControlContainer, FormGroup } from "@angular/forms";
import { ValidationErrorOverrides } from "@shared/components/controls";
import { assertIsDefined } from "@utils/misc";
import { addDays, datepickerFormat } from "@utils/time";
import {
  Observable,
  Subscription,
  debounceTime,
  delay,
  filter,
  map,
  startWith,
  withLatestFrom,
} from "rxjs";
import { TenorDate, TenorDateConfig, TenorDateForm, TenorDateVisibility } from "../tenor-model";

@Component({
  selector: "app-form-tenor-date",
  templateUrl: "tenor-date.component.html",
})
export class TenorDateFormComponent implements OnInit, OnDestroy {
  #subscription?: Subscription;
  #tenorDates: TenorDate[] = [];
  #tenors: string[] = [];
  #minDate?: string;
  #maxDate?: string;

  @Output() changed = new EventEmitter<TenorDateForm>();

  @Input() label?: string;
  @Input() show: TenorDateVisibility = "both";
  @Input() holidays?: string[] | null;
  @Input() restricted?: string[] | null;
  @Input() translateText: string | false = false;
  @Input() tenorPlaceholder?: string;
  @Input("errors") errorOverrides?: ValidationErrorOverrides;
  @Input() disableSingleValue = true;

  @Input() set tenorDates(arrayOrConfig: TenorDateConfig | TenorDate[] | null) {
    const { values, min, max } = parseConfig(arrayOrConfig);

    this.#tenorDates = values;
    this.#minDate = min;
    this.#maxDate = max;
    this.#tenors = values.flatMap(({ tenor }: TenorDate) => tenor);

    if (!values.length) return;
    this.#updateValueAndValidity();
  }

  constructor(@Host() private parent: ControlContainer) {}

  get group() {
    return this.parent.control as FormGroup;
  }

  get showTenor() {
    return this.show === "both" || this.show === "tenor";
  }

  get showDate() {
    return this.show === "both" || this.show === "date";
  }

  get minDate() {
    return this.#minDate;
  }

  get maxDate() {
    return this.#maxDate;
  }

  get tenors() {
    return this.#tenors;
  }

  ngOnInit() {
    this.#assertForm();
    this.#observeChanges();
    this.#updateValueAndValidity();
  }

  ngOnDestroy() {
    this.#subscription?.unsubscribe();
  }

  #assertForm() {
    const { tenor, date } = this.group.controls;

    assertIsDefined(tenor);
    assertIsDefined(date);

    return { tenor, date };
  }

  #observeChanges() {
    if (!this.showDate) return;

    const { tenor, date } = this.group.controls;

    // debouncing so we don't emit twice, once for tenor change, once for date.
    const group$ = this.group.valueChanges.pipe(debounceTime(100));

    const tenor$ = date.valueChanges.pipe(this.#mapTo("tenor"), filterValidFor(tenor));

    const date$ = tenor.valueChanges.pipe(
      this.#mapTo("date"),
      filterValidFor(date),
      takeSpecialCareOfDate()
    );

    // in hindsight, this emitter was a mistake. Components should simply connect to the stream.
    this.#subscription = group$.subscribe((next) => this.changed.emit(next));
    this.#subscription.add(tenor$.subscribe((next) => tenor.setValue(next)));
    this.#subscription.add(date$.subscribe((next) => date.setValue(next)));
  }

  /**
   * Forces the valueChanges pipe to run, if possible.
   */
  #updateValueAndValidity() {
    const { tenor, date } = this.group.controls;

    if (tenor.value) {
      tenor.updateValueAndValidity();
    } else if (date.value) {
      date.updateValueAndValidity();
    }
  }

  /**
   * Maps tenor to the corresponding date or vice versa.
   * @param targetProp The other TenorDate prop to map to.
   */
  #mapTo(targetProp: "date" | "tenor") {
    const sourceProp = targetProp === "date" ? "tenor" : "date";
    const toTarget = (selected: string) =>
      this.#tenorDates?.find((tenorDate) => tenorDate[sourceProp] === selected)?.[targetProp] ??
      null;

    return (source$: Observable<string>) => source$.pipe(filter(Boolean), map(toTarget));
  }
}

const parseConfig = (arrayOrConfig: TenorDateConfig | TenorDate[] | null) => {
  if (!arrayOrConfig) return { values: [] };

  const { values, min, max, exclusive }: TenorDateConfig = Array.isArray(arrayOrConfig)
    ? { values: arrayOrConfig }
    : arrayOrConfig;

  let tenorDates = [...values];

  const minDate = exclusive ? min && datepickerFormat(addDays(new Date(min), 1)) : min;
  const maxDate = exclusive ? max && datepickerFormat(addDays(new Date(max), -1)) : max;

  if (minDate) {
    tenorDates = tenorDates.filter(({ date }) => date >= minDate);
  }

  if (maxDate) {
    tenorDates = tenorDates.filter(({ date }) => date <= maxDate);
  }

  return {
    values: tenorDates,
    min: minDate ?? values.at(0)?.date,
    max: maxDate ?? values.at(-1)?.date,
  };
};

/**
 * This stream ensures we don't enter an infinite loop of cross updates.
 * Tenor and date are interconnected, and they must be set with `{ emitEvent: true }`
 * for ngbDatepicker to apply formatting (and possibly for some other reasons too).
 * @param previous$ `valueChanges` of the control that might be updated
 * @returns only values that should be updated in the controls.
 */
const filterValidFor = (target: AbstractControl) => (next$: Observable<string | null>) =>
  next$.pipe(
    withLatestFrom(target.valueChanges.pipe(startWith(target.value))),
    filter(([next, prev]) => next !== prev),
    map(([next]) => next)
  );

/**
 * Delaying the update to allow ngbDatepicker's validators (i.e. disabled dates) to update first.
 * Otherwise dgbDatepicker might see it as a "disabled" date (synchronization issue) and remove it.
 */
const takeSpecialCareOfDate = () => (source$: Observable<string | null>) =>
  // filter is just in case, date won't be null if tenorDates are valid.
  source$.pipe(filter(Boolean), delay(0));
