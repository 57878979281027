<form [formGroup]="form">
  <app-select-flag
    formControlName="language"
    ngId="select-flag"
    [items]="languages"
    [bindLabel]="bindLabel"
    bindIcon="code"
    [translateLabel]="translateLabel"
    ngDefaultControl>
  </app-select-flag>
</form>
