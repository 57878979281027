import { TransactionType } from "@features/transaction/models";

export type ModalType = "modal" | "page";
export type DialogType = "success" | "warning" | "failure" | "info";
export type BodyLine = { key: string; params?: Record<string, unknown> };
export type DialogBody = string | BodyLine | BodyLine[];

/**
 * Base interface for any modal popup
 */
export interface Modal {
  title?: string;
  titleTooltip?: string;
  subtitle?: string;
  buttons?: ModalButtons;
  scrollable?: boolean;
  hideCloseButton?: boolean;
  translateTitle?: boolean;
}

/**
 * Popup (or page) that facilitates communication (i.e. dialog) with the user,
 * usually in a form of a question with yes/no buttons or similar.
 */
export interface Dialog extends Modal {
  /** Typed modals have their respective icons. Empty for a plain dialog. */
  type?: DialogType;
  body?: DialogBody;
  style?: string;
  translateBody?: boolean;
  transactionType?: TransactionType;
}

export interface ModalButton {
  text: string;
  // This is passed to the result Promise when the modal is closed.
  // We could even resign from `onClick`, and pass that callback here,
  // then call it from the caller.
  resultOnClose?: unknown;
  onClick?: () => Promise<unknown> | unknown;
  disabled?: () => boolean;
  disabledReason?: string;
}

export interface ModalButtons {
  primary?: ModalButton;
  secondary?: ModalButton;
}

/**
 * Basic **OK** button for accepting/dismissing a modal.
 * When clicked, the modal resolves with `true`, if needed.
 */
export const defaultPrimary: ModalButton = { text: "OK", resultOnClose: true };
