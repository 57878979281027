import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { BreakpointObserverService } from "@core/breakpoints";
import { BusyService } from "@core/loading/busy.service";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session";
import { GraphModalComponent } from "@features/graph/components/graph-modal.component";
import { MobileGraphComponent } from "@features/graph/components/mobile-graph.component";
import { GraphModel, IndicativeGraph } from "@features/graph/graph.model";
import darkTheme from "@features/graph/themes/graph.dark.theme";
import lightTheme from "@features/graph/themes/graph.light.theme";
import { IndicativePair } from "@features/indicatives/indicatives.model";
import { ModalService } from "@features/modal/modal.service";
import * as Highcharts from "highcharts/highstock";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-indicative-chart",
  templateUrl: "./indicative-chart.component.html",
})
export class IndicativeChartComponent implements OnInit {
  @HostBinding("class") class = "pko-indicative__chart position-relative";
  @Input() pair!: IndicativePair;
  @Input() data$!: Observable<IndicativeGraph>;

  graph$?: Observable<GraphModel>;

  get darkMode(): boolean {
    return this.userService.isDarkMode;
  }

  isBusy$ = this.busyService.observeOn("/graph");
  currentBreakpoint$ = this.breakpointObserver.currentBreakpoint$;

  get selectedTenor() {
    return this.preferences.indicatives.tenor;
  }

  constructor(
    private userService: UserService,
    private busyService: BusyService,
    private preferences: PreferencesService,
    private modalService: ModalService,
    private breakpointObserver: BreakpointObserverService
  ) {
    this.darkMode ? darkTheme(Highcharts) : lightTheme(Highcharts);
  }

  ngOnInit(): void {
    this.graph$ = this.data$.pipe(map((data) => this.setChartOptions(data)));
  }

  openModal() {
    const small = this.breakpointObserver.isMatched("xs");
    this.modalService.modal({
      component: small ? MobileGraphComponent : GraphModalComponent,
      data: {
        currencyPair: this.pair.code,
        tenor: this.selectedTenor,
        side: "Buy",
      },
      size: "xl",
      modalDialogClass: `modal-fullscreen-sm-down${small ? " modal-details" : ""}`,
    });
  }

  private setChartOptions(data: IndicativeGraph) {
    const points = data?.points;
    if (!points?.length) {
      return {} as GraphModel;
    }

    const chartOptions = {
      global: { useUTC: true },
      credits: { enabled: false },
      tooltip: { enabled: false },
      navigator: { enabled: false },
      scrollbar: { enabled: false },
      rangeSelector: { enabled: false },
      plotOptions: {
        series: {
          lineWidth: 1.5,
          allowPointSelect: false,
          enableMouseTracking: false,
          marker: {
            enabled: false,
          },
        },
      },
      xAxis: {
        visible: false,
        min: Math.min(...points.map((x) => x.time)),
        max: Math.max(...points.map((x) => x.time)),
      },
      yAxis: {
        visible: false,
        endOnTick: false,
        startOnTick: false,
        min: Math.min(...points.map((x) => x.mid)) - 0.0002,
        max: Math.max(...points.map((x) => x.mid)) + 0.0002,
      },
      chart: {
        zoomType: "",
        pinchType: "",
        panning: false,
        margin: [0, 0, 0, 0],
      },
      series: [
        {
          name: "mid",
          data: points.map((x) => [x.time, x.mid]),
          color: this.darkMode ? "#006EF5" : "#004c9a",
        },
      ],
      exporting: {
        enabled: false,
      },
    };

    return {
      Highcharts: Highcharts,
      chartOptions,
      oneToOneFlag: true,
      update: true,
      runOutsideAngularFlag: false,
    } as GraphModel;
  }
}
