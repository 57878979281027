<div class="pko-modal">
  <div class="pko-modal__container" *ngIf="modalConfig">
    <div class="pko-modal__header">
      <button
        type="button"
        class="pko-modal__close"
        [attr.aria-label]="'buttons.close' | translate"
        (click)="close()"
        *ngIf="!modalConfig.hideCloseButton">
        <svg icon name="close_nav" class="icon icon--lg"></svg>
      </button>
      <h3 class="pko-modal__title">
        {{ data.title }}
      </h3>
      <p class="pko-modal__subtitle text-xs text-muted">
        {{ data.publishDate | datePko }}
      </p>
    </div>

    <div class="pko-modal__body my-3" [innerHTML]="data.content"></div>

    <div
      class="pko-modal__footer"
      *ngIf="modalConfig.buttons"
      [buttons]="modalConfig.buttons"
      app-modal-footer></div>
  </div>
</div>
