import { ArgumentError } from "./errors";

/**
 * You can await this async function instead of using setTimeout directly.
 */
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function raise(errorMessage: string): never {
  throw new Error(errorMessage);
}

export function assertIsDefined<T>(
  value: T,
  message = `Expected parameter to be defined, but received ${value}`
): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(message);
  }
}

export function booleanAttribute(value: unknown) {
  return typeof value === "boolean" ? value : value != null && value !== "false";
}

/**
 * A simple type guard that uses a custom predicate. So it's not type-safe.
 * @returns Whether given value is of declared type, based on the passed predicate.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOfType = <T>(value: any, predicate: (v: any) => boolean): value is T =>
  predicate(value);

export type Flatten<T> = T extends Array<infer Item> ? Item : T;

export type KeysMatching<T, V> = { [P in keyof T]-?: T[P] extends V ? P : never }[keyof T];

/**
 * Make all properties in T optional or null.
 * @see Required
 * @see Partial
 */
export type Nullable<T> = { [K in keyof T]?: T[K] | null };

/**
 * Make some properties in T required
 * @see Required
 */
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

/**
 * Add null and undefined to T
 * @see NonNullable
 */
export type Maybe<T> = T | null | undefined;

export type Either<L, R> = L | R;

/**
 *
 * @param {string} value - The string to be parsed
 * @returns - `true` if `value` is `"true"` or `"True"`
 *
 * - `false` if `value` is `"false"` or `"False"`
 * @throws *{@link ArgumentError}*
 */
export function parseBoolean(value: string) {
  switch (value) {
    case "false":
    case "False":
      return false;
    case "true":
    case "True":
      return true;
    default:
      throw new ArgumentError("value", value);
  }
}
