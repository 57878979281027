<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="ratesControlEnabled">
    <div class="pko-deviations">
      <p class="d-flex justify-content-center text-sm">
        {{ "investment-deposit.SelectDeviation" | translate }}
      </p>
      <div class="pko-grid__row pko-grid__row--12 justify-content-center">
        <div
          class="pko-deviation {{ numberOfRanges > 2 ? 'col-3' : 'col-6' }}"
          *ngFor="let deviation of vm.deviations">
          <div
            class="pko-deviation__tile btn {{
              vm.selectedIndex === deviation.key
                ? 'btn-primary pko-deviation__tile--selected'
                : 'btn-secondary'
            }}"
            (click)="selectDeviation(deviation.value)">
            -{{ deviation.value / 100 | number : "1.0-2" }}{{ vm.currency | minorUnit }}/+{{
              deviation.value / 100 | number : "1.0-2"
            }}{{ vm.currency | minorUnit }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
