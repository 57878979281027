<ng-container *ngTemplateOutlet="stack ? stackTemplate : tableTemplate"></ng-container>

<ng-template #tableTemplate>
  <td align="center" pkoTableCell>
    {{ index + 1 }}
  </td>
  <td align="center" pkoTableCell>{{ "multifx.types." + multiFxLeg.product | translate }}</td>
  <td align="center" pkoTableCell>
    {{ "sides." + multiFxLeg.side | translate }}
  </td>
  <td align="end" pkoTableCell>
    {{ multiFxLeg.amount | balance : (currency | async) || "" }}
  </td>
  <td align="end" pkoTableCell>{{ multiFxLeg.settlement.date | datePko }}</td>
  <td [actionMenu]="true" pkoTableCell>
    <app-action-menu [menu]="menu"></app-action-menu>
  </td>
</ng-template>

<ng-template #stackTemplate>
  <div class="flex-grow-1">
    <p class="mb-1">
      <span class="fw-light">{{ "multifx.types." + multiFxLeg.product | translate }}</span>
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "sides." + multiFxLeg.side | translate }}:</span>
      {{ multiFxLeg.amount | balance : (currency | async) || "" }}
    </p>
    <p class="mb-0">
      <span class="fw-light">{{ multiFxLeg.settlement.date | datePko }}</span>
    </p>
    <app-form-multifx-transaction-error
      *ngFor="let error of errors | keyvalue"
      [error]="{ code: error.key, data: error.value }"></app-form-multifx-transaction-error>
  </div>
  <app-action-menu [menu]="menu" class="flex-shrink-1"></app-action-menu>
</ng-template>
