<app-modal-container [config]="modalConfig" [formGroup]="form">
  <app-alert icon="good-to-know" content="dashboard.settings.Help"></app-alert>
  <div id="selection-container" *ngIf="tiles$ | async as tiles" class="position-relative">
    <app-input-check
      class="tile-select"
      *ngFor="let tile of tiles.left"
      [label]="'dashboard.tiles.' + tile"
      [formControlName]="tile"
      [attr.data-name]="tile"
      ngDefaultControl></app-input-check>
    <app-input-check
      class="tile-select"
      *ngFor="let tile of tiles.right"
      [label]="'dashboard.tiles.' + tile"
      [formControlName]="tile"
      [attr.data-name]="tile"
      ngDefaultControl></app-input-check>
  </div>
</app-modal-container>
