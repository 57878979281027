<ng-container *ngIf="countdown$ | async as time; else timeout">
  <ngb-progressbar
    class="pko-countdown__progressbar"
    [value]="time"
    [max]="max"
    [type]="time | progress: max">
  </ngb-progressbar>

  <div class="pko-countdown__text">
    <div class="pko-countdown__time">
      {{ "time.Left" | translate }}
      <span class="text-{{ time | progress: max }}">
        {{ "time.sec" | translate: { value: time } }}
      </span>
    </div>
    <div
      *ngIf="message"
      class="pko-countdown__message text-{{ message.type }}"
      [innerHtml]="message.value | translate"></div>
  </div>
</ng-container>

<ng-template #timeout>
  <ngb-progressbar class="pko-countdown__progressbar"></ngb-progressbar>
  <div *ngIf="timeoutText" class="pko-countdown__text">
    <div class="pko-countdown__message" [innerHtml]="timeoutText | translate"></div>
  </div>
</ng-template>
