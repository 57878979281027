import { LogConfig, LogLevel } from "./logging.model";

export interface Config {
  logging: LogConfig;
  http: HttpConfig;
  indicatives: Indicatives;
  links: Links;
  infoPhone: string;
}

interface HttpConfig {
  retries: number;
  timeout: number;
}

interface Indicatives {
  pollingInterval: number;
  pairLimit: number;
}

interface Links {
  forwardRisk: string;
  swapRisk: string;
  safety: ChannelOption<string>;
  help: ChannelOption<string>;
  contact: ChannelOption<string>;
}

interface ChannelOption<T> {
  ipko: T;
  ipkobiznes: T;
}

export const defaultConfig: Config = {
  logging: { default: LogLevel[LogLevel.Warning] },
  indicatives: { pollingInterval: 10, pairLimit: 8 },
} as Config;
