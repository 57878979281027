<div
  *ngIf="{ graph: graph$ | async, breakpoint: currentBreakpoint$ | async } as vm"
  (click)="openModal()">
  <span class="pko-indicative__chart-label" *ngIf="vm.breakpoint | breakpoint: 'xs'">{{
    pair.code | pair
  }}</span>
  <ng-container *ngIf="vm.graph as graph">
    <highcharts-chart
      *ngIf="graph.chartOptions; else noData"
      class="pko-indicative__chart-graph"
      [Highcharts]="graph.Highcharts"
      [constructorType]="'stockChart'"
      [options]="graph.chartOptions"
      [(update)]="graph.update"
      [oneToOne]="graph.oneToOneFlag"
      [runOutsideAngular]="graph.runOutsideAngularFlag"
      style="display: block; width: auto"
      [style.height]="(vm.breakpoint | breakpoint: 'xs') ? '36px' : '30px'">
    </highcharts-chart>
    <button
      class="pko-indicative__chart-button pko-indicative__chart-button--icon"
      *ngIf="graph.chartOptions && (vm.breakpoint | breakpoint: 'sm')">
      {{ "graphs.OpenModal" | translate }}
      <svg icon name="zoom" class="icon--lg"></svg></button
  ></ng-container>
  <div pko-preloader *ngIf="isBusy$ | async"></div>
</div>
<ng-template #noData>
  <div class="pko-indicative__chart-blank">
    <div>{{ "missingData.Amount" | translate }}</div>
    <div>{{ "missingData.IndicativeGraph" | translate }}</div>
  </div>
</ng-template>
