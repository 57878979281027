import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BreakpointObserverService } from "@core/breakpoints";
import {
  FlowDeclaration,
  FlowDeclarationVM,
} from "@features/flow-declaration/flow-declaration.model";
import { ModalService } from "@features/modal/modal.service";
import { FlowDeclarationApplicationModalComponent } from "../flow-declaration-application-modal/flow-declaration-application-modal.component";

@Component({
  selector: "app-flow-declaration-application-panel",
  templateUrl: "flow-declaration-application-panel.component.html",
})
export class FlowDeclarationApplicationPanelComponent implements OnInit {
  @Input()
  public get flowsDeclaration(): FlowDeclarationVM {
    return this.#flowsDeclaration;
  }
  public set flowsDeclaration(value: FlowDeclarationVM) {
    this.declaration = value.declaration;
    this.#flowsDeclaration = value;
  }
  @Input() form!: FormGroup;

  #flowsDeclaration!: FlowDeclarationVM;
  declaration!: FlowDeclaration;
  entryDeclaration: FlowDeclaration | null = null;
  isEditing = false;

  get flowDeclarationControl() {
    return this.form.controls.flowDeclaration;
  }

  constructor(
    private breakPointObserver: BreakpointObserverService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.declaration = this.flowDeclarationControl.value ?? this.flowsDeclaration.declaration;
  }

  onUpdated() {
    this.entryDeclaration = this.flowDeclarationControl.value;
    this.declaration = this.flowDeclarationControl.value ?? this.flowsDeclaration.declaration;
    this.flowDeclarationControl.setValue(null);
    this.flowDeclarationControl.markAsUntouched();

    if (this.breakPointObserver.isMatched("lg", "md")) {
      this.isEditing = true;
    } else {
      const modal = this.modalService.modal({
        component: FlowDeclarationApplicationModalComponent,
        scrollable: true,
      });
      modal.componentInstance.declaration = this.declaration;
      modal.componentInstance.flowsDeclaration = this.flowsDeclaration;
      modal.result.then((declaration: FlowDeclaration) => {
        declaration ? this.onSaved(declaration) : this.onCanceled();
      });
    }
  }

  onSaved(declaration: FlowDeclaration) {
    this.flowDeclarationControl.setValue(declaration);
    this.flowDeclarationControl.markAsTouched();
    this.declaration = declaration;
    this.isEditing = false;
  }

  onCanceled() {
    this.flowDeclarationControl.setValue(this.entryDeclaration);
    this.declaration = this.entryDeclaration ?? this.flowsDeclaration.declaration;
    this.isEditing = false;
  }
}
