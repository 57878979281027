import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, EMPTY, map, Observable, shareReplay, switchMap } from "rxjs";
import { TenorDate } from "./tenor-model";
import { parseTenorDates, splitDepositTenors } from "./tenor-utils";

@Injectable({
  providedIn: "root",
})
export class TenorService {
  #tenorDatesByStartTenor: Record<string, Observable<TenorDate[]>> = {};
  #tenorDatesByCurrencyPair: Record<string, Observable<TenorDate[]>> = {};

  constructor(private http: HttpClient) {}

  fxTenors$ = this.http.get<string[]>("/settings/tenors", { params: { market: "fx" } }).pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError(() => EMPTY)
  );

  depositTenors$ = this.http.get<string[]>("/settings/tenors", { params: { market: "mm" } }).pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
    map(splitDepositTenors),
    catchError(() => EMPTY)
  );

  getTenorDatesByCurrencyPair(code: string): Observable<TenorDate[]>;
  getTenorDatesByCurrencyPair(code$: Observable<string>): Observable<TenorDate[]>;
  getTenorDatesByCurrencyPair(code: string | Observable<string>) {
    const byPair = (key: string) =>
      this.#getTenorDates({ key, store: this.#tenorDatesByCurrencyPair, type: "exchange" });

    return typeof code === "string" ? byPair(code) : code.pipe(switchMap(byPair));
  }

  getTenorDatesByTenor = (tenor: string) =>
    this.#getTenorDates({ key: tenor, store: this.#tenorDatesByStartTenor, type: "deposit" });

  #getTenorDates({ key, store, type }: FetcherParams) {
    const stored = store[key];
    if (stored) return stored;

    const params = { key };
    const tenorDates$ = this.http
      .get<TenorDate[]>(`/${type}/tenors`, { params })
      .pipe(map(parseTenorDates), shareReplay({ refCount: true, bufferSize: 1 }));

    store[key] = tenorDates$;

    return tenorDates$;
  }
}

type FetcherParams = {
  key: string;
  store: Record<string, Observable<TenorDate[]>>;
  type: "exchange" | "deposit";
};
