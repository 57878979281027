import {
  AfterViewInit,
  Component,
  HostBinding,
  QueryList,
  TemplateRef,
  ViewChildren,
} from "@angular/core";
import { BreakpointObserverService } from "@core/breakpoints";
import { DashboardTile } from "@core/preferences/preferences.model";
import { PreferencesService } from "@core/preferences/preferences.service";
import { combineLatest, map } from "rxjs";
import { SectionTemplateDirective } from "./section-template.directive";
@Component({
  selector: "app-dashboard-pro",
  templateUrl: "./dashboard-pro.component.html",
})
export class DashboardProComponent implements AfterViewInit {
  @HostBinding("class") class = "pko-dashboard pko-dashboard--pro";
  @ViewChildren(SectionTemplateDirective)
  templates!: QueryList<SectionTemplateDirective>;
  types: Record<string, TemplateRef<unknown>> = {};

  tiles$ = combineLatest([
    this.breakpointObserver.isBreakpoint("xs"),
    this.preferences.dashboard$,
  ]).pipe(
    map(([isXs, preferences]) => {
      const tiles = preferences.tilesSelection;

      if (isXs) {
        const mobileTiles: DashboardTile[] = ["notifications"];

        if (tiles.left.concat(tiles.right).includes("news")) mobileTiles.push("news");

        return {
          left: mobileTiles,
          right: [],
        };
      }
      return tiles;
    })
  );

  constructor(
    private preferences: PreferencesService,
    private breakpointObserver: BreakpointObserverService
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.templates?.forEach((x) => (this.types[x.sectionType] = x.template));
    }, 0);
  }
}
