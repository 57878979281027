import { Pipe, PipeTransform } from "@angular/core";
import { groupAndSort } from "@shared/utils/list";
import { KeysMatching } from "@utils/misc";
import { datepickerFormat } from "@utils/time";

@Pipe({
  name: "groupAndSort",
})
export class GroupAndSortPipe implements PipeTransform {
  transform<T, K extends KeysMatching<T, string>>(items: T[], key: K) {
    // KeysMatching ensures that item[key] is already te desired type, but the compiler doesn't see it
    // when T is generic 🤷‍♂️
    return groupAndSort((item) => datepickerFormat(String(item[key])), items);
  }
}
