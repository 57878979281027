<app-notifications-item [child]="this">
  {{ "notifications.Return" | translate }}
  <span *ngIf="deposit.isCompleted; else notCompleted">{{
    deposit.settlementAmount | balance : deposit.currency
  }}</span>
</app-notifications-item>

<ng-template #notCompleted>
  {{ deposit.amount | balance : deposit.currency }}
  {{ "notifications.UnknownInterest" | translate }}
</ng-template>
