import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PremiumDepositBasket } from "./resolver.service";

@Pipe({
  name: "pickName",
})
export class BasketNamePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform({ names }: PremiumDepositBasket) {
    return (
      names.find((x) => x.language.toLowerCase() === this.translate.currentLang)?.name ??
      names[0]?.name
    );
  }
}
