<h4>
  {{ "multifx.CurrencySum" | translate }}
</h4>
<hr />
<app-labeled-text-inline
  label="{{ 'multifx.Sum' | translate: { currency: detail.currency.code } }}"
  [translateLabel]="false">
  <app-flag
    [icon]="detail.currency.code"
    [label]="detail.dealCurrencyTotal | balance: detail.currency.code"></app-flag>
</app-labeled-text-inline>

<app-labeled-text-inline
  label="{{ 'multifx.Sum' | translate: { currency: detail.counterCurrency.code } }}"
  [translateLabel]="false">
  <app-flag
    [icon]="detail.counterCurrency.code"
    [label]="detail.counterCurrencyTotal | balance: detail.counterCurrency.code"></app-flag>
</app-labeled-text-inline>
