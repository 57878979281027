const logSources = ["test", "global", "tracking"] as const;

export type LogSource = typeof logSources[number];

/**
 * Server application serializes enums to strings,
 * that's why `keyof typeof LogLevel`, not just `LogLevel`
 */
export type LogConfig = Record<"default" | LogSource, keyof typeof LogLevel>;

export enum LogLevel {
  Trace,
  Debug,
  Information,
  Warning,
  Error,
  Critical,
  None,
}
