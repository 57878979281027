import { Location } from "@angular/common";
import { Directive, HostListener, Input } from "@angular/core";
@Directive({
  selector: "[backButton]",
})
export class BackButtonDirective {
  @Input() back?: () => void;

  constructor(private _location: Location) {}

  @HostListener("click")
  onClick(): void {
    if (this.back) {
      this.back();
      return;
    }

    this._location.back();
  }
}
