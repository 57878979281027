import { Component, HostBinding, Input, Self } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { ControlSize } from "@core/models/control";

@Component({
  selector: "app-input-search",
  templateUrl: "./input-search.component.html",
})
export class InputSearchComponent {
  @HostBinding("class") class = "d-block";
  @Input("ngId") id?: string;
  @Input("validate") controlToValidate?: string;
  @Input() label?: string;
  @Input() placeholder = "placeholders.Search";
  @Input() size?: ControlSize;

  constructor(@Self() private ngControl: NgControl) {}

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }
}
