<div class="pko-modal__header">
  <h3 class="pko-modal__title d-inline-flex">
    {{ "flowDeclaration.Title" | translate }}
    <app-tooltip content="flowDeclaration.Tooltip"></app-tooltip>
  </h3>
</div>

<div class="pko-modal__body">
  <p>{{ "flowDeclaration.FillInHeaderExtension" | translate }}</p>
  <p class="fw-bold">
    {{
      "flowDeclaration.FillInHeader"
        | translate
          : {
              start: flowDeclaration.dateFrom | datePko,
              end: flowDeclaration.dateTo | datePko
            }
    }}
  </p>
  <p>{{ "flowDeclaration.NoFlowsDeclared" | translate }}</p>
</div>
