import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { origins } from "@core/user-tracking/tracking.model";
import { Account, AccountsService } from "@features/accounts";
import { CurrencyService } from "@features/currency";
import { DepositFormService } from "@features/deposit/services/deposit-form.service";
import { TenorDateConfig, TenorService } from "@features/tenor";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Observable, map, merge, startWith, switchMap } from "rxjs";

@Component({
  selector: "app-form-deposit",
  templateUrl: "form-deposit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositFormComponent implements OnInit {
  form: FormGroup;

  currencies$ = this.currencyService.depositCurrencies$;
  accounts$: Observable<Account[]>;
  isBusy$ = merge(
    this.formService.submission$.pipe(mapToBusy()),
    this.busyService.observeOn("/currency", "/accounts", "/deposit")
  );

  protected isTile = false;

  constructor(
    fb: FormBuilder,
    private formService: DepositFormService,
    private currencyService: CurrencyService,
    private accountsService: AccountsService,
    private busyService: BusyService,
    private tenorService: TenorService,
    private tracker: Tracker
  ) {
    const form = formService.current;

    this.form = fb.group({
      currency: form.currency,
      amount: form.amount,
      account: [form.account, Validators.required],
      start: fb.group(form.start),
      end: fb.group(form.end),
    });

    const currency = this.form.controls.currency;
    this.accounts$ = currency.valueChanges.pipe(
      startWith(currency.value),
      switchMap(this.accountsService.getAccounts("deposit"))
    );
  }

  getTenorDates = (tenor: string) =>
    this.tenorService
      .getTenorDatesByTenor(tenor)
      .pipe(map((tenors) => ({ values: tenors } as TenorDateConfig)));

  ngOnInit(): void {
    this.isTile ||
      this.tracker.follow({ process: "deposit", origin: history.state?.origin ?? "reload" });
  }

  onSubmit(componentSelector?: string) {
    this.formService.save(this.form.getRawValue());

    if (handleFormErrors(this.form, componentSelector)) {
      this.isTile && this.tracker.follow({ process: "deposit", origin: origins.TILE });
      this.formService.submit();
    }
  }
}
