<ng-container *ngIf="isBusy$ | async">
  <ng-container *ngTemplateOutlet="loading"></ng-container>
</ng-container>

<ng-container #mifidValidation></ng-container>

<ng-container *ngIf="emptyMessage$ | async as emptyMessage">
  <app-tile-message
    [data]="emptyMessage.dialog || {}"
    *ngIf="emptyMessage.isEmpty; else formTemplate"></app-tile-message>
</ng-container>

<ng-template #formTemplate>
  <app-alert
    *ngIf="warningCodeService.outOfTargetMarket"
    icon="critical"
    content="alert.Content.OutOfTargetMarket"
    context="danger">
  </app-alert>
  <form
    class="pko-form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    *ngIf="initialization$ | async; else loading">
    <div class="pko-grid__row">
      <app-form-control label="investment-deposit.Currency" validate="currency">
        <app-select-flag formControlName="currency" [items]="currencies$ | async" ngDefaultControl>
        </app-select-flag>
      </app-form-control>
      <app-form-control label="Amount" validate="amount">
        <app-input-number
          formControlName="amount"
          placeholder="placeholders.Amount"
          [decimals]="decimals$ | async"
          ngDefaultControl>
        </app-input-number>
      </app-form-control>
      <app-form-currency-pair
        label="investment-deposit.CurrencyPair"
        [pairs]="pairs$ | async"
        [disableSingleValue]="false"></app-form-currency-pair>
      <app-form-date-bounds
        transactionType="investment-deposit"
        [getTenorDates]="getTenorDates()"
        endTenorPlaceholder="placeholders.SelectTenorShort"
        [plnHolidaysOnly]="true"
        [disableSingleValue]="false"></app-form-date-bounds>
      <app-select-account
        label="accounts.From"
        validate="account"
        formControlName="account"
        [items]="accounts$ | async"
        placeholder="placeholders.SelectAccount"
        [useDefault]="false"
        ngDefaultControl></app-select-account>
      <div *ngIf="offerConfig$ | async as offer" formGroupName="rates">
        <!-- <pre *ifIsDevMode>{{ offer | json }}</pre> -->
        <app-slider
          [min]="offer.minimalInterestRate"
          [max]="offer.maximalInterestRate"
          [step]="0.05"
          ngId="interestRate"
          label="investment-deposit.WarrantedInterestRate"
          tooltip="investment-deposit.WarrantedInterestRateTooltip"
          labelSuffix="%"
          formControlName="interestRate"></app-slider>
        <app-slider
          [min]="offer.midSpotRate | minInvDepoRate : offer.maxDeviation"
          [max]="offer.midSpotRate | maxInvDepoRate : offer.maxDeviation"
          [step]="offer.midSpotRate | stepInvDepoRate"
          [minSpread]="offer.minSpread / 10000"
          ngId="rate"
          numberFormat="1.4-4"
          label="investment-deposit.ExpectedRate"
          [labelData]="{ pair: (this.form.controls.currencyPair.value | pair) ?? '' }"
          tooltip="investment-deposit.ExpectedRateTooltip"
          [tooltipData]="{ pair: (this.form.controls.currencyPair.value | pair) ?? '' }"
          formControlName="rate"
          type="range"></app-slider>
        <app-select-deviation
          [midSpotRate]="offer.midSpotRate"
          [deviationRange]="offer.maxDeviation - offer.minSpread"
          [numberOfRanges]="offer.numberOfRateRanges">
        </app-select-deviation>
        <!-- <pre *ifIsDevMode>{{ form.value | json }}</pre> -->
      </div>
    </div>
    <app-form-footer [back]="{ hidden: true }"></app-form-footer>
  </form>
</ng-template>

<ng-template #loading>
  <div pko-preloader></div>
</ng-template>
