import { Component, HostBinding } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { PreferencesService } from "@core/preferences/preferences.service";
import { CurrencyService } from "@features/currency";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Selectable } from "@shared/components/groups-selector/groups-selector.model";
import { Modal } from "@shared/components/modal/modal-model";
import { combineLatest, map } from "rxjs";
import { BlotterSettingsItemComponent } from "./item/blotter-settings-item.component";

@Component({
  selector: "app-blotter-settings",
  templateUrl: "./blotter-settings.component.html",
})
export class BlotterSettingsComponent {
  @HostBinding("class") class = "h-100";

  selectablePairs$ = combineLatest([
    this.service.blotterGarph$,
    this.currencyService.exchangeCurrencies$,
  ]).pipe(
    map(([{ currencies }, all]) =>
      all.map(
        (x) =>
          ({
            isSelected: currencies.includes(x.code),
            order: 1,
            key: x.code,
          } as Selectable)
      )
    )
  );

  form: FormGroup;
  selectedPairsGroup: FormGroup;
  notSelectedPairsGroup: FormGroup;
  itemType = BlotterSettingsItemComponent;

  isBusy$ = this.busyService.observeOn("/accounts/exchange", "cyrrency/exchange");

  modalConfig: Modal = {
    title: "blotter.EditCurrencies",
    buttons: { primary: { text: "buttons.Save", onClick: () => this.save() } },
  };

  constructor(
    fb: FormBuilder,
    private service: PreferencesService,
    public activeModal: NgbActiveModal,
    private currencyService: CurrencyService,
    private busyService: BusyService
  ) {
    const selectedPairsGroup = new FormGroup({});
    const notSelectedPairsGroup = new FormGroup({});
    this.form = fb.group({
      selectedPairs: selectedPairsGroup,
      notSelectedPairs: notSelectedPairsGroup,
    });

    this.selectedPairsGroup = selectedPairsGroup;
    this.notSelectedPairsGroup = notSelectedPairsGroup;
  }

  save() {
    const selectedCurrencies = Object.entries(this.selectedPairsGroup.value).map((x) => x[0]);
    this.service.setBlotterGraph({ currencies: selectedCurrencies });
    this.activeModal.close(selectedCurrencies);
  }
}
