<form [formGroup]="dropdownFormGroup" class="pko-filter-status">
  <app-select
    [items]="dropdownOptions"
    formControlName="option"
    [label]="label"
    class="pko-filter-status__select"
    ngDefaultControl>
    <ng-template ng-label-tmp ng-option-tmp let-option="item">
      {{ optionLabel + "." + option | translate }}
    </ng-template>
  </app-select>
  <app-main-filter-button
    *ngIf="showToggle === 'show'"
    class="align-self-end"
    [filtersCount]="filtersCount"
    (filtersToggled)="onFiltersToggled()"></app-main-filter-button>
</form>
