import { Component, HostBinding, Input } from "@angular/core";

let nextUniqueId = 0;
@Component({
  selector: "app-simple-radio",
  template: `
    <div class="form-radio">
      <input
        class="form-radio-input"
        type="radio"
        [value]="value"
        [id]="id"
        [name]="name"
        [checked]="checked"
        [readonly]="readonly" />
      <label class="form-radio-label" [for]="id">
        <ng-container *ngIf="label; else content">
          {{ label }}
        </ng-container>
      </label>
    </div>

    <ng-template #content><ng-content></ng-content></ng-template>
  `,
})
export class SimpleRadioComponent {
  @HostBinding("class") class = "d-block";

  readonly #uniqueId = `radio-${++nextUniqueId}`;
  @Input() ngId?: string;
  @Input() label?: string;
  @Input() value?: string;
  @Input() name = "";
  @Input() checked = false;
  @Input() readonly = false;

  get id() {
    return this.ngId || this.#uniqueId;
  }
}
