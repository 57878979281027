import { Component } from "@angular/core";
import { BusyService } from "@core/loading/busy.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
})
export class NotificationsComponent {
  isBusy$ = this.busyService.observeOn("/notification");

  constructor(private busyService: BusyService) {}
}
