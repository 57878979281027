import { Component, OnDestroy } from "@angular/core";
import { AppEventsService } from "@core/events/app-events.service";
import { PreferencesService } from "@core/preferences/preferences.service";
import { deleteNotificationAnimation } from "@features/notifications/notifications.animations";
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs";
import { UserNotification } from "../../../notifications.model";
import { NotificationsService } from "../../../notifications.service";
import { NotificationsCommonComponent } from "../notifications.common.component";

@Component({
  selector: "app-notifications-pro",
  templateUrl: "./notifications.pro.component.html",
  animations: [deleteNotificationAnimation],
})
export class NotificationsProComponent extends NotificationsCommonComponent implements OnDestroy {
  public dates$ = this.notificationService.loadDates;
  public expanded$ = new BehaviorSubject<boolean>(false);
  public toggle$ = new BehaviorSubject<boolean>(false);
  public allNotifications: Array<Observable<UserNotification[]>> = [];
  selectedDate$ = new BehaviorSubject<number>(1);

  #threshold = 3;
  #reload = this.notificationService.reload.pipe(tap(() => this.#resetView()));
  #subscription: Subscription;

  dateFormat = "EEEE, dd.MM";

  get selectedTab() {
    return this.selectedDate$.getValue();
  }

  constructor(
    private notificationService: NotificationsService,
    preferences: PreferencesService,
    private events: AppEventsService
  ) {
    super(notificationService);
    [0, 1, 2].forEach((x) => this.allNotifications.push(this.getNotifications(x)));
    preferences.dashboard.simple && (this.dateFormat = "EE, dd.MM");
    this.#subscription = this.#reload.subscribe();
  }

  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  toggleExpansion() {
    this.expanded$.next(!this.expanded$.value);
  }

  selectDate(id: number) {
    this.selectedDate$.next(id);
  }

  getNotifications(index: number) {
    return combineLatest([this.dates$, this.selectedDate$, this.expanded$]).pipe(
      filter(([, selectedIndex]) => selectedIndex === index),
      switchMap(([dates]) => this.notificationService.loadNotifications(dates[index])),
      tap((notifications) => this.toggle$.next(notifications.length > this.#threshold)),
      map((x) => (this.expanded$.value ? x : x.slice(0, this.#threshold)))
    );
  }

  #resetView() {
    this.expanded$.next(false);
    this.selectDate(1);
  }
}
