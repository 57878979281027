import { Component, ContentChild, HostBinding, Input, TemplateRef } from "@angular/core";
import { ModalButton } from "@shared/components/modal/modal-model";
import { of } from "rxjs";
import { TableColumn } from "./table-model";

@Component({
  selector: "[pkoTable]",
  templateUrl: "./table.component.html",
})
export class TableComponent {
  @HostBinding("class") class = "pko-table table table-borderless";
  @Input() columns!: TableColumn[];
  @Input() loadNextPageButton?: ModalButton;
  @Input() items?: unknown[];
  @Input() emptyMessage = "missingData.Generic";
  @Input() isBusy$ = of(false);
  @Input() customTBody = false;

  @ContentChild("itemTemplate", { static: true, read: TemplateRef })
  itemTemplate?: TemplateRef<unknown>;
}
