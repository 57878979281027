import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { TelephoneFormatDirective } from "./form-telephone/telephone-format.directive";
import { TelephoneFormComponent } from "./form-telephone/telephone.component";

@NgModule({
  imports: [SharedModule],
  declarations: [TelephoneFormComponent, TelephoneFormatDirective],
  exports: [TelephoneFormComponent],
})
export class TelephoneModule {}
