import { Component, Input } from "@angular/core";
import { ExchangeQuote } from "../../models";

@Component({
  selector: "app-summary-quote",
  templateUrl: "summary-quote.component.html",
})
export class SummaryQuoteComponent {
  @Input() quote!: ExchangeQuote;
  @Input() header?: Header;

  get counterAmount() {
    return this.quote.counterAmount || null;
  }

  get rate() {
    return this.quote.rate || null;
  }
}

interface Header {
  title: string;
  subtitle?: string;
}
