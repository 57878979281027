<ng-container *ngTemplateOutlet="role === 'stack' ? stack : row"></ng-container>

<ng-template #row>
  <td pkoTableCell>
    {{ "alert.Details." + alert.side | translate }} <br />
    <span class="text-muted text-sm">{{ alert.amount | balance: alert.currency }}</span>
  </td>
  <td align="center" pkoTableCell>{{ alert.rate | rate }}</td>
  <td align="center" pkoTableCell>
    <app-currency-pair class="d-inline-flex" [code]="alert.currencyPair"></app-currency-pair>
  </td>
  <td align="center" [class]="alert.status | statusColor" pkoTableCell>
    {{ "alert.statuses." + alert.status | translate }}
  </td>
  <td align="end" pkoTableCell>{{ alert.expiration | datePko }}</td>
  <td align="end" pkoTableCell>
    {{ alert.phone ? (alert.phone | phone) : ("alert.Details.NotificationPush" | translate) }}
  </td>
  <td [actionMenu]="true" pkoTableCell>
    <app-action-menu *ngIf="menu" [menu]="menu"></app-action-menu>
  </td>
</ng-template>

<ng-template #stack>
  <div class="d-block flex-grow-1">
    <p class="mb-1">
      <span class="fw-light">{{ "alert.Details." + alert.side | translate }}:</span>
      {{ alert.amount | balance: alert.currency }} {{ "for" | translate }}
      {{ alert.counterCurrency }}
    </p>
    <p class="mb-1 {{ alert.status | statusColor }}">
      {{ "alert.statuses." + alert.status | translate }}
    </p>
    <p class="mb-1">
      <span class="fw-light">{{ "ExpectedRate" | translate }}:</span>
      {{ alert.rate | rate }}
    </p>
    <p class="mb-0">
      <span class="fw-light">{{ "alert.Details.Notification" | translate }}:</span>
      {{ alert.phone ? (alert.phone | phone) : ("alert.Details.NotificationPush" | translate) }}
    </p>
  </div>
  <app-action-menu *ngIf="menu" [menu]="menu"></app-action-menu>
</ng-template>
