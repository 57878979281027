import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { ApplicationDetailsComponent } from "./components/application-details/application-details.component";
import { ClientTypePipe } from "./pipes/client-type.pipe";

@NgModule({
  imports: [SharedModule],
  declarations: [ApplicationDetailsComponent, ClientTypePipe],
  exports: [ApplicationDetailsComponent, ClientTypePipe],
  providers: [],
})
export class DigitalizationCommonModule {}
