import { Component, Input, OnInit } from "@angular/core";
import { InvestmentDepositSummary } from "@features/investment-deposit/investment-deposit.model";
import { difference } from "@utils/time";

@Component({
  selector: "app-summary-detail",
  templateUrl: "summary-detail.component.html",
})
export class InvestmentDepositSummaryDetailComponent implements OnInit {
  @Input() detail!: InvestmentDepositSummary;
  duration = 0;

  ngOnInit(): void {
    const { start, end } = this.detail;
    this.duration = difference(new Date(end.date), new Date(start.date), "days");
  }
}
