import { Component, HostBinding, Input, Self } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { ButtonGroupOption } from "@shared/components/controls/model";
import { uid } from "@utils/string";

@Component({
  selector: "app-form-button-group",
  templateUrl: "button-group.component.html",
})
export class ButtonGroupFormComponent {
  @HostBinding("class") class = "d-block";

  @Input() pointed = false;

  @Input() set options(value: Array<ButtonGroupOption | string>) {
    this.mappedOptions = value.map(toOption);
  }

  name = `radio-group-${uid()}`;
  mappedOptions: Option[] = [];

  constructor(@Self() private ngControl: NgControl) {}

  get control() {
    return this.ngControl.control as FormControl;
  }
}

type Option = ButtonGroupOption & { id: string };

const toOption = (option: ButtonGroupOption | string): Option => ({
  text: typeof option === "string" ? option : option.text,
  value: typeof option === "string" ? option : option.value,
  id: `radio-${uid()}`,
});
