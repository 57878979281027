import { Component, HostBinding, Input } from "@angular/core";
import { Account } from "@features/accounts";

@Component({
  selector: "app-definition",
  templateUrl: "./definition.component.html",
})
export class DefinitionComponent {
  @HostBinding("class") class = "pko-definition";
  @Input() account?: Account;
  @Input() label = "";
  @Input() translateLabel = true;
  @Input() remark?: string;
  @Input() tooltip?: string;
  @Input() value?: string | number | null;
}
