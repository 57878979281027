import { Component, HostBinding } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DialogComponent } from "../base-dialog.component";

@Component({
  selector: "app-modal-dialog",
  templateUrl: "modal-dialog.component.html",
})
export class ModalDialogComponent extends DialogComponent {
  @HostBinding("class") class = "pko-dialog";

  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  close(result?: unknown): void {
    this.activeModal.close(result);
  }

  onClicked(resultOnClose?: unknown): void {
    // if needed, add `wait` prop
    // and then await onClicked("complete") -> close()
    this.activeModal.close(resultOnClose);
  }
}
