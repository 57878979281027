<ng-container *ngIf="isBusy$ | async">
  <ng-container *ngTemplateOutlet="loading"></ng-container>
</ng-container>

<ng-container *ngIf="mifidValidated$ | async; else loading">
  <app-alert
    *ngIf="warningService.outOfTargetMarket"
    icon="critical"
    content="alert.Content.OutOfTargetMarket"
    context="danger">
  </app-alert>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="pko-grid__row">
      <app-summary-original [original]="original" class="col-10 col-sm-5"></app-summary-original>

      <div class="col-10 col-sm-5">
        <h2>{{ "exchange.close.Title" | translate }}</h2>
        <app-input-amount-left
          formControlName="amount"
          label="exchange.close.Amount"
          [currency]="original.currency"
          [max]="original.openBalance"
          ngDefaultControl>
        </app-input-amount-left>
      </div>
    </div>

    <p
      *ngIf="isForward$ | async"
      class="pko-infotext"
      [innerHtml]="'exchange.info.risk.forward' | translate : { link: link }"></p>
    <app-form-footer [back]="{ onClick: back }"></app-form-footer>

    <pre *ifIsDevMode>{{ form.getRawValue() | json }}</pre>
  </form>
</ng-container>
<ng-template #loading>
  <div pko-preloader></div>
</ng-template>
