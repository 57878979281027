import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Failure } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { ModalService } from "@features/modal/modal.service";
import { isInitializationSuccess, isRateFailure } from "@features/transaction/models";
import { TransactionResponseService } from "@features/transaction/services/response.service";
import { InitializationResponseWrapper, isInitializationWarning } from "../models/initialization";
import { MultiFxRateError, MultiFxRateResponse } from "../models/multifx-rate";
import { MultiFxStatusResponse } from "../models/multifx-summary";

@Injectable({ providedIn: "root" })
export class MultiFxResponseService {
  constructor(
    private modal: ModalService,
    private transaction: TransactionResponseService,
    private router: Router,
    private tracker: Tracker
  ) {}

  async handleInitResponse(input: InitializationResponseWrapper, isDpw: boolean) {
    const { response, resubmit } = input;

    if (isInitializationSuccess(response)) {
      return this.transaction.decide(isDpw ? "multidpw" : "multifx", response.token);
    }

    if (isInitializationWarning(response)) {
      const consents = await this.#onWarnings(response.warnings);
      if (!consents) return;

      return resubmit(consents);
    }

    return this.#onError(response.error);
  }

  handleRateError(rateResponse: MultiFxRateResponse) {
    if (!isRateFailure(rateResponse)) return;

    return this.#onRateError(rateResponse);
  }

  handleConfirmationError() {
    this.#onError();
  }

  handleStatusResponse(response: MultiFxStatusResponse) {
    response.success ? this.#onSuccess() : this.#onError(response.status);
  }

  #onError(error?: Failure) {
    this.tracker.reportProgress({
      action: actions.FAILURE,
      data: { error: error?.code ?? "unknown" },
    });
    this.transaction.error({ source: "multifx", error });
  }

  #onRateError({ series }: MultiFxRateError) {
    const buttons = {
      primary: {
        text: "buttons.Retry",
        onClick: () =>
          this.router.navigate(["/transactions/multifx"], {
            replaceUrl,
            state: { retain: "all", errors: series },
          }),
      },
      secondary: {
        text: "buttons.home",
        onClick: () => this.router.navigateByUrl("/", { replaceUrl }),
      },
    };
    const error = { code: "MultiFxGeneric" };
    this.tracker.reportProgress({ action: actions.FAILURE, data: { error: error.code } });
    this.transaction.error({ source: "multifx", error, modalType: "page", buttons });
  }

  /**
   * Displays warning dialogs in sequence, if user accepts.
   * @param warnings Warnings to display.
   * @returns If all warnings are accepted, returns their codes. Otherwise `undefined`.
   */
  async #onWarnings(warnings: Failure[]) {
    for (const { code, data } of warnings) {
      this.tracker.reportProgress({
        action: actions.WARNING,
        data: { code },
      });
      const accepted = await this.modal
        .dialog({
          type: "warning",
          title: `dialog.warning.title.${code}`,
          body: { key: `dialog.warning.body.${code}`, params: data },
          buttons: {
            primary: { text: "buttons.Continue", resultOnClose: true },
            secondary: { text: "buttons.Resign" },
          },
        })
        // a dismissed modal calls Promise.reject()
        .result.catch(() => false);

      this.tracker.reportProgress({
        action: actions.WARNING,
        data: { code, accepted: accepted ?? false },
      });

      if (!accepted) return;
    }

    return warnings.map(({ code }) => code);
  }

  #onSuccess() {
    this.tracker.reportProgress({
      action: actions.SUCCESS,
    });
    this.transaction.success({
      source: "multifx",
      bodyLines: [],
    });
  }
}

const replaceUrl = true;
