import { NgModule } from "@angular/core";
import { DigitalizationCommonModule } from "@features/digitalization-common/digitalization-common.module";
import { SharedModule } from "@shared/shared.module";
import { ActiveApplicationsComponent } from "./components/active-applications/active-applications.component";
import { ApplicationsAndContractsComponent } from "./components/applications-and-contracts/applications-and-contracts.component";
import { ApplicationSelectComponent } from "./components/apply/application-select.component";
import { ClientDataSimpleComponent } from "./components/client-data-simple/client-data-simple.component";
import { ContractsComponent } from "./components/contracts/contracts.component";
import { ManageContractsComponent } from "./components/manage-contracts/manage-contracts.component";
import { RegulationsComponent } from "./components/regulations/app-regulations.component";
import { ApplicationIdPipe } from "./pipes/applicaion-id.pipe";
import { ApplicationStatusPipe } from "./pipes/application-status.pipe";

@NgModule({
  imports: [DigitalizationCommonModule, SharedModule],
  declarations: [
    ActiveApplicationsComponent,
    ContractsComponent,
    ApplicationIdPipe,
    ApplicationStatusPipe,
    ApplicationSelectComponent,
    ClientDataSimpleComponent,
    ManageContractsComponent,
    RegulationsComponent,
    ApplicationsAndContractsComponent,
  ],
  exports: [
    ActiveApplicationsComponent,
    ContractsComponent,
    ManageContractsComponent,
    ApplicationSelectComponent,
    RegulationsComponent,
    ApplicationsAndContractsComponent,
  ],
})
export class ActivationModule {}
