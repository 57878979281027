import { Attribute, Component, Host, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Account } from "@features/accounts";
import { ProductType } from "@features/tenor";
import { assertIsDefined } from "@utils/misc";
import { combineLatest, EMPTY, filter, map, Observable, startWith, switchMap, tap } from "rxjs";
import { CollateralType, COLLATERAL_TYPES } from "../collateral-model";
import { CollateralService } from "../collateral.service";

@Component({
  selector: "app-form-collateral",
  templateUrl: "collateral.component.html",
})
export class CollateralFormComponent implements OnInit {
  vm$: Observable<CollateralView> = EMPTY;

  constructor(
    private collateralService: CollateralService,
    @Host() private parent: ControlContainer,
    @Attribute("product") private product?: ProductType
  ) {}

  get group() {
    return this.parent.control as FormGroup;
  }

  ngOnInit() {
    const { product, type, account } = this.#assertForm();

    const getCollateralTypes = (productType: ProductType) =>
      this.collateralService.getTypes(productType).pipe(tap(updateTypeSelection(type)));

    const types$ = isProductType(product)
      ? getCollateralTypes(product)
      : product.valueChanges.pipe(
          startWith(product.value),
          filter(Boolean),
          switchMap(getCollateralTypes)
        );

    const isDeposit$ = type.valueChanges.pipe(
      startWith(type.value),
      map((type) => type === COLLATERAL_TYPES.deposit),
      tap(updateValidators(account))
    );

    this.vm$ = combineLatest([this.collateralService.accounts$, types$, isDeposit$]).pipe(
      map(([accounts, types, isDeposit]) => ({
        accounts,
        types,
        isDeposit,
        show: isSectionVisible(types),
      }))
    );
  }

  #assertForm() {
    const { controls } = this.group;
    const product = this.product ?? this.group.parent?.get("product");

    assertIsDefined(product);

    const type = controls.type ?? new FormControl();
    const account = controls.account ?? new FormControl();

    controls.type || this.group.addControl("type", type);
    controls.account || this.group.addControl("account", account);

    return { product, type, account };
  }
}

interface CollateralView {
  show: boolean;
  types: CollateralType[];
  accounts: Account[];
  isDeposit: boolean;
}

const isSectionVisible = (types: CollateralType[]) =>
  types.some((type) => type !== COLLATERAL_TYPES.block);

const updateTypeSelection = (typeControl: AbstractControl) => (types: CollateralType[]) => {
  updateValidators(typeControl)(Boolean(types.length));

  const hasSelected = types.some((type) => type === typeControl.value);
  if (hasSelected) return;

  types.length === 1 ? typeControl.setValue(types[0]) : typeControl.reset();
};

const updateValidators = (control: AbstractControl) => (required: boolean) => {
  if (required) {
    control.setValidators(Validators.required);
    control.updateValueAndValidity();
  } else {
    control.clearValidators();
    control.reset();
  }
};

const isProductType = (
  typeOrControl: ProductType | AbstractControl
): typeOrControl is ProductType => typeof typeOrControl === "string";
