import { Pipe, PipeTransform } from "@angular/core";
import { Breakpoint, isBreakpoint } from "@core/breakpoints";

@Pipe({
  name: "breakpoint",
})
export class BreakpointPipe implements PipeTransform {
  transform(current: Breakpoint | null, selected: Breakpoint | Breakpoint[]) {
    return isBreakpoint(current, selected);
  }
}
