import { NgModule } from "@angular/core";
import { AccountsModule } from "@features/accounts/accounts.module";
import { CurrencyModule } from "@features/currency/currency.module";
import { ModalModule } from "@features/modal/modal.module";
import { TenorModule } from "@features/tenor/tenor.module";
import { SharedModule } from "@shared/shared.module";
import { DealerWaitComponent } from "./components/dealer-wait/dealer-wait.component";
import { DecisionComponent } from "./components/decision/decision.component";
import { AccountsFormComponent } from "./components/form-accounts/accounts.component";
import { FormFooterComponent } from "./components/form-footer/form-footer.component";
import { PvpFormComponent } from "./components/form-pvp/form-pvp.component";
import { LiveRateFormComponent } from "./components/form-rate/live-rate.component";
import { RateFormComponent } from "./components/form-rate/rate.component";
import { SideFormComponent } from "./components/form-side/side.component";
import { MarginInfoComponent } from "./components/margin-info.component";
import { SimilarDealsWarningItemComponent } from "./components/similar-deals-warning/similar-deals-warning-item/similar-deals-warning-item.component";
import { SimilarDealsWarningComponent } from "./components/similar-deals-warning/similar-deals-warning.component";

@NgModule({
  imports: [SharedModule, AccountsModule, CurrencyModule, TenorModule, ModalModule],
  declarations: [
    FormFooterComponent,
    SideFormComponent,
    DecisionComponent,
    DealerWaitComponent,
    AccountsFormComponent,
    LiveRateFormComponent,
    RateFormComponent,
    PvpFormComponent,
    MarginInfoComponent,
    SimilarDealsWarningItemComponent,
    SimilarDealsWarningComponent,
  ],
  exports: [
    SharedModule,
    AccountsModule,
    CurrencyModule,
    TenorModule,
    FormFooterComponent,
    SideFormComponent,
    DecisionComponent,
    AccountsFormComponent,
    RateFormComponent,
    PvpFormComponent,
    MarginInfoComponent,
    SimilarDealsWarningComponent,
  ],
  providers: [],
})
export class TransactionModule {}
