import { HttpClient } from "@angular/common/http";
import { Injectable, isDevMode } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  isApiFailure,
  isApiPending,
  isApiSuccess,
  mapToApiResponse,
} from "@core/models/observable-action-v2";
import { NavigationService } from "@core/navigation";
import { UserService } from "@core/session";
import { ModalService } from "@features/modal/modal.service";
import { handleFormErrors, scrollToError, validateV2 } from "@features/transaction/utils/form";
import { parseBoolean } from "@utils/misc";
import {
  BehaviorSubject,
  Observable,
  Subject,
  audit,
  combineLatest,
  exhaustMap,
  of,
  share,
  shareReplay,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from "rxjs";
import { TreasurySurveyPage } from "./components/treasury-form.component";
import {
  BankLimit,
  Commodity,
  CurrencyFlow,
  InterestRateProduct,
  ProductKey,
  TreasuryAgreementData,
  TreasuryAgreementForm,
  TreasuryFormDetails,
} from "./treasury-form-model";
import {
  maxMonthValidator,
  minMonthValidator,
  monthValidator,
  requiredIf,
  validateRequiredCurrencyTenors,
  validateUnique,
} from "./treasury-form-validators";

@Injectable({ providedIn: "root" })
export class TreasuryFormService {
  readonly monthLimits;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private modal: ModalService,
    private navigation: NavigationService,
    private user: UserService
  ) {
    this.monthLimits = {
      min: {
        month: new Date().getUTCMonth() + 1,
        year: new Date().getUTCFullYear(),
      },
      max: {
        month: new Date().getUTCMonth() + 1,
        year: new Date().getUTCFullYear() + 25,
      },
    };
    this.addCurrencyFlow();
    this.addInterestRateProduct();
    this.addCommodity();
    this.addBankLimit();
  }

  readonly form = this.fb.group({
    products: this.fb.group({
      currency: [false, Validators.required],
      interestRate: [false, Validators.required],
      commodity: [false, Validators.required],
    }),
    hasOtherBanks: [undefined, Validators.required],
    hasBankLimits: [undefined, Validators.required],
    authType: [undefined, Validators.required],
    declarations: this.fb.group({}),
    financialDeclarations: this.fb.group({}),
  });

  readonly data$ = this.http
    .get<TreasuryAgreementData>(
      `/treasuryAgreement/data/${
        this.user.userData.treasuryAgreementSummary?.agreement?.formId ?? ""
      }`
    )
    .pipe(
      mapToApiResponse(),
      // eslint-disable-next-line rxjs/no-sharereplay
      shareReplay({ refCount: true }),
      tap(({ data }) => {
        if (!data || !this.clean) return;
        this.clean = false;

        if (this.source === "apply") {
          data.baskets.currency =
            this.applyProducts?.some((x) => data.productGroups[1].includes(x)) ?? false;
          data.baskets.interestRate =
            this.applyProducts?.some((x) => data.productGroups[2].includes(x)) ?? false;
          data.baskets.commodity =
            this.applyProducts?.some((x) => data.productGroups[3].includes(x)) ?? false;
        }

        const products = this.form.controls["products"] as FormGroup;
        for (const basket in data.baskets) {
          if (!data.baskets[basket as keyof typeof data.baskets]) {
            products.controls[basket].setValue(false);
            continue;
          }
          products.controls[basket].setValue(true);
        }

        const declarations = this.form.controls["declarations"] as FormGroup;
        this.declarationData = {};
        for (const statement of data.generalStatements) {
          declarations.addControl(
            `statement-${statement.id}`,
            new FormControl(false, Validators.requiredTrue)
          );
          this.declarationData[statement.id] = { question: statement.text };
        }

        const financialDeclarations = this.form.controls["financialDeclarations"] as FormGroup;
        this.financialDeclarationData = {};
        for (const statement of data.statements) {
          financialDeclarations.addControl(
            `financial-statement-${statement.id}`,
            new FormControl(null, Validators.required)
          );
          this.financialDeclarationData[statement.id] = {
            question: statement.text,
            hasNotApplicable: statement.hasNotApplicable,
          };
        }

        if (data.attorneys.isReadOnly) {
          this.attorneysControl.disable();
          data.attorneys.attorneys
            .map((attorney) => attorney.pid)
            .forEach((x) => this.attorneys.add(x));
        }

        data.details && this.setFromDetails(data.details);
      })
    );

  source: "profile" | "apply" = "profile";
  applyProducts?: number[];
  files: { file: File; safeFileID?: string }[] = [];
  declarationData: { [key: number]: { question: string } } = {};
  financialDeclarationData: { [key: number]: { question: string; hasNotApplicable: boolean } } = {};
  clean = true;
  filesChanged = new Subject<void>();
  readonly #submission = new Subject<TreasuryAgreementForm>();
  readonly #submission$ = this.#submission.pipe(
    exhaustMap((form) => this.#send(form)),
    share()
  );

  reset(retain?: boolean) {
    if (retain) return;
    this.form.reset();
    this.currencyFlows.splice(0);
    this.interestRateProducts.splice(0);
    this.commodities.splice(0);
    this.bankLimits.splice(0);
    this.banks.splice(0);
    this.editedCurrencyFlow = undefined;
    this.cachedCurrencyFlow = undefined;
    this.editedInterestRateProduct = undefined;
    this.cachedInterestRateProduct = undefined;
    this.editedCommodity = undefined;
    this.cachedCommodity = undefined;
    this.editedBankLimit = undefined;
    this.cachedBankLimit = undefined;
    this.attorneys.clear();
    this.addCurrencyFlow();
    this.addInterestRateProduct();
    this.addCommodity();
    this.addBankLimit();
    this.clean = true;
  }

  setFromDetails(details: TreasuryFormDetails) {
    this.form.reset({
      products: {
        currency:
          details.products.isCurrencyProduct ||
          !!(this.source === "apply" && this.form.get("products.currency")?.value),
        interestRate:
          details.products.isInterestRateProduct ||
          !!(this.source === "apply" && this.form.get("products.interestRate")?.value),
        commodity:
          details.products.isCommodityProduct ||
          !!(this.source === "apply" && this.form.get("products.commodity")?.value),
      },
      hasOtherBanks: details.externalTransactions.hasTransactions ? "true" : "false",
      hasBankLimits: details.externalLimits.hasLimits ? "true" : "false",
    });
    details.currencyFlows.length && this.currencyFlows.splice(0);
    details.currencyFlows.forEach((x) => {
      this.addCurrencyFlow();
      this.editedCurrencyFlow = undefined;
      this.cachedCurrencyFlow = undefined;
      const form = this.currencyFlows[this.currencyFlows.length - 1];
      form.reset({
        side: x.customerBuySell === "B" ? "Buy" : "Sell",
        currency: x.currency,
        saved: true,
        tenors: x.flowSet,
      });
      this.currencyFlows[this.currencyFlows.length - 1].disable();
    });
    details.interestRateProducts.length && this.interestRateProducts.splice(0);
    details.interestRateProducts.forEach((x) => {
      this.addInterestRateProduct();
      this.editedInterestRateProduct = undefined;
      this.cachedInterestRateProduct = undefined;
      this.interestRateProducts[this.interestRateProducts.length - 1].reset({
        currency: x.currency,
        amount: x.amount,
        date: dateToMonthString(new Date(x.settleDate)),
        name: x.name,
        collateralPercent: x.collateralPercent,
        collateralDate: dateToMonthString(new Date(x.collateralSettleDate)),
        isCollateralized: x.collateralized,
        collateralProduct: x.collateralProduct,
        saved: true,
      });
      this.interestRateProducts[this.interestRateProducts.length - 1].disable();
    });
    details.commodities.length && this.commodities.splice(0);
    details.commodities.forEach((x) => {
      this.addCommodity();
      this.editedCommodity = undefined;
      this.cachedCommodity = undefined;
      this.commodities[this.commodities.length - 1].reset({
        commodity: { name: x.name, unit: x.metric },
        amount: x.value,
        unit: x.metric,
        currency: x.currency,
        date: dateToMonthString(new Date(x.date)),
        side: x.customerBuySell === "B" ? "Buy" : "Sell",
        collateralPercent: x.collateralPercent,
        isCollateralized: x.collateralized,
        saved: true,
      });
      this.commodities[this.commodities.length - 1].disable();
    });
    details.externalTransactions.hasTransactions && this.banks.splice(0);
    details.externalTransactions.banks.forEach((x) => {
      this.banks.push(x.bankNameMarked);
    });
    details.externalLimits.hasLimits && this.bankLimits.splice(0);
    details.externalLimits.limits.forEach((x) => {
      this.addBankLimit();
      this.editedBankLimit = undefined;
      this.cachedBankLimit = undefined;
      this.bankLimits[this.bankLimits.length - 1].reset({
        bank: x.bankName,
        amount: x.amount,
        currency: x.currency,
        saved: true,
      });
      this.bankLimits[this.bankLimits.length - 1].disable();
    });
    details.generalStatements.forEach((x) => {
      const control = this.form.get(`declarations.statement-${x.questionId}`);
      if (!control) return;
      control.setValue(x.answer);
    });
    details.financeStatements.forEach((x) => {
      const control = this.form.get(`financialDeclarations.financial-statement-${x.questionId}`);
      if (!control) return;
      control.setValue(x.answer);
    });
  }

  readonly currencyFlows: FormGroup[] = [];
  readonly interestRateProducts: FormGroup[] = [];
  readonly commodities: FormGroup[] = [];
  readonly bankLimits: FormGroup[] = [];
  editedCurrencyFlow?: number;
  cachedCurrencyFlow?: CurrencyFlow;
  editedInterestRateProduct?: number;
  cachedInterestRateProduct?: InterestRateProduct;
  editedCommodity?: number;
  cachedCommodity?: Commodity;
  editedBankLimit?: number;
  cachedBankLimit?: BankLimit;
  readonly banksControl = new FormControl(null);
  readonly otherBankControl = new FormControl("", { updateOn: "blur" });
  readonly banks: string[] = [];
  readonly attorneysControl = new FormControl(null);
  readonly attorneys = new Set<string>();
  readonly currencyFlowsTenors = ["flow1", "flow2", "flow3", "flow4", "flow5", "flow6", "flow7"];

  readonly currencyFlowsCountChanged: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  readonly currencyFlowsValueChanges$: Observable<CurrencyFlow[]> =
    this.currencyFlowsCountChanged.pipe(
      switchMap(() =>
        combineLatest(
          this.currencyFlows.map((flow) => flow.valueChanges.pipe(startWith(flow.value)))
        )
      )
    );

  readonly interestRateProductsCountChanged: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );
  readonly interestRateProductsValueChanges$: Observable<InterestRateProduct[]> =
    this.interestRateProductsCountChanged.pipe(
      switchMap(() =>
        combineLatest(
          this.interestRateProducts.map((source) =>
            source.valueChanges.pipe(startWith(source.value))
          )
        )
      )
    );

  readonly commoditiesCountChanged: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  readonly commoditiesValueChanges$: Observable<Commodity[]> = this.commoditiesCountChanged.pipe(
    switchMap(() =>
      combineLatest(
        this.commodities.map((commodity) => commodity.valueChanges.pipe(startWith(commodity.value)))
      )
    )
  );

  readonly bankLimitsCountChanged: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  readonly bankLimitsValueChanges$: Observable<BankLimit[]> = this.bankLimitsCountChanged.pipe(
    switchMap(() =>
      combineLatest(this.bankLimits.map((limit) => limit.valueChanges.pipe(startWith(limit.value))))
    )
  );

  readonly banksCountChanged: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  readonly attorneysCountChanged: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  readonly overridePage: Subject<number> = new Subject<number>();

  debugSubmit: unknown = {};

  submit(options?: {
    onSuccess?: () => void;
    onFailure?: () => void;
    overrideAttorneys?: string[];
    attorneysEncrypted?: boolean;
  }) {
    const form = this.form.getRawValue();

    form.hasOtherBanks = form.hasOtherBanks && parseBoolean(form.hasOtherBanks);
    form.hasBankLimits = form.hasBankLimits && parseBoolean(form.hasBankLimits);
    if (options?.overrideAttorneys) form.authType = "I";

    const declarations: { [key: string]: boolean } = {};
    for (const declaration in form.declarations) {
      declarations[declaration.substring(10)] = form.declarations[declaration];
    }

    const financialDeclarations: { [key: string]: string } = {};
    for (const declaration in form.financialDeclarations) {
      financialDeclarations[declaration.substring(20)] = form.financialDeclarations[declaration];
    }

    const formData = {
      ...form,
      origin: this.source,
      banks: form.hasOtherBanks ? this.banks : [],
      attorneysEncrypted: !!options?.attorneysEncrypted,
      authAttorneys:
        options?.overrideAttorneys ??
        (this.source !== "apply" && form.authType === "I" ? [...this.attorneys] : []),
      declarations,
      financialDeclarations: this.user.userData.treasuryAgreementSummary?.isCustomerKnownForRiskArea
        ? {}
        : financialDeclarations,
      files: this.files.map((x) => ({ name: x.file.name, safeFileId: x.safeFileID })),
      currencyFlows: form.products.currency
        ? this.currencyFlows
            .map((form) => form.getRawValue())
            .filter((form) => form.saved)
            .map((form) => {
              const { saved, ...rest } = form;
              return rest;
            })
        : [],
      interestRateProducts: form.products.interestRate
        ? this.interestRateProducts
            .map((form) => form.getRawValue())
            .filter((form) => form.saved)
            .map((form) => {
              const { saved, date, collateralDate, ...rest } = form;
              return {
                ...rest,
                date: monthStringToDate(date),
                collateralDate: monthStringToDate(collateralDate),
              };
            })
        : [],
      commodities: form.products.commodity
        ? this.commodities
            .map((form) => {
              const item = form.getRawValue();
              return {
                ...item,
                commodity: item.commodity.name,
              };
            })
            .filter((form) => form.saved)
            .map((form) => {
              const { saved, date, ...rest } = form;
              return { ...rest, date: monthStringToDate(date) };
            })
        : [],
      bankLimits: form.hasBankLimits
        ? this.bankLimits
            .map((form) => form.getRawValue())
            .filter((form) => form.saved)
            .map((form) => {
              const { saved, ...rest } = form;
              return rest;
            })
        : [],
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hasOtherBanks, hasBankLimits, ...rest } = formData;
    console.log(rest);
    if (isDevMode()) {
      this.debugSubmit = rest;
    }
    this.#submission$.subscribe({
      next: (x) => {
        if (isApiSuccess(x)) {
          switch (this.source) {
            case "apply":
              options?.onSuccess && options.onSuccess();
              return;
            case "profile":
              this.modal.dialog(
                {
                  type: "success",
                  title: "TreasuryForm.Success",
                  hideCloseButton: true,
                  buttons: {
                    primary: {
                      text: "OK",
                      onClick: () => {
                        this.navigation.navigate(["/profile/treasury-form"]);
                        return;
                      },
                    },
                  },
                },
                "page"
              );
              return;
          }
        }
        if (isApiFailure(x)) {
          switch (this.source) {
            case "apply":
              options?.onFailure && options.onFailure();
              return;
            case "profile":
              this.modal.dialog(
                {
                  type: "failure",
                  title: "TreasuryForm.Failure",
                  hideCloseButton: true,
                  buttons: {
                    primary: {
                      text: "buttons.Retry",
                      onClick: () => {
                        switch (this.source) {
                          case "apply":
                            options?.onFailure && options.onFailure();
                            return;
                          case "profile":
                            this.navigation.navigate([window.location.pathname], {
                              state: { retain: true },
                            });
                            return;
                        }
                      },
                    },
                  },
                },
                "page"
              );
              return;
          }
        }
      },
    });
    this.#submission.next(rest);
  }

  #send(form: TreasuryAgreementForm) {
    return this.http.post("/treasuryAgreement", form).pipe(
      mapToApiResponse(),
      takeWhile(isApiPending, true),
      audit(() => (this.source === "profile" ? this.user.load() : of(undefined)))
    );
  }

  addCurrencyFlow() {
    const flow = this.fb.group({
      side: ["", Validators.required],
      currency: ["", Validators.required],
      tenors: this.fb.group(
        this.currencyFlowsTenors.reduce<{ [key: string]: FormGroup }>((prev, name) => {
          prev[name] = this.fb.group({
            periodAmount: [
              "",
              Validators.compose([
                Validators.min(0),
                requiredIf(
                  (control) =>
                    (<FormGroup>control.parent)?.controls["periodCollateralPercent"].value
                ),
              ]),
            ],
            periodCollateralPercent: [
              "",
              Validators.compose([
                Validators.min(0),
                Validators.max(100),
                requiredIf(
                  (control) => (<FormGroup>control.parent)?.controls["periodAmount"].value
                ),
              ]),
            ],
          });
          return prev;
        }, {})
      ),
      saved: [false],
    });

    this.currencyFlows.push(flow);
    this.editedCurrencyFlow = this.currencyFlows.length - 1;
    this.cachedCurrencyFlow = undefined;
    this.currencyFlowsCountChanged.next();
  }

  saveCurrencyFlow(index?: number) {
    const flow = this.currencyFlows[index ?? this.currencyFlows.length - 1];
    const savedControl = flow.controls["saved"];
    savedControl.updateValueAndValidity();
    if (!handleFormErrors(flow) || !validateRequiredCurrencyTenors(flow)) return;
    savedControl.setValue(true);
    flow.disable({ emitEvent: false });
    this.form.get("products.currency")?.setValue(true);
    this.editedCurrencyFlow = undefined;
    this.cachedCurrencyFlow = undefined;
  }

  editCurrencyFlow(index: number) {
    const flow = this.currencyFlows[index];
    if (this.editedCurrencyFlow !== undefined) {
      if (this.cachedCurrencyFlow) {
        this.currencyFlows[this.editedCurrencyFlow].reset({
          ...this.cachedCurrencyFlow,
          saved: false,
        });
        this.saveCurrencyFlow(this.editedCurrencyFlow);
        this.cachedCurrencyFlow = undefined;
      } else {
        this.removeCurrencyFlow(this.editedCurrencyFlow);
      }
      this.editedCurrencyFlow = undefined;
    }
    this.editedCurrencyFlow = index;
    this.cachedCurrencyFlow = flow.getRawValue();
    flow.controls["saved"].setValue(false);
    flow.enable({ emitEvent: false });
  }

  removeCurrencyFlow(index: number) {
    this.currencyFlows.splice(index, 1);
    this.currencyFlowsCountChanged.next();
    if (this.editedCurrencyFlow === index) {
      this.editedCurrencyFlow = undefined;
      this.cachedCurrencyFlow = undefined;
    }
    if (!this.currencyFlows.length) {
      this.addCurrencyFlow();
    }
  }

  addInterestRateProduct() {
    this.interestRateProducts.push(
      this.fb.group({
        currency: ["", Validators.required],
        amount: ["", Validators.compose([Validators.required, Validators.min(0)])],
        date: [
          "",
          Validators.compose([
            Validators.required,
            monthValidator,
            minMonthValidator(this.monthLimits.min.month, this.monthLimits.min.year),
            maxMonthValidator(this.monthLimits.max.month, this.monthLimits.max.year),
          ]),
        ],
        name: ["", Validators.required],
        collateralPercent: [
          "",
          Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
        ],
        collateralDate: [
          "",
          Validators.compose([
            Validators.required,
            monthValidator,
            minMonthValidator(this.monthLimits.min.month, this.monthLimits.min.year),
            maxMonthValidator(this.monthLimits.max.month, this.monthLimits.max.year),
          ]),
        ],
        isCollateralized: ["NOT", Validators.required],
        collateralProduct: [
          "",
          requiredIf(
            (control) => (<FormGroup>control.parent)?.controls["isCollateralized"].value !== "NOT"
          ),
        ],
        saved: [false],
      })
    );

    this.editedInterestRateProduct = this.interestRateProducts.length - 1;
    this.cachedInterestRateProduct = undefined;
    this.interestRateProductsCountChanged.next();
  }

  saveInterestRateProduct(index?: number) {
    const source = this.interestRateProducts[index ?? this.interestRateProducts.length - 1];
    const savedControl = source.controls["saved"];
    savedControl.updateValueAndValidity();
    if (!validateV2(source)) return;
    savedControl.setValue(true);
    source.disable({ emitEvent: false });
    this.form.get("products.interestRate")?.setValue(true);
    this.editedInterestRateProduct = undefined;
    this.cachedInterestRateProduct = undefined;
  }

  editInterestRateProduct(index: number) {
    const source = this.interestRateProducts[index];
    if (this.editedInterestRateProduct !== undefined) {
      if (this.cachedInterestRateProduct) {
        this.interestRateProducts[this.editedInterestRateProduct].reset({
          ...this.cachedInterestRateProduct,
          saved: false,
        });
        this.saveInterestRateProduct(this.editedInterestRateProduct);
        this.cachedInterestRateProduct = undefined;
      } else {
        this.removeInterestRateProduct(this.editedInterestRateProduct);
      }
      this.editedInterestRateProduct = undefined;
    }
    this.editedInterestRateProduct = index;
    this.cachedInterestRateProduct = source.getRawValue();
    source.controls["saved"].setValue(false);
    source.enable();
    source.controls["isCollateralized"].enable(); //This is to emit valueChanges on the control, so that it can disable the collateralProduct control if needed
  }

  removeInterestRateProduct(index: number) {
    this.interestRateProducts.splice(index, 1);
    this.interestRateProductsCountChanged.next();
    if (this.editedInterestRateProduct === index) {
      this.editedInterestRateProduct = undefined;
      this.cachedInterestRateProduct = undefined;
    }
    if (!this.interestRateProducts.length) {
      this.addInterestRateProduct();
    }
  }

  addCommodity() {
    this.commodities.push(
      this.fb.group({
        commodity: ["", Validators.required],
        amount: ["", Validators.compose([Validators.required, Validators.min(0)])],
        unit: ["", Validators.required],
        currency: ["", Validators.required],
        date: [
          "",
          Validators.compose([
            Validators.required,
            monthValidator,
            minMonthValidator(this.monthLimits.min.month, this.monthLimits.min.year),
            maxMonthValidator(this.monthLimits.max.month, this.monthLimits.max.year),
          ]),
        ],
        side: ["Buy", Validators.required],
        collateralPercent: [
          "",
          Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
        ],
        isCollateralized: ["NOT", Validators.required],
        saved: [false],
      })
    );
    this.editedCommodity = this.currencyFlows.length - 1;
    this.cachedCommodity = undefined;
    this.commoditiesCountChanged.next();
  }

  saveCommodity(index?: number) {
    const commodity = this.commodities[index ?? this.commodities.length - 1];
    const savedControl = commodity.controls["saved"];
    savedControl.updateValueAndValidity();
    validateUnique(this.commodities, commodityEqualityPredicate);
    if (!validateV2(commodity)) return;
    savedControl.setValue(true);
    commodity.disable({ emitEvent: false });
    this.form.get("products.commodity")?.setValue(true);
    this.editedCommodity = undefined;
    this.cachedCommodity = undefined;
  }

  editCommodity(index: number) {
    const commodity = this.commodities[index];
    if (this.editedCommodity !== undefined) {
      if (this.cachedCommodity) {
        this.commodities[this.editedCommodity].reset({
          ...this.cachedCommodity,
          saved: false,
        });
        this.saveCommodity(this.editedCommodity);
        this.cachedCommodity = undefined;
      } else {
        this.removeCommodity(this.editedCommodity);
      }
      this.editedCommodity = undefined;
    }
    this.editedCommodity = index;
    this.cachedCommodity = commodity.getRawValue();
    commodity.controls["saved"].setValue(false);
    commodity.enable({ emitEvent: false });
  }

  removeCommodity(index: number) {
    this.commodities.splice(index, 1);
    this.commoditiesCountChanged.next();
    if (this.editedCommodity === index) {
      this.editedCommodity = undefined;
      this.cachedCommodity = undefined;
    }
    if (!this.commodities.length) {
      this.addCommodity();
    }
  }

  addBankLimit() {
    this.bankLimits.push(
      this.fb.group({
        bank: ["", Validators.required],
        amount: ["", Validators.compose([Validators.required, Validators.min(0)])],
        currency: ["", Validators.required],
        saved: [false],
      })
    );
    this.editedBankLimit = this.currencyFlows.length - 1;
    this.cachedBankLimit = undefined;
    this.bankLimitsCountChanged.next();
  }

  saveBankLimit(index?: number) {
    const limit = this.bankLimits[index ?? this.bankLimits.length - 1];
    const savedControl = limit.controls["saved"];
    savedControl.updateValueAndValidity();
    if (!validateV2(limit)) return;
    savedControl.setValue(true);
    limit.disable({ emitEvent: false });
    this.editedBankLimit = undefined;
    this.cachedBankLimit = undefined;
  }

  editBankLimit(index: number) {
    const limit = this.bankLimits[index];
    if (this.editedBankLimit !== undefined) {
      if (this.cachedBankLimit) {
        this.bankLimits[this.editedBankLimit].reset({
          ...this.cachedBankLimit,
          saved: false,
        });
        this.saveBankLimit(this.editedBankLimit);
        this.cachedBankLimit = undefined;
      } else {
        this.removeBankLimit(this.editedBankLimit);
      }
      this.editedBankLimit = undefined;
    }
    this.editedBankLimit = index;
    this.cachedBankLimit = limit.getRawValue();
    limit.controls["saved"].setValue(false);
    limit.enable({ emitEvent: false });
  }

  removeBankLimit(index: number) {
    this.bankLimits.splice(index, 1);
    this.bankLimitsCountChanged.next();
    if (this.editedBankLimit === index) {
      this.editedBankLimit = undefined;
      this.cachedBankLimit = undefined;
    }
    if (!this.bankLimits.length) {
      this.addBankLimit();
    }
  }

  validateBanks() {
    if (this.banks.length) return true;

    this.banksControl.setErrors({ required: true });
    this.banksControl.markAsDirty();
    setTimeout(scrollToError, 100);
    return false;
  }

  validateFiles(data: TreasuryAgreementData) {
    if (
      this.files.length ||
      !this.banks.filter(
        (bank) => !(data.banks.find((x) => x.name == bank)?.reportsToSwitip ?? false)
      ).length
    )
      return true;

    return false;
  }

  validateAttorneys() {
    if (this.attorneys.size) return true;

    this.attorneysControl.setErrors({ required: true });
    this.attorneysControl.markAsDirty();
    setTimeout(scrollToError, 100);
    return false;
  }

  validateProducts() {
    if (Object.values(this.form.getRawValue().products).some(Boolean)) return true;

    const control = this.form.controls["products"];
    control.markAsDirty();
    control.updateValueAndValidity();

    setTimeout(scrollToError, 100);
    return false;
  }

  validatePage(page: TreasurySurveyPage, data: TreasuryAgreementData) {
    const form = this.form.getRawValue();
    form.hasOtherBanks = form.hasOtherBanks && parseBoolean(form.hasOtherBanks);
    form.hasBankLimits = form.hasBankLimits && parseBoolean(form.hasBankLimits);

    switch (page) {
      case "products":
        return (
          validateV2(<FormGroup>this.form.controls["products"]) &&
          this.validateProducts() &&
          (!form.products.currency || validateSaved(this.currencyFlows)) &&
          (!form.products.interestRate || validateSaved(this.interestRateProducts)) &&
          (!form.products.commodity || validateSaved(this.commodities))
        );
      case "banks":
        return (
          validateV2(<FormGroup>this.form.controls["hasBankLimits"]) &&
          validateV2(<FormGroup>this.form.controls["hasOtherBanks"]) &&
          (!form.hasOtherBanks || this.validateBanks()) &&
          (!form.hasOtherBanks || this.validateFiles(data)) &&
          (!form.hasBankLimits || validateSaved(this.bankLimits))
        );
      case "financialDeclarations":
        return validateV2(<FormGroup>this.form.controls["financialDeclarations"]);
      case "declarations":
        return validateV2(<FormGroup>this.form.controls["declarations"]);
      case "authSchema":
        return (
          validateV2(<FormGroup>this.form.controls["authType"]) &&
          (form.authType !== "I" || this.validateAttorneys())
        );
      default:
        return false;
    }
  }

  getDetails(): TreasuryFormDetails {
    const form = this.form.getRawValue();

    const declarations: TreasuryFormDetails["generalStatements"] = [];
    for (const declaration in form.declarations) {
      const id = Number(declaration.substring(10));
      declarations.push({
        questionId: id,
        answer: form.declarations[declaration],
        question: this.declarationData[id].question,
      });
    }

    const financialDeclarations: TreasuryFormDetails["financeStatements"] = [];
    for (const declaration in form.financialDeclarations) {
      const id = Number(declaration.substring(20));
      financialDeclarations.push({
        questionId: id,
        answer: form.financialDeclarations[declaration],
        hasNotApplicable: this.financialDeclarationData[id].hasNotApplicable,
        question: this.financialDeclarationData[id].question,
      });
    }

    return {
      products: {
        isCurrencyProduct: form.products.currency,
        isInterestRateProduct: form.products.interestRate,
        isCommodityProduct: form.products.commodity,
      },
      currencyFlows: form.products.currency
        ? this.currencyFlows
            .map((flow) => <CurrencyFlow>flow.getRawValue())
            .filter((flow) => flow.saved)
            .map((flow) => ({
              customerBuySell: flow.side === "Buy" ? "B" : "S",
              currency: flow.currency,
              flowSet: flow.tenors,
            }))
        : [],
      interestRateProducts: form.products.interestRate
        ? this.interestRateProducts
            .map((product) => <InterestRateProduct>product.getRawValue())
            .filter((product) => product.saved)
            .map((product) => ({
              currency: product.currency,
              amount: product.amount,
              settleDate: product.date,
              name: product.name,
              collateralPercent: product.collateralPercent,
              collateralSettleDate: product.collateralDate,
              collateralized: product.isCollateralized,
              collateralProduct: product.collateralProduct,
            }))
        : [],
      commodities: form.products.commodity
        ? this.commodities
            .map((commodity) => <Commodity>commodity.getRawValue())
            .filter((commodity) => commodity.saved)
            .map((commodity) => ({
              name: commodity.commodity.name,
              value: commodity.amount,
              metric: commodity.unit,
              currency: commodity.currency,
              date: commodity.date,
              customerBuySell: commodity.side === "Buy" ? "B" : "S",
              collateralPercent: commodity.collateralPercent,
              collateralized: commodity.isCollateralized,
            }))
        : [],
      externalTransactions: {
        hasTransactions: form.hasOtherBanks && parseBoolean(form.hasOtherBanks),
        banks:
          form.hasOtherBanks && parseBoolean(form.hasOtherBanks)
            ? this.banks.map((bank) => ({ bankNameMarked: bank }))
            : [],
        files: [],
      },
      externalLimits: {
        hasLimits: form.hasBankLimits && parseBoolean(form.hasBankLimits),
        limits:
          form.hasOtherBanks && parseBoolean(form.hasOtherBanks)
            ? this.bankLimits
                .map((limit) => <BankLimit>limit.getRawValue())
                .filter((limit) => limit.saved)
                .map((limit) => ({
                  bankName: limit.bank,
                  amount: limit.amount,
                  currency: limit.currency,
                }))
            : [],
      },
      financeStatements: financialDeclarations,
      generalStatements: declarations,
    };
  }
  clearProductItems(product: ProductKey) {
    switch (product) {
      case "currency":
        while (this.currencyFlows.length > 1) {
          this.removeCurrencyFlow(this.currencyFlows.length - 1);
        }
        this.removeCurrencyFlow(0);
        return;
      case "interestRate":
        while (this.interestRateProducts.length > 1) {
          this.removeInterestRateProduct(this.interestRateProducts.length - 1);
        }
        this.removeInterestRateProduct(0);
        return;
      case "commodity":
        while (this.commodities.length > 1) {
          this.removeCommodity(this.commodities.length - 1);
        }
        this.removeCommodity(0);
        return;
    }
  }
}

const commodityEqualityPredicate = (a: FormGroup, b: FormGroup) =>
  a.controls["commodity"].value === b.controls["commodity"].value &&
  a.controls["currency"].value === b.controls["currency"].value &&
  a.controls["date"].value === b.controls["date"].value &&
  a.controls["side"].value === b.controls["side"].value;

function monthStringToDate(value: string) {
  const [year, month] = value.split("-");
  return new Date(Date.UTC(+year, +month - 1));
}

function dateToMonthString(value: Date) {
  const year = value.getFullYear().toString().padStart(4, "0");
  const month = (value.getMonth() + 1).toString().padStart(2, "0");
  return `${year}-${month}`;
}

function validateSaved(forms: FormGroup[]) {
  if (!forms.length) {
    return false;
  }
  for (const form of forms) {
    const control = form.controls["saved"] as FormControl;
    if (control?.value) continue;

    control.setErrors({ notSaved: true });
    control.markAsDirty();
    form.updateValueAndValidity({ onlySelf: true });
    setTimeout(scrollToError, 100);
    return false;
  }
  return true;
}
