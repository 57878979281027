import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RangeFormComponent } from "./form-range.component";

@Component({
  selector: "app-form-range-number",
  templateUrl: "range-number.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberRangeFormComponent extends RangeFormComponent<number> {
  @Input() decimals = 0;
}
