import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { BreakpointObserverService } from "@core/breakpoints";
import { Failure } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { ModalService } from "@features/modal/modal.service";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { TableColumn } from "@shared/components/table/table-model";
import { Observable, Subscription, defer, delay, filter, map, startWith } from "rxjs";
import { MultiFxLegForm } from "../../models/multifx-form";
import { MultiFxTransactionRateFailure } from "../../models/multifx-rate";
import { MultiFxFormService } from "../../services/multifx-form.service";
import { Action, mapToAction, updateSideControls } from "../../utils/par-forward-utils";
import { FormMultifxNewTransactionLegComponent } from "../form-multifx-new-transaction-leg/form-multifx-new-transaction-leg.component";

@Component({
  selector: "app-form-multifx-transactions-leg",
  templateUrl: "./form-multifx-transactions-leg.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormMultifxTransactionsLegComponent implements OnInit, OnDestroy {
  @Input() seriesErrors?: MultiFxTransactionRateFailure[];

  currencyPair$!: Observable<string>;
  currency$!: Observable<string>;
  disabledAddition$!: Observable<boolean>;
  legStates: LegStatus[] = [];
  lastId = 0;
  #subsciption!: Subscription;

  readonly columns: TableColumn[] = [
    { name: "multifx.Index" },
    { name: "multifx.Type", align: "center" },
    { name: "multifx.Side", align: "center" },
    { name: "Amount", align: "end" },
    { name: "SettlementDate", align: "end" },
    { name: "", actionMenu: true },
  ];

  get #controls() {
    return this.parent.form.controls;
  }

  get series() {
    return this.#controls.series as FormArray;
  }

  get isMobile() {
    return this.breakPointObserver.isMatched("xs");
  }

  constructor(
    private formService: MultiFxFormService,
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private modal: ModalService,
    private cdr: ChangeDetectorRef,
    private breakPointObserver: BreakpointObserverService,
    private tracker: Tracker
  ) {}

  ngOnInit(): void {
    const { currencyPair, currency } = this.#assertForm();
    const { series } = this.formService.current;
    this.lastId = Math.max(
      ...(series ?? []).map(({ clientSideId }) => +clientSideId.substring(1)),
      0
    );

    this.currencyPair$ = defer(() =>
      currencyPair.valueChanges.pipe(startWith(currencyPair.value), filter(Boolean))
    );
    this.currency$ = defer(() => currency.valueChanges.pipe(startWith(currency.value)));
    this.disabledAddition$ = this.series.valueChanges.pipe(
      map((series: MultiFxLegForm[]) => series.length >= 10),
      delay(0)
    );
    this.addInitialLegs(series);
    this.series.markAsUntouched();

    this.#subsciption = this.breakPointObserver
      .isBreakpoint("xs")
      .pipe(filter(Boolean))
      .subscribe(() => this.deleteUnsaved());
  }

  ngOnDestroy(): void {
    this.#subsciption.unsubscribe();
  }

  #assertForm() {
    const { currencyPair, currency, series, parForward } = this.#controls;

    if (!currencyPair || !currency || !series || !(series instanceof FormArray) || !parForward) {
      throw new Error(
        "This component needs 'currencyPair', 'currency', 'parForward' and 'series' as FormArray controls present on the form!"
      );
    }

    series.addValidators(this.#minLengthValidator);

    return { currencyPair, currency };
  }

  getSeriesAt(index: number) {
    return this.series.at(index) as FormGroup;
  }

  addInitialLegs(series: MultiFxLegForm[]) {
    if (!series || series.length === 0) {
      if (!this.isMobile) {
        this.addNewLeg();
      }
      return;
    }

    series.map(this.#mapToFormGroup).forEach((group) => {
      if (
        this.seriesErrors &&
        this.seriesErrors.some((error) => error.clientSideId === group.controls.clientSideId.value)
      ) {
        this.legStates.push({ isEditing: !this.isMobile, isNew: false });
        const error = this.seriesErrors.find(
          (error) => error.clientSideId === group.controls.clientSideId.value
        )?.error ?? { code: "GENERIC" };
        const seriesLegValidator = this.#seriesLegValidator(error);
        group.addValidators(seriesLegValidator);
      } else {
        this.legStates.push({ isEditing: false, isNew: false });
      }
      this.series.push(group);
    });

    this.cdr.detectChanges();
  }

  addNewLeg() {
    const newForm = this.fb.group({
      clientSideId: [`c${++this.lastId}`],
      side: ["Sell", Validators.required],
      amount: [null, Validators.required],
      settlement: this.fb.group({ tenor: "TOD", date: null }),
      product: [null, Validators.required],
    });

    this.legStates.push({ isEditing: true, isNew: true });
    this.series.push(newForm);
    this.#openNewLegModal(this.series.length - 1, newForm);
    this.cdr.detectChanges();
  }

  deleteLeg(rowIndex: number): void {
    const leg = this.series.at(rowIndex).value;
    this.tracker.reportProgress({
      action: "delete-leg",
      data: { amount: leg.amount, date: leg.settlement.date },
    });
    this.legStates.splice(rowIndex, 1);
    this.series.removeAt(rowIndex);
    if (this.series.controls.length === 0 && !this.isMobile) {
      this.addNewLeg();
    }
  }

  deleteUnsaved() {
    const max = this.legStates.length;
    let maxCounter = 0;
    for (let i = 0; i < this.legStates.length && maxCounter < max; i++) {
      if (this.legStates[i].isEditing) {
        this.deleteLeg(i);
        i--;
      }

      maxCounter++;
    }
  }

  edit(rowIndex: number) {
    this.legStates[rowIndex].isEditing = true;
    this.#openNewLegModal(rowIndex, this.getSeriesAt(rowIndex));
  }

  save(rowIndex: number) {
    const leg = this.series.at(rowIndex).value;
    this.tracker.reportProgress({
      action: this.legStates[rowIndex].isNew ? "add-leg" : "update-leg",
      data: { amount: leg.amount, date: leg.settlement.date },
    });
    this.legStates[rowIndex].isEditing = false;
    this.legStates[rowIndex].isNew = false;

    const { parForward } = this.#controls;
    const action = mapToAction(this.series, parForward.value, rowIndex);
    if (!action.shouldUpdate) return;

    updateSideControls(action);
    this.#showWarningDialog(action);
  }

  #showWarningDialog({ side }: Action) {
    this.modal.dialog({
      title: "modals.parForward.Title",
      body: { key: `modals.parForward.Description${side}` },
      buttons: { primary: defaultPrimary },
    });
  }

  #minLengthValidator: ValidatorFn = (arrayControl: AbstractControl): ValidationErrors | null => {
    const array = arrayControl as FormArray;
    return array.controls.length >= 2 ? null : { seriesTooShort: true };
  };

  #seriesLegValidator = (failure: Failure): ValidatorFn => {
    return (formControl: AbstractControl): ValidationErrors | null => {
      const form = formControl as FormGroup;
      return form.untouched ? { [failure.code]: failure.data } : null;
    };
  };

  #mapToFormGroup = (form: MultiFxLegForm): FormGroup => {
    return this.fb.group({
      clientSideId: [form.clientSideId],
      side: [form.side, Validators.required],
      amount: [form.amount, Validators.required],
      settlement: this.fb.group(form.settlement),
      product: [form.product, Validators.required],
    });
  };

  async #openNewLegModal(index: number, form: FormGroup) {
    if (!this.isMobile) return;

    const { componentInstance, result } = this.modal.modal({
      component: FormMultifxNewTransactionLegComponent,
      fullscreen: true,
      backdrop: false,
      centered: true,
      scrollable: false,
    });

    componentInstance.modal = true;
    componentInstance.currencyPair = this.currencyPair$;
    componentInstance.currency = this.currency$;
    componentInstance.form = form;

    try {
      const action: "save" | "delete" | undefined = await result;
      if (action === "save") {
        this.save(index);
      } else if (action === "delete") {
        this.deleteLeg(index);
      }
    } catch {
      // bootstrap rejects modal promise on window size change
    }

    this.cdr.detectChanges();
  }

  get hasError() {
    return !!this.series && !this.series.valid && (this.series.dirty || this.series.touched);
  }

  hasSeriesError(index: number) {
    return (
      !!this.series.controls[index] &&
      !this.series.controls[index].valid &&
      (this.series.controls[index].dirty || this.series.controls[index].touched)
    );
  }
}

type LegStatus = {
  isEditing: boolean;
  isNew: boolean;
};
