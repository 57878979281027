import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfigStore } from "@core/config";
import { Failure } from "@core/models/transaction";
import { NavigationService } from "@core/navigation";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions, origins } from "@core/user-tracking/tracking.model";
import { NormalExchangeForm } from "@features/exchange/models";
import { ExchangeWarningCodeService } from "@features/exchange/services/exchange-warning-code.service";
import { TileMessageComponent } from "@features/modal/tile-message/tile-message.component";
import { PRODUCT_TYPES, isSpotTenor } from "@features/tenor";
import {
  ComplexInitializationResponse,
  isInitializationWarning,
  isMifidError,
} from "@features/transaction/models";
import { getWarningDialog } from "@features/transaction/utils/dialog";
import { handleFormErrors } from "@features/transaction/utils/form";
import { Dialog } from "@shared/components/modal/modal-model";
import {
  Observable,
  Subscription,
  filter,
  firstValueFrom,
  shareReplay,
  skip,
  switchMap,
  tap,
} from "rxjs";
import { pageClasses, prepareForm, tileClasses } from "../../exchange-utils";
import { ExchangeFormService, ExchangeResponseService, ExchangeService } from "../../services";

@Component({
  selector: "app-form-exchange-forward",
  templateUrl: "form-exchange-forward.component.html",
})
export class ForwardExchangeFormComponent implements OnInit, OnDestroy {
  @Input() isTile = false;

  subscription = new Subscription();
  form: FormGroup;
  link: string;
  errorMessage$: Observable<Dialog | null>;
  showForm = false;
  mifidValidation$: Observable<ComplexInitializationResponse>;
  @ViewChild("mifidWarnings", { read: ViewContainerRef }) mifidWarnings!: ViewContainerRef;

  back = () => this.navigationService.navigate(["/"]);

  get classes() {
    return this.isTile ? tileClasses : pageClasses;
  }

  constructor(
    fb: FormBuilder,
    private formService: ExchangeFormService,
    private exchangeService: ExchangeService,
    private responseService: ExchangeResponseService,
    public warningService: ExchangeWarningCodeService,
    store: ConfigStore,
    private navigationService: NavigationService,
    private elem: ElementRef,
    private tracker: Tracker
  ) {
    this.link = store.config.links.forwardRisk;

    const form = formService.current as NormalExchangeForm;
    const { tenor, date } = form.settlement;

    this.form = fb.group({
      side: form.side,
      currency: form.currency,
      counterCurrency: form.counterCurrency,
      netSettlement: form.netSettlement,
      amount: form.amount,
      account: form.account,
      counterAccount: form.counterAccount,
      pvp: form.pvp,
      product: null,
      settlement: fb.group({
        tenor: ensureForward(tenor),
        // date is set by tenor, unless user picked a date without a tenor.
        date: tenor ? null : date,
      }),
      collateral: fb.group(form.collateral ?? {}),
    });

    const product$ = this.form.controls.product.valueChanges.pipe(
      filter((product) => product !== PRODUCT_TYPES.forward),
      tap(() => this.formService.change("spot"))
    );

    const tab$ = this.formService.formType$.pipe(
      skip(1),
      filter((type) => type !== "swap"),
      tap(() => this.save())
    );

    this.subscription.add(product$.subscribe());
    this.subscription.add(tab$.subscribe());

    this.mifidValidation$ = this.exchangeService
      .validateMifid("FxForw")
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.errorMessage$ = this.mifidValidation$.pipe(
      switchMap((response) => this.#handleMifidResponse(response))
    );
  }

  ngOnInit(): void {
    this.isTile ||
      this.tracker.follow(
        { process: "exchange", origin: history.state?.origin ?? "reload" },
        { type: "forward" }
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    this.save();

    const componentSelector = this.elem.nativeElement.tagName.toLowerCase();
    if (handleFormErrors(this.form, componentSelector)) {
      this.isTile &&
        this.tracker.follow({ process: "exchange", origin: origins.TILE }, { type: "forward" });
      this.formService.submit();
    }
  }

  private save = () => this.formService.save(prepareForm(this.form));

  async #handleMifidResponse(response: ComplexInitializationResponse) {
    if (isMifidError(response)) {
      return this.responseService.handleMifidError(response.error, this.back, this.isTile);
    }
    if (isInitializationWarning(response)) {
      const resign = () => this.formService.change("spot", "tab");

      if (this.isTile) {
        const consents = await this.#onWarningsTile(response.warnings);
        if (!consents) {
          resign();
        }
      } else {
        await this.responseService.handleMifidWarnings(response.warnings, resign);
      }
    }
    this.showForm = true;
    return null;
  }

  async #onWarningsTile(warnings: Failure[]) {
    for (const { code, data } of warnings) {
      const message = this.mifidWarnings.createComponent(TileMessageComponent);
      message.instance.data = getWarningDialog(code, "transaction", data);
      const accepted = await firstValueFrom(message.instance.clicked);
      this.mifidWarnings.clear();

      this.tracker.report({
        category: "exchange",
        action: actions.WARNING,
        data: { code, accepted: accepted ?? false },
      });

      if (!accepted) return;
    }

    return warnings.map(({ code }) => code);
  }
}

const ensureForward = (tenor?: string | null) => (isSpotTenor(tenor) ? "SN" : tenor);
