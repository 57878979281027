import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { EMPTY, catchError } from "rxjs";
import { Currency, CurrencyPair } from "./model";

@Injectable({
  providedIn: "root",
})
export class CurrencyApiService {
  #observedEvents = [AppEvent.CustomerChanged];

  constructor(private http: HttpClient, private _events: AppEventsService) {}

  getCurrencies(type: "exchange" | "deposit") {
    return this._events.observe(
      () => this.http.get<Currency[]>(`/currency/${type}`).pipe(catchError(() => EMPTY)),
      ...this.#observedEvents
    );
  }

  getCurrencyPairs(type: "pairs" | "iob-pairs" = "pairs") {
    return this._events.observe(
      () => this.http.get<CurrencyPair[]>(`/currency/${type}`).pipe(catchError(() => EMPTY)),
      ...this.#observedEvents
    );
  }

  getSortedCurrencyPairs(type: "sorted-pairs" | "fast-deal-pairs" = "sorted-pairs") {
    return this._events.observe(
      () => this.http.get<CurrencyPair[]>(`/currency/${type}`).pipe(catchError(() => EMPTY)),
      ...this.#observedEvents,
      AppEvent.IndicativesChanged
    );
  }
}
