<p class="fw-bold">
  {{
    "flowDeclaration.RefusalHeader"
      | translate
        : {
            start: declaration.dateFrom | datePko,
            end: declaration.dateTo | datePko
          }
  }}
</p>

<div class="d-flex my-2" *ngIf="showActions">
  <button class="btn btn-primary btn-fixed ms-auto" (click)="update()">
    {{ "buttons.flowDeclaration.Update" | translate }}
  </button>
</div>
