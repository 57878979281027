<dt class="pko-definition__term" [class.pko-definition__term--tooltip]="tooltip">
  {{ translateLabel ? (label | translate) : label }} <app-tooltip [content]="tooltip" *ngIf="tooltip"></app-tooltip>
</dt>

<dd class="pko-definition__description">
  <ng-container
    *ngTemplateOutlet="account ? accountTitle : value ? definitionDescription : content">
  </ng-container>
</dd>

<dd class="pko-definition__remark" *ngIf="remark || account">
  <ng-container *ngTemplateOutlet="account ? accountNumber : definitionRemark"></ng-container>
</dd>

<ng-template #definitionDescription>
  {{ value }}
</ng-template>

<ng-template #definitionRemark>
  {{ remark }}
</ng-template>

<ng-template #accountTitle>
  {{ account!.name | nullable: ("accounts.DefaultName" | translate) }} ({{
    account!.balance | balance: account!.currency
  }})
</ng-template>

<ng-template #accountNumber>
  {{ account!.number | account }}
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
