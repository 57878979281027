<app-form-control [formGroup]="group" [label]="label" [inputGroup]="true">
  <div class="pko-grid__row pko-grid__row--12">
    <app-input-number
      formControlName="from"
      [label]="labelFrom"
      [placeholder]="placeholder?.from || ''"
      [decimals]="decimals"
      class="col-6"
      ngDefaultControl>
    </app-input-number>
    <app-input-number
      formControlName="to"
      [label]="labelTo"
      [placeholder]="placeholder?.to || ''"
      [decimals]="decimals"
      class="col-6"
      ngDefaultControl>
    </app-input-number>
  </div>
</app-form-control>
