<ng-container *ngFor="let breakpoint of responsiveTiles | keyvalue">
  <ng-container *ngFor="let column of $any(breakpoint.value) | keyvalue: orderAsIs">
    <ng-container
      *ngTemplateOutlet="
        dashboardArea;
        context: {
          $implicit: { column: column, breakpoint: breakpoint.key }
        }
      "></ng-container>
  </ng-container>
</ng-container>

<ng-template #dashboardArea let-item>
  <div
    class="pko-dashboard__area pko-dashboard__area--{{ item.column.key }}"
    *ngIf="(currentBreakpoint$ | async) === item.breakpoint">
    <ng-container *ngFor="let tile of item.column.value">
      <ng-container *ngIf="this.types[tile] as template">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template sectionType="banner">
  <app-pro-banner></app-pro-banner>
</ng-template>

<ng-template sectionType="exchange">
  <app-dashboard-section *ifHasRole="'FxSpot'" sectionTitle="exchange.Simple">
    <app-form-exchange-simple></app-form-exchange-simple>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="indicatives">
  <app-indicatives></app-indicatives>
</ng-template>

<ng-template sectionType="notifications">
  <app-dashboard-section sectionTitle="notifications.Title">
    <app-notifications></app-notifications>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="shortcuts">
  <app-dashboard-section sectionTitle="shortcuts.MyShortcuts" [solid]="false">
    <app-shortcuts></app-shortcuts>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="transactions">
  <app-recent-history-view></app-recent-history-view>
</ng-template>
