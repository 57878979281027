<ng-container [formGroup]="group">
  <app-form-control [label]="label" [inputGroup]="showTenor && showDate">
    <app-select
      [hidden]="!showTenor"
      formControlName="tenor"
      [items]="tenors"
      [translateText]="translateText"
      [placeholder]="tenorPlaceholder"
      [disableSingleValue]="disableSingleValue"
      ngDefaultControl></app-select>
    <app-datepicker
      formControlName="date"
      [min]="minDate"
      [max]="maxDate"
      [holidays]="holidays"
      [restricted]="restricted"
      [errors]="errorOverrides"
      ngDefaultControl
      *ngIf="showDate"></app-datepicker>
  </app-form-control>
</ng-container>
