import { Failure } from "@core/models/transaction";

export const RESPONSE_TYPE = {
  success: "PRICE",
  intervention: "DEALERWAIT",
  retry: "RETRY",
} as const;

export interface RateIntervention {
  responseType: typeof RESPONSE_TYPE.intervention;
}

// not used
export interface RateRetry {
  responseType: typeof RESPONSE_TYPE.retry;
}

export interface RateFailure {
  error: Failure;
}

export interface RateSuccess {
  responseType: typeof RESPONSE_TYPE.success;
  decisionTime: number;
  token: string;
}

export type RateStreamable = RateSuccess | RateIntervention;

export type RateResponse<TSuccess extends RateSuccess = RateSuccess, TError extends RateFailure = RateFailure> =
  | TSuccess
  | TError
  | RateIntervention
  | RateRetry;

export const isRateFailure = (rate: RateResponse): rate is RateFailure => "error" in rate;

export const isRateSuccess = <T extends RateSuccess = RateSuccess>(rate: RateResponse): rate is T =>
  (rate as RateSuccess).responseType === RESPONSE_TYPE.success;

export const isRateStreamable = (res: RateResponse): res is RateStreamable => {
  const { responseType } = res as RateStreamable;
  return (
    !!responseType && [RESPONSE_TYPE.success, RESPONSE_TYPE.intervention].includes(responseType)
  );
};
