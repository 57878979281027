<div class="pko-header d-none d-md-flex">
  <h1 class="pko-title">{{ "pages.Order" | translate }}</h1>
</div>

<div pko-preloader *ngIf="isBusy$ | async"></div>
<form *ngIf="formData$ | async as data" class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-form-order-controls [formData]="data"></app-form-order-controls>

  <app-input-check
    class="col-12 col-sm-4 mt-sm-4"
    formControlName="oco"
    label="order.OCO"
    tooltip="order.ocoTooltip"
    ngDefaultControl>
  </app-input-check>

  <app-form-order-controls *ngIf="oco$ | async" [formData]="data" oco="other">
  </app-form-order-controls>

  <app-form-footer type="order"></app-form-footer>
</form>

<pre *ifIsDevMode>{{ form.value | json }}</pre>
