import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { ApplicationActionsService } from "@features/activation/application-actions.service";
import { Modal, defaultPrimary } from "@shared/components/modal/modal-model";
import { SurveyResult } from "../../mifid.model";

@Component({
  selector: "app-mifid-application-survey-result",
  templateUrl: "./mifid-application-survey-result.component.html",
})
export class MifidApplicationSurveyResultComponent implements OnInit {
  @HostBinding("class") class = "h-100";
  @Input() data!: SurveyResult;
  @Input() applicationId!: string;
  @Input() otherAttorneyResult = false;

  modalConfig!: Modal;

  constructor(private actionsService: ApplicationActionsService) {}

  ngOnInit() {
    if (!this.data?.success) {
      this.modalConfig = {
        title: "TechnicalError",
        buttons: { primary: defaultPrimary },
      };
      return;
    }

    this.modalConfig = {
      buttons: !this.otherAttorneyResult
        ? {
            primary: {
              text: "buttons.Close",
              onClick: () =>
                this.actionsService.startSigningDialog(true, false, this.applicationId, this.data),
            },
          }
        : {
            secondary: {
              text: "mifid.mifidAction.RejectColleagueDecision",
              onClick: () => this.actionsService.rejectColleagueDecision(this.applicationId),
            },
            primary: {
              text: "mifid.mifidAction.AcceptColleagueDecision",
              onClick: () => {
                this.actionsService.acceptColleagueDecision(this.applicationId);
              },
            },
          },
      scrollable: true,
    };
  }
}
