import { Component, HostBinding, HostListener } from "@angular/core";
import { Tracker } from "@core/user-tracking/tracker.service";
import { origins } from "@core/user-tracking/tracking.model";
import { ModalService } from "@features/modal/modal.service";
import { TilesSettingsComponent } from "../tiles-settings/tiles-settings.component";

@Component({
  selector: "app-pro-settings-banner",
  template: `
    <div class="pko-settings-banner__container">
      <h3 class="pko-settings-banner__title">{{ "banner.ProSettingsTitle" | translate }}</h3>
      <div class="d-flex">
        <div class="pko-settings-banner__icon">
          <svg icon name="settings"></svg>
        </div>
        <p class="pko-settings-banner__subtitle">{{ "banner.ProSettingsText" | translate }}</p>
      </div>
    </div>
  `,
})
export class ProSettingsBannerComponent {
  @HostBinding("class") class = "pko-banner d-none d-md-flex";
  @HostListener("click") onClick() {
    this.#configureShortcuts();
  }

  constructor(private modalService: ModalService, private tracker: Tracker) {}

  #configureShortcuts() {
    this.tracker.follow({ process: "set-tiles", origin: origins.BANNER });
    this.modalService.modal({ component: TilesSettingsComponent });
  }
}
