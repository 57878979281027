import {
  InvestmentDepositConfig,
  OfferAmount,
  OfferCurrencyPair,
  TenorDates,
} from "@features/investment-deposit/investment-deposit.model";

export function getOfferByCurrencyAndAmount(
  config: InvestmentDepositConfig,
  currency: string,
  amount: number
) {
  const offerConfig = config.offer.currencies
    ?.find(({ code }) => currency === code)
    ?.amounts?.filter((offerAmount) => offerAmount.amount >= amount)
    ?.sort((a, b) => a.amount - b.amount)[0];

  return offerConfig;
}

export function getOfferTenorsDatesByCurrencyPair(
  config: InvestmentDepositConfig,
  offerConfig: OfferAmount,
  currencyPair: string
) {
  const offerTenorConfig = offerConfig.currencyPairs
    ?.find(({ code }) => code === currencyPair)
    ?.tenors?.map((tenor) => config.tenors.find((x) => x.name === tenor.name));
  const nonNullable = (offerTenorConfig || []).filter((tenor) => !!tenor) as TenorDates[];
  return sortTenors(nonNullable);
}

export function sortTenors(tenors: TenorDates[]) {
  return tenors?.sort((a, b) => new Date(a.from).getTime() - new Date(b.from).getTime());
}

export function mapOfferTenorsToGlobalTenors(
  offerPairConfig: OfferCurrencyPair,
  config: InvestmentDepositConfig
) {
  return offerPairConfig?.tenors
    ?.map((tenor) => ({ tenor, dates: config.tenors.find((x) => x.name === tenor.name)! }))
    ?.sort((a, b) => new Date(a.dates.from).getTime() - new Date(b.dates.from).getTime());
}

export function amountMapper(amount: string) {
  return !!amount || amount == "0" ? +amount.toString().replace(",", ".") : null;
}
