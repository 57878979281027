import { Directive, HostBinding, Input, OnInit } from "@angular/core";
import { CellAlign } from "./../components/table/table-model";

@Directive({
  selector: "[pkoTableHeaderCell]",
})
export class TableHeaderCellDirective implements OnInit {
  @Input() align?: CellAlign;
  @Input() actionMenu?: boolean;
  @HostBinding("class") hostClasses = "pko-table__header-col";
  @HostBinding("scope") scope = "col";

  ngOnInit() {
    this.hostClasses = [
      this.hostClasses,
      this.align && "text-" + this.align,
      this.actionMenu && "pko-table__header-col--menu",
    ]
      .filter(Boolean)
      .join(" ");
  }
}
