<p class="text-center">{{ "mifid.Question" | translate : { total: total, index: index + 1 } }}</p>
<h3 class="pko-modal__title">{{ question.text }}</h3>
<form [formGroup]="form" class="pko-form">
  <ng-container *ngFor="let answer of answers.controls; let i = index" formArrayName="answers">
    <app-input-check
      [formControlName]="i"
      [label]="question.answers[i].text"
      [translateLabel]="false"
      ngDefaultControl>
    </app-input-check>
  </ng-container>

  <ng-container *ngIf="hasError && answers?.errors as errors">
    <app-form-error *ngFor="let error of errors | keyvalue" [error]="error"></app-form-error>
  </ng-container>
</form>

<div class="pko-modal__footer">
  <button class="btn btn-secondary mb-3 mb-sm-0" (click)="back()">
    {{ "buttons.back" | translate }}
  </button>

  <button class="btn btn-primary ms-sm-auto" (click)="next()">
    {{ "buttons.Next" | translate }}
  </button>
</div>
