import { formatNumber } from "@angular/common";
import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  Self,
} from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";
import { length } from "@utils/number";

const SPECIAL_KEYS = [
  "Control",
  "Backspace",
  "Tab",
  "End",
  "Home",
  "ArrowLeft",
  "ArrowRight",
  "Delete",
];

/**
 * Ensures input of only numbers and decimals. Formats the value.
 * @throws NG0201 (No provider for NgControl found in NodeInjector) if used without a FormControl.
 */
@Directive({
  selector: "[numeric]",
})
export class NumericDirective implements AfterViewChecked {
  private fractionDigits = 0;
  private _maxLength = 10;
  private _allowZero = false;
  private regex = new RegExp(`^[${this._allowZero ? 0 : 1}-9][0-9]{0,${this._maxLength - 1}}$`);

  @Input("paste") allowPaste = false;

  @Input("allowZero")
  set allowZero(value: boolean) {
    this._allowZero = value;
    this.maxLength = this._maxLength;
  }

  @Input("maxLength")
  set maxLength(value: number) {
    if (value < 1) return;
    this._maxLength = value;
    if (this.fractionDigits > 0) {
      this.decimalPoints = this.fractionDigits;
    } else {
      this.regex = new RegExp(`^[${this._allowZero ? 0 : 1}-9][0-9]{0,${this._maxLength - 1}}$`);
    }
  }

  @Input("decimals")
  set decimalPoints(value: number) {
    if (value > 0) {
      this.fractionDigits = value;
      this.regex = new RegExp(
        `^(?:0|[1-9][0-9]{0,${this._maxLength - 1}})(?:[\\.,][0-9]{0,${value}})?$`
      );
    } else {
      this.fractionDigits = 0;
      this.regex = new RegExp(`^[${this._allowZero ? 0 : 1}-9][0-9]{0,${this._maxLength - 1}$`);
      this.#removeDecimals();
    }
  }

  constructor(
    @Self() private ngControl: NgControl,
    private el: ElementRef<HTMLInputElement>,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  get control(): AbstractControl | null {
    return this.ngControl.control;
  }

  get element(): HTMLInputElement {
    return this.el.nativeElement;
  }

  ngAfterViewChecked(): void {
    if (this.element !== document.activeElement) {
      this.onBlur();
    }
  }

  @HostListener("focus") onFocus(): void {
    this.ngControl.valueAccessor?.writeValue(this.control?.value);
  }

  @HostListener("blur") onBlur(): void {
    const value = this.control?.value;

    if (!shouldFormat(value)) return;

    const parsed = parse(value);
    const normalized = parsed || parsed === 0 ? parsed.toString() : "";

    // update the model
    if (normalized !== value) {
      this.control?.setValue(normalized, { emitEvent: false });
    }
    // update just the DOM separately
    this.ngControl.valueAccessor?.writeValue(this.#format(parsed));
  }

  @HostListener("paste", ["$event"]) onPaste(event: ClipboardEvent): void {
    if (!this.allowPaste) {
      return event.preventDefault();
    }

    this.#runAfter();
  }

  @HostListener("drop", ["$event"]) onDrop(event: DragEvent): void {
    if (!this.allowPaste) {
      return event.preventDefault();
    }

    this.#runAfter();
  }

  @HostListener("keydown", ["$event"]) onKeyDown(event: KeyboardEvent): void {
    if (event.key === "Unidentified") {
      // Android keyboard (all non-digit keys are Unidentified, code 229)
      this.#runAfter();
    } else {
      this.#runBefore(event);
    }
  }

  #runBefore(event: KeyboardEvent) {
    if (this.#shouldIgnore(event)) return;
    if (event.key === "Enter") this.element.blur();

    const { key } = event;
    const { value, selectionStart } = this.element;
    const start = selectionStart ?? 0;
    const next = [value.slice(0, start), key, value.slice(start)].join("");

    if (!this.#validate(next)) {
      event.preventDefault();
    }

    if (isMultiplier(key)) {
      const multiplied = parse(value) * multipliers[key];
      if (length(multiplied) > 10) return;

      this.control?.setValue(String(multiplied));
    }
  }

  #runAfter() {
    const previous: string = this.control?.value;

    setTimeout(() => {
      const current: string = this.#preparePasted();

      if (!this.#validate(current)) {
        /*
         * Some Android devices seem to be retaining input history, so that
         * programmatically updated value is pushed to the front of the element value
         * e.g. 12r -> set back to 12 -> press 3 -> `current` is 1212r3
         * e.g. 12r -> set back to 12 -> press Backspace -> `current` is 1212
         * And I can't say how or when it retains these values, because sometimes it gets cleared.
         *
         * So, the easy solution is to just lose focus, which apparently resets the history...
         */
        this.control?.setValue(previous, { emitEvent: false, onlySelf: true });
        this.element.blur();
        this.element.focus();
      }
    });
  }

  #shouldIgnore(event: KeyboardEvent): boolean {
    return (
      // Allow specified special keys
      SPECIAL_KEYS.includes(event.key) ||
      // Allow copy, paste, cut
      ((event.ctrlKey || event.metaKey) &&
        (["a", "c", "x"].includes(event.key) || (event.key === "v" && this.allowPaste)))
    );
  }

  #validate(value: string): boolean {
    return value === "" || this.regex.test(String(value));
  }

  #format(value: number): string {
    if (!value && value !== 0) return "";

    const { locale, fractionDigits } = this;
    return formatNumber(value, locale, `1.${fractionDigits}-${fractionDigits}`);
  }

  #removeDecimals() {
    const value = this.control?.value;

    if (!shouldFormat(value)) return;

    const parsed = parse(value);
    const normalized = parsed || parsed === 0 ? parsed.toFixed(0) : "";
    if (normalized !== value) {
      this.control?.setValue(normalized, { emitEvent: false });
    }
    this.ngControl.valueAccessor?.writeValue(this.#format(parsed));
  }

  #preparePasted() {
    this.control?.setValue(
      this.control?.value?.replace(/\s/g, "").replace(/[.,](?=(.*[.,]|$))/g, ""),
      {
        emitEvent: false,
        onlySelf: true,
      }
    );
    return this.control?.value;
  }
}

const shouldFormat = (value: number): boolean => !!value || value === 0;

const parse = (value: string | number): number =>
  Number.parseFloat(value.toString().replace(",", "."));

const multipliers = {
  k: 1000,
  m: 1000000,
} as const;

const isMultiplier = (key: string): key is keyof typeof multipliers => key in multipliers;
