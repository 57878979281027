import { NgModule } from "@angular/core";
import { TransactionModule } from "@features/transaction/transaction.module";
import { SharedModule } from "@shared/shared.module";
import { HighchartsChartModule } from "highcharts-angular";
import { GraphModalComponent } from "./components/graph-modal.component";
import { GraphComponent } from "./components/graph.component";
import { MobileGraphComponent } from "./components/mobile-graph.component";

@NgModule({
  imports: [SharedModule, TransactionModule, HighchartsChartModule],
  declarations: [GraphComponent, GraphModalComponent, MobileGraphComponent],
  exports: [GraphComponent, HighchartsChartModule, GraphModalComponent, MobileGraphComponent],
})
export class GraphModule {}
