export type Units = "days" | "hours" | "minutes" | "seconds" | "milliseconds";

/**
 * Returns a new date that adds the specified number of days to the value of the supplied date.
 * @param date A source date
 * @param days A number of whole and fractional days. The value parameter can be negative or positive.
 */
export function addDays(date: Date, days: number) {
  const result = new Date(date.valueOf());
  result.setDate(result.getDate() + days);
  return result;
}
/**
 * Returns a new date that adds the specified number of time to the value of the supplied date.
 * @param date A source date
 * @param amount A whole or fractional number signifying the amount of time. The value parameter can be negative or positive.
 * @param units A unit of time to add.
 */
export function add(date: Date, amount: number, units: Units = "milliseconds") {
  return new Date(date.valueOf() + amount * divider[units]);
}

/**
 * Turns a date object into an [ISO 8601](https://www.iso.org/iso-8601-date-and-time-format.html)
 * string compatible with `input[type="date"]`.
 * @param date a date to format
 * @returns date in `yyyy-MM-dd` format
 * @warning
 * `Date` is converted to ISO string (UTC), which may result in local timezone inconsistency.
 * Use `shiftToUTC` first if timezone is a factor.
 */
export function datepickerFormat(date: Date | string) {
  const dateString = typeof date === "string" ? date : date.toISOString();
  return dateString.slice(0, 10);
}

/**
 * Turns a date object into an string compatible with `input[type="time"]`.
 * @param date a date to format
 * @returns date in hh:mm format
 */
export function timepickerFormat(date: Date | string) {
  const dateDate = typeof date === "string" ? new Date(date) : date;
  return dateDate.toTimeString().slice(0, 5);
}

/**
 * Verifies if given dates are the same day.
 * @returns true, if the given dates are the same day.
 */
export function isSameDay(dateOne: Date, dateTwo: Date) {
  return (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() === dateTwo.getDate()
  );
}

/**
 * Verifies if given date (or today) is a weekend.
 */
export function isWeekend(date = new Date()) {
  return date.getDay() % 6 === 0;
}

/**
 * Counts the difference between two dates.
 * @param units unit of measurement.
 * @returns Difference between the two dates in the supplied unit of measurement.
 */
export function difference(dateOne: Date, dateTwo: Date, units: Units) {
  const diff = dateOne.valueOf() - dateTwo.valueOf();
  return diff / divider[units];
}

/**
 * If the given date is on weekend, returns the closest Monday. Otherwise returns the same date.
 * @param date a source date
 */
export function firstWorkingDay(date: Date) {
  const dayOfWeek = date.getDay();
  const dayOffset = [1, 0, 0, 0, 0, 0, 2];

  return addDays(date, dayOffset[dayOfWeek]);
}

/**
 * Returns a date object set to midnight local time. This means that UTC time might be a different day.
 * @param date a date to remove the time part.
 */
export function dateOnly(date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Shifts a given date, so that current local time becomes current UTC time. The local time will shift.
 * @param date a date to convert to UTC
 * @example
 * const today = dateOnly(new Date("2022-09-15T13:00")); // Thu Sep 15 2022 00:00:00 GMT+0200
 * const todayShifted = shiftToUTC(today);               // Thu Sep 15 2022 02:00:00 GMT+0200
 * console.log(datepickerFormat(today));                 // 2022-09-14 - incorrect
 * console.log(datepickerFormat(todayShifted));          // 2022-09-15
 */
export function shiftToUTC(date: Date): Date {
  const result = new Date(date.valueOf());
  result.setHours(result.getHours() - result.getTimezoneOffset() / 60);
  return result;
}

export function getMin(...values: (string | Date)[]) {
  const min = Math.min(...values.map((x) => new Date(x).getTime()));
  return min ? new Date(min) : undefined;
}

export function getMax(...values: (string | Date)[]) {
  const min = Math.max(...values.map((x) => new Date(x).getTime()));
  return min ? new Date(min) : undefined;
}

const divider: Record<Units, number> = {
  days: 1000 * 60 * 60 * 24,
  hours: 1000 * 60 * 60,
  minutes: 1000 * 60,
  seconds: 1000,
  milliseconds: 1,
};
