import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { SelectAccountComponent, SummaryAccountsComponent } from "./components";
import { SelectAccountsComponent } from "./components/select-accounts/select-accounts.component";

@NgModule({
  imports: [SharedModule],
  declarations: [SelectAccountComponent, SelectAccountsComponent, SummaryAccountsComponent],
  exports: [SelectAccountComponent, SelectAccountsComponent, SummaryAccountsComponent],
})
export class AccountsModule {}
