<dl class="pko-definition__list">
  <app-definition label="deposit.Amount">
    <app-flag
      [icon]="detail.currency.code"
      [label]="detail.amount | balance : detail.currency.code"></app-flag>
  </app-definition>

  <app-definition label="investment-deposit.ReferenceRateRange">
    {{ detail.rates.rate[0] | rate }}-{{ detail.rates.rate[1] | rate }}
    {{ detail.currencyPair | pair }}
  </app-definition>

  <app-definition label="StartDate" [value]="detail.start.date | datePko"></app-definition>

  <app-definition
    label="investment-deposit.EndDate"
    [value]="detail.end.date | datePko"></app-definition>

  <app-definition label="deposit.DurationDays" [value]="duration"></app-definition>

  <app-definition label="investment-deposit.ReferenceRate">
    {{
      "investment-deposit.ReferenceRateValue"
        | translate
          : {
              code: detail.referenceRate,
              date: (detail.referenceRateDate | datePko) ?? "---",
              currencyPair: detail.currencyPair | pair
            }
    }}
  </app-definition>

  <app-definition label="accounts.From" [account]="detail.account"></app-definition>
</dl>

<app-interest-rate-info [detail]="detail" class="d-block d-md-none"></app-interest-rate-info>
