import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({ selector: "[app-drag-n-drop]" })
export class DragAndDropDirective {
  @Output() dropped = new EventEmitter<Event>();
  @HostBinding("class.dragover") dragover = false;

  @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragover = true;
  }

  @HostListener("dragleave")
  onDragLeave() {
    this.dragover = false;
  }

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.dragover = false;
    this.dropped.emit(event);
  }
}
