import { Component, HostBinding, Input, Self } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { ControlSize } from "@core/models/control";
import { IFormControl } from "@shared/components/controls/model";

@Component({
  selector: "app-input-password",
  templateUrl: "./input-password.component.html",
})
export class InputPasswordComponent implements IFormControl {
  @HostBinding("class") class = "d-block";
  @Input("ngId") id?: string;
  @Input() className?: string;
  @Input() placeholder?: string;
  @Input() size?: ControlSize;

  constructor(@Self() private ngControl: NgControl) {}

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }
}
