import { Component, Input, OnInit } from "@angular/core";
import { Modal } from "@shared/components/modal/modal-model";
import { SurveyResult } from "../../mifid.model";
import { MifidService } from "../../mifid.service";

@Component({
  selector: "app-mifid-survey-result",
  templateUrl: "./mifid-survey-result.component.html",
})
export class MifidSurveyResultComponent implements OnInit {
  @Input() data!: SurveyResult;

  get modalConfig(): Modal {
    return {
      title: this.data?.success ? "" : "errors.TechnicalError",
      buttons: {
        primary: { text: "buttons.Close" },
      },
    };
  }

  constructor(private mifidService: MifidService) {}

  ngOnInit(): void {
    if (this.data?.success) {
      this.mifidService.setKidStatus("Accepted").subscribe();
    }
  }
}
