import { NgModule } from "@angular/core";
import { AlertModule } from "@features/alert/alert.module";
import { DepositModule } from "@features/deposit/deposit.module";
import { DpwModule } from "@features/dpw/dpw.module";
import { ExchangeModule } from "@features/exchange/exchange.module";
import { IndicativesModule } from "@features/indicatives/indicatives.module";
import { InvestmentDepositModule } from "@features/investment-deposit/investment-deposit.module";
import { MarketNewsModule } from "@features/market-news/market-news.module";
import { MessagesModule } from "@features/messages/messages.module";
import { NotificationsModule } from "@features/notifications/notifications.module";
import { RecentHistoryModule } from "@features/recent-history/recent-history.module";
import { TransactionModule } from "@features/transaction/transaction.module";
import { OrderModule } from "../order/order.module";
import { ProBannerComponent } from "./components/pro-banner/pro-banner.component";
import { ProSettingsBannerComponent } from "./components/pro-settings-banner/pro-settings-banner.component";
import { ShortcutItemComponent } from "./components/shortcut-item/shortcut-item.component";
import { ShortcutsComponent } from "./components/shortcut-list/shortcuts.component";
import { ShortcutSettingsItemComponent } from "./components/shortcut-settings-item/shortcut-settings-item.component";
import { ShortcutSettingsComponent } from "./components/shortcut-settings/shortcut-settings.component";
import { TilesSettingsComponent } from "./components/tiles-settings/tiles-settings.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { DashboardProComponent } from "./views/dashboard-pro.component";
import { DashboardSimpleComponent } from "./views/dashboard-simple.component";
import { SectionTemplateDirective } from "./views/section-template.directive";

@NgModule({
  imports: [
    TransactionModule,
    NotificationsModule,
    IndicativesModule,
    RecentHistoryModule,
    DashboardRoutingModule,
    ExchangeModule,
    DpwModule,
    AlertModule,
    DepositModule,
    InvestmentDepositModule,
    OrderModule,
    MarketNewsModule,
    MessagesModule,
  ],
  declarations: [
    DashboardComponent,
    ShortcutsComponent,
    ProBannerComponent,
    ProSettingsBannerComponent,
    ShortcutItemComponent,
    ShortcutSettingsComponent,
    ShortcutSettingsItemComponent,
    TilesSettingsComponent,
    DashboardSimpleComponent,
    DashboardProComponent,
    SectionTemplateDirective,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
