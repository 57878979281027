import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { of, lastValueFrom } from "rxjs";

import { Config, defaultConfig } from "./models/config.model";

@Injectable({ providedIn: "root" })
export class ConfigStore {
  private _config: Config = defaultConfig;

  constructor(private httpClient: HttpClient) {}

  get config(): Config {
    return this._config;
  }

  async load(): Promise<void> {
    const config$ = this.httpClient
      .get<Config>("/browser/config")
      .pipe(catchError(() => of(defaultConfig)));

    this._config = await lastValueFrom(config$);
  }
}
