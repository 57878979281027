import { Directive, HostListener, Self } from "@angular/core";
import { NgControl } from "@angular/forms";
@Directive({
  selector: "[clearErrors]",
})
export class ClearErrorsDirective {
  constructor(@Self() private ngControl: NgControl) {}

  @HostListener("focus")
  onFocurs(): void {
    this.ngControl.control?.setErrors(null);
  }
}
