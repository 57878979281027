<h3 class="pko-form__title">{{ "sides." + side | translate }}</h3>

<dpw-form-amount-currency-pair
  class="col-10 col-sm-8 col-md-6 col-lg-5"></dpw-form-amount-currency-pair>
<div class="w-100"></div>

<app-dpw-form-tenor-date-product show="date" class="col-10 col-sm-4 col-md-3 col-lg-2">
</app-dpw-form-tenor-date-product>

<app-form-pvp class="col-10"></app-form-pvp>

<app-dpw-select-account class="col-10 col-sm-8 col-md-6 col-lg-4"></app-dpw-select-account>

<div class="w-100"></div>
<app-form-collateral formGroupName="collateral" class="col-10 col-sm-7 col-md-5 col-lg-4">
</app-form-collateral>
