<app-grouped-list
  [groups]="[{ key: '', value: quote.series }]"
  class="d-block d-sm-none"
  [flatList]="true">
  <ng-template #itemTemplate let-item let-i="index">
    <div
      class="pko-group__item"
      [multiFxLegSummary]="item"
      [currency]="quote.currency"
      [counterCurrency]="quote.counterCurrency"
      [stack]="true"></div>
  </ng-template>
</app-grouped-list>

<table [columns]="columns" [items]="quote.series" class="d-none d-sm-table" pkoTable>
  <ng-template #itemTemplate let-item>
    <tr
      class="pko-table__row align-middle"
      [multiFxLegSummary]="item"
      [currency]="quote.currency"
      [counterCurrency]="quote.counterCurrency"></tr>
  </ng-template>
</table>
