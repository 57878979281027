import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, FormGroupDirective } from "@angular/forms";
import { Currency, CurrencyService } from "@features/currency";
import { baseCurrency, quoteCurrency } from "@shared/utils/currency";
import {
  Observable,
  Subscription,
  combineLatest,
  combineLatestWith,
  defer,
  distinctUntilChanged,
  map,
  skip,
  startWith,
  tap,
} from "rxjs";

@Component({
  selector: "app-form-multifx-currency-pair",
  templateUrl: "./form-multifx-currency-pair.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormMultifxCurrencyPairComponent implements OnInit, OnDestroy {
  @HostBinding("class") class = "pko-currency-pair pko-currency-pair--multifx";
  #subscription = new Subscription();

  pairs$ = combineLatest([
    this.currencyService.exchangeCurrencies$,
    this.currencyService.currencySortedPairs$,
  ]).pipe(
    map(([currencies, codes]) =>
      codes.filter(
        ({ code }) =>
          currencies.find((currency) => currency.code === baseCurrency(code)) &&
          currencies.find((currency) => currency.code === quoteCurrency(code))
      )
    )
  );

  currencies$!: Observable<Currency[]>;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective, private currencyService: CurrencyService) {}

  ngOnInit() {
    const { currency, counterCurrency, currencyPair } = this.#assertForm();

    const currencyPair$ = defer(() =>
      currencyPair.valueChanges.pipe(distinctUntilChanged(), startWith(currencyPair.value))
    );

    this.currencies$ = currencyPair$.pipe(
      combineLatestWith(this.currencyService.exchangeCurrencies$),
      map(([value, currencies]) =>
        currencies.filter(
          ({ code }) => !value || code === baseCurrency(value) || code === quoteCurrency(value)
        )
      )
    );

    const currency$ = currencyPair$.pipe(
      skip(1),
      tap(ensureControlHasValueAndForceEventPropagation(currency))
    );

    const counterCurrency$ = currency.valueChanges.pipe(
      map((value) =>
        baseCurrency(currencyPair.value) == value
          ? quoteCurrency(currencyPair.value)
          : baseCurrency(currencyPair.value)
      ),
      tap((counterValue) => counterCurrency.setValue(counterValue))
    );

    const setInitialPair$ = this.pairs$.pipe(
      map(
        (pairs) =>
          pairs.find(
            ({ code }) => code.includes(currency.value) && code.includes(counterCurrency.value)
          ) || pairs[0]
      ),
      tap((pair) => currencyPair.setValue(pair.code))
    );

    this.#subscription.add(setInitialPair$.subscribe());
    this.#subscription.add(counterCurrency$.subscribe());
    this.#subscription.add(currency$.subscribe());
  }

  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  #assertForm() {
    const { currency, counterCurrency, currencyPair } = this.#controls;

    if (!currency || !counterCurrency || !currencyPair) {
      throw new Error(
        "This component needs 'currency', 'counterCurrency' and 'currencyPair' controls present on the form!"
      );
    }

    return { currency, counterCurrency, currencyPair };
  }
}

const ensureControlHasValueAndForceEventPropagation =
  (currencyControl: AbstractControl) => (pair: string) => {
    setTimeout(() => currencyControl.setValue(baseCurrency(pair)));
  };
