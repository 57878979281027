export default function (Highcharts: any) {
  Highcharts.theme = {
    chart: {
      backgroundColor: "#1A1A1A",
      style: {
        fontFamily: "pkobp",
      },
      plotBorderColor: "#606063",
      title: {
        margin: 0,
        text: null,
      },
    },
    xAxis: {
      gridLineColor: "#707073",
      labels: {
        style: {
          color: "#E0E0E3",
        },
      },
      lineColor: "#707073",
      minorGridLineColor: "#505053",
      tickColor: "#707073",
      title: {
        style: {
          color: "#A0A0A3",
        },
      },
    },
    yAxis: {
      gridLineColor: "#707073",
      labels: {
        style: {
          color: "#E0E0E3",
        },
      },
      lineColor: "#707073",
      minorGridLineColor: "#505053",
      tickColor: "#707073",
      tickWidth: 1,
      title: {
        style: {
          color: "#A0A0A3",
        },
      },
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      style: {
        color: "#585858",
        cursor: "default",
        fontSize: "13px",
        pointerEvents: "none",
        whiteSpace: "nowrap",
      },
    },
    rangeSelector: {
      buttonTheme: {
        height: 12,
        width: "auto",
        fill: "#636363",
        stroke: "#004c9a",
        "stroke-width": 1,
        padding: 6,
        r: 4,
        style: {
          color: "#006EF5",
          borderWidth: 1,
          fontSize: "0.75rem",
          lineHeight: 1,
        },
        states: {
          hover: {
            fill: "#FFFFFF",
            style: {
              color: "#585858",
            },
          },
          select: {
            fill: "#006EF5",
            style: {
              color: "#000000",
            },
          },
        },
      },
    },
  };

  // Apply the theme
  Highcharts.setOptions(Highcharts.theme);
}
