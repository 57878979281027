import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { IFormService } from "@features/transaction/models";
import { PvpService } from "@features/transaction/services/pvp.service";
import { BehaviorSubject } from "rxjs";
import { MultiFxForm } from "../models/multifx-form";
import { MultiFxService } from "./multifx.service";

@Injectable({ providedIn: "root" })
export class MultiFxFormService implements IFormService<MultiFxForm> {
  private _form = new BehaviorSubject(this.defaultForm);

  submission$ = this.multiFxService.submission$;

  constructor(
    private preferences: PreferencesService,
    private multiFxService: MultiFxService,
    private pvpService: PvpService
  ) {}

  get current() {
    return this._form.getValue();
  }

  private get defaultForm() {
    const { currency, counterCurrency } = this.preferences.exchange;

    return {
      currency: currency,
      counterCurrency: counterCurrency,
      shortCollateral: {},
      forwardCollateral: {},
      isDpw: false,
      parForward: false,
    } as MultiFxForm;
  }

  reset(retain?: "all") {
    if (retain === "all") return;

    this._form.next(this.defaultForm);
  }

  save(form: MultiFxForm) {
    this._form.next(form);
  }

  submit() {
    this.multiFxService.init(this.current, this.pvpService.rule);
  }
}
