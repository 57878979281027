<app-transaction-decision type="exchange" [loading$]="loading$" [vm$]="vm$" [modal]="false">
  <ng-template #summary let-summary>
    <div class="pko-grid__row">
      <app-summary-quote [quote]="summary" class="col-10 col-md-4 order-md-last">
      </app-summary-quote>
      <dl class="pko-definition__list col-10 col-md-6">
        <app-definition label="dpw.Number" [value]="summary.ssrId"> </app-definition>

        <app-definition
          label="dpw.Type"
          [value]="'dpw.type.' + (summary.side === 'Sell' ? 'IN' : 'OUT') | translate">
        </app-definition>
      </dl>
    </div>
  </ng-template>
</app-transaction-decision>
