import { Component, Input, OnInit } from "@angular/core";
import { Tracker } from "@core/user-tracking/tracker.service";
import { AuthComponentData } from "@features/auth/auth.model";
import { AuthService } from "@features/auth/auth.service";
import { AuthComponent } from "@features/auth/components/auth.component";
import { ModalService } from "@features/modal/modal.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";
import { Observable, catchError, map, of, tap } from "rxjs";
import {
  FinishSurveyRequest,
  Question,
  QuestionAnswer,
  Survey,
  SurveyResult,
} from "../../mifid.model";
import { MifidService } from "../../mifid.service";
import { toQuestionAnswer } from "../../mifid.utils";
import { MifidApplicationSurveyResultComponent } from "../mifid-application-survey-result/mifid-application-survey-result.component";
import { MifidSurveyResultComponent } from "../mifid-survey-result/mifid-survey-result.component";

@Component({
  selector: "app-mifid-survey",
  templateUrl: "./mifid-survey.component.html",
})
export class MifidSurveyComponent implements OnInit {
  @Input() data$!: Observable<Survey>;
  @Input() applicationId = 0;
  modalConfig: Modal = {};
  survey$!: Observable<SurveyVm>;

  index = 0;
  total = 0;
  showInfo = true;
  #finishRequest!: FinishSurveyRequest;

  constructor(
    private mifidService: MifidService,
    private authService: AuthService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal,
    private tracker: Tracker
  ) {}

  ngOnInit(): void {
    this.survey$ = this.data$.pipe(
      map(toSurveyVm),
      tap((x) => (this.total = x.questions.length)),
      tap((x) => (this.#finishRequest = toFinishRequest(x, this.applicationId))),
      catchError(() => of({ isError: true, questions: [], id: "" } as SurveyVm))
    );

    this.tracker.report({
      category: "Digitalization",
      action: "MifidSurveyStarted",
      data: { ApplicationId: this.applicationId?.toString() ?? "" },
    });
  }

  onNextClicked(answer: QuestionAnswer) {
    this.#finishRequest.questionAnswers[this.index] = answer;
    if (this.index + 1 < this.total) {
      this.index++;
    } else {
      this.close();
      if (!this.applicationId) {
        this.modalService.modal({
          component: AuthComponent,
          data: {
            modalConfig: { title: "mifid.Questionnaire" },
            prepare$: this.authService.prepareMifidAuthorization(this.#finishRequest.surveyId),
            onResult: (result: SurveyResult) =>
              this.modalService.modal({ component: MifidSurveyResultComponent, data: result }),
            authorize: (code: string) => this.#authorize(code),
            mobileAuthorize: () => this.mifidService.finishMobileSurvey(this.#finishRequest),
          } as AuthComponentData,
        });
        return;
      }

      this.mifidService
        .finishSurvey(this.#finishRequest)
        .pipe(
          tap((result) => {
            const modal = this.modalService.modal({
              component: MifidApplicationSurveyResultComponent,
              data: result.result,
              scrollable: true,
            });
            modal.componentInstance.applicationId = this.applicationId;
          })
        )
        .subscribe();
    }
  }

  onBackClicked() {
    if (this.index > 0) {
      this.index--;
    } else {
      this.showInfo = true;
    }
  }

  showSurvey() {
    this.showInfo = false;
  }

  close() {
    this.activeModal.close();
  }

  #authorize(code: string) {
    this.#finishRequest.code = code;
    return this.mifidService.finishSurvey(this.#finishRequest);
  }
}

interface SurveyVm {
  isError: boolean;
  id: string;
  questions: Question[];
}

const toSurveyVm = (survey: Survey): SurveyVm => {
  return {
    isError: false,
    id: survey.id,
    questions: survey.profilingQuestions.concat(survey.knowledgeQuestions),
  };
};

const toFinishRequest = (survey: SurveyVm, applicationId: number): FinishSurveyRequest => {
  return {
    code: "",
    applicationId,
    surveyId: survey.id,
    questionAnswers: survey.questions.map(toQuestionAnswer),
  };
};
