<app-grouped-list
  *ngIf="analysis$ | async as analysis"
  [groups]="analysis | groupAndSort: 'date'"
  groupLabel=""
  [byDate]="true"
  emptyMessage="missingData.Analysis"
  displayAs="table">
  <ng-template #itemTemplate let-item>
    <app-pdf-document
      [title]="item.title"
      [href]="'/marketnews/analysis/' + item.id"></app-pdf-document>
  </ng-template>
</app-grouped-list>
