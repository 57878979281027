import { Component, HostBinding, Input } from "@angular/core";
import { MenuItem } from "@core/components/nav-menu/nav-menu.model";
import { UserService } from "@core/session";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal-menu",
  templateUrl: "modal-menu.component.html",
})
export class ModalMenuComponent {
  @HostBinding("class") class = "pko-modal";
  @Input() data!: MenuItem;
  readonly customerShortName: string;

  constructor(private activeModal: NgbActiveModal, private userService: UserService) {
    const { customerShortName } = this.userService.userData;
    this.customerShortName = customerShortName;
  }

  close(result?: unknown): void {
    this.activeModal.close(result);
  }

  onClicked(resultOnClose?: unknown): void {
    // if needed, add `wait` prop
    // and then await onClicked("complete") -> close()
    this.activeModal.close(resultOnClose);
  }

  getState(navState?: Record<string, unknown>) {
    return { ...navState, origin: "menu" };
  }
}
