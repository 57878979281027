import { Component, HostBinding } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConfigStore } from "@core/config";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { IndicativePair } from "@features/indicatives/indicatives.model";
import { IndicativesService } from "@features/indicatives/indicatives.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Selectable } from "@shared/components/groups-selector/groups-selector.model";
import { Modal } from "@shared/components/modal/modal-model";
import { tap } from "rxjs";
import { IndicativeSettingsItemComponent } from "./item/indicative-settings-item.component";

@Component({
  selector: "app-indicative-settings",
  templateUrl: "./indicative-settings.component.html",
})
export class IndicativeSettingsComponent {
  @HostBinding("class") class = "h-100";

  #pairs!: IndicativePair[];
  public get pairs(): IndicativePair[] {
    return this.#pairs;
  }
  public set pairs(value: IndicativePair[]) {
    this.selectablePairs = value.map((x) => ({ ...x, key: x.code }));
    this.#pairs = value;
  }
  selectablePairs!: Selectable[];
  form: FormGroup;
  selectedPairsGroup: FormGroup;
  notSelectedPairsGroup: FormGroup;
  itemType = IndicativeSettingsItemComponent;

  modalConfig: Modal = {
    title: "indicatives.EditCurrencyPairs",
    buttons: { primary: { text: "buttons.Save", onClick: () => this.save() } },
  };

  constructor(
    fb: FormBuilder,
    private service: IndicativesService,
    public activeModal: NgbActiveModal,
    public config: ConfigStore,
    private events: AppEventsService
  ) {
    const selectedPairsGroup = new FormGroup({});
    const notSelectedPairsGroup = new FormGroup({});
    this.form = fb.group({
      selectedPairs: selectedPairsGroup,
      notSelectedPairs: notSelectedPairsGroup,
    });

    this.selectedPairsGroup = selectedPairsGroup;
    this.notSelectedPairsGroup = notSelectedPairsGroup;
  }

  save() {
    const selectedPairs = Object.entries(this.selectedPairsGroup.value).map((x) => x[0]);
    this.service
      .setPairs(selectedPairs)
      .pipe(tap(() => this.events.emit(AppEvent.IndicativesChanged)))
      .subscribe();
    this.activeModal.close(selectedPairs);
  }
}
