<p class="fw-bold">
  {{
    "flowDeclaration.RegularHeader"
      | translate
        : {
            start: now | datePko,
            end: declaration.dateTo | datePko
          }
  }}
</p>

<form [formGroup]="form">
  <div [hidden]="form.controls.refuseToAnswer.value">
    <app-grouped-list
      formArrayName="flows"
      [groups]="[{ key: '', value: flows.controls }]"
      class="d-block d-sm-none"
      [flatList]="true">
      <ng-template #itemTemplate let-item let-i="index">
        <div class="pko-group__item">
          <div class="flex-grow-1" [formGroupName]="i">
            <div class="pko-grid__row pko-grid__row--12">
              <app-form-control class="col-6" validate="currency">
                <app-select-flag formControlName="currency" [items]="currencies" ngDefaultControl>
                </app-select-flag>
              </app-form-control>
              <app-form-control class="col-6" validate="side">
                <app-select
                  [items]="sides"
                  bindValue="value"
                  formControlName="side"
                  ngDefaultControl>
                  <ng-template ng-label-tmp ng-option-tmp let-item="item">
                    {{ item.text | translate }}
                  </ng-template>
                </app-select>
              </app-form-control>
            </div>
            <app-select [items]="amounts" formControlName="amount" ngDefaultControl></app-select>
            <app-form-error
              *ngFor="let error of flows.controls[i]?.errors | keyvalue"
              [error]="error">
            </app-form-error>

            <button type="button" class="btn btn-secondary mb-3 mt-3" (click)="remove(i)">
              {{ "buttons.Delete" | translate }}
            </button>
          </div>
        </div>
      </ng-template>
    </app-grouped-list>

    <table
      formArrayName="flows"
      [columns]="columns"
      [items]="flows.controls"
      class="d-none d-sm-table pko-flow__table"
      pkoTable>
      <ng-template #itemTemplate let-item let-i="index">
        <tr class="pko-table__row" [formGroupName]="i">
          <td class="bg-light position-relative" pkoTableCell>
            <app-form-control validate="currency">
              <app-select-flag formControlName="currency" [items]="currencies" ngDefaultControl>
              </app-select-flag>
            </app-form-control>
          </td>
          <td class="bg-light position-relative" pkoTableCell>
            <app-select [items]="sides" bindValue="value" formControlName="side" ngDefaultControl>
              <ng-template ng-label-tmp ng-option-tmp let-item="item">
                {{ item.text | translate }}
              </ng-template>
            </app-select>
          </td>
          <td class="bg-light position-relative" pkoTableCell>
            <app-select [items]="amounts" formControlName="amount" ngDefaultControl> </app-select>
          </td>
          <td class="bg-light" [actionMenu]="true" pkoTableCell>
            <app-action-menu [menu]="menu(i)"></app-action-menu>
          </td>
        </tr>
        <tr *ngIf="flows.controls[i]?.errors">
          <td class="bg-light position-relative" [attr.colspan]="+columns.length">
            <app-form-error
              *ngFor="let error of flows.controls[i]?.errors | keyvalue"
              [error]="error"></app-form-error>
          </td>
        </tr>
      </ng-template>
    </table>
  </div>

  <button
    class="btn btn-link btn-add btn-add--invert"
    type="button"
    (click)="addNewFlow()"
    *ngIf="!allowRefuse || !form.controls.refuseToAnswer.value">
    {{ "buttons.flowDeclaration.Add" | translate }}
    <svg icon name="plus-0" class="icon icon--2xl border rounded-circle ms-2"></svg>
  </button>

  <app-input-check
    *ngIf="allowRefuse"
    formControlName="refuseToAnswer"
    label="buttons.flowDeclaration.RefuseToAnswer"
    ngDefaultControl>
  </app-input-check>
</form>

<div class="pko-flow__container-footer">
  <button type="button" class="btn btn-secondary mb-3 mb-sm-0 ms-sm-auto" (click)="cancel()">
    {{ "buttons.Cancel" | translate }}
  </button>

  <button type="submit" class="btn btn-primary" (click)="save()">
    {{ "buttons.Save" | translate }}
  </button>
</div>
