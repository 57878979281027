import { Component, Input } from "@angular/core";
import { InvestmentDepositSummary } from "@features/investment-deposit/investment-deposit.model";

@Component({
  selector: "app-summary-quote",
  templateUrl: "summary-quote.component.html",
})
export class InvestmentDepositSummaryQuoteComponent {
  @Input() quote!: InvestmentDepositSummary;

  get currency() {
    return this.quote.currency.code;
  }

  get maximalInterestAmount() {
    return this.quote.maximalInterestAmount || null;
  }

  get standardInterestAmount() {
    return this.quote.standardInterestAmount || null;
  }

  get maximalRate() {
    return this.quote.maximalRate || null;
  }

  get interestRate() {
    return this.quote.rates.interestRate || null;
  }
}
