import { Component } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";
import { NavigationService } from "@core/navigation";
import {
  EndingDeposit,
  UserNotificationComponent,
} from "@features/notifications/notifications.model";

@Component({
  selector: "app-notifications-ending-deposit",
  templateUrl: "./notifications-ending-deposit.component.html",
})
export class NotificationsEndingDepositComponent extends UserNotificationComponent {
  get deposit(): EndingDeposit {
    return this.notification as EndingDeposit;
  }

  constructor(private navigation: NavigationService) {
    super();
  }

  get icon(): string {
    return "deposit";
  }

  get header(): string {
    return "notifications." + this.deposit.name;
  }

  mainAction(): void {
    this.navigation.navigate(["/deposits/history", this.deposit.dealId], {
      replaceUrl: true,
      state: { origin: "notification" },
    });
  }

  get menu(): ActionItem[] {
    return [
      {
        text: "notifications.Details",
        callback: () => this.mainAction(),
      } as ActionItem,
    ];
  }
}
