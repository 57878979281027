import { Component, HostBinding, Input, Self } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { uid } from "@utils/string";
import { IFormControl } from "../model";

@Component({
  selector: "app-input-check",
  templateUrl: "input-check.component.html",
})
export class InputCheckComponent implements IFormControl {
  @HostBinding("class") class = "d-block";

  @Input("ngId") id = `check-${uid()}`;
  @Input() label?: string;
  @Input() tooltip?: string;
  @Input() translateLabel? = true;

  /**
   * Indicates whether this checkbox has additional content, other than a label and description.
   * If true, wraps the component in form-check-container
   */
  @Input() hasContent = false;

  /**
   * Makes 2 line checkbox
   */
  @Input() description?: string | null;

  constructor(@Self() private ngControl: NgControl) {}

  get control() {
    return this.ngControl.control as FormControl;
  }
}
