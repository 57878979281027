import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function maxMonthValidator(maxMonth: number, maxYear: number): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    const year = Number((<string>control.value).substring(0, 4));
    const month = Number((<string>control.value).substring(5, 7));

    if (isNaN(year) || isNaN(month)) return { invalidFormat: true };

    if (year > maxYear || (year === maxYear && month > maxMonth))
      return { max: { max: `${maxYear}-${maxMonth}` } };

    return null;
  };
}

export function minMonthValidator(minMonth: number, minYear: number): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    const year = Number((<string>control.value).substring(0, 4));
    const month = Number((<string>control.value).substring(5, 7));

    if (isNaN(year) || isNaN(month)) return { invalidFormat: true };

    if (year < minYear || (year === minYear && month < minMonth))
      return { min: { min: `${minYear}-${minMonth}` } };

    return null;
  };
}

export function monthValidator(control: AbstractControl): ValidationErrors | null {
  const year = Number((<string>control.value).substring(0, 4));
  const month = Number((<string>control.value).substring(5, 7));

  if (isNaN(year) || isNaN(month) || month < 1 || month > 12) return { invalidFormat: true };

  return null;
}

export function validateUnique(
  forms: FormGroup[],
  isEqual: (a: FormGroup, b: FormGroup) => boolean
) {
  for (let i = 0; i < forms.length - 1; ++i) {
    for (let j = i + 1; j < forms.length; ++j) {
      if (isEqual(forms[i], forms[j])) {
        forms[i].setErrors({ notUnique: true });
        forms[j].setErrors({ notUnique: true });
        return false;
      }
    }
  }

  return true;
}

export function requiredIf(
  predicate: (() => boolean) | ((control: AbstractControl) => boolean)
): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    if (!predicate(control)) return null;
    if (control.value === null || control.value === undefined || control.value === "")
      return { required: true };
    return null;
  };
}

export function validateRequiredCurrencyTenors(currencyFlow: FormGroup) {
  const tenors = currencyFlow.controls["tenors"].value;
  for (const key in tenors) {
    const tenor = tenors[key];
    if (tenor.periodAmount !== "" || tenor.periodCollateralPercent !== "") return true;
  }

  const controls = (<FormGroup>currencyFlow.controls["tenors"]).controls;
  for (const key in controls) {
    const tenor = <FormGroup>controls[key];
    tenor.controls["periodAmount"].updateValueAndValidity();
    tenor.controls["periodAmount"].setErrors({ required: true });
    tenor.controls["periodCollateralPercent"].updateValueAndValidity();
    tenor.controls["periodCollateralPercent"].setErrors({ required: true });
  }
  return false;
}
