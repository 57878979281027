<!-- 
// #########################
// ## MODYFIKACJA
// #########################
-->

<ng-container *ngIf="isBusy$ | async">
  <ng-container *ngTemplateOutlet="loading"></ng-container>
</ng-container>

<ng-container *ngIf="mifidValidation$ | async; else loading">
  <app-alert
    *ngIf="warningService.outOfTargetMarket as code"
    icon="critical"
    content="alert.Content.OutOfTargetMarket"
    context="danger">
  </app-alert>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="pko-grid__row">
      <app-summary-original [original]="original" class="col-10 col-sm-5"></app-summary-original>

      <div class="col-10 col-sm-5">
        <h2>{{ "exchange.roll.Title" | translate }}</h2>

        <app-select
          label="exchange.roll.RateType"
          formControlName="rateType"
          validate="rateType"
          [items]="rateTypes"
          translateText="exchange.roll.rateTypes"
          ngDefaultControl>
        </app-select>

        <app-input-amount-left
          formControlName="amount"
          label="exchange.roll.Amount"
          [currency]="original.currency"
          [max]="original.openBalance"
          ngDefaultControl>
        </app-input-amount-left>

        <app-form-tenor-date-roll [original]="original"></app-form-tenor-date-roll>

        <app-form-collateral formGroupName="collateral"></app-form-collateral>
        <!-- Dodano  [depoData]="original" -->
        <!-- <app-form-collateral [depoData]="original" formGroupName="collateral"></app-form-collateral> -->
      </div>
    </div>

    <p class="pko-infotext" [innerHtml]="'exchange.info.risk.swap' | translate: { link: link }"></p>
    <app-form-footer [back]="{ onClick: back }"></app-form-footer>

    <pre *ifIsDevMode>{{ form.getRawValue() | json }}</pre>
  </form>
</ng-container>

<ng-template #loading>
  <div pko-preloader></div>
</ng-template>
