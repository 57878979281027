import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "leftSlider",
})
export class LeftSliderPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | number[] | null, numberFormat: string, labelSuffix?: string) {
    return (
      this.decimalPipe.transform(
        !value ? 0 : Array.isArray(value) ? value[0] : value,
        numberFormat
      ) + (labelSuffix ?? "")
    );
  }
}

@Pipe({
  name: "rightSlider",
})
export class RightSliderPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  transform(value: number | number[] | null, numberFormat: string, labelSuffix?: string) {
    return (
      this.decimalPipe.transform(
        !value ? 0 : Array.isArray(value) ? value[1] : value,
        numberFormat
      ) + (labelSuffix ?? "")
    );
  }
}
