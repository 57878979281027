import { Component, Input } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";
import { Tracker } from "@core/user-tracking/tracker.service";
import { AlertListItem } from "@features/alert/models/alert-list";
import { AlertHistoryService } from "@features/alert/services/alert-history.service";
import { ItemRole, constructCancellationDialog } from "@features/history-common/models";
import { ModalService } from "@features/modal/modal.service";
import { getCounterCurrency } from "@shared/utils/currency";

@Component({
  selector: "[alert]",
  templateUrl: "./list-alert-item.component.html",
})
export class ListAlertItemComponent {
  #alert!: Item;
  #actionMenu?: ActionItem[];

  @Input() set alert(alert: AlertListItem) {
    this.#alert = {
      ...alert,
      counterCurrency: getCounterCurrency(alert.currencyPair, alert.currency),
    };

    if (alert.status === "Active") {
      this.#actionMenu = this.#createActionMenu(alert);
    }
  }

  get alert(): Item {
    return this.#alert;
  }

  get menu() {
    return this.#actionMenu;
  }

  @Input() role!: ItemRole;

  constructor(
    private modal: ModalService,
    private service: AlertHistoryService,
    private tracker: Tracker
  ) {}

  #createActionMenu(alert: AlertListItem) {
    const prompt = constructCancellationDialog(alert, "Alert");

    const callback = async () => {
      if (await this.modal.dialog(prompt).result) {
        this.tracker.report({ category: "alert", action: "cancel", data: { id: alert.id } });
        this.service.cancel(alert.id);
      }
    };

    return [{ text: "buttons.cancel.Alert", callback }];
  }
}

type Item = AlertListItem & { counterCurrency: string };
