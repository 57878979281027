import { Component, HostBinding, Input } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";

@Component({
  selector: "app-action-menu",
  templateUrl: "action-menu.component.html",
})
export class ActionMenuComponent {
  @HostBinding("class") class = "d-inline-flex";
  @Input() public menu: ActionMenuItem[] = [];

  executeMenuItem(action: ActionMenuItem, event: MouseEvent): void {
    action.callback?.();
    event.stopPropagation();
  }

  trackByKey(_: number, { text }: ActionItem): string {
    return text;
  }
}

export interface ActionMenuItem extends ActionItem {
  hidden?: boolean;
}
