<div
  *ngFor="let option of options"
  class="pko-radio-stack__item"
  [class.pko-radio-stack__item--checked]="control.value === option.value">
  <input
    type="radio"
    class="pko-radio-stack__input"
    [value]="option.value"
    [id]="option.value"
    [formControl]="control" />
  <label class="pko-radio-stack__label d-flex" [for]="option.value">
    <ng-container
      [ngTemplateOutlet]="optionTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{ $implicit: option }">
    </ng-container
  ></label>
  <ng-content></ng-content>
</div>

<ng-template #defaultTemplate let-option>{{ option }}</ng-template>
