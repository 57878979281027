export default function (Highcharts: any) {
  Highcharts.theme = {
    chart: {
      style: {
        fontFamily: "pkobp",
      },
      title: {
        margin: 0,
        text: null,
      },
      spacing: [0, 0, 0, 0],
    },
    tooltip: {
      style: {
        color: "#585858",
        cursor: "default",
        fontSize: "13px",
        pointerEvents: "none",
        whiteSpace: "nowrap",
      },
    },
    rangeSelector: {
      buttonTheme: {
        height: 12,
        width: "auto",
        fill: "#F2F2F2",
        stroke: "none",
        "stroke-width": 0,
        padding: 6,
        r: 4,
        style: {
          color: "#003574",
          fontSize: "0.75rem",
          lineHeight: 1,
        },
        states: {
          hover: {
            fill: "#004c9a",
            style: {
              color: "#FFFFFF",
            },
          },
          select: {
            fill: "#003574",
            style: {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
  };

  // Apply the theme
  Highcharts.setOptions(Highcharts.theme);
}
