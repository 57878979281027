import { Component, Input } from "@angular/core";
import { Failure } from "@core/models/transaction";

@Component({
  selector: "app-form-multifx-transaction-error",
  template: `<small
    class="invalid-feedback d-block"
    [innerHtml]="'errors.' + error.code | translate : error.data">
  </small>`,
})
export class FormMultifxTransactionErrorComponent {
  @Input() error!: Failure;
}
