import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: "[enterConfirm]",
})
export class EnterConfirmDirective {
  @Output() enterPressed = new EventEmitter<void>();

  constructor() {}

  @HostListener("keypress", ["$event"]) onEnter(event: KeyboardEvent): void {
    if (event.key !== "Enter") return;
    this.enterPressed.emit();
  }
}
