<dl class="pko-definition__list">
  <app-summary-accounts [accounts]="detail"></app-summary-accounts>
  <app-definition label="order.Type" [value]="type | translate"></app-definition>
  <app-definition label="ExpirationDate" [value]="expiration | datePko: 'minute'"></app-definition>
  <app-definition
    *ngIf="detail.pendingConversion | isNonNullable"
    label="order.PendingConversion"
    [value]="'yesno.' + detail.pendingConversion | translate">
  </app-definition>
</dl>
