import { Component, Input } from "@angular/core";
import { DepositSummary } from "@features/deposit/models/deposit-summary";

@Component({
  selector: "app-summary-quote",
  templateUrl: "summary-quote.component.html",
})
export class DepositSummaryQuoteComponent {
  @Input() quote!: Pick<
    DepositSummary,
    "currency" | "interestAmount" | "maturityAmount" | "rate" | "end"
  >;

  get currency() {
    return this.quote.currency.code;
  }

  get interestAmount() {
    return this.quote.interestAmount || null;
  }

  get maturityAmount() {
    return this.quote.maturityAmount || null;
  }

  get rate() {
    return this.quote.rate || null;
  }
}
