import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InfoPhones } from "@features/activation/activation.model";
import { catchError, of } from "rxjs";
import { ContractAvailability } from "./application-select.model";

@Injectable({ providedIn: "root" })
export class ApplicationSelectService {
  constructor(private http: HttpClient) {}

  getApplyStatus() {
    return this.http
      .get<ContractAvailability>("/contracts/apply-status")
      .pipe(catchError(() => of({} as ContractAvailability)));
  }

  getInfoPhones() {
    return this.http.get<InfoPhones>("/contracts/phones");
  }
}
