import { KeyValue } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ValidationErrorOverrides } from "../model";

@Component({
  selector: "app-form-error",
  template: `<small class="invalid-feedback d-block">
    {{ "validationErrors." + message.key | translate: { value: message.value } }}
  </small>`,
})
export class FormErrorComponent {
  @Input() error!: KeyValue<string, unknown>;
  @Input() overrides?: ValidationErrorOverrides;

  get message() {
    const { overrides, error } = this;
    const override = overrides?.[error.key];

    if (!override) return error;

    return typeof override === "string" ? { ...error, key: override } : override;
  }
}
