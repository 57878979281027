import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, I18nPluralPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BasketNamePipe } from "@features/deposit/premium/basket-name.pipe";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import {
  ActionMenuComponent,
  AlertComponent,
  BusyIndicatorComponent,
  CountdownComponent,
  CsvDocumentComponent,
  DashboardSectionComponent,
  FlagComponent,
  GroupedListComponent,
  GroupsSelectorComponent,
  IconComponent,
  LabeledTextInlineComponent,
  ModalContainerComponent,
  ModalFooterComponent,
  PaginationComponent,
  PdfDocumentComponent,
  RateDirectionArrowComponent,
  TableComponent,
  TickingRateComponent,
  TooltipComponent,
} from "@shared/components";
import {
  AlphanumericDirective,
  BackButtonDirective,
  IfIsDevModeDirective,
  NumericDirective,
  ScrollingDirective,
} from "@shared/directives";
import { AppCanActivate } from "./app-guard";
import {
  ButtonGroupFormComponent,
  CheckboxComponent,
  DatepickerComponent,
  DefinitionComponent,
  DragAndDropDirective,
  FormControlComponent,
  FormErrorComponent,
  InputCheckComponent,
  InputFileComponent,
  InputNumberComponent,
  InputPasswordComponent,
  InputRadioComponent,
  InputSearchComponent,
  InputTextComponent,
  RadioStackComponent,
  SelectComponent,
  SelectFlagComponent,
  SimpleRadioComponent,
} from "./components/controls";
import { DatepickerFormatDirective } from "./components/controls/datepicker/datepicker-format.directive";
import { ProgressPipe } from "./components/countdown/progress.pipe";
import { MonthPickerComponent } from "./components/month-picker/month-picker.component";
import { SelectedItemComponent } from "./components/selected-item/selected-item.component";
import { ButtonCollapseDirective } from "./directives/button-collapse.directive";
import { ClearErrorsDirective } from "./directives/clear-errors.directive";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { DigitsOnlyDirective } from "./directives/digits-only.directive";
import { EnterConfirmDirective } from "./directives/enter-confirm.directive";
import { IfHasRoleDirective } from "./directives/has-role.directive";
import { TableCellDirective } from "./directives/table-cell.directive";
import { TableHeaderCellDirective } from "./directives/table-header-cell.directive";
import {
  AccountPipe,
  BalancePipe,
  CustomNumberPipe,
  Customi18nPluralPipe,
  DatePkoPipe,
  DatepickerFormatPipe,
  InitialsPipe,
  IsNonNullablePipe,
  NullablePipe,
  PadEndPipe,
  PadStartPipe,
  PhonePipe,
  RatePipe,
} from "./pipes";
import { BreakpointPipe } from "./pipes/breakpoint.pipe";
import { CurrencyPairPipe } from "./pipes/currency-pair.pipe";
import { GroupAndSortPipe } from "./pipes/group-and-sort.pipe";
import { MinorUnitPipe } from "./pipes/minor-unit.pipe";
import { PkoPercentPipe } from "./pipes/pko-percent.pipe";

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    LayoutModule,
  ],
  declarations: [
    AccountPipe,
    ActionMenuComponent,
    AlertComponent,
    AlphanumericDirective,
    BackButtonDirective,
    BalancePipe,
    BasketNamePipe,
    BreakpointPipe,
    BusyIndicatorComponent,
    ButtonCollapseDirective,
    ButtonGroupFormComponent,
    CheckboxComponent,
    ClearErrorsDirective,
    ClickOutsideDirective,
    CountdownComponent,
    CsvDocumentComponent,
    CurrencyPairPipe,
    Customi18nPluralPipe,
    CustomNumberPipe,
    DashboardSectionComponent,
    DatepickerComponent,
    DatepickerFormatDirective,
    DatepickerFormatPipe,
    DatePkoPipe,
    DefinitionComponent,
    DigitsOnlyDirective,
    DragAndDropDirective,
    EnterConfirmDirective,
    FlagComponent,
    FormControlComponent,
    FormErrorComponent,
    GroupAndSortPipe,
    GroupedListComponent,
    GroupsSelectorComponent,
    IconComponent,
    IfHasRoleDirective,
    IfIsDevModeDirective,
    InitialsPipe,
    InputCheckComponent,
    InputFileComponent,
    InputNumberComponent,
    InputPasswordComponent,
    InputRadioComponent,
    InputSearchComponent,
    InputTextComponent,
    IsNonNullablePipe,
    LabeledTextInlineComponent,
    ModalContainerComponent,
    ModalFooterComponent,
    MonthPickerComponent,
    NullablePipe,
    NumericDirective,
    PadStartPipe,
    PadEndPipe,
    PaginationComponent,
    PdfDocumentComponent,
    PhonePipe,
    PkoPercentPipe,
    ProgressPipe,
    RadioStackComponent,
    RateDirectionArrowComponent,
    RatePipe,
    ScrollingDirective,
    SelectComponent,
    SelectedItemComponent,
    SelectFlagComponent,
    MinorUnitPipe,
    SimpleRadioComponent,
    TableCellDirective,
    TableComponent,
    TableHeaderCellDirective,
    TickingRateComponent,
    TooltipComponent,
  ],
  exports: [
    AccountPipe,
    ActionMenuComponent,
    AlertComponent,
    AlphanumericDirective,
    BackButtonDirective,
    BalancePipe,
    BasketNamePipe,
    BreakpointPipe,
    BusyIndicatorComponent,
    ButtonCollapseDirective,
    ButtonGroupFormComponent,
    CheckboxComponent,
    ClearErrorsDirective,
    ClickOutsideDirective,
    CommonModule,
    CountdownComponent,
    CsvDocumentComponent,
    CurrencyPairPipe,
    Customi18nPluralPipe,
    CustomNumberPipe,
    DashboardSectionComponent,
    DatepickerComponent,
    DatepickerFormatPipe,
    DatePkoPipe,
    DefinitionComponent,
    DigitsOnlyDirective,
    DragAndDropDirective,
    FlagComponent,
    FormControlComponent,
    FormErrorComponent,
    GroupAndSortPipe,
    GroupedListComponent,
    GroupsSelectorComponent,
    IconComponent,
    IfHasRoleDirective,
    IfIsDevModeDirective,
    InitialsPipe,
    InputCheckComponent,
    InputFileComponent,
    InputNumberComponent,
    InputPasswordComponent,
    InputRadioComponent,
    InputSearchComponent,
    InputTextComponent,
    IsNonNullablePipe,
    LabeledTextInlineComponent,
    ModalContainerComponent,
    ModalFooterComponent,
    MonthPickerComponent,
    NgbModule,
    NgSelectModule,
    NullablePipe,
    PadStartPipe,
    PadEndPipe,
    PaginationComponent,
    PdfDocumentComponent,
    PhonePipe,
    PkoPercentPipe,
    RadioStackComponent,
    RateDirectionArrowComponent,
    RatePipe,
    ReactiveFormsModule,
    RouterModule,
    ScrollingDirective,
    SelectComponent,
    SelectedItemComponent,
    SelectFlagComponent,
    MinorUnitPipe,
    SimpleRadioComponent,
    TableCellDirective,
    TableComponent,
    TableHeaderCellDirective,
    TickingRateComponent,
    TooltipComponent,
    TranslateModule,
  ],
  providers: [
    AppCanActivate,
    CurrencyPairPipe,
    CurrencyPipe,
    Customi18nPluralPipe,
    DatePipe,
    DecimalPipe,
    I18nPluralPipe,
    PkoPercentPipe,
    TranslatePipe,
  ],
})
export class SharedModule {}

// https://angular.io/guide/sharing-ngmodules
