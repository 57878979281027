import { assertIsDefined } from "@utils/misc";
import { datepickerFormat } from "@utils/time";
import { baseCurrency, quoteCurrency } from "./currency";

/**
 * Formats a number according to the `pl` locale and formatting options.
 * @param value Numeric value to be formatted.
 * @param options Specific options for this operation.
 * @returns Formatted value.
 * @throws when `"currency"` or `"pair"` style is selected without a valid `code`.
 * @description
 * This function is meant as a supplement to `BalancePipe` and Angular's `NumberPipe` when pipe can't be used.
 * It is a lightweight alternative to Angular's `formatNumber` function, which needs locale from DI.
 */
export function formatNumber(value: number, { style, ...props } = defaultOptions) {
  const options = style === "currency" ? currencyOptions(props) : numberOptions(props);
  const formatted = new Intl.NumberFormat("pl", options).format(value);

  if (style === "pair") {
    assertIsDefined(props.code, "missing currency pair code");
    return `${formatted}\xa0${formatCurrencyPair(props.code)}`;
  }

  return formatted;
}

const defaultOptions: NumberFormatOptions = {
  decimals: 2,
  style: "decimal",
};

/**
 * @param value The date expression: a `Date` object
 * or an [ISO 8601](https://www.iso.org/iso-8601-date-and-time-format.html) string.
 * @param separator Custom separator to use.
 * @returns A date string in the "dd.MM.yyyy" format.
 * @description
 * This function is meant as a supplement to `DatePkoPipe` when pipe can't be used.
 * It is a lightweight alternative to Angular's `formatDate` function, which needs locale from DI.
 *
 * Bear in mind that this method does not validate the value. If no value is given, an empty string is returned.
 * For any given string, it is assumed to be in the *ISO 8601* format, and processed accordingly.
 */
export function formatDate(value: Date | string, separator = ".") {
  if (!value) return "";

  const date = datepickerFormat(value);
  const [year, month, day] = date.trim().split("-");

  return day + separator + month + separator + year;
}

/**
 * Formats a currency pair to PKO display standard.
 * @param pair Code of the pair to format.
 * @returns Formatted code.
 *
 * @example
 * const formatted = formatCurrencyPair("EURUSD")
 * console.log(formatted); // EUR/USD
 */
export const formatCurrencyPair = (pair: string) => baseCurrency(pair) + "/" + quoteCurrency(pair);

const numberOptions = ({ decimals }: NumberFormatOptions) => ({
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
});

const currencyOptions = ({ code, decimals }: NumberFormatOptions) => ({
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
  style: "currency",
  currencyDisplay: "code",
  currency: code,
});

export type NumberFormatOptions = {
  code?: string;
  decimals?: number;
  style?: "decimal" | "currency" | "pair";
};
