import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-main-filter",
  templateUrl: "./main-filter.component.html",
})
export class MainFilterComponent implements OnDestroy, OnInit {
  #subscription?: Subscription;

  @Input() startDropdown!: string;
  @Input() dropdownOptions!: string[];
  @Input() label!: string;
  @Input() optionLabel!: string;
  @Input() filtersCount = 0;
  @Input("toggle") showToggle: "show" | "hide" = "show";

  @Output() filtersToggled = new EventEmitter<void>();
  @Output() dropdownChanged = new EventEmitter<string>();

  dropdownFormGroup!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    const dropdown = new FormControl(
      this.startDropdown || this.dropdownOptions[0],
      Validators.required
    );

    this.dropdownFormGroup = this.fb.group({ option: dropdown });

    this.#subscription = dropdown.valueChanges.subscribe((option) =>
      this.dropdownChanged.emit(option)
    );
  }

  ngOnDestroy() {
    this.#subscription?.unsubscribe();
  }

  onFiltersToggled(): void {
    this.filtersToggled.emit();
  }
}
