import { Injectable } from "@angular/core";
import { ConfigStore, LogLevel, LogSource } from "@core/config";
import { environment } from "@env/environment";
import { getCookie } from "@utils/cookie";
import { debounceTime, Subject } from "rxjs";
import { LogEntry } from "./logging.model";

@Injectable({ providedIn: "root" })
export class Logger {
  private _buffer: LogEntry[] = [];
  private _flush = new Subject<void>();

  constructor(private configStore: ConfigStore) {
    this._flush.pipe(debounceTime(4000)).subscribe(() => this.flushBuffer());
  }

  private get config() {
    return this.configStore.config.logging;
  }

  logDebug(source: LogSource, message: string): void {
    this.log(source, message, LogLevel.Debug);
  }

  logInfo(source: LogSource, message: string): void {
    this.log(source, message, LogLevel.Information);
  }

  logWarning(source: LogSource, message: string): void {
    this.log(source, message, LogLevel.Warning);
  }

  logError(source: LogSource, message: string, error?: Error): void {
    const errorMessage = message + (error && " | " + error.message);
    this.log(source, errorMessage, LogLevel.Error);

    if (!environment.production) {
      console.error(error);
    }
  }

  private log(source: LogSource, message: string, level: LogLevel) {
    if (!this.canLog(source, level)) {
      return;
    }

    const entry = new LogEntry();
    entry.level = level;
    entry.source = source;
    entry.message = message;

    if (!environment.production) {
      console.log(entry);
    }

    this._buffer.push(entry);
    this._flush.next();
  }

  private canLog(source: LogSource, level: LogLevel): boolean {
    if (environment.production && source === "test") {
      return false;
    }

    // get the string representation of the enum from config (keyof typeof LogLevel)
    const allowedLevel = this.config[source] ?? this.config.default;
    // compare two enums (as numbers)
    return level >= LogLevel[allowedLevel];
  }

  private flushBuffer() {
    const entries = this._buffer.splice(0);

    if (!entries.length) {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.onerror = (err) => console.error(err);
    xhr.open("POST", "/browser/log", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("x-xsrf-token", getCookie("XSRF-TOKEN"));
    xhr.send(JSON.stringify(entries));

    /*
     * Consider using navigator.sendBeacon instead if the CQRS issue can be resolved
     * (requires IE polyfill)
     * https://bugs.chromium.org/p/chromium/issues/detail?id=490015

     * const headers = { type: "application/json" };
     * const data = new Blob([JSON.stringify(entry)], headers);
     * navigator.sendBeacon("/browser/log", data);
     */
  }
}
