import { Component, HostBinding, inject } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BreakpointObserverService } from "@core/breakpoints";
import { ConfigStore } from "@core/config";
import { AccessChannel, UserService } from "@core/session";
import { Tracker } from "@core/user-tracking/tracker.service";
import { combineLatestWith, filter, map, startWith } from "rxjs";

@Component({
  selector: "[pkoFooter]",
  templateUrl: "./footer.component.html",
})
export class FooterComponent {
  @HostBinding("class") class = "pko-footer";
  readonly safety: string;
  readonly help: string;
  readonly contact: string;

  track(link: string){
    this.tracker.report({category: "navigation", action: "FooterLinkClicked", data: {"Link": link}})
  }

  hide$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map(() => this.#getDeepestRoute(this.activatedRoute)),
    filter((route) => route.outlet === "primary"),
    combineLatestWith(this.breakPointObserver.currentBreakpoint$),
    map(
      ([route, breakpoint]) =>
        breakpoint === "xs" &&
        (route.snapshot.routeConfig?.component?.name.toLowerCase().endsWith("decisioncomponent") ??
          false)
    ),
    startWith(false)
  );

  constructor(
    store: ConfigStore,
    userService: UserService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private breakPointObserver: BreakpointObserverService,
    private tracker: Tracker,
  ) {
    const { safety, help, contact } = store.config.links;
    const channel = userService.userData.channel.toLowerCase() as Lowercase<AccessChannel>;
    this.safety = safety[channel];
    this.help = help[channel];
    this.contact = contact[channel];
  }

  #getDeepestRoute(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
