import { Component } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { IOption } from "@shared/components/controls/radio-stack/radio-stack-model";

@Component({
  selector: "app-dpw-direction",
  templateUrl: "dpw-direction.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DpwDirectionComponent {
  directions: DpwDirection[] = [
    {
      title: "dpw.type.IN",
      value: "Sell",
      subtitle: "dpw.type.description.IN",
      icon: "transfer-in",
    },
    {
      title: "dpw.type.OUT",
      value: "Buy",
      subtitle: "dpw.type.description.OUT",
      icon: "transfer-out",
    },
  ];
}

interface DpwDirection extends IOption {
  icon: string;
  title: string;
  subtitle: string;
}
