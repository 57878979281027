import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Tracker } from "@core/user-tracking/tracker.service";
import { WATCH_STATUS_OPTIONS, WatchStatusOption } from "@features/history-common/models";
import { AlertHistoryService } from "../services/alert-history.service";

@Component({
  selector: "app-page-alerts",
  templateUrl: "alerts.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AlertHistoryService],
})
export class AlertsPageComponent {
  alerts$ = this.service.alerts$;

  statuses = WATCH_STATUS_OPTIONS;

  constructor(private service: AlertHistoryService, private tracker: Tracker) {
    tracker.report({
      category: "history",
      action: "open",
      data: { type: "alert" },
    });
  }

  onStatusChanged(status: WatchStatusOption) {
    this.service.changeStatus(status);

    this.tracker.report({
      category: "history",
      action: "filter",
      data: { type: "alert", status },
    });
  }
}
