import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { RangeFormComponent } from "./form-range.component";

@Component({
  selector: "app-form-range-date",
  templateUrl: "range-date.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFormComponent extends RangeFormComponent<string> {
  @Input() minDate?: string;
  @Input() maxDate?: string;
}
