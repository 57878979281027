<ng-container *ngIf="data$ | async as titlebar">
  <div class="pko-titlebar" *ngIf="titlebar.title || titlebar.close">
    <button
      class="btn pko-titlebar__button pko-titlebar__button--return"
      (click)="onBack()"
      *ngIf="!titlebar.close">
      <div class="pko-titlebar__button-container">
        <svg icon name="chevron-left" class="icon icon--lg"></svg>
      </div>
    </button>

    <button
      class="btn pko-titlebar__button pko-titlebar__button--close"
      (click)="onClose()"
      *ngIf="titlebar.close">
      <div class="pko-titlebar__button-container">
        <svg icon name="close_nav" class="icon icon--lg"></svg>
      </div>
    </button>

    <div class="pko-titlebar__title {{ titlebar.showIcon && 'ms-0 me-auto fw-bold' }}">
      <span *ngIf="titlebar.title">{{ titlebar.title | translate }}</span>
    </div>

    <img
      src="assets/images/pko_logo.svg"
      alt="iPKO Dealer - Logo"
      *ngIf="titlebar.showIcon"
      class="px-3" />
  </div>
</ng-container>
