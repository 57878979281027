import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { handleFormErrors } from "@features/transaction/utils/form";
import { merge } from "rxjs";
import { MultiFxForm } from "../../models/multifx-form";
import { MultiFxTransactionRateFailure } from "../../models/multifx-rate";
import { MultiFxFormService } from "../../services/multifx-form.service";

@Component({
  selector: "app-form-multifx",
  templateUrl: "./form-multifx.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMultiFxComponent {
  @Input() seriesErrors?: MultiFxTransactionRateFailure[];

  isBusy$ = merge(this.formService.submission$.pipe(mapToBusy()), this.busyService.isBusy$);
  form: FormGroup;

  constructor(
    fb: FormBuilder,
    private formService: MultiFxFormService,
    private busyService: BusyService,
    private tracker: Tracker
  ) {
    const { retain } = window.history.state;
    formService.reset(retain);

    this.tracker.follow({ process: "multifx", origin: history.state?.origin ?? "reload" });

    const form = formService.current as MultiFxForm;

    this.form = fb.group({
      currency: form.currency,
      counterCurrency: form.counterCurrency,
      currencyPair: form.currencyPair,
      parForward: form.parForward,
      account: [form.account, Validators.required],
      counterAccount: [form.counterAccount, Validators.required],
      series: fb.array([]),
      shortCollateral: fb.group(form.shortCollateral ?? {}),
      forwardCollateral: fb.group(form.forwardCollateral ?? {}),
    });
  }

  onSubmit() {
    this.formService.save(prepareForm(this.form));

    handleFormErrors(this.form) && this.formService.submit();
  }
}

const prepareForm = (formGroup: FormGroup) => {
  const form: MultiFxForm = formGroup.getRawValue();

  const series = formGroup.controls.series as FormArray;
  series.markAllAsTouched();
  series.controls.forEach((control) => control.updateValueAndValidity());

  if (!form.shortCollateral?.type) {
    delete form.shortCollateral;
  }

  if (!form.forwardCollateral?.type || formGroup.controls.forwardCollateral.disabled) {
    delete form.forwardCollateral;
  }

  return form;
};
