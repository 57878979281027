<div class="anchor" #anchor></div>
<div
  class="pko-header pko-activation__header"
  [scrollingAnchor]="anchor"
  [scrollingBody]="body"
  scrolling>
  <div class="pko-activation__header-container">
    <div class="pko-activation__header-content">
      <a routerLink="/customer" class="btn btn-link pko-activation__header-back">
        <svg icon name="chevron-left" class="icon icon--lg"></svg>
        <span class="d-none d-md-inline">{{ "activation.Return" | translate }}</span>
      </a>

      <h1 class="pko-title pko-title--1">
        {{ "activation.Manage" | translate }}
      </h1>
    </div>
    <img src="assets/images/pko_logo.svg" alt="PKO - Logo" />
  </div>
</div>
<div class="pko-activation__body" #body>
  <app-client-data-simple></app-client-data-simple>
  <hr />
  <app-application-select [fromProfile]="false"></app-application-select>
  <app-applications-and-contracts></app-applications-and-contracts>

  <ng-container *ngIf="regulations$ | async as regulations">
    <div class="pko-activation__section">
      <app-regulations
        title="activation.RelatedRegulations"
        [regulations]="regulations"></app-regulations>
    </div>
  </ng-container>
</div>
