<ng-container *ngIf="pageCount > 1">
  <ng-container *ngIf="page$ | async as page">
    <svg
      icon
      name="double-chevron-left"
      class="icon--lg"
      *ngIf="page.hasPrevious"
      role="button"
      (click)="first()"></svg>
    <svg
      icon
      name="chevron-left"
      class="icon--lg"
      *ngIf="page.hasPrevious"
      role="button"
      (click)="previous()"></svg>
    <span *ngFor="let i of page.previous" (click)="goTo(i)" class="mx-3" role="button">{{
      i
    }}</span>
    <span class="fw-bold mx-3" role="button">{{ page.current }}</span>
    <span *ngFor="let i of page.next" (click)="goTo(i)" class="mx-3" role="button">{{ i }}</span>
    <svg
      icon
      name="chevron-right"
      class="icon--lg"
      *ngIf="page.hasNext"
      role="button"
      (click)="next()"></svg>
    <svg
      icon
      name="double-chevron-right"
      class="icon--lg"
      *ngIf="page.hasNext"
      role="button"
      (click)="last()"></svg>
  </ng-container>
</ng-container>
