<app-select
  [formControl]="control"
  [items]="items"
  [bindValue]="bindValue"
  [placeholder]="!control.disabled && placeholder ? placeholder : undefined"
  [disableSingleValue]="disableSingleValue"
  ngDefaultControl>
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <app-flag
      [icon]="item[bindIcon]"
      position="left"
      [label]="item[bindLabel]"
      [translateLabel]="translateLabel"></app-flag>
  </ng-template>
</app-select>
