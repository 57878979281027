import { Component, Input } from "@angular/core";
import { TransactionType } from "@features/transaction/models";

@Component({
  selector: "[similar-deals-warning-item]",
  templateUrl: "similar-deals-warning-item.component.html",
})
export class SimilarDealsWarningItemComponent {
  @Input() similarDeal!: SimilarDeal;
  @Input() transactionType?: TransactionType;
  @Input() role!: "stack" | "table";
}

type SimilarDeal = {
  DealId: string;
  Currency?: string;
  CurrencyPair?: string;
  Amount: number;
  DealDate: string;
  DealType: string;
  Status: string;
};
