// #########################
// ## MODYFIKACJA
// #########################

import { HttpParams } from "@angular/common/http";
import { Customer } from "@core/models/customer.model";
import { Language, Preferences, TransactionControl } from "@core/preferences/preferences.model";
import { UserData } from "@core/session";
import { Account, DefaultAccounts, PvpRules } from "@features/accounts";
import {
  Application,
  CompetedContractsModel,
  InfoPhones,
  Regulation,
} from "@features/activation/activation.model";
import { ContractAvailability } from "@features/activation/components/apply/application-select.model";
import { AlertListData } from "@features/alert/models/alert-list";
import { CreateContractResult } from "@features/apply/apply.model";
import { BlotterCurrency, BlotterDate } from "@features/blotter-graph/blotter-graph.model";
import { COLLATERAL_TYPES, CollateralType } from "@features/collateral";
import { Currency, CurrencyPair } from "@features/currency";
import { DepositHistoryItem } from "@features/deposit-history/models/deposit-list";
import { DepositRateSuccess } from "@features/deposit/models/deposit-rate";
import { PremiumDepositConfiguration } from "@features/deposit/premium/resolver.service";
import { Dpw } from "@features/dpw/dpw.model";
import { ExchangeRateSuccess } from "@features/exchange/models";
import { Rate, Widget } from "@features/fast-deal/fast-deal.model";
import { GraphButtonConfig, GraphPoint, IndicativeGraph } from "@features/graph/graph.model";
import {
  PageData,
  View360Details,
  WATCH_STATUSES,
  statuses,
} from "@features/history-common/models";
import {
  BaseExchangeDetail,
  ChangeNdfResponse,
  ExchangeDetails,
  ExchangeHistoryItem,
  SwapDetails,
} from "@features/history/models/exchange-list";
import {
  SettlementsGroupedByDate,
  SettlementsGroupedByPair,
} from "@features/history/models/settlement-list";
import { Deal, IListItem, PricingsConfig } from "@features/history/models/view360-list";
import { IndicativePair, IndicativeRate } from "@features/indicatives/indicatives.model";
import { Deposit } from "@features/investment-deposit-history/ivestment-deposit-history.model";
import {
  InvestmentDepositConfig,
  InvestmentDepositRateSuccess,
} from "@features/investment-deposit/investment-deposit.model";
import { Analysis, MarketNews } from "@features/market-news/market-news.model";
import { Message } from "@features/messages/messages.model";
import { MultiFxTransactionRateSuccess } from "@features/multifx/models/multifx-rate";
import {
  NewDpw,
  UnsettledDeal,
  UserNotification,
} from "@features/notifications/notifications.model";
import { OrderDetails, OrderItem } from "@features/order-history/models/order-history";
import { OrderFormData, OrderType } from "@features/order/models/order-form";
import {
  RecentDeal,
  UnsettledDeal as UnsettledDealHistory,
} from "@features/recent-history/recent-history.model";
import { Prefix } from "@features/telephone/telephone-model";
import { NON_FORWARD_TENORS, PRODUCT_TYPES, ProductType, TenorDate } from "@features/tenor";
import { LiveRate } from "@features/transaction/components/form-rate/live-rate.service";
import {
  ComplexInitializationResponse,
  InitializationResponse,
  RESPONSE_TYPE,
  RateResponse,
  StatusResponse,
} from "@features/transaction/models";
import { Config } from "protractor";
import { of } from "rxjs";
import { commonData } from "./prezentacja.interceptor";

export const dataPrezentacjaWaluty = {
  PLN: "PLN",
  EUR: "EUR",
  USD: "USD",
  CHF: "CHF",
  GBP: "GBP",
};

const currentDate = new Date();

const calculatePrevDate = (
  back: { year: number; month: number; day: number },
  set: { hour: number; minute: number }
): Date => {
  let dataToReturn = currentDate;
  const date = new Date(currentDate);
  date.setFullYear(date.getFullYear() - back.year);
  date.setMonth(date.getMonth() - back.month);
  date.setDate(date.getDate() - back.day);
  date.setHours(set.hour);
  date.setMinutes(set.minute);

  if (date.getDay() === 6) {
    dataToReturn = new Date(date.getTime() - 86400000);
  } else if (date.getDay() === 0) {
    dataToReturn = new Date(date.getTime() - 2 * 86400000);
  } else {
    dataToReturn = date;
  }
  return dataToReturn;
};

const calculateDate = (year: number, month: number, day: number): Date => {
  // [ZMIENIONY SPOSÓB ROZLICZANIA DAT]
  let dataToReturn = currentDate;
  const date = new Date(currentDate);
  date.setFullYear(date.getFullYear() + year);
  date.setMonth(date.getMonth() + month);
  date.setDate(date.getDate() + day);

  if (date.getDay() === 6) {
    dataToReturn = new Date(date.getTime() + 2 * 86400000);
  } else if (date.getDay() === 0) {
    dataToReturn = new Date(date.getTime() + 86400000);
  } else {
    dataToReturn = date;
  }
  return dataToReturn;
};

const calculatePrevFreeDate = (date: string) => {
  let result = new Date(date);

  if (result.getDay() > 1) {
    result = new Date(result.getTime() - 86400000);
  } else if (result.getDay() === 1) {
    result = new Date(result.getTime() - 3 * 86400000);
  }
  return result.toISOString();
};

const calculateDateFromDate = (startDate: Date, year: number, month: number, day: number): Date => {
  // [ZMIENIONY SPOSÓB ROZLICZANIA DAT]
  let dataToReturn = startDate;
  const date = new Date(startDate);
  date.setFullYear(date.getFullYear() + year);
  date.setMonth(date.getMonth() + month);
  date.setDate(date.getDate() + day);

  if (date.getDay() === 6) {
    dataToReturn = new Date(date.getTime() + 2 * 86400000);
  } else if (date.getDay() === 0) {
    dataToReturn = new Date(date.getTime() + 86400000);
  } else {
    dataToReturn = date;
  }
  return dataToReturn;
};

const dataPreference: Preferences = {
  exchange: {
    side: "Sell",
    currency: dataPrezentacjaWaluty.EUR,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    amount: 10000.0,
    tenor: "TOD", // ZMIANA Z SN
  },
  deposit: {
    amount: 1000000,
    currency: dataPrezentacjaWaluty.PLN,
    startTenor: "ON",
    endTenor: "6M",
    showLPREMessage: false,
  },
  language: {
    code: "pl",
    name: "Polski",
  },
  dashboard: {
    // "shortcuts": ["NewDeal", "NewOrder", "Profile", "NewAlert"],
    shortcuts: [],
    simple: true,
    tilesSelection: {
      //   "deal","order","alert","deposit","dpw","notifications","news","transactions",
      left: ["order", "alert", "deposit", "deal"],
      right: ["dpw", "notifications", "news", "transactions"],
    },
  },
  swap: {
    side: "Sell",
    currency: dataPrezentacjaWaluty.EUR,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    amount: 10000.0,
    nearTenor: "SPOT",
    farTenor: "2W",
  },
  transactionControl: {
    values: {
      checkSimilarDeals: false,
      allowDealsWithoutBalance: false, //true
    },
    availability: {
      checkSimilarDeals: false,
      allowDealsWithoutBalance: false,
    },
  },
  indicatives: {
    pollingEnabled: false,
    tenor: NON_FORWARD_TENORS[0],
  },
  dealHistory: {
    order: "TransactionDate",
    openBalance: true, // << -- zmiana
    unsettled: true, // << -- zmiana
  },
  phone: {
    prefix: "+48",
    number: "456456456",
  },
  graph: {
    side: "Sell",
    period: 0,
    type: "linear",
  },
  blotterGraph: {
    currencies: [
      // domyślne wartości dla wykresu moich rozliczeń
      dataPrezentacjaWaluty.PLN,
      dataPrezentacjaWaluty.EUR,
    ],
  },
  customers: {
    pinned: [
      // "K365063"
    ],
  },
  investmentDeposit: {
    currency: "",
    amount: 0,
    currencyPair: "",
    tenor: "",
  },
  depositControl: {
    checkSimilarDeals: true,
  },
  premiumDepositControl: {
    checkSimilarDeals: true,
  },
  investmentDepositControl: {
    checkSimilarDeals: true,
  },
};

const dataUserTransakcyjny: UserData = {
  userName: "DEALER",
  customerName: "KLIENT TESTOWY - pełna oferta",
  customerShortName: "K123456",
  tokenExpiration: 10,
  roles: [
    "ProUser",
    // "LiEnabled",
    "Customer",
    "HasCustomer",
    "FxSpot",
    "FxForward",
    "FxSwap",
    "MultiFx",
    "MmDeposit",
    "PremiumDeposit",
    "Orders",
    "PotentialCustomer",
    // "Lei",
    "DashboardEnabled",
    "DPWEnabled",
    "DefaultAccounts",
    "ProductAttorneys",
    "HRB",
    "MRB",
    "HRO",
    "MRO",
    "DpwOn",
    "InvDeposit",
  ],
  channel: "IPKO",
  passive: false, // na true to w 'Moje pary walutowe' nie pozwala sprzedac kupic
  customerPid: "79170859",
  isIdg: false, // profile.Menu.Mifid
  isInstitutional: false,
  showPilotInfo: false, // pokazuje informacje przy ładowaniu demo
  leiMessage: {
    show: false,
    expired: false,
    lei: {
      code: "12345600000000000007",
      expirationDate: calculateDate(1, 0, 0),
    },
    infoLink: "https://www.pkobp.pl",
  },
  showMobileAuthNotAvailable: false,
  showWelcomeMessage: false,
  showFlowDeclaration: false,
  flowsDeclaration: {
    canBePostponed: false,
    declaration: {
      dateFrom: calculateDate(-1, 0, 0),
      dateTo: calculateDate(-1, 0, 1),
      refuseToAnswer: true,
      isActual: false,
      flowsData: [],
    },
    currencies: [],
  },
  liPilot: false,
  showLPREMessage: false,
  showTreasuryForm: false,
  hasTreasuryFormProducts: false,
};

const dataUserDigitalizacja = {
  customerName: "KLIENT TESTOWY - nowy wniosek",
  customerShortName: "K401388",
  customerPid: "98765432",
  channel: "IPKO",
  tokenExpiration: 10,
  roles: ["Customer", "PotentialCustomer"],
  leiMessage: {
    show: false,
    expired: false,
    lei: {},
  },
  passive: false,
  isIdg: false,
  isInstitutional: false,
  showWelcomeMessage: false,
  showPilotInfo: false,
  showFlowDeclaration: false,
  showMobileAuthNotAvailable: false,
  liPilot: true,
  showLPREMessage: false,
  showTreasuryForm: false,
  hasTreasuryFormProducts: false,
};

const dataUserJDG = {
  userName: "KLIENT TESTOWY - szybka wymiana walut",
  customerName: "KLIENT TESTOWY - szybka wymiana walut",
  customerShortName: "K401377",
  tokenExpiration: 10,
  roles: ["Customer", "PotentialCustomer"],
  channel: "IPKO",
  passive: false,
  customerPid: "87654321",
  isIdg: false,
  isInstitutional: false,
  showPilotInfo: false,
  leiMessage: {
    show: false,
    expired: false,
    lei: {
      code: "12345600000000000007",
      expirationDate: calculateDate(1, 0, 0),
    },
    infoLink: "https://www.pkobp.pl",
  },
  showMobileAuthNotAvailable: false,
  showWelcomeMessage: true,
  showFlowDeclaration: false,
  flowsDeclaration: {
    canBePostponed: false,
    declaration: {
      dateFrom: calculateDate(-1, 0, 0),
      dateTo: calculateDate(-1, 0, 1),
      refuseToAnswer: true,
      isActual: false,
      flowsData: [],
    },
    currencies: [],
  },
  liPilot: false,
  showLPREMessage: false,
  showTreasuryForm: false,
  hasTreasuryFormProducts: false,
};

// sessionStorage.setItem("demoPid", dataUserTransakcyjny.customerPid);

const dataCustomer: Customer[] = [
  {
    id: "CfDJ8EuOa00pnIBOgQ0p9GJKPNQhgNFGo5qtwaKpPaNGF",
    pid: "79170859",
    name: "KLIENT TESTOWY - pełna oferta",
    shortName: "K123456",
    hasApp: true,
    canApplyInQuickPath: false,
    canApply: true,
    hasPendingTreasuryAgreementAuth: false,
  },
  {
    id: "CfDJ8EuOa00pnIBOgQ0p9GJKPNT1utBcZ62BDJyM6ID199",
    pid: "87654321",
    name: "KLIENT TESTOWY - szybka wymiana walut",
    shortName: "K401377",
    hasApp: false,
    canApplyInQuickPath: true,
    canApply: true,
    hasPendingTreasuryAgreementAuth: false,
  },
  {
    id: "CfDJ8EuOa00pnIBOgQ0p9GJKPNT1utBcZ62BDJyM6ID66S",
    pid: "98765432",
    name: "KLIENT TESTOWY - nowy wniosek",
    shortName: "K401388",
    hasApp: false,
    canApplyInQuickPath: false,
    canApply: true,
    hasPendingTreasuryAgreementAuth: false,
  },
];

let isContractsInClientActiveSign = false;
let isContractsCanceled = false;

let contractActiveSigned = false;
const contractActive = (isSigned: boolean, isOnline: boolean) => {
  const userPid = sessionStorage.getItem("demoPid");
  let isNew = true;
  let user;
  switch (userPid) {
    case dataUserJDG.customerPid:
      user = dataUserJDG;
      break;
    case dataUserDigitalizacja.customerPid:
      user = dataUserDigitalizacja;
      break;
    default:
      isNew = false;
      user = dataUserTransakcyjny;
  }
  return {
    number: "999888771",
    nemoId: "999888771",
    clientAName: user.customerName,
    clientAPid: user.customerPid,
    clientBName: "",
    clientBPid: "",
    type: isNew ? "URN" : "URZ", // "URN" | "URZ" | "SPOT1" | "JDGSPOT"
    status: !isOnline ? "Verification" : isSigned ? "ContractSigned" : "ReadyForSigning",
    /*
    "Cancelled": "Rejected": "Branch": "New": "ReadyForSigning": "ReadyForSigningI":  "Verification": "Realization": "Accepted": "ContractSigned": "Completed": "NotRecognized": "SchemaChange": "ErrorGate": "NewSuspended":"RealizationJdg": "RealizationSuspended"
    */
    cancelVisible: isSigned ? false : isOnline ? true : false,
    signVisible: isSigned ? false : true,
    methodOfSigning: isOnline ? "Online" : "Branch",
    isInstitutional: true,
  };
};
let contractsActive: any[] = [];

let dataAccount: Account[] = [
  {
    id: "1",
    number: "58 1020 1111 0000 0000 0102 0902",
    name: "BIZNES PARTNER PLN 1",
    currency: dataPrezentacjaWaluty.PLN,
    balance: 234678.67,
    isExternal: false,
    isDefault: true,
  },
  {
    id: "2",
    number: "57 1020 1111 0000 0000 0102 0901",
    name: "BIZNES PARTNER PLN 2",
    currency: dataPrezentacjaWaluty.PLN,
    balance: 10000.0,
    isExternal: false,
    isDefault: false,
  },
  {
    id: "3",
    number: "87 1020 1111 0000 0000 0601 8771",
    name: "BIZNES PARTNER EUR 1",
    currency: dataPrezentacjaWaluty.EUR,
    balance: 1236982.03,
    isExternal: false,
    isDefault: true,
  },
  {
    id: "4",
    number: "88 1020 1111 0000 0000 0601 8772",
    name: "BIZNES PARTNER EUR 2",
    currency: dataPrezentacjaWaluty.EUR,
    balance: 5000.0,
    isExternal: false,
    isDefault: false,
  },
  {
    id: "5",
    number: "17 1020 1111 0000 0000 0506 8703",
    name: "BIZNES PARTNER USD",
    currency: dataPrezentacjaWaluty.USD,
    balance: 345003.09,
    isExternal: false,
    isDefault: true,
  },
  {
    id: "6",
    number: "22 1020 1111 0000 0000 0226 3001",
    name: "BIZNES PARTNER CHF",
    currency: dataPrezentacjaWaluty.CHF,
    balance: 125678.45,
    isExternal: false,
    isDefault: true,
  },
];

const calculateTenorDate = (date: Date): string => {
  let dateToReturn = date;
  if (date.getDay() === 6) {
    dateToReturn = new Date(date.getTime() + 2 * 86400000);
  }
  if (date.getDay() === 0) {
    dateToReturn = new Date(date.getTime() + 86400000);
  }
  return dateToReturn.toISOString();
};

const changePairToRealCurrencyPair = (oldPair: string): string => {
  let dataToReturn = oldPair;
  let reverse = true;

  (PrezentacjaDane["/currency/pairs"]["GET"]({}) as CurrencyPair[]).forEach((el) => {
    if (el.code === oldPair) {
      reverse = false;
    }
  });
  if (reverse) {
    const curr1 = oldPair.slice(0, 3);
    const curr2 = oldPair.slice(3, 6);
    dataToReturn = curr2 + curr1;
  }

  return dataToReturn;
};

const checkIfIsDoubleExchange = (newExchange: any): boolean => {
  if (dataPreference.transactionControl.values.checkSimilarDeals) {
    const { currency, counterCurrency, amount, product } = newExchange;
    const uniqueExchange = `${currency}-${counterCurrency}-${amount}-${product}`;

    if (dataExchangeSet.has(uniqueExchange)) {
      return true;
    }
  }
  return false;
};
const calculateExchangeRate = (pair: string, isSell: string): number => {
  let rateToReturn = 0;

  let arrayToCalculate = [];
  if (DataIndicativeRate.length > 0) {
    arrayToCalculate = DataIndicativeRate;
  } else {
    arrayToCalculate = PrezentacjaDane["/indicatives/rates"]["GET"]({}) as IndicativeRate[];
  }
  arrayToCalculate
    .filter((el) => el.pair === pair)
    .forEach((el) => {
      if (isSell === "Sell") {
        rateToReturn = el.sell;
      } else {
        rateToReturn = el.buy;
      }
    });
  return rateToReturn;
};
const calculateExchangeFisibility = (dataExchange: any): boolean => {
  let dataToReturn = true;
  dataAccount.forEach((el) => {
    if (dataExchange.side === "Sell") {
      if (el.number === dataExchange.account) {
        if ((el.balance ?? 0) < dataExchange.amount) {
          dataToReturn = false;
        }
      }
    } else {
      if (el.number === dataExchange.counterAccount) {
        if (
          (el.balance ?? 0) <
          (dataExchangeRateSwapPairs
            ? +(dataExchange.amount / dataExchangeRate).toFixed(2)
            : dataExchange.amount * dataExchangeRate)
        ) {
          dataToReturn = false;
        }
      }
    }
  });
  return dataToReturn;
};
const calculateMultiFxFisibility = (dataExchange: any): boolean => {
  let dataToReturn = true;
  dataAccount.forEach((el) => {
    if (el.number === dataExchange.account) {
      const accountBallance = el.balance ?? 0;
      if (accountBallance < Math.abs(dataExchangeMultiFxTotal)) {
        dataToReturn = false;
      }
      dataExchange.series.forEach((el2: any) => {
        const { side, amount } = el2;
        if (side === "Sell" && accountBallance < +amount) {
          dataToReturn = false;
        }
      });
    }
    if (el.number === dataExchange.counterAccount) {
      const accountBallance = el.balance ?? 0;

      if (accountBallance < Math.abs(dataExchangeMultiFxCounterTotal)) {
        dataToReturn = false;
      }
      dataExchange.series.forEach((el2: any) => {
        const { side, amount } = el2;
        if (side === "Buy" && accountBallance < +amount) {
          dataToReturn = false;
        }
      });
    }
  });
  return dataToReturn;
};
const calculateDepositFisibility = (dataDeposit: any): boolean => {
  // let dataToReturn = true;
  // dataAccount.forEach((el) => {
  //   if (el.number === dataDeposit.account) {
  //     if ((el?.balance ? el.balance : 0) < +dataDeposit.amount) {
  //       dataToReturn = false;
  //     }
  //   }
  // });
  // return dataToReturn;
  return true;
};

let DataIndicativeRate: IndicativeRate[] = [];

let dataExchangeArray: ExchangeHistoryItem[] = [
  // ROZLICZONE
  {
    id: "120000048",
    transactionDate: calculateDate(0, 0, -1).toISOString(),
    subtype: "IOB Transfer incoming",
    side: "Sell", // "Sell" | "Buy"
    amount: 17000.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 78409.1,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6123,
    status: statuses[1], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, -1),
    openBalance: 0,
    details$: of(
      // <<-- zmiana
      {
        id: "120000048",
        transactionDate: calculateDate(0, 0, -1),
        subtype: "IOB Transfer incoming",
        side: "Sell", // "Sell" | "Buy"
        amount: 17000.0,
        currency: dataPrezentacjaWaluty.EUR,
        counterAmount: 78409.1,
        counterCurrency: dataPrezentacjaWaluty.PLN,
        rate: 4.6123,
        status: statuses[1], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
        settlementDate: calculateDate(0, 0, -1).toISOString(),
        openBalance: 0,
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
        // collateral?: Collateral,
        product: PRODUCT_TYPES.overnight,
        linkedDeals: [],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
        account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
        counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
        // orderId?: number,
        linkedTransactionNr: "",
        netSettlement: false,
        referenceRate: "", // ["Market Rate","NBP Fixing"]
        ndfModifiable: false,
        // netSettlementAmount?: number,
        netSettlementCurrency: dataPrezentacjaWaluty.PLN,
        closeVisible: false,
        rollVisible: false,
        rollData: {
          // rollDisabledReason?: string,
          isHistoricalRollBackEnabled: false,
          isHistoricalRollOverEnabled: false,
          isMarketRollBackEnabled: false,
          isMarketRollOverEnabled: false,
          rateTypes: [],
        },
        dpwReference: "SR22SN00060388DR",

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana        [potwierdzenie PDF przycisk właczenie/ wyłączenie]
        type: "", // << -- zmiana
      } as ExchangeDetails // as BaseExchangeDetail
    ),
    type: "FXON", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  {
    id: "120000010",
    transactionDate: calculateDate(0, 0, -7).toISOString(),
    subtype: "",
    side: "Sell", // "Sell" | "Buy"
    amount: 200.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 927.8,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.639,
    status: statuses[1], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, -5),
    openBalance: 0,
    details$: of(
      {
        id: "120000010",
        transactionDate: calculateDate(0, 0, -7),
        subtype: "",
        side: "Sell", // "Sell" | "Buy"
        amount: 200.0,
        currency: dataPrezentacjaWaluty.EUR,
        counterAmount: 927.8,
        counterCurrency: dataPrezentacjaWaluty.PLN,
        rate: 4.639,
        status: statuses[1], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
        settlementDate: calculateDate(0, 0, -5).toISOString(),
        openBalance: 0,
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
        // collateral: {
        //     type: "Limit",   //  "Limit" | "Deposit" | "AmountBlock"
        // },
        product: PRODUCT_TYPES.spot,
        linkedDeals: [],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
        account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
        counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
        // orderId?: number,
        linkedTransactionNr: "",
        netSettlement: false,
        referenceRate: "", // ["Market Rate","NBP Fixing"]
        ndfModifiable: false,
        // netSettlementAmount?: number,
        netSettlementCurrency: dataPrezentacjaWaluty.PLN,
        closeVisible: false,
        rollVisible: false,
        rollData: {
          // rollDisabledReason?: string,
          isHistoricalRollBackEnabled: false,
          isHistoricalRollOverEnabled: false,
          isMarketRollBackEnabled: false,
          isMarketRollOverEnabled: false,
          rateTypes: [],
        },
        // dpwReference: "SR22SN00060388DR",

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana
        type: "", // << -- zmiana
      } as ExchangeDetails // as BaseExchangeDetail
    ),
    type: "FXSPOT", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  {
    id: "120000006",
    transactionDate: calculateDate(0, 0, -7).toISOString(),
    subtype: "",
    side: "Sell", // "Sell" | "Buy"
    amount: 1000.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 4644.8,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6448,
    status: statuses[1], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, -7),
    openBalance: 0,
    details$: of(
      {
        id: "120000006",
        transactionDate: calculateDate(0, 0, -7),
        subtype: "",
        side: "Sell", // "Sell" | "Buy"
        amount: 1000.0,
        currency: dataPrezentacjaWaluty.EUR,
        counterAmount: 4644.8,
        counterCurrency: dataPrezentacjaWaluty.PLN,
        rate: 4.6448,
        status: statuses[1], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
        settlementDate: calculateDate(0, 0, -7).toISOString(),
        openBalance: 0,
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
        // collateral?: Collateral,
        product: PRODUCT_TYPES.overnight,
        linkedDeals: [],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
        account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
        counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
        // orderId?: number,
        linkedTransactionNr: "",
        netSettlement: false,
        referenceRate: "", // ["Market Rate","NBP Fixing"]
        ndfModifiable: false,
        // netSettlementAmount?: number,
        netSettlementCurrency: dataPrezentacjaWaluty.PLN,
        closeVisible: false,
        rollVisible: false,
        rollData: {
          // rollDisabledReason?: string,
          isHistoricalRollBackEnabled: false,
          isHistoricalRollOverEnabled: false,
          isMarketRollBackEnabled: false,
          isMarketRollOverEnabled: false,
          rateTypes: [],
        },
        // dpwReference: "SR22SN00060388DR",

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana
        type: "", // << -- zmiana
      } as ExchangeDetails // as BaseExchangeDetail
    ),
    type: "FXON", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  // NIEROZLICZONE
  {
    id: "120000023",
    transactionDate: calculateDate(0, 0, -10).toISOString(), // calculateDate(0, -1, -10).toISOString(), //
    // subtype: "",
    subtype: "NDF",
    side: "Sell", // "Sell" | "Buy"
    amount: 8000.0,
    currency: dataPrezentacjaWaluty.CHF,
    counterAmount: 35762.4,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.4703,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 1, 0), // calculateDate(0, 0, -14),     //
    openBalance: 8000.0,
    details$: of(
      {
        id: "120000023",
        transactionDate: calculateDate(0, 0, -10),
        // subtype: "",
        subtype: "NDF",
        side: "Sell", // "Sell" | "Buy"
        amount: 8000.0,
        currency: dataPrezentacjaWaluty.CHF,
        counterAmount: 35762.4,
        counterCurrency: dataPrezentacjaWaluty.PLN,
        rate: 4.4703,
        status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
        settlementDate: calculateDate(0, 1, 0).toISOString(),
        openBalance: 8000.0, // TO SŁUŻY DO ZAMKNIĘCIA TRANZAKCJI !!! najlepiej      openBalance === amount
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.CHF + dataPrezentacjaWaluty.PLN,
        collateral: {
          type: "Deposit", //  "Limit" | "Deposit" | "AmountBlock"
          account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.CHF)[0],
          amount: 400,
          currency: dataPrezentacjaWaluty.CHF,
        },
        product: PRODUCT_TYPES.forward,
        linkedDeals: [],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
        account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.CHF)[0],
        counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
        // orderId?: number,
        linkedTransactionNr: "",
        netSettlement: true, // Czy zaznacza 'Roliczenie na netto'
        referenceRate: "Market Rate", // ["Market Rate","NBP Fixing"]
        ndfModifiable: true, // Umożliwia zmiane ROZLICZENIA z NETTO na BRUTTO
        netSettlementAmount: 8000.0,
        netSettlementCurrency: dataPrezentacjaWaluty.CHF,
        closeVisible: true, // Umożliwia opcje 'Zamknij tranzakcje'
        rollVisible: true, // jak brutto to aktywny [jak netto to nie aktywny]
        rollData: {
          rollDisabledReason: "Deal_Leg_is_an_NDF",
          isHistoricalRollBackEnabled: false,
          isHistoricalRollOverEnabled: false,
          isMarketRollBackEnabled: false,
          isMarketRollOverEnabled: false,
          rateTypes: ["Historical", "Market"],
        },
        // dpwReference?: string,

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana
        type: "FX", // << -- zmiana  [POTRZEBNE BY WYŚWIETLAC SZCZEGOŁY]
      } as ExchangeDetails // as BaseExchangeDetail
    ),
    type: "FXFWD", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  // roll_back
  {
    id: "120000015",
    transactionDate: calculateDate(0, 0, -14).toISOString(),
    subtype: "",
    side: "Sell", // "Sell" | "Buy"
    amount: 170000.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 788834.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6402,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 14),
    openBalance: 0,
    details$: of(
      {
        id: "120000015",
        transactionDate: calculateDate(0, 0, -14),
        subtype: "",
        side: "Sell", // "Sell" | "Buy"
        amount: 170000.0,
        currency: dataPrezentacjaWaluty.EUR,
        counterAmount: 788834.0,
        counterCurrency: dataPrezentacjaWaluty.PLN,
        rate: 4.6402,
        status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
        settlementDate: calculateDate(0, 0, 14).toISOString(),
        openBalance: 0,
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
        collateral: {
          type: "Limit", //  "Limit" | "Deposit" | "AmountBlock"
        },
        product: PRODUCT_TYPES.forward,
        linkedDeals: [
          // CZY COŚ TUTAJ ???
        ],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
        account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
        counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
        // orderId?: number,
        linkedTransactionNr: "",
        netSettlement: false,
        referenceRate: "", // ["Market Rate","NBP Fixing"]
        ndfModifiable: true,
        // netSettlementAmount?: number,
        netSettlementCurrency: dataPrezentacjaWaluty.PLN,
        closeVisible: false,
        rollVisible: true,
        rollData: {
          rollDisabledReason:
            "Deal_Leg_is_a_Closing_deal_or_ManRollCloseDeal_or_Penalty_Closing_deal_",
          isHistoricalRollBackEnabled: false,
          isHistoricalRollOverEnabled: false,
          isMarketRollBackEnabled: false,
          isMarketRollOverEnabled: false,
          rateTypes: ["Historical", "Market"],
        },
        // dpwReference: "SR22SN00060388DR",

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana
        type: "", // << -- zmiana
      } as ExchangeDetails // as BaseExchangeDetail
    ),
    type: "FXFWD", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  {
    id: "120000016",
    transactionDate: calculateDate(0, 0, -14).toISOString(),
    subtype: "HistoricalRollback",

    details$: of(
      {
        id: "120000016",
        transactionDate: calculateDate(0, 0, -14),
        subtype: "HistoricalRollback",

        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
        collateral: {
          type: "Limit", //  "Limit" | "Deposit" | "AmountBlock"
        },
        product: PRODUCT_TYPES.swap,
        linkedDeals: [],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS

        status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"] ,
        nearLeg: {
          amount: 170000,
          currency: dataPrezentacjaWaluty.EUR,
          counterAmount: 785604.0,
          counterCurrency: dataPrezentacjaWaluty.PLN,
          status: "Unsettled",
          side: "Sell",
          settlementDate: calculateDate(0, 0, 7).toISOString(),
          rate: 4.6212,
          account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
          counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
          openBalance: 170000, // było 0
          closeVisible: false,
          rollVisible: false,
          rollData: {
            // rollDisabledReason?: string,
            isHistoricalRollBackEnabled: false,
            isHistoricalRollOverEnabled: false,
            isMarketRollBackEnabled: false,
            isMarketRollOverEnabled: false,
            rateTypes: [],
          },

          buyPricingCurrency: "", // << -- zmiana
          sellPricingCurrency: "", // << -- zmiana
        },
        farLeg: {
          amount: 170000,
          currency: dataPrezentacjaWaluty.EUR,
          counterAmount: 788834.0,
          counterCurrency: dataPrezentacjaWaluty.PLN,
          status: "Unsettled",
          side: "Buy",
          settlementDate: calculateDate(0, 0, 14).toISOString(),
          rate: 4.6402,
          account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
          counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
          openBalance: 0,
          closeVisible: false,
          rollVisible: false,
          rollData: {
            // rollDisabledReason?: string,
            isHistoricalRollBackEnabled: false,
            isHistoricalRollOverEnabled: false,
            isMarketRollBackEnabled: false,
            isMarketRollOverEnabled: false,
            rateTypes: [],
          },

          buyPricingCurrency: "", // << -- zmiana
          sellPricingCurrency: "", // << -- zmiana
        },
        linkedTransactionNr: "120000015",

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana
        type: "", // << -- zmiana
      } as SwapDetails // as BaseExchangeDetail
    ),

    side: "Sell", // "Sell" | "Buy"
    amount: 170000,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 785604.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6212,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 7),
    openBalance: 170000,
    type: "FXSWAP", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.EUR,
    displayDetails: false,
  },
  {
    id: "120000016",
    transactionDate: calculateDate(0, 0, -14).toISOString(),
    subtype: "HistoricalRollback",

    details$: of(
      {
        id: "120000016",
        transactionDate: calculateDate(0, 0, -14),
        subtype: "HistoricalRollback",

        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
        collateral: {
          type: "Limit", //  "Limit" | "Deposit" | "AmountBlock"
        },
        product: PRODUCT_TYPES.swap,
        linkedDeals: [],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS

        status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"] ,
        nearLeg: {
          amount: 170000,
          currency: dataPrezentacjaWaluty.EUR,
          counterAmount: 785604.0,
          counterCurrency: dataPrezentacjaWaluty.PLN,
          status: "Unsettled",
          side: "Sell",
          settlementDate: calculateDate(0, 0, 7).toISOString(),
          rate: 4.6212,
          account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
          counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
          openBalance: 170000, // buło 0
          // closeVisible: false,
          // rollVisible: false,
          closeVisible: true,
          rollVisible: true,
          rollData: {
            // rollDisabledReason?: string,
            isHistoricalRollBackEnabled: false,
            isHistoricalRollOverEnabled: false,
            isMarketRollBackEnabled: false,
            isMarketRollOverEnabled: false,
            rateTypes: [],
          },

          buyPricingCurrency: "", // << -- zmiana
          sellPricingCurrency: "", // << -- zmiana
        },
        farLeg: {
          // spróbować dodac toolptipa
          amount: 170000,
          currency: dataPrezentacjaWaluty.EUR,
          counterAmount: 788834.0,
          counterCurrency: dataPrezentacjaWaluty.PLN,
          status: "Settled", //Unsettled
          side: "Buy",
          settlementDate: calculateDate(0, 0, 14).toISOString(),
          rate: 4.6402,
          account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
          counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
          openBalance: 0,
          closeVisible: false,
          rollVisible: true,
          rollData: {
            // rollDisabledReason?: string,
            isHistoricalRollBackEnabled: true,
            isHistoricalRollOverEnabled: true,
            isMarketRollBackEnabled: true,
            isMarketRollOverEnabled: true,
            rateTypes: [],
          },

          buyPricingCurrency: "", // << -- zmiana
          sellPricingCurrency: "", // << -- zmiana
        },
        linkedTransactionNr: "",

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana
        type: "", // << -- zmiana
      } as SwapDetails // as BaseExchangeDetail
    ),

    side: "Buy", // "Sell" | "Buy"
    amount: 170000,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 788834.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6402,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 14),
    openBalance: 0,
    type: "FXSWAP", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  // roll_over
  {
    id: "12102199",
    transactionDate: calculateDate(0, 0, 0).toISOString(),
    // subtype: "NDF",
    subtype: "",
    side: "Sell", // "Sell" | "Buy"
    amount: 36000.0,
    currency: dataPrezentacjaWaluty.USD,
    counterAmount: 158256.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.396,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 10),
    openBalance: 0,
    details$: of(),
    type: "FXFWD", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  {
    id: "12102200",
    // transactionDate: calculateDate(0, 0, 2).toISOString(),
    transactionDate: calculateDate(0, 0, -1).toISOString(), // zmiana 2023-06-20
    subtype: "HistoricalRollover",

    details$: of(),

    side: "Buy", // "Sell" | "Buy"
    amount: 36000,
    currency: dataPrezentacjaWaluty.USD,
    counterAmount: 158256.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.396,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 10),
    openBalance: 0,
    type: "FXSWAP", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },
  {
    id: "12102200",
    // transactionDate: calculateDate(0, 0, 2).toISOString(),
    transactionDate: calculateDate(0, 0, -1).toISOString(), // zmiana 2023-06-20
    subtype: "HistoricalRollover",

    details$: of(),

    side: "Sell", // "Sell" | "Buy"
    amount: 36000,
    currency: dataPrezentacjaWaluty.USD,
    counterAmount: 158436.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.401,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 20),
    openBalance: 36000,
    type: "FXSWAP", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.USD,
    displayDetails: false,
  },
  //-------- notification elements
  {
    id: "120000001",
    transactionDate: calculateDate(0, 0, -7).toISOString(),
    subtype: "",
    side: "Buy", // "Sell" | "Buy"
    amount: 500.0,
    currency: dataPrezentacjaWaluty.USD,
    counterAmount: 2216.25,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.4325,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 1),
    openBalance: 500,
    details$: of({
      id: "120000001",
      transactionDate: calculateDate(0, 0, -7),
      subtype: "",
      side: "Buy", // "Sell" | "Buy"
      amount: 500.0,
      currency: dataPrezentacjaWaluty.USD,
      counterAmount: 2216.25,
      counterCurrency: dataPrezentacjaWaluty.PLN,
      rate: 4.4325,
      status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
      settlementDate: calculateDate(0, 0, 1).toISOString(),
      openBalance: 0,
      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      documentId: "",
      currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
      product: PRODUCT_TYPES.spot,
      linkedDeals: [],
      pvp: {
        // << -- zmiana
        value: false,
        visible: false,
      },
      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.USD)[0],
      counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
      linkedTransactionNr: "",
      netSettlement: false,
      referenceRate: "", // ["Market Rate","NBP Fixing"]
      ndfModifiable: false,
      netSettlementCurrency: dataPrezentacjaWaluty.PLN,
      closeVisible: false,
      rollVisible: false,
      rollData: {
        isHistoricalRollBackEnabled: false,
        isHistoricalRollOverEnabled: false,
        isMarketRollBackEnabled: false,
        isMarketRollOverEnabled: false,
        rateTypes: [],
      },

      attorney: "", // << -- zmiana
      openAmountCurrencyFTOOne: "", // << -- zmiana
      openAmountCurrencyFTOTwo: "", // << -- zmiana
      buyPricingCurrency: "", // << -- zmiana
      sellPricingCurrency: "", // << -- zmiana
      pricingCurrency: "", // << -- zmiana
      settlementType: "", // << -- zmiana
      hasConfirmation: true, // << -- zmiana
      type: "", // << -- zmiana
    } as ExchangeDetails),
    type: "FXSPOT",
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.USD,
    displayDetails: false,
  },
  {
    id: "120000002",
    transactionDate: calculateDate(0, 0, -10).toISOString(),
    subtype: "",
    side: "Sell", // "Sell" | "Buy"
    amount: 1000.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 4637.2,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6372,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 1, 0),
    openBalance: 1000,
    details$: of(
      {
        id: "120000002",
        transactionDate: calculateDate(0, 0, -10),
        subtype: "",
        side: "Sell", // "Sell" | "Buy"
        amount: 1000.0,
        currency: dataPrezentacjaWaluty.EUR,
        counterAmount: 4637.2,
        counterCurrency: dataPrezentacjaWaluty.PLN,
        rate: 4.6372,
        status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
        settlementDate: calculateDate(0, 1, 0).toISOString(),
        openBalance: 0,
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        documentId: "",
        currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
        // collateral: {
        //     type: "Deposit",   //  "Limit" | "Deposit" | "AmountBlock"
        //     account: dataAccount.filter(el => el.currency === dataPrezentacjaWaluty.EUR)[1],
        //     amount: 400,
        // },
        product: PRODUCT_TYPES.forward,
        linkedDeals: [],
        pvp: {
          // << -- zmiana
          value: false,
          visible: false,
        },
        //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
        account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
        counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
        // orderId?: number,
        linkedTransactionNr: "",
        netSettlement: true,
        referenceRate: "Market Rate", // ["Market Rate","NBP Fixing"]
        ndfModifiable: false,
        // netSettlementAmount: 400.00,
        netSettlementCurrency: dataPrezentacjaWaluty.PLN,
        closeVisible: false,
        rollVisible: false,
        rollData: {
          // rollDisabledReason?: string,
          isHistoricalRollBackEnabled: false,
          isHistoricalRollOverEnabled: false,
          isMarketRollBackEnabled: false,
          isMarketRollOverEnabled: false,
          rateTypes: [],
        },
        // dpwReference?: string,

        attorney: "", // << -- zmiana
        openAmountCurrencyFTOOne: "", // << -- zmiana
        openAmountCurrencyFTOTwo: "", // << -- zmiana
        buyPricingCurrency: "", // << -- zmiana
        sellPricingCurrency: "", // << -- zmiana
        pricingCurrency: "", // << -- zmiana
        settlementType: "", // << -- zmiana
        hasConfirmation: true, // << -- zmiana
        type: "", // << -- zmiana
      } as ExchangeDetails // as BaseExchangeDetail
    ),
    type: "FXFWD", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    openBalanceCurrency: dataPrezentacjaWaluty.EUR,
    displayDetails: false,
  },
  //--------

  // settlementsToday ***
  {
    id: "12103795",
    transactionDate: calculateDate(0, 0, -1).toISOString(),
    subtype: "basic",
    side: "Sell", // "Sell" | "Buy"
    amount: 400000.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 1943680.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.8592,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 0),
    openBalance: 400000.0,
    details$: of({
      id: "12103795",
      hasConfirmation: true,
      attorney: "", //"JUDKA MICHALEK",
      type: "FX",
      amount: 400000,
      transactionDate: calculateDate(0, 0, -1),
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
      currency: dataPrezentacjaWaluty.EUR,
      settlementDate: calculateDate(0, 0, 0).toISOString(),
      counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      counterAmount: 1943680,
      counterCurrency: dataPrezentacjaWaluty.PLN,
      status: statuses[2],
      rate: 4.8592,
      product: "FxOn", // PRODUCT_TYPES.AAA,
      side: "Sell",
      currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
      linkedDeals: [],
      netSettlement: false,
      ndfModifiable: false,
      openBalance: 400000,
      closeVisible: false,
      rollVisible: false,
      rollData: {
        isHistoricalRollBackEnabled: false,
        isHistoricalRollOverEnabled: true,
        isMarketRollBackEnabled: false,
        isMarketRollOverEnabled: true,
        rateTypes: ["Historical", "Market"],
        dealType: "FX ON",
        historicalRollStateDescription: "Cannot_roll_back_transaction_maturing_today",
        marketRollStateDescription: "Cannot_roll_back_transaction_maturing_today",
        historicalRollBackVisible: true,
        historicalRollForwardVisible: true,
        marketRollBackVisible: true,
        marketRollForwardVisible: true,
      },
      netSettlementAmount: 0,
      netSettlementCurrency: "",
      pvp: {
        value: true,
        visible: false,
      },
      dpwReference: "",
      documentId: "",
      subtype: "basic",
    } as BaseExchangeDetail),
    type: "FX ON", // ["FXON", "FXTN", "FXSPOT", "FXFWD", "FXSWAP"],
    openBalanceCurrency: dataPrezentacjaWaluty.EUR,

    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.EUR,
    displayDetails: false,
  },
  {
    id: "12103800",
    transactionDate: calculateDate(0, 0, -1).toISOString(),
    subtype: "Derivative",
    side: "Sell", // "Sell" | "Buy"
    amount: 300000.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 1399650.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6655,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 0),
    openBalance: 300000.0,
    details$: of({
      id: "12103800",
      hasConfirmation: true,
      attorney: "", //"JUDKA MICHAŁEK",
      type: "FX",
      amount: 300000,
      transactionDate: calculateDate(0, 0, -1),
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
      currency: dataPrezentacjaWaluty.EUR,
      settlementDate: calculateDate(0, 0, 0).toISOString(),
      counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      counterAmount: 1399650,
      counterCurrency: dataPrezentacjaWaluty.PLN,
      status: statuses[2],
      rate: 4.6655,
      product: "FxForw",
      side: "Sell",
      currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
      linkedDeals: [],
      collateral: {
        type: "Deposit",
        account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
        amount: 70740,
        currency: dataPrezentacjaWaluty.PLN,
      },
      netSettlement: false,
      ndfModifiable: true,
      openBalance: 300000,
      closeVisible: true,
      rollVisible: true,
      rollData: {
        isHistoricalRollBackEnabled: false,
        isHistoricalRollOverEnabled: true,
        isMarketRollBackEnabled: false,
        isMarketRollOverEnabled: true,
        rateTypes: ["Historical", "Market"],
        dealType: "FORW",
        historicalRollStateDescription: "Cannot_roll_back_transaction_maturing_today",
        marketRollStateDescription: "Cannot_roll_back_transaction_maturing_today",
        historicalRollBackVisible: true,
        historicalRollForwardVisible: true,
        marketRollBackVisible: true,
        marketRollForwardVisible: true,
      },
      netSettlementAmount: 0,
      netSettlementCurrency: "",
      pvp: {
        value: true,
        visible: false,
      },
      dpwReference: "",
      documentId: "",
      subtype: "Derivative",
    } as BaseExchangeDetail),
    type: "FORW", // FXFWD
    openBalanceCurrency: dataPrezentacjaWaluty.EUR,

    actualPricing: 0,
    actualPricingCurrency: dataPrezentacjaWaluty.PLN,
    displayDetails: false,
  },

  //
];

const otherTransationArray: any[] = [
  {
    amount: 250000,
    currency: dataPrezentacjaWaluty.PLN,
    id: "MX115966",
    type: "INVDEPO",
    status: "All",
    // transactionDate: calculateDate(0, 0, 0).toISOString(),
    // settlementDate: calculateDate(0, 1, 0).toISOString(),
    transactionDate: "2023-06-01T12:00:00",
    settlementDate: "2023-12-01T12:00:00",
    isComplex: false,

    // actualPricing: 3002,
    // pricingCurrency: dataPrezentacjaWaluty.PLN,
    // // pricingDate: calculateDate(0, 1, 0),

    details$: of({
      id: "MX115966",
      type: "INVDEPO",
      documentId: "", //"IAD32407534",
      hasConfirmation: true,
      attorney: "", //"MATEUSZ TEST",
      // transactionDate: calculateDate(0, 0, 0).toISOString(),
      // startDate: calculateDate(0, 0, 0).toISOString(),
      // endDate: calculateDate(0, 1, 0).toISOString(),
      transactionDate: "2023-06-01T12:00:00",
      startDate: "2023-06-01T12:00:00",
      endDate: "2023-12-01T12:00:00",
      amount: 250000,
      currency: dataPrezentacjaWaluty.PLN,
      strategy: "WS",
      status: "",
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],

      // pricingAmount: 3002,
      // pricingCurrency: dataPrezentacjaWaluty.PLN,
      // // pricingDate: calculateDate(0, 1, 0),
    } as View360Details),
  },

  {
    amount: 5000000,
    currency: dataPrezentacjaWaluty.PLN,
    id: "IRS47078",
    type: "IRS",
    status: "All",
    transactionDate: "2022-11-24T00:00:00",
    settlementDate: "2024-11-28T00:00:00",
    isComplex: false,

    actualPricing: 511.53,
    pricingCurrency: dataPrezentacjaWaluty.PLN,
    // pricingDate: calculateDate(0, 1, 0),

    details$: of({
      id: "IRS47078",
      type: "IRS",
      documentId: "", //"IRS47078",
      hasConfirmation: true,
      transactionDate: "2022-11-24T00:00:00",
      maturityDate: "2024-11-28T00:00:00",
      valueDate: "2022-11-28T00:00:00",
      initialAmount: 5000000,
      initialCurrency: dataPrezentacjaWaluty.PLN,
      clientPayInterestRate: "PLN_WIBOR_3M ",
      clientPayInterestRateBase: "ACT/365",
      interestRatePaymentType: "netto",
      collateralType: "LIMIT",
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      clientReceiveInterestRate: "5.1451%",
      clientReceiveInterestRateBase: "ACT/ACT",

      pricingAmount: 511.53,
      pricingCurrency: dataPrezentacjaWaluty.PLN,
      // pricingDate: calculateDate(0, 1, 0),

      schedule: [
        {
          observationNumber: 880811,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2022-11-24T00:00:00",
          interestRatePeriodBeginning: "2022-11-28T00:00:00",
          interestRatePeriodEnd: "2023-02-28T00:00:00",
          settlementDate: "2023-02-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880812,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2023-02-24T00:00:00",
          interestRatePeriodBeginning: "2023-02-28T00:00:00",
          interestRatePeriodEnd: "2023-05-29T00:00:00",
          settlementDate: "2023-05-29T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880813,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2023-05-25T00:00:00",
          interestRatePeriodBeginning: "2023-05-29T00:00:00",
          interestRatePeriodEnd: "2023-08-28T00:00:00",
          settlementDate: "2023-08-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880814,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2023-08-24T00:00:00",
          interestRatePeriodBeginning: "2023-08-28T00:00:00",
          interestRatePeriodEnd: "2023-11-28T00:00:00",
          settlementDate: "2023-11-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880815,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2023-11-24T00:00:00",
          interestRatePeriodBeginning: "2023-11-28T00:00:00",
          interestRatePeriodEnd: "2024-02-28T00:00:00",
          settlementDate: "2024-02-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880816,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2024-02-26T00:00:00",
          interestRatePeriodBeginning: "2024-02-28T00:00:00",
          interestRatePeriodEnd: "2024-05-28T00:00:00",
          settlementDate: "2024-05-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880817,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2024-05-24T00:00:00",
          interestRatePeriodBeginning: "2024-05-28T00:00:00",
          interestRatePeriodEnd: "2024-08-28T00:00:00",
          settlementDate: "2024-08-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880818,
          paymentSide: "Paying",
          rate: "PLN_WIBOR_3M",
          fixingDate: "2024-08-26T00:00:00",
          interestRatePeriodBeginning: "2024-08-28T00:00:00",
          interestRatePeriodEnd: "2024-11-28T00:00:00",
          settlementDate: "2024-11-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880819,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2022-11-28T00:00:00",
          interestRatePeriodBeginning: "2022-11-28T00:00:00",
          interestRatePeriodEnd: "2023-02-28T00:00:00",
          settlementDate: "2023-02-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880820,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2023-02-28T00:00:00",
          interestRatePeriodBeginning: "2023-02-28T00:00:00",
          interestRatePeriodEnd: "2023-05-29T00:00:00",
          settlementDate: "2023-05-29T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880821,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2023-05-29T00:00:00",
          interestRatePeriodBeginning: "2023-05-29T00:00:00",
          interestRatePeriodEnd: "2023-08-28T00:00:00",
          settlementDate: "2023-08-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880822,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2023-08-28T00:00:00",
          interestRatePeriodBeginning: "2023-08-28T00:00:00",
          interestRatePeriodEnd: "2023-11-28T00:00:00",
          settlementDate: "2023-11-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880823,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2023-11-28T00:00:00",
          interestRatePeriodBeginning: "2023-11-28T00:00:00",
          interestRatePeriodEnd: "2024-02-28T00:00:00",
          settlementDate: "2024-02-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880824,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2024-02-28T00:00:00",
          interestRatePeriodBeginning: "2024-02-28T00:00:00",
          interestRatePeriodEnd: "2024-05-28T00:00:00",
          settlementDate: "2024-05-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880825,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2024-05-28T00:00:00",
          interestRatePeriodBeginning: "2024-05-28T00:00:00",
          interestRatePeriodEnd: "2024-08-28T00:00:00",
          settlementDate: "2024-08-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
        {
          observationNumber: 880826,
          paymentSide: "Receiving",
          rate: "5.1451%",
          fixingDate: "2024-08-28T00:00:00",
          interestRatePeriodBeginning: "2024-08-28T00:00:00",
          interestRatePeriodEnd: "2024-11-28T00:00:00",
          settlementDate: "2024-11-28T00:00:00",
          currency: "PLN",
          amount: 5000000,
        },
      ],
    }),
  },
  {
    amount: 1000000,
    currency: dataPrezentacjaWaluty.EUR,
    side: "Sell",
    id: "MX115996",
    counterAmount: 4668500,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6685,
    type: "FXO",
    subtype: "OPVE",
    status: "All",
    transactionDate: calculateDate(0, -3, 0).toISOString(),
    settlementDate: calculateDate(0, -1, -3).toISOString(),
    isComplex: false,

    actualPricing: 3002,
    pricingCurrency: dataPrezentacjaWaluty.PLN,
    // pricingDate: calculateDate(0, 1, 0),

    details$: of({
      id: "MX115996",
      transactionType: "OPVE",
      hasConfirmation: true,
      optionType: "CALL",
      documentId: "FXO363172",
      attorney: "", //"MATEUSZ TEST",
      transactionDate: calculateDate(0, -3, 0).toISOString(), // "2023-02-22T00:00:00",
      expirationDate: calculateDate(0, -1, -3).toISOString(), // "2023-03-22T00:00:00",
      dueDate: calculateDate(0, -1, -1).toISOString(), // "2023-03-24T00:00:00",
      amountOne: 1000000,
      currencyOne: dataPrezentacjaWaluty.EUR,
      amountTwo: 4668500,
      currencyTwo: dataPrezentacjaWaluty.PLN,
      side: "S",
      type: "FXO",
      executionRate: 4.6685,
      currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
      referenceRate: "NBPFIX",
      barrierType: "",
      floorRate: 0,
      capRate: 0,
      collateralType: "LIMIT",
      collateralAmount: 0,
      premiumAmount: 21821.51,
      premiumCurrency: dataPrezentacjaWaluty.EUR,
      premiumPayDate: calculateDate(0, -1, -1).toISOString(), //"2023-02-24T00:00:00",
      settlementType: "DOST",
      schedule: [],
      accountOne: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
      accountTwo: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],

      // "accountOne": {
      //     "number": "49102055610000300203365681",
      //     "currency": "EUR",
      //     "isDefault": false,
      //     "isDeposit": false,
      //     "isForex": false,
      //     "isExternal": false
      // },
      // "accountTwo": {
      //     "number": "71102055610000300203380805",
      //     "currency": "PLN",
      //     "isDefault": false,
      //     "isDeposit": false,
      //     "isForex": false,
      //     "isExternal": false
      // },

      pricingAmount: 3002,
      pricingCurrency: dataPrezentacjaWaluty.PLN,
      // pricingDate: calculateDate(0, 1, 0),
    }),
  },
  {
    amount: 100,
    currency: "TONA",
    side: "Buy",
    id: "COP23901",
    counterAmount: 200,
    counterCurrency: dataPrezentacjaWaluty.USD,
    type: "FROPTION",
    subtype: "Paliwo lotnicze Jet Cargo CIF NWE",
    status: "All",
    transactionDate: calculateDate(0, -3, -7).toISOString(), //"2023-02-22T00:00:00",
    settlementDate: calculateDate(0, -2, 0).toISOString(), // "2024-02-26T00:00:00",
    isComplex: false,

    actualPricing: -75235,
    pricingCurrency: dataPrezentacjaWaluty.PLN,
    // pricingDate: calculateDate(0, 1, 0),

    details$: of({
      id: "COP23901",
      type: "FROPTION",
      hasConfirmation: true,
      commodity: "Paliwo lotnicze Jet Cargo CIF NWE",
      attorney: "", //"SZYMON KIM",
      transactionDate: calculateDate(0, -3, -7).toISOString(), //"2023-02-22T00:00:00",
      documentId: "COP23901",
      optionType: "CALL",
      expiryDate: calculateDate(0, -2, -4).toISOString(), //"2024-02-22T00:00:00",
      settlementDate: calculateDate(0, -2, 0).toISOString(), //"2024-02-26T00:00:00",
      currencyOneCommodity: "TONA",
      unit: "TONA",
      amount: 100,
      settlementCurrency: dataPrezentacjaWaluty.USD,
      side: "B",
      transactionType: "EURCOMMOPT",
      executionRate: 200,
      executionCurrency: dataPrezentacjaWaluty.USD,
      collateralType: "LIMIT",
      collateralAmount: 0,
      clientPayedOptionPremium: 20,
      clientReceivedOptionPremium: 20,
      clientPayedOptionPremiumCurrency: dataPrezentacjaWaluty.USD,
      clientReceivedOptionPremiumCurrency: dataPrezentacjaWaluty.USD,
      pricePaymentDate: calculateDate(0, -3, -5).toISOString(), //"2023-02-24T00:00:00",
      observations: [],
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.USD)[0],
      // "account": {
      //     "number": "73102055610000390203380813",
      //     "currency": "USD",
      //     "isDefault": false,
      //     "isDeposit": false,
      //     "isForex": false,
      //     "isExternal": false
      // },

      pricingAmount: -75235,
      pricingCurrency: dataPrezentacjaWaluty.PLN,
      // pricingDate: calculateDate(0, 1, 0),
    }),
  },
];

// LINKED DEALS
// roll_back
dataExchangeArray[4].details$ = of(
  {
    id: "120000015",
    transactionDate: calculateDate(0, 0, -14),
    subtype: "",
    side: "Sell", // "Sell" | "Buy"
    amount: 170000.0,
    currency: dataPrezentacjaWaluty.EUR,
    counterAmount: 788834.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.6402,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 14).toISOString(),
    openBalance: 0,
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    documentId: "",
    currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
    collateral: {
      type: "Limit", //  "Limit" | "Deposit" | "AmountBlock"
    },
    product: PRODUCT_TYPES.forward,
    linkedDeals: [
      // CZY COŚ TUTAJ ???
      dataExchangeArray[5],
      dataExchangeArray[6],
    ],
    pvp: {
      // << -- zmiana
      value: false,
      visible: false,
    },
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
    account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
    counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
    // orderId?: number,
    linkedTransactionNr: "",
    netSettlement: false,
    referenceRate: "", // ["Market Rate","NBP Fixing"]
    ndfModifiable: true,
    // netSettlementAmount?: number,
    netSettlementCurrency: dataPrezentacjaWaluty.PLN,
    closeVisible: false,
    rollVisible: true,
    rollData: {
      rollDisabledReason: "Deal_Leg_is_a_Closing_deal_or_ManRollCloseDeal_or_Penalty_Closing_deal_",
      isHistoricalRollBackEnabled: false,
      isHistoricalRollOverEnabled: false,
      isMarketRollBackEnabled: false,
      isMarketRollOverEnabled: false,
      rateTypes: ["Historical", "Market"],
    },
    // dpwReference: "SR22SN00060388DR",

    attorney: "", // << -- zmiana
    openAmountCurrencyFTOOne: "", // << -- zmiana
    openAmountCurrencyFTOTwo: "", // << -- zmiana
    buyPricingCurrency: "", // << -- zmiana
    sellPricingCurrency: "", // << -- zmiana
    pricingCurrency: "", // << -- zmiana
    settlementType: "", // << -- zmiana
    hasConfirmation: true, // << -- zmiana
    type: "FX", // << -- zmiana
  } as ExchangeDetails // as BaseExchangeDetail
);
dataExchangeArray[5].details$ = of(
  {
    id: "120000016",
    transactionDate: calculateDate(0, 0, -14),
    subtype: "HistoricalRollback",

    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    documentId: "",
    currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
    collateral: {
      type: "Limit", //  "Limit" | "Deposit" | "AmountBlock"
    },
    product: PRODUCT_TYPES.swap,
    linkedDeals: [
      dataExchangeArray[4],
      // dataExchangeArray[5],
      // dataExchangeArray[6]
    ],
    pvp: {
      // << -- zmiana
      value: false,
      visible: false,
    },
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS

    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"] ,
    nearLeg: {
      amount: 170000,
      currency: dataPrezentacjaWaluty.EUR,
      counterAmount: 785604.0,
      counterCurrency: dataPrezentacjaWaluty.PLN,
      status: "Unsettled",
      side: "Sell",
      settlementDate: calculateDate(0, 0, 7).toISOString(),
      rate: 4.6212,
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
      counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      openBalance: 170000, // było 0
      closeVisible: true,
      rollVisible: true,
      rollData: {
        // rollDisabledReason?: string,
        isHistoricalRollBackEnabled: true,
        isHistoricalRollOverEnabled: true,
        isMarketRollBackEnabled: true,
        isMarketRollOverEnabled: true,
        rateTypes: ["Historical", "Market"],
      },

      buyPricingCurrency: "", // << -- zmiana
      sellPricingCurrency: "", // << -- zmiana
    },
    farLeg: {
      amount: 170000,
      currency: dataPrezentacjaWaluty.EUR,
      counterAmount: 788834.0,
      counterCurrency: dataPrezentacjaWaluty.PLN,
      status: "Unsettled",
      side: "Buy",
      settlementDate: calculateDate(0, 0, 14).toISOString(),
      rate: 4.6402,
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[0],
      counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      openBalance: 0,
      closeVisible: false,
      rollVisible: true,
      rollData: {
        rollDisabledReason: "Deal_Leg_is_completely_closed",
        isHistoricalRollBackEnabled: false,
        isHistoricalRollOverEnabled: false,
        isMarketRollBackEnabled: false,
        isMarketRollOverEnabled: false,
        rateTypes: ["Historical", "Market"],
      },

      buyPricingCurrency: "", // << -- zmiana
      sellPricingCurrency: "", // << -- zmiana
    },
    linkedTransactionNr: "120000015",

    attorney: "", // << -- zmiana
    openAmountCurrencyFTOOne: "", // << -- zmiana
    openAmountCurrencyFTOTwo: "", // << -- zmiana
    buyPricingCurrency: "", // << -- zmiana
    sellPricingCurrency: "", // << -- zmiana
    pricingCurrency: "", // << -- zmiana
    settlementType: "", // << -- zmiana
    hasConfirmation: true, // << -- zmiana
    type: "FXSWAP", // << -- zmiana
  } as SwapDetails // as BaseExchangeDetail
);
dataExchangeArray[6].details$ = dataExchangeArray[5].details$;
// roll_over
dataExchangeArray[7].details$ = of(
  {
    id: "12102199",
    transactionDate: calculateDate(0, 0, 0),
    // subtype: "NDF",
    subtype: "",
    side: "Sell", // "Sell" | "Buy"
    amount: 36000.0,
    currency: dataPrezentacjaWaluty.USD,
    counterAmount: 158256.0,
    counterCurrency: dataPrezentacjaWaluty.PLN,
    rate: 4.396,
    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
    settlementDate: calculateDate(0, 0, 10).toISOString(),
    openBalance: 0,
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    documentId: "",
    currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
    collateral: {
      type: "Limit", //  "Limit" | "Deposit" | "AmountBlock"
    },
    product: PRODUCT_TYPES.forward,
    linkedDeals: [dataExchangeArray[8], dataExchangeArray[9]],
    pvp: {
      // << -- zmiana
      value: false,
      visible: false,
    },
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
    account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.USD)[0],
    counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
    // orderId?: number,
    linkedTransactionNr: "",
    netSettlement: false,
    referenceRate: "", // ["Market Rate","NBP Fixing"]
    ndfModifiable: true,
    // netSettlementAmount?: number,
    netSettlementCurrency: dataPrezentacjaWaluty.PLN,
    // closeVisible: true,
    // rollVisible: true,
    // rollData: {
    //     rollDisabledReason: "",
    //     isHistoricalRollBackEnabled: true,
    //     isHistoricalRollOverEnabled: true,
    //     isMarketRollBackEnabled: true,
    //     isMarketRollOverEnabled: true,
    //     rateTypes: [
    //         "Historical",
    //         "Market"
    //     ],
    // },
    closeVisible: false,
    rollVisible: true,
    rollData: {
      rollDisabledReason: "Deal_Leg_is_a_Closing_deal_or_ManRollCloseDeal_or_Penalty_Closing_deal_",
      isHistoricalRollBackEnabled: false,
      isHistoricalRollOverEnabled: false,
      isMarketRollBackEnabled: false,
      isMarketRollOverEnabled: false,
      rateTypes: ["Historical", "Market"],
    },
    // dpwReference: "SR22SN00060388DR",

    attorney: "", // << -- zmiana
    openAmountCurrencyFTOOne: "", // << -- zmiana
    openAmountCurrencyFTOTwo: "", // << -- zmiana
    buyPricingCurrency: "", // << -- zmiana
    sellPricingCurrency: "", // << -- zmiana
    pricingCurrency: "", // << -- zmiana
    settlementType: "", // << -- zmiana
    hasConfirmation: true, // << -- zmiana
    type: "", // << -- zmiana
  } as ExchangeDetails // as BaseExchangeDetail
);
dataExchangeArray[8].details$ = of(
  {
    id: "12102200",
    // transactionDate: calculateDate(0, 0, 2),
    transactionDate: calculateDate(0, 0, -1), // zmiana 2023-06-20
    subtype: "HistoricalRollover",

    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    documentId: "pdf_12102200",
    currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
    collateral: {
      type: "Limit", //  "Limit" | "Deposit" | "AmountBlock"
    },
    product: PRODUCT_TYPES.swap,
    linkedDeals: [
      dataExchangeArray[7],
      // dataExchangeArray[8],
      // dataExchangeArray[9]
    ],
    pvp: {
      // << -- zmiana
      value: false,
      visible: false,
    },
    //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS

    status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"] ,
    nearLeg: {
      amount: 36000,
      currency: dataPrezentacjaWaluty.USD,
      counterAmount: 158256.0,
      counterCurrency: dataPrezentacjaWaluty.PLN,
      status: statuses[2],
      side: "Buy",
      settlementDate: calculateDate(0, 0, 10).toISOString(),
      rate: 4.396,
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.USD)[0],
      counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      openBalance: 36000,
      closeVisible: false,
      rollVisible: true,
      rollData: {
        rollDisabledReason: "Deal_Leg_is_completely_closed",
        isHistoricalRollBackEnabled: false,
        isHistoricalRollOverEnabled: false,
        isMarketRollBackEnabled: false,
        isMarketRollOverEnabled: false,
        rateTypes: ["Historical", "Market"],
      },
      buyPricingCurrency: "", // << -- zmiana
      sellPricingCurrency: "", // << -- zmiana
    },
    farLeg: {
      amount: 36000,
      currency: dataPrezentacjaWaluty.USD,
      counterAmount: 158436.0,
      counterCurrency: dataPrezentacjaWaluty.PLN,
      status: statuses[2],
      side: "Sell",
      settlementDate: calculateDate(0, 0, 20).toISOString(),
      rate: 4.401,
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.USD)[0],
      counterAccount: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      openBalance: 36000,
      closeVisible: true,
      rollVisible: true,
      rollData: {
        // rollDisabledReason?: string,
        isHistoricalRollBackEnabled: true,
        isHistoricalRollOverEnabled: true,
        isMarketRollBackEnabled: true,
        isMarketRollOverEnabled: true,
        rateTypes: ["Historical", "Market"],
      },
      buyPricingCurrency: "", // << -- zmiana
      sellPricingCurrency: "", // << -- zmiana
    },
    linkedTransactionNr: dataExchangeArray[7].id,
    attorney: "", // << -- zmiana
    openAmountCurrencyFTOOne: "", // << -- zmiana
    openAmountCurrencyFTOTwo: "", // << -- zmiana
    buyPricingCurrency: "", // << -- zmiana
    sellPricingCurrency: "", // << -- zmiana
    pricingCurrency: "", // << -- zmiana
    settlementType: "", // << -- zmiana
    hasConfirmation: true, // << -- zmiana
    type: "", // << -- zmiana
  } as SwapDetails // as BaseExchangeDetail
);
dataExchangeArray[9].details$ = dataExchangeArray[8].details$;

let dataExchange: any = {};
let dataExchangeConsents: string[] = [];
const dataExchangeSet = new Set();
let dataExchangeRate = 0;
let dataExchangeRateSwapPairs = false;
let dataExchangeRateFar = 0;
let dataExchangeNetSettlementAmount = 0;
let dataExchangeIsFisible = true;
let dataExchangeCollateralAmount = 0;
const dataExchangeCollateralAmountTenorsProcentage = {
  // 0: 0,
  // 7: 3.4,
  // 14: 4.8,
  // 36: 5.9,
  // 97: 8.3,
  // 190: 11,
  // 372: 17,
  // 740: 24,
  // 1105: 30,
  // 1471: 33,
  // 1835: 35,
  // 2566: 48,
  // 3662: 63,
  //--------------------
  0: 3.4,
  7: 4.8,
  14: 5.9,
  36: 8.3,
  97: 11,
  190: 17,
  372: 24,
  740: 30,
  1105: 33,
  1471: 35,
  1835: 48,
  2566: 63,
};

const caluclateCollateralAmountTenorsProcentage = (daysFromToday: number): number => {
  let dataToReturn = 0;

  const procentageTab = Object.entries(dataExchangeCollateralAmountTenorsProcentage);

  procentageTab.forEach((el, id) => {
    if (+el[0] === daysFromToday) {
      dataToReturn = el[1];
    } else {
      if (id !== procentageTab.length - 1) {
        if (daysFromToday > +el[0] && daysFromToday <= +procentageTab[id + 1][0]) {
          dataToReturn = el[1];
        }
      }
    }
  });
  if (daysFromToday > +procentageTab[procentageTab.length - 1][0]) {
    dataToReturn = +procentageTab[procentageTab.length - 1][1];
  }

  return dataToReturn;
};

let dataExchangeMultiFxSeries: any[] = [];
let dataExchangeMultiFxTotal = 0;
let dataExchangeMultiFxCounterTotal = 0;

// const dataFastDealWidgets: any | { string: Widget } = {};

let dataDepositID = 10077099;
let dataDeposit: any = {};
let dataDepositRate = 0;
let dataDepositIsFisible = true;

let dataInvestmentsDeposit: any = {};
const investmentDepositArray: any[] = [
  // Deposit[] = [
  {
    id: "524043",
    amount: 600000,
    currency: dataPrezentacjaWaluty.PLN,
    currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
    standardInterestRate: 1.7,
    maximalInterestRate: 5.9,
    status: "Unsettled", // "Settled", "Unsettled", "Terminated"
    startDate: calculateDate(0, 0, -7).toISOString(),
    endDate: calculateDate(0, 0, 24).toISOString(),
    displayDetails: false,
    details$: {
      id: "524043",
      amount: 600000,
      currency: dataPrezentacjaWaluty.PLN,
      currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
      standardInterestRate: 1.7,
      maximalInterestRate: 5.9,
      status: "Unsettled", // "Settled", "Unsettled", "Terminated"
      startDate: calculateDate(0, 0, -7).toISOString(),
      endDate: calculateDate(0, 0, 24).toISOString(),
      //....
      terminationFee: 3917.28,
      rateFrom: 4.285,
      rateTo: 4.385,
      fixing: "",
      fixingDate: calculateDate(0, 0, 22).toISOString(),
      standardInterest:
        (((600000 * 1.7) / 100) *
          ((calculateDate(0, 0, 24).getTime() - calculateDate(0, 0, -7).getTime()) / 86400000)) /
        365,
      maximalInterest:
        (((600000 * 5.9) / 100) *
          ((calculateDate(0, 0, 24).getTime() - calculateDate(0, 0, -7).getTime()) / 86400000)) /
        365,
      account: dataAccount[0],
      hasConfirmation: true,
      documentId: "012031201230213asfdafsd",
      // terminationDate?: string;
    },
    executedWithMaxInterest: false,
  },
  {
    id: "524026",
    amount: 100000,
    currency: dataPrezentacjaWaluty.EUR,
    currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
    standardInterestRate: 1.0,
    maximalInterestRate: 3.5,
    status: "Unsettled", // "Settled", "Unsettled", "Terminated"
    startDate: calculateDate(0, -1, 0).toISOString(),
    endDate: calculateDate(0, 2, 0).toISOString(),
    displayDetails: false,
    details$: {
      id: "524026",
      amount: 100000,
      currency: dataPrezentacjaWaluty.EUR,
      currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
      standardInterestRate: 1.0,
      maximalInterestRate: 3.5,
      status: "Unsettled", // "Settled", "Unsettled", "Terminated"
      startDate: calculateDate(0, -1, 0).toISOString(),
      endDate: calculateDate(0, 2, 0).toISOString(),
      //....
      terminationFee: 744.8,
      rateFrom: 4.289,
      rateTo: 4.358,
      fixing: "",
      fixingDate: calculateDate(0, 1, 28).toISOString(),
      standardInterest:
        (((100000 * 1.0) / 100) *
          (Math.abs(calculateDate(0, -1, 0).getTime() - calculateDate(0, 2, 0).getTime()) /
            86400000)) /
        365,
      maximalInterest:
        (((100000 * 3.5) / 100) *
          (Math.abs(calculateDate(0, -1, 0).getTime() - calculateDate(0, 2, 0).getTime()) /
            86400000)) /
        365,
      account: dataAccount[2],
      hasConfirmation: true,
      documentId: "012031201230213asfdafsd",
      // terminationDate?: string;
    },
    executedWithMaxInterest: false,
  },
  {
    id: "565781",
    amount: 1000000,
    currency: dataPrezentacjaWaluty.USD,
    currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
    standardInterestRate: 2.1,
    maximalInterestRate: 3.38,
    status: "Settled", // "Settled", "Unsettled", "Terminated"
    startDate: calculateDate(0, -1, 0).toISOString(),
    endDate: calculateDate(0, 0, 0).toISOString(),
    displayDetails: false,
    details$: {
      id: "565781",
      amount: 1000000,
      currency: dataPrezentacjaWaluty.USD,
      currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
      standardInterestRate: 2.1,
      maximalInterestRate: 3.38,
      status: "Settled", // "Settled", "Unsettled", "Terminated"
      startDate: calculateDate(0, -1, 0).toISOString(),
      endDate: calculateDate(0, 0, 0).toISOString(),
      //....
      terminationFee: 6105.35,
      rateFrom: 3.87,
      rateTo: 4.001,
      fixing: "3.9991",
      fixingDate: calculateDate(0, 0, -2).toISOString(),
      standardInterest:
        (((1000000 * 2.1) / 100) *
          (Math.abs(calculateDate(0, -1, 0).getTime() - calculateDate(0, 0, 0).getTime()) /
            86400000)) /
        365,
      maximalInterest:
        (((1000000 * 3.38) / 100) *
          (Math.abs(calculateDate(0, -1, 0).getTime() - calculateDate(0, 0, 0).getTime()) /
            86400000)) /
        365,
      account: dataAccount[4],
      hasConfirmation: true,
      documentId: "012031201230213asfdafsd",
      // terminationDate?: string;
    },
    executedWithMaxInterest: false,
  },
];

let basket2MaxDate = calculateDate(0, 0, 13).toISOString().split("T")[0];
const basket3MinDate = calculateDate(0, 0, 14).toISOString().split("T")[0];
let basket3MaxDate = calculateDate(0, 1, -1).toISOString().split("T")[0];
const basket4MinDate = calculateDate(0, 1, 0).toISOString().split("T")[0];
let basket4MaxDate = calculateDate(0, 3, -1).toISOString().split("T")[0];
const basket5MinDate = calculateDate(0, 3, 0).toISOString().split("T")[0];
let basket5MaxDate = calculateDate(0, 6, -1).toISOString().split("T")[0];
const basket6MinDate = calculateDate(0, 6, 0).toISOString().split("T")[0];
if (basket2MaxDate === basket3MinDate) {
  basket2MaxDate = calculatePrevFreeDate(basket2MaxDate);
}
if (basket3MaxDate === basket4MinDate) {
  basket3MaxDate = calculatePrevFreeDate(basket3MaxDate);
}
if (basket4MaxDate === basket5MinDate) {
  basket4MaxDate = calculatePrevFreeDate(basket4MaxDate);
}
if (basket5MaxDate === basket6MinDate) {
  basket5MaxDate = calculatePrevFreeDate(basket5MaxDate);
}
const premiuDepostiOffers = [
  {
    id: "BB6F0C47-3CFB-4134-A228-06D4FA83B301",
    rate: 0.0235,
    band: 999999.99,
    basket: {
      id: "Basket 1",
      minDate: calculateDate(0, 0, 1).toISOString(),
      maxDate: calculateDate(0, 0, 1).toISOString(),
      names: [
        {
          name: "Lokata 1D",
          language: "EN",
        },
        {
          name: "Lokata 1D",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "150561DF-88CA-4A33-91E6-372D87A6D0E6",
    rate: 0.028,
    band: 4999999.99,
    basket: {
      id: "Basket 1",
      minDate: calculateDate(0, 0, 1).toISOString(),
      maxDate: calculateDate(0, 0, 1).toISOString(),
      names: [
        {
          name: "Lokata 1D",
          language: "EN",
        },
        {
          name: "Lokata 1D",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "FA1D7F6B-DEAA-4886-9DF9-9CB50ACD72B3",
    rate: 0.0315,
    band: 25000000,
    basket: {
      id: "Basket 1",
      minDate: calculateDate(0, 0, 1).toISOString(),
      maxDate: calculateDate(0, 0, 1).toISOString(),
      names: [
        {
          name: "Lokata 1D",
          language: "EN",
        },
        {
          name: "Lokata 1D",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "1E991436-E2E5-4789-8884-A4DE61F470A3",
    rate: 0.0242,
    band: 999999.99,
    basket: {
      id: "Basket 2",
      minDate: calculateDate(0, 0, 2).toISOString(),
      maxDate: basket2MaxDate,
      names: [
        {
          name: "Lokata od 2D do 2W",
          language: "EN",
        },
        {
          name: "Lokata od 2D do 2W",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "D1C39A44-20DE-4BD3-924D-6493F8EAADE4",
    rate: 0.0317,
    band: 4999999.99,
    basket: {
      id: "Basket 2",
      minDate: calculateDate(0, 0, 2).toISOString(),
      maxDate: basket2MaxDate,
      names: [
        {
          name: "Lokata od 2D do 2W",
          language: "EN",
        },
        {
          name: "Lokata od 2D do 2W",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "54D738A2-13E1-4005-A30F-ADAD216C7152",
    rate: 0.0322,
    band: 25000000,
    basket: {
      id: "Basket 2",
      minDate: calculateDate(0, 0, 2).toISOString(),
      maxDate: basket2MaxDate,
      names: [
        {
          name: "Lokata od 2D do 2W",
          language: "EN",
        },
        {
          name: "Lokata od 2D do 2W",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "275F60C4-2CC8-4711-AB86-EB97DAA33204",
    rate: 0.029,
    band: 999999.99,
    basket: {
      id: "Basket 3",
      minDate: calculateDate(0, 0, 14).toISOString(),
      maxDate: basket3MaxDate,
      names: [
        {
          name: "Lokata od 2W do 1M",
          language: "EN",
        },
        {
          name: "Lokata od 2W do 1M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "F1CF5C8A-BA3B-41CF-B361-A554F2CC3B71",
    rate: 0.033,
    band: 4999999.99,
    basket: {
      id: "Basket 3",
      minDate: calculateDate(0, 0, 14).toISOString(),
      maxDate: basket3MaxDate,
      names: [
        {
          name: "Lokata od 2W do 1M",
          language: "EN",
        },
        {
          name: "Lokata od 2W do 1M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "558ACE85-8185-40F1-832C-AED65EDFD62C",
    rate: 0.034,
    band: 25000000,
    basket: {
      id: "Basket 3",
      minDate: calculateDate(0, 0, 14).toISOString(),
      maxDate: basket3MaxDate,
      names: [
        {
          name: "Lokata od 2W do 1M",
          language: "EN",
        },
        {
          name: "Lokata od 2W do 1M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "9AE044BD-75BD-4993-BD53-9307BFC9BB32",
    rate: 0.0289,
    band: 999999.99,
    basket: {
      id: "Basket 4",
      minDate: calculateDate(0, 1, 0).toISOString(),
      maxDate: basket4MaxDate,
      names: [
        {
          name: "Lokata od 1M do 3M",
          language: "EN",
        },
        {
          name: "Lokata od 1M do 3M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "29713812-A2D2-4E4D-BF08-690F4DEBFAF4",
    rate: 0.0329,
    band: 4999999.99,
    basket: {
      id: "Basket 4",
      minDate: calculateDate(0, 1, 0).toISOString(),
      maxDate: basket4MaxDate,
      names: [
        {
          name: "Lokata od 1M do 3M",
          language: "EN",
        },
        {
          name: "Lokata od 1M do 3M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "2723CE63-D13C-42F5-BC87-6C0A6A01F720",
    rate: 0.0339,
    band: 25000000,
    basket: {
      id: "Basket 4",
      minDate: calculateDate(0, 1, 0).toISOString(),
      maxDate: basket4MaxDate,
      names: [
        {
          name: "Lokata od 1M do 3M",
          language: "EN",
        },
        {
          name: "Lokata od 1M do 3M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "B2AD7B97-DB0B-4DAE-AD5C-4937093CBCF5",
    rate: 0.0294,
    band: 999999.99,
    basket: {
      id: "Basket 5",
      minDate: calculateDate(0, 3, 0).toISOString(),
      maxDate: basket5MaxDate,
      names: [
        {
          name: "Lokata od 3M do 6M",
          language: "EN",
        },
        {
          name: "Lokata od 3M do 6M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "71C6C82D-2F0D-48E0-A5E0-D5D39C1EC6C6",
    rate: 0.0324,
    band: 4999999.99,
    basket: {
      id: "Basket 5",
      minDate: calculateDate(0, 3, 0).toISOString(),
      maxDate: basket5MaxDate,
      names: [
        {
          name: "Lokata od 3M do 6M",
          language: "EN",
        },
        {
          name: "Lokata od 3M do 6M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "29C87A8A-142B-4B6B-81BA-4A532DCE3A3C",
    rate: 0.034,
    band: 25000000,
    basket: {
      id: "Basket 5",
      minDate: calculateDate(0, 3, 0).toISOString(),
      maxDate: basket5MaxDate,
      names: [
        {
          name: "Lokata od 3M do 6M",
          language: "EN",
        },
        {
          name: "Lokata od 3M do 6M",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "225772BB-76D3-4F77-BDC5-84E42F266511",
    rate: 0.0268,
    band: 999999.99,
    basket: {
      id: "Basket 6",
      minDate: calculateDate(0, 6, 0).toISOString(),
      maxDate: calculateDate(1, 0, 0).toISOString(),
      names: [
        {
          name: "Lokata od 6M do 1Y",
          language: "EN",
        },
        {
          name: "Lokata od 6M do 1Y",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "A27B1F17-DA6A-4EEA-9308-904BFD36AC9C",
    rate: 0.0308,
    band: 4999999.99,
    basket: {
      id: "Basket 6",
      minDate: calculateDate(0, 6, 0).toISOString(),
      maxDate: calculateDate(1, 0, 0).toISOString(),
      names: [
        {
          name: "Lokata od 6M do 1Y",
          language: "EN",
        },
        {
          name: "Lokata od 6M do 1Y",
          language: "PL",
        },
      ],
    },
  },
  {
    id: "770A9429-E2E3-4A37-98E9-F685B4A1E17F",
    rate: 0.0328,
    band: 25000000,
    basket: {
      id: "Basket 6",
      minDate: calculateDate(0, 6, 0).toISOString(),
      maxDate: calculateDate(1, 0, 0).toISOString(),
      names: [
        {
          name: "Lokata od 6M do 1Y",
          language: "EN",
        },
        {
          name: "Lokata od 6M do 1Y",
          language: "PL",
        },
      ],
    },
  },
];
const premiumDepositHistory = [
  {
    amount: 500000,
    currency: dataPrezentacjaWaluty.PLN,
    startDate: calculateDate(0, -1, 0).toISOString(),
    endDate: calculateDate(0, 2, 0),
    interestRate: 2.64,
    id: "10077049",
    interestAmount:
      (((500000 * 2.64) / 100) *
        ((calculateDate(0, 2, 0).getTime() - calculateDate(0, -1, 0).getTime()) / 86400000)) /
      365,
    settlementAmount:
      500000 +
      (((500000 * 2.64) / 100) *
        ((calculateDate(0, 2, 0).getTime() - calculateDate(0, -1, 0).getTime()) / 86400000)) /
        365,

    details$: {
      amount: 500000,
      currency: dataPrezentacjaWaluty.PLN,
      startDate: calculateDate(0, -1, 0).toISOString(),
      endDate: calculateDate(0, 2, 0),
      interestRate: 2.64,
      basket: {
        id: "Basket 4",
        minDate: calculateDate(0, 1, 0).toISOString(),
        maxDate: basket4MaxDate,
        names: [
          {
            name: "Lokata od 1M do 3M",
            language: "EN",
          },
          {
            name: "Lokata od 1M do 3M",
            language: "PL",
          },
        ],
      },
      id: "10077049",

      dealDate: calculateDate(0, -1, 0),
      interestAmount:
        (((500000 * 2.64) / 100) *
          ((calculateDate(0, 2, 0).getTime() - calculateDate(0, -1, 0).getTime()) / 86400000)) /
        365,
      amountWithInterest:
        500000 +
        (((500000 * 2.64) / 100) *
          ((calculateDate(0, 2, 0).getTime() - calculateDate(0, -1, 0).getTime()) / 86400000)) /
          365,
      account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[0],
      closeVisible: true, // false
      isTerminated: false,

      status: "", // << --- zmiana
      hasConfirmation: true, // << --- zmiana
      documentId: "", // << --- zmiana
      type: "", // << --- zmiana
      depositType: "Premium", // Premium
    },

    status: "Unsettled", // "Unsettled" | "All" | "Settled" | "Undefined" | "Terminated"
    displayDetails: false,
    type: "Premium", // Premium
  },
];

const PrezentacjaDane: any = {
  //------------------------------------
  //------------------ action
  //------------------------------------
  "/action": {
    POST: (data: any) => {
      // console.warn("/action POST | DATA = ", data.body.data);
    },
  },
  //------------------------------------
  //------------------ documents
  //------------------------------------
  "/documents": {
    GET: () => {
      return {
        regulations: [
          // {
          //     title: string;
          //     link: string;
          // }
        ],
        instructions: [
          // {
          //     title: string;
          //     link: string;
          // }
        ],
      };
    },
  },
  //------------------------------------
  //------------------ settings
  //------------------------------------
  "/settings/prefixes": {
    GET: (): Prefix[] => {
      return [
        {
          code: "+43",
          countryCode: "AT",
        },
        {
          code: "+32",
          countryCode: "BE",
        },
        {
          code: "+359",
          countryCode: "BG",
        },
        {
          code: "+375",
          countryCode: "BY",
        },
        {
          code: "+1",
          countryCode: "CA",
        },
        {
          code: "+41",
          countryCode: "CH",
        },
        {
          code: "+357",
          countryCode: "CY",
        },
        {
          code: "+420",
          countryCode: "CZ",
        },
        {
          code: "+49",
          countryCode: "DE",
        },
        {
          code: "+45",
          countryCode: "DK",
        },
        {
          code: "+372",
          countryCode: "EE",
        },
        {
          code: "+34",
          countryCode: "ES",
        },
        {
          code: "+358",
          countryCode: "FI",
        },
        {
          code: "+33",
          countryCode: "FR",
        },
        {
          code: "+44",
          countryCode: "GB",
        },
        {
          code: "+30",
          countryCode: "GR",
        },
        {
          code: "+385",
          countryCode: "HR",
        },
        {
          code: "+36",
          countryCode: "HU",
        },
        {
          code: "+353",
          countryCode: "IE",
        },
        {
          code: "+972",
          countryCode: "IL",
        },
        {
          code: "+354",
          countryCode: "IS",
        },
        {
          code: "+39",
          countryCode: "IT",
        },
        {
          code: "+370",
          countryCode: "LT",
        },
        {
          code: "+352",
          countryCode: "LU",
        },
        {
          code: "+371",
          countryCode: "LV",
        },
        {
          code: "+31",
          countryCode: "NL",
        },
        {
          code: "+47",
          countryCode: "NO",
        },
        {
          code: "+48",
          countryCode: "PL",
        },
        {
          code: "+351",
          countryCode: "PT",
        },
        {
          code: "+40",
          countryCode: "RO",
        },
        {
          code: "+7",
          countryCode: "RU",
        },
        {
          code: "+46",
          countryCode: "SE",
        },
        {
          code: "+386",
          countryCode: "SI",
        },
        {
          code: "+421",
          countryCode: "SK",
        },
        {
          code: "+378",
          countryCode: "SM",
        },
        {
          code: "+90",
          countryCode: "TR",
        },
        {
          code: "+380",
          countryCode: "UA",
        },
        {
          code: "+1",
          countryCode: "US",
        },
      ];
    },
  },
  "/settings/tenors": {
    // te tutaj muszą być pokryte w /exchange/tenors i /deposit/tenors
    GET: (data: any): string[] => {
      const tenorsData = data.params.get("market");
      let dataToReturn: string[] = [];

      switch (tenorsData) {
        case "fx":
          dataToReturn = [
            "TOD",
            "TOM",
            "SPOT",
            "SN",
            "SW",
            "2W",
            "3W",
            "1M",
            "2M",
            "3M",
            "6M",
            "9M",
            "12M",
          ];
          break;
        case "mm":
          dataToReturn = [
            "ON",
            "TN",
            "1D",
            "SPOT",
            "1W",
            "2W",
            "3W",
            "1M",
            "2M",
            "3M",
            "4M",
            "5M",
            "6M",
            "9M",
            "12M",
          ];
          break;
      }
      return dataToReturn;
    },
  },
  //------------------------------------
  //------------------ browser
  //-----------------------------------
  "/browser/config": {
    GET: (): Config => {
      return {
        // ...defaultConfig,
        logging: {
          default: "Debug",
        },
        http: {
          retries: 2,
          timeout: 30000,
        },
        indicatives: {
          pollingInterval: 10,
          pairLimit: 8,
        },
        infoPhone: "500 300 400",
        links: {
          forwardRisk:
            "https://www.pkobp.pl/korporacje-i-samorzady/produkty-skarbowe/ryzyko-walutowe-zabezpieczenia/fx-forward/",
          swapRisk:
            "https://www.pkobp.pl/korporacje-i-samorzady/produkty-skarbowe/ryzyko-walutowe-zabezpieczenia/fx-swap/",
          safety: {
            ipko: "https://www.pkobp.pl/klienci-indywidualni/bankowosc-elektroniczna/bezpieczenstwo-bankowosci-elektronicznej/",
            ipkobiznes:
              "https://www.pkobp.pl/bankowosc-elektroniczna/ipko-biznes/bezpieczna-obsluga/",
          },
          help: {
            ipko: "https://www.pkobp.pl/bankowosc-elektroniczna/ipko-dealer/",
            ipkobiznes: "https://www.pkobp.pl/bankowosc-elektroniczna/ipko-dealer/",
          },
          contact: {
            ipko: "https://www.pkobp.pl/klienci-indywidualni/bankowosc-elektroniczna/serwis-telefoniczny/",
            ipkobiznes: "https://www.pkobp.pl/kontakt/#kontakt-dla-przedsiebiorstw-i-korporacji/",
          },
        },
      };
    },
  },
  "/browser/log": {
    POST: () => {
      return {
        level: 0,
        source: "",
        message: "",
      };
    },
  },
  //------------------------------------
  //------------------ contracts | STRONA GŁÓWNA i DIGITALIZACJA
  // JEŚLI BĘDĄ BŁĘDY ŁADOWANIA to trzeba zmienic 'CustomerService' metodę 'set' 'tap' na router:ROuter i navigate
  //-----------------------------------

  "/contracts/client-active": {
    GET: (): Application[] => {
      let isUserDigitalizacja = false;
      let isUserDigitalizacjaJDG = false;
      const userPid = sessionStorage.getItem("demoPid");
      if (userPid === dataUserDigitalizacja.customerPid) {
        isUserDigitalizacja = true;
      }
      if (userPid === dataUserJDG.customerPid) {
        isUserDigitalizacjaJDG = true;
      }
      return isUserDigitalizacja || isUserDigitalizacjaJDG
        ? []
        : isContractsCanceled === true
        ? []
        : [
            {
              number: "999888777",
              nemoId: "999888777",
              clientAName: dataUserTransakcyjny.customerName,
              clientAPid: dataUserTransakcyjny.customerPid,
              clientBName: dataUserTransakcyjny.customerName,
              clientBPid: dataUserTransakcyjny.customerPid,
              type: "URZ", // "URN" | "URZ" | "SPOT1" | "JDGSPOT"
              status: isContractsInClientActiveSign ? "ContractSigned" : "ReadyForSigning",
              /*
              "Cancelled": "Rejected": "Branch": "New": "ReadyForSigning": "ReadyForSigningI":  "Verification": "Realization": "Accepted": "ContractSigned": "Completed": "NotRecognized": "SchemaChange": "ErrorGate": "NewSuspended":"RealizationJdg": "RealizationSuspended"
              */
              cancelVisible: isContractsInClientActiveSign ? false : true,
              signVisible: isContractsInClientActiveSign ? false : true,
              methodOfSigning: "Online",
              isInstitutional: true,
            },
          ];
    },
  },
  "/contracts/details": {
    GET: () => {
      // ApplicationDetailsResult
      return {
        applicationDetails: {
          applicationId: 105522,
          nemoId: "999888777",
          type: "URZ", // "URN" | "URZ" | "SPOT1" | "JDGSPOT"
          statusGate: isContractsInClientActiveSign ? "ContractSigned" : "ReadyForSigning",
          /*
          "Cancelled": "Rejected": "Branch": "New": "ReadyForSigning": "ReadyForSigningI":  "Verification": "Realization": "Accepted": "ContractSigned": "Completed": "NotRecognized": "SchemaChange": "ErrorGate": "NewSuspended":"RealizationJdg": "RealizationSuspended"
          */
          legalRepresentation: [
            {
              signatureStatus: "NotSigned",
              id: "CfDJ8OCS2uq27RtGnlWIGH6E27fGebIS6oMzTu7VqGwvwlyw6UIio4XrScRky83g8LVi-a8EyqqUbKwS628UgyDCbLNh5WaT08mdPrH-_Jh8-kh0YF3Ix_7TVN6ju92O4emodA",
              pid: "28XXXX20",
              name: "Jan Test",
            },
          ],
          methodOfSigning: "Online",
          mifidRequired: false,
          isInstitutional: true,
        },
        executiveClientPid: dataUserTransakcyjny.customerPid,
        isRepresentative: true,
        isSigned: false,
        cancelVisible: true,
        regulations: [
          {
            name: "Dokumenty do podpisu",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Broszura informacyjna w zakresie wymogów MiFID dla Klientów Powszechnej Kasy Oszczędności Banku Polskiego Spółki Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Instrukcja użytkownika Platformy iPKO dealer dla Klientów PKO Banku Polskiego",
            path: "https://www.pkobp.pl/",
          },
          {
            name: 'Regulamin „Ogólne warunki współpracy z Klientami na rynku finansowym w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej"',
            path: "https://www.pkobp.pl/",
          },
          {
            name: 'Regulamin „Szczególne warunki współpracy z Klientami PKO Banku Polskiego SA w zakresie produktów skarbowych na rynku finansowym"',
            path: "https://www.pkobp.pl/",
          },
        ],
        infoPhones: {
          local: "800302302",
          global: "801363636",
        },
        hasUpdatedDeclaration: false,
        // flowDeclaration: FlowDeclaration;
      };
    },
  },
  "/contracts/cancel": {
    POST: (data: any) => {
      const contactId = data.rest[0];
      if (contactId === "999888777") {
        isContractsCanceled = true;
      } else {
        contractsActive = contractsActive.map((el) => {
          if (el.number === contactId) {
            return {
              ...el,
              status: "Cancelled",
              cancelVisible: false,
              signVisible: false,
            };
          }
          return el;
        });
      }
      return true;
    },
  },
  "/contracts/start-signing": {
    POST: () => {
      //
    },
  },
  "/authorization/application": {
    POST: () => {
      //PrepareAuthorizationResult
      return {
        authorizationData: {
          authorizationMethod: "SMS", // "Card" | "SMS" | "Token" | "ChallengeResponseToken" | "Mobile";
          challenge: "123456789",
          response: "bbb",
          // approvalTime?: Date,
          // timeLeft?: number,
          deviceName: "ccc",
          phoneNumber: "111222333",
          pollingInterval: 1,
        },
        // error: {
        //   code: "",
        //   // data?: Record<string, string>,
        // },
      };
    },
  },
  "/contracts/sign": {
    POST: (data: any) => {
      // <AuthorizationResult<SurveyOperationResult>>
      const code = data?.body?.code;
      const codeMatch = String(code).match(/\d{6}/g);
      if (codeMatch && codeMatch?.length > 0) {
        if (data.body.applicationId === "999888777") {
          isContractsInClientActiveSign = true;
        } else if (data.body.applicationId === "999888771") {
          contractsActive = [];
          contractActiveSigned = true;
          contractsActive.push(contractActive(contractActiveSigned, true));
        }

        return {
          result: {
            alreadyRejected: false,
            success: true,
          },
          nextAttempt: {
            ...(PrezentacjaDane["/authorization/application"]["POST"]({}) as Record<string, any>),
            // error: {
            //   code: 'invalidCode',
            // },
          },
        };
      } else {
        return {
          result: {
            alreadyRejected: false,
            success: false,
          },
          nextAttempt: {
            ...(PrezentacjaDane["/authorization/application"]["POST"]({}) as Record<string, any>),
            error: {
              code: "invalidCode",
              data: {
                ERROR: "Cannot auth",
              },
            },
          },
        };
      }
    },
  },
  "/contracts/completed": {
    GET: (): CompetedContractsModel => {
      let isUserDigitalizacja = false;
      let isUserDigitalizacjaJDG = false;
      const userPid = sessionStorage.getItem("demoPid");
      if (userPid === dataUserDigitalizacja.customerPid) {
        isUserDigitalizacja = true;
      }
      if (userPid === dataUserJDG.customerPid) {
        isUserDigitalizacjaJDG = true;
      }
      return {
        ...(isUserDigitalizacja || isUserDigitalizacjaJDG
          ? {
              items: [],
              pageCount: 0,
            }
          : {
              items: [
                {
                  applicationId: "125XXX",
                  nemoId: "125226167",
                  type: "URZ", // "URN" | "URZ" | "SPOT1" | "JDGSPOT"
                  signDate: calculateDate(0, -5, 0),
                },
              ],
              pageCount: 1,
            }),
      };
    },
  },
  "/contracts/related-regulations": {
    GET: (): Regulation[] => {
      let isUserDigitalizacja = false;
      let isUserDigitalizacjaJDG = false;
      const userPid = sessionStorage.getItem("demoPid");
      if (userPid === dataUserDigitalizacja.customerPid) {
        isUserDigitalizacja = true;
      }
      if (userPid === dataUserJDG.customerPid) {
        isUserDigitalizacjaJDG = true;
      }
      return isUserDigitalizacja || isUserDigitalizacjaJDG
        ? []
        : [
            {
              name: "Broszura informacyjna w zakresie wymogów MiFID dla klientów Powszechnej Kasy Oszczędności Banku Polskiego Spółki Akcyjnej ",
              path: "",
            },
            {
              name: "Instrukcja użytkownika Platformy iPKO dealer dla klientów PKO Banku Polskiego",
              path: "",
            },
            {
              name: 'Regulamin „Ogólne warunki współpracy z Klientami na rynku finansowym w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej" ',
              path: "",
            },
            {
              name: 'Regulamin „Szczególne warunki współpracy z Klientami PKO Banku Polskiego SA w zakresie produktów skarbowych na rynku finansowym"',
              path: "",
            },
          ];
    },
  },
  //------------------------------------
  //------------------ contracts | DIGITALIZACJA
  //-----------------------------------
  "/contracts/apply-status": {
    GET: (): ContractAvailability => {
      let isUserDigitalizacja = false;
      let isUserDigitalizacjaJDG = false;
      const userPid = sessionStorage.getItem("demoPid");
      if (userPid === dataUserDigitalizacja.customerPid) {
        isUserDigitalizacja = true;
      }
      if (userPid === dataUserJDG.customerPid) {
        isUserDigitalizacjaJDG = true;
      }
      return {
        clientType: "Institutional",
        new: isUserDigitalizacja || isUserDigitalizacjaJDG ? true : false,
        change: isUserDigitalizacja || isUserDigitalizacjaJDG ? false : true,
        oneSpot: false,
        changeRequiresConsent: false,
        spot: isUserDigitalizacjaJDG ? true : false, // TO JEST SZYBKA SCIEZKA JDG
        isForeign: false,
      };
    },
  },
  "/contracts/phones": {
    GET: (): InfoPhones => {
      return {
        local: "",
        global: "",
      };
    },
  },
  "/contracts/attorneys": {
    GET: () => {
      // Product[]
      return [
        {
          name: "negocjowane, natychmiastowe transakcje wymiany walut wymienialnych",
          attorneys: [
            {
              firstName: "Jan",
              lastName: "Test",
              pid: "28845920",
            },
            {
              firstName: "Adam",
              lastName: "Demo",
              pid: "97241632",
            },
            {
              firstName: "Monika",
              lastName: "Kowalska",
              pid: "52573212",
            },
          ],
        },
      ];
    },
  },
  "/contracts/client": {
    // GET: (data: any): Client => {
    GET: (): any => {
      return {
        clientType: "Institutional", //"Individual" | "Institutional"
        institutionalClient: {
          type: "Institutional", //"Individual" | "Institutional"
          companyName: "Testowy Klient",
          regon: "774375628",
          nip: "1114421651",
          krs: "",
          companyAddress: {
            addressType: "SIEDZIBA_FIRMY",
            blockNumber: "",
            city: "Warszawa",
            country: "POL",
            number: "36",
            street: "ul. Świętokrzyska",
            streetType: "",
            unitNumber: "",
            zipCode: "00-827",
          },
          resident: true,
          pid: dataUserDigitalizacja.customerPid,
          kNumber: "",
          companyShortName: "",
          euroNip: "",
          legalForm: "", // "SPOLKI_AKCYJNE",
          propertyForm: "", // WLASNOSC_POZOSTALYCH_KRAJOWYCH_JEDNOSTEK_PRYWATNYCH
          residencyStatus: "", // 'R'
          segmentCode: "", // "277008",
          establishmentDate: new Date("2015-01-01"),
          isForeign: false,
        },
      };
    },
  },
  "/contracts/active": {
    GET: (): Application[] => {
      return contractsActive;
    },
  },
  "/contracts/new-data": {
    // NewAgreementData
    GET: () => {
      return {
        institutionalClient: {
          type: "Institutional", //"Individual" | "Institutional"
          companyName: dataUserDigitalizacja.customerName,
          regon: "774375628",
          nip: "1114421651",
          krs: "",
          companyAddress: {
            addressType: "SIEDZIBA_FIRMY",
            blockNumber: "",
            city: "Warszawa",
            country: "POL",
            number: "36",
            street: "ul. Świętokrzyska",
            streetType: "",
            unitNumber: "",
            zipCode: "00-827",
          },
          resident: true,
          pid: dataUserDigitalizacja.customerPid,
          kNumber: "",
          companyShortName: "",
          euroNip: "",
          legalForm: "", // "SPOLKI_AKCYJNE",
          propertyForm: "", // WLASNOSC_POZOSTALYCH_KRAJOWYCH_JEDNOSTEK_PRYWATNYCH
          residencyStatus: "", // 'R'
          segmentCode: "", // "277008",
          establishmentDate: new Date("2015-01-01"),
          isForeign: false,
        },
        applyAllowed: true,
        attorneys: [
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        legalTypeReadOnly: false,
        availableProducts: [
          {
            visible: true,
            isDefault: true,
            editable: true,
            id: "1",
            name: "negocjowane, natychmiastowe transakcje wymiany walut wymienialnych",
            requiresMifid: false,
            requiresLei: false,
            knfRequired: false,
            showNFC: false,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: true,
            editable: true,
            id: "16",
            name: "lokata premium",
            requiresMifid: false,
            requiresLei: false,
            knfRequired: false,
            showNFC: false,
            notMappedInGate: false,
          },
          {
            visible: false,
            isDefault: true,
            editable: true,
            id: "14",
            name: "lokata negocjowana",
            requiresMifid: false,
            requiresLei: false,
            knfRequired: false,
            showNFC: false,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "2",
            name: "negocjowane, terminowe transakcje wymiany walut wymienialnych oraz negocjowane transakcje FX swap",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "5",
            name: "opcje walutowe ",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "3",
            name: "terminowe transakcje stopy procentowej FRA",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "6",
            name: "transakcje zamiany stóp procentowych IRS oraz walutowe transakcje zamiany stóp procentowych CIRS",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "9",
            name: "opcje na stopę procentową ",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "8",
            name: "transakcje swapa towarowego",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "12",
            name: "opcje towarowe",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "4",
            name: "transakcje na rynku dłużnych papierów wartościowych",
            requiresMifid: true,
            requiresLei: false,
            knfRequired: false,
            showNFC: true,
            notMappedInGate: false,
            notes:
              "UWAGA: Transakcje na rynku dłużnych papierów wartościowych wymagają posiadania rachunku papierów wartościowych. W celu zawarcia umowy na prowadzenie rachunku pap. wartościowych prosimy o kontakt z doradcą",
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "13",
            name: "lokata inwestycyjna",
            requiresMifid: true,
            requiresLei: false,
            knfRequired: false,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "7",
            name: "lokaty dwuwalutowe z inwestycją w opcje walutowe",
            requiresMifid: true,
            requiresLei: false,
            knfRequired: false,
            showNFC: true,
            notMappedInGate: false,
          },
        ],
        legalRepresentation: [],
        clientType: "Institutional",
        documents: [
          {
            name: "Regulamin „Ogólne warunki współpracy z Klientami na rynku finansowym w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej”",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin „Szczególne warunki współpracy z Klientami PKO Banku Polskiego SA w zakresie produktów skarbowych na rynku finansowym”",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Broszura informacyjna w zakresie wymogów MiFID dla Klientów Powszechnej Kasy Oszczędności Banku Polskiego Spółki Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Instrukcja użytkownika Platformy iPKO dealer dla Klientów PKO Banku Polskiego",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin lokaty inwestycyjnej w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin negocjowanej, terminowej transakcji wymiany walut wymienialnych oraz transakcji FX swap w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin opcji walutowej w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin terminowej transakcji stopy procentowej FRA w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin transakcji zamiany stóp procentowych IRS oraz  walutowej transakcji zamiany stóp procentowych CIRS w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin opcji na stopę procentową w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Lista towarów bazowych, cen referencyjnych i minimalnych nominałów dla towarowych transakcji pochodnych w PKO Banku Polskim SA",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin transakcji swapa towarowego w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin opcji towarowej w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin zawierania transakcji na rynku dłużnych papierów wartościowych z Powszechną Kasą Oszczędności Bankiem Polskim Spółką Akcyjną",
            path: "https://www.pkobp.pl",
          },
          {
            name: "Regulamin lokaty dwuwalutowej z inwestycją w opcję walutową w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl",
          },
        ],
        infoPhones: {
          local: "800302302",
          global: "801363636",
        },
        segmentInfoPhones: {
          local: "801363636",
          global: "(+48)618559494",
        },
        internetAccessIndividualSegments: false,
        retailInfoLink:
          "https://www.pkobp.pl/klienci-indywidualni/konta/kantor-internetowy/kantor-internetowy/",
        application: {
          // leiNumber: "",
          products: [],
          signMethod: "Branch",
        },
        productAttorneys: [
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        minDealAmount: "25 000 USD",
        customerEmails: [],
        requiresMifid: false,
        statusPollingConfiguration: {
          totalPollingTime: 5000,
          pollingInterval: 1,
        },
        flowDeclarationPanel: {
          declaration: {
            dateFrom: calculateDate(0, 0, 0),
            dateTo: calculateDate(1, 0, 0),
            flowsData: [],
            isActual: false,
            refuseToAnswer: false,
          },
          canBePostponed: false,
          currencies: [
            {
              code: "EUR",
              decimals: 2,
            },
            {
              code: "USD",
              decimals: 2,
            },
            {
              code: "GBP",
              decimals: 2,
            },
            {
              code: "SEK",
              decimals: 2,
            },
            {
              code: "NOK",
              decimals: 2,
            },
            {
              code: "AUD",
              decimals: 2,
            },
            {
              code: "BGN",
              decimals: 2,
            },
            {
              code: "CAD",
              decimals: 2,
            },
            {
              code: "CHF",
              decimals: 2,
            },
            {
              code: "CNY",
              decimals: 2,
            },
            {
              code: "CZK",
              decimals: 2,
            },
            {
              code: "DKK",
              decimals: 2,
            },
            {
              code: "HKD",
              decimals: 2,
            },
            {
              code: "HUF",
              decimals: 2,
            },
            {
              code: "JPY",
              decimals: 2,
            },
            {
              code: "MXN",
              decimals: 2,
            },
            {
              code: "NZD",
              decimals: 2,
            },
            {
              code: "RON",
              decimals: 2,
            },
            {
              code: "RUB",
              decimals: 2,
            },
            {
              code: "SGD",
              decimals: 2,
            },
            {
              code: "TRY",
              decimals: 2,
            },
            {
              code: "ZAR",
              decimals: 2,
            },
          ],
        },
        checkSwitip: true,
        collectEmployeeCount: false,
        employeeCountOptions: [
          {
            id: 1,
            text: "Poniżej 10 pracowników",
          },
          {
            id: 2,
            text: "Pomiędzy 10 a 249 pracowników",
          },
          {
            id: 3,
            text: "Powyżej 249 pracowników",
          },
        ],
      };
    },
  },
  "/contracts/black-list": {
    GET: () => {
      // CheckBlackListResult
      return {
        success: true,
        isInBlackList: false,
      };
    },
  },
  "/contracts/new-save": {
    POST: (data: any): CreateContractResult => {
      contractsActive = [];
      contractActiveSigned = false;
      if (data?.body?.signing === "Online") {
        contractsActive.push(contractActive(contractActiveSigned, true));
      } else {
        contractsActive.push(contractActive(true, false));
      }

      return {
        success: true,
        errorCode: "",
        applicationId: "12345",
        requiresMifid: false,
        isCurrentUserSelectedAttorney: data?.body?.signing === "Online" ? true : false, // (różne okienka podsumowania w zależności od metody podpisania)
        infoPhones: {
          local: "999000999",
          global: "999000999",
        },
      };
    },
  },
  "/contracts/change-data": {
    GET: (): any => {
      // NewAgreementData
      return {
        applyAllowed: true,
        availableProducts: [
          {
            visible: true,
            isDefault: true,
            editable: true,
            id: "1",
            name: "negocjowane, natychmiastowe transakcje wymiany walut wymienialnych",
            requiresMifid: false,
            requiresLei: false,
            knfRequired: false,
            showNFC: false,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: true,
            editable: true,
            id: "16",
            name: "lokata premium",
            requiresMifid: false,
            requiresLei: false,
            knfRequired: false,
            showNFC: false,
            notMappedInGate: false,
          },
          {
            visible: false,
            isDefault: true,
            editable: true,
            id: "14",
            name: "lokata negocjowana",
            requiresMifid: false,
            requiresLei: false,
            knfRequired: false,
            showNFC: false,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "2",
            name: "negocjowane, terminowe transakcje wymiany walut wymienialnych oraz negocjowane transakcje FX swap",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "5",
            name: "opcje walutowe ",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "3",
            name: "terminowe transakcje stopy procentowej FRA",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "6",
            name: "transakcje zamiany stóp procentowych IRS oraz walutowe transakcje zamiany stóp procentowych CIRS",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "9",
            name: "opcje na stopę procentową ",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "8",
            name: "transakcje swapa towarowego",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "12",
            name: "opcje towarowe",
            requiresMifid: true,
            requiresLei: true,
            knfRequired: true,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "4",
            name: "transakcje na rynku dłużnych papierów wartościowych",
            requiresMifid: true,
            requiresLei: false,
            knfRequired: false,
            showNFC: true,
            notMappedInGate: false,
            notes:
              "UWAGA: Transakcje na rynku dłużnych papierów wartościowych wymagają posiadania rachunku papierów wartościowych. W celu zawarcia umowy na prowadzenie rachunku pap. wartościowych prosimy o kontakt z doradcą",
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "13",
            name: "lokata inwestycyjna",
            requiresMifid: true,
            requiresLei: false,
            knfRequired: false,
            showNFC: true,
            notMappedInGate: false,
          },
          {
            visible: true,
            isDefault: false,
            editable: true,
            id: "7",
            name: "lokaty dwuwalutowe z inwestycją w opcje walutowe",
            requiresMifid: true,
            requiresLei: false,
            knfRequired: false,
            showNFC: true,
            notMappedInGate: false,
          },
        ],
        legalRepresentation: [
          // Attorney
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        clientType: "Institutional", // "Institutional" | "Individual"
        // individualClient: IndividualClientDataDetails,
        institutionalClient: {
          type: "Institutional", //"Individual" | "Institutional"
          companyName: dataUserTransakcyjny.customerName,
          regon: "774375628",
          nip: "1114421651",
          krs: "",
          companyAddress: {
            addressType: "SIEDZIBA_FIRMY",
            blockNumber: "",
            city: "Warszawa",
            country: "POL",
            number: "36",
            street: "ul. Świętokrzyska",
            streetType: "",
            unitNumber: "",
            zipCode: "00-827",
          },
          resident: true,
          pid: dataUserTransakcyjny.customerPid,
          kNumber: "",
          companyShortName: "",
          euroNip: "",
          legalForm: "", // "SPOLKI_AKCYJNE",
          propertyForm: "", // WLASNOSC_POZOSTALYCH_KRAJOWYCH_JEDNOSTEK_PRYWATNYCH
          residencyStatus: "", // 'R'
          segmentCode: "", // "277008",
          establishmentDate: new Date("2015-01-01"),
          isForeign: false,
        },
        documents: [
          // Regulation
          {
            name: "Regulamin „Ogólne warunki współpracy z Klientami na rynku finansowym w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej”",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin „Szczególne warunki współpracy z Klientami PKO Banku Polskiego SA w zakresie produktów skarbowych na rynku finansowym”",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Broszura informacyjna w zakresie wymogów MiFID dla Klientów Powszechnej Kasy Oszczędności Banku Polskiego Spółki Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Instrukcja użytkownika Platformy iPKO dealer dla Klientów PKO Banku Polskiego",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin lokaty inwestycyjnej w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin negocjowanej, terminowej transakcji wymiany walut wymienialnych oraz transakcji FX swap w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin opcji walutowej w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin terminowej transakcji stopy procentowej FRA w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin transakcji zamiany stóp procentowych IRS oraz  walutowej transakcji zamiany stóp procentowych CIRS w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin opcji na stopę procentową w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Lista towarów bazowych, cen referencyjnych i minimalnych nominałów dla towarowych transakcji pochodnych w PKO Banku Polskim SA",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin transakcji swapa towarowego w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin opcji towarowej w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin zawierania transakcji na rynku dłużnych papierów wartościowych z Powszechną Kasą Oszczędności Bankiem Polskim Spółką Akcyjną",
            path: "https://www.pkobp.pl/",
          },
          {
            name: "Regulamin lokaty dwuwalutowej z inwestycją w opcję walutową w Powszechnej Kasie Oszczędności Banku Polskim Spółce Akcyjnej",
            path: "https://www.pkobp.pl/",
          },
        ],
        internetAccessIndividualSegments: true,
        retailInfoLink:
          "https://www.pkobp.pl/klienci-indywidualni/konta/kantor-internetowy/kantor-internetowy/",
        statusNFCLink: "https://www.pkobp.pl/",
        application: {
          // Agreement
          customerKNumber: "K401291",
          agreementDate: new Date("2023-09-29T00:00:00"),
          emirStatus: "",
          leiNumber: "97000000000000000001",
          products: [
            {
              id: "1",
              attorneys: [
                // Attorney
                {
                  internetAccess: true,
                  isCurrentUser: false,
                  isSelectedCustomer: false,
                  id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                  pid: "28845920",
                  name: "Jan Test",
                },
              ],
              notMappedInGate: false,
            },
            {
              id: "2",
              attorneys: [
                // Attorney
                {
                  internetAccess: true,
                  isCurrentUser: false,
                  isSelectedCustomer: false,
                  id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                  pid: "28845920",
                  name: "Jan Test",
                },
              ],
              notMappedInGate: false,
            },
            {
              id: "16",
              attorneys: [
                // Attorney
                {
                  internetAccess: true,
                  isCurrentUser: false,
                  isSelectedCustomer: false,
                  id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                  pid: "28845920",
                  name: "Jan Test",
                },
              ],
              notMappedInGate: false,
            },
            {
              id: "13",
              attorneys: [
                // Attorney
                {
                  internetAccess: true,
                  isCurrentUser: false,
                  isSelectedCustomer: false,
                  id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                  pid: "28845920",
                  name: "Jan Test",
                },
              ],
              notMappedInGate: false,
            },
          ],
          accounts: [
            {
              id: "870958",
              number: "82102055610000350290545047",
              currency: "PLN",
              fX: false,
              isFXDefault: false,
              Mm: false,
              isMMDefault: false,
              all: true,
              isAllDefault: false,
              deriv: false,
              isDerivDefault: false,
              secure: false,
              isSecureDefault: false,
            },
            {
              id: "870957",
              number: "51102055610000310290545025",
              currency: "EUR",
              fX: false,
              isFXDefault: false,
              Mm: false,
              isMMDefault: false,
              all: true,
              isAllDefault: false,
              deriv: false,
              isDerivDefault: false,
              secure: false,
              isSecureDefault: false,
            },
          ],
          operationalEmails: ["testowy.klient@email.pl"],
          contactEmails: ["testowy.klient@email.pl"],
          contactPhones: [
            {
              country: "PL",
              prefix: "+48",
              number: "111222333",
            },
          ],
          signMethod: "Branch",
          representatives: [
            {
              internetAccess: true,
              isCurrentUser: false,
              isSelectedCustomer: false,
              id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
              pid: "28845920",
              name: "Jan Test",
            },
          ],

          // customerKNumber: string;
          // agreementDate?: Date;
          // emirStatus: string;
          // leiNumber: string;
          // products: Product[];
          // accounts: Account[];
          // operationalEmails: string[];
          // contactEmails: string[];
          // contactPhones: Phone[];
          // signMethod: MethodOfContractSigning;
          // representatives: Attorney[];
        },
        productAttorneys: [
          // Attorney
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        minDealAmount: "0",
        customerEmails: ["testowy.klient@email.pl"],
        requiresMifid: false,
        phonePrefixes: [
          // PhonePrefix
          {
            code: "+48",
            countryCode: "PL",
            text: "",
          },
        ],
        attorneys: [
          // Attorney
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        legalTypeReadOnly: false,
        infoPhones: {
          // InfoPhones
          local: "999000999",
          global: "999000999",
        },
        segmentInfoPhones: {
          // InfoPhones
          local: "999000999",
          global: "999000999",
        },
        // blockReason?: ApplicationBlockReason;
        isIdg: true,
        signer: false,
        statusPollingConfiguration: {
          // StatusPollingConfiguration
          totalPollingTime: 5000,
          pollingInterval: 1,
        },
        flowDeclarationPanel: {
          // FlowDeclarationVM
          canBePostponed: true,
          declaration: {
            // FlowDeclaration
            dateFrom: calculateDate(0, -1, 0),
            dateTo: calculateDate(0, 1, 0),
            refuseToAnswer: false,
            isActual: true,
            flowsData: [
              // FlowDeclarationItem
              {
                currency: "EUR",
                amount: "< 10 000",
                side: "Sell",
              },
              {
                currency: "USD",
                amount: "< 10 000",
                side: "Sell",
              },
            ],
          },
          currencies: [
            // Currency
            {
              code: "EUR",
              decimals: 2,
            },
            {
              code: "USD",
              decimals: 2,
            },
            {
              code: "GBP",
              decimals: 2,
            },
          ],
        },
        checkSwitip: true,
        isInstitutional: true,
        collectEmployeeCount: true,
        employeeCountOptions: [
          // EmployeeCountOption
          {
            id: 1,
            text: "Poniżej 10 pracowników",
          },
          {
            id: 2,
            text: "Pomiędzy 10 a 249 pracowników",
          },
          {
            id: 3,
            text: "Powyżej 249 pracowników",
          },
        ],
      };
    },
  },
  "/contracts/change-save": {
    POST: (data: any) => {
      contractsActive = [];
      contractActiveSigned = false;
      if (data?.body?.signing === "Online") {
        contractsActive.push(contractActive(contractActiveSigned, true));
      } else {
        contractsActive.push(contractActive(true, false));
      }

      // CreateContractResult
      return {
        success: true,
        // errorCode: '',
        applicationId: "12345",
        requiresMifid: false,
        isCurrentUserSelectedAttorney: data?.body?.signing === "Online" ? true : false, // (różne okienka podsumowania w zależności od metody podpisania)
        infoPhones: {
          // InfoPhones
          local: "999000999",
          global: "999000999",
        },
      };
    },
  },
  "/contracts/spot-data": {
    GET: () => {
      // NewAgreementData
      return {
        applyAllowed: true,
        availableProducts: [
          // ProductSegment
          {
            id: "1",
            name: "Lokata",
            attorneys: [
              // Attorney
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                pid: "28845920",
                name: "Jan Test",
              },
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
                pid: "97241632",
                name: "Adam Demo",
              },
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
                pid: "52573212",
                name: "Monika Kowalska",
              },
            ],
            notMappedInGate: true,
            requiresMifid: true,
            requiresLei: true,
            visible: true,
            isDefault: true,
            editable: true,
            knfRequired: true,
            showNFC: true,
            // notes?: string;
          },
          {
            id: "2",
            name: "SPOT",
            attorneys: [
              // Attorney
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                pid: "28845920",
                name: "Jan Test",
              },
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
                pid: "97241632",
                name: "Adam Demo",
              },
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
                pid: "52573212",
                name: "Monika Kowalska",
              },
            ],
            notMappedInGate: false,
            requiresMifid: false,
            requiresLei: false,
            visible: true,
            isDefault: true,
            editable: true,
            knfRequired: false,
            showNFC: false,
            // notes?: string;
          },
          {
            id: "3",
            name: "FORWARD",
            attorneys: [
              // Attorney
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                pid: "28845920",
                name: "Jan Test",
              },
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
                pid: "97241632",
                name: "Adam Demo",
              },
              {
                internetAccess: true,
                isCurrentUser: false,
                isSelectedCustomer: false,
                id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
                pid: "52573212",
                name: "Monika Kowalska",
              },
            ],
            notMappedInGate: false,
            requiresMifid: false,
            requiresLei: false,
            visible: true,
            isDefault: true,
            editable: true,
            knfRequired: false,
            showNFC: false,
            // notes?: string;
          },
        ],
        legalRepresentation: [
          // Attorney
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        clientType: "Institutional", // "Institutional" | "Individual"
        // individualClient: IndividualClientDataDetails,
        institutionalClient: {
          type: "Institutional", //"Individual" | "Institutional"
          companyName: dataUserJDG.customerName,
          regon: "774375628",
          nip: "1114421651",
          krs: "",
          companyAddress: {
            addressType: "SIEDZIBA_FIRMY",
            blockNumber: "",
            city: "Warszawa",
            country: "POL",
            number: "36",
            street: "ul. Świętokrzyska",
            streetType: "",
            unitNumber: "",
            zipCode: "00-827",
          },
          resident: true,
          pid: dataUserJDG.customerPid,
          kNumber: "",
          companyShortName: "",
          euroNip: "",
          legalForm: "", // "SPOLKI_AKCYJNE",
          propertyForm: "", // WLASNOSC_POZOSTALYCH_KRAJOWYCH_JEDNOSTEK_PRYWATNYCH
          residencyStatus: "", // 'R'
          segmentCode: "", // "277008",
          establishmentDate: new Date("2015-01-01"),
          isForeign: false,
        },
        documents: [
          // Regulation
          {
            name: "Instrukcja użytkownika Platformy iPKO dealer dla Klientów PKO Banku Polskiego",
            path: "",
          },
          {
            name: "Szczególne warunki współpracy z Klientami PKO Banku Polskiego SA w zakresie produktów skarbowych na rynku finansowym",
            path: "",
          },
        ],
        internetAccessIndividualSegments: true,
        retailInfoLink:
          "https://www.pkobp.pl/klienci-indywidualni/konta/kantor-internetowy/kantor-internetowy/",
        statusNFCLink: "https://www.pkobp.pl/",
        application: {
          // Agreement
          customerKNumber: "K401291",
          agreementDate: new Date("2023-09-29T00:00:00"),
          emirStatus: "",
          leiNumber: "97000000000000000001",
          products: [
            {
              id: "3",
              attorneys: [
                // Attorney
                {
                  internetAccess: true,
                  isCurrentUser: false,
                  isSelectedCustomer: false,
                  id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
                  pid: "28845920",
                  name: "Jan Test",
                },
                {
                  internetAccess: true,
                  isCurrentUser: false,
                  isSelectedCustomer: false,
                  id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
                  pid: "97241632",
                  name: "Adam Demo",
                },
                {
                  internetAccess: true,
                  isCurrentUser: false,
                  isSelectedCustomer: false,
                  id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
                  pid: "52573212",
                  name: "Monika Kowalska",
                },
              ],
              notMappedInGate: false,
            },
          ],
          accounts: [
            {
              id: "870958",
              number: "82102055610000350290545047",
              currency: "PLN",
              fX: false,
              isFXDefault: false,
              Mm: false,
              isMMDefault: false,
              all: true,
              isAllDefault: false,
              deriv: false,
              isDerivDefault: false,
              secure: false,
              isSecureDefault: false,
            },
            {
              id: "870957",
              number: "51102055610000310290545025",
              currency: "EUR",
              fX: false,
              isFXDefault: false,
              Mm: false,
              isMMDefault: false,
              all: true,
              isAllDefault: false,
              deriv: false,
              isDerivDefault: false,
              secure: false,
              isSecureDefault: false,
            },
          ],
          operationalEmails: ["M1@M.PL"],
          contactEmails: ["M2@M.PL"],
          contactPhones: [
            {
              country: "PL",
              prefix: "+48",
              number: "111222333",
            },
          ],
          signMethod: "Branch",
          representatives: [
            {
              internetAccess: true,
              isCurrentUser: false,
              isSelectedCustomer: false,
              id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
              pid: "28845920",
              name: "Jan Test",
            },
            {
              internetAccess: true,
              isCurrentUser: false,
              isSelectedCustomer: false,
              id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
              pid: "97241632",
              name: "Adam Demo",
            },
            {
              internetAccess: true,
              isCurrentUser: false,
              isSelectedCustomer: false,
              id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
              pid: "52573212",
              name: "Monika Kowalska",
            },
          ],

          // customerKNumber: string;
          // agreementDate?: Date;
          // emirStatus: string;
          // leiNumber: string;
          // products: Product[];
          // accounts: Account[];
          // operationalEmails: string[];
          // contactEmails: string[];
          // contactPhones: Phone[];
          // signMethod: MethodOfContractSigning;
          // representatives: Attorney[];
        },
        productAttorneys: [
          // Attorney
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        minDealAmount: "0",
        customerEmails: [],
        requiresMifid: false,
        phonePrefixes: [
          // PhonePrefix
          {
            code: "+48",
            countryCode: "PL",
            text: "",
          },
        ],
        attorneys: [
          // Attorney
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27cPE1apR26PTqdwLG1hqSy9gk6tkcNz2RoPQ5K_m0dqProkKvPudPJE31JwCjTiN_bIekn35-4C3zaNsZnUeS3rf95E3752qgudBZysaGoCuQ",
            pid: "28845920",
            name: "Jan Test",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27d4zqSgdMOu_FI3YmExMtjNXSncA5WMS9jnYO57hg03pKK4_vSFZS7cq3M9CqHFknMZvDW2AK4E0qj_aXl7ayJcWFbaSHN3Basv-vIo5GbmiQ",
            pid: "97241632",
            name: "Adam Demo",
          },
          {
            internetAccess: true,
            isCurrentUser: false,
            isSelectedCustomer: false,
            id: "CfDJ8OCS2uq27RtGnlWIGH6E27ew6gttjkTBeuskw5anLl5HzJG5EFXVbozNfWhD6Y-qFVQiLtJHxlujrnyrz8OagDIP7qgCdng59c-TH28uJKP_SB5Enay8NYv7hOwRXFairA",
            pid: "52573212",
            name: "Monika Kowalska",
          },
        ],
        legalTypeReadOnly: false,
        infoPhones: {
          // InfoPhones
          local: "999000999",
          global: "999000999",
        },
        segmentInfoPhones: {
          // InfoPhones
          local: "999000999",
          global: "999000999",
        },
        // blockReason?: ApplicationBlockReason;
        isIdg: true,
        signer: true,
        statusPollingConfiguration: {
          // StatusPollingConfiguration
          totalPollingTime: 5000,
          pollingInterval: 1,
        },
        flowDeclarationPanel: {
          // FlowDeclarationVM
          canBePostponed: true,
          declaration: {
            // FlowDeclaration
            dateFrom: calculateDate(0, -1, 0),
            dateTo: calculateDate(0, 1, 0),
            refuseToAnswer: false,
            isActual: true,
            flowsData: [
              // FlowDeclarationItem
              {
                currency: "EUR",
                amount: "> 30 000",
                side: "Sell",
              },
            ],
          },
          currencies: [
            // Currency
            {
              code: "EUR",
              decimals: 2,
            },
            {
              code: "USD",
              decimals: 2,
            },
            {
              code: "GBP",
              decimals: 2,
            },
          ],
        },
        checkSwitip: true,
        isInstitutional: true,
        collectEmployeeCount: true,
        employeeCountOptions: [
          // EmployeeCountOption
          {
            id: 1,
            text: "Poniżej 10 pracowników",
          },
          {
            id: 2,
            text: "Pomiędzy 10 a 249 pracowników",
          },
          {
            id: 3,
            text: "Powyżej 249 pracowników",
          },
        ],
      };
    },
  },
  "/contracts/spot-save": {
    POST: () => {
      //CreateContractResult
      return {
        success: true,
        applicationId: "105558",
        requiresMifid: false,
        isCurrentAttorneySingleRepresentative: false,
        isCurrentUserSelectedAttorney: true,
        infoPhones: {
          local: "123123123",
          global: "123123123",
        },
      };
    },
  },
  "/contracts/spot-status": {
    GET: () => {
      // ApplicationStatus        // "Cancelled" | "Rejected" | "Branch" | "New" | "ReadyForSigning" | "Verification" | "Realization" | "Accepted" | "ContractSigned" | "Completed" | "ErrorGate" | "NotRecognized" | "SchemaChange" | "NewSuspended" | "RealizationSuspended"
      sessionStorage.setItem(
        "JDG_ROLES",
        JSON.stringify([
          "Customer",
          "HasCustomer",
          "PotentialCustomer",
          "Orders",
          "FxSpot",
          "DPWEnabled",
          "DpwOn",
        ])
      );
      // return "New";
      return "Completed";
    },
  },
  "/contracts/status": {
    GET: () => {
      // "Cancelled"      | "Rejected"      | "Branch"      | "New"      | "ReadyForSigning"      | "Verification"      | "Realization"      | "Accepted"      | "ContractSigned"      | "Completed"      | "ErrorGate"      | "NotRecognized"      | "SchemaChange"      | "NewSuspended"| "RealizationSuspended";
      return "Verification";
    },
  },
  "/contracts/cancel-process": {
    POST: (): void => {
      //
    },
  },
  "/contracts/profile-regulations": {
    GET: (): Regulation[] => {
      return [];
    },
  },
  //------------------------------------
  //------------------ preferences
  //-----------------------------------
  "/preferences": {
    GET: (): Preferences => {
      return dataPreference;
    },
  },
  "/preferences/dashboard": {
    POST: (data: any) => {
      dataPreference.dashboard = data.body;
      return data.body;
    },
  },
  "/preferences/indicatives": {
    // TO_DO_???
    POST: () => {
      return {
        pollingEnabled: true,
        tenor: NON_FORWARD_TENORS[0],
      };
    },
  },
  "/preferences/language": {
    POST: (data: any): Language => {
      const dataToReturn = {
        code: data.body.code,
      } as Language;
      dataPreference.language = dataToReturn;
      return dataToReturn;
    },
  },
  "/preferences/phone": {
    // TO_DO_???
    POST: () => {
      return {
        prefix: "+48",
        number: "456456456",
      };
    },
  },
  "/preferences/dealhistory": {
    POST: (data: any) => {
      dataPreference.dealHistory = data.body.order;
      return data.body.order;
    },
  },
  "/preferences/transactionControl": {
    POST: (data: any): TransactionControl => {
      const dataToReturn = {
        values: data.body,
        availability: {
          checkSimilarDeals: false,
          allowDealsWithoutBalance: false,
        },
      };
      dataPreference.transactionControl = dataToReturn;
      return dataToReturn;
    },
  },
  "/preferences/exchange": {
    POST: (data: any) => {
      const dataToReturn = data.body;
      dataToReturn.amount = Number(dataToReturn.amount);
      dataPreference.exchange = dataToReturn;
      return dataToReturn;
    },
  },
  "/preferences/deposit": {
    POST: (data: any) => {
      const dataToReturn = data.body;
      dataToReturn.amount = Number(dataToReturn.amount);
      dataPreference.deposit = dataToReturn;
      return dataToReturn;
    },
  },
  "/preferences/swap": {
    POST: (data: any) => {
      const dataToReturn = data.body;
      dataToReturn.amount = Number(dataToReturn.amount);
      dataPreference.swap = dataToReturn;
      return dataToReturn;
    },
  },
  "/preferences/graph": {
    POST: (data: any) => {
      const dataToReturn = data.body;
      dataPreference.graph = dataToReturn;
      return dataToReturn;
    },
  },
  "/preferences/blottergraph": {
    POST: (data: any) => {
      const dataToReturn = data.body.currencies;
      dataPreference.blotterGraph.currencies = dataToReturn;
      return dataToReturn;
    },
  },
  "/preferences/customers": {
    POST: () => {
      return {};
    },
  },
  "/preferences/investmentDeposit": {
    POST: (data: any) => {
      const dataToReturn = data.body;
      return dataToReturn;
    },
  },
  //------------------------------------
  //------------------ user
  //-----------------------------------
  "/user": {
    GET: (): UserData => {
      const userPid = sessionStorage.getItem("demoPid");
      if (userPid === dataUserDigitalizacja.customerPid) {
        return dataUserDigitalizacja as UserData;
      }
      if (userPid === dataUserJDG.customerPid) {
        const JDG_ROLES = sessionStorage.getItem("JDG_ROLES");
        if (JDG_ROLES) {
          dataUserJDG.roles = JSON.parse(JDG_ROLES);
        }
        return dataUserJDG as UserData;
      }
      return dataUserTransakcyjny;
    },
  },
  "/customers": {
    GET: (): Customer[] => {
      return dataCustomer;
    },
    POST: (data: any) => {
      const dataCustomerId = String(data.body.replaceAll('"', ""));

      dataCustomer.forEach((customer) => {
        if (customer.id === dataCustomerId) {
          sessionStorage.setItem("demoPid", customer.pid);
        }
      });
      return {};
    },
  },
  "/login/extend": {
    POST: () => {
      return dataUserTransakcyjny.tokenExpiration;
    },
  },
  //------------------------------------
  //------------------ accounts
  //-----------------------------------
  "/accounts/exchange": {
    GET: (): Account[] => {
      return dataAccount;
    },
  },
  "/accounts/deposit": {
    GET: (): Account[] => {
      return dataAccount;
    },
  },
  "/accounts/premium-deposit": {
    GET: (): Account[] => {
      return dataAccount;
    },
  },
  "/accounts/collateral": {
    GET: (): Account[] => {
      return dataAccount.filter((el) => {
        if (el.currency === dataPrezentacjaWaluty.CHF) {
          return false;
        }
        return true;
      });
    },
  },
  "/accounts/pvp": {
    // ???
    GET: (): PvpRules => {
      return {
        // ver stara
        internal: {
          isValueEditable: false,
          defaultValue: true,
          displayWarningMessage: "No", // "Yes" | "No" | "IfPvpChosen";           // to wyświetla info PVP przy tranzakcjach
        },
        external: {
          isValueEditable: false,
          defaultValue: true,
          displayWarningMessage: "No",
        },
      };
    },
  },
  "/accounts/default": {
    GET: (): DefaultAccounts => {
      return {
        technicalCustomerNumber: "1",
        accounts: dataAccount,
      };
    },
    PUT: (data: any) => {
      const newAccountsMap = new Map();
      data.body.accounts.forEach((el: Account) => {
        newAccountsMap.set(el.number, el);
      });
      dataAccount = dataAccount.map((el) => {
        if (newAccountsMap.has(el.number)) return newAccountsMap.get(el.number);

        return el;
      });
    },
  },
  //------------------------------------
  //------------------ currency
  //-----------------------------------           [UWAGA - wartości brane z plików CSV]
  "/currency/pairs": {
    GET: (): CurrencyPair[] => {
      return commonData["/currency"].map((el: any) => {
        return {
          code: el.para,
          decimals: 4,
          isActive360: true, // false
        };
      });
    },
  },
  "/currency/sorted-pairs": {
    GET: (): CurrencyPair[] => {
      return PrezentacjaDane["/currency/pairs"]["GET"]({});
    },
  },
  "/currency/exchange": {
    GET: (): Currency[] => {
      return Object.values(dataPrezentacjaWaluty).map((el: any) => {
        return {
          code: el,
          decimals: 2,
        };
      });
    },
  },
  "/currency/deposit": {
    // zwraca to samo co    /currency/exchange
    GET: (data: any): Currency[] => {
      return PrezentacjaDane["/currency/exchange"]["GET"](data);
    },
  },
  "/currency/holidays": {
    GET: (): Record<string, string[]> => {
      return {
        CHF: [
          "2023-08-01T00:00:00+02:00",
          "2023-05-29T00:00:00+02:00",
          "2023-05-18T00:00:00+02:00",
          "2023-05-01T00:00:00+02:00",
          "2023-04-10T00:00:00+02:00",
          "2023-04-07T00:00:00+02:00",
          "2023-01-02T00:00:00+01:00",
          "2022-12-26T00:00:00+01:00",
        ],
        EUR: [
          "2023-05-01T00:00:00+02:00",
          "2023-04-10T00:00:00+02:00",
          "2023-04-07T00:00:00+02:00",
          "2022-12-26T00:00:00+01:00",
        ],
        PLN: [
          "2023-11-01T00:00:00+01:00",
          "2023-08-15T00:00:00+02:00",
          "2023-06-08T00:00:00+02:00",
          "2023-05-03T00:00:00+02:00",
          "2023-05-01T00:00:00+02:00",
          "2023-04-10T00:00:00+02:00",
          "2023-01-06T00:00:00+01:00",
          "2022-12-26T00:00:00+01:00",
        ],
        USD: [
          "2023-11-23T00:00:00+01:00",
          "2023-10-09T00:00:00+02:00",
          "2023-09-04T00:00:00+02:00",
          "2023-07-04T00:00:00+02:00",
          "2023-05-29T00:00:00+02:00",
          "2023-02-20T00:00:00+01:00",
          "2023-01-16T00:00:00+01:00",
          "2023-01-02T00:00:00+01:00",
          "2022-12-26T00:00:00+01:00",
        ],
        GBP: [],

        // "CHF": [],
        // "EUR": ["2023-04-13T00:00:00+02:00", "2023-04-14T00:00:00+02:00"],
        // "PLN": ["2023-04-10T00:00:00+02:00", "2023-04-11T00:00:00+02:00", "2023-04-12T00:00:00+02:00"],
        // "USD": [],
        // "GBP": [],
      };
    },
  },
  "/currency/fast-deal-pairs": {
    // zwraca to samo co    /currency/pairs
    GET: (): CurrencyPair[] => {
      return PrezentacjaDane["/currency/pairs"]["GET"]({});
    },
  },
  "/currency/iob-pairs": {
    // zwraca to samo co    /currency/pairs
    GET: (): CurrencyPair[] => {
      return PrezentacjaDane["/currency/pairs"]["GET"]({});
    },
  },
  //------------------------------------
  //------------------ pary walutowe
  //-----------------------------------
  "/indicatives/pairs": {
    GET: (): IndicativePair[] => {
      return commonData["/currency"].map((el: any, index: number) => {
        const accountsLength = dataAccount.filter((acc) => {
          if (el.para.startsWith(acc.currency)) return true;
          return false;
        }).length;
        return {
          order: index,
          code: el.para,
          isSelected: el.wybrane,
          baseAccountMissing: accountsLength > 0 ? false : true,
          quoteAccountMissing: false,
        };
      });
    },
    POST: (data: any): string[] => {
      return data.body;
    },
  },
  "/indicatives/rates": {
    // aktualizacja losowane odchylenie
    GET: (): IndicativeRate[] => {
      const newDataIndicativeRates = commonData["/currency"].map((el: any) => {
        return {
          pair: el.para,
          buy: (el.kup + (Math.random() * 2 * el.odchylenie - el.odchylenie)).toFixed(4),
          sell: (el.sprzedaj + (Math.random() * 2 * el.odchylenie - el.odchylenie)).toFixed(4),
        };
      });
      DataIndicativeRate = newDataIndicativeRates;
      return DataIndicativeRate;
    },
  },
  "/indicatives/rate": {
    GET: (data: any): LiveRate => {
      const dataPair = data.params.get("pair");
      const liveRateData = commonData["/currency"]
        .filter((el: any) => {
          if (el.para === dataPair) {
            return true;
          }
          return false;
        })
        .map((el: any) => {
          return {
            buy: (el.kup + (Math.random() * 2 * el.odchylenie - el.odchylenie)).toFixed(4),
            sell: (el.sprzedaj + (Math.random() * 2 * el.odchylenie - el.odchylenie)).toFixed(4),
          };
        })[0];
      return liveRateData;
    },
  },
  "/graph/indicatives": {
    GET: (): IndicativeGraph[] => {
      return [
        {
          pair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
          points: [
            { time: 1650561960000, mid: 4.6325 },
            { time: 1650563760000, mid: 4.6311 },
            { time: 1650565560000, mid: 4.6342 },
            { time: 1650567360000, mid: 4.6378 },
            { time: 1650569160000, mid: 4.641 },
            { time: 1650570960000, mid: 4.642 },
            { time: 1650572760000, mid: 4.642 },
            { time: 1650574560000, mid: 4.6413 },
            { time: 1650576360000, mid: 4.6413 },
            { time: 1650578160000, mid: 4.6403 },
            { time: 1650579960000, mid: 4.6386 },
            { time: 1650581760000, mid: 4.6384 },
            { time: 1650583560000, mid: 4.6398 },
            { time: 1650585360000, mid: 4.6398 },
            { time: 1650587160000, mid: 4.6381 },
            { time: 1650588960000, mid: 4.6381 },
            { time: 1650590760000, mid: 4.638 },
            { time: 1650592560000, mid: 4.6378 },
            { time: 1650594360000, mid: 4.6397 },
            { time: 1650596160000, mid: 4.6407 },
            { time: 1650597960000, mid: 4.6394 },
            { time: 1650599760000, mid: 4.6405 },
            { time: 1650601560000, mid: 4.6401 },
            { time: 1650603360000, mid: 4.6404 },
            { time: 1650605160000, mid: 4.6409 },
            { time: 1650606960000, mid: 4.6403 },
            { time: 1650608760000, mid: 4.6394 },
            { time: 1650610560000, mid: 4.64 },
            { time: 1650612360000, mid: 4.6387 },
            { time: 1650614160000, mid: 4.6392 },
            { time: 1650615960000, mid: 4.6335 },
            { time: 1650617760000, mid: 4.6349 },
            { time: 1650619560000, mid: 4.6414 },
            { time: 1650621360000, mid: 4.6364 },
            { time: 1650623160000, mid: 4.6396 },
            { time: 1650624960000, mid: 4.6366 },
            { time: 1650626760000, mid: 4.6404 },
            { time: 1650628560000, mid: 4.637 },
            { time: 1650630360000, mid: 4.6351 },
            { time: 1650632160000, mid: 4.6358 },
            { time: 1650633960000, mid: 4.6358 },
            { time: 1650635760000, mid: 4.6353 },
            { time: 1650637560000, mid: 4.6331 },
            { time: 1650639360000, mid: 4.6317 },
            { time: 1650641160000, mid: 4.6351 },
            { time: 1650642960000, mid: 4.6381 },
            { time: 1650644760000, mid: 4.6462 },
            { time: 1650646560000, mid: 4.6475 },
          ],
        },
        {
          pair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
          points: [
            { time: 1650561960000, mid: 4.2665 },
            { time: 1650563760000, mid: 4.273 },
            { time: 1650565560000, mid: 4.277 },
            { time: 1650567360000, mid: 4.2774 },
            { time: 1650569160000, mid: 4.2767 },
            { time: 1650570960000, mid: 4.2755 },
            { time: 1650572760000, mid: 4.2808 },
            { time: 1650574560000, mid: 4.2805 },
            { time: 1650576360000, mid: 4.284 },
            { time: 1650578160000, mid: 4.2834 },
            { time: 1650579960000, mid: 4.2786 },
            { time: 1650581760000, mid: 4.2803 },
            { time: 1650583560000, mid: 4.2817 },
            { time: 1650585360000, mid: 4.2828 },
            { time: 1650587160000, mid: 4.2802 },
            { time: 1650588960000, mid: 4.2789 },
            { time: 1650590760000, mid: 4.2799 },
            { time: 1650592560000, mid: 4.2803 },
            { time: 1650594360000, mid: 4.2813 },
            { time: 1650596160000, mid: 4.283 },
            { time: 1650597960000, mid: 4.2778 },
            { time: 1650599760000, mid: 4.2813 },
            { time: 1650601560000, mid: 4.2807 },
            { time: 1650603360000, mid: 4.28 },
            { time: 1650605160000, mid: 4.2809 },
            { time: 1650606960000, mid: 4.2805 },
            { time: 1650608760000, mid: 4.2776 },
            { time: 1650610560000, mid: 4.2786 },
            { time: 1650612360000, mid: 4.2781 },
            { time: 1650614160000, mid: 4.2775 },
            { time: 1650615960000, mid: 4.2728 },
            { time: 1650617760000, mid: 4.2798 },
            { time: 1650619560000, mid: 4.2921 },
            { time: 1650621360000, mid: 4.29 },
            { time: 1650623160000, mid: 4.2969 },
            { time: 1650624960000, mid: 4.2936 },
            { time: 1650626760000, mid: 4.2945 },
            { time: 1650628560000, mid: 4.2921 },
            { time: 1650630360000, mid: 4.2893 },
            { time: 1650632160000, mid: 4.2875 },
            { time: 1650633960000, mid: 4.2831 },
            { time: 1650635760000, mid: 4.2837 },
            { time: 1650637560000, mid: 4.2812 },
            { time: 1650639360000, mid: 4.2802 },
            { time: 1650641160000, mid: 4.2791 },
            { time: 1650642960000, mid: 4.2895 },
            { time: 1650644760000, mid: 4.3013 },
            { time: 1650646560000, mid: 4.3111 },
          ],
        },
        {
          pair: dataPrezentacjaWaluty.CHF + dataPrezentacjaWaluty.PLN,
          points: [
            { time: 1650561960000, mid: 4.7322 },
            { time: 1650563760000, mid: 4.7311 },
            { time: 1650565560000, mid: 4.7309 },
            { time: 1650567360000, mid: 4.7301 },
            { time: 1650569160000, mid: 4.73 },
            { time: 1650570960000, mid: 4.7289 },
            { time: 1650572760000, mid: 4.7277 },
            { time: 1650574560000, mid: 4.7255 },
            { time: 1650576360000, mid: 4.7244 },
            { time: 1650578160000, mid: 4.7242 },
            { time: 1650579960000, mid: 4.7235 },
            { time: 1650581760000, mid: 4.7222 },
            { time: 1650583560000, mid: 4.7219 },
            { time: 1650585360000, mid: 4.7216 },
            { time: 1650587160000, mid: 4.721 },
            { time: 1650588960000, mid: 4.7199 },
            { time: 1650590760000, mid: 4.72 },
            { time: 1650592560000, mid: 4.7202 },
            { time: 1650594360000, mid: 4.7209 },
            { time: 1650596160000, mid: 4.7211 },
            { time: 1650597960000, mid: 4.7233 },
            { time: 1650599760000, mid: 4.7266 },
            { time: 1650601560000, mid: 4.7288 },
            { time: 1650603360000, mid: 4.7298 },
            { time: 1650605160000, mid: 4.7301 },
            { time: 1650606960000, mid: 4.7305 },
            { time: 1650608760000, mid: 4.7304 },
            { time: 1650610560000, mid: 4.7302 },
            { time: 1650612360000, mid: 4.73 },
            { time: 1650614160000, mid: 4.7309 },
            { time: 1650615960000, mid: 4.7312 },
            { time: 1650617760000, mid: 4.7323 },
            { time: 1650619560000, mid: 4.7344 },
            { time: 1650621360000, mid: 4.7343 },
            { time: 1650623160000, mid: 4.7349 },
            { time: 1650624960000, mid: 4.7352 },
            { time: 1650626760000, mid: 4.7365 },
            { time: 1650628560000, mid: 4.7371 },
            { time: 1650630360000, mid: 4.7373 },
            { time: 1650632160000, mid: 4.738 },
            { time: 1650633960000, mid: 4.7394 },
            { time: 1650635760000, mid: 4.7401 },
            { time: 1650637560000, mid: 4.7389 },
            { time: 1650639360000, mid: 4.7355 },
            { time: 1650641160000, mid: 4.7344 },
            { time: 1650642960000, mid: 4.7348 },
            { time: 1650644760000, mid: 4.7352 },
            { time: 1650646560000, mid: 4.7361 },
          ],
        },
        {
          pair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.USD,
          points: [
            { time: 1650561960000, mid: 1.0858 },
            { time: 1650563760000, mid: 1.0838 },
            { time: 1650565560000, mid: 1.0835 },
            { time: 1650567360000, mid: 1.0843 },
            { time: 1650569160000, mid: 1.0852 },
            { time: 1650570960000, mid: 1.0857 },
            { time: 1650572760000, mid: 1.0844 },
            { time: 1650574560000, mid: 1.0843 },
            { time: 1650576360000, mid: 1.0834 },
            { time: 1650578160000, mid: 1.0833 },
            { time: 1650579960000, mid: 1.0842 },
            { time: 1650581760000, mid: 1.0837 },
            { time: 1650583560000, mid: 1.0836 },
            { time: 1650585360000, mid: 1.0834 },
            { time: 1650587160000, mid: 1.0836 },
            { time: 1650588960000, mid: 1.0839 },
            { time: 1650590760000, mid: 1.0837 },
            { time: 1650592560000, mid: 1.0835 },
            { time: 1650594360000, mid: 1.0837 },
            { time: 1650596160000, mid: 1.0835 },
            { time: 1650597960000, mid: 1.0845 },
            { time: 1650599760000, mid: 1.0839 },
            { time: 1650601560000, mid: 1.084 },
            { time: 1650603360000, mid: 1.0842 },
            { time: 1650605160000, mid: 1.0841 },
            { time: 1650606960000, mid: 1.0841 },
            { time: 1650608760000, mid: 1.0846 },
            { time: 1650610560000, mid: 1.0845 },
            { time: 1650612360000, mid: 1.0843 },
            { time: 1650614160000, mid: 1.0846 },
            { time: 1650615960000, mid: 1.0844 },
            { time: 1650617760000, mid: 1.083 },
            { time: 1650619560000, mid: 1.0814 },
            { time: 1650621360000, mid: 1.0807 },
            { time: 1650623160000, mid: 1.0798 },
            { time: 1650624960000, mid: 1.0799 },
            { time: 1650626760000, mid: 1.0806 },
            { time: 1650628560000, mid: 1.0804 },
            { time: 1650630360000, mid: 1.0806 },
            { time: 1650632160000, mid: 1.0812 },
            { time: 1650633960000, mid: 1.0824 },
            { time: 1650635760000, mid: 1.0821 },
            { time: 1650637560000, mid: 1.0822 },
            { time: 1650639360000, mid: 1.0821 },
            { time: 1650641160000, mid: 1.0832 },
            { time: 1650642960000, mid: 1.0813 },
            { time: 1650644760000, mid: 1.0802 },
            { time: 1650646560000, mid: 1.078 },
          ],
        },
        {
          pair: dataPrezentacjaWaluty.GBP + dataPrezentacjaWaluty.PLN,
          points: [
            { time: 1650561960000, mid: 5.2351 },
            { time: 1650563760000, mid: 5.2362 },
            { time: 1650565560000, mid: 5.2353 },
            { time: 1650567360000, mid: 5.2632 },
            { time: 1650569160000, mid: 5.2901 },
            { time: 1650570960000, mid: 5.201 },
            { time: 1650572760000, mid: 5.212 },
            { time: 1650574560000, mid: 5.23 },
            { time: 1650576360000, mid: 5.232 },
            { time: 1650578160000, mid: 5.234 },
            { time: 1650579960000, mid: 5.249 },
            { time: 1650581760000, mid: 5.2501 },
            { time: 1650583560000, mid: 5.2626 },
            { time: 1650585360000, mid: 5.2812 },
            { time: 1650587160000, mid: 5.3001 },
            { time: 1650588960000, mid: 5.309 },
            { time: 1650590760000, mid: 5.321 },
            { time: 1650592560000, mid: 5.3165 },
            { time: 1650594360000, mid: 5.3199 },
            { time: 1650596160000, mid: 5.325 },
            { time: 1650597960000, mid: 5.332 },
            { time: 1650599760000, mid: 5.335 },
            { time: 1650601560000, mid: 5.3346 },
            { time: 1650603360000, mid: 5.3341 },
            { time: 1650605160000, mid: 5.336 },
            { time: 1650606960000, mid: 5.3399 },
            { time: 1650608760000, mid: 5.3501 },
            { time: 1650610560000, mid: 5.355 },
            { time: 1650612360000, mid: 5.3589 },
            { time: 1650614160000, mid: 5.4012 },
            { time: 1650615960000, mid: 5.4016 },
            { time: 1650617760000, mid: 5.4033 },
            { time: 1650619560000, mid: 5.3872 },
            { time: 1650621360000, mid: 5.3866 },
            { time: 1650623160000, mid: 5.3901 },
            { time: 1650624960000, mid: 5.3844 },
            { time: 1650626760000, mid: 5.3821 },
            { time: 1650628560000, mid: 5.3601 },
            { time: 1650630360000, mid: 5.3587 },
            { time: 1650632160000, mid: 5.3322 },
            { time: 1650633960000, mid: 5.3365 },
            { time: 1650635760000, mid: 5.3344 },
            { time: 1650637560000, mid: 5.3212 },
            { time: 1650639360000, mid: 5.3317 },
            { time: 1650641160000, mid: 5.3311 },
            { time: 1650642960000, mid: 5.3301 },
            { time: 1650644760000, mid: 5.3285 },
            { time: 1650646560000, mid: 5.3244 },
          ],
        },
      ];
    },
  },
  "/graph/config": {
    GET: (): GraphButtonConfig[][] => {
      return [
        [
          { interval: "minute", count: 1440 },
          { interval: "minute", count: 10080 },
          { interval: "minute", count: 45420 },
          { interval: "minute", count: 132480 },
          { interval: "minute", count: 528480 },
        ],
        [
          { interval: "minute", count: 1440 },
          { interval: "minute", count: 10080 },
          { interval: "minute", count: 45420 },
          { interval: "minute", count: 132480 },
          { interval: "minute", count: 528480 },
        ],
      ];
    },
  },
  "/graph/data": {
    GET: (data: any): GraphPoint[] => {
      const currencyPair = data.rest[0] ?? "_ERROR";
      if (data?.service?.data) {
        Object.entries(data.service.data as Record<string, GraphPoint[]>).forEach((el) => {
          if (commonData.idDashboardSimple) {
            el[1] = el[1].sort((gp1: any, gp2: any) => gp1.closeTime - gp2.closeTime);
          } else {
            if (commonData.isGraphLinear) {
              el[1] = el[1].sort((gp1: any, gp2: any) => gp1.closeTime - gp2.closeTime);
            } else {
              el[1] = el[1].sort((gp1: any, gp2: any) => gp1.openTime - gp2.openTime);
            }
          }
        });
      }
      try {
        if (currencyPair) {
          if (data.rest[2] === "0") {
            let dataToReturn: GraphPoint[] = [];
            // ver 0
            // dataToReturn = commonData["/graph/" + currencyPair];    // -- usunąć WERSJA_BRAK_SORTOWANIA

            // ver 1
            // if (commonData.isGraphLinear) {
            //     dataToReturn = commonData["/graph/" + currencyPair].sort((gp1: any, gp2: any) => gp1.closeTime - gp2.closeTime)
            // } else {
            //     dataToReturn = commonData["/graph/" + currencyPair].sort((gp1: any, gp2: any) => gp1.openTime - gp2.openTime)
            // }

            // ver 2
            if (commonData.idDashboardSimple) {
              dataToReturn = commonData["/graph/" + currencyPair].sort(
                (gp1: any, gp2: any) => gp1.closeTime - gp2.closeTime
              );
            } else {
              if (commonData.isGraphLinear) {
                dataToReturn = commonData["/graph/" + currencyPair].sort(
                  (gp1: any, gp2: any) => gp1.closeTime - gp2.closeTime
                );
              } else {
                dataToReturn = commonData["/graph/" + currencyPair].sort(
                  (gp1: any, gp2: any) => gp1.openTime - gp2.openTime
                );
              }
            }

            // ##########################
            // ### Wizualizacja dodania tylko danych z dnia dzisiejszego
            // ##########################
            // dataToReturn.push({
            //     openTime: new Date().getTime() - 1000,
            //     closeTime: new Date().getTime(),
            //     openBid: 2,
            //     openAsk: 2.1,
            //     closeBid: 2.05,
            //     closeAsk: 2.15,
            //     highBid: 2.4,
            //     highAsk: 2.5,
            //     lowBid: 1.9,
            //     lowAsk: 2.0,
            // })

            return dataToReturn;
          } else {
            return [];
          }
        }
      } catch (exc) {
        console.error("/graph/data ERROR ", exc);
      }
      return [];
    },
  },
  "/graph/pairs": {
    // zwraca to samo co    /currency/pairs
    GET: (): CurrencyPair[] => {
      return PrezentacjaDane["/currency/pairs"]["GET"]({});
    },
  },
  "/graph/blotter": {
    GET: (data: any): BlotterCurrency[] => {
      let fullDatesSet: string[] = [];
      const caluclateBlotter = (currency: string): BlotterCurrency => {
        const elBlotterDate: BlotterDate[] = [];

        const dataSource: UnsettledDealHistory[] = PrezentacjaDane["/history/unsettled"]
          ["GET"]({})
          .filter((el: UnsettledDealHistory) => {
            if (el.currency === currency || el.counterCurrency === currency) {
              return true;
            }
            return false;
          });
        const datesSet: string[] = Array.from(
          new Set(
            dataSource.map((el: UnsettledDealHistory) => {
              return el.settlementDate;
            })
          )
        );
        fullDatesSet = Array.from(new Set([...fullDatesSet, ...datesSet]));
        datesSet.forEach((date) => {
          let minValue = 0;
          let maxValue = 0;
          dataSource
            .filter((el) => {
              if (el.settlementDate === date) return true;
              return false;
            })
            .forEach((el) => {
              if (el.side === "Sell") {
                minValue = minValue - (el.currency === currency ? el.amount : -el.counterAmount);
              } else {
                maxValue = maxValue + (el.currency === currency ? el.amount : -el.counterAmount);
              }
            });

          elBlotterDate.push({
            date: new Date(date), // wersja bez kontrolii weekendu
            // date: new Date(calculateTenorDate(new Date(date))),
            values: [minValue, maxValue],
          });
        });

        return {
          currency: currency,
          dates: elBlotterDate,
        };
      };

      const dataCurrencies = (data.params as HttpParams).getAll("currencies");

      let dataToReturn: BlotterCurrency[] = [];

      if (dataCurrencies)
        dataCurrencies.forEach((el: string) => {
          dataToReturn.push(caluclateBlotter(el));
        });

      dataToReturn = dataToReturn.map((el) => {
        const elementDates = el.dates.map((el2) => el2.date.toISOString());
        fullDatesSet.forEach((fullDate) => {
          if (!elementDates.includes(fullDate))
            el.dates.push({
              date: new Date(fullDate), // calculateTenorDate
              values: [],
            });
        });
        return el;
      });

      dataToReturn = dataToReturn.map((el) => {
        el.dates.sort((el1, el2) => {
          if (new Date(el1.date) > new Date(el2.date)) {
            // calculateTenorDate
            return 1;
          }
          return -1;
        });
        return el;
      });

      return dataToReturn;
    },
  },
  //------------------------------------
  //------------------ exchange
  //-----------------------------------
  "/exchange": {
    POST: (data: any): InitializationResponse => {
      dataExchangeRateSwapPairs = false;
      if (data.body.consents) {
        dataExchangeConsents.push(...data.body.consents);
      }
      if (!dataExchangeConsents.includes("HasSimilarDeal") && checkIfIsDoubleExchange(data.body)) {
        return {
          warnings: [
            {
              code: "HasSimilarDeal",
              data: {
                similarDeals: JSON.stringify([
                  {
                    DealId: "12349876",
                    Currency: data.body.currency,
                    CurrencyPair: `${data.body.currency}${data.body.counterCurrency}`,
                    Amount: Number(data.body.amount),
                    DealDate: currentDate.toISOString(),
                    DealType: data.body.product,
                    //  "All": "Wszystkie",
                    // "FXON": "FX TOD",
                    // "FXTN": "FX TOM",
                    // "FX ON": "FX TOD",
                    // "FX TN": "FX TOM",
                    // "SPOT": "FX Spot",
                    // "FXSPOT": "FX Spot",
                    // "FX SPOT": "FX Spot",
                    // "FXFWD": "FX Forward",
                    // "FORW": "FX Forward",
                    // "FXSWAP": "FX Swap",
                    // "FSWP": "FX Swap"
                    Status: "Unsettled", // "Unsettled" | "All" | "Settled" | "Undefined" | "Terminated"
                  },
                ]),
              },
            },
          ],
        } as unknown as InitializationResponse;
      }
      dataExchange = data.body;
      dataExchange.currencyPair = changePairToRealCurrencyPair(
        data.body.currency + data.body.counterCurrency
      ); // -- NEW LINE
      dataExchangeRate = calculateExchangeRate(dataExchange.currencyPair, dataExchange.side);
      if (data.body.currency + data.body.counterCurrency + "" !== dataExchange.currencyPair) {
        // dataExchangeRate = +(1 / dataExchangeRate).toFixed(4);
        dataExchangeRateSwapPairs = true;
        dataExchangeRate = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Buy" : "Sell"
        );
      }
      dataExchangeIsFisible = calculateExchangeFisibility(dataExchange);

      if (dataExchange.product === "FxForw" && dataExchange.collateral.type === "Deposit") {
        try {
          /*
            dataExchangeCollateralAmount   -- EW jako 5% transakcji
            */
          let depositCurrencyRate = 1;
          const depositCurrency =
            dataAccount.filter((el) => el.number === data.body?.collateral?.account)?.[0]
              ?.currency ?? "PLN";
          if (data.body.currency !== depositCurrency) {
            depositCurrencyRate = calculateExchangeRate(
              `${data.body.currency}${depositCurrency}`,
              dataExchange.side
            );
          }

          dataExchangeCollateralAmount =
            (+dataExchange.amount *
              depositCurrencyRate *
              caluclateCollateralAmountTenorsProcentage(
                Math.floor(
                  (new Date(dataExchange.settlement.date).getTime() - currentDate.getTime()) /
                    86400000
                )
              )) /
            100;
        } catch (exc) {
          dataExchangeCollateralAmount = 0;
        }
      } else {
        dataExchangeCollateralAmount = 0;
      }

      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        !dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          error: {
            code: "InsufficientFundsInfo",
          },
        };
      }
      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          warnings: [
            {
              code: "InsufficientFunds",
            },
          ],
        } as unknown as InitializationResponse;
      }
      return {
        token: "10076971",
      };
    },
  },
  "/exchange/rate": {
    GET: (data: any): RateResponse<ExchangeRateSuccess> => {
      const tokenId = data.params.get("token");
      const dataToReturn =
        dataExchangeNetSettlementAmount > 0
          ? {
              token: tokenId,
              decisionTime: 30,
              responseType: RESPONSE_TYPE.success, // "PRICE", "DEALERWAIT", "RETRY"
              rate: dataExchangeRate,
              counterAmount: dataExchangeRateSwapPairs
                ? +(dataExchange.amount / dataExchangeRate).toFixed(2)
                : dataExchange.amount * dataExchangeRate,
              collateralAmount: dataExchangeCollateralAmount, // ??? czy to implementować        [ILE]
              hasInsufficientFunds: !dataExchangeIsFisible, // ??? czy to potrzebne
              netSettlementAmount: dataExchangeNetSettlementAmount,
            }
          : {
              token: tokenId,
              decisionTime: 30,
              responseType: RESPONSE_TYPE.success, // "PRICE", "DEALERWAIT", "RETRY"
              rate: dataExchangeRate,
              counterAmount: dataExchangeRateSwapPairs
                ? +(dataExchange.amount / dataExchangeRate).toFixed(2)
                : dataExchange.amount * dataExchangeRate,
              collateralAmount: dataExchangeCollateralAmount, // ??? czy to implementować
              hasInsufficientFunds: !dataExchangeIsFisible, // ??? czy to potrzebne
            };
      return dataToReturn;
    },
  },
  "/exchange/reject": {
    POST: (): void => {
      // const tokenId = data.body.token;
      dataExchange = {};
      dataExchangeConsents = [];
      dataExchangeRate = 0;
      dataExchangeRateFar = 0;
      dataExchangeNetSettlementAmount = 0;
      dataExchangeIsFisible = true;
    },
  },
  "/exchange/confirm": {
    POST: (data: any): string => {
      const tokendId = data.body.transactionToken;
      const { currency, counterCurrency, amount, product } = dataExchange;
      const uniqueExchange = `${currency}-${counterCurrency}-${amount}-${product}`;
      dataExchangeSet.add(uniqueExchange);
      dataExchange = {};
      dataExchangeConsents = [];
      dataExchangeRate = 0;
      dataExchangeRateFar = 0;
      dataExchangeNetSettlementAmount = 0;
      dataExchangeIsFisible = true;
      return tokendId + "";
    },
  },
  "/exchange/status": {
    GET: (data: any): StatusResponse => {
      const tokenId = data.params.get("token");
      return {
        transactionId: tokenId,
      };
    },
  },
  "/exchange/collateral": {
    // ???
    GET: (data: any): CollateralType[] => {
      const product = data?.params?.get("product") ?? "FxOn";
      if (product === "FxForw" || product === "FxSwap")
        // FxOn | FxForw | FxSwap
        return [
          COLLATERAL_TYPES.limit,
          COLLATERAL_TYPES.deposit,
          //COLLATERAL_TYPES.block,      //  "Limit" | "Deposit" | "AmountBlock"
        ];
      return [];
    },
  },
  "/exchange/tenors": {
    GET: (data: any): TenorDate[] => {
      // daty sa generowane
      const currencyPair = data.params.get("key");
      const dataToReturn: TenorDate[] = [];
      const currency1 = currencyPair.slice(0, 3);
      const currency2 = currencyPair.slice(3);

      const holidys1 = PrezentacjaDane["/currency/holidays"]
        ["GET"]({})
        [currency1].map((el: string) => el.slice(0, 10));
      const holidys2 = PrezentacjaDane["/currency/holidays"]
        ["GET"]({})
        [currency2].map((el: string) => el.slice(0, 10));
      const tenors = [
        {
          tenor: "TOD",
          date: calculateTenorDate(calculateDate(0, 0, 0)),
        },
        {
          tenor: "TOM",
          date: calculateTenorDate(calculateDate(0, 0, 1)),
        },
        {
          tenor: "SPOT",
          date: calculateTenorDate(calculateDate(0, 0, 2)),
        },
        {
          tenor: "SN",
          date: calculateTenorDate(calculateDate(0, 0, 3)),
        },
        {
          tenor: "SW",
          date: calculateTenorDate(calculateDate(0, 0, 9)),
        },
        {
          tenor: "2W",
          date: calculateTenorDate(calculateDate(0, 0, 14)),
        },
        {
          tenor: "3W",
          date: calculateTenorDate(calculateDate(0, 0, 21)),
        },
        {
          tenor: "1M",
          date: calculateTenorDate(calculateDate(0, 1, 0)),
        },
        {
          tenor: "2M",
          date: calculateTenorDate(calculateDate(0, 2, 0)),
        },
        {
          tenor: "3M",
          date: calculateTenorDate(calculateDate(0, 3, 0)),
        },
        {
          tenor: "6M",
          date: calculateTenorDate(calculateDate(0, 6, 0)),
        },
        {
          tenor: "9M",
          date: calculateTenorDate(calculateDate(0, 9, 0)),
        },
        {
          tenor: "12M",
          date: calculateTenorDate(calculateDate(1, 0, 0)),
        },
      ];
      tenors.forEach((el, id) => {
        let isOk = false;
        let elDate = el.date;
        let prevElDateToCheck = "";
        if (id >= 1) {
          prevElDateToCheck = tenors[id - 1].date.slice(0, 10);
        }
        let dateToCheck = "";
        while (isOk === false) {
          dateToCheck = elDate.slice(0, 10);
          if (new Date(prevElDateToCheck).getTime() > new Date(dateToCheck).getTime()) {
            elDate = calculateTenorDate(new Date(new Date(elDate).getTime() + 86400000));
          } else {
            if (
              holidys1.includes(dateToCheck) ||
              holidys2.includes(dateToCheck) ||
              prevElDateToCheck === dateToCheck
            ) {
              elDate = calculateTenorDate(new Date(new Date(elDate).getTime() + 86400000));
            } else {
              isOk = true;
              el.date = elDate;
            }
          }
        }
        dataToReturn.push(el);
      });
      return dataToReturn;
    },
  },
  "/exchange/ndf": {
    // ZMIANY NA HISTORII TYLKO DLA TRANZAKCJI --  120000023
    POST: (data: any): ChangeNdfResponse => {
      const dataNdf = data.body;
      if (dataNdf.id == "120000023") {
        dataExchangeArray = dataExchangeArray.map((el) => {
          if (el.id == dataNdf.id) {
            el.details$ = of({
              id: "120000023",
              transactionDate: calculateDate(0, 0, -10),
              // subtype: "",
              subtype: dataNdf.ndf ? "NDF" : "",
              side: "Sell", // "Sell" | "Buy"
              amount: 8000.0,
              currency: dataPrezentacjaWaluty.CHF,
              counterAmount: 35762.4,
              counterCurrency: dataPrezentacjaWaluty.PLN,
              rate: 4.4703,
              status: statuses[2], // ["All", "Settled", "Unsettled", "Undefined", "Terminated"]
              settlementDate: calculateDate(0, 1, 0).toISOString(),
              openBalance: 8000.0, // TO SŁUŻY DO ZAMKNIĘCIA TRANZAKCJI !!! najlepiej      openBalance === amount
              //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
              documentId: "",
              currencyPair: dataPrezentacjaWaluty.CHF + dataPrezentacjaWaluty.PLN,
              collateral: {
                type: "Deposit", //  "Limit" | "Deposit" | "AmountBlock"
                account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.CHF)[0],
                amount: 400,
                currency: dataPrezentacjaWaluty.CHF,
              },
              product: PRODUCT_TYPES.forward,
              linkedDeals: [],
              pvp: {
                value: false,
                visible: false,
              }, // ??
              //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EXCHANGE_DETAILS
              account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.CHF)[0],
              counterAccount: dataAccount.filter(
                (el) => el.currency === dataPrezentacjaWaluty.PLN
              )[0],
              // orderId?: number,
              linkedTransactionNr: "",
              netSettlement: dataNdf.ndf, // Czy zaznacza 'Roliczenie na netto'
              referenceRate: "Market Rate", // ["Market Rate","NBP Fixing"]
              ndfModifiable: true, // Umożliwia zmiane ROZLICZENIA z NETTO na BRUTTO
              netSettlementAmount: 8000.0,
              netSettlementCurrency: dataPrezentacjaWaluty.CHF,
              closeVisible: true, // Umożliwia opcje 'Zamknij tranzakcje'
              rollVisible: true, // jak brutto to aktywny [jak netto to nie aktywny]
              rollData: dataNdf.ndf
                ? {
                    rollDisabledReason: "Deal_Leg_is_an_NDF",
                    isHistoricalRollBackEnabled: false,
                    isHistoricalRollOverEnabled: false,
                    isMarketRollBackEnabled: false,
                    isMarketRollOverEnabled: false,
                    rateTypes: ["Historical", "Market"],
                  }
                : {
                    isHistoricalRollBackEnabled: true,
                    isHistoricalRollOverEnabled: true,
                    isMarketRollBackEnabled: true,
                    isMarketRollOverEnabled: true,
                    rateTypes: ["Historical", "Market"],
                  },
              // dpwReference?: string,
              attorney: "", // << -- zmiana
              openAmountCurrencyFTOOne: "", // << -- zmiana
              openAmountCurrencyFTOTwo: "", // << -- zmiana
              buyPricingCurrency: "", // << -- zmiana
              sellPricingCurrency: "", // << -- zmiana
              pricingCurrency: "", // << -- zmiana
              settlementType: "", // << -- zmiana
              hasConfirmation: true, // << -- zmiana
              type: "", // << -- zmiana
            } as ExchangeDetails);
          }
          return el;
        });
      }
      return {
        success: true,
        failure: {
          code: "",
        },
      };
    },
  },
  "/exchange/roll": {
    POST: (data: any): InitializationResponse => {
      // ####################################################
      // ######################### PRZY ROLU WSTECZ bez wskazywania zabezpieczenia !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      // ##############################################################################

      dataExchangeRateSwapPairs = false;
      dataExchange = data.body;
      dataExchange.currencyPair = changePairToRealCurrencyPair(
        data.body.currency + data.body.counterCurrency
      );
      // PrezentacjaDane["/indicatives/rates"]["GET"]({})      // odświżenie kursu walut
      dataExchangeRate = calculateExchangeRate(dataExchange.currencyPair, dataExchange.side);
      dataExchangeRateFar = calculateExchangeRate(
        dataExchange.currencyPair,
        dataExchange.side === "Sell" ? "Buy" : "Sell"
      );

      if (data.body.currency + data.body.counterCurrency + "" !== dataExchange.currencyPair) {
        // dataExchangeRate = +(1 / dataExchangeRate).toFixed(4);
        // dataExchangeRateFar = +(1 / dataExchangeRateFar).toFixed(4);

        dataExchangeRateSwapPairs = true;

        dataExchangeRate = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Buy" : "Sell"
        );
        dataExchangeRateFar = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Sell" : "Buy"
        );
      }

      if (dataExchange.collateral.type === "Deposit") {
        try {
          const dateToUse = new Date(dataExchange.settlement.date).getTime();
          /*
            dataExchangeCollateralAmount   -- EW jako 5% transakcji
            */

          let depositCurrencyRate = 1;
          const depositCurrency =
            dataAccount.filter((el) => el.number === dataExchange.collateral?.account)?.[0]
              ?.currency ?? "PLN";
          if (data.body.currency !== depositCurrency) {
            depositCurrencyRate = calculateExchangeRate(
              `${data.body.currency}${depositCurrency}`,
              dataExchange.side
            );
          }

          dataExchangeCollateralAmount =
            (+dataExchange.amount *
              depositCurrencyRate *
              caluclateCollateralAmountTenorsProcentage(
                Math.floor(
                  (new Date(dataExchange.settlement.date).getTime() - currentDate.getTime()) /
                    86400000
                )
              )) /
            100;
        } catch (exc) {
          dataExchangeCollateralAmount = 0;
        }
      } else {
        dataExchangeCollateralAmount = 0;
      }

      if (new Date(dataExchange.original.settlementDate) < new Date(dataExchange.settlement.date)) {
        if (dataExchange.rateType === "Historical") {
          dataExchangeNetSettlementAmount = 0;
          dataExchangeRate = dataExchange.original.rate;
        } else {
          // dataExchangeNetSettlementAmount = dataExchangeRateSwapPairs
          //   ? +(+dataExchange.amount / (dataExchangeRate - +dataExchange.original.rate)).toFixed(2)
          //   : +dataExchange.amount * (dataExchangeRate - +dataExchange.original.rate);
          dataExchangeNetSettlementAmount =
            +dataExchange.amount * (dataExchangeRate - +dataExchange.original.rate);
        }
      } else {
        if (dataExchange.rateType === "Historical") {
          dataExchangeNetSettlementAmount = 0;
          dataExchangeRateFar = dataExchange.original.rate;
        } else {
          // dataExchangeNetSettlementAmount = dataExchangeRateSwapPairs
          //   ? +(+dataExchange.amount / (dataExchangeRateFar - +dataExchange.original.rate)).toFixed(
          //       2
          //     )
          //   : +dataExchange.amount * (dataExchangeRateFar - +dataExchange.original.rate);
          dataExchangeNetSettlementAmount =
            +dataExchange.amount * (dataExchangeRateFar - +dataExchange.original.rate);
        }
      }
      // dataExchangeIsFisible = calculateExchangeFisibility(dataExchange);
      dataExchangeIsFisible = true;
      return {
        token: dataExchange.id,
        // error: {
        //     code: ""
        // },
      };
    },
  },
  "/exchange/close": {
    POST: (data: any): InitializationResponse => {
      dataExchangeRateSwapPairs = false;
      dataExchange = data.body;
      dataExchange.currencyPair = changePairToRealCurrencyPair(
        data.body.currency + data.body.counterCurrency
      );
      // PrezentacjaDane["/indicatives/rates"]["GET"]({})      // odświżenie kursu walut
      dataExchangeRate = calculateExchangeRate(dataExchange.currencyPair, dataExchange.side);
      // dataExchangeIsFisible = calculateExchangeFisibility(dataExchange);
      if (data.body.currency + data.body.counterCurrency + "" !== dataExchange.currencyPair) {
        // dataExchangeRate = +(1 / dataExchangeRate).toFixed(4);
        dataExchangeRateSwapPairs = true;
        dataExchangeRate = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Buy" : "Sell"
        );
      }
      dataExchangeIsFisible = true;

      dataExchangeNetSettlementAmount = dataExchangeRateSwapPairs
        ? +(+dataExchange.amount / (dataExchangeRate - +dataExchange.original.rate)).toFixed(2)
        : +dataExchange.amount * (dataExchangeRate - +dataExchange.original.rate);
      return {
        token: dataExchange.id,
      };
    },
  },
  "/exchange/validateMifid": {
    GET: (data: any): ComplexInitializationResponse => {
      return {
        token: "",
      };
    },
  },
  "/exchangehistory": {
    // bazuje na dataExchangeArray
    GET: (data: any): PageData<ExchangeHistoryItem> => {
      let filterId: string | undefined = undefined;
      if (data.params) {
        // pageNumber = data?.params.get("pageNumber");
        // filterType = data?.params.get("type");
        // filterCurrency = data?.params.get("currency");
        filterId = data?.params.get("id");
      }
      return {
        items: dataExchangeArray.filter((el) => {
          if (filterId) {
            if (el.id.includes(filterId ?? "")) {
              return true;
            }
            return false;
          } else {
            if (el.id === "120000001" || el.id === "120000002") {
              return false;
            }
            return true;
          }
          return true;
        }),
        isLastPage: true,
      };
    },
  },
  "/exchangehistory/openbalance": {
    // TO_DO ???
    GET: (data: any): PageData<ExchangeHistoryItem> => {
      const settlementStatus = data.params.get("settlementStatus"); // Unsettled , All
      const openBalance = data.params.get("openBalance"); // true, false
      const exchangeArray: ExchangeHistoryItem[] = dataExchangeArray.filter(
        (el: ExchangeHistoryItem) => {
          if (openBalance !== "true" && settlementStatus === "All") {
            return true;
          } else {
            if (openBalance === "true") {
              if (el.openBalance > 0) {
                if (
                  settlementStatus === "All" ||
                  (settlementStatus === "Unsettled" && el.status === settlementStatus)
                ) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              if (el.status === settlementStatus) {
                return true;
              }
              return false;
            }
          }
        }
      );
      return {
        items: exchangeArray,
        isLastPage: true,
      };
    },
  },
  "/exchangehistory/settlementsToday": {
    GET: (): PageData<SettlementsGroupedByDate> => {
      const mappingHelpObj: any = {};
      const todaysSettlementsByPair: SettlementsGroupedByPair[] = [];
      dataExchangeArray.forEach((el: ExchangeHistoryItem) => {
        if (el.settlementDate.toDateString() === new Date().toDateString()) {
          if (
            mappingHelpObj[changePairToRealCurrencyPair(el.currency + el.counterCurrency) as string]
          ) {
            mappingHelpObj[
              changePairToRealCurrencyPair(el.currency + el.counterCurrency) as string
            ].push(el);
          } else {
            mappingHelpObj[
              changePairToRealCurrencyPair(el.currency + el.counterCurrency) as string
            ] = [el];
          }
        }
      });

      Object.entries(mappingHelpObj).forEach((item) => {
        let tempCurrencySum = 0;
        let tempCounterCurrencySum = 0;
        (item[1] as ExchangeHistoryItem[]).forEach((item2: ExchangeHistoryItem) => {
          tempCurrencySum =
            tempCurrencySum + (item2.side === "Sell" ? -1 * item2.amount : item2.amount);
          tempCounterCurrencySum =
            tempCounterCurrencySum +
            (item2.side === "Sell" ? item2.counterAmount : -1 * item2.counterAmount);
        });
        todaysSettlementsByPair.push({
          isExpanded: false,
          currencyPair: item[0],
          deals: item[1] as ExchangeHistoryItem[],
          currencySum: tempCurrencySum,
          counterCurrencySum: tempCounterCurrencySum,
        });
      });
      return {
        items: todaysSettlementsByPair.length
          ? [
              {
                settlementDate: new Date(), // Date;
                settlementsByPair: todaysSettlementsByPair, // SettlementsGroupedByPair[]
              },
            ]
          : [],
        isLastPage: true,
      };
    },
  },
  "/exchangehistory/settlementsToday/csv": {
    GET: (): ExchangeHistoryItem[] => {
      return dataExchangeArray.filter((el: ExchangeHistoryItem) => {
        if (el.settlementDate.toDateString() === new Date().toDateString()) {
          return true;
        }
        return false;
      });
    },
  },
  "/exchangehistory/settlements": {
    GET: (): PageData<SettlementsGroupedByDate> => {
      const mappingHelpObj: any = {};
      const settlementsByDate: SettlementsGroupedByDate[] = [];
      dataExchangeArray.forEach((el: ExchangeHistoryItem) => {
        if (el.settlementDate.getTime() > new Date().getTime()) {
          if (mappingHelpObj[el.settlementDate.toDateString()]) {
            if (
              mappingHelpObj?.[el.settlementDate.toDateString()]?.[
                changePairToRealCurrencyPair(el.currency + el.counterCurrency) as string
              ]
            ) {
              mappingHelpObj[el.settlementDate.toDateString()][
                changePairToRealCurrencyPair(el.currency + el.counterCurrency) as string
              ].push(el);
            } else {
              mappingHelpObj[el.settlementDate.toDateString()][
                changePairToRealCurrencyPair(el.currency + el.counterCurrency) as string
              ] = [el];
            }
          } else {
            mappingHelpObj[el.settlementDate.toDateString()] = {};
            mappingHelpObj[el.settlementDate.toDateString()][
              changePairToRealCurrencyPair(el.currency + el.counterCurrency) as string
            ] = [el];
          }
        }
      });
      Object.entries(mappingHelpObj).forEach((item) => {
        const settlementsByPair: SettlementsGroupedByPair[] = [];
        Object.entries(item[1] as any).forEach((item2) => {
          let tempCurrencySum = 0;
          let tempCounterCurrencySum = 0;
          (item2[1] as ExchangeHistoryItem[]).forEach((item3: ExchangeHistoryItem) => {
            tempCurrencySum =
              tempCurrencySum + (item3.side === "Sell" ? -1 * item3.amount : item3.amount);
            tempCounterCurrencySum =
              tempCounterCurrencySum +
              (item3.side === "Sell" ? item3.counterAmount : -1 * item3.counterAmount);
          });
          settlementsByPair.push({
            isExpanded: false,
            currencyPair: item2[0],
            deals: item2[1] as ExchangeHistoryItem[],
            currencySum: tempCurrencySum,
            counterCurrencySum: tempCounterCurrencySum,
          });
        });

        settlementsByDate.push({
          settlementDate: new Date(item[0]),
          settlementsByPair: settlementsByPair,
        });
      });
      return {
        items: settlementsByDate.length
          ? settlementsByDate.sort(
              (el1, el2) => el1.settlementDate.getTime() - el2.settlementDate.getTime()
            )
          : [],
        isLastPage: true,
      };
    },
  },
  "/exchangehistory/settlements/csv": {
    GET: (): ExchangeHistoryItem[] => {
      return dataExchangeArray
        .filter((el: ExchangeHistoryItem) => {
          if (el.settlementDate.getTime() > new Date().getTime()) {
            return true;
          }
          return false;
        })
        .sort((el1, el2) => el1.settlementDate.getTime() - el2.settlementDate.getTime());
    },
  },
  //############################################################################################################### ZAKRES KART POZA PULPITEM
  //___________________________________________________ TRANZAKCJE
  //------------------------------------
  //------------------ swap
  //-----------------------------------
  "/swap": {
    POST: (data: any): InitializationResponse => {
      dataExchangeRateSwapPairs = false;
      if (data.body.consents) {
        dataExchangeConsents.push(...data.body.consents);
      }
      if (!dataExchangeConsents.includes("HasSimilarDeal") && checkIfIsDoubleExchange(data.body)) {
        return {
          warnings: [
            {
              code: "HasSimilarDeal",
              data: {
                similarDeals: JSON.stringify([
                  {
                    DealId: "12349876",
                    Currency: data.body.currency,
                    CurrencyPair: `${data.body.currency}${data.body.counterCurrency}`,
                    Amount: Number(data.body.amount),
                    DealDate: currentDate.toISOString(),
                    DealType: data.body.product,
                    //  "All": "Wszystkie",
                    // "FXON": "FX TOD",
                    // "FXTN": "FX TOM",
                    // "FX ON": "FX TOD",
                    // "FX TN": "FX TOM",
                    // "SPOT": "FX Spot",
                    // "FXSPOT": "FX Spot",
                    // "FX SPOT": "FX Spot",
                    // "FXFWD": "FX Forward",
                    // "FORW": "FX Forward",
                    // "FXSWAP": "FX Swap",
                    // "FSWP": "FX Swap"
                    Status: "Unsettled", // "Unsettled" | "All" | "Settled" | "Undefined" | "Terminated"
                  },
                ]),
              },
            },
          ],
        } as unknown as InitializationResponse;
      }

      dataExchange = data.body;
      dataExchange.currencyPair = changePairToRealCurrencyPair(
        data.body.currency + data.body.counterCurrency
      ); // -- NEW LINE
      dataExchangeRate = calculateExchangeRate(dataExchange.currencyPair, dataExchange.side);
      dataExchangeRateFar = calculateExchangeRate(
        dataExchange.currencyPair,
        dataExchange.side === "Sell" ? "Buy" : "Sell"
      );
      dataExchangeIsFisible = calculateExchangeFisibility(dataExchange);

      if (data.body.currency + data.body.counterCurrency + "" !== dataExchange.currencyPair) {
        // dataExchangeRate = +(1 / dataExchangeRate).toFixed(4);
        // dataExchangeRateFar = +(1 / dataExchangeRateFar).toFixed(4);

        dataExchangeRateSwapPairs = true;
        dataExchangeRate = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Buy" : "Sell"
        );
        dataExchangeRateFar = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Sell" : "Buy"
        );
      }
      dataExchangeIsFisible = calculateExchangeFisibility(dataExchange);

      if (dataExchange.collateral.type === "Deposit") {
        try {
          /*
            dataExchangeCollateralAmount   -- EW jako 5% transakcji
            */

          let depositCurrencyRate = 1;
          const depositCurrency =
            dataAccount.filter((el) => el.number === dataExchange.collateral?.account)?.[0]
              ?.currency ?? "PLN";
          if (data.body.currency !== depositCurrency) {
            depositCurrencyRate = calculateExchangeRate(
              `${data.body.currency}${depositCurrency}`,
              dataExchange.side
            );
          }

          dataExchangeCollateralAmount =
            (+dataExchange.amount *
              depositCurrencyRate *
              caluclateCollateralAmountTenorsProcentage(
                Math.floor(
                  (new Date(dataExchange.farSettlement.date).getTime() - currentDate.getTime()) /
                    86400000
                )
              )) /
            100;
        } catch (exc) {
          dataExchangeCollateralAmount = 0;
        }
      } else {
        dataExchangeCollateralAmount = 0;
      }

      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        !dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          error: {
            code: "InsufficientFundsInfo",
          },
        } as unknown as InitializationResponse;
      }
      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          warnings: [
            {
              code: "InsufficientFunds",
            },
          ],
        } as unknown as InitializationResponse;
      }

      return {
        token: "10077053",
      };
    },
  },
  "/swap/rate": {
    GET: (data: any): RateResponse<ExchangeRateSuccess> => {
      const tokenId = data.params.get("token");
      return {
        token: tokenId,
        decisionTime: 30,
        responseType: RESPONSE_TYPE.success,
        rate: dataExchangeRate,
        counterAmount: dataExchangeRateSwapPairs
          ? +(dataExchange.amount / dataExchangeRate).toFixed(2)
          : dataExchange.amount * dataExchangeRate,
        farRate: dataExchangeRateFar,
        farCounterAmount: dataExchangeRateSwapPairs
          ? +(dataExchange.amount / dataExchangeRateFar).toFixed(2)
          : dataExchange.amount * dataExchangeRateFar,
        // collateralAmount: 0,
        collateralAmount: dataExchangeCollateralAmount,
        netSettlementAmount: dataExchangeNetSettlementAmount,
        hasInsufficientFunds: !dataExchangeIsFisible, // ??? czy to potrzebne
      };
    },
  },
  //------------------------------------
  //------------------ iob
  //-----------------------------------
  "/iob": {
    POST: (data: any): InitializationResponse => {
      dataExchangeRateSwapPairs = false;
      dataExchange = data.body;
      dataExchange.collateral.type = "Limit"; // ???????
      dataExchange.currencyPair = changePairToRealCurrencyPair(
        data.body.currency + data.body.counterCurrency
      );
      dataExchangeRate = calculateExchangeRate(dataExchange.currencyPair, dataExchange.side);
      dataExchangeIsFisible = calculateExchangeFisibility(dataExchange);
      if (data.body.currency + data.body.counterCurrency + "" !== dataExchange.currencyPair) {
        // dataExchangeRate = +(1 / dataExchangeRate).toFixed(4);

        dataExchangeRateSwapPairs = true;
        dataExchangeRate = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Buy" : "Sell"
        );
      }
      return {
        token: "10065879",
      };
    },
  },
  //------------------------------------
  //------------------ dpw
  //-----------------------------------
  "/dpw": {
    POST: (data: any): InitializationResponse => {
      dataExchangeRateSwapPairs = false;
      if (data.body.consents) {
        dataExchangeConsents.push(...data.body.consents);
      }
      if (!dataExchangeConsents.includes("HasSimilarDeal") && checkIfIsDoubleExchange(data.body)) {
        return {
          warnings: [
            {
              code: "HasSimilarDeal",
              data: {
                similarDeals: JSON.stringify([
                  {
                    DealId: "12349876",
                    Currency: data.body.currency,
                    CurrencyPair: `${data.body.currency}${data.body.counterCurrency}`,
                    Amount: Number(data.body.amount),
                    DealDate: currentDate.toISOString(),
                    DealType: data.body.product,
                    //  "All": "Wszystkie",
                    // "FXON": "FX TOD",
                    // "FXTN": "FX TOM",
                    // "FX ON": "FX TOD",
                    // "FX TN": "FX TOM",
                    // "SPOT": "FX Spot",
                    // "FXSPOT": "FX Spot",
                    // "FX SPOT": "FX Spot",
                    // "FXFWD": "FX Forward",
                    // "FORW": "FX Forward",
                    // "FXSWAP": "FX Swap",
                    // "FSWP": "FX Swap"
                    Status: "Unsettled", // "Unsettled" | "All" | "Settled" | "Undefined" | "Terminated"
                  },
                ]),
              },
            },
          ],
        } as unknown as InitializationResponse;
      }

      dataExchange = data.body;
      dataExchange.currencyPair = changePairToRealCurrencyPair(
        data.body.currency + data.body.counterCurrency
      ); // -- NEW LINE
      dataExchangeRate = calculateExchangeRate(dataExchange.currencyPair, dataExchange.side);
      dataExchangeRateFar = calculateExchangeRate(
        dataExchange.currencyPair,
        dataExchange.side === "Sell" ? "Buy" : "Sell"
      );
      dataExchangeIsFisible = calculateExchangeFisibility(dataExchange);
      if (data.body.currency + data.body.counterCurrency + "" !== dataExchange.currencyPair) {
        // dataExchangeRate = +(1 / dataExchangeRate).toFixed(4);
        // dataExchangeRateFar = +(1 / dataExchangeRateFar).toFixed(4);

        dataExchangeRateSwapPairs = true;
        dataExchangeRate = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Buy" : "Sell"
        );
        dataExchangeRateFar = calculateExchangeRate(
          dataExchange.currencyPair,
          dataExchange.side === "Sell" ? "Sell" : "Buy"
        );
      }
      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        !dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          error: {
            code: "InsufficientFundsInfo",
          },
        } as unknown as InitializationResponse;
      }
      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          warnings: [
            {
              code: "InsufficientFunds",
            },
          ],
        } as unknown as InitializationResponse;
      }

      return {
        token: "10078921",
      };
    },
  },
  "/dpw/history": {
    GET: (): Dpw[] => {
      return [
        // Oczekujące na ustalenie kursu
        {
          type: "IN", // "IN", "OUT"
          amount: 5000,
          currency: dataPrezentacjaWaluty.USD,
          expireTime: calculateDate(0, 0, 2),
          ssrId: "SR22SE14570756DR",
          isActive: true,
          currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
          counterCurrency: dataPrezentacjaWaluty.PLN,
        },
        // {
        //     type: "OUT",
        //     amount: 200,
        //     currency: dataPrezentacjaWaluty.CHF,
        //     expireTime: calculateDate(0, 0, 4),
        //     ssrId: "SR22IP14561320DS",
        //     isActive: true,
        //     currencyPair: dataPrezentacjaWaluty.CHF + dataPrezentacjaWaluty.PLN,
        //     counterCurrency: dataPrezentacjaWaluty.PLN,
        // },
        // Oczekujące na potwierdzenie
        {
          type: "OUT",
          amount: 30000,
          currency: dataPrezentacjaWaluty.EUR,
          expireTime: calculateDate(0, 0, 1),
          ssrId: "SR22IP14562490DS",
          isActive: false,
          currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
          counterCurrency: dataPrezentacjaWaluty.PLN,
        },
      ];
    },
  },
  //------------------------------------
  //------------------ multifx
  //-----------------------------------               // NIE ROBIĆ TEGO !!!
  "/multifx": {
    POST: (data: any): InitializationResponse => {
      if (data.body.consents) {
        dataExchangeConsents.push(...data.body.consents);
      }
      // if (!dataExchangeConsents.includes("HasSimilarDeal") && checkIfIsDoubleExchange(data.body)) {
      //     return {
      //         warnings: [
      //             {
      //                 code: "HasSimilarDeal"
      //             }
      //         ]
      //     } as unknown as InitializationResponse
      // }

      dataExchange = data.body;
      dataExchange.currencyPair = changePairToRealCurrencyPair(
        data.body.currency + data.body.counterCurrency
      );

      let showError = false;
      data.body.series.reduce((acc: Record<string, any>, el: any) => {
        const index = `${el.amount}-${el.settlement.date}`;
        if (acc[index]) {
          if (!acc[index].includes(el.side)) {
            showError = true;
          }
          acc[index] = [...acc[index], el.side];
        } else {
          acc[index] = [el.side];
        }
        return acc;
      }, {});
      const multiplyByRate = dataExchange.currencyPair.startsWith(data.body.currency)
        ? true
        : false;
      const parForwardRate = data.body.parForward
        ? calculateExchangeRate(dataExchange.currencyPair, dataExchange.series[0].side)
        : 0;
      dataExchangeMultiFxSeries = data.body.series.map(
        (el: {
          side: string;
          clientSideId: any;
          amount: number;
          settlement: { tenor: string };
        }) => {
          if (
            parForwardRate
            // &&
            // !(
            //   el.settlement.tenor === "TOD" ||
            //   el.settlement.tenor === "TOM" ||
            //   el.settlement.tenor === "SPOT"
            // )
          ) {
            if (
              !(
                el.settlement.tenor === "TOD" ||
                el.settlement.tenor === "TOM" ||
                el.settlement.tenor === "SPOT"
              ) &&
              data.body?.forwardCollateral?.type === "Deposit"
            ) {
              const depositCurrency =
                dataAccount.filter((el) => el.number === data.body?.forwardCollateral?.account)?.[0]
                  ?.currency ?? "PLN";
              let depositCurrencyRate = 1;
              if (data.body.currency !== depositCurrency) {
                depositCurrencyRate = calculateExchangeRate(
                  `${data.body.currency}${depositCurrency}`,
                  el.side
                );
              }
              return {
                clientSideId: el.clientSideId,
                rate: parForwardRate,
                calculatedAmount: multiplyByRate
                  ? el.amount * parForwardRate
                  : el.amount / parForwardRate,
                depositAmount:
                  depositCurrencyRate === 0 ? "" : el.amount * 0.059 * depositCurrencyRate,
                depositCurrency: depositCurrencyRate === 0 ? "" : depositCurrency,
              } as MultiFxTransactionRateSuccess;
            } else {
              return {
                clientSideId: el.clientSideId,
                rate: parForwardRate,
                calculatedAmount: multiplyByRate
                  ? el.amount * parForwardRate
                  : el.amount / parForwardRate,
              } as MultiFxTransactionRateSuccess;
            }
          } else {
            const rateToUse = calculateExchangeRate(dataExchange.currencyPair, el.side);
            PrezentacjaDane["/indicatives/rates"]["GET"]({});
            if (
              !(
                el.settlement.tenor === "TOD" ||
                el.settlement.tenor === "TOM" ||
                el.settlement.tenor === "SPOT"
              ) &&
              data.body?.forwardCollateral?.type === "Deposit"
            ) {
              const depositCurrency =
                dataAccount.filter((el) => el.number === data.body?.forwardCollateral?.account)?.[0]
                  ?.currency ?? "PLN";
              let depositCurrencyRate = 1;
              if (data.body.currency !== depositCurrency) {
                depositCurrencyRate = calculateExchangeRate(
                  `${data.body.currency}${depositCurrency}`,
                  el.side
                );
              }
              return {
                clientSideId: el.clientSideId,
                rate: rateToUse,
                calculatedAmount: multiplyByRate ? el.amount * rateToUse : el.amount / rateToUse,
                depositAmount:
                  depositCurrencyRate === 0 ? "" : el.amount * 0.059 * depositCurrencyRate,
                depositCurrency: depositCurrencyRate === 0 ? "" : depositCurrency,
              } as MultiFxTransactionRateSuccess;
            } else {
              return {
                clientSideId: el.clientSideId,
                rate: rateToUse,
                calculatedAmount: multiplyByRate ? el.amount * rateToUse : el.amount / rateToUse,
              } as MultiFxTransactionRateSuccess;
            }
          }
        }
      );
      dataExchangeMultiFxCounterTotal = dataExchangeMultiFxSeries.reduce((acc, current, index) => {
        if (dataExchange.series[index].side === "Sell") acc += current.calculatedAmount;
        else acc -= current.calculatedAmount;
        return acc;
      }, 0);
      dataExchangeMultiFxTotal = dataExchange.series.reduce(
        (acc: any, current: { side: string; amount: string }) => {
          if (current.side === "Sell") acc -= Number(current.amount);
          else acc += Number(current.amount);
          return acc;
        },
        0
      );

      if (showError) {
        return {
          error: {
            code: "MultiFxGeneric",
          },
        } as unknown as InitializationResponse;
      }

      dataExchangeIsFisible = calculateMultiFxFisibility(dataExchange);
      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        !dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          error: {
            code: "InsufficientFundsInfo",
          },
        } as unknown as InitializationResponse;
      }
      if (
        !dataExchangeConsents.includes("InsufficientFunds") &&
        !dataExchangeIsFisible &&
        dataPreference.transactionControl.values.allowDealsWithoutBalance
      ) {
        return {
          warnings: [
            {
              code: "InsufficientFunds",
            },
          ],
        } as unknown as InitializationResponse;
      }

      return {
        token: "10087596",
      };
    },
  },
  "/multifx/rate": {
    GET: (data: any): any => {
      // RateResponse<MultiFxRateSuccess>
      const tokenId = data.params.get("token");
      return {
        token: tokenId,
        decisionTime: 20,
        responseType: RESPONSE_TYPE.success,
        counterCurrencyTotal: dataExchangeMultiFxCounterTotal,
        dealCurrencyTotal: dataExchangeMultiFxTotal,
        quoteId: 1,
        series: dataExchangeMultiFxSeries,
        // error: { code: "TechnicalError" },
      };
    },
  },
  "/multifx/reject": {
    POST: (data: any): void => {
      // const tokenId = data.body.token;
      dataExchange = {};
      dataExchangeConsents = [];
      dataExchangeIsFisible = true;
      dataExchangeMultiFxSeries = [];
      dataExchangeMultiFxCounterTotal = 0;
      dataExchangeMultiFxTotal = 0;
    },
  },
  "/multifx/confirm": {
    POST: (data: any) => {
      dataExchange = {};
      dataExchangeConsents = [];
      dataExchangeIsFisible = true;
      dataExchangeMultiFxSeries = [];
      dataExchangeMultiFxCounterTotal = 0;
      dataExchangeMultiFxTotal = 0;
      return "6fa087bc-04be-44b0-9d6c-7a94a7d82884";
    },
  },
  "/multifx/status": {
    GET: (data: any) => {
      const tokenId = data.params.get("token");
      return {
        status: { code: "CONFIRMED" },
        success: true,
      };
    },
  },
  //------------------------------------
  //------------------ fastdeal
  //-----------------------------------               // NIE ROBIĆ TEGO !!!
  "/fastdeal/widgets": {
    GET: (): Widget[] => {
      return [];
    },
  },
  "/fastdeal/create": {
    POST: (): Widget => {
      const widgetToReturn: Widget = {
        collateral: "",
        collateralAccount: "",
        position: 0,
        tenor: "",
        currency: "",
        currencyPair: "",
        currencyOneAccount: "",
        // currencyOneAccountNumber: "",
        currencyTwoAccount: "",
        // currencyTwoAccountNumber: "",
        amount: 0,
        id: "",
        tickingWidgetId: "",
      };

      return widgetToReturn;
    },
  },
  "/fastdeal/rates": {
    GET: (): Rate[] => {
      return [];
    },
  },
  "/fastdeal/update": {
    PUT: (): Widget[] => {
      return [];
    },
  },
  "/fastdeal/delete": {
    DELETE: (): void => {
      //...
    },
  },
  //___________________________________________________ KURSY
  //------------------------------------
  //------------------ order
  //-----------------------------------               // OCZEKIWANIE_NA_ROZWINIĘCIE
  "/order": {
    POST: (): InitializationResponse => {
      // const orderItem = data.body;
      return {
        token: "10087176",
      };
    },
  },
  "/order/status": {
    GET: (data: any): StatusResponse => {
      const tokenId = data.params.get("token");
      return {
        transactionId: tokenId,
      };
    },
  },
  "/order/form": {
    GET: (): Omit<OrderFormData, "currencies"> => {
      return {
        defaultExpiration: 72000000, // 86400000 - niby 1 dzień   | 72000000   -- to coś nie działą
        orderTypes: [OrderType.Limit, OrderType.StopMarket, OrderType.TrailingStop],
        tenors: [
          "TOD",
          "TOM",
          "SPOT",
          // "1W",
          // "2W",
          // "1M"
        ],
        currencyPairs: PrezentacjaDane["/currency/pairs"]["GET"]({}).map((el: CurrencyPair) => {
          return {
            ...el,
            limit: true,
            stopMarket: true,
            trailingStop: true,
          };
        }),
        pendingConversionEnabled: true,
        // pendingConversionEnabled: false,
        hasPairs: true,
      };
    },
  },
  "/order-history": {
    GET: (): PageData<OrderItem> => {
      // const filterPageNumber = data?.params.get("pageNumber");
      // const filterStatus = data?.params.get("status");
      // const filterOrderType = data?.params.get("orderType");
      // const filterSide = data?.params.get("side");

      const orderArray: OrderItem[] = [
        {
          id: "112546",
          currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
          side: "Buy", // "Sell" | "Buy"
          currency: dataPrezentacjaWaluty.EUR,
          amount: 5000,
          counterCurrency: dataPrezentacjaWaluty.PLN,
          counterAmount: 23250.0,
          rate: 4.65,
          tenor: "TOD",
          type: "Limit", // "Limit", "StopMarket", "TrailingStop"
          status: "Active", // "Active","Executed","Cancelled","Expired","ExecutedPendingConversion","All",
          expiration: calculateDate(0, 1, 0).toISOString(),
          // expirationType: "type_001",
          details$: of({
            id: "112546",
            currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
            side: "Buy", // "Sell" | "Buy"
            currency: dataPrezentacjaWaluty.EUR,
            amount: 5000,
            counterCurrency: dataPrezentacjaWaluty.PLN,
            counterAmount: 23250.0,
            rate: 4.65,
            tenor: "TOD",
            type: "Limit",
            status: "Active", // "Active","Executed","Cancelled","Expired","ExecutedPendingConversion","All",
            expiration: calculateDate(0, 1, 0).toISOString(),
            // expirationType: "type_001",

            cancellable: true,
            executedTransaction: "nie_001",
            points: "ponits_001",
            accounts: {
              account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
              counterAccount: dataAccount.filter(
                (el) => el.currency === dataPrezentacjaWaluty.PLN
              )[1],
            },
            // counterSettlementAccount: dataAccount.filter(el => el.currency === dataPrezentacjaWaluty.PLN)[1],
            // settlementAccount: dataAccount.filter(el => el.currency === dataPrezentacjaWaluty.EUR)[1],
            phonePrefix: dataPreference.phone.prefix,
            phone: dataPreference.phone.number,
            ratePoints: {
              rate: 4.65,
            },
            spotDesk: true,
            pendingConversion: false,
          } as OrderDetails),
          overnightPricing: false,
          subOrder: undefined as unknown as OrderItem,
          oCORef: 1,
          displayDetails: false,
        },
        {
          id: "112550",
          currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
          side: "Sell", // "Sell" | "Buy"
          currency: dataPrezentacjaWaluty.EUR,
          amount: 5000,
          counterCurrency: dataPrezentacjaWaluty.PLN,
          counterAmount: 22500.0,
          rate: 4.5,
          tenor: "TOM",
          type: "StopMarket", // "Limit", "StopMarket", "TrailingStop"
          status: "Active", // "Active","Executed","Cancelled","Expired","ExecutedPendingConversion","All",
          expiration: calculateDate(0, 1, 0).toISOString(),
          // expirationType: "type_001",
          details$: of({
            id: "112550",
            currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
            side: "Sell", // "Sell" | "Buy"
            currency: dataPrezentacjaWaluty.EUR,
            amount: 5000,
            counterCurrency: dataPrezentacjaWaluty.PLN,
            counterAmount: 22500.0,
            rate: 4.5,
            tenor: "TOM",
            type: "StopMarket", // "Limit", "StopMarket", "TrailingStop"
            status: "Active", // "Active","Executed","Cancelled","Expired","ExecutedPendingConversion","All",
            expiration: calculateDate(0, 1, 0).toISOString(),
            expirationType: "type_001",

            cancellable: true,
            executedTransaction: "nie_001",
            points: "ponits_001",
            accounts: {
              account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.EUR)[1],
              counterAccount: dataAccount.filter(
                (el) => el.currency === dataPrezentacjaWaluty.PLN
              )[1],
            },
            // counterSettlementAccount: dataAccount.filter(el => el.currency === dataPrezentacjaWaluty.PLN)[1],
            // settlementAccount: dataAccount.filter(el => el.currency === dataPrezentacjaWaluty.EUR)[1],
            phonePrefix: dataPreference.phone.prefix,
            phone: dataPreference.phone.number,
            ratePoints: {
              rate: 4.65,
            },
            spotDesk: true,
            pendingConversion: false,
          } as OrderDetails),
          overnightPricing: false,
          subOrder: undefined as unknown as OrderItem,
          oCORef: 1,
          displayDetails: false,
        },
      ];

      orderArray.forEach((el) => {
        PrezentacjaDane[`/order-history/${el.id}`] = {
          GET: (): OrderDetails => {
            let dataToReturn: any = {};
            el.details$.subscribe((detail) => {
              dataToReturn = detail;
            });
            return dataToReturn;
          },
        };
      });

      return {
        items: orderArray,
        isLastPage: true,
      };
    },
    POST: () => {
      // const orderId = data.body;
      return true;
    },
  },
  //------------------------------------
  //------------------ alert
  //-----------------------------------
  "/alert": {
    POST: (): InitializationResponse => {
      return {
        token: "10077038",
      };
    },
  },
  "/alert/status": {
    GET: (data: any): StatusResponse => {
      const alertId = data.params.get("token");
      return {
        transactionId: alertId,
      };
    },
  },
  // "/alert/list": {
  //     "GET": (data: any): AlertListItem[] => {
  //         return [
  //             {
  //                 id: "10077022",
  //                 side: "Sell",       // "Sell", "Buy"
  //                 rate: 4.9000,
  //                 amount: 10000,
  //                 currency: dataPrezentacjaWaluty.EUR,
  //                 currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
  //                 expiration: calculateDate(0, 1, 0).toISOString(),
  //                 phone: `${dataPreference.phone.prefix}${dataPreference.phone.number}`,
  //                 status: WATCH_STATUSES[0],
  //             },
  //             {
  //                 id: "10077025",
  //                 side: "Buy",       // "Sell", "Buy"
  //                 rate: 4.6000,
  //                 amount: 25000,
  //                 currency: dataPrezentacjaWaluty.USD,
  //                 currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
  //                 expiration: calculateDate(0, 2, 0).toISOString(),
  //                 phone: `${dataPreference.phone.prefix}${dataPreference.phone.number}`,
  //                 status: WATCH_STATUSES[0],
  //             }
  //         ]
  //     }
  // },
  "/alert/cancel": {
    POST: (): boolean => {
      // const alertId = data.body;
      return true;
    },
  },
  "/alert/history": {
    GET: (): AlertListData => {
      return {
        items: [
          // AlertListItem[]
          {
            id: "10077022",
            side: "Sell", // "Sell", "Buy"
            rate: 4.9,
            amount: 10000,
            currency: dataPrezentacjaWaluty.EUR,
            currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
            expiration: calculateDate(0, 1, 0).toISOString(),
            phone: `${dataPreference.phone.prefix}${dataPreference.phone.number}`,
            status: WATCH_STATUSES[0],
          },
          {
            id: "10077025",
            side: "Buy", // "Sell", "Buy"
            rate: 4.6,
            amount: 25000,
            currency: dataPrezentacjaWaluty.USD,
            currencyPair: dataPrezentacjaWaluty.USD + dataPrezentacjaWaluty.PLN,
            expiration: calculateDate(0, 2, 0).toISOString(),
            phone: `${dataPreference.phone.prefix}${dataPreference.phone.number}`,
            status: WATCH_STATUSES[0],
          },
        ],
        isLastPage: true,
      };
    },
  },
  //___________________________________________________ LOKATY
  //------------------------------------
  //------------------ deposit
  //-----------------------------------
  "/deposit/tenors": {
    GET: (data: any): TenorDate[] => {
      // wersja wszystkie święta z wszystkich walut ... średnia, ale nie da się innej by objąć wszystkie przypadki (no chyba, że wywalić sięta z innych walut niż PLN)
      const forwardTenor = data.params.get("key"); // ON, TN, SPOT

      const dataToReturn: TenorDate[] = [];
      let holidays: string[] = [];
      Object.values(PrezentacjaDane["/currency/holidays"]["GET"]({})).forEach((el: any) => {
        holidays = Array.from(
          new Set([
            ...el.map((el2: any) => {
              return el2.slice(0, 10);
            }),
            ...holidays,
          ])
        );
      });

      const startTenors = [
        {
          tenor: "ON",
          date: calculateTenorDate(calculateDate(0, 0, 0)),
        },
        {
          tenor: "TN",
          date: calculateTenorDate(calculateDate(0, 0, 1)),
        },
        {
          tenor: "SPOT",
          date: calculateTenorDate(calculateDate(0, 0, 2)),
        },
      ];

      startTenors.forEach((el, id) => {
        let isOk = false;
        let elDate = el.date;
        let prevElDateToCheck = "";
        if (id >= 1) {
          prevElDateToCheck = startTenors[id - 1].date.slice(0, 10);
        }
        let dateToCheck = "";
        while (isOk === false) {
          dateToCheck = elDate.slice(0, 10);
          if (new Date(prevElDateToCheck).getTime() > new Date(dateToCheck).getTime()) {
            elDate = calculateTenorDate(new Date(new Date(elDate).getTime() + 86400000));
          } else {
            if (holidays.includes(dateToCheck) || prevElDateToCheck === dateToCheck) {
              elDate = calculateTenorDate(new Date(new Date(elDate).getTime() + 86400000));
            } else {
              isOk = true;
              el.date = elDate;
            }
          }
        }
        dataToReturn.push(el);
      });

      let startDate: string = startTenors[0].date;
      if (forwardTenor === "TN") {
        startDate = startTenors[1].date;
      }
      if (forwardTenor === "SPOT") {
        startDate = startTenors[2].date;
      }
      const endTenors = [
        {
          tenor: "1D",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 0, 1)),
        },
        {
          tenor: "1W",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 0, 7)),
        },
        {
          tenor: "2W",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 0, 14)),
        },
        {
          tenor: "3W",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 0, 21)),
        },
        {
          tenor: "1M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 1, 0)),
        },
        {
          tenor: "2M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 2, 0)),
        },
        {
          tenor: "3M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 3, 0)),
        },
        {
          tenor: "4M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 4, 0)),
        },
        {
          tenor: "5M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 5, 0)),
        },
        {
          tenor: "6M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 6, 0)),
        },
        {
          tenor: "9M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 9, 0)),
        },
        {
          tenor: "12M",
          date: calculateTenorDate(calculateDateFromDate(new Date(startDate), 0, 12, 0)),
        },
      ];

      endTenors.forEach((el, id) => {
        let isOk = false;
        let elDate = el.date;
        let prevElDateToCheck = "";
        if (id >= 1) {
          prevElDateToCheck = endTenors[id - 1].date.slice(0, 10);
        }
        let dateToCheck = "";
        while (isOk === false) {
          dateToCheck = elDate.slice(0, 10);
          if (new Date(prevElDateToCheck).getTime() > new Date(dateToCheck).getTime()) {
            elDate = calculateTenorDate(new Date(new Date(elDate).getTime() + 86400000));
          } else {
            if (holidays.includes(dateToCheck) || prevElDateToCheck === dateToCheck) {
              elDate = calculateTenorDate(new Date(new Date(elDate).getTime() + 86400000));
            } else {
              isOk = true;
              el.date = elDate;
            }
          }
        }
        dataToReturn.push(el);
      });
      return dataToReturn;
    },
  },
  "/deposit": {
    POST: (data: any): InitializationResponse => {
      dataDeposit = data.body;
      // dataDepositRate = Math.round(1 + Math.random() * 4);
      dataDepositRate = 2.6;
      dataDepositIsFisible = calculateDepositFisibility(dataDeposit);
      dataDepositID++;
      if (dataDepositIsFisible) {
        return {
          token: dataDepositID + "",
        };
      } else {
        return {
          error: {
            // code: "InsufficientFundsInfo"
            code: "NoAgreementForTransaction",
          },
        };
      }
    },
  },
  "/deposit/rate": {
    GET: (data: any): RateResponse<DepositRateSuccess> => {
      const depositId = data.params.get("token");
      const inMonthDeposit =
        new Date(
          new Date(dataDeposit.end.date).getTime() - new Date(dataDeposit.start.date).getTime()
        ).getTime() /
        86400000 /
        365;
      return {
        token: depositId,
        decisionTime: 120,
        responseType: "PRICE",
        rate: dataDepositRate,
        interestAmount: ((dataDeposit.amount * dataDepositRate) / 100) * inMonthDeposit,
        maturityAmount:
          +dataDeposit.amount + ((dataDeposit.amount * dataDepositRate) / 100) * inMonthDeposit,
      };
    },
  },
  "/deposit/reject": {
    POST: (): void => {
      // const depositId = data.body.token;
      dataDeposit = {};
      dataDepositRate = 0;
      dataDepositIsFisible = true;
    },
  },
  "/deposit/confirm": {
    POST: (data: any): string => {
      const depositId = data.body.transactionToken;
      dataDeposit = {};
      dataDepositRate = 0;
      dataDepositIsFisible = true;

      return depositId;
    },
  },
  "/deposit/status": {
    GET: (data: any): StatusResponse => {
      const depositId = data.params.get("token");
      return {
        transactionId: depositId,
      };
    },
  },
  "/deposithistory": {
    GET: (data: any): PageData<DepositHistoryItem> => {
      // const filterPageNumber = data?.params.get("pageNumber");
      const filterStatus = data?.params.get("settlementStatus");
      // const filterOrderType = data?.params.get("orderType");
      // const filterSide = data?.params.get("side");

      const depositArray: any[] = [
        {
          amount: 250000,
          currency: dataPrezentacjaWaluty.USD,
          startDate: calculateDate(0, -1, 0).toISOString(),
          endDate: calculateDate(0, 11, 0),
          interestRate: 2.56,

          details$: of({
            amount: 250000,
            currency: dataPrezentacjaWaluty.USD,
            startDate: calculateDate(0, -1, 0).toISOString(),
            endDate: calculateDate(0, 11, 0),
            interestRate: 2.56,

            id: "10077044",
            dealDate: calculateDate(0, -1, -1),
            interestAmount: (((250000 * 2.56) / 100) * 365) / 365,
            amountWithInterest: 250000 + (((250000 * 2.56) / 100) * 365) / 365,
            account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.USD)[0],
            closeVisible: true, // false
            isTerminated: false,

            status: "", // << --- zmiana
            hasConfirmation: true, // << --- zmiana
            documentId: "", // << --- zmiana
            type: "", // << --- zmiana
            depositType: "Negotiated",
          }),
          id: "10077044",
          status: "Unsettled", // "Unsettled" | "All" | "Settled" | "Undefined" | "Terminated"
          displayDetails: false,
          interestAmount: (((250000 * 2.56) / 100) * 365) / 365,
          settlementAmount: 250000 + (((250000 * 2.56) / 100) * 365) / 365,
          type: "Negotiated",
        },
        {
          amount: 1000000,
          currency: dataPrezentacjaWaluty.PLN,
          startDate: calculateDate(0, -1, -1).toISOString(),
          endDate: calculateDate(0, 0, -2),
          interestRate: 2.96,

          details$: of({
            amount: 1000000,
            currency: dataPrezentacjaWaluty.PLN,
            startDate: calculateDate(0, -1, -1).toISOString(),
            endDate: calculateDate(0, 0, -2),
            interestRate: 2.96,

            id: "10077047",
            dealDate: calculateDate(0, -1, -2),
            interestAmount: (((1000000 * 2.96) / 100) * 30) / 365,
            amountWithInterest: 1000000 + (((1000000 * 2.96) / 100) * 30) / 365,
            account: dataAccount.filter((el) => el.currency === dataPrezentacjaWaluty.PLN)[1],
            closeVisible: false,
            isTerminated: false,

            status: "", // << --- zmiana
            hasConfirmation: true, // << --- zmiana
            documentId: "", // << --- zmiana
            type: "",
            depositType: "Negotiated", // << --- zmiana
          }),
          id: "10077047",
          status: "Settled",
          displayDetails: false,
          interestAmount: (((1000000 * 2.96) / 100) * 30) / 365,
          settlementAmount: 1000000 + (((1000000 * 2.96) / 100) * 30) / 365,
          type: "Negotiated",
        },
        ...premiumDepositHistory,
      ];
      return {
        items: depositArray.filter((el) => {
          if (filterStatus && filterStatus === "All") {
            return el;
          } else if (filterStatus) {
            return el.status === filterStatus;
          }
          return el;
        }),
        isLastPage: true,
      };
    },
    POST: () => {
      return [];
    },
  },
  "/depositHistory/review-premium": {
    GET: (data: any): any => {
      //DepositDetails
      const deposit = premiumDepositHistory.filter((el) => el.id === data.rest[0])[0];
      return deposit.details$;
    },
  },
  //------------------------------------
  //------------------ investments deposit
  //-----------------------------------
  "/investmentdeposit/data": {
    GET: (data: any): InvestmentDepositConfig => {
      let midSpotRateEURPLN = 4.335;
      let midSpotRateUSDPLN = 4.00595;

      try {
        const valEURPLN = commonData["/currency"].filter(
          (el: { para: string; kup: number; sprzedaj: number }) => el.para === "EURPLN"
        )?.[0];
        const valUSDPLN = commonData["/currency"].filter(
          (el: { para: string; kup: number; sprzedaj: number }) => el.para === "USDPLN"
        )?.[0];
        midSpotRateEURPLN = Number(((valEURPLN.kup + valEURPLN.sprzedaj) / 2).toFixed(4));
        midSpotRateUSDPLN = Number(((valUSDPLN.kup + valUSDPLN.sprzedaj) / 2).toFixed(4));
      } catch (exc) {}
      return {
        currencies: [
          {
            code: "EUR",
            minimalAmount: 45000,
            maximalAmount: 2500000,
            currencyPairs: ["EURPLN"],
          },
          {
            code: "PLN",
            minimalAmount: 200000,
            maximalAmount: 10000000,
            currencyPairs: ["EURPLN", "USDPLN"],
          },
          {
            code: "USD",
            minimalAmount: 50000,
            maximalAmount: 2500000,
            currencyPairs: ["USDPLN"],
          },
        ],
        tenors: [
          {
            name: "2W",
            from: calculateDate(0, 0, 14),
            to: calculateDate(0, 0, 24),
          },
          {
            name: "1M",
            from: calculateDate(0, 0, 25),
            to: calculateDate(0, 0, 45),
          },
          // {
          //   name: "2M",
          //   from: calculateDate(0, 0, 46),
          //   to: calculateDate(0, 0, 75),
          // },
          {
            name: "3M",
            from: calculateDate(0, 0, 76),
            to: calculateDate(0, 0, 135),
          },
          // {
          //   name: "6M",
          //   from: calculateDate(0, 0, 136),
          //   to: calculateDate(0, 0, 270),
          // },
          // {
          //   name: "12M",
          //   from: calculateDate(0, 0, 271),
          //   to: calculateDate(0, 0, 365),
          // },
        ],
        offer: {
          currencies: [
            {
              code: "EUR",
              amounts: [
                {
                  amount: 2500000,
                  currencyPairs: [
                    {
                      code: "EURPLN",
                      midSpotRate: midSpotRateEURPLN,
                      tenors: [
                        // {
                        //   name: "12M",
                        //   settings: {
                        //     minimalInterestRate: 0.1,
                        //     maximalInterestRate: 0.9,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "1M",
                          settings: {
                            minimalInterestRate: 0.1,
                            maximalInterestRate: 0.8,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "2M",
                        //   settings: {
                        //     minimalInterestRate: 0.1,
                        //     maximalInterestRate: 0.9,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "2W",
                          settings: {
                            minimalInterestRate: 0.1,
                            maximalInterestRate: 0.5,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        {
                          name: "3M",
                          settings: {
                            minimalInterestRate: 0.1,
                            maximalInterestRate: 0.9,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "6M",
                        //   settings: {
                        //     minimalInterestRate: 0.1,
                        //     maximalInterestRate: 0.9,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              code: "PLN",
              amounts: [
                {
                  amount: 999999,
                  currencyPairs: [
                    {
                      code: "EURPLN",
                      midSpotRate: midSpotRateEURPLN,
                      tenors: [
                        // {
                        //   name: "12M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.6,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "1M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.5,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "2M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.5,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "2W",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.1,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        {
                          name: "3M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.6,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "6M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.4,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                      ],
                    },
                    {
                      code: "USDPLN",
                      midSpotRate: midSpotRateUSDPLN,
                      tenors: [
                        // {
                        //   name: "12M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.6,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "1M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.5,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "2M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.5,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "2W",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.1,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        {
                          name: "3M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.6,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "6M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.4,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                      ],
                    },
                  ],
                },
                {
                  amount: 10000000,
                  currencyPairs: [
                    {
                      code: "EURPLN",
                      midSpotRate: midSpotRateEURPLN,
                      tenors: [
                        // {
                        //   name: "12M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 4,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "1M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 4,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "2M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 4,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "2W",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.6,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        {
                          name: "3M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 4,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "6M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.8,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                      ],
                    },
                    {
                      code: "USDPLN",
                      midSpotRate: midSpotRateUSDPLN,
                      tenors: [
                        // {
                        //   name: "12M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 4,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "1M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 4,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "2M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 4,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "2W",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 3.6,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        {
                          name: "3M",
                          settings: {
                            minimalInterestRate: 0.5,
                            maximalInterestRate: 4,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "6M",
                        //   settings: {
                        //     minimalInterestRate: 0.5,
                        //     maximalInterestRate: 3.8,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              code: "USD",
              amounts: [
                {
                  amount: 2500000,
                  currencyPairs: [
                    {
                      code: "USDPLN",
                      midSpotRate: midSpotRateUSDPLN,
                      tenors: [
                        // {
                        //   name: "12M",
                        //   settings: {
                        //     minimalInterestRate: 0.1,
                        //     maximalInterestRate: 2.2,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "1M",
                          settings: {
                            minimalInterestRate: 0.1,
                            maximalInterestRate: 1.7,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "2M",
                        //   settings: {
                        //     minimalInterestRate: 0.1,
                        //     maximalInterestRate: 2,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                        {
                          name: "2W",
                          settings: {
                            minimalInterestRate: 0.1,
                            maximalInterestRate: 1.3,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        {
                          name: "3M",
                          settings: {
                            minimalInterestRate: 0.1,
                            maximalInterestRate: 2.2,
                            minSpread: 400,
                            maxDeviation: 1000,
                            numberOfRateRanges: 4,
                          },
                        },
                        // {
                        //   name: "6M",
                        //   settings: {
                        //     minimalInterestRate: 0.1,
                        //     maximalInterestRate: 2.4,
                        //     minSpread: 400,
                        //     maxDeviation: 1000,
                        //     numberOfRateRanges: 4,
                        //   },
                        // },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        mifidValidationResult: {
          token: "",
        },
      };
    },
  },
  "/investmentdeposit": {
    POST: (data: any) => {
      dataInvestmentsDeposit = data.body;
      return {
        token: `71f7f864-${new Date().getTime()}`,
      };
    },
  },
  "/investmentdeposit/rate": {
    GET: (data: any): InvestmentDepositRateSuccess => {
      const token = data.params.get("token");
      const depositDurationInPercents =
        (new Date(dataInvestmentsDeposit.end.date).getTime() -
          new Date(dataInvestmentsDeposit.start.date).getTime()) /
        86400000 /
        365;
      return {
        token: token,
        decisionTime: 90,
        responseType: "PRICE", // "PRICE" 'DEALERWAIT', 'RETRY'
        terminationFee: Number(dataInvestmentsDeposit.amount) * 0.00235,
        standardInterestAmount:
          ((Number(dataInvestmentsDeposit.amount) * dataInvestmentsDeposit.rates.interestRate) /
            100) *
          depositDurationInPercents,
        maximalRate: dataInvestmentsDeposit.rates.interestRate + 0.75,
        maximalInterestAmount:
          ((Number(dataInvestmentsDeposit.amount) *
            (dataInvestmentsDeposit.rates.interestRate + 0.75)) /
            100) *
          depositDurationInPercents,
        referenceRateDate: new Date(
          new Date(dataInvestmentsDeposit.end.date).getTime() - 2 * 86400000
        ).toISOString(),
        referenceRate: "NBP - Fixing NBP",
        salesMargin: 0.025,
        salesProfit: Number(dataInvestmentsDeposit.amount) * 0.00025,
      };
    },
  },
  "/investmentdeposit/confirm": {
    POST: (data: any) => {
      // dataInvestmentsDeposit = {};
      return data.body.rateToken;
    },
  },
  "/investmentdeposit/status": {
    GET: (data: any): StatusResponse => {
      // { transactionId: string };
      // { status: Failure };
      return {
        transactionId: "381689",
      };
    },
  },
  "/investmentdeposit/reject": {
    POST: (data: any): void => {
      // dataInvestmentsDeposit = {};
    },
  },
  "/investment-deposit-history": {
    GET: (data: any): PageData<Deposit> => {
      // const filterPageNumber = data?.params.get("pageNumber");
      const filterStatus = data?.params.get("settlementStatus");
      // const filterOrderType = data?.params.get("orderType");
      // const filterSide = data?.params.get("side");
      return {
        items: investmentDepositArray.filter((el) => {
          if (filterStatus && filterStatus === "All") {
            return el;
          } else if (filterStatus) {
            return el.status === filterStatus;
          }
          return el;
        }),
        isLastPage: true,
      };
    },
  },
  "/investment-deposit-history/details": {
    GET: (data: any): any => {
      const id = data.rest[0];
      //DepositDetails
      const el = investmentDepositArray.filter((el) => el.id === id)[0];
      return el.details$;
    },
  },
  //------------------------------------
  //------------------ investments deposit
  //-----------------------------------
  "/premiumDeposit": {
    GET: (data: any): PremiumDepositConfiguration => {
      return {
        offers: premiuDepostiOffers,
        options: {
          access: {
            enabled: true,
            min: "07:00",
            max: "17:00",
          },
          amountLimit: {
            min: 250000,
            max: 25000000,
          },
        },
      };
    },
    POST: (data: any): { rate: number; interestAmount: number; token: string } => {
      const amount = data.body.amount;
      const rate = premiuDepostiOffers.filter((el) => {
        return el.basket.id === data.body.basket.id && amount < el.band;
      })[0].rate;
      const interestAmount =
        (amount *
          rate *
          (new Date(data.body.endDate).getTime() - new Date(data.body.startDate).getTime())) /
        86400000 /
        365;
      return {
        rate: rate,
        interestAmount: interestAmount,
        token: `12342134-${new Date().getTime()}`,
      };
    },
  },
  "/premiumDeposit/confirm": {
    POST: (data: any): { transactionId: string } => {
      return {
        transactionId: "32112",
      };
    },
  },
  //###############################################################################################################
  //------------------------------------
  //------------------ notification
  //-----------------------------------
  "/marketnews/news": {
    // zmiana 2023-06-20
    GET: (data: any): MarketNews[] | MarketNews => {
      const newsId = data?.rest?.[0] ?? "-1";
      const newsArray = (short = true): MarketNews[] => {
        return [
          {
            id: "1",
            headline: "W SKRÓCIE-W III sprzedaż miedzi KGHM wyniosła 66,1 tys. ton",
            // date: calculateTenorDate(new Date('2022-04-25 15:14')),
            date: calculatePrevDate(
              { year: 0, month: 0, day: 1 },
              { hour: 15, minute: 42 }
            ).toISOString(),
            content: short
              ? ""
              : "KGHM Polska Miedz SA KGH.WA * SPRZEDAZ MIEDZI W III 66,1 TYS. TON WOBEC 66,6 TYS. TON ROK WCZESNIEJ * PRODUKCJA MIEDZI PLATNEJ W III 63,9 TYS. TON WOBEC 65,8 TYS. TON ROK WCZESNIEJ Pełny komunikat spółki w serwisie Eikon jest dostępny tutaj: ID:nEM587pZ7a Aby zobaczyć pozostałe depesze na temat spółki, kliknij tutaj KGH.WA (Gdańsk Newsroom) ((gdansk.newsroom@thomsonreuters.com; +48 58 769 66 00; ))",
          },
          {
            id: "2",
            content: short
              ? ""
              : "WARSZAWA - Ministerstwo Finansów (MF) poinformowało w poniedziałek, ze po pierwszych trzech miesiącach 2022 roku deficyt budżetu wyniósł 0,3 miliarda złotych. Po lutym budżet miał 11,2 miliarda złotych nadwyżki. (Autorzy: Anna Koper i Pawel Florkiewicz, Redagował: Alan Charlish) ((warsaw.newsroom@thomsonreuters.com; +48 22 6539700; Reuters Messaging: warsaw.newsroom.reuters.com@thomsonreuters.net))",
            headline: "Po marcu deficyt budżetu państwa wyniósł 0,3 mld zł-MF",
            // date: calculateTenorDate(new Date('2022-04-25 14:12')),
            date: calculatePrevDate(
              { year: 0, month: 0, day: 1 },
              { hour: 14, minute: 12 }
            ).toISOString(),
          },
          {
            id: "3",
            content: short
              ? ""
              : "WARSZAWA - W marcu podaż pieniądza M3 wzrosła o 7,9 procent rok do roku do 2009,57 miliarda złotych, poinformował w poniedziałek Narodowy Bank Polski (NBP). Analitycy ankietowani przez agencje Reutera spodziewali się wzrostu o 7,7 procent. III ZMIANA R./R. M./M. (mld zł) (proc.) (proc.) PODAZ PIENIADZA M3 2009,57 7,9 1,3 PIENIADZ GOTÓWKOWY W OBIEGU 372,38 DEPOZYTY 1626,89 gospodarstw domowych 1019,58 przedsiębiorstw 415,64 NALEZNOSCI 1476,09 od gospodarstw domowych 823,62 od przedsiębiorstw 400,90 ZADLUZENIE NETTO INSTYTUCJI RZADOWYCH 383,77 AKTYWA ZAGRANICZNE NETTO (w mld euro) 113,10 (Autor: Anna Włodarczak-Semczuk; Redagował: Paweł Florkiewicz) ((pawel.florkiewicz@thomsonreuters.com)(+48 22 6539700) (Reuters Messaging: pawel.florkiewicz.reuters.com@thomsonreuters.net))",
            headline: "TABELA- W III podaż pieniądza M3 wzrosła o 7,9 proc.-NBP",
            // date: calculateTenorDate(new Date('2022-04-25 14:00')),
            date: calculatePrevDate(
              { year: 0, month: 0, day: 1 },
              { hour: 14, minute: 0 }
            ).toISOString(),
          },
          {
            id: "4",
            content: short
              ? ""
              : "INDEKS WARTOSC OTWARCIE / ZAMKNIECIE POPRZEDNIEJ SESJI (pkt.) (zmiana proc.) WIG20 1.951,03 -1,13 WIG20lev 388,71 -0,01 WIG20short 3.402,16 0,02 mWIG40 4.561,05 -0,03 WIG 59.997,97 -1,49 WIGDIV 1.132,32 -0,63 sWIG80 18.917,40 -1,43",
            headline: "GPW – NOTOWANIA CIAGLE: Na otwarciu WIG20 spadł o 1,13 proc.",
            // date: calculateTenorDate(new Date('2022-04-25 09:01')),
            date: calculatePrevDate(
              { year: 0, month: 0, day: 1 },
              { hour: 9, minute: 1 }
            ).toISOString(),
          },
        ];
      };
      if (newsId === "-1") {
        return newsArray(true);
      } else {
        return newsArray(false).filter((el) => {
          if (el.id === newsId) {
            return true;
          }
          return false;
        })[0];
      }
    },
  },
  "/marketnews/analysis": {
    GET: (data: any): Analysis[] | Analysis => {
      const newsId = data?.rest?.[0] ?? "-1";
      const newsArray = [
        {
          id: "demo.pdf",
          title:
            "Rynek walutowy - Złoty konsoliduje sie przed kluczowymi decyzjami banków centralnych",
          content: "treść nówsa",
          category: "Kategoria",
          categoryId: 1,
          date: calculateDate(0, 0, 0).toISOString(),
        },
      ];
      if (newsId === "-1") {
        return newsArray;
      } else {
        return newsArray.filter((el) => {
          if (el.id === newsId) {
            return true;
          }
          return false;
        })[0];
      }
      return [];
    },
  },
  //------------------------------------
  //------------------ notification
  //-----------------------------------
  "/notification/dates": {
    // zwraca datę wczorajszą [albo piątkową], dzisiejszą i jutrzejszą
    GET: (): string[] => {
      return [
        (() => {
          let dateToReturn = calculateDate(0, 0, -1);
          if (dateToReturn.toISOString().slice(0, 10) === currentDate.toISOString().slice(0, 10)) {
            dateToReturn = new Date(currentDate.getTime() - 3 * 86400000);
          }
          return dateToReturn.toISOString();
        })(),
        calculateDate(0, 0, 0).toISOString(),
        calculateDate(0, 0, 1).toISOString(),
      ];
    },
  },
  "/notification/actions": {
    GET: (data: any): UserNotification[] => {
      let dataToReturn: UserNotification[] = [];
      try {
        let notificationDate;
        if (data?.params) {
          notificationDate = (data.params as HttpParams).get("date");
        }
        switch (notificationDate) {
          case calculateDate(0, 0, 0).toISOString().split("T")[0]:
            dataToReturn = [
              {
                key: "UnsettledDeal",
                name: "UnsettledDeal",
                date: calculateDate(0, 0, 1),
                mainDealId: "120000002", // zmiana z dealId na mainDealId
                dealSide: "Sell",
                currency1: dataPrezentacjaWaluty.EUR,
                amount1: 1000,
                currency2: dataPrezentacjaWaluty.PLN,
                amount2: 4637.2,
              } as UnsettledDeal,
              {
                dpwType: "IN", // "OUT"
                expirationDate: new Date(calculateDate(0, 0, 1).setHours(13)),
                amount: 10000,
                currency: dataPrezentacjaWaluty.PLN,
                dpwNumber: "SR22IP14561670DS",
                currencyPair: dataPrezentacjaWaluty.EUR + dataPrezentacjaWaluty.PLN,
                status: "Active",
                key: "10077031",
                date: calculateDate(0, 0, 0),
                name: "NewDpw",
              } as NewDpw,
            ];
            break;
          case calculateDate(0, 0, 1).toISOString().split("T")[0]:
            dataToReturn = [
              {
                key: "UnsettledDeal",
                name: "UnsettledDeal",
                date: calculateDate(0, 0, 1),
                mainDealId: "120000001", // zmiana z dealId na mainDealId
                dealSide: "Buy",
                currency1: dataPrezentacjaWaluty.USD,
                amount1: 500,
                currency2: dataPrezentacjaWaluty.PLN,
                amount2: 2216.25,
              } as UnsettledDeal,
            ];
            break;
          default:
            dataToReturn = [];
            break;
        }
      } catch (exc) {
        console.log("");
      }
      return dataToReturn;
    },
  },
  "/messages": {
    GET: (): Message[] => {
      return [];
    },
  },
  "/messages/archive": {
    POST: (): void => {
      // const messageId = data.body;
    },
  },
  "/messages/read": {
    POST: (): string[] => {
      return [];
    },
  },
  //------------------------------------
  //------------------ history
  //-----------------------------------\
  "/history": {
    GET: (): PageData<IListItem> => {
      // const pageNumber = data.params.get("pageNumber");
      // const subproduct = data.params.get("subproduct");
      // const type = data.params.get("type");
      // const settlementStatus = data.params.get("settlementStatus");
      // const sortingOrder = data.params.get("sortingOrder");

      return {
        items: otherTransationArray,
        isLastPage: true,
      };
    },
  },
  "/history/csv": {
    GET: (): Deal[] => {
      return otherTransationArray;
    },
  },
  "/history/pricings-config": {
    // posortowanie dat od najstarszej do najmłodszej
    GET: (): PricingsConfig => {
      let datesArray: Date[] = [];

      const datesArrayExchane = Array.from(
        new Set(
          dataExchangeArray.map((el) => {
            const datesArray = [];
            const dateStart = new Date(el.transactionDate);
            const dateEnd = el.settlementDate;

            const yearDistance = dateEnd.getFullYear() - dateStart.getFullYear();
            const monthDistance = dateEnd.getMonth() - dateStart.getMonth();

            for (let i = 1; i <= yearDistance * 12 + monthDistance; i++) {
              datesArray.push(new Date(dateStart.getFullYear(), dateStart.getMonth() + i, 0));
            }
            return datesArray;
          })
        )
      ).flat();
      const datesArrayOther = Array.from(
        new Set(
          otherTransationArray.map((el) => {
            const datesArray = [];
            const dateStart = new Date(el.transactionDate);
            const dateEnd = new Date(el.settlementDate);

            const yearDistance = dateEnd.getFullYear() - dateStart.getFullYear();
            const monthDistance = dateEnd.getMonth() - dateStart.getMonth();

            for (let i = 1; i <= yearDistance * 12 + monthDistance; i++) {
              datesArray.push(new Date(dateStart.getFullYear(), dateStart.getMonth() + i, 0));
            }
            return datesArray;
          })
        )
      ).flat();
      datesArray = Array.from(
        new Set([
          ...datesArrayExchane.map((el) => el.toISOString()),
          ...datesArrayOther.map((el) => el.toISOString()),
        ])
      )
        .map((el) => new Date(el))
        .filter((el) => {
          if (el.getTime() < new Date().getTime()) {
            return true;
          }
          return false;
        })
        .sort((el1, el2) => {
          return el1.getTime() > el2.getTime() ? 1 : 0;
        });
      datesArray.sort((el1, el2) => {
        return el1.getTime() < el2.getTime() ? 1 : -1;
      });

      return {
        pricingsDates: datesArray, // Date[]
        currentPricingDate: datesArray[0], // Date
        pricingsVisible: true, // boolean
      };
    },
  },
  // "/history/pricings-config": {
  //     "GET": (data: any): PricingsConfig => {

  //         /*
  //             tutaj daty jeszcze raz przeliczyć, brać tylko te wcześniejsze niż obecny miesiąc
  //         */

  //         let datesArray: Date[] = [];
  //         const datesArrayExchane = Array.from(
  //             new Set(
  //                 dataExchangeArray.map(el => {
  //                     return new Date(el.settlementDate.getFullYear(), el.settlementDate.getMonth() + 1, 0).toDateString();
  //                 })
  //             )
  //         ).map(el => new Date(el));
  //         const datesArrayOther = Array.from(
  //             new Set(
  //                 otherTransationArray.map(el => {
  //                     const otherDate = new Date(el.settlementDate);
  //                     return new Date(otherDate.getFullYear(), otherDate.getMonth() + 1, 0).toDateString();
  //                 })
  //             )
  //         ).map(el => new Date(el));
  //         datesArray = Array.from(
  //             new Set(
  //                 [
  //                     ...datesArrayExchane.map(el => el.toISOString()),
  //                     ...datesArrayOther.map(el => el.toISOString())
  //                 ]
  //             )
  //         ).map(el => new Date(el));

  //         return {
  //             pricingsDates: datesArray,              // Date[]
  //             currentPricingDate: datesArray[0],      // Date
  //             pricingsVisible: false,                  // boolean
  //         }
  //     }
  // },
  "/history/pricings": {
    GET: (data: any): PageData<IListItem> | Deal[] => {
      const dateToCalculate = data?.service?.filters?.valuationsDate;
      const items = dateToCalculate
        ? [
            ...dataExchangeArray
              .filter((el) => {
                if (
                  new Date(el.transactionDate).getTime() <= dateToCalculate.getTime() &&
                  dateToCalculate.getTime() < el.settlementDate.getTime()
                ) {
                  return true;
                }
                return false;
              })
              .map((el) => {
                const copy = JSON.parse(JSON.stringify(el));
                copy.isComplex = false;
                return copy;
              }),
            ...otherTransationArray
              .filter((el) => {
                if (
                  new Date(el.transactionDate).getTime() <= dateToCalculate.getTime() &&
                  dateToCalculate.getTime() < new Date(el.settlementDate).getTime()
                ) {
                  return true;
                }
                return false;
              })
              .map((el) => {
                const copy = JSON.parse(JSON.stringify(el));
                copy.isComplex = false;
                return copy;
              }),
          ]
        : [];
      if (data?.rest?.[0] === "csv") {
        return items;
      }

      return {
        items: items,
        isLastPage: true,
      };
    },
  },
  // "/history/pricings": {
  //     "GET": (data: any): PageData<IListItem> | Deal[] => {
  //         if (data?.rest?.[0] === 'csv') {
  //             return PrezentacjaDane["/history/pricings/csv"]["GET"](data);
  //         }
  //         const monthToCalculate = data?.service?.filters?.valuationsDate?.getMonth() ? [data.service.filters.valuationsDate.getMonth()] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  //         return {
  //             items:
  //                 [
  //                     ...dataExchangeArray
  //                         .filter(el => {
  //                             /*
  //                                 transactionDate <= valuationsDate < settlementDate
  //                             */
  //                             const elMonth = new Date(el.transactionDate).getMonth();

  //                             // const elMonth = el.settlementDate.getMonth();
  //                             if (monthToCalculate.includes(elMonth)) {
  //                                 return true;
  //                             }
  //                             return false;
  //                         })
  //                         .map(el => {
  //                             const copy = JSON.parse(JSON.stringify(el));
  //                             copy.isComplex = false;
  //                             return copy;
  //                         }),
  //                     ...otherTransationArray
  //                         .filter(el => {
  //                             const elMonth = new Date(el.transactionDate).getMonth();
  //                             // const elMonth = new Date(el.settlementDate).getMonth();
  //                             if (monthToCalculate.includes(elMonth)) {
  //                                 return true;
  //                             }
  //                             return false;
  //                         })
  //                         .map(el => {
  //                             const copy = JSON.parse(JSON.stringify(el));
  //                             copy.isComplex = false;
  //                             return copy;
  //                         }),
  //                 ],
  //             // [],
  //             isLastPage: true,
  //         }
  //     }
  // },
  "/history/pricings/csv": {
    GET: (): Deal[] => {
      // const dateToCalculate = data?.service?.filters?.valuationsDate;
      // const items = dateToCalculate ?
      //     [
      //         ...dataExchangeArray
      //             .filter(el => {
      //                 if (new Date(el.transactionDate) <= dateToCalculate && dateToCalculate < el.settlementDate) {
      //                     return true;
      //                 }
      //                 return false;
      //             })
      //             .map(el => {
      //                 const copy = JSON.parse(JSON.stringify(el));
      //                 copy.isComplex = false;
      //                 return copy;
      //             }),
      //         ...otherTransationArray
      //             .filter(el => {
      //                 if (new Date(el.transactionDate) <= dateToCalculate && dateToCalculate < el.settlementDate) {
      //                     return true;
      //                 }
      //                 return false;
      //             })
      //             .map(el => {
      //                 const copy = JSON.parse(JSON.stringify(el));
      //                 copy.isComplex = false;
      //                 return copy;
      //             }),
      //     ]
      //     :
      //     [];
      // return items;
      return [];
    },
  },
  // "/history/pricings/csv": {
  //     "GET": (data: any): Deal[] => {
  //         const monthToCalculate = data?.service?.filters?.valuationsDate?.getMonth() ? [data.service.filters.valuationsDate.getMonth()] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  //         const items = [
  //             ...dataExchangeArray
  //                 .filter(el => {
  //                     const elMonth = new Date(el.transactionDate).getMonth();
  //                     // const elMonth = el.settlementDate.getMonth();
  //                     if (monthToCalculate.includes(elMonth)) {
  //                         return true;
  //                     }
  //                     return false;
  //                 })
  //                 .map(el => {
  //                     const copy = JSON.parse(JSON.stringify(el));
  //                     copy.isComplex = false;
  //                     return copy;
  //                 }),
  //             ...otherTransationArray
  //                 .filter(el => {
  //                     const elMonth = new Date(el.transactionDate).getMonth();
  //                     // const elMonth = new Date(el.settlementDate).getMonth();
  //                     if (monthToCalculate.includes(elMonth)) {
  //                         return true;
  //                     }
  //                     return false;
  //                 })
  //                 .map(el => {
  //                     const copy = JSON.parse(JSON.stringify(el));
  //                     copy.isComplex = false;
  //                     return copy;
  //                 }),
  //         ]
  //         return items;
  //     }
  // },
  "/history/recent": {
    GET: (): RecentDeal[] => {
      return (
        PrezentacjaDane["/exchangehistory"]
          ["GET"]({})
          .items // .filter((el: ExchangeHistoryItem) => {
          //     if (el.status === "Settled") {
          //         return true;
          //     }
          //     return false;
          // })
          .map((el: ExchangeHistoryItem) => {
            return {
              currencyPair: el.currency + el.counterCurrency, //---
              transactionDate: el.transactionDate,
              settlementStatus: el.status,
              side: el.side,
              amount: el.amount,
              currency: el.currency,
              rate: el.rate,
            } as RecentDeal;
          })
      );
    },
  },
  "/history/review": {
    GET: (data: any): BaseExchangeDetail => {
      const objectId = data.rest[0];
      let objectToReturn: BaseExchangeDetail = {
        id: "",
        documentId: "",
        currencyPair: "",
        transactionDate: new Date(),
        product: PRODUCT_TYPES.spot,
        subtype: "",
        linkedDeals: [],
        pvp: {
          value: false,
          visible: false,
        },
        hasConfirmation: true,
        type: "",
      };

      (
        PrezentacjaDane["/deposithistory"]["GET"]({
          params: new Map(),
        }) as PageData<DepositHistoryItem>
      ).items.forEach((el: DepositHistoryItem) => {
        if (el.id === objectId) {
          el.details$.subscribe((detailData) => {
            objectToReturn = detailData as unknown as BaseExchangeDetail;
          });
        }
      });
      // (PrezentacjaDane["/exchangehistory"]["GET"]({ params: new Map() }) as PageData<ExchangeHistoryItem>).items
      dataExchangeArray.forEach((el: ExchangeHistoryItem) => {
        if (el.id == objectId) {
          el.details$.subscribe((detailData: unknown) => {
            objectToReturn = detailData as unknown as BaseExchangeDetail;
          });
        }
      });

      otherTransationArray.forEach((el: Deal) => {
        if (el.id == objectId) {
          el.details$.subscribe((detailData: unknown) => {
            objectToReturn = detailData as unknown as BaseExchangeDetail;
          });
        }
      });
      return objectToReturn;
    },
  },
  "/history/unsettled": {
    GET: (): UnsettledDealHistory[] => {
      const dealsArray: UnsettledDealHistory[] = dataExchangeArray
        .filter((el) => {
          if (el.status === statuses[2] && !(el.id === "120000001" || el.id === "120000002"))
            return true;
          return false;
        })
        .map((el) => {
          let productType: ProductType = PRODUCT_TYPES.overnight;
          el.details$.subscribe((detail: any) => {
            productType = detail.product;
          });
          return {
            settlementDate: el.settlementDate.toISOString(),
            side: el.side,
            amount: el.amount,
            currency: el.currency,
            counterAmount: el.counterAmount,
            counterCurrency: el.counterCurrency,
            rate: el.rate,
            product: productType,
          };
        });
      return dealsArray;
    },
  },
};

export default PrezentacjaDane;
