import { Injectable } from "@angular/core";
import { mapToAction } from "@core/models/observable-action";
import { Failure } from "@core/models/transaction";
import { Tracker } from "@core/user-tracking/tracker.service";
import { actions } from "@core/user-tracking/tracking.model";
import { isStatusSuccess, StatusResponse } from "@features/transaction/models";
import {
  createSuccessBody,
  TransactionResponseService,
} from "@features/transaction/services/response.service";
import { pollStatus } from "@features/transaction/utils/form";
import { formatNumber } from "@shared/utils/format";
import { filter, Subject, switchMap, tap } from "rxjs";
import {
  CreateOrderResponse,
  isInitialized,
  OrderForm,
  OrderInitialized,
  OrderSummary,
} from "../models/order-form";
import { OrderApiService } from "./order-api.service";

@Injectable({ providedIn: "root" })
export class OrderService {
  #confirmation = new Subject<[OrderForm, OrderSummary]>();
  confirmation$ = this.#confirmation.pipe(switchMap(this.#create.bind(this)));

  constructor(
    private api: OrderApiService,
    private transaction: TransactionResponseService,
    private tracker: Tracker
  ) {}

  confirm(form: OrderForm, summary: OrderSummary) {
    this.tracker.reportProgress({
      action: "init",
      data: { rate: (form.rate ?? form.points).toString(), type: form.type },
    });
    this.#confirmation.next([form, summary]);
  }

  #create([form, summary]: [OrderForm, OrderSummary]) {
    const handleCreateError = (response: CreateOrderResponse) =>
      isInitialized(response) || this.#onError(response.error);

    const getStatus = ({ token }: OrderInitialized) => this.api.getStatus(token).pipe(pollStatus());

    const handleStatus = (response: StatusResponse) =>
      isStatusSuccess(response)
        ? this.#onSuccess(response.transactionId, summary)
        : this.#onError(response.status);

    this.tracker.reportProgress({
      action: "confirm",
      data: { rate: (summary.rate ?? summary.points).toString(), type: form.type },
    });

    return this.api
      .create(form)
      .pipe(
        tap(handleCreateError),
        filter(isInitialized),
        switchMap(getStatus),
        tap(handleStatus),
        mapToAction()
      );
  }

  #onError(error?: Failure) {
    this.tracker.reportProgress({ action: actions.FAILURE });
    this.transaction.error({ source: "order", error });
  }

  #onSuccess(id: string, { oco, ...summary }: OrderSummary) {
    const body = createBody(id, summary);

    if (oco) {
      body.push(...createBody(id, oco, true));
    }

    this.tracker.reportProgress({
      action: actions.SUCCESS,
      data: { id },
    });
    this.transaction.success({ source: "order", body });
  }
}

const createBody = (id: string, summary: OrderSummary, isOco?: true) => {
  const { side, currency, rate, points } = summary;

  const params = {
    amount: formatNumber(+summary.amount, { style: "currency", ...currency }),
    value: formatNumber(+rate || +points, { decimals: 4 }),
    counterCurrency: summary.counterCurrency.code,
  };

  return createSuccessBody({
    source: "order",
    bodyLines: { key: `order.success.${side}.${rate ? "Rate" : "Points"}`, params },
    // the other order has Id incremented by 1.
    // no need to handle this on the server.
    id: isOco ? `${+id + 1}` : id,
    lookupTarget: id,
  });
};
