import { Component, Input } from "@angular/core";
import { MultiFxSummary } from "../../models/multifx-summary";

@Component({
  selector: "app-summary-detail",
  templateUrl: "./summary-detail.component.html",
})
export class SummaryDetailComponent {
  @Input() detail!: MultiFxSummary;

  get showCollateral() {
    return this.detail?.series.some(({ product }) => product === "Forward");
  }
}
