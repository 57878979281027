<div class="pko-header d-none d-md-flex">
  <h1 class="pko-title">{{ "pages.Alert" | translate }}</h1>
</div>

<form class="pko-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div pko-preloader *ngIf="isBusy$ | async"></div>
  <div class="pko-grid__row">
    <app-form-side class="col-10 col-md-7 col-lg-5"></app-form-side>
    <div class="w-100"></div>
    <app-form-amount-currency-pair
      [pairs]="pairs$ | async"
      [currencies]="currencies$ | async"
      class="col-10 col-md-7 col-lg-5">
    </app-form-amount-currency-pair>
    <div class="w-100"></div>
    <app-form-rate class="col-10 col-md-7 col-lg-5" tenor="SPOT"></app-form-rate>
    <div class="w-100"></div>
    <div class="col-10 col-md-7 col-lg-5">
      <div class="pko-grid__row pko-grid__row--12">
        <app-datepicker
          label="ExpirationDate"
          formControlName="expirationDate"
          class="col-12 col-sm-6"
          [max]="max"
          ngDefaultControl>
        </app-datepicker>
        <app-form-telephone formGroupName="phone" [flag]="false" class="col-12 col-sm-6">
        </app-form-telephone>
      </div>
    </div>
  </div>
  <app-form-footer type="alert"></app-form-footer>
</form>

<pre *ifIsDevMode>
    {{ form.value | json }}
</pre>
