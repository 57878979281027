import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { assertIsDefined } from "@utils/misc";
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  combineLatestWith,
  map,
  pluck,
  startWith,
} from "rxjs";
import {
  getMaxInvestmentDepositRate,
  getMinInvestmentDepositRate,
} from "../utils/inve-depo-rate.utils";

@Component({
  selector: "app-select-deviation",
  templateUrl: "select-deviation.component.html",
  styleUrls: ["./select-deviation.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SelectDeviationComponent implements OnInit {
  @Input() midSpotRate!: number;

  @Input()
  set deviationRange(value: number) {
    this._deviationRange.next(value);
  }

  @Input()
  set numberOfRanges(value: number) {
    this._numberOfRanges.next(value);
  }
  get numberOfRanges() {
    return this._numberOfRanges.getValue();
  }

  private _deviationRange = new BehaviorSubject<number>(0);
  private _numberOfRanges = new BehaviorSubject<number>(0);

  vm$!: Observable<{
    currency: string;
    deviations: { key: number; value: number }[];
    selectedIndex?: number;
  }>;

  get #controls() {
    return this.parent.form.controls;
  }

  #assertForm() {
    const { currency, rates } = this.#controls;
    assertIsDefined(currency);
    assertIsDefined(rates);
    return { currency, rates };
  }

  get ratesControlEnabled() {
    const { rates } = this.#assertForm();
    return rates.enabled;
  }

  set #rateValue(value: number[]) {
    const { rates } = this.#assertForm();
    rates.get("rate")?.setValue(value);
  }

  constructor(private parent: FormGroupDirective) {}

  ngOnInit(): void {
    const { currency, rates } = this.#assertForm();

    //If the start deviation is less than 100, it means that the rounded value on the tiles may be repeated
    const deviations$ = combineLatest([this._deviationRange, this._numberOfRanges]).pipe(
      map(([deviationRange, numberOfRanges]) => {
        const startDeviation = deviationRange / numberOfRanges;
        return Array.from({ length: numberOfRanges }, (_, index) => ({
          key: index + 1,
          value:
            startDeviation < 100
              ? Math.ceil(startDeviation * (index + 1))
              : Math.ceil((startDeviation * (index + 1)) / 100) * 100,
        }));
      })
    );

    const currency$ = currency.valueChanges.pipe(startWith(currency.value));

    const selectedIndex$ = rates.valueChanges.pipe(
      startWith(rates.value),
      pluck("rate"),
      combineLatestWith(deviations$),
      map(([rateRange, deviations]) => this.#checkIfSelected(rateRange, deviations))
    );

    this.vm$ = combineLatest([currency$, deviations$, selectedIndex$]).pipe(
      map(([currency, deviations, selectedIndex]) => ({
        currency,
        deviations,
        selectedIndex,
      }))
    );
  }

  selectDeviation(deviation: number): void {
    const min = getMinInvestmentDepositRate(this.midSpotRate, deviation);
    const max = getMaxInvestmentDepositRate(this.midSpotRate, deviation);
    this.#rateValue = [min, max];
  }

  #checkIfSelected(rateRange: number[], deviations: { key: number; value: number }[]) {
    const foundDeviation = deviations?.find((deviation) => {
      const min = getMinInvestmentDepositRate(this.midSpotRate, deviation.value);
      const max = getMaxInvestmentDepositRate(this.midSpotRate, deviation.value);
      return min === rateRange[0] && max === rateRange[1];
    });
    return foundDeviation?.key;
  }
}
