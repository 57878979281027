import { NgModule } from "@angular/core";
import { ActivationModule } from "@features/activation/activation.module";
import { SharedModule } from "@shared/shared.module";
import { TreasuryAgreementAuthPendingComponent } from "./components/auth-pending-modal.component";
import { CustomerItemComponent } from "./components/customer-item/customer-item.component";
import { CustomerListComponent } from "./components/customer-list/customer-list.component";
import { CustomerSelectionComponent } from "./components/customer-selection/customer-selection.component";
import { CustomerRoutingModule } from "./customer-routing.module";

@NgModule({
  imports: [SharedModule, ActivationModule, CustomerRoutingModule],
  declarations: [
    CustomerSelectionComponent,
    CustomerItemComponent,
    CustomerListComponent,
    TreasuryAgreementAuthPendingComponent,
  ],
})
export class CustomerModule {}
