import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { HolidaysService } from "@features/currency/holidays.service";
import {
  TenorDate,
  TenorDateForm,
  TenorDateVisibility,
  TenorService,
  getProduct,
  getSpotDate,
} from "@features/tenor";
import { EMPTY, Observable, defer, filter, map, startWith, tap } from "rxjs";

@Component({
  selector: "app-dpw-form-tenor-date-product",
  template: `<app-form-tenor-date
    formGroupName="settlement"
    label="SettlementDate"
    [show]="show"
    [tenorDates]="tenorDates$ | async"
    [holidays]="holidays$ | async"
    (changed)="onChanged($event)">
  </app-form-tenor-date> `,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DpwTenorDateProductFormComponent implements OnInit {
  @Input() show: TenorDateVisibility = "both";
  @Input() minTenor?: "SN";

  #spotDate = "";

  tenorDates$: Observable<TenorDate[]> = EMPTY;
  holidays$: Observable<string[]> = EMPTY;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(
    private parent: FormGroupDirective,
    private tenorService: TenorService,
    private holidayService: HolidaysService
  ) {}

  ngOnInit() {
    const { currencyPair } = this.#assertForm();

    // defer ensures this observable emits 🤷‍♂️
    const currencyPair$: Observable<string> = defer(() =>
      currencyPair.valueChanges.pipe(startWith(currencyPair.value), filter(Boolean))
    );

    this.tenorDates$ = this.tenorService.getTenorDatesByCurrencyPair(currencyPair$).pipe(
      tap((tenorDates) => (this.#spotDate = getSpotDate(tenorDates))),
      map((tenorDates) => {
        if (!this.minTenor) return tenorDates;
        return tenorDates.slice(tenorDates.findIndex(({ tenor }) => tenor === this.minTenor));
      })
    );

    this.holidays$ = this.holidayService.getHolidaysByCurrencyPair(currencyPair$);
  }

  onChanged({ tenor, date }: TenorDateForm) {
    if (!date) return;

    const nextProduct = getProduct({ tenor, date, spotDate: this.#spotDate });
    const currentProduct = this.#controls.product.value;

    if (nextProduct && currentProduct !== nextProduct) {
      this.#controls.product.setValue(nextProduct);
    }
  }

  #assertForm() {
    const { currencyPair, product, settlement } = this.#controls;

    if (!currencyPair || !product || !settlement) {
      throw new Error(
        "This component needs 'settlement', 'currencyPair' and 'product' controls present on the form!"
      );
    }

    return { currencyPair, product };
  }
}
