<div class="pko-modal__titlebar">
  <div class="pko-modal__titlebar-title">{{ data.label | translate }}</div>
</div>
<div class="pko-modal__container">
  <div class="pko-modal__body">
    <ng-container *ngFor="let item of data.children">
      <ng-container *ifHasRole="item.requiredRoles || 'HasCustomer'">
        <ng-container *ngIf="!item.isHidden || item.isHidden !== true">
          <ul class="pko-submenu" [class.pko-submenu--hidden]="item.hideOnMobile">
            <li
              class="pko-submenu__item pko-submenu__item--title"
              [class.pko-submenu__item--profile]="item.label === 'menu.profile.Title'"
              [class.pe-none]="!item.routerLink"
              [routerLink]="item.routerLink"
              [state]="{ origin: 'menu' }"
              (click)="item.routerLink && close()">
              <svg
                icon
                [name]="item.icon"
                class="pko-submenu__item-icon icon"
                *ngIf="item.icon"></svg>
              <ng-container
                *ngTemplateOutlet="
                  item.label === 'menu.profile.Title' ? profileItem : defaultItem;
                  context: { $implicit: item }
                "></ng-container>
            </li>
            <ng-container *ngFor="let child of item.children">
              <ng-container *ifHasRole="child.requiredRoles || 'HasCustomer'">
                <ng-container *ngIf="!child.isHidden || child.isHidden !== true">
                  <li
                    class="pko-submenu__item"
                    [routerLink]="child.routerLink"
                    (click)="close(); child.command?.()"
                    [state]="getState(child.navState)">
                    <span
                      class="pko-submenu__item-name"
                      [innerHTML]="child.label | translate"></span></li
                ></ng-container>
              </ng-container>
            </ng-container>
          </ul>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #defaultItem let-item>
  <span class="pko-submenu__item-name" [innerHTML]="item.label | translate"></span>
</ng-template>

<ng-template #profileItem let-item>
  <div class="pko-submenu__item-container">
    <div class="pko-submenu__item-name" [innerHTML]="item.label | translate"></div>
    <div class="pko-submenu__item-description">
      {{ customerShortName }}
    </div>
  </div>
</ng-template>
