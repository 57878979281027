import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BusyPayload, BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { origins } from "@core/user-tracking/tracking.model";
import { prepareForm } from "@features/exchange/exchange-utils";
import { PRODUCT_TYPES } from "@features/tenor";
import { handleFormErrors } from "@features/transaction/utils/form";
import { datepickerFormat } from "@utils/time";
import { Subscription, combineLatest, debounceTime, filter, map, merge } from "rxjs";
import { NormalExchangeForm } from "../../models";
import { ExchangeFormService } from "../../services";

@Component({
  selector: "app-form-exchange-simple",
  templateUrl: "form-exchange-simple.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleExchangeFormComponent implements OnDestroy {
  @HostBinding("class") class = "position-relative";

  #subscription: Subscription;
  #formFetching$ = this.busyService.currentState$.pipe(debounceTime(100), map(isFetchingFormData));
  isBusy$ = merge(this.formService.submission$.pipe(mapToBusy()), this.#formFetching$);

  today = datepickerFormat(new Date());
  accountsCollapsed = true;
  form: FormGroup;

  constructor(
    fb: FormBuilder,
    private formService: ExchangeFormService,
    private busyService: BusyService,
    private cdr: ChangeDetectorRef,
    private tracker: Tracker
  ) {
    const { retain } = window.history.state;
    formService.reset(retain);

    const form = formService.current as NormalExchangeForm;

    this.form = fb.group({
      side: form.side,
      currency: form.currency,
      counterCurrency: form.counterCurrency,
      amount: form.amount,
      account: form.account,
      counterAccount: form.counterAccount,
      currencyPair: null,
      collateral: fb.group(form.collateral ?? {}),
      settlement: fb.group({ tenor: "TOD", date: this.today }),
      product: PRODUCT_TYPES.overnight,
    });

    this.#subscription = this.#shouldBeOpen().subscribe(() => this.#openAccounts());
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
  }

  onSubmit() {
    const { form } = this;

    this.formService.save(prepareForm(form));

    if (!handleFormErrors(form)) {
      return checkAccounts(form.controls);
    }

    this.tracker.follow({ process: "exchange", origin: origins.SIMLE_DEAL_TILE }, { type: "spot" });
    this.formService.submit({ modal: true });
  }

  #shouldBeOpen() {
    // don't attempt to show accounts before account & currency data are ready
    // those are needed for proper account-select display
    const canOpen$ = this.#formFetching$.pipe(map((loading) => !loading));

    return combineLatest([canOpen$, hasUndefinedAccounts(this.form.controls)]).pipe(
      map((flags) => flags.every(Boolean)),
      filter(Boolean)
    );
  }

  #openAccounts() {
    if (!this.accountsCollapsed) return;

    // ngbCollapse enforces this approach. Even when done reactively
    // e.g. `[ngbCollapse]="accountsCollapsed$ | async"` it doesn't detect the change.
    this.accountsCollapsed = false;
    this.cdr.detectChanges();
  }
}

const isFetchingFormData = ({ working }: BusyPayload) =>
  ["/accounts", "/currency", "/exchange"].some((formDataUrls) =>
    working.some((currentUrls) => currentUrls.startsWith(formDataUrls))
  );

/**
 * @note
 * can't use form.valueChanges, because if there is a single account, it is disabled -
 * and disabled controls are undefined in form.value
 */
const hasUndefinedAccounts = ({ account, counterAccount }: FormControls) =>
  combineLatest([account.valueChanges, counterAccount.valueChanges]).pipe(
    map((accounts) => accounts.some((account) => !account))
  );

/**
 * Force accounts' `valueChanges` stream to emit. If they are invalid, the collapse must be open.
 */
const checkAccounts = ({ account, counterAccount }: FormControls) => {
  account.updateValueAndValidity();
  counterAccount.updateValueAndValidity();
};

type FormControls = FormGroup["controls"];
