import { Account } from "@features/accounts";
import { TenorDate } from "@features/tenor";
import {
  ComplexInitializationResponse,
  RESPONSE_TYPE,
  RateResponse,
  RateSuccess,
} from "@features/transaction/models";

export type InvestmentDepositForm = {
  currency: string;
  amount: number;
  currencyPair: string;
  start: { date: string; tenor: string };
  end: { date: string; tenor: string };
  account: string;
  rates: {
    rate: number[];
    interestRate: number;
  };
};

export type InvestmentDepositConfig = {
  currencies: Currency[];
  tenors: TenorDates[];
  offer: Offer;
  mifidValidationResult?: ComplexInitializationResponse;
};

export type Currency = {
  code: string;
  minimalAmount: number;
  maximalAmount: number;
  currencyPairs: string[];
};

export type TenorDates = {
  name: string;
  from: Date;
  to: Date;
};

export type Offer = {
  currencies: OfferCurrency[];
};

export type OfferCurrency = {
  code: string;
  amounts: OfferAmount[];
};

export type OfferAmount = {
  amount: number;
  currencyPairs: OfferCurrencyPair[];
};

export type OfferCurrencyPair = {
  code: string;
  midSpotRate: number;
  tenors: OfferTenor[];
};

export type OfferTenor = {
  name: string;
  settings: OfferTenorSettings;
};

export type OfferTenorSettings = {
  minimalInterestRate: number;
  maximalInterestRate: number;
  minSpread: number;
  maxDeviation: number;
  numberOfRateRanges: number;
};

export interface InvestmentDepositRateSuccess extends RateSuccess {
  terminationFee: number;
  standardInterestAmount: number;
  maximalRate: number;
  maximalInterestAmount: number;
  referenceRateDate?: string;
  referenceRate: string;

  salesProfit?: number;
  salesMargin?: number;
}

export type InvestmentDepositRateResponse = RateResponse<InvestmentDepositRateSuccess>;

export const rejectedRate: InvestmentDepositRateSuccess = {
  responseType: RESPONSE_TYPE.success,
  decisionTime: -1,
  token: "",
  terminationFee: 0,
  standardInterestAmount: 0,
  maximalRate: 0,
  maximalInterestAmount: 0,
  referenceRate: "",
};

export type InvestmentDepositSummary = SummarySpecials &
  Omit<InvestmentDepositForm, "account" | "currency"> &
  Pick<
    InvestmentDepositRateSuccess,
    | "token"
    | "terminationFee"
    | "standardInterestAmount"
    | "maximalRate"
    | "maximalInterestAmount"
    | "referenceRate"
    | "referenceRateDate"
    | "salesMargin"
    | "salesProfit"
  >;

interface SummarySpecials {
  currency: Currency;
  account: Account;

  start: Date;
  end: TenorDate;
}

export type ConfirmationData = Pick<
  InvestmentDepositSummary,
  "amount" | "currency" | "rates" | "maximalRate"
>;

export type SuccessData = ConfirmationData & { transactionId: string };

export interface InitializationResponseWrapper {
  response: ComplexInitializationResponse;
  resubmit: (consents: string[]) => void;
}

export type InitializationRequest = InvestmentDepositForm & { consents?: string[] };
