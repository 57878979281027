import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ExchangeWarningCodeService {
  outOfTargetMarket = false;

  checkOutOfTargetMarket(warningCodes: string[] | undefined) {
    this.outOfTargetMarket = warningCodes ? warningCodes.includes("OutOfTargetMarket") : false;
  }
}
