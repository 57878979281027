import { Component, HostBinding } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";
import { ShortcutsService } from "../../services/shortcuts.service";
import { ShortcutSettingsItemComponent } from "../shortcut-settings-item/shortcut-settings-item.component";

@Component({
  selector: "app-shortcut-settings",
  templateUrl: "./shortcut-settings.component.html",
})
export class ShortcutSettingsComponent {
  @HostBinding("class") class = "h-100";

  shortcuts$ = this.service.shortcutsToSelectable$;
  form: FormGroup;
  selectedGroup: FormGroup;
  notSelectedGroup: FormGroup;
  itemType = ShortcutSettingsItemComponent;

  modalConfig: Modal = {
    title: "shortcuts.settings.Title",
    buttons: { primary: { text: "buttons.Set", onClick: () => this.save() } },
  };

  constructor(
    fb: FormBuilder,
    private service: ShortcutsService,
    public activeModal: NgbActiveModal
  ) {
    const selectedGroup = new FormGroup({});
    const notSelectedGroup = new FormGroup({});
    this.form = fb.group({
      selectedPairs: selectedGroup,
      notSelectedPairs: notSelectedGroup,
    });

    this.selectedGroup = selectedGroup;
    this.notSelectedGroup = notSelectedGroup;
  }

  save() {
    const selectedShortcuts = Object.entries(this.selectedGroup.value).map(([first]) => first);
    this.service.updateShortcuts(selectedShortcuts.length ? selectedShortcuts : ["NoSelection"]);
    this.activeModal.close();
  }
}
