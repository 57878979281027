<app-modal-dialog [data]="data" [formGroup]="form">
  <span class="d-flex align-self-start">
    {{
      "dialog.warning.body.HasSimilarDeal.Title." +
        (data.transactionType === "exchange" ? "Transaction" : "Deposit") | translate
    }}
  </span>

  <table class="d-none d-sm-table" pkoTable [columns]="columns" [items]="items">
    <ng-template #itemTemplate let-item>
      <tr
        class="pko-table__row align-middle"
        similar-deals-warning-item
        [similarDeal]="item"
        [transactionType]="data.transactionType"
        role="table"></tr
    ></ng-template>
  </table>

  <div class="d-block d-sm-none flex-grow-1">
    <div
      *ngFor="let item of items"
      similar-deals-warning-item
      [similarDeal]="item"
      [transactionType]="data.transactionType"
      role="stack"></div>
  </div>

  <app-form-control validate="approved">
    <app-input-check
      class="mx-auto"
      formControlName="approved"
      label="{{
        'dialog.warning.checkbox.HasSimilarDeal.' +
          (data.transactionType === 'exchange' ? 'transaction' : 'deposit')
      }}"
      ngDefaultControl>
    </app-input-check>
  </app-form-control>
</app-modal-dialog>
