import { Component, Input, OnInit, Self } from "@angular/core";
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  NgControl,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-input-amount-left",
  templateUrl: "input-amount-left.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AmountLeftInputComponent implements OnInit {
  @Input() currency!: string;
  @Input() label?: string;
  @Input() descriptor = "exchange.OpenBalance";
  @Input() max!: number;

  constructor(@Self() private ngControl: NgControl) {}

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  ngOnInit() {
    this.control.setValidators([Validators.required, Validators.max(this.max)]);
  }
}
