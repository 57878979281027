<ng-container [formGroup]="form">
  <app-input-search
    *ngIf="hasSearchBar"
    [placeholder]="placeholder"
    formControlName="search"
    class="sticky-top"
    ngDefaultControl></app-input-search>
  <div class="pko-section">
    <h3 class="pko-section__title">{{ selectedTitle | translate }}</h3>
    <p *ngIf="selectedSubtitle" class="pko-section__subtitle">{{ selectedSubtitle | translate }}</p>
    <div [formGroup]="selectedGroup">
      <ng-template #selectedContainer></ng-template>
      <app-alert
        [content]="emptySelectionTitle"
        *ngIf="emptySelectionTitle && selectedCount <= 0"></app-alert>
    </div>
    <h3 class="pko-section__title">{{ unSelectedTitle | translate }}</h3>
    <p *ngIf="unSelectedSubtitle" class="pko-section__subtitle">
      {{ unSelectedSubtitle | translate }}
    </p>
    <div [formGroup]="unSelectedGroup">
      <ng-template #notSelectedContainer></ng-template>
    </div>
  </div>
</ng-container>
