import { Component, HostBinding, OnDestroy } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { Currency, getCurrencyByCode } from "@features/currency";
import { prepareForm } from "@features/exchange/exchange-utils";
import { Button } from "@features/transaction/models/button";
import { handleFormErrors } from "@features/transaction/utils/form";
import { round } from "@utils/number";
import { BehaviorSubject, Observable, Subscription, combineLatest, map, merge, take } from "rxjs";
import { DpwService } from "../dpw.service";
import { IobFormService } from "../services/iob-form.service";

@Component({
  selector: "app-dpw-wizard",
  templateUrl: "dpw-wizard.component.html",
})
export class DpwWizardComponent implements OnDestroy {
  @HostBinding("class") class = "pko-dpw-wizard";
  form: FormGroup;

  #page = new BehaviorSubject<number>(1);
  page$ = this.#page.asObservable();
  #subscription: Subscription;

  get page() {
    return this.#page.value;
  }

  isBusy$ = merge(this.formService.submission$.pipe(mapToBusy()), this.busyService.isBusy$);

  constructor(
    fb: FormBuilder,
    private formService: IobFormService,
    private busyService: BusyService,
    private dpwService: DpwService,
    private router: Router
  ) {
    const { retain } = window.history.state;
    this.formService.reset(retain);
    const { amount, currency, counterCurrency, side, settlement } = this.formService.current;
    const currencyControl = fb.control(currency);
    const amountControl = fb.control(
      amount,
      Validators.required,
      validateMinimum(currencyControl, this.dpwService.iobLimits, this.dpwService.currencies$)
    );
    this.form = fb.group({
      side: [retain === "all" ? side : null, [Validators.required]],
      amount: amountControl,
      currency: currencyControl,
      counterCurrency,
      product: null,
      account: null,
      counterAccount: [this.formService.current.counterAccount, [Validators.required]],
      settlement: fb.group(settlement),
      pvp: null,
      collateral: fb.group({}),
    });

    if (retain === "all") {
      this.goForward();
    }

    this.#subscription = currencyControl.valueChanges.subscribe(() =>
      amountControl.updateValueAndValidity()
    );
  }

  ngOnDestroy(): void {
    this.#subscription.unsubscribe();
  }

  forwardValidator = () => {
    if (this.page === 1) return this.form.controls.side.valid;
    return handleFormErrors(this.form);
  };

  forward: Button = {
    text: "buttons.forward.dpw",
    onClick: () => {
      this.goForward();
    },
  };

  goForward() {
    const page = this.#page.value + 1;
    this.#page.next(page);

    if (page === 2 && this.form.controls.side.value === "Sell")
      this.router.navigate(["/transactions/iob/dpw"]);

    if (page === 3) this.onSubmit();
  }

  back$: Observable<Button> = this.#page.pipe(
    map(
      (page) =>
        ({
          text: "buttons.Back",
          onClick: () => {
            this.#page.next(this.#page.value - 1);
          },
          hidden: page === 1,
        } as Button)
    )
  );

  onSubmit() {
    this.save();

    handleFormErrors(this.form) && this.formService.submit();
  }

  private save = () => this.formService.save(prepareForm(this.form));
}

function validateMinimum(
  currency: AbstractControl,
  limits: Observable<Record<string, number>>,
  currencies: Observable<Currency[]>
) {
  return (amount: AbstractControl): Observable<ValidationErrors | null> =>
    combineLatest([currencies, limits]).pipe(
      map(([currencies, limits]) => {
        const currencyLimit = round(
          limits[currency.value],
          getCurrencyByCode(currencies, currency.value)!.decimals
        );
        return currencyLimit <= amount.value
          ? null
          : { minIobAmount: `${currencyLimit} ${currency.value}` };
      }),
      take(1)
    );
}
