import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import { AccountsService } from "@features/accounts";
import { startWith, switchMap } from "rxjs";

@Component({
  selector: "app-dpw-select-account",
  template: `<app-select-account
    label="{{ side === 'Buy' ? 'accounts.From' : 'accounts.To' }}"
    validate="counterAccount"
    formControlName="counterAccount"
    [items]="accounts$ | async"
    ngDefaultControl></app-select-account>`,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DpwAccountComponent implements OnInit {
  @Input()
  get side() {
    return this.#controls.side.value;
  }

  get #controls() {
    return this.parent.form.controls;
  }

  accounts$ = this.#observeAccounts();

  constructor(private parent: FormGroupDirective, private accountsService: AccountsService) {}

  ngOnInit(): void {
    this.#assertForm();
  }

  #assertForm() {
    const { counterCurrency, counterAccount } = this.#controls;

    if (!counterCurrency || !counterAccount) {
      throw new Error(
        "This component needs 'counterCurrency' and 'counterAccount' controls present on the form!"
      );
    }
  }

  #observeAccounts() {
    const counterCurrency = this.#controls.counterCurrency;
    const getAccounts = this.accountsService.getAccounts("iob");
    return counterCurrency.valueChanges.pipe(
      startWith(counterCurrency.value),
      switchMap(getAccounts)
    );
  }
}
