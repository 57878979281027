import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { Dpw } from "@features/dpw/dpw.model";
import { DpwService } from "@features/dpw/dpw.service";
import { DpwFormService } from "@features/dpw/services/dpw-form.service";
import { groupAndSortDataResult } from "@features/history-common/history-common.utils";
import { MultiFxFormService } from "@features/multifx/services/multifx-form.service";
import { datepickerFormat, timepickerFormat } from "@utils/time";
import { Observable, catchError, map, of } from "rxjs";
import { BaseDpwListComponent } from "../base-dpw-list.component";

@Component({
  selector: "app-dpw-tile",
  templateUrl: "dpw-tile.component.html",
})
export class DpwTileComponent extends BaseDpwListComponent {
  pendingGroup$ = mapToDataGroup(this.pending$, dpwKeySelector);
  activeGroup$ = mapToDataGroup(this.active$, dpwKeySelector);

  isTile = true;

  constructor(
    dpwService: DpwService,
    fb: FormBuilder,
    formService: DpwFormService,
    multiFormService: MultiFxFormService,
    busyService: BusyService,
    tracker: Tracker
  ) {
    super(dpwService, fb, formService, multiFormService, busyService, tracker);
  }
}

function mapToDataGroup<T>(items: Observable<T[]>, groupKeySelector: (dpw: T) => string) {
  return items.pipe(
    map((data) => ({ data })),
    catchError(() => of({ error: true })),
    map(groupAndSortDataResult(groupKeySelector, false))
  );
}

function dpwKeySelector(dpw: Dpw) {
  const a = `${datepickerFormat(dpw.expireTime)} ${timepickerFormat(dpw.expireTime)}`;
  return a;
}
