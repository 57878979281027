import { Component, HostBinding } from "@angular/core";
import { ActivationApiService } from "@features/activation/activation-api.service";

@Component({ selector: "app-manage-contracts", templateUrl: "./manage-contracts.component.html" })
export class ManageContractsComponent {
  @HostBinding("class") class = "pko-activation";

  regulations$ = this.service.getRelatedRegulations;

  constructor(private service: ActivationApiService) {}
}
