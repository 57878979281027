<app-modal-container [config]="modalConfig" [formGroup]="form">
  <app-groups-selector
    selectedTitle="indicatives.SelectedTitle"
    selectedSubtitle="indicatives.SelectedSubtitle"
    unSelectedTitle="indicatives.UnselectedTitle"
    unSelectedSubtitle="indicatives.UnselectedSubtitle"
    [selectedGroup]="selectedPairsGroup"
    [unSelectedGroup]="notSelectedPairsGroup"
    [elements]="selectablePairs"
    [componentType]="itemType"
    placeholder="indicatives.SearchPair"
    [maxSelected]="config.config.indicatives.pairLimit"
    [minSelected]="1">
  </app-groups-selector>
</app-modal-container>
