<ng-container *ngIf="vm">
  <ng-container *ngIf="vm.netSettlement; then net; else vm.isBuying ? buying : selling">
  </ng-container>

  <ng-template #net>
    <app-select-account
      label="accounts.Settlement"
      validate="counterAccount"
      formControlName="counterAccount"
      [items]="vm.counterAccounts"
      ngDefaultControl></app-select-account>
  </ng-template>

  <ng-template #selling>
    <app-select-account
      label="accounts.From"
      validate="account"
      formControlName="account"
      [items]="vm.accounts"
      ngDefaultControl></app-select-account>

    <app-select-account
      label="accounts.To"
      validate="counterAccount"
      formControlName="counterAccount"
      [items]="vm.counterAccounts"
      ngDefaultControl></app-select-account>
  </ng-template>

  <ng-template #buying>
    <app-select-account
      label="accounts.From"
      validate="counterAccount"
      formControlName="counterAccount"
      [items]="vm.counterAccounts"
      ngDefaultControl></app-select-account>

    <app-select-account
      label="accounts.To"
      validate="account"
      formControlName="account"
      [items]="vm.accounts"
      ngDefaultControl></app-select-account>
  </ng-template>
</ng-container>
