import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Logger } from "@core/logging";
import { uid } from "@utils/string";
import { difference } from "@utils/time";
import { ProcessTrace, Trace, actions, completed } from "./tracking.model";

@Injectable({ providedIn: "root" })
export class Tracker {
  private _trackedProcess?: ProcessTrace;

  constructor(private _http: HttpClient, private _logger: Logger) {}

  /**
   * Initialize new process trace: starting new transaction of exchange, order, or deposit
   * @param trace process type and origin.
   */
  follow({ process, origin }: Partial<ProcessTrace>, data?: Record<string, string>): void {
    if (this._trackedProcess) {
      const { process, id } = this._trackedProcess;
      this._logger.logInfo("tracking", `Unfinished ${process} process ${id}.`);
    }

    if (!process || !origin) return;

    this._trackedProcess = {
      process,
      origin,
      id: uid(),
      start: new Date(),
    };

    this.reportProgress({
      action: actions.ENTRY,
      data: { origin, ...data },
    });
  }

  /**
   * Report an action during an ongoing process.
   * @param trace process action and optional data
   */
  reportProgress({ action, data }: Partial<Trace>) {
    if (!this._trackedProcess) {
      throw new NoTrackedProcessError(`No process for action ${action} is being followed`);
    }

    const { process: category, id: processId } = this._trackedProcess;

    if (completed(action)) {
      const age = difference(new Date(), this._trackedProcess.start, "seconds").toFixed(3);

      data = { ...data, age };
      this._trackedProcess = undefined;
    }

    action && this.report({ category, action, data, processId });
  }

  /**
   * Track user's action.
   * @param trace
   */
  report(trace: Trace) {
    this._http
      .post("/action", trace)
      .toPromise()
      .catch(() => console.error("Tracing error!"));
  }
}

export class NoTrackedProcessError extends Error {}
