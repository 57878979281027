<form
  class="pko-form pko-form--full pko-multifx__transaction"
  [formGroup]="form"
  (ngSubmit)="save()">
  <div class="pko-grid__row pko-grid__row--12">
    <app-select
      [items]="sides"
      bindValue="value"
      formControlName="side"
      label="order.details.Side"
      class="pko-multifx__transaction-side col-12 col-sm-6 col-md-4"
      ngDefaultControl
      placeholder="placeholders.Select">
      <ng-template ng-label-tmp ng-option-tmp let-item="item">
        {{ item.text | translate }}
      </ng-template>
    </app-select>
    <app-form-control
      label="Amount"
      validate="amount"
      [inputGroup]="true"
      class="pko-multifx__transaction-amount col-12 col-sm-6 col-md-4">
      <app-input-number
        formControlName="amount"
        placeholder="placeholders.Amount"
        [decimals]="2"
        [paste]="true"
        ngDefaultControl>
      </app-input-number>
      <span class="align-self-center ps-3">{{ currency | async }}</span>
    </app-form-control>

    <app-form-multifx-tenor-date-product
      [currencyPair]="currencyPair"
      class="pko-multifx__transaction-date col-12 col-sm-6 col-md-4"></app-form-multifx-tenor-date-product>
  </div>
  <div class="pko-multifx__transaction-footer">
    <button type="button" class="btn btn-secondary mb-3 mb-sm-0 ms-sm-auto" (click)="delete()">
      {{ "buttons.Delete" | translate }}
    </button>

    <button type="submit" class="btn btn-primary">
      {{ "buttons.Save" | translate }}
    </button>
  </div>
</form>
