<div ngbDropdown dropdownClass="pko-action" class="pko-action" container="body">
  <button
    type="button"
    class="btn rounded-circle pko-action__button"
    ngbDropdownToggle
    (click)="$event.stopPropagation()"
    style="min-height: auto">
    <svg icon name="more-2" class="icon--lg"></svg>
  </button>
  <div class="pko-action__list" ngbDropdownMenu>
    <div class="pko-action__list-container">
      <ng-container *ngFor="let m of menu; trackBy: trackByKey">
        <button
          *ngIf="!m.hidden"
          class="pko-action__list-item"
          (click)="executeMenuItem(m, $event)"
          ngbDropdownItem>
          {{ m.text | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
