import { Component, HostBinding, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";
import { Message } from "../messages.model";

@Component({ selector: "app-message", templateUrl: "./message.component.html" })
export class MessageComponent {
  @HostBinding("class") class = "h-100 h-sm-auto";

  @Input()
  public get data(): Message {
    return this._data;
  }
  public set data(value: Message) {
    this._data = value;
    this.modalConfig = {
      title: value.title,
      hideCloseButton: value.requiresAgreement,
      buttons: value.requiresAgreement
        ? {
            primary: {
              text: "messages.ReadAndAccept",
              onClick: () => this.activeModal.close(true),
            },
            secondary: {
              text: "messages.DoNotAccept",
              onClick: () => this.activeModal.close(false),
            },
          }
        : {
            primary: {
              text: "messages.Read",
              onClick: () => this.activeModal.close(true),
            },
          },
    };
  }

  private _data!: Message;

  modalConfig?: Modal;

  constructor(private activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close(true);
  }
}
