import { Component, HostBinding, Input, OnInit, Optional } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session";
import { NON_FORWARD_TENORS } from "@features/tenor";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  delay,
  from,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs";
import { BaseGraphComponent } from "../base-graph.component";
import { GraphButtonConfig, GraphModel } from "../graph.model";
import { GraphService } from "../graph.service";

@Component({
  selector: "app-mobile-graph",
  templateUrl: "mobile-graph.component.html",
})
export class MobileGraphComponent extends BaseGraphComponent implements OnInit {
  @HostBinding("class") class = "pko-details";

  @Input() data!: {
    currencyPair: string;
    tenor: string;
  };

  isBusy$ = this.busyService.isBusy$;

  graph$: Observable<GraphModel | null> = of(null);
  type$ = new BehaviorSubject<"linear" | "candle">("linear");

  form!: FormGroup;
  tenors = [...NON_FORWARD_TENORS];
  pairs$ = this.graphService.getPairs();

  constructor(
    fb: FormBuilder,
    userService: UserService,
    translate: TranslateService,
    private graphService: GraphService,
    private busyService: BusyService,
    private preferences: PreferencesService,
    @Optional() private activeModal: NgbActiveModal
  ) {
    super(translate, userService, false);
    this.form = fb.group({
      side: null,
      tenor: null,
      currencyPair: null,
    });
  }

  get side() {
    return this.form.controls.side.value;
  }

  get type() {
    return this.type$.value;
  }

  get currencyPair() {
    return this.form.controls.currencyPair.value;
  }

  get tenor() {
    return this.form.controls.tenor.value;
  }

  get isSimple() {
    return this.preferences.dashboard.simple;
  }

  ngOnInit(): void {
    this.form.setValue(this.data);

    const { side, currencyPair, tenor } = this.form.controls;

    const loadPreferences$ = this.preferences.graph$.pipe(
      take(1),
      tap((preferences) => {
        this.type$.next(this.isSimple ? "linear" : preferences.type);
        this.form.controls.side.setValue(preferences.side, { emitEvent: false });
        this.graphRange = preferences.period;

        if (this.isSimple) this.form.controls.tenor.setValue("TOD");
      })
    );

    this.graph$ = combineLatest([this.graphService.getConfig(), loadPreferences$]).pipe(
      switchMap(([config]) =>
        combineLatest([
          of(config),
          this.type$,
          side.valueChanges.pipe(debounceTime(0), startWith(side.value)),
          currencyPair.valueChanges.pipe(debounceTime(0), startWith(currencyPair.value)),
          tenor.valueChanges.pipe(debounceTime(0), startWith(tenor.value)),
        ]).pipe(
          switchMap(([config, , , pair, tenor]) =>
            this.graphService
              .getData(pair, tenor)
              .pipe(
                map((data) =>
                  this.type$.value === "linear"
                    ? this.getLinearChartOptions(config[0], data)
                    : this.getCandleChartOptions(config[1], data)
                )
              )
          ),
          switchMap((data) => from([null, data]).pipe(delay(0)))
        )
      )
    );
  }

  switchType(type: "linear" | "candle") {
    this.type$.next(type);
  }

  export(type: "png" | "svg") {
    switch (type) {
      case "png":
        this.chart.exportChartLocal();
        break;
      case "svg":
        this.chart.exportChartLocal({
          type: "image/svg+xml",
        });
        break;
    }
  }

  graphButtons(bc: GraphButtonConfig[]) {
    const dg = (units: any) => {
      return {
        approximation: this.type == "linear" ? "close" : "ohlc",
        dateTimeLabelFormats: this.getDateTimeLabelFormats(),
        forced: true,
        enabled: true,
        units: units,
      };
    };

    return [
      {
        type: bc[0].interval,
        count: bc[0].count,
        text: this.translate.instant("graphs.buttons.Day"),
        dataGrouping: dg([["minute", [this.type === "linear" ? 5 : 60]]]),
      },
      {
        type: bc[1].interval,
        count: bc[1].count,
        text: this.translate.instant("graphs.buttons.Week"),
        dataGrouping: dg([["hour", [this.type === "linear" ? 1 : 4]]]),
      },
      {
        type: bc[2].interval,
        count: bc[2].count,
        text: this.translate.instant("graphs.buttons.Month1"),
        dataGrouping: dg([["hour", [this.type === "linear" ? 8 : 24]]]),
      },
      {
        type: bc[3].interval,
        count: bc[3].count,
        text: this.translate.instant("graphs.buttons.Month3"),
        dataGrouping: dg([["hour", [this.type === "linear" ? 24 : 48]]]),
      },
      {
        type: bc[4].interval,
        count: bc[4].count,
        text: this.translate.instant("graphs.buttons.Month12"),
        dataGrouping: dg([this.type === "linear" ? ["day", [7]] : ["week", [2]]]),
      },
    ];
  }

  getDateTimeLabelFormats() {
    return {
      millisecond: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M", " - %H:%M"],
      second: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M:%S", " - %H:%M:%S"],
      minute: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M:%S", " - %H:%M:%S"],
      hour: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M", " - %d.%m.%Y %H:%M"],
      day: ["%d.%m.%Y", "%d.%m.%Y %H:%M", " - %d.%m.%Y %H:%M"],
      week: ["%d.%m.%Y", "%d.%m.%Y %H:%M", " - %d.%m.%Y %H:%M"],
      month: ["%d.%m.%Y", "%d.%m.%Y", " - %d.%m.%Y"],
      year: ["%b.%Y", "%b.%Y", "%b.%Y"],
    };
  }

  close(): void {
    this.activeModal.close();
  }
}
