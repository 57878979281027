import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CurrencyApiService } from "@features/currency/currency-api.service";
import { shareReplay } from "rxjs";
import { Dpw } from "./dpw.model";

@Injectable({ providedIn: "root" })
export class DpwService {
  pairs$ = this.api.getCurrencyPairs("iob-pairs");
  currencies$ = this.api.getCurrencies("exchange");

  constructor(private http: HttpClient, private api: CurrencyApiService) {}

  getList() {
    return this.http.get<Dpw[]>("/dpw/history");
  }

  iobLimits = this.http
    .get<Record<string, number>>("/iob/limits")
    .pipe(shareReplay({ bufferSize: 1, refCount: false }));
}
