import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session/user.service";
import { Subscription } from "rxjs";

@Directive({ selector: "[ifHasRole]" })
export class IfHasRoleDirective implements OnInit, OnDestroy {
  @Input("ifHasRole") role!: string;
  #subscription: Subscription;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>,
    private userService: UserService,
    private preferences: PreferencesService
  ) {
    this.#subscription = this.preferences.dashboard$.subscribe(() => this.#build());
  }

  ngOnInit() {
    this.#build();
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
  }

  #build() {
    this.viewContainerRef.clear();
    if (this.userService.hasRole(this.role)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
