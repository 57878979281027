import { map, Observable, pluck, startWith } from "rxjs";

export interface ObservableAction<T = void> {
  loading: boolean;
  response?: T;
}

export const loading = { loading: true };
export const complete = <T>(response: T) => ({ loading: false, response });

export function mapToAction<T>() {
  return (source$: Observable<T>): Observable<ObservableAction<T>> =>
    source$.pipe(
      map((response: T) => complete<T>(response)),
      startWith(loading)
    );
}

export function mapToBusy<T>() {
  return (source$: Observable<ObservableAction<T>>) => source$.pipe(pluck("loading"));
}
