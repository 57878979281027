<div class="btn-group pko-btn-group" role="group">
  <ng-container *ngFor="let option of mappedOptions">
    <input
      type="radio"
      [value]="option.value"
      class="btn-check pko-btn-group__check"
      [id]="option.id"
      [name]="name"
      [formControl]="control" />
    <label
      class="btn btn-outline-primary pko-btn-group__label"
      [class.btn--pointed]="pointed"
      [for]="option.id">
      {{ option.text | translate }}
    </label>
  </ng-container>
</div>
