<ng-container [formGroup]="form">
  <div class="pko-indicatives__list" *ngIf="pairs$ | async as pairs">
    <div
      *ngFor="let pair of pairs | slice: 0:8; let i = index"
      class="pko-indicative"
      [class.pko-indicative--selected]="
        (selectedPair$ | async) === pair.code && !(breakpoint | breakpoint: 'xs')
      "
      id="panel-{{ i }}">
      <input
        type="radio"
        class="pko-radio-stack__input"
        [value]="pair.code"
        [id]="pair.code"
        formControlName="selectedPair"
        class="d-none" />
      <label [for]="pair.code" class="w-100">
        <app-indicative-rate
          [pair]="pair"
          [rates]="rates$"
          [isSimple]="false"
          [graphs$]="graphs$"></app-indicative-rate
      ></label>
    </div>
    <p class="pko-indicatives__footnote pko-infotext pko-infotext--xs" *ngIf="pairs">
      {{ "indicatives.NotTransactional" | translate }}
    </p>
  </div>
  <ng-container *ngIf="selectedPair$ | async as selectedPair">
    <app-graph
      [currencyPair]="selectedPair"
      class="pko-indicatives__graph"
      [tenor]="selectedTenor"
      *ngIf="selectedPair && (breakpoint | breakpoint: ['md', 'lg'])">
    </app-graph>
  </ng-container>
</ng-container>
