import { HttpParams } from "@angular/common/http";

export function objectToHttpParams(object: Object, params: HttpParams, keyPrefix = ""): HttpParams {
  return Object.entries(object)
    .filter(([, value]) => value !== null)
    .reduce(
      (params, [key, value]) => flattenKeyValuePair(constructKey(keyPrefix, key), value, params),
      params
    );
}

function constructKey(keyPrefix: string, key: string) {
  return keyPrefix ? keyPrefix + key[0].toUpperCase() + key.slice(1) : key;
}

function flattenKeyValuePair(originalKey: string, originalValue: unknown, params: HttpParams) {
  if (originalValue instanceof Array) {
    return originalValue.reduce((acc, x, i) => acc.set(`${originalKey}[${i}]`, `${x}`), params);
  }

  if (!(originalValue instanceof Object)) {
    return params.set(originalKey, `${originalValue}`);
  }

  return objectToHttpParams(originalValue, params, originalKey);
}
