import { Component } from "@angular/core";
import { ActionItem } from "@core/menus/action-item";
import { NavigationService } from "@core/navigation";
import {
  UnsettledDeal,
  UserNotificationComponent,
} from "@features/notifications/notifications.model";

@Component({
  selector: "app-notifications-unsettled-deal",
  templateUrl: "./notifications-unsettled-deal.component.html",
})
export class NotificationsUnsettledDealComponent extends UserNotificationComponent {
  get deal(): UnsettledDeal {
    return this.notification as UnsettledDeal;
  }

  constructor(private navigation: NavigationService) {
    super();
  }

  get icon(): string {
    return "loan";
  }

  get header(): string {
    return "notifications." + this.deal.name;
  }

  mainAction(): void {
    this.navigation.navigate(["/history/exchange/fx", this.deal.mainDealId], {
      state: { origin: "notification" },
    });
  }

  get menu(): ActionItem[] {
    return [
      {
        text: "notifications.Details",
        callback: () => this.mainAction(),
      } as ActionItem,
    ];
  }
}
