import { Component } from "@angular/core";
import { DataResult } from "@core/models/data-result";
import { groupAndSortDataResult } from "@features/history-common/history-common.utils";
import { GroupedArray } from "@utils/collection";
import { datepickerFormat } from "@utils/time";
import { map, Observable } from "rxjs";
import { UnsettledDeal } from "../recent-history.model";
import { RecentHistoryService } from "../recent-history.service";

@Component({
  selector: "app-unsettled-history",
  templateUrl: "./unsettled-history.component.html",
})
export class UnsettledHistoryComponent {
  deals$: Observable<DataResult<GroupedArray<UnsettledDeal>>> =
    this._service.getUnsettledDeals.pipe(
      map(groupAndSortDataResult((current) => datepickerFormat(current.settlementDate), false))
    );

  constructor(private _service: RecentHistoryService) {}
}
