import { Failure } from "@core/models/transaction";
import { RateResponse, RateSuccess, RESPONSE_TYPE } from "@features/transaction/models";

export interface ExchangeRateSuccess extends RateSuccess {
  rate: number;
  counterAmount: number;
  collateralAmount?: number;

  /** Defined only for fx close */
  netSettlementAmount?: number;

  hasInsufficientFunds?: boolean;
  balanceWarning?: Failure;

  farRate?: number;
  farCounterAmount?: number;

  salesProfit?: number;
  salesMargin?: number;
}

export type ExchangeRateResponse = RateResponse<ExchangeRateSuccess>;

export const rejectedRate: ExchangeRateSuccess = {
  responseType: RESPONSE_TYPE.success,
  decisionTime: -1,
  token: "",
  rate: 0,
  counterAmount: 0,

  farRate: 0,
  farCounterAmount: 0,
};
