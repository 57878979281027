import { Component, HostBinding, OnDestroy } from "@angular/core";
import { AbstractControl, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ObservableAction } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { AccountsService, getAccountByNumber } from "@features/accounts";
import { CurrencyService, getCurrencyByCode } from "@features/currency";
import {
  ConfirmationRequest,
  DecisionViewModel,
  RateStreamable,
  StatusResponse,
  isRateSuccess,
} from "@features/transaction/models";
import { handleFormErrors } from "@features/transaction/utils/form";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  distinct,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  merge,
  of,
  share,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { InvestmentDepositSummary } from "../investment-deposit.model";
import { InvestmentDepositFormService } from "../services/investment-deposit-form.service";
import { InvestmentDepositResponseService } from "../services/investment-deposit-response.service";
import { InvestmentDepositWarningCodeService } from "../services/investment-deposit-warning-code-service.service";
import { InvestmentDepositService } from "../services/investment-deposit.service";

@Component({
  selector: "app-decision",
  templateUrl: "decision.component.html",
})
export class InvestmentDepositDecisionComponent implements OnDestroy {
  @HostBinding("class") class = "pko-decision";

  form = this.fb.group({
    terminationFee: [
      null,
      (control: AbstractControl) => (!control.value ? { required: true } : null),
    ],
  });

  private _confirm = new Subject<[ConfirmationRequest, InvestmentDepositSummary]>();
  private confirmation$ = this._confirm.pipe(
    switchMap((data) =>
      this.depositService.confirm(...data).pipe(
        tap((response: ObservableAction<StatusResponse>) => {
          if (response.loading) return;

          const status = response.response!;
          this.waitingStart.next(status);
        })
      )
    )
  );

  private _reject = new Subject<string>();
  private rejection$ = this._reject.pipe(
    distinct(),
    filter((token) => !!token),
    switchMap((token) => this.depositService.reject(token)),
    takeUntil(this._confirm)
  );

  private rate$ = this.route.params.pipe(
    map((x: any) => x.token),
    switchMap((token) => this.depositService.pollRate(token, this._reject)),
    share(),
    takeUntil(this._confirm)
  );

  waitingStart = new BehaviorSubject<StatusResponse | undefined>(undefined);
  waitingStop = new Subject<void>();

  loading$ = merge(
    this.rate$.pipe(map(() => false)),
    this.confirmation$.pipe(map(() => true)),
    this.formService.submission$.pipe(map(() => true))
  ).pipe(distinctUntilChanged(), startWith(false));

  vm$: Observable<DecisionViewModel<InvestmentDepositSummary>> = this.rate$.pipe(
    switchMap((rate) => this.#constructViewModel(rate)),
    tap((vm) => this.#trackRate(vm))
  );

  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private formService: InvestmentDepositFormService,
    private accountsService: AccountsService,
    private currencyService: CurrencyService,
    private depositService: InvestmentDepositService,
    private responseService: InvestmentDepositResponseService,
    public warningService: InvestmentDepositWarningCodeService,
    private tracker: Tracker
  ) {
    this.subscription = this.rejection$.subscribe();
  }

  async ngOnDestroy() {
    this._reject.next(this.depositService.token);
    await lastValueFrom(this.rejection$);
    this.subscription.unsubscribe();
  }

  handleStatus(status: StatusResponse | undefined, data: InvestmentDepositSummary) {
    if (!status) return;
    this.responseService.handleStatusResponse(status, data);
  }

  #constructViewModel(
    rate: RateStreamable
  ): Observable<DecisionViewModel<InvestmentDepositSummary>> {
    if (!isRateSuccess(rate)) {
      this.tracker.reportProgress({ action: "intervention" });
      return of({ intervention: true });
    }

    const form = this.formService.current;

    return combineLatest([
      this.accountsService.depositAccounts$,
      this.currencyService.allCurrencies$,
    ]).pipe(
      map(([accounts, currencies]) => {
        const summary = {
          ...rate,
          ...form,
          currency: getCurrencyByCode(currencies, form.currency),
          account: getAccountByNumber(accounts, form.account),
          profitCurrency: form.currency,
        } as any;
        const decision = {
          summary,
          time: rate.decisionTime,
          ...this.#getActions(rate.token, summary),
        };

        return { decision };
      })
    );
  }

  #trackRate({ decision }: DecisionViewModel<InvestmentDepositSummary>) {
    if (!decision) return;
    const { summary } = decision;
    if (!summary.rates.interestRate) return;

    this.tracker.reportProgress({
      action: "rate",
      data: {
        rate: summary.rates.interestRate.toString(),
        token: summary.token,
        currency: summary.currency.code,
      },
    });
  }

  #getActions(rateToken: string, data: InvestmentDepositSummary) {
    const transactionToken = this.depositService.token;
    return {
      forwardValidator: (time: number) => {
        if (time <= 0) return true;
        this.form.controls.terminationFee.markAsTouched();
        this.form.controls.terminationFee.updateValueAndValidity();
        return handleFormErrors(this.form);
      },
      onTimeout: () => this._reject.next(transactionToken),
      forward: rateToken
        ? () => {
            if (!handleFormErrors(this.form)) return;
            this.tracker.reportProgress({
              action: "confirm",
              data: { rate: data.rates.interestRate.toString(), token: rateToken },
            });
            this._confirm.next([{ rateToken, transactionToken }, data]);
          }
        : () => this.formService.submit(),
    };
  }
}
