<app-alert
  icon="info1"
  content="investment-deposit.InteresetTooltip"
  [contentData]="{
    code: detail.referenceRate,
    currencyPair: detail.currencyPair | pair,
    date: detail.referenceRateDate ? (detail.referenceRateDate | datePko) : '---',
    currency: detail.currency.code,
    interestRate: detail.rates.interestRate | number : '1.2-2',
    standardInterestAmount: standardInterestAmount
      ? (standardInterestAmount | balance : detail.currency.code)
      : '---',
    maximalRate: maximalRate ? (maximalRate | number : '1.2-2') : '---',
    maximalInterestAmount: maximalInterestAmount
      ? (maximalInterestAmount | balance : detail.currency.code)
      : '---',
    minRate: detail.rates.rate[0] | rate,
    maxRate: detail.rates.rate[1] | rate
  }"
  class="col-10 col-md-8">
</app-alert>
