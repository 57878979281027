<button
  type="button"
  class="btn btn-fixed btn-secondary pko-form-footer__button-back"
  backButton
  [back]="back?.onClick"
  *ngIf="!back?.hidden">
  {{ "buttons.back" | translate }}
</button>
<app-countdown
  *ngIf="countdown"
  [time]="countdown"
  (finished)="onTimeout()"
  timeoutText="time.out.{{ type }}"
  [messageText]="message"
  class="pko-form-footer__countdown">
</app-countdown>
<button
  type="submit"
  class="btn btn-fixed btn-primary pko-form-footer__button-forward"
  (click)="onForward()"
  *ngIf="!forward?.hidden">
  {{ forwardText | translate }}
</button>
