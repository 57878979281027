<div class="pko-footer__container" *ngIf="(hide$ | async) === false">
  <div class="pko-footer__row">
    <div class="pko-footer__section">
      <a rel="noopener noreferrer" class="pko-footer__link" href="//www.pkobp.pl/" target="_blank" (click)="track('PKO')">
        {{ "footer.Copyright" | translate }}
      </a>
      <a
        rel="noopener noreferrer"
        class="pko-footer__link"
        href="//www.pkobp.pl/klienci-indywidualni/bankowosc-elektroniczna/kod-bic-swift-iban/"
        target="_blank"
        (click)="track('SwiftCode')">
        {{ "footer.SwiftCode" | translate: { value: "BPKOPLPW" } }}
      </a>
      <a
        rel="noopener noreferrer"
        class="pko-footer__link"
        href="//www.pkobp.pl/polityka-prywatnosci/"
        target="_blank"
        (click)="track('PrivacyPolicy')">
        {{ "footer.PrivacyPolicy" | translate }}
      </a>
    </div>
    <div class="pko-footer__section">
      <a
        rel="noopener noreferrer"
        class="btn btn-link pko-footer__btn"
        [href]="safety"
        target="_blank"
        (click)="track('Security')">
        <svg icon name="security" class="icon--lg"></svg>{{ "footer.Safety" | translate }}
      </a>
      <a
        rel="noopener noreferrer"
        class="btn btn-link pko-footer__btn"
        [href]="help"
        target="_blank"
        (click)="track('Help')">
        <svg icon name="help" class="icon--lg"></svg>{{ "footer.Help" | translate }}
      </a>
      <a
        rel="noopener noreferrer"
        class="btn btn-link pko-footer__btn"
        [href]="contact"
        target="_blank"
        (click)="track('Contact')">
        <svg icon name="mail" class="icon--lg"></svg>{{ "footer.Contact" | translate }}
      </a>
    </div>
  </div>
</div>
