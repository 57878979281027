import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BlotterGraphModule } from "@features/blotter-graph/blotter-graph.module";
import { SharedModule } from "@shared/shared.module";
import { RecentHistoryItemComponent } from "./components/item/recent-history-item.component";
import { UnsettledHistoryItemComponent } from "./components/item/unsettled-history-item.component";
import { RecentHistoryListComponent } from "./components/recent-history-list.component";
import { RecentHistoryViewComponent } from "./components/recent-history-view.component";
import { RecentHistoryComponent } from "./components/recent-history.component";
import { UnsettledHistoryComponent } from "./components/unsettled-history.component";

@NgModule({
  imports: [SharedModule, RouterModule, BlotterGraphModule],
  declarations: [
    RecentHistoryComponent,
    RecentHistoryItemComponent,
    UnsettledHistoryComponent,
    UnsettledHistoryItemComponent,
    RecentHistoryViewComponent,
    RecentHistoryListComponent,
  ],
  exports: [RecentHistoryViewComponent],
})
export class RecentHistoryModule {}
