import {
  catchError,
  EMPTY,
  endWith,
  map,
  MonoTypeOperatorFunction,
  Observable,
  of,
  repeat,
  ReplaySubject,
  share,
  shareReplay,
  skipWhile,
  take,
  tap,
  timer,
} from "rxjs";
import { assertIsDefined, Maybe } from "./misc";

export type RepeatWhileParams = { delay: number; count: number; failResponse: any };

export function repeatWhile<T>(
  predicate: (value: T) => boolean,
  { delay, count, failResponse }: RepeatWhileParams
) {
  return (source$: Observable<T>) =>
    source$.pipe(repeat({ delay, count }), endWith(failResponse), skipWhile(predicate), take(1));
}

export const cache = <T>(time: number): MonoTypeOperatorFunction<T> =>
  share({ connector: () => new ReplaySubject(1), resetOnComplete: () => timer(time) });

export const log =
  <T>(message?: string) =>
  (source$: Observable<T>) =>
    source$.pipe(tap((value) => (message ? console.log(message, value) : console.log(value))));

export const keepStatic =
  <T>(options?: { fallback: T }) =>
  ($: Observable<T>) =>
    $.pipe(
      catchError(() => (options ? of(options.fallback) : EMPTY)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

export const assertNonNullable =
  <T>(valueName = "value") =>
  ($: Observable<Maybe<T>>) =>
    $.pipe(
      map((value) => {
        assertIsDefined(value, valueName);
        return value;
      })
    );
