import { ceil, floor } from "@utils/number";

export function getMaxInvestmentDepositRate(midRate: number, deviation: number) {
  return roundMax(midRate + deviation / 10000, midRate);
}

export function getMinInvestmentDepositRate(midRate: number, deviation: number) {
  return roundMin(midRate - deviation / 10000, midRate);
}

export function roundMax(rate: number, midRate: number) {
  if (midRate < 1) {
    return ceil(rate, 4);
  }

  if (midRate < 2) {
    return rate + 0.0005 - (rate % 0.0005 || 0.0005);
  }

  return ceil(rate, 3);
}

export function roundMin(rate: number, midRate: number) {
  if (midRate < 1) {
    return floor(rate, 4);
  }

  if (midRate < 2) {
    return rate - (rate % 0.0005);
  }

  return floor(rate, 3);
}
