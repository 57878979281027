<form [formGroup]="form" (ngSubmit)="submit()">
  <p>{{ "lei.edit.EnterCode" | translate }}</p>
  <p>{{ "lei.edit.Description" | translate }}</p>
  <p>
    {{ "lei.edit.Info" | translate }}
    <a href="https://lei.kdpw.pl" target="_blank" class="text-primary text-decoration-none">
      https://lei.kdpw.pl
    </a>
  </p>
  <label for="lei" class="form-label d-inline-flex align-items-center">
    {{ "lei.edit.LeiNumber" | translate }}
    <app-tooltip content="lei.edit.Tooltip"></app-tooltip>
  </label>
  <app-form-control validate="lei">
    <input
      type="text"
      formControlName="lei"
      class="pko-lei form-control"
      alphanumeric
      maxlength="20" />
  </app-form-control>
  <p>{{ "lei.edit.Warning" | translate }}</p>
  <p>{{ "lei.edit.WarningInfo" | translate }}</p>
  <p>
    {{ "lei.edit.EmirInfo" | translate }}
    <a
      href="http://www.pkobp.pl/emir-podstawowe-informacje-dla-klientow"
      target="_blank"
      class="text-primary text-decoration-none">
      http://www.pkobp.pl/emir-podstawowe-informacje-dla-klientow
    </a>
  </p>

  <div class="d-flex my-2">
    <button type="submit" class="btn btn-primary btn-fixed ms-auto">
      {{ "buttons.Save" | translate }}
    </button>
  </div>
</form>
