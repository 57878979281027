<div class="pko-page__container pko-page__container--center">
  <div class="pko-page__header">
    <div pko-preloader class="mb-2 position-relative"></div>
    <h3 class="pko-page__title" translate>modals.Intervention.title.{{ type }}</h3>
  </div>
  <div class="pko-page__body">
    <p class="mx-auto" style="max-width: 328px" translate>modals.Intervention.body.{{ type }}</p>
  </div>
  <div class="pko-page__footer" *ngIf="!modal">
    <button type="button" class="btn btn-secondary btn-fixed mx-auto" (click)="backClicked.emit()">
      {{ "buttons.back" | translate }}
    </button>
  </div>
</div>
