<!-- 
  // #########################
// ## MODYFIKACJA
// #########################
 -->

<ng-container *ngIf="regulations.length">
  <h2 class="pko-title pko-title--2" *ngIf="title">{{ title | translate }}</h2>
  <ul class="list-unstyled mt-2">
    <li *ngFor="let r of regulations" class="text-sm pt-2">
      <!-- <a href="{{ r.path }}" target="_blank" class="text-decoration-none">{{ r.name }}</a> -->
      <!-- NOWE -->
      <span class="text-decoration-none" style="color: var(--bs-link-color)">{{ r.name }}</span>
    </li>
  </ul>
</ng-container>
