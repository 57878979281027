import { FormGroup } from "@angular/forms";
import { CollateralForm } from "@features/collateral";

export const tileClasses = {
  infotext: "u-hide",
  side: "",
  currencyPair: "",
  tenor: "",
  tenorSwap: "col-12 col-md-9 col-lg-8",
  accounts: "",
  collateral: ""
};

export const pageClasses = {
  infotext: "pko-infotext u-hide--mobile",
  side: "col-10 col-md-7 col-lg-5",
  currencyPair: "col-10 col-md-7 col-lg-5",
  tenor: "col-10 col-md-5 col-lg-4",
  accounts: "col-10 col-md-5 col-lg-4",
  collateral: "col-10 col-md-5 col-lg-4",
  tenorSwap: "col-10 col-sm-7 col-md-5 col-lg-4",
};

/**
 * Collateral type is an enum on the server, so we can't send `{ type: null }`
 * Best approach instead of this workaround would be to disable form.collateral (on collateral.component)
 * and send `form.value`, instead of `getRawValue`.
 */
export const prepareForm = <T extends { collateral?: CollateralForm }>(formGroup: FormGroup) => {
  const form: T = formGroup.getRawValue();
  if (!form.collateral?.type) {
    delete form.collateral;
  }
  return form;
};
