import { AbstractControl } from "@angular/forms";
import { raise } from "@utils/misc";
import {
  PRODUCT_TYPES,
  TenorDate,
  isNearTenor,
  productsByTenor,
  swapProductsByTenor,
} from "./tenor-model";

export const parseTenorDates = (tenorDates: TenorDate[]): TenorDate[] => {
  return tenorDates.map(({ tenor, date }) => {
    // incoming format is local ISO yyyy-MM-ddThh:mm:ss
    // setting here the datepicker format
    return { tenor, date: date.slice(0, 10) };
  });
};

export const splitDepositTenors = (tenors: string[]) => ({
  nearTenors: tenors.filter(isNearTenor),
  farTenors: tenors.filter((tenor) => !isNearTenor(tenor)),
});

export const getSpotDate = (tenorDates: TenorDate[]) =>
  tenorDates.find(({ tenor }) => tenor === "SPOT")?.date ?? raise("SPOT tenor missing");

/**
 * Finds a product type corresponding to a given tenor and/or date.
 */
export function getProduct({ tenor, date, spotDate, isSwap }: ProductMapperInput) {
  if (!tenor && !date) return;

  const dict = isSwap ? swapProductsByTenor : productsByTenor;
  const [pre, post] = isSwap
    ? [PRODUCT_TYPES.shortSwap, PRODUCT_TYPES.swap]
    : [PRODUCT_TYPES.tomorrow, PRODUCT_TYPES.forward];

  const product = dict[tenor as keyof typeof dict];
  const preSpot = new Date(date) < new Date(spotDate);

  return product ?? (preSpot ? pre : post);
}

export const ensureTenorControlHasValue = (control: AbstractControl) => (tenors: string[]) => {
  if (tenors.length && tenors.includes(control.value)) return;

  if (!tenors.length) {
    control.reset();
    return;
  }

  control.setValue(tenors[0]);
};

interface ProductMapperInput {
  tenor: string | null | undefined;
  date: string;
  spotDate: string;
  isSwap?: boolean;
}
