import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "padStart" })
export class PadStartPipe implements PipeTransform {
  transform(value: Value, options: PadOptions) {
    if (value === null || value === undefined) return null;

    return String(value).padStart(options.length, options.pad);
  }
}

@Pipe({ name: "padEnd" })
export class PadEndPipe implements PipeTransform {
  transform(value: Value, options: PadOptions) {
    if (value === null || value === undefined) return null;

    return String(value).padEnd(options.length, options.pad);
  }
}

type Value = string | number | null | undefined;
type PadOptions = { length: number; pad?: string };
