import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isNonNullable",
})
export class IsNonNullablePipe implements PipeTransform {
  transform<T>(value: T | null | undefined): value is NonNullable<T> {
    return value !== null && value !== undefined;
  }
}
