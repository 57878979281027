import { I18nPluralPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { AppEvent, AppEventsService } from "@core/events/app-events.service";
import { ModalService } from "@features/modal/modal.service";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { combineLatest, shareReplay, tap } from "rxjs";
import { ActivationApiService } from "./activation-api.service";
import { Application, getId } from "./activation.model";

@Injectable({ providedIn: "root" })
export class ActivationService {
  activeApplications = {
    clientOnly$: this.api.getClientActiveApplications,
    all$: this.applicationEvents.observe(
      (currentEvent?: AppEvent) =>
        this.api
          .getActiveApplications()
          .pipe(
            tap(
              (applications) =>
                currentEvent != AppEvent.ApplicationAction && this.showSignWarning(applications)
            )
          ),
      AppEvent.ApplicationAction,
      AppEvent.ApplicationProcessFinished
    ),
  };

  contractsProfileSectionHidden$ = combineLatest(
    [
      this.api.getClientActiveApplications,
      this.api.getContracts(1),
      this.api.getProfileRegulations,
    ],
    (applications, contracts, regulations) =>
      !applications.length && !contracts.items.length && !regulations.length
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor(
    private api: ActivationApiService,
    private modalService: ModalService,
    private pluralPipe: I18nPluralPipe,
    private applicationEvents: AppEventsService
  ) {}

  showSignWarning(applications?: Application[] | null) {
    const readyForSinging = applications?.filter(({ signVisible }) => signVisible);
    if (!readyForSinging?.length) return;

    const base = "activation.ApplicationToSignText";

    const body = this.pluralPipe.transform(readyForSinging?.length, {
      "=1": `${base}.one`,
      "=2": `${base}.two`,
      few: `${base}.other`,
      other: `${base}.other`,
    });

    const bodyParams =
      readyForSinging?.length == 1
        ? { id: `<b>${getId(readyForSinging[0])}</b>` }
        : readyForSinging && readyForSinging?.length > 1
        ? {
            ids: readyForSinging
              ?.slice(0, -1)
              .map((x) => `<b>${getId(x)}</b>`)
              .join(", "),
            lastId: `<b>${getId(readyForSinging?.slice(-1)[0])}</b>`,
          }
        : {};

    this.modalService.dialog({
      type: "info",
      title: "activation.ApplicationToSign",
      body: [
        {
          key: body,
          params: bodyParams,
        },
      ],
      buttons: { primary: defaultPrimary },
    });
  }
}
