import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, of, shareReplay } from "rxjs";
import { defaultPrefix, Prefix } from "./telephone-model";

@Injectable({ providedIn: "root" })
export class TelephoneService {
  prefixes$ = this.http.get<Prefix[]>("settings/prefixes").pipe(
    map((prefixes) =>
      prefixes.map(
        (x) =>
          ({
            ...x,
            label: `${x.countryCode} (${x.code})`,
          } as Prefix)
      )
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError(() => of([defaultPrefix]))
  );

  constructor(private http: HttpClient) {}
}
