<div pko-preloader *ngIf="isBusy$ | async"></div>
<div *ngIf="pendingGroup$ | async as pending; else loading">
  <app-grouped-list
    [groups]="pending.data"
    [byDate]="true"
    dateFormat="EEEE, dd.MM.yyyy HH:mm"
    *ngIf="!pending.error && pending.data?.length"
    emptyMessage="missingData.DpwPending">
    <ng-template #itemTemplate let-dpw>
      <div class="d-flex flex-row align-items-center m-2 p-2">
        <ng-container *ngTemplateOutlet="base; context: { $implicit: dpw }"></ng-container>
        <div
          class="gtext-700 w-50 text-sm d-flex flex-row justify-content-between align-items-center">
          <div>{{ "dpw.status.Pending" | translate }}</div>
          <app-tooltip content="dpw.PendingTooltip" class="me-2"></app-tooltip>
        </div>
      </div>
    </ng-template>
  </app-grouped-list>
</div>

<ng-container *ngIf="form$ | async as form">
  <div [formGroup]="form" *ngIf="activeGroup$ | async as active; else loading">
    <app-grouped-list
      [groups]="active.data"
      [byDate]="true"
      dateFormat="EEEE, dd.MM.yyyy HH:mm"
      *ngIf="!active.error; else error"
      emptyMessage="missingData.DpwActive">
      <ng-template #itemTemplate let-dpw>
        <div class="d-flex flex-row align-items-center m-2 p-2">
          <ng-container *ngTemplateOutlet="base; context: { $implicit: dpw }"></ng-container>
          <div class="w-50 d-flex flex-row justify-content-between align-items-center">
            <div class="text-warning text-sm">{{ "dpw.status.Active" | translate }}</div>
            <app-input-check [formControlName]="dpw.ssrId" ngDefaultControl></app-input-check>
          </div>
        </div>
      </ng-template>
    </app-grouped-list>
    <div class="text-end">
      <button
        *ngIf="active.data?.length"
        type="submit"
        class="btn btn-fixed btn-primary pko-form-footer__button-forward"
        (click)="getRate()"
        [disabled]="form.invalid">
        {{ "dpw.GetRate" | translate }}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div pko-preloader height="50vw"></div>
</ng-template>

<ng-template #error>
  <app-alert content="errors.TechnicalError" icon="failure" context="danger"></app-alert>
</ng-template>

<ng-template #base let-dpw>
  <div class="d-flex flex-row align-items-center w-50">
    <svg icon name="dpw" class="icon icon--3xl me-2"></svg>
    <div class="d-flex flex-column">
      <span class="fw-bold text-sm" [innerHTML]="'dpw.type.' + dpw.type | translate"></span>
      <span class="text-sm">{{ dpw.amount | balance: dpw.currency }}</span>
      <span class="gtext-600 text-sm">{{ dpw.ssrId }}</span>
    </div>
  </div>
</ng-template>
