import { FlowDeclaration } from "@features/flow-declaration/flow-declaration.model";

export interface Application {
  number: string;
  nemoId: string;
  clientAName: string;
  clientAPid: string;
  clientBName: string;
  clientBPid: string;
  type: ApplicationType;
  status: string;
  cancelVisible: boolean;
  signVisible: boolean;
  methodOfSigning: string;
  isInstitutional: boolean;
}

export interface Contract {
  applicationId: string;
  nemoId: string;
  type: ApplicationType;
  signDate?: Date;
}

export interface CompetedContractsModel {
  items: Contract[];
  pageCount: number;
}

export interface Regulation {
  name: string;
  path: string;
}

export const getId = ({ nemoId, type, number }: Application) =>
  nemoId || (type === "JDGSPOT" ? "JDGSPOT" + number : number);

export const getType = ({ isInstitutional, type }: Application) =>
  `${type}${isInstitutional ? "I" : ""}`;

export type ApplicationType = "URN" | "URZ" | "SPOT1" | "JDGSPOT";

export type ApplicationStatus =
  | "Cancelled"
  | "Rejected"
  | "Branch"
  | "New"
  | "ReadyForSigning"
  | "Verification"
  | "Realization"
  | "Accepted"
  | "ContractSigned"
  | "Completed"
  | "ErrorGate"
  | "NotRecognized"
  | "SchemaChange"
  | "NewSuspended"
  | "RealizationSuspended";

export interface Attorney {
  id: string;
  pid: string;
  name: string;
  internetAccess: boolean;
  isCurrentUser: boolean;
  isSelectedCustomer: boolean;
}

export type MethodOfContractSigning = "Branch" | "Online";

export interface InfoPhones {
  local: string;
  global: string;
}

export interface ApplicationDetails {
  applicationId: number;
  nemoId: string;
  attorneyPid: string;
  clientPids: string[];
  type: ApplicationType;
  statusGate: ApplicationStatus;
  legalRepresentation: Representative[];
  methodOfSigning: MethodOfContractSigning;
  mifidRequired: boolean;
  documentId: string;
  isInstitutional: boolean;
}

export interface Representative extends Attorney {
  signatureStatus: SignatureStatus;
}

export type SignatureStatus = "Signed" | "NotSigned";

export interface ApplicationDetailsResult {
  applicationDetails: ApplicationDetails;
  executiveClientPid: string;
  isRepresentative: boolean;
  isSigned: boolean;
  cancelVisible: boolean;
  regulations: Regulation[];
  infoPhones: InfoPhones;
  hasUpdatedDeclaration: boolean;
  flowDeclaration: FlowDeclaration;
  treasurySurveyId?: string;
}
