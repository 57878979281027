import { Failure } from "@core/models/transaction";
import { RateResponse, RateSuccess, RESPONSE_TYPE } from "@features/transaction/models";

//Doesn't have rate token instead has quoteId
export interface MultiFxRateSuccess extends RateSuccess {
  dealCurrencyTotal: number;
  counterCurrencyTotal: number;
  quoteId: number;
  series: MultiFxTransactionRateSuccess[];

  salesProfit?: number;
  salesMargin?: number;
}

export interface MultiFxTransactionRateSuccess {
  clientSideId: string;
  rate: number;
  calculatedAmount?: number;
  depositAmount?: number;
  depositCurrency: string;
}

export interface MultiFxTransactionRateFailure {
  clientSideId: string;
  error: Failure;
}

export interface MultiFxRateError {
  error: Failure; //first error code
  series: MultiFxTransactionRateFailure[];
}

export type MultiFxRateResponse = RateResponse<MultiFxRateSuccess, MultiFxRateError>;

export const rejectedRate: MultiFxRateSuccess = {
  responseType: RESPONSE_TYPE.success,
  decisionTime: -1,
  token: "",
  counterCurrencyTotal: 0,
  dealCurrencyTotal: 0,
  quoteId: 0,
  series: [],
};
