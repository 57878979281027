import { Component } from "@angular/core";
import { DataResult } from "@core/models/data-result";
import { groupAndSortDataResult } from "@features/history-common/history-common.utils";
import { GroupedArray } from "@utils/collection";
import { datepickerFormat } from "@utils/time";
import { map, Observable } from "rxjs";
import { RecentDeal } from "../recent-history.model";
import { RecentHistoryService } from "../recent-history.service";

@Component({
  selector: "app-recent-history",
  templateUrl: "./recent-history.component.html",
})
export class RecentHistoryComponent {
  deals$: Observable<DataResult<GroupedArray<RecentDeal>>> = this._service.getRecentDeals.pipe(
    map(groupAndSortDataResult((current) => datepickerFormat(current.transactionDate)))
  );

  constructor(private _service: RecentHistoryService) {}
}
