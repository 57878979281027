// #########################
// ## MODYFIKACJA
// #########################

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider, Type } from "@angular/core";
import { PrezentacjaInterceptor } from "../PREZENTACJA/prezentacja.interceptor"; // <---- NOWE
import { ConfigStore } from "./config";
import { HeaderInterceptor } from "./headers";
import { AppHeaderInterceptor } from "./headers/app-headers";
import { LocalizationService } from "./i18n/localization.service";
import { BusyInterceptor } from "./loading/busy.interceptor";
import { PreferencesService } from "./preferences/preferences.service";
import { UserService } from "./session/user.service";

// These services block the app from initializing
// This happens when combining APP_INITIALIZER with a promise
export function InitialBlockingFactory(
  configStore: ConfigStore,
  preferencesService: PreferencesService,
  localizationService: LocalizationService,
  userService: UserService
) {
  return async (): Promise<void> => {
    await preferencesService.setup();
    await Promise.all([configStore.load(), localizationService.setup(), userService.load()]);

    console.log("%cUwaga", "background: #ff0; color: #f00; font-size: 20px");
    console.log(
      "%cOtworzyłeś konsolę deweloperską. Nie wprowadzaj żadnych zmian oraz nie wklejaj kodu lub tekstu, których nie jesteś pewien lub jeśli prosi Cię o to ktoś obcy. To może zagrażać bezpieczeństwu danych na tej stronie.",
      "font-size: 17px"
    );
  };
}

export const assertLoadedOnce = (module: unknown, name: string): void => {
  if (module) {
    throw new Error(`${name} has already been loaded.`);
  }
};

export const httpInterceptorProviders = [
  addInterceptor(AppHeaderInterceptor),
  addInterceptor(HeaderInterceptor),
  addInterceptor(BusyInterceptor),
  addInterceptor(PrezentacjaInterceptor), // <<------- NOWE
];

function addInterceptor<T extends Type<unknown>>(interceptor: T): Provider {
  return { provide: HTTP_INTERCEPTORS, useClass: interceptor, multi: true };
}
