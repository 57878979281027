<ng-container *ngTemplateOutlet="label ? containedInput : input"></ng-container>

<ng-template #input>
  <input
    class="form-control {{ size && 'form-control-' + size }} {{ className }}"
    [id]="id"
    [formControl]="control"
    [attr.placeholder]="placeholder ? (placeholder | translate) : null"
    autocomplete="off"
    type="text"
  />
</ng-template>

<ng-template #containedInput>
  <app-form-control [label]="label" [validate]="controlToValidate">
    <ng-container *ngTemplateOutlet="input"></ng-container>
  </app-form-control>
</ng-template>
