// #########################
// ## MODYFIKACJA
// #########################s

import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, HostBinding, HostListener, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { LPREMessageService } from "@core/LPREMessage.service";
import { WelcomeWindowComponent } from "@core/components/welcome-window/welcome-window.component";
import { WelcomeWindowService } from "@core/components/welcome-window/welcome-window.service";
import { BusyPayload, BusyService } from "@core/loading/busy.service";
import { PreferencesService } from "@core/preferences/preferences.service";
import { SessionService } from "@core/session";
import { UserService } from "@core/session/user.service";
import { ModalService } from "@features/modal/modal.service";
import { ProlongSessionComponent } from "@features/prolong-session/prolong-session.component";
import { defaultPrimary } from "@shared/components/modal/modal-model";
import { debounceTime, map, switchMap, tap } from "rxjs";
import { isDemoReady } from "./PREZENTACJA/prezentacja.interceptor";
import { FlowDeclarationModalComponent } from "./features/flow-declaration/components/flow-declaration-modal/flow-declaration-modal.component";
import { LeiMessageComponent } from "./features/lei/components/lei-message/lei-message.component";

@Component({
  selector: "[app-root]",
  templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit {
  title = "app";
  isDemoReady$ = isDemoReady; // <<------- NOWE

  @HostBinding("class.bg-body-light") get bodyBg() {
    return this.isDashboard;
  }

  isBusy$ = this.busyService.currentState$.pipe(map(toGlobalBusy), debounceTime(0));
  #sessionProlongOffered = false;

  constructor(
    public userService: UserService,
    private router: Router,
    private busyService: BusyService,
    private modalService: ModalService,
    private sessionService: SessionService,
    private welcomeService: WelcomeWindowService,
    private preferencesService: PreferencesService,
    private lpreMessageService: LPREMessageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.sessionService.sessionExpiration$
      .pipe(
        tap((newExpiration) => {
          if (newExpiration <= 30) {
            if (!this.#sessionProlongOffered) {
              this.#sessionProlongOffered = true;
              this.modalService.modal({
                component: ProlongSessionComponent,
                scrollable: false,
              });
            }
          } else {
            this.#sessionProlongOffered = false;
          }
        })
      )
      .subscribe();
  }

  get isDashboard() {
    return this.router.url === "/";
  }

  get isContracts() {
    return this.router.routerState.snapshot.url.startsWith("/contracts");
  }

  get isFlows() {
    return this.router.routerState.snapshot.url.startsWith("/profile/flows");
  }

  ngAfterViewInit() {
    if (showApplicationMessage()) {
      this.showWelcomeWindow();
      this.openLPREInfo();
    }
    this.openPilotInfo();
  }

  @HostListener("window:resize", ["$event"])
  handleKeyboardOpen() {
    if (this.document.activeElement?.tagName?.toUpperCase() !== "INPUT") {
      return;
    }

    setTimeout(() => {
      (this.document.activeElement as HTMLElement).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  }

  openPilotInfo() {
    this.userService.userData.showPilotInfo &&
      setTimeout(
        () =>
          this.modalService.dialog(
            {
              body: "PilotInfo",
              type: "info",
              buttons: { primary: defaultPrimary },
            },
            "modal",
            true
          ),
        1000
      );
  }

  openLPREInfo() {
    if (
      this.userService.userData.showLPREMessage &&
      this.preferencesService.deposit.showLPREMessage &&
      (this.userService.hasRole("PremiumDeposit") || this.userService.hasRole("MmDeposit"))
    ) {
      this.lpreMessageService.showLPREMessage();
    }
  }

  showWelcomeWindow() {
    if (!this.userService.userData.showWelcomeMessage) {
      this.showMandatoryLeiMessage();
      return;
    }
    const modal = this.modalService.modal({
      component: WelcomeWindowComponent,
      skipDismissOnRoute: true,
    });

    modal.closed
      .pipe(
        switchMap(() => this.welcomeService.readWelcome()),
        tap(() => this.showMandatoryLeiMessage())
      )
      .subscribe();
  }

  showMandatoryLeiMessage() {
    if (!this.userService.userData.leiMessage.show) {
      this.showMandatoryFlowDeclaration();
      return;
    }
    const modal = this.modalService.modal({
      component: LeiMessageComponent,
      skipDismissOnRoute: true,
    });

    modal.closed.pipe(tap(() => this.showMandatoryFlowDeclaration())).subscribe();
  }

  showMandatoryFlowDeclaration() {
    return; // <<------- NOWE

    if (!this.userService.userData.flowsDeclaration || this.isFlows) return;
    this.modalService.modal({
      component: FlowDeclarationModalComponent,
      skipDismissOnRoute: true,
    });
  }
}

const toGlobalBusy = ({ working }: BusyPayload) =>
  working.some((url) =>
    ["/user", "/customers", "/preferences", "reload", "/messages", "/messages/read"].includes(url)
  );

const showApplicationMessage = () =>
  window.location.pathname !== "/customer" && !window.location.pathname.startsWith("/contracts");
