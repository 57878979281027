import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { viewSize } from "@utils/window";
import { Observable } from "rxjs";

@Injectable()
export class AppHeaderInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = req.headers.set("view-var", viewSize());
    const requestWithHeaders = req.clone({ headers });
    return next.handle(requestWithHeaders);
  }
}
