// #########################
// ## MODYFIKACJA
// #########################

import { Component, Input, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Language } from "@core/preferences/preferences.model";
import { PreferencesService } from "@core/preferences/preferences.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-language",
  templateUrl: "./language.component.html",
})
export class LanguageComponent implements OnDestroy {
  @Input() bindLabel = "name";
  @Input() translateLabel = true;
  form: FormGroup;
  private destroy$ = new Subject<void>();

  languages: Language[] = [
    { code: "pl", name: "PL" },
    { code: "en", name: "EN" },
    // { code: "ru", name: "RU" },         // <-- NOWE
    // { code: "uk", name: "UA" },
    // { code: "de", name: "DE" },
    // { code: "cs", name: "CZ" },
    // { code: "ro", name: "RO" },
    // { code: "sk", name: "SK" },
  ];

  constructor(
    fb: FormBuilder,
    translate: TranslateService,
    private _preferences: PreferencesService,
    private _tracker: Tracker
  ) {
    this.form = fb.group({
      language: translate.currentLang,
    });

    this.form.controls.language.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((language) => this.change(language));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  change(language: string): void {
    this._preferences.setLanguage({ code: language } as Language);

    this._tracker.report({
      category: "preferences",
      action: "LanguageChanged",
      data: { Language: language },
    });
  }
}
