import { NgModule } from "@angular/core";
import { CollateralModule } from "@features/collateral/collateral.module";
import { ModalModule } from "@features/modal/modal.module";
import { TransactionModule } from "@features/transaction/transaction.module";
import {
  AmountCurrencyPairFormComponent,
  CloseExchangeFormComponent,
  DecisionComponent,
  ExchangeComponent,
  FormExchangeTabsComponent,
  ForwardExchangeFormComponent,
  RollExchangeFormComponent,
  SimpleExchangeFormComponent,
  SpotExchangeFormComponent,
  SummaryDetailComponent,
  SummaryOriginalComponent,
  SummaryQuoteComponent,
  SwapDecisionComponent,
  SwapExchangeFormComponent,
  TenorDateProductFormComponent,
  TenorDateProductRollFormComponent,
  TenorDateProductSwapFormComponent,
} from "./components";
import { ExchangeRoutingModule } from "./exchange-routing.module";

@NgModule({
  imports: [TransactionModule, ModalModule, CollateralModule, ExchangeRoutingModule],
  declarations: [
    AmountCurrencyPairFormComponent,
    CloseExchangeFormComponent,
    DecisionComponent,
    ExchangeComponent,
    ForwardExchangeFormComponent,
    RollExchangeFormComponent,
    SimpleExchangeFormComponent,
    SpotExchangeFormComponent,
    SummaryOriginalComponent,
    SummaryQuoteComponent,
    SummaryDetailComponent,
    SwapDecisionComponent,
    SwapExchangeFormComponent,
    TenorDateProductFormComponent,
    TenorDateProductRollFormComponent,
    TenorDateProductSwapFormComponent,
    FormExchangeTabsComponent,
  ],
  exports: [
    SimpleExchangeFormComponent,
    SummaryDetailComponent,
    SummaryQuoteComponent,
    FormExchangeTabsComponent,
  ],
})
export class ExchangeModule {}
