import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Type,
  ViewChild,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import {
  NotificationActionEvent,
  UserNotification,
  UserNotificationComponent,
} from "../../notifications.model";
import { DetailsDirective } from "../details.directive";
import { NotificationsEndingDepositComponent } from "../details/notifications-ending-deposit/notifications-ending-deposit.component";
import { NotificationsExecutedAlertComponent } from "../details/notifications-executed-alert/notifications-executed-alert.component";
import { NotificationsExecutedInvDepositComponent } from "../details/notifications-executed-inv-deposit/notifications-executed-inv-deposit.component";
import { NotificationsExecutedOrderComponent } from "../details/notifications-executed-order/notifications-executed-order.component";
import { NotificationsExecutedPremiumDepositComponent } from "../details/notifications-executed-premium-deposit/notifications-executed-premium-deposit.component";
import { NotificationsExpiredAlertComponent } from "../details/notifications-expired-alert/notifications-expired-alert.component";
import { NotificationsExpiredOrderComponent } from "../details/notifications-expired-order/notifications-expired-order.component";
import { NotificationsMessageComponent } from "../details/notifications-message/notifications-message.component";
import { NotificationsNewDpwComponent } from "../details/notifications-new-dpw/notifications-new-dpw.component";
import { NotificationsUnsettledDealComponent } from "../details/notifications-unsettled-deal/notifications-unsettled-deal.component";

@Component({
  selector: "app-notifications-main",
  templateUrl: "./notifications-main.component.html",
})
export class NotificationsMainComponent implements AfterViewInit, OnDestroy {
  @Input() notification: UserNotification | null = null;
  @Input() simple = false;
  @Output() public actionExecuted = new EventEmitter<NotificationActionEvent>();
  @ViewChild(DetailsDirective, { static: true }) details!: DetailsDirective;
  private destroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    setTimeout(() => {
      const types: Record<string, Type<UserNotificationComponent>> = {
        UnsettledDeal: NotificationsUnsettledDealComponent,
        EndingDeposit: NotificationsEndingDepositComponent,
        NewDpw: NotificationsNewDpwComponent,
        ExpiredOrder: NotificationsExpiredOrderComponent,
        ExecutedOrder: NotificationsExecutedOrderComponent,
        ExpiredAlert: NotificationsExpiredAlertComponent,
        ExecutedAlert: NotificationsExecutedAlertComponent,
        Message: NotificationsMessageComponent,
        ExecutedInvDepo: NotificationsExecutedInvDepositComponent,
        ExecutedPremiumDeposit: NotificationsExecutedPremiumDepositComponent,
      };

      const componentRef = this.details.viewContainerRef.createComponent(
        types[this.notification?.name || ""]
      );
      componentRef.instance.notification = this.notification;
      componentRef.instance.showIcon = !this.simple;
      componentRef.instance.actionExecuted
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => this.actionExecuted.emit(event));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
