import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BodyLine, Dialog } from "@shared/components/modal/modal-model";
import { TableColumn } from "@shared/components/table/table-model";

@Component({
  selector: "app-similar-deals-warning",
  templateUrl: "similar-deals-warning.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimilarDealsWarningComponent implements OnInit {
  data!: Dialog;
  columns!: TableColumn[];
  form: FormGroup;

  constructor() {
    this.form = new FormGroup({ approved: new FormControl(null, Validators.requiredTrue) });
  }

  ngOnInit(): void {
    this.columns = [
      { name: "dialog.warning.body.HasSimilarDeal.Number", align: "center" },
      {
        name: this.data.transactionType === "deposit" ? "history.Currency" : "CurrencyPair",
        align: "center",
      },
      { name: "Amount", align: "center" },
      { name: "TransactionDate", align: "center" },
      {
        name: this.data.transactionType === "exchange" ? "exchange.Type" : "deposit.Type",
        align: "center",
      },
      { name: "Status", align: "center" },
    ];

    this.data.buttons!.primary = {
      ...this.data.buttons!.primary!,
      disabled: () => !this.form.valid,
    };
  }

  get items() {
    const maxHistoryElements = 5;
    const body = this.data?.body as BodyLine;
    const similarDeals = body.params?.["similarDeals"] as string;
    return JSON.parse(similarDeals).slice(0, maxHistoryElements);
  }
}
