import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { isSpotTenor } from "@features/tenor";
import { IFormService } from "@features/transaction/models";
import { PvpService } from "@features/transaction/services/pvp.service";
import { pick } from "@utils/object";
import { BehaviorSubject } from "rxjs";
import {
  ExchangeForm,
  FormType,
  NormalExchangeForm,
  SubmissionOptions,
  SwapExchangeForm,
} from "../models";
import { ExchangeService } from "./exchange.service";

@Injectable({ providedIn: "root" })
export class ExchangeFormService implements IFormService<ExchangeForm> {
  #formType = new BehaviorSubject<FormType>("spot");
  formType$ = this.#formType.asObservable();

  #normalForm = new BehaviorSubject(this.#defaultForm);
  #swapForm = new BehaviorSubject(this.#defaultSwapForm);

  submission$ = this.exchangeService.submission$;

  constructor(
    private preferences: PreferencesService,
    private exchangeService: ExchangeService,
    private pvpService: PvpService
  ) {}

  get current() {
    return this.#isSwap ? this.#swapForm.getValue() : this.#normalForm.getValue();
  }

  get #isSwap() {
    return this.#formType.getValue() === "swap";
  }

  get #defaultType() {
    const tenor = this.#defaultForm.settlement?.tenor ?? "TOD";

    return isSpotTenor(tenor) ? "spot" : "forward";
  }

  get #defaultForm() {
    const { tenor, ...preferences } = this.preferences.exchange;

    return {
      ...preferences,
      settlement: { tenor, date: null },
      collateral: {},
    } as NormalExchangeForm;
  }

  get #defaultSwapForm() {
    const { nearTenor, farTenor, ...preferences } = this.preferences.swap;

    return {
      ...preferences,
      nearSettlement: { tenor: nearTenor, date: null },
      farSettlement: { tenor: farTenor, date: null },
      collateral: {},
    } as SwapExchangeForm;
  }

  change(type: FormType, origin: "tab" | "product" = "product") {
    this.#formType.next(type);

    if (origin !== "tab" || type === "swap") return;

    const form = this.#normalForm.getValue();
    const { tenor } = this.preferences.exchange;

    // use preferences, not default, when changing spot/forward tabs
    this.#normalForm.next({ ...form, settlement: { tenor, date: null } });
  }

  reset(retain?: "all" | "some") {
    if (retain === "all") return;

    if (retain === "some") {
      this.#normalForm.next(retainSome(this.#defaultForm, this.#normalForm.getValue()));
      this.#swapForm.next(retainSome(this.#defaultSwapForm, this.#swapForm.getValue()));
      return;
    }

    this.#normalForm.next(this.#defaultForm);
    this.#swapForm.next(this.#defaultSwapForm);
    this.#formType.next(this.#defaultType);
  }

  save(form: SwapExchangeForm | NormalExchangeForm) {
    this.#isSwap
      ? this.#swapForm.next(form as SwapExchangeForm)
      : this.#normalForm.next(form as NormalExchangeForm);
  }

  submit(options?: SubmissionOptions) {
    const payload = options ? { ...this.current, ...options } : this.current;

    this.exchangeService.init(payload, this.pvpService.rule);
  }
}

const retainSome = <T extends NormalExchangeForm | SwapExchangeForm>(
  defaultForm: T,
  filledForm: T
): T => ({
  ...defaultForm,
  ...pick(filledForm, "account", "counterAccount", "currency", "counterCurrency", "side"),
});
