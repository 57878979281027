import { Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { BreakpointObserverService } from "@core/breakpoints";
import { Side } from "@core/models/transaction";
import { NavigationService } from "@core/navigation";
import { PreferencesService } from "@core/preferences/preferences.service";
import { UserService } from "@core/session";
import { origins } from "@core/user-tracking/tracking.model";
import { DashboardService } from "@features/dashboard/dashboard.service";
import { NormalExchangeForm } from "@features/exchange/models";
import { IndicativeGraph } from "@features/graph/graph.model";
import { ModalService } from "@features/modal/modal.service";
import { NON_FORWARD_TENORS, PRODUCT_TYPES } from "@features/tenor";
import { baseCurrency, quoteCurrency } from "@shared/utils/currency";
import { Observable, Subject, map, of, takeUntil } from "rxjs";
import { IndicativePair, IndicativeRate } from "../../indicatives.model";

@Component({
  selector: "app-indicative-rate",
  templateUrl: "./indicative-rate.component.html",
})
export class IndicativeRateComponent implements OnInit, OnDestroy {
  @HostBinding("class") class = "pko-indicative__content";

  @Input() isSimple = true;
  @Input() id!: number;
  @Input()
  public get pair(): IndicativePair {
    return this.#pair;
  }
  public set pair(value: IndicativePair) {
    this.accountsMissing =
      (value.baseAccountMissing && value.quoteAccountMissing) ||
      ((value.baseAccountMissing || value.quoteAccountMissing) &&
        !this.userService.hasRole("FxForward"));
    this.#pair = value;
  }
  @Input() rates!: Observable<IndicativeRate[]>;

  @Input() graphs$?: Observable<IndicativeGraph[]>;
  #pair!: IndicativePair;
  #destroy = new Subject<void>();

  accountsMissing = false;
  buy$!: Observable<number | undefined>;
  sell$!: Observable<number | undefined>;
  form!: FormGroup;
  tenors = [...NON_FORWARD_TENORS];
  showSimple$ = this.dashboardService.simple$;

  graph$ = of({} as IndicativeGraph);
  currentBreakpoint$ = this.breakpointObserver.currentBreakpoint$;

  constructor(
    private navigation: NavigationService,
    private userService: UserService,
    private modalService: ModalService,
    private dashboardService: DashboardService,
    private preferences: PreferencesService,
    private breakpointObserver: BreakpointObserverService,
    fb: FormBuilder
  ) {
    const tenor = new FormControl({ tenor: this.preferences.indicatives.tenor });
    this.form = fb.group({ tenor });
    tenor.valueChanges.pipe(takeUntil(this.#destroy)).subscribe((tenor) => {
      this.preferences.setIndicatives({
        tenor,
      });
    });
    this.preferences.indicatives$
      .pipe(takeUntil(this.#destroy))
      .subscribe(({ tenor }) => this.form.controls.tenor.setValue(tenor, { emitEvent: false }));
  }

  ngOnInit(): void {
    const rate$ = this.rates.pipe(
      map(
        (rates) =>
          rates.find((x) => x.pair == this.pair.code) ??
          ({
            buy: 0,
            sell: 0,
            pair: this.pair.code,
          } as IndicativeRate)
      )
    );
    this.buy$ = rate$.pipe(map((x) => x.buy));
    this.sell$ = rate$.pipe(map((x) => x.sell));

    this.graph$ =
      this.graphs$?.pipe(
        map(
          (graphs) => graphs?.find(({ pair }) => pair === this.pair.code) || ({} as IndicativeGraph)
        )
      ) ?? of({} as IndicativeGraph);
  }

  ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
  }

  async openDeal(side: Side) {
    if (this.userService.userData.passive) {
      this.modalService.dialog({
        type: "info",
        title: "errors.PassiveAccess",
        buttons: {
          primary: { text: "OK" },
        },
      });
      return;
    }

    if (!this.userService.hasRole("FxSpot")) {
      this.modalService.dialog({
        title: "exchange.Failure",
        type: "failure",
        body: "errors.NoAgreementForTransaction",
        buttons: {
          primary: { text: "OK" },
        },
      });
      return;
    }

    if (!this.pair.baseAccountMissing && !this.pair.quoteAccountMissing) {
      this.#goToDeal(side);
      return;
    }

    const modal = this.modalService.dialog({
      type: "info",
      title: "missingAccount.Title",
      body: this.accountsMissing ? "missingAccount.Text" : "missingAccount.NdfText",
      hideCloseButton: !this.accountsMissing,
      buttons: this.accountsMissing
        ? {
            primary: { text: "missingAccount.Button" },
          }
        : {
            primary: { text: "missingAccount.NdfAccept", resultOnClose: "accept" },
            secondary: { text: "missingAccount.NdfResign" },
          },
    });

    if (await modal.result) {
      this.#goToDeal(side);
    }
  }

  get tenor() {
    return this.form.controls.tenor.value;
  }

  #goToDeal(side: Side) {
    let currencies = [baseCurrency(this.pair.code), quoteCurrency(this.pair.code)];

    const ndf = this.pair.baseAccountMissing || this.pair.quoteAccountMissing;
    const tenor = this.preferences.dashboard.simple ? "TOD" : this.form.controls.tenor.value;

    if (ndf && !this.pair.baseAccountMissing && this.pair.quoteAccountMissing) {
      currencies = [currencies[1], currencies[0]];
    }

    this.navigation.navigate(["transactions/exchange"], {
      state: {
        form: {
          currency: currencies[0],
          counterCurrency: currencies[1],
          currencyPair: this.pair.code,
          product: ndf ? PRODUCT_TYPES.forward : PRODUCT_TYPES.overnight,
          side,
          settlement: {
            date: null,
            tenor,
          },
          netSettlement: ndf,
          amount: "",
          collateral: {},
        } as NormalExchangeForm,
        origin: origins.INDICATIVES,
        type: ndf ? "forward" : "spot",
      },
    });
  }
}
