import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { LeiEditComponent } from "./components/lei-edit/lei-edit.component";
import { LeiMessageComponent } from "./components/lei-message/lei-message.component";
import { LeiViewComponent } from "./components/lei-view/lei-view.component";

@NgModule({
  imports: [TranslateModule, SharedModule],
  declarations: [LeiMessageComponent, LeiEditComponent, LeiViewComponent],
  exports: [LeiMessageComponent, LeiEditComponent, LeiViewComponent],
})
export class LeiModule {}
