<h2 class="pko-title pko-title--2">
  {{ "activation.ClientData" | translate }}
</h2>

<div class="pko-grid__row">
  <div class="col-10 col-md-5">
    <app-definition
      label="activation.client.baseData.Name"
      value="{{ userData.customerName }}"></app-definition>
    <app-definition
      *ngIf="userData.customerShortName"
      label="activation.client.baseData.ShortName"
      value="{{ userData.customerShortName }}"></app-definition>
  </div>
  <div class="col-10 col-md-5">
    <app-definition
      label="activation.client.baseData.Pid"
      value="{{ userData.customerPid }}"></app-definition>
  </div>
</div>
