<app-modal-container [config]="modalConfig">
  <app-flow-declaration-summary-refusal
    [declaration]="declaration"
    *ngIf="declaration.refuseToAnswer"
    [showActions]="false"></app-flow-declaration-summary-refusal>
  <app-flow-declaration-summary-active
    [declaration]="declaration"
    *ngIf="!declaration.refuseToAnswer"
    [showActions]="false"></app-flow-declaration-summary-active>
</app-modal-container>
