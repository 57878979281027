import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { AnalysisComponent } from "./components/analysis.component";
import { MarketNewsDetailsComponent } from "./components/market-news-details.component";
import { MarketNewsTileComponent } from "./components/market-news-tile.component";
import { MarketNewsComponent } from "./components/market-news.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    MarketNewsComponent,
    MarketNewsTileComponent,
    MarketNewsDetailsComponent,
    AnalysisComponent,
  ],
  exports: [MarketNewsTileComponent],
})
export class MarketNewsModule {}
