<app-modal-container [config]="modalConfig">
  <ng-container *ngIf="data.success">
    <h3 class="pko-title pko-title--3">{{ "mifid.kid.AgreementTitle" | translate }}</h3>
    <p class="mb-5">{{ "KidAcceptShortDescription" | translate }}</p>
    <hr />
    <h3 class="pko-title pko-title--3">{{ "mifid.result.Title" | translate }}</h3>
    <dl class="pko-definition">
      <dt class="pko-definition__term pko-definition__term--tooltip">
        {{ "mifid.result.ClientType" | translate }}
        <app-tooltip>
          {{ "mifid.clientTypeTooltip.ClientCategories" | translate }}
          <ul class="mb-0">
            <li>{{ "mifid.clientTypeTooltip.RetailClient" | translate }}</li>
            <li>{{ "mifid.clientTypeTooltip.ProfessionalClient" | translate }}</li>
            <li>{{ "mifid.clientTypeTooltip.EligibleCounterparty" | translate }}</li>
          </ul>
          {{ "mifid.clientTypeTooltip.MoreClientInformation" | translate }}
        </app-tooltip>
      </dt>
      <dd class="pko-definition__description">
        {{ "mifid.result.RetailClient" | translate }}
      </dd>
    </dl>
    <hr />
    <h3 class="d-inline-flex w-100 justify-content-center">
      {{ "mifid.result.SurveyResult" | translate }}
      <app-tooltip
        [content]="data.riskProfile.description"
        [translateContent]="false"></app-tooltip>
    </h3>
    <h5 class="text-center mb-4">
      {{ ("mifid.result.RiskProfileInformation" | translate) + data.riskProfile.label }}
    </h5>
    <p>{{ "mifid.result.AdequacyInformation" | translate }}</p>
    <ng-container *ngFor="let group of data.financialInstrumentGroups; let i = index">
      <app-mifid-survey-result-financial-group
        [group]="group"
        [index]="i"></app-mifid-survey-result-financial-group>
    </ng-container>
    <ng-container *ngFor="let service of data.services; let i = index">
      <app-mifid-survey-result-service
        [service]="service"
        [index]="i"></app-mifid-survey-result-service>
    </ng-container>
  </ng-container>
</app-modal-container>
