<app-form-control
  *ngIf="current$ | async as current"
  [formGroup]="group"
  [label]="label"
  [inputGroup]="true">
  <div class="pko-grid__row pko-grid__row--12">
    <app-datepicker
      formControlName="from"
      [label]="labelFrom"
      [placeholder]="placeholder?.from || 'placeholders.From' | translate"
      [allowPastDates]="true"
      [validate]="['min', 'max']"
      [min]="minDate"
      [max]="current.to | maxDate : maxDate"
      class="col-6"
      ngDefaultControl>
    </app-datepicker>
    <app-datepicker
      formControlName="to"
      [label]="labelTo"
      [placeholder]="placeholder?.to || 'placeholders.To' | translate"
      [allowPastDates]="true"
      [validate]="['min', 'max']"
      [min]="current.from"
      [max]="maxDate"
      class="col-6"
      ngDefaultControl>
    </app-datepicker>
  </div>
</app-form-control>
