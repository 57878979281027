// #########################
// ## MODYFIKACJA
// #########################

import { Component, Input, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BreakpointObserverService } from "@core/breakpoints";
import { UserService } from "@core/session";
import { ModalService } from "@features/modal/modal.service";
import { MobileMenuService } from "@routing/menu-service";
import {
  Subscription,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  startWith,
  tap,
} from "rxjs";
import { MenuItem } from "./nav-menu.model";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
})
export class NavMenuComponent implements OnDestroy {
  #subscription!: Subscription;

  readonly app = this.userService.userData.channel === "IPKO" ? "iPKO" : "IPKO BIZNES";
  isModalOpened = false;
  isLaptop = false;
  working = false;

  readonly menu$ = this.mobileMenuService.menu.pipe(tap((menu) => this.initializeMenu(menu)));
  readonly mobileMenu$ = this.mobileMenuService.mobileMenu;
  readonly isMenuModalOpen$ = this.modalService.modalRef$.pipe(map(({ menu }) => Boolean(menu)));

  readonly isContracts$ = this.#getUrlObservable("/contracts");
  readonly isCustomer$ = this.#getUrlObservable("/customer");

  @Input() hasCustomer = false;

  constructor(
    public router: Router,
    private modalService: ModalService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserverService,
    private mobileMenuService: MobileMenuService
  ) {}

  initializeMenu(menu: MenuItem[]) {
    this.#subscription && this.#subscription.unsubscribe();
    this.#subscription = this.breakpointObserver
      .isBreakpoint(["xs", "sm", "md"])
      .pipe(tap((isLaptop) => (this.isLaptop = isLaptop)))
      .pipe(skip(1), distinctUntilChanged())
      .subscribe(() => {
        this.collapseAll(menu);
        this.modalService.getModalRef("menu")?.close();
      });
  }

  collapseToggle(menu: MenuItem[], item: MenuItem) {
    const expandedItem = menu.find((i) => i.expanded);
    // Collapse expanded item
    if (expandedItem) {
      expandedItem.expanded = false;
    }
    // Expand item if every item is collapsed and wasn't expanded before function was fired
    if (expandedItem !== item || !expandedItem) {
      this.working = true;
      // wait 350ms time to finish all collapse animations and toggle selected item
      setTimeout(() => {
        item.expanded = !item.expanded;
        this.working = false;
      }, 250);
    }

    if (this.isLaptop) {
      this.#toggle(item);
    }
  }

  collapseAll(menu: MenuItem[]) {
    if (this.isExpanded(menu)) {
      for (const i of menu) {
        i.expanded = false;
      }
    }
  }

  isExpanded(menu: MenuItem[]) {
    return menu.some((item) => item.expanded);
  }

  logout() {
    // window.location.href = "/logout";          // <<---- NOWE
  }

  backToBank() {
    // window.location.href = "/returnToBank";    // <<---- NOWE
  }

  handleBrand() {
    // <<---- NOWE
    this.router.navigate(["/"]);
  }

  closeMenu() {
    this.modalService.close("menu");
  }

  trackByLabel(_: number, item: MenuItem) {
    return item.label;
  }

  #toggle(item: MenuItem) {
    const modalRef = this.modalService.getModalRef("menu");
    if (modalRef) {
      const modalData = modalRef.componentInstance?.data;
      this.closeMenu();
      // Don't open another modal if closed modal and clicked modal was the same
      if (modalData === item) return;
    }
    this.modalService.menu(item);
  }

  #getUrlObservable(path: string) {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => window.location.pathname.startsWith(path)),
      startWith(true),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  ngOnDestroy() {
    this.#subscription && this.#subscription.unsubscribe();
  }
}
