import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { baseCurrency, quoteCurrency } from "@shared/utils/currency";
import { merge } from "@utils/collection";
import { pick } from "@utils/object";
import { EMPTY, Observable, catchError, map, shareReplay, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HolidaysService {
  holidaysByCurrency$ = this.http.get<HolidaysApiModel>("/currency/holidays").pipe(
    map(parseHolidayDates),
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError(() => EMPTY)
  );

  constructor(private http: HttpClient) {}

  getHolidaysByCurrencyPair(code: string): Observable<string[]>;
  getHolidaysByCurrencyPair(code$: Observable<string>): Observable<string[]>;
  getHolidaysByCurrencyPair(currencyPair: string | Observable<string>) {
    const byPair = (code: string) => this.#getHolidaysByCurrencies(splitCurrencyPair(code));

    return typeof currencyPair === "string"
      ? byPair(currencyPair)
      : currencyPair.pipe(switchMap(byPair));
  }

  getHolidaysByCurrency(code: string): Observable<string[]>;
  getHolidaysByCurrency(code$: Observable<string>): Observable<string[]>;
  getHolidaysByCurrency(currency: string | Observable<string>) {
    const byCurrency = (code: string) =>
      this.holidaysByCurrency$.pipe(map((dict) => dict[code] ?? []));

    return typeof currency === "string"
      ? byCurrency(currency)
      : currency.pipe(switchMap(byCurrency));
  }

  #getHolidaysByCurrencies = (currencies: string[]) =>
    this.holidaysByCurrency$.pipe(
      map((dict) => pick(dict, ...currencies)),
      map((subset) => merge(...Object.values(subset)))
    );
}

const parseHolidayDates = (holidaysByCurrency: HolidaysApiModel): HolidaysApiModel => {
  return Object.keys(holidaysByCurrency).reduce(
    (result, currency): HolidaysApiModel => ({
      ...result,
      [currency]: holidaysByCurrency[currency].map((date) => date.slice(0, 10)),
    }),
    {}
  );
};

const splitCurrencyPair = (pair: string) => [baseCurrency(pair), quoteCurrency(pair)];

type HolidaysApiModel = Record<string, string[]>;
