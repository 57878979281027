import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Self,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroupDirective,
  NgControl,
} from "@angular/forms";
import { ControlSize } from "@core/models/control";
import { Account, getDefaultAccount } from "@features/accounts";

@Component({
  selector: "app-select-account",
  templateUrl: "select-account.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class SelectAccountComponent implements OnChanges {
  @Input("validate") controlToValidate?: string;
  @Input("ngId") labelForId?: string;
  @Input() items: Account[] | null = [];
  @Input() label?: string;
  @Input() translateLabel = true;
  @Input() size?: ControlSize;
  @Input() bindValue: "id" | "number" = "number";
  @Input() useDefault = true;
  @Input() placeholder?: string;

  @Output() selected = new EventEmitter<Account>();

  constructor(@Self() private controlDir: NgControl) {}

  get control() {
    return this.controlDir.control as FormControl;
  }

  ngOnChanges({ items }: SimpleChanges) {
    if (!this.control || !items?.currentValue) return;

    this.#updateSelection(this.control, items.currentValue);
  }

  #updateSelection(control: AbstractControl, accounts: Account[]) {
    const property = this.bindValue;
    const hasSelected =
      control.value && accounts.some((account) => account[property] === control.value);

    if (hasSelected) return;

    const account = this.useDefault ? getDefaultAccount(accounts) : null;

    control.setValue(account?.[property] ?? null);
    control.updateValueAndValidity();
  }
}
