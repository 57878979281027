import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { ExchangeService } from "@features/exchange/services/exchange.service";
import { IFormService } from "@features/transaction/models";
import { PvpService } from "@features/transaction/services/pvp.service";
import { datepickerFormat } from "@utils/time";
import { BehaviorSubject } from "rxjs";
import { IobForm } from "../dpw.model";

@Injectable({ providedIn: "root" })
export class IobFormService implements IFormService<IobForm> {
  private _form = new BehaviorSubject(this.defaultForm);

  submission$ = this.exchangeService.submission$;

  constructor(
    private preferences: PreferencesService,
    private exchangeService: ExchangeService,
    private pvpService: PvpService
  ) {}

  get current() {
    return this._form.value;
  }

  private get defaultForm() {
    const { ...preferences } = this.preferences.exchange;

    return {
      ...preferences,
      settlement: { tenor: "TOD", date: datepickerFormat(new Date()) },
      currencyPair: "",
      product: "FxOn",
    } as IobForm;
  }

  reset(retain?: "all") {
    if (retain === "all") return;

    this._form.next(this.defaultForm);
  }

  save(form: IobForm) {
    this._form.next(form);
  }

  submit() {
    this.exchangeService.init({ ...this.current, variation: "iob" }, this.pvpService.rule);
  }
}
