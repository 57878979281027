import { HttpClient } from "@angular/common/http";
import { Component, HostBinding } from "@angular/core";
import { SessionService } from "@core/session";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";
import { take } from "rxjs";

@Component({
  selector: "app-prolong-session",
  templateUrl: "./prolong-session.component.html",
})
export class ProlongSessionComponent {
  @HostBinding("class") class = "h-100";
  modalConfig: Modal = {
    title: "",
    buttons: {
      primary: { text: "buttons.session.Prolong", onClick: () => this.prolong() },
      secondary: { text: "buttons.session.Logout", onClick: () => this.logout() },
    },
  };

  constructor(
    private http: HttpClient,
    public activeModal: NgbActiveModal,
    public session: SessionService
  ) {}

  prolong() {
    this.http.post<void>("/extend", null).pipe(take(1)).subscribe();
  }

  logout() {
    window.location.href = "/logout";
  }
}
