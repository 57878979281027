<dl class="pko-definition__list">
  <app-definition label="deposit.Amount">
    <app-flag [icon]="currency" [label]="detail.amount | balance: currency"></app-flag>
  </app-definition>

  <app-definition label="StartDate" [value]="detail.start.date | datePko"></app-definition>

  <app-definition
    label="deposit.Duration"
    [value]="'tenor.' + (detail.end.tenor || 'Custom') | translate"></app-definition>

  <app-definition label="deposit.DurationDays" [value]="duration"></app-definition>

  <app-definition label="accounts.From" [account]="detail.account"></app-definition>
</dl>
