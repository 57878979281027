import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControlOptions, FormBuilder, FormGroup } from "@angular/forms";
import { BusyService } from "@core/loading/busy.service";
import { mapToBusy } from "@core/models/observable-action";
import { Tracker } from "@core/user-tracking/tracker.service";
import { MULTIFX_PRODUCT_TYPES } from "@features/multifx/models/multifx-form";
import { MultiFxFormService } from "@features/multifx/services/multifx-form.service";
import { PRODUCT_TYPES } from "@features/tenor";
import { datepickerFormat } from "@utils/time";
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  from,
  lastValueFrom,
  map,
  merge,
  partition,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs";
import { Dpw } from "../dpw.model";
import { DpwService } from "../dpw.service";
import { DpwFormService } from "../services/dpw-form.service";
@Component({
  selector: "app-base-dpw-list",
  template: "dpw list",
})
export class BaseDpwListComponent implements OnInit, OnDestroy {
  active$: Observable<Dpw[]>;
  pending$: Observable<Dpw[]>;

  protected isTile = false;

  form$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  isBusy$ = merge(
    this.formService.submission$.pipe(mapToBusy()),
    this.multiFormService.submission$.pipe(mapToBusy()),
    this.busyService.observeOn("/dpw")
  );
  #destroy$ = new Subject<void>();

  constructor(
    dpwService: DpwService,
    private fb: FormBuilder,
    private formService: DpwFormService,
    private multiFormService: MultiFxFormService,
    private busyService: BusyService,
    private tracker: Tracker
  ) {
    const [active, pending] = partition(
      dpwService.getList().pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        map(groupDpw),
        switchMap(({ active, pending }) => from([active, pending]))
      ),
      (_, i) => i === 0
    );
    this.active$ = active.pipe(share());
    this.pending$ = pending.pipe(share());
    this.active$
      .pipe(
        take(1),
        tap((dpws) => this.#createForm(dpws))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.isTile ||
      this.tracker.follow({ process: "dpw", origin: history.state?.origin ?? "reload" });
  }

  async getRate() {
    this.isTile &&
      this.tracker.follow({ process: "dpw", origin: history.state?.origin ?? "reload" });
    const active = await lastValueFrom(this.active$);
    const selectedKeys = Object.entries(this.form$.value.controls)
      .filter(([, control]) => control.value)
      .map(([key]) => key);
    const selected = active.filter((x) => selectedKeys.includes(x.ssrId));

    if (selected.length > 1) {
      this.saveMulti(selected);
      this.multiFormService.submit();
      return;
    }

    this.save(selected[0]);
    this.formService.submit();
  }

  private save = (dpw: Dpw) =>
    this.formService.save({
      side: dpw.type === "IN" ? "Sell" : "Buy",
      amount: dpw.amount,
      currency: dpw.currency,
      counterCurrency: dpw.counterCurrency,
      currencyPair: dpw.currencyPair,
      settlement: { date: datepickerFormat(new Date()), tenor: "TOD" },
      product: PRODUCT_TYPES.overnight,
      ssrId: dpw.ssrId,
    });

  private saveMulti = (dpws: Dpw[]) =>
    this.multiFormService.save({
      currency: dpws[0].currency,
      counterCurrency: dpws[0].counterCurrency,
      currencyPair: dpws[0].currencyPair,
      isDpw: true,
      parForward: false,
      pvp: true,
      series: dpws.map((x, i) => ({
        amount: x.amount,
        clientSideId: `c${i}`,
        product: MULTIFX_PRODUCT_TYPES.overnight,
        side: x.type === "IN" ? "Sell" : "Buy",
        settlement: {
          date: datepickerFormat(new Date()),
          tenor: "TOD",
        },
        dpwNumber: x.ssrId,
      })),
    });

  #createForm(dpws: Dpw[]) {
    const form = this.fb.group(
      dpws.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.ssrId]: false,
        }),
        {}
      ),
      {
        validators: [validateSelected],
      } as AbstractControlOptions
    );

    form.valueChanges
      .pipe(
        filter((state) => !Object.entries(state).some(([, value]) => value === "on")),
        takeUntil(this.#destroy$)
      )
      .subscribe((state) => {
        const selected = Object.entries(state)
          .filter(([, value]) => value)
          .map((x) => x[0]);

        if (selected.length > 1) return;

        Object.entries(form.controls).forEach(([, value]) => {
          value.enable({ emitEvent: false });
        });

        if (!selected.length) return;

        const selectedPair = dpws.filter((x) => x.ssrId === selected[0])[0].currencyPair;
        Object.entries(form.controls).forEach(([key, value]) => {
          if (selectedPair !== dpws.filter((x) => x.ssrId === key)[0].currencyPair)
            value?.disable({ emitEvent: false });
        });
      });

    this.form$.next(form);
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}

function groupDpw(x: Dpw[]) {
  return x.reduce(
    (acc, curr) => {
      curr.isActive ? acc.active.push(curr) : acc.pending.push(curr);
      return acc;
    },
    { active: [] as Dpw[], pending: [] as Dpw[] }
  );
}

function validateSelected(group: FormGroup) {
  return Object.entries(group.controls).filter(([, control]) => control.value).length
    ? {}
    : { empty: true };
}
