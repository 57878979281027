import { Component, ContentChild, HostBinding, Input, TemplateRef } from "@angular/core";
import { DataResult } from "@core/models/data-result";
import { GroupedArray } from "@utils/collection";
import { Observable } from "rxjs";
import { RecentDeal, UnsettledDeal } from "../recent-history.model";

@Component({
  selector: "app-recent-history-list",
  templateUrl: "recent-history-list.component.html",
})
export class RecentHistoryListComponent {
  @HostBinding("class") class = "pko-recent-history";

  @Input() deals$!: Observable<DataResult<GroupedArray<RecentDeal | UnsettledDeal>>>;
  @Input() emptyMessage = "missingData.DealHistory";
  @Input() destination = "fx";

  @ContentChild("internalTemplate", { static: true, read: TemplateRef })
  internalTemplate!: TemplateRef<unknown>;
}
