import { NgModule } from "@angular/core";
import { CurrencyModule } from "@features/currency/currency.module";
import { GraphModule } from "@features/graph/graph.module";
import { SharedModule } from "@shared/shared.module";
import { IndicativeChartComponent } from "./components/chart/indicative-chart.component";
import { IndicativeRateComponent } from "./components/rate/indicative-rate.component";
import { IndicativeSettingsComponent } from "./components/settings/indicative-settings.component";
import { IndicativeSettingsItemComponent } from "./components/settings/item/indicative-settings-item.component";
import { IndicativesProComponent } from "./components/views/indicatives-pro.component";
import { IndicativesSimpleComponent } from "./components/views/indicatives-simple.component";
import { IndicativesComponent } from "./indicatives.component";

@NgModule({
  imports: [CurrencyModule, SharedModule, GraphModule],
  declarations: [
    IndicativeRateComponent,
    IndicativesComponent,
    IndicativeSettingsComponent,
    IndicativeSettingsItemComponent,
    IndicativeChartComponent,
    IndicativesSimpleComponent,
    IndicativesProComponent,
  ],
  exports: [IndicativesComponent],
})
export class IndicativesModule {}
