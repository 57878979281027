import { Directive, isDevMode, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[ifIsDevMode]",
})
export class IfIsDevModeDirective implements OnInit {
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>
  ) {}

  ngOnInit() {
    if (isDevMode()) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
