import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, FormArray, FormGroupDirective } from "@angular/forms";
import { MultiFxLegForm } from "@features/multifx/models/multifx-form";
import { filter, map, Observable, pairwise, startWith, tap } from "rxjs";

@Component({
  selector: "app-form-multifx-forward-collateral",
  templateUrl: "./form-multifx-forward-collateral.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FormMultifxForwardCollateralComponent implements OnInit {
  showForwardCollateral$!: Observable<boolean>;

  get #controls() {
    return this.parent.form.controls;
  }

  constructor(private parent: FormGroupDirective, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const { series, forwardCollateral } = this.#assertForm();

    this.showForwardCollateral$ = series.valueChanges.pipe(
      startWith(series.value),
      map(toShowForward),
      tap(toggle(forwardCollateral)),
      tap(() => this.cdr.detectChanges())
    );
  }

  #assertForm() {
    const { forwardCollateral, series } = this.#controls;

    if (!forwardCollateral || !series || !(series instanceof FormArray)) {
      throw new Error(
        "This component needs 'forwardCollateral' and 'series' as FormArray controls present on the form!"
      );
    }

    return { series, forwardCollateral };
  }
}

const toShowForward = (series: MultiFxLegForm[]) =>
  series.some(({ product }) => product === "Forward");

const toggle = (control: AbstractControl) => (enable: boolean) => {
  if (enable) {
    control.enable();
  } else {
    control.disable();
  }
};
