import { Injectable } from "@angular/core";
import { Account, AccountsService } from "@features/accounts";
import { removeDuplicates } from "@utils/collection";
import { Observable, combineLatest, map, shareReplay } from "rxjs";
import { CurrencyApiService } from "./currency-api.service";
import { getCurrencyByCode } from "./currency-utils";
import { Currency, CurrencyPair } from "./model";
@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  /**
   * Although we call for FX currencies, effectively it's always a superset of deposit currencies.
   */
  allCurrencies$ = this.api.getCurrencies("exchange");

  currencyPairs$ = this.api.getCurrencyPairs();

  currencySortedPairs$ = this.api.getSortedCurrencyPairs();

  exchangeCurrencies$ = extractRelevantCurrencies(
    this.allCurrencies$,
    getCustomerCurrencyCodes(this.accountService.exchangeAccounts$),
    (codes) => hasNoPairs(this.currencyPairs$, codes)
  );

  depositCurrencies$ = extractRelevantCurrencies(
    this.api.getCurrencies("deposit"),
    getCustomerCurrencyCodes(this.accountService.depositAccounts$),
    (codes) => hasNoCurrencies(codes)
  );

  constructor(private api: CurrencyApiService, private accountService: AccountsService) {}

  /**
   * Get currency observable from the stream of all currencies (can be used safely* for deposits).
   * *safely: deposit currency not present in this stream is a hypothetical case not handled even by the core infrastructure.
   */
  getCurrency(code: string) {
    return this.allCurrencies$.pipe(map((currencies) => getCurrencyByCode(currencies, code)));
  }

  getCurrencyPair(code: string) {
    return this.currencyPairs$.pipe(map((pairs) => pairs.find((pair) => pair.code === code)));
  }
}

const getCustomerCurrencyCodes = (source$: Observable<Account[]>) =>
  source$.pipe(
    map((accounts) => accounts.map(({ currency }) => currency)),
    map((x) => removeDuplicates(x))
  );

const hasNoPairs = (
  currencyPairs$: Observable<CurrencyPair[]>,
  customerCurrencies$: Observable<string[]>
) =>
  combineLatest([currencyPairs$, customerCurrencies$]).pipe(
    map(([pairs, currencies]) => {
      return (
        pairs.filter(
          ({ code }) =>
            currencies.includes(code.substring(0, 3)) && currencies.includes(code.substring(3))
        ).length < 1
      );
    })
  );

const hasNoCurrencies = (customerCurrencies$: Observable<string[]>) =>
  customerCurrencies$.pipe(map((currencies) => currencies.length < 1));

const extractRelevantCurrencies = (
  currencies$: Observable<Currency[]>,
  codes$: Observable<string[]>,
  takeAllDelegate: (codes$: Observable<string[]>) => Observable<boolean>
) =>
  combineLatest([currencies$, codes$, takeAllDelegate(codes$)]).pipe(
    map(([currencies, codes, takeAll]) =>
      // if user has no account or only in one currency, show all possible currencies
      takeAll ? currencies : currencies.filter((currency) => codes.includes(currency.code))
    ),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
