import { Component } from "@angular/core";

import { ActionItem } from "@core/menus/action-item";
import { NavigationService } from "@core/navigation";
import { NormalExchangeForm } from "@features/exchange/models";
import {
  ExecutedAlert,
  UserNotificationComponent,
} from "@features/notifications/notifications.model";

@Component({
  selector: "app-notifications-executed-alert",
  templateUrl: "./notifications-executed-alert.component.html",
})
export class NotificationsExecutedAlertComponent extends UserNotificationComponent {
  get alert(): ExecutedAlert {
    return this.notification as ExecutedAlert;
  }

  constructor(private navigation: NavigationService) {
    super();
  }

  get icon(): string {
    return "alert-out";
  }

  get header(): string {
    return "notifications." + this.alert.name;
  }

  mainAction(): void {
    const { amount1, currency1, currency2, side } = this.alert;
    const form = {
      side,
      amount: amount1,
      currency: currency1,
      counterCurrency: currency2,
      settlement: {
        date: null,
        tenor: "TOD",
      },
      collateral: {},
    } as NormalExchangeForm;

    this.navigation.navigate(["/transactions/exchange"], {
      replaceUrl: true,
      state: { form, origin: "notification" },
    });
  }

  get menu(): ActionItem[] {
    return [
      {
        text: "notifications.NewDeal",
        callback: () => this.mainAction(),
      } as ActionItem,
    ];
  }
}
