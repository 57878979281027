import { Component } from "@angular/core";
import { FlowDeclaration } from "@features/flow-declaration/flow-declaration.model";
import { BehaviorSubject, switchMap } from "rxjs";
import { FlowDeclarationService } from "../../flow-declaratoin.service";

@Component({
  selector: "app-flow-declaration-view",
  templateUrl: "./flow-declaration-view.component.html",
})
export class FlowDeclarationViewComponent {
  #vmReload = new BehaviorSubject<boolean>(true);

  vm$ = this.#vmReload.asObservable().pipe(switchMap(() => this.service.get()));

  isEditing = false;

  constructor(private service: FlowDeclarationService) {}

  onUpdated() {
    this.isEditing = true;
  }

  onSaved(declaration: FlowDeclaration) {
    this.service.save(declaration).subscribe(() => {
      this.#vmReload.next(true);
      this.isEditing = false;
    });
  }

  onRefused() {
    this.#vmReload.next(true);
  }

  onCanceled() {
    this.isEditing = false;
  }
}
