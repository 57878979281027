<div
  class="pko-ticking-rate position-relative"
  (click)="onClick()"
  [class.pko-ticking-rate--disabled]="disabled || lockReason"
  [ngbTooltip]="lockReason ? tooltipContent : ''"
  tooltipClass="pko-tooltip__window"
  placement="right"
  *ngIf="tooltipTrigger$ | async as ttVm"
  [triggers]="ttVm.trigger"
  [autoClose]="ttVm.autoClose">
  <h3 class="pko-ticking-rate__key">
    {{ "sides." + (side | titlecase) | translate }}&nbsp;{{ currency }}
  </h3>
  <div class="pko-ticking-rate__value">
    <ng-container *ngIf="rate || (rate === 0 && handleZero); else loading">
      <ng-container *ngIf="rate; else noRate">
        {{ rate | rate }}
        <app-rate-direction-arrow
          class="pko-ticking-rate__indicator"
          [currentRate]="rate || undefined"></app-rate-direction-arrow>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loading
  >&nbsp;
  <div pko-preloader [transparent]="true"></div
></ng-template>

<ng-template #noRate>
  <div class="pko-ticking-rate__value--no-rate">{{ "missingData.Rate" | translate }}</div>
</ng-template>

<ng-template #tooltipContent>
  <div class="pko-tooltip__container">
    <div class="pko-tooltip__content" [innerHtml]="'errors.' + lockReason | translate"></div>
  </div>
</ng-template>
