import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, filter, map, Subscription, tap } from "rxjs";
import { Breakpoint, BREAKPOINTS, isBreakpoint } from "./breakpoints";

@Injectable({
  providedIn: "root",
})
export class BreakpointObserverService implements OnDestroy {
  #subscription = new Subscription();
  #currentBreakpoint = new BehaviorSubject<Breakpoint>("xs");
  currentBreakpoint$ = this.#currentBreakpoint.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    const observables = Object.entries(BREAKPOINTS).map(([breakpoint, size]) =>
      breakpointObserver.observe(size).pipe(
        filter((state) => state.matches),
        tap(() => this.#currentBreakpoint.next(breakpoint as Breakpoint))
      )
    );

    observables.forEach((observable) => this.#subscription.add(observable.subscribe()));
  }

  isBreakpoint(selected: Breakpoint | Breakpoint[]) {
    return this.#currentBreakpoint.pipe(map((breakpoint) => isBreakpoint(breakpoint, selected)));
  }

  isMatched(...breakpoints: Breakpoint[]) {
    return breakpoints.some((breakpoint) =>
      this.breakpointObserver.isMatched(BREAKPOINTS[breakpoint])
    );
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
  }
}
