<app-labeled-text-inline
  class="pko-recent-history__item-deal"
  [label]="'exchange.type.' + deal.product">
  <span [class.text-danger]="isSelling">{{ deal.amount | balance: deal.currency }}</span>
</app-labeled-text-inline>
<app-labeled-text-inline
  class="pko-recent-history__item-deal"
  label="{{ 'RateValue' | translate: { rate: deal.rate | rate } }}"
  [translateLabel]="false">
  <span [class.text-danger]="!isSelling">{{
    deal.counterAmount | balance: deal.counterCurrency
  }}</span>
</app-labeled-text-inline>
