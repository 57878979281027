import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from "@angular/forms";
import { Account, AccountsService, getAccountByNumber, PvpRule } from "@features/accounts";
import { PvpService } from "@features/transaction/services/pvp.service";
import { areSome } from "@utils/collection";
import { combineLatest, distinctUntilChanged, EMPTY, map, Observable, startWith, tap } from "rxjs";

@Component({
  selector: "app-form-pvp",
  templateUrl: "form-pvp.component.html",
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class PvpFormComponent implements OnInit {
  accounts$ = this.accountService.exchangeAccounts$;

  /**
   * Appropriate PvP rule, depends on the selected accounts.
   */
  rule$: Observable<PvpRule> = EMPTY;

  constructor(
    private parent: ControlContainer,
    private accountService: AccountsService,
    private pvpService: PvpService
  ) {}

  get #group() {
    return this.parent.control as FormGroup;
  }

  ngOnInit() {
    this.#assertForm();
    this.#observe();
  }

  #assertForm() {
    const { pvp = new FormControl(), account, counterAccount } = this.#group.controls;

    if (!account || !counterAccount) {
      throw new Error(
        "This component needs 'account' and 'counterAccount' controls present on the form!"
      );
    }

    this.#group.controls.pvp || this.#group.addControl("pvp", pvp);

    return { pvp };
  }

  #observe() {
    const { account, counterAccount, pvp } = this.#group.controls;

    const hasExternalAccount$ = combineLatest([
      account.valueChanges.pipe(startWith(account.value), this.#mapToIsExternal()),
      counterAccount.valueChanges.pipe(startWith(counterAccount.value), this.#mapToIsExternal()),
    ]).pipe(map(areSome(true)), distinctUntilChanged());

    this.rule$ = combineLatest([this.accountService.pvp$, hasExternalAccount$]).pipe(
      map(([rules, withExternal]) => (withExternal ? rules.external : rules.internal)),
      tap((rule) => handleDefault(rule ?? {}, pvp)),
      tap((rule) => (this.pvpService.rule = rule))
    );
  }

  #mapToIsExternal = () => (source$: Observable<string>) =>
    combineLatest([source$, this.accounts$], isExternal);
}

const isExternal = (account: string, accounts: Account[]) =>
  getAccountByNumber(accounts, account)?.isExternal ?? false;

const handleDefault = ({ isValueEditable, defaultValue }: PvpRule, pvp: AbstractControl) => {
  if (isValueEditable && pvp.value !== undefined && pvp.value !== null) {
    return;
  }

  pvp.setValue(defaultValue);
};
