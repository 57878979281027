import { Injectable } from "@angular/core";
import { PreferencesService } from "@core/preferences/preferences.service";
import { Currency, CurrencyService, filterCurrenciesByPairs } from "@features/currency";
import { IFormService } from "@features/transaction/models";
import { TransactionResponseService } from "@features/transaction/services/response.service";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { OrderForm, OrderFormData, formDefaults } from "../models/order-form";
import { hasPairs } from "../order-utils";
import { OrderApiService } from "./order-api.service";

@Injectable({ providedIn: "root" })
export class OrderFormService implements IFormService<OrderForm> {
  #form = new BehaviorSubject(this.#defaultForm);

  formData$ = combineLatest([
    this.api.getFormData(),
    this.currencyService.exchangeCurrencies$,
  ]).pipe(map(toFormData));

  constructor(
    private transaction: TransactionResponseService,
    private preferences: PreferencesService,
    private api: OrderApiService,
    private currencyService: CurrencyService
  ) {}

  get current() {
    return this.#form.getValue() as OrderForm;
  }

  get #defaultForm() {
    const { phone } = this.preferences;
    return { ...formDefaults, phone } as Partial<OrderForm>;
  }

  reset(retain?: "all" | "some") {
    if (retain === "all") return;

    let form = this.#defaultForm;
    if (retain === "some") {
      const current = { ...this.current };
      propsToReset.forEach((prop) => delete current[prop]);

      const { other } = current;
      other && propsToReset.forEach((prop) => delete other[prop]);

      form = { ...this.#defaultForm, ...current };
    }

    this.#form.next(form);
  }

  save(form: OrderForm) {
    this.#form.next(form);
  }

  submit() {
    this.transaction.decide("order");
    this.preferences.setPhone({ ...this.current.phone });
  }
}

const propsToReset = ["amount", "rate", "points", "account", "counterAccount"] as const;

const toFormData = ([formData, currencies]: [
  Omit<OrderFormData, "currencies">,
  Currency[]
]): OrderFormData => ({
  ...formData,
  // orders can have some currencies disabled
  currencies: filterCurrenciesByPairs(currencies, formData.currencyPairs),
  hasPairs: hasPairs(formData.currencyPairs),
});
