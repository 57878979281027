<ng-container *ngIf="dates$ | async as dates; else loadingBlock">
  <ul
    ngbNav
    #nav="ngbNav"
    class="pko-tabs nav-tabs"
    role="tablist"
    [activeId]="selectedTab"
    (activeIdChange)="selectDate($event)">
    <li
      class="pko-tabs__item"
      role="presentation"
      *ngFor="let date of dates; index as i"
      [ngbNavItem]="i">
      <a class="pko-tabs__link" role="tab" ngbNavLink>{{
        i === 1 ? ("notifications.Today" | translate) : (date | date: dateFormat)
      }}</a>
      <ng-template ngbNavContent>
        <ng-content *ngTemplateOutlet="data; context: { $implicit: i }"></ng-content>
      </ng-template>
    </li>
  </ul>
  <div class="pko-tabs__panel" role="tabpanel" [ngbNavOutlet]="nav"></div>
</ng-container>

<ng-template #data let-i>
  <ng-container *ngIf="allNotifications[i] | async as notifications; else loadingBlock">
    <ng-container *ngIf="notifications.length; else missing">
      <app-notifications-main
        *ngFor="let n of notifications; trackBy: trackByKey"
        [notification]="n"
        [@slideOut]="n.deleted ? 'deleted' : ''"
        [simple]="false"
        (actionExecuted)="onActionExecuted($event)">
      </app-notifications-main>
      <button
        class="btn btn-more pko-notifications__more"
        [class.btn-more--collapsed]="expanded$ | async"
        (click)="toggleExpansion()"
        *ngIf="toggle$ | async">
        {{ ((expanded$ | async) ? "ShowLess" : "ShowMore") | translate }}
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #loadingBlock>
  <div pko-preloader></div>
</ng-template>

<ng-template #missing>
  <app-alert content="missingData.Notifications"></app-alert>
</ng-template>
