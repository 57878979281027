<ng-container *ngIf="deals$ | async as deals; else loading">
  <app-grouped-list
    [groups]="deals.data"
    [byDate]="true"
    *ngIf="!deals.error; else error"
    [emptyMessage]="emptyMessage">
    <ng-template #itemTemplate let-item>
      <ng-container
        *ngTemplateOutlet="internalTemplate; context: { $implicit: item }"></ng-container>
      <ng-content></ng-content>
    </ng-template>
  </app-grouped-list>

  <button
    class="btn btn-secondary btn-fixed btn-sm pko-recent-history__more"
    routerLink="/history/exchange"
    [state]="{ destination }">
    {{ "buttons.go.history" | translate }}
  </button>
</ng-container>

<ng-template #loading>
  <div pko-preloader height="50vw"></div>
</ng-template>

<ng-template #error>
  <app-alert content="errors.TechnicalError" icon="failure" context="danger"></app-alert>
</ng-template>
