<app-grouped-list
  [groups]="items | groupAndSort : 'expiration'"
  groupLabel="alert.Details.Expiration"
  [byDate]="true"
  emptyMessage="missingData.AlertHistory"
  displayAs="table"
  class="d-sm-none">
  <ng-template #itemTemplate let-item>
    <div class="pko-group__item" [alert]="item" role="stack"></div>
  </ng-template>
</app-grouped-list>

<table
  [columns]="columns"
  class="d-none d-sm-table"
  emptyMessage="missingData.AlertHistory"
  [loadNextPageButton]="loadMoreButton"
  pkoTable
  [items]="items">
  <ng-template #itemTemplate let-item>
    <tr class="pko-table__row align-middle" [alert]="item" role="row"></tr>
  </ng-template>
</table>

<button
  class="btn btn-link btn-add d-md-none {{ noItems ? 'btn-add--vertical' : 'btn-fab' }}"
  routerLink="/rates/alert">
  <svg icon name="plus-0" class="icon icon--3xl border rounded-circle"></svg>
  {{ noItems ? ("buttons.add.alert" | translate) : "" }}
</button>
