<div pko-preloader *ngIf="isBusy$ | async"></div>
<form class="pko-form pko-form--full" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="pko-grid__row">
    <app-form-multifx-currency-pair
      class="col-10 col-md-5 col-lg-4"></app-form-multifx-currency-pair>
    <div class="w-100"></div>
    <app-form-multifx-parforward class="col-10"></app-form-multifx-parforward>
    <app-form-pvp class="col-10"></app-form-pvp>
    <app-form-multifx-accounts class="col-10 col-md-8"></app-form-multifx-accounts>
    <app-form-multifx-transactions-leg
      class="col-10"
      [seriesErrors]="seriesErrors"></app-form-multifx-transactions-leg>
    <app-form-collateral
      formGroupName="shortCollateral"
      product="FxOn"
      class="col-10 col-md-4"
      hidden></app-form-collateral>
    <app-form-multifx-forward-collateral
      class="col-10 col-md-4"></app-form-multifx-forward-collateral>
    <div class="w-100"></div>
    <app-form-multifx-sum class="col-10 col-md-5 col-lg-4 ms-auto"></app-form-multifx-sum>
  </div>
  <app-form-footer [back]="{ hidden: true }"></app-form-footer>
</form>

<pre *ifIsDevMode>{{ form.getRawValue() | json }}</pre>
