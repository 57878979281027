<div class="pko-dashboard__area pko-dashboard__area--center">
  <div class="pko-grid__row">
    <app-indicatives class="col-12 col-lg-9"></app-indicatives>
    <div class="col-12 col-lg-3">
      <app-dashboard-section sectionTitle="shortcuts.MyShortcuts" [solid]="false"
        ><app-shortcuts></app-shortcuts
      ></app-dashboard-section>
      <app-pro-settings-banner></app-pro-settings-banner>
    </div>
  </div>
</div>

<ng-container *ngIf="tiles$ | async as tiles">
  <div class="pko-dashboard__area pko-dashboard__area--left">
    <ng-container *ngFor="let tile of tiles.left">
      <ng-container *ngIf="this.types[tile] as template">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="pko-dashboard__area pko-dashboard__area--right">
    <ng-container *ngFor="let tile of tiles.right">
      <ng-container *ngIf="this.types[tile] as template">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template sectionType="transactions">
  <app-recent-history-view sectionTitle="history.recent.MyTransactions"></app-recent-history-view>
</ng-template>

<ng-template sectionType="deal">
  <app-dashboard-section
    *ifHasRole="'FxSpot|FxForward|FxSwap|ProUser'"
    sectionTitle="pages.Exchange">
    <app-form-exchange-tabs [isTile]="true"></app-form-exchange-tabs>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="notifications">
  <app-dashboard-section sectionTitle="notifications.Title">
    <app-notifications></app-notifications>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="dpw">
  <app-dashboard-section sectionTitle="dpw.Title" *ifHasRole="'DPWEnabled|ProUser,DpwOn'">
    <app-dpw-tile></app-dpw-tile>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="alert">
  <app-dashboard-section sectionTitle="pages.Alert">
    <app-form-alert-tile></app-form-alert-tile>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="order">
  <app-dashboard-section *ifHasRole="'Orders|ProUser'" sectionTitle="pages.Order">
    <app-form-order-tile></app-form-order-tile>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="deposit">
  <app-dashboard-section *ifHasRole="'MmDeposit'" sectionTitle="pages.Deposit">
    <app-form-deposit-tile></app-form-deposit-tile>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="investmentDeposit">
  <app-dashboard-section
    *ifHasRole="'InvDeposit|ProUser,LiEnabled'"
    sectionTitle="pages.InvestmentDeposit">
    <app-investment-deposit-tile></app-investment-deposit-tile>
  </app-dashboard-section>
</ng-template>

<ng-template sectionType="news">
  <app-dashboard-section *ifHasRole="'HasCustomer'" sectionTitle="pages.News">
    <app-market-news-tile></app-market-news-tile>
  </app-dashboard-section>
</ng-template>
