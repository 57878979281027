<div class="pko-dialog__container" [class.pko-dialog__container--center]="data.type">
  <div class="pko-dialog__header">
    <button
      type="button"
      class="pko-dialog__close"
      [attr.aria-label]="'buttons.Close' | translate"
      (click)="close()"
      *ngIf="!data.hideCloseButton">
      <svg icon name="close_nav" class="icon icon--lg"></svg>
    </button>
    <svg
      icon
      name="{{ data.type }}"
      class="pko-dialog__icon {{ data.style ? 'pko-dialog__icon--' + data.style : '' }}"
      *ngIf="data.type"></svg>
    <h3
      class="pko-dialog__title {{ data.style ? 'pko-dialog__title--' + data.style : '' }}"
      [innerHTML]="data.translateTitle ?? true ? (data.title ?? '' | translate) : data.title ?? ''"
      [class.hidden]="!data.title"></h3>
  </div>
  <div class="pko-dialog__body" *ngIf="lines">
    <ng-container *ngFor="let line of lines">
      <p
        *ngIf="line.key !== 'HasSimilarDeal'"
        [innerHtml]="
          data.translateBody ?? true ? (line.key | translate : line.params) : line.key
        "></p>
    </ng-container>
    <ng-content></ng-content>
  </div>
  <div
    class="pko-dialog__footer"
    *ngIf="data.buttons"
    [buttons]="data.buttons"
    [type]="'modal'"
    (clicked)="onClicked($event)"
    app-modal-footer></div>
</div>
