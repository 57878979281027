import { Boundary } from "@core/models";
import { Side } from "@core/models/transaction";
import { DepositType } from "@features/deposit-history/models/deposit-list";

export interface Filters {
  pageSize: number;
  pageNumber: number;
  type: ListingType;
  productTypes: string[];
  subproduct?: string | null;
  currencyPair: string;
  currency: string;
  amount: Boundary<number | null>;
  rate: Boundary<number | null>;
  settlementDate: Boundary<Date | null>;
  dealDate: Boundary<Date | null>;
  id: string | null;
  side?: Side;
  settlementStatus: SettlementStatus;
  sortingOrder: string;
  orderId: string;
  openBalance?: boolean;
  valuationsDate?: Date;
  pricing?: Boundary<number | null>;
  depositType?: DepositType;
}

export const defaultBoundary: Boundary<null> = { from: null, to: null };

export const statuses = ["All", "Settled", "Unsettled", "Undefined", "Terminated"] as const;

export type SettlementStatus = (typeof statuses)[number];

export const types = ["All", "NotMatured", "Matured", "MaturingWeek"] as const;

export type ListingType = (typeof types)[number];
