<app-transaction-decision type="exchange" [subtype]="subtype" [loading$]="loading$" [vm$]="vm$">
  <ng-template #summary let-summary let-time="time">
    <app-alert
      *ngIf="warningService.outOfTargetMarket"
      icon="critical"
      content="alert.Content.OutOfTargetMarket"
      context="danger">
    </app-alert>
    <div class="pko-grid__row">
      <div class="col-10 col-md-4 order-md-last">
        <!-- near leg: regular | closing | rolling -->
        <ng-container [ngSwitch]="summary.nearLeg.netSettlementAmount | isNonNullable">
          <!-- closing -->
          <app-summary-quote
            *ngSwitchCase="true"
            [quote]="summary.nearLeg"
            [header]="{ title: 'exchange.NearLeg', subtitle: closingSubtitle }"
            class="opacity-50">
          </app-summary-quote>
          <!-- regular | rolling -->
          <app-summary-quote
            *ngSwitchCase="false"
            [quote]="summary.nearLeg"
            [header]="{ title: 'exchange.NearLeg', subtitle: rollingSubtitle }">
          </app-summary-quote>
        </ng-container>

        <!-- far leg: regular | closing | rolling -->
        <ng-container [ngSwitch]="summary.farLeg.netSettlementAmount | isNonNullable">
          <!-- closing -->
          <app-summary-quote
            *ngSwitchCase="true"
            [quote]="summary.farLeg"
            [header]="{ title: 'exchange.FarLeg', subtitle: closingSubtitle }"
            class="opacity-50">
          </app-summary-quote>
          <!-- regular | rolling -->
          <app-summary-quote
            *ngSwitchCase="false"
            [quote]="summary.farLeg"
            [header]="{ title: 'exchange.FarLeg', subtitle: rollingSubtitle }">
          </app-summary-quote>
        </ng-container>

        <app-margin-info [data]="summary" *ngIf="time > 0"></app-margin-info>
      </div>
      <app-summary-detail [detail]="summary" class="col-10 col-md-6"></app-summary-detail>
    </div>
  </ng-template>
</app-transaction-decision>
