import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "app-labeled-text-inline",
  templateUrl: "labeled-text-inline.component.html",
})
export class LabeledTextInlineComponent {
  @HostBinding("class") class = "d-flex justify-content-between align-items-center";

  @Input() label!: string;
  @Input() translateLabel = true;
}
