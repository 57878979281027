import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { last } from "@utils/collection";
import { getMax, getMin } from "@utils/time";
import { map } from "rxjs";
import {
  getOfferTenorsDatesByCurrencyPair,
  sortTenors,
} from "../components/utils/investment-deposit.utils";
import {
  Currency,
  InvestmentDepositConfig,
  OfferAmount,
  OfferCurrency,
  OfferCurrencyPair,
  TenorDates,
} from "../investment-deposit.model";

@Injectable({
  providedIn: "root",
})
export class InvestmentDepositApiService {
  constructor(private http: HttpClient) {}

  getData() {
    return this.http.get<InvestmentDepositConfig>("/investmentdeposit/data").pipe(
      map((data) => {
        const config = {
          currencies: [],
          offer: { currencies: [] },
          tenors: data.tenors,
          mifidValidationResult: data.mifidValidationResult,
        } as InvestmentDepositConfig;

        if (!data.currencies?.length || !data.tenors?.length || !data.offer?.currencies?.length) {
          return config;
        }

        const globalTenorConfig = sortTenors(data.tenors);
        for (const currency of data.offer.currencies) {
          const globalCurrency = data.currencies.find((x) => x.code === currency.code);

          if (!globalCurrency) continue;

          const amounts = this.#filterCurrencies(data, globalCurrency, currency, globalTenorConfig);

          if (!amounts.length) continue;

          config.currencies.push(globalCurrency);
          config.offer.currencies.push({
            amounts,
            code: globalCurrency.code,
          });
        }

        return config;
      })
    );
  }

  #filterCurrencies(
    data: InvestmentDepositConfig,
    globalCurrency: Currency,
    currency: OfferCurrency,
    globalTenorConfig: TenorDates[]
  ) {
    const amounts = [];
    for (const amount of currency.amounts) {
      this.#filterPairs(data, amount, globalCurrency, globalTenorConfig) && amounts.push(amount);
    }

    return amounts;
  }

  #filterPairs(
    data: InvestmentDepositConfig,
    amount: OfferAmount,
    globalCurrency: Currency,
    globalTenorConfig: TenorDates[]
  ) {
    amount.currencyPairs = amount.currencyPairs.filter((currencyPair) =>
      globalCurrency?.currencyPairs.some((globalPair) => globalPair === currencyPair.code)
    );

    if (!amount.currencyPairs?.length) return false;

    const pairs = [];
    for (const currencyPair of amount.currencyPairs) {
      this.#filterTenors(data, amount, currencyPair, globalTenorConfig) && pairs.push(currencyPair);
    }

    return pairs.length;
  }

  #filterTenors(
    data: InvestmentDepositConfig,
    amount: OfferAmount,
    currencyPair: OfferCurrencyPair,
    globalTenorConfig: TenorDates[]
  ) {
    const offerTenorConfig = getOfferTenorsDatesByCurrencyPair(data, amount, currencyPair.code);

    if (!offerTenorConfig?.length) return false;

    const min = getMax(offerTenorConfig[0]?.from, globalTenorConfig[0]?.from)!;
    const max = getMin(last(offerTenorConfig)?.to, last(globalTenorConfig)?.to)!;

    currencyPair.tenors = currencyPair.tenors.filter((x) => {
      const tenor = offerTenorConfig.find((y) => y.name === x.name)!;
      return !!tenor && new Date(tenor.from) >= min && new Date(tenor.to) <= max;
    });

    return currencyPair.tenors?.length;
  }
}
