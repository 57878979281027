import { Component } from "@angular/core";
import { UserService } from "@core/session";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Modal } from "@shared/components/modal/modal-model";

@Component({
  selector: "app-welcome-window",
  templateUrl: "welcome-window.component.html",
})
export class WelcomeWindowComponent {
  modalConfig: Modal = {
    title: "welcome.Title",
    buttons: {
      primary: {
        text: "welcome.ContinueButtonText",
        onClick: () => {
          this.activeModal.close();
        },
      },
    },
  };

  shortName: string;

  constructor(private userService: UserService, private activeModal: NgbActiveModal) {
    this.shortName = this.userService.userData.customerShortName;
  }
}
