import { ChangeDetectionStrategy, Component, ElementRef, HostBinding } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { BusyService } from "@core/loading/busy.service";
import { Tracker } from "@core/user-tracking/tracker.service";
import { AccountsService } from "@features/accounts";
import { CurrencyService } from "@features/currency";
import { ModalService } from "@features/modal/modal.service";
import { TenorService } from "@features/tenor";
import { InvestmentDepositFormService } from "../services/investment-deposit-form.service";
import { InvestmentDepositResponseService } from "../services/investment-deposit-response.service";
import { InvestmentDepositWarningCodeService } from "../services/investment-deposit-warning-code-service.service";
import { InvestmentDepositApiService } from "../services/investment-deposit.api.service";
import { InvestmentDepositComponent } from "./investment-deposit.component";

@Component({
  selector: "app-investment-deposit-tile",
  templateUrl: "investment-deposit-tile.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestmentDepositComponentTile extends InvestmentDepositComponent {
  @HostBinding("class") class = "position-relative";
  isTile = true;

  constructor(
    fb: FormBuilder,
    formService: InvestmentDepositFormService,
    currencyService: CurrencyService,
    accountsService: AccountsService,
    api: InvestmentDepositApiService,
    tenorService: TenorService,
    busyService: BusyService,
    tracker: Tracker,
    router: Router,
    modal: ModalService,
    responseService: InvestmentDepositResponseService,
    warningCodeService: InvestmentDepositWarningCodeService,
    private elem: ElementRef
  ) {
    super(
      fb,
      formService,
      currencyService,
      accountsService,
      api,
      tenorService,
      busyService,
      tracker,
      router,
      modal,
      responseService,
      warningCodeService
    );
  }

  onSubmit() {
    const componentSelector = this.elem.nativeElement.tagName.toLowerCase();
    super.onSubmit(componentSelector);
  }
}
